import React, { FC } from 'react';
import { InferProps } from '@violetta/ubeya/utils';
import { Field } from 'react-final-form';
import { TextInput } from '../TextInputs';
import { FormFieldProps } from './types';

export const required = (value) => (value ? undefined : 'Required');

export const identity = (value) => value;

export const InputField: FC<
  FormFieldProps<string, InferProps<typeof TextInput>>
> = ({ fieldProps, props, component: Component }) => (
  <Field<string>
    {...fieldProps}
    parse={identity}
    render={({ input, meta }) => (
      <Component
        {...props}
        onChange={input.onChange}
        value={input.value?.toString()}
        onFocus={input.onFocus as any}
        // eslint-disable-next-line react/jsx-closing-bracket-location
        onBlur={input.onBlur as any}
        meta={meta}
      >
        {fieldProps.value}
      </Component>
    )}
  />
);
