import { Alert } from '@violetta/ubeya/alert';
import { useUser } from '@violetta/ubeya/auth';
import { IEmployee } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseCollapsible,
  BaseText,
  BaseView,
  Button,
  ChevronDown,
  ChevronUp,
  FlexColumn,
  FlexColumnDirection,
  FlexRow,
  FlexRowBetween,
  H3Regular,
  InputField,
  StarRating,
  StarRatingField,
  SvgIcon,
  TextInput,
  TimeChangeModal,
  UserImage,
} from '@violetta/ubeya/ui';
import { uiAlert } from '@violetta/ubeya/utils';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Linking, Platform, Pressable, ScrollView } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { TimeItem } from './TimeItem';
import { clockApiKeyToSlug } from '@violetta/ubeya/shared';
import { icons } from '@violetta/ubeya/assets';

const Container = styled(FlexColumnDirection)`
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.colors.gray13};
  border-radius: 8px;
  padding: 22px 27px;
`;

const StarContainer = styled(BaseView)`
  width: 200px;
  margin: 10px 0;
`;

const InputContainer = styled(BaseView)``;

const RateShift = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray15};
`;

const EmployeeName = styled(BaseText)`
  margin-left: 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  width: 160px;
`;

const Dash = styled(H3Regular)`
  margin: 5px 0 0;
`;

// minus margin to avoid users clicking above the button and
// catching the collapse/expand onpress
const FormContainer = styled(FlexRow)`
  align-items: center;
  margin-top: -8px;
  padding-top: 8px;
`;
const EditContainer = styled(BaseView)`
  padding: 8px 10px;
`;

const Details = styled(FlexRowBetween)``;
const AutoTimeClock = styled(FlexRow)`
  padding: 5px 0;
  justify-content: flex-end;
`;
const AutoTimeClockHours = styled(FlexRow)`
  align-items: center;
`;

const TriggerContainer = styled(FlexColumn)`
  width: 100%;
  padding: 8px 0;
`;

const TriggerHeader = styled(FlexRowBetween)`
  align-items: flex-start;
  margin-top: 5px;
  width: 100%;
`;

const ButtonContainer = styled(FlexRowBetween)``;

// color: ${({ theme }) => theme.colors.surface};

const Space = styled(BaseView)`
  width: 23px;
`;

const inputStyle = (colors) => ({
  borderTopWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  marginBottom: 10,
  paddingLeft: 0,
  paddingBottom: 0,
  fontSize: 14,
  color: colors.textPrimary,
});

interface Props {
  employee: IEmployee;
  startTime: Date | null;
  endTime: Date | null;
  checkInTime: Date | null;
  checkOutTime: Date | null;
  rating: number;
  onUpdateTime: (values: any) => void;
  onUpdateSlot: (values: any) => void;
  shift: {
    startTime?: Date | null;
    endTime?: Date | null;
    position?: string | null;
    projectLocation?: string | null;
    shiftLocation?: string | null;
  };
  review: string;
  payrollNotes: string;
  notes?: string;
  hidePayrollNotes?: boolean;
  showNotes?: boolean;
  isCaptainAutoClock?: boolean;
  toRequireCheckInWithMobile?: boolean;
  logo?: string | null;
  allowClockIn?: boolean;
  allowClockOut?: boolean;
}

type ClockTypes = 'startTime' | 'endTime' | 'checkInTime' | 'checkOutTime';
export const EmployeeCollapsible: FC<Props> = ({
  employee,
  startTime,
  endTime,
  checkInTime,
  checkOutTime,
  rating,
  onUpdateTime,
  onUpdateSlot,
  shift,
  review,
  payrollNotes,
  hidePayrollNotes,
  notes,
  showNotes = true,
  isCaptainAutoClock = false,
  toRequireCheckInWithMobile = false,
  allowClockIn = true,
  allowClockOut = true,
  logo,
}) => {
  const { t } = useTranslation();
  const [timeChangeModalName, setTimeChangeModalName] =
    useState<ClockTypes | null>(null);
  const [timeState, setTimeState] = useState({
    startTime,
    endTime,
    checkInTime,
    checkOutTime,
  });
  const { colors } = useTheme();
  const { data: userData } = useUser();

  useEffect(() => {
    setTimeState({ startTime, endTime, checkInTime, checkOutTime });
  }, [checkInTime, checkOutTime, endTime, startTime]);

  const call = useCallback(() => {
    if (!employee?.phone) {
      Alert.alert(t('contactHasNoPhone'));
      return;
    }
    const url = `tel:${`+${employee?.phone}`}`;
    if (!Linking.canOpenURL(url)) {
      Alert.alert('Can not call from this device');
      return;
    }
    Linking.openURL(url);
  }, [employee?.phone, t]);

  const openSms = useCallback(() => {
    if (!employee?.phone) {
      Alert.alert(t('contactHasNoPhone'));
      return;
    }
    const url = `sms:${employee?.phone}`;
    if (!Linking.canOpenURL(url)) {
      Alert.alert('Can not send SMS from this device');
      return;
    }
    Linking.openURL(url);
  }, [employee?.phone, t]);

  const shiftTimes = useMemo(
    () => ({
      startTime: shift?.startTime,
      endTime: shift?.endTime,
    }),
    [shift?.endTime, shift?.startTime]
  );

  const employeeName = useMemo(
    () => `${employee?.firstName || ''} ${employee?.lastName || ''}`,
    [employee?.firstName, employee?.lastName]
  );

  const handleCloseStartModal = useCallback(() => {
    setTimeChangeModalName(null);
  }, []);

  const nextReportValue = useMemo(() => {
    if (allowClockIn && !timeState.startTime) {
      return 'startTime';
    }
    if (toRequireCheckInWithMobile) {
      if (!timeState.checkInTime) {
        return 'checkInTime';
      }
      if (!timeState.checkOutTime || !allowClockOut) {
        return 'checkOutTime';
      }
    }
    return 'endTime';
  }, [
    allowClockIn,
    allowClockOut,
    timeState.checkInTime,
    timeState.checkOutTime,
    timeState.startTime,
    toRequireCheckInWithMobile,
  ]) as ClockTypes;

  const openTimeChangeModalWithNextReport = useCallback(
    () => setTimeChangeModalName(nextReportValue),
    [nextReportValue]
  );

  const captainAutoClock = useCallback(async () => {
    const res = await uiAlert({
      title:
        nextReportValue === 'startTime'
          ? t('captainAutoClockAreYouSureClockIn', { employeeName })
          : nextReportValue === 'checkInTime'
          ? t('captainAutoClockAreYouSureCheckIn', { employeeName })
          : nextReportValue === 'checkOutTime'
          ? t('captainAutoClockAreYouSureCheckOut', { employeeName })
          : t('captainAutoClockAreYouSureClockOut', { employeeName }),

      message: '',
      cancel: {
        text: t('no'),
      },
      save: {
        text: t('yes'),
      },
    });

    if (!res) {
      return;
    }

    setTimeState({
      ...timeState,
      [nextReportValue]: new Date(),
    });
    onUpdateTime({
      name: nextReportValue,
      values: { ...timeState, [nextReportValue]: new Date() },
    });
  }, [employeeName, nextReportValue, onUpdateTime, t, timeState]);

  if (!employee) {
    return null;
  }

  const Trigger = React.memo(({ isCollapsed }) => (
    <TriggerContainer>
      <TriggerHeader>
        <FlexRowBetween>
          {Platform.OS === 'web' ? null : userData?.id === employee.userId ? (
            <Space />
          ) : isCollapsed ? (
            <ChevronDown />
          ) : (
            <ChevronUp />
          )}
          <UserImage
            size={30}
            firstName={employee?.firstName}
            lastName={employee?.lastName}
            image={employee?.image}
          />

          <EmployeeName>{employeeName}</EmployeeName>
        </FlexRowBetween>
        <Details key={employee?.id}>
          <FormContainer>
            {isCaptainAutoClock ? (
              <Button
                textStyle={{
                  fontWeight: 'normal',
                  textDecorationStyle: 'solid',
                  letterSpacing: 0.2,
                }}
                fontSize={16}
                paddingHorizontal={10}
                paddingVertical={5}
                radius={15}
                type="transparent"
                onPress={captainAutoClock}
                title={t(clockApiKeyToSlug(nextReportValue))}
                lowercase
              />
            ) : (
              <EditContainer
                as={Pressable}
                onPress={openTimeChangeModalWithNextReport}
              >
                <SvgIcon xml={icons.actionIcons.edit} height={15} width={15} />
              </EditContainer>
            )}
          </FormContainer>
        </Details>
      </TriggerHeader>
      <AutoTimeClock>
        <AutoTimeClockHours
          as={Pressable}
          onPress={
            isCaptainAutoClock ? () => {} : openTimeChangeModalWithNextReport
          }
        >
          <TimeItem time={timeState?.startTime} slug="clockIn" />
          {toRequireCheckInWithMobile && (
            <>
              <TimeItem time={timeState?.checkInTime} slug="timesheetCheckIn" />
              <TimeItem
                time={timeState?.checkOutTime}
                slug="timesheetCheckOut"
              />
            </>
          )}
          <TimeItem time={timeState?.endTime} slug="clockOut" />
        </AutoTimeClockHours>
      </AutoTimeClock>
    </TriggerContainer>
  ));

  return (
    <>
      <BaseCollapsible trigger={Trigger} hideRightChevron>
        <Form
          keepDirtyOnReinitialize
          onSubmit={() => {}}
          initialValues={{
            rating,
            review,
            payrollNotes,
            notes,
          }}
          render={() => {
            if (Platform.OS === 'web') {
              // currently not working well on web
              return null;
            }
            if (employee?.userId === userData?.id) {
              return null;
            }
            return (
              <Container>
                <FormSpy
                  subscription={{ values: true, dirtyFields: true }}
                  onChange={onUpdateSlot}
                />
                <RateShift>
                  {t('rate')} {employee?.firstName}
                </RateShift>
                <StarContainer>
                  <StarRatingField
                    component={StarRating}
                    fieldProps={{
                      name: 'rating',
                    }}
                    props={{}}
                  />
                </StarContainer>
                <InputContainer>
                  {showNotes && (
                    <InputField
                      fieldProps={{
                        name: 'notes',
                      }}
                      props={{
                        placeholder: t('bookingNotes'),
                        style: inputStyle(colors),
                        placeholderTextColor: colors.gray2,
                      }}
                      component={TextInput}
                    />
                  )}
                  <InputField
                    fieldProps={{
                      name: 'review',
                    }}
                    props={{
                      placeholder: t('review'),
                      style: inputStyle(colors),
                      placeholderTextColor: colors.gray2,
                    }}
                    component={TextInput}
                  />
                  {!hidePayrollNotes && (
                    <InputField
                      fieldProps={{
                        name: 'payrollNotes',
                      }}
                      component={TextInput}
                      props={{
                        placeholder: t('payrollNotes'),
                        placeholderTextColor: colors.gray2,
                        style: inputStyle(colors),
                      }}
                    />
                  )}
                </InputContainer>
                {employee?.phone && (
                  <ButtonContainer>
                    <Button
                      textStyle={{
                        fontWeight: 'normal',
                        textDecorationLine: 'underline',
                        textDecorationStyle: 'solid',
                        letterSpacing: 0.2,
                      }}
                      fontSize={16}
                      paddingHorizontal={0}
                      type="transparent"
                      onPress={openSms}
                      title={t('message')}
                    />

                    <Button
                      textStyle={{
                        fontWeight: 'normal',
                        textDecorationLine: 'underline',
                        textDecorationStyle: 'solid',
                        letterSpacing: 0.2,
                      }}
                      fontSize={16}
                      paddingHorizontal={0}
                      type="transparent"
                      onPress={call}
                      title={t('call')}
                    />

                    <BaseView />
                  </ButtonContainer>
                )}
              </Container>
            );
          }}
        />
      </BaseCollapsible>
      <TimeChangeModal
        isOpen={!!timeChangeModalName}
        values={timeState}
        onStateChange={setTimeState}
        shiftTimes={shiftTimes}
        onTimeChange={onUpdateTime}
        onClose={handleCloseStartModal}
        employeeName={employeeName}
        logo={logo}
        name={timeChangeModalName}
        employeePhoto={employee.image}
        position={shift.position}
        projectLocation={shift.projectLocation}
        shiftLocation={shift.shiftLocation}
        showCheckIn={toRequireCheckInWithMobile}
        showClockIn={allowClockIn}
        showClockOut={allowClockOut}
      />
    </>
  );
};
