import React, { FC, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { CustomTimesheetDTO, useBranches } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { BookingActionCard } from '../../bookings/components';
import { BookingsScreenNames } from '../../bookings/navigation/ScreenNames';
import { SequencesScreenNames } from '../../sequences';
import { TimesheetsScreenNames } from '../navigation';
import moment from 'moment';

const Container = styled(BaseView)`
  width: 100%;
  height: 100%;
`;

interface Props {
  timesheet: CustomTimesheetDTO;
}

export const TimesheetActions: FC<Props> = ({ timesheet }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { mappedBranches } = useBranches();

  const config = useMemo(
    () => mappedBranches?.[timesheet?.branchId]?.schedulingConfig,
    [timesheet, mappedBranches]
  );
  const timesheetConfig = useMemo(
    () => mappedBranches?.[timesheet?.branchId]?.timesheetConfig,
    [timesheet, mappedBranches]
  );

  return (
    <Container>
      {!!timesheetConfig?.isAbleEditTimesheet && (
        <BookingActionCard
          onPress={() =>
            navigate(TimesheetsScreenNames.MAIN, {
              screen: TimesheetsScreenNames.EDIT_TIMESHEET,
              params: { timesheet },
            })
          }
          icon={{
            xml: icons.uiIcons.clock2,
            width: 20,
            height: 20,
          }}
          title={t('editTimesheet')}
        />
      )}
      {!!timesheet?.employeeSlot?.captainType && (
        <BookingActionCard
          onPress={() =>
            navigate(BookingsScreenNames.MAIN, {
              screen: BookingsScreenNames.CAPTAIN,
              params: {
                bookingId: timesheet?.shift?.project.id,
                shiftDate: timesheet!.shift!.startTime
                  ? moment(timesheet!.shift!.startTime).toDate()
                  : moment(timesheet!.shift!.project.date).toDate(),
              },
            })
          }
          icon={{
            xml: icons.uiIcons.admin,
            width: 20,
            height: 20,
          }}
          title={t('captain')}
        />
      )}
      {(timesheet?.sequences || []).map(({ id, instances, title }) =>
        (instances || []).map(({ id: instanceId, hasAnswered }) => (
          <BookingActionCard
            onPress={() =>
              navigate(SequencesScreenNames.MAIN, {
                screen: SequencesScreenNames.SEQUENCE,
                params: {
                  sequenceId: id,
                  instanceId,
                  employeeSlotId: timesheet?.employeeSlotId,
                },
              })
            }
            icon={{
              xml: icons.navIcons.feedLine,
              width: 20,
              height: 20,
            }}
            title={title}
            suffixIcon={
              hasAnswered === true
                ? {
                    xml: icons.actionIcons.done,
                    width: 20,
                    height: 20,
                    color: '#008000',
                  }
                : undefined
            }
          />
        ))
      )}

      {config?.showTeam && timesheet?.shift?.project?.id && (
        <BookingActionCard
          onPress={() =>
            navigate(BookingsScreenNames.MAIN, {
              screen: BookingsScreenNames.TEAM,
              params: {
                projectId: timesheet!.shift!.project.id,
                date: timesheet!.shift!.startTime
                  ? moment(timesheet!.shift!.startTime).toDate()
                  : moment(timesheet!.shift!.project.date).toDate(),
              },
            })
          }
          icon={{
            xml: icons.uiIcons.team,
            width: 20,
            height: 20,
          }}
          title={t('team')}
        />
      )}

      {timesheet?.shift?.project?.id && (
        <BookingActionCard
          onPress={() =>
            navigate(BookingsScreenNames.MAIN, {
              screen: BookingsScreenNames.PROJECT_FEED,
              params: { projectId: timesheet?.shift?.project?.id },
            })
          }
          icon={{
            xml: icons.navIcons.feedLine,
            width: 20,
            height: 20,
          }}
          title={t('projectFeed')}
        />
      )}
    </Container>
  );
};
