import React from 'react';
import Config from 'react-native-config';
import { lotties } from '@violetta/ubeya/assets';
import { InferProps as PropsOf } from '@violetta/ubeya/utils';
import { ActivityIndicator, Platform, useWindowDimensions } from 'react-native';
import styled from 'styled-components/native';
import { Lottie } from '../Lotties';
import { useTheme } from '@react-navigation/native';

interface Props {
  size?: number;
  width?: number;
  height?: number;
}

const LoaderWrapper = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const MainLoader = () => {
  const { height, width } = useWindowDimensions();
  const { colors } = useTheme();
  return (
    <LoaderWrapper>
      {Platform.OS === 'web' && (
        <ActivityIndicator size="small" color="#C5C5C5" />
      )}
      {Platform.OS !== 'web' ? (
        Config.APP_NAME === 'Constellation' ? (
          <ActivityIndicator color={colors.primary} size="large" />
        ) : (
          <Lottie
            name={lotties.mainLoader}
            autoPlay
            width={350}
            height={350}
            resizeMode="cover"
          />
        )
      ) : null}
    </LoaderWrapper>
  );
};
