import React, { FC, PureComponent, useCallback, useState } from 'react';
import { View } from 'react-native';

interface Props {
  start: {
    x: number;
    y: number;
  };
  end: {
    x: number;
    y: number;
  };
  locations: any[];
  colors: any[];
  useAngle: boolean;
  angle: number;
  onLayout?: (event: any) => void;
  style: object;
  angleCenter: number;
}

export const LinearGradient: FC<Props> = ({
  onLayout,
  start,
  end,
  colors,
  locations,
  useAngle,
  angleCenter,
  angle,
  style,
  children,
  ...otherProps
}) => {
  const [stats, setStats] = useState<{ width: number; height: number }>({ width: 1, height: 1 });
  const measure = useCallback(
    (event: any) => {
      setStats({
        width: event.nativeEvent.layout.width,
        height: event.nativeEvent.layout.height,
      });
      onLayout?.(event);
    },
    [onLayout, setStats],
  );

  const getColors = useCallback(
    () =>
      colors
        .map((color, index) => {
          const location = locations[index];
          let locationStyle = '';
          if (location) {
            locationStyle = ' ' + location * 100 + '%';
          }
          return color + locationStyle;
        })
        .join(','),
    [],
  );

  const getAngle = useCallback(() => {
    if (useAngle) {
      return angle + 'deg';
    }

    // Math.atan2 handles Infinity
    const newAngle = Math.atan2(stats.width * (end.y - start.y), stats.height * (end.x - start.x)) + Math.PI / 2;
    return newAngle + 'rad';
  }, []);

  return (
    <View
      {...otherProps}
      style={[style, { backgroundImage: `linear-gradient(${getAngle()},${getColors()})` }]}
      onLayout={measure}>
      {children}
    </View>
  );
};
