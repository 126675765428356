import React, { FC } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import { FlexRowBetween, H5Light } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const Container = styled(FlexRowBetween)`
  padding: 20px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
`;

const Title = styled(H5Light)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #1e2534;
`;

interface Props {}

export const OffersFilterModalHeader: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('filter')}</Title>
      {/* <Pressable onPress={() => {}}>
        <ChevronRight rtl={rtl} size={35} />
      </Pressable> */}
    </Container>
  );
};
