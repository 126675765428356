import { useProjectLiveView } from '@violetta/ubeya/admin-entities';
import { projectLiveViewFilters } from '@violetta/ubeya/shared';
import { useRecoilState } from 'recoil';
import LiveViewContent from './LiveViewContent';
import { Modalize } from 'react-native-modalize';
import { icons } from '@violetta/ubeya/assets';

const ProjectLiveView = ({
  projectId,
  branchId,
  title,
  filterModalizeRef,
  renderBottom,
  timeFormat,
  dateFormat,
}: {
  projectId: number;
  branchId: number;
  title?: any;
  renderBottom?: any;
  filterModalizeRef: React.RefObject<Modalize>;
  timeFormat: string;
  dateFormat: string;
}) => {
  const [filters, setFilters] = useRecoilState(projectLiveViewFilters);

  const {
    isLoading,
    data: { stats = {}, shiftsInfo = {} },
  } = useProjectLiveView({ projectId, branchId, filters });

  return (
    <LiveViewContent
      filterModalizeRef={filterModalizeRef}
      stats={stats}
      shiftsInfo={shiftsInfo}
      title={title}
      filters={filters}
      setFilters={setFilters}
      timeFormat={timeFormat}
      dateFormat={dateFormat}
      isLoading={isLoading}
      isCollpasible
      renderBottom={renderBottom}
      isCollpased={stats.scheduled === 0}
      showEmptyState={stats.scheduled === 0}
      emptyStateIcon={icons?.emptyStates?.projectLiveViewEmptyState}
    />
  );
};

export default ProjectLiveView;
