import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { lotties } from '@violetta/ubeya/assets';
import { useAccounts, useWeeklySchedule } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  ChevronLeft,
  ChevronRight,
  EmptyState,
  H3Medium,
  H3Regular,
  H6Medium,
  H5Light,
  BaseText,
  RipplePressable,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { Pressable, RefreshControl, SectionList } from 'react-native';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import {
  searchStateAtom,
  weeklyScheduleSelectedWeekAtom,
} from '../../../state';
import { API_DATE_TIME_FORMAT, DAYS } from '../../main/constants';
import { WeeklyScheduleListRow } from '../components/WeeklyScheduleListRow';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { AppContext } from '../../main/AppContext';

const Container = styled(BaseView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const Separator = styled(BaseView)`
  border-bottom-width: 1px;
  border-bottom-color: #eaeaea;
  margin: 0 16px;
`;

const DayStrip = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.primaryLight2};
  padding: 8px 16px;
`;
const DayTitle = styled(H6Medium)`
  font-weight: 500;
`;

const Title = styled(H3Medium)`
  font-weight: 300;
  margin: 0 20px;
`;

const HeaderContainer = styled(BaseView)`
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`;

const TitleContainer = styled(BaseView)`
  align-items: center;
`;

const Subtitle = styled(H5Light)`
  margin-top: 4px;
  font-size: 15px;
  font-weight: 400;
`;

const DayBoxContainer = styled(HeaderContainer)`
  justify-content: space-between;
  padding: 0 16px;
  flex-wrap: wrap;
`;

const DayBox = styled(RipplePressable)`
  width: 40px;
  height: 40px;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.gray7 : theme.colors.primary};
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.primary : theme.colors.white};
`;

const DayText = styled(BaseText)`
  font-size: 12px;
  color: ${({ selected, theme, disabled }) =>
    disabled
      ? theme.colors.gray7
      : selected
      ? theme.colors.white
      : theme.colors.primary};
`;

export const WeeklyScheduleScreen: FC = () => {
  const [selectedWeek, setSelectedWeek] = useRecoilState(
    weeklyScheduleSelectedWeekAtom
  );
  const [searchState, setSearchState] = useRecoilState(searchStateAtom);
  const [selectedDay, setSelectedDay] = useState(null);
  const sectionListRef = useRef<SectionList>(null);
  const { bottom } = useSafeAreaInsets();
  const {
    profile: { selectedAccount },
  } = useContext(AppContext);

  const {
    isLoading,
    refetchSchedule,
    mappedScheduleByDayAndLabel,
    activeDays,
  } = useWeeklySchedule({
    accountId: selectedAccount?.id,
    from: moment(selectedWeek.date).format(API_DATE_TIME_FORMAT),
    to: moment(selectedWeek.date).add(6, 'day').format(API_DATE_TIME_FORMAT),
  });

  const { t } = useTranslation();
  const { rtl } = useContext(ConfigContext);

  const filteredSchedule = useMemo(() => {
    const tempArray = mappedScheduleByDayAndLabel?.map((scheduleDay) => ({
      title: scheduleDay.title,
      data:
        searchState.searchKey.length > 0
          ? scheduleDay?.data?.map(({ title, data }) => ({
              title,
              data: data.filter(
                ({ firstName, lastName }) =>
                  `${firstName} ${lastName}`.includes(searchState.searchKey) ||
                  `${firstName} ${lastName}`
                    .toLowerCase()
                    .includes(searchState.searchKey) ||
                  `${firstName} ${lastName}`
                    .toUpperCase()
                    .includes(searchState.searchKey)
              ),
            }))
          : scheduleDay?.data,
    }));
    return tempArray.every(
      (item) => item.data.filter((piece) => piece.data.length > 0).length === 0
    )
      ? []
      : tempArray;
  }, [mappedScheduleByDayAndLabel, searchState.searchKey]);

  const randomWeek = Array(7)
    .fill(0)
    .map((v, index) => moment().weekday(index));
  const tempDays = randomWeek.map((weekDay, index) => {
    const momentedDay = moment(weekDay);

    return {
      day: momentedDay.format('ddd'),
      title: momentedDay.format('dddd'),
      id: index,
      dow: momentedDay.day(),
    };
  });

  useEffect(
    () => () => {
      setSearchState({ isSearchOpen: false, searchKey: '' });
    },
    [setSearchState]
  );

  const scrollToIndex = useCallback(
    (index) => {
      const sectionIndex = index + (1 - moment().localeData().firstDayOfWeek());
      if (sectionIndex <= mappedScheduleByDayAndLabel.length - 1) {
        sectionListRef?.current?.scrollToLocation({
          sectionIndex,
          itemIndex: 0,
        });
      }
    },
    [mappedScheduleByDayAndLabel.length]
  );

  const onPrev = useCallback(() => {
    setSelectedDay(null);
    setSelectedWeek(({ date }) => ({
      date: moment(date).subtract(1, 'week').toDate(),
    }));
  }, [setSelectedWeek]);
  const onNext = useCallback(() => {
    setSelectedDay(null);
    setSelectedWeek(({ date }) => ({
      date: moment(date).add(1, 'week').toDate(),
    }));
  }, [setSelectedWeek]);

  const dayMonthFormat = useMemo(() => {
    const currentFormat = moment.localeData().longDateFormat('L');

    // Check locale format and strip year from the first part
    const format = currentFormat.match(/Y/g)
      ? currentFormat.replace(/\/YYYY/, '')
      : currentFormat;

    return format.trim();
  }, []);

  const renderSectionHeader = useCallback(
    ({ section: { title, data } }) => {
      const shouldShow = data.filter((piece) => piece.data.length > 0);
      return shouldShow.length > 0 ? (
        <DayStrip key={title}>
          <DayTitle>{`${moment(title)
            .format(`ddd ${dayMonthFormat}`)
            .toUpperCase()} (${data.length})`}</DayTitle>
        </DayStrip>
      ) : (
        <BaseView key={title} />
      );
    },
    [dayMonthFormat]
  );

  const weeksDiff = useMemo(
    () => moment(selectedWeek.date).diff(moment().startOf('week'), 'week'),
    [selectedWeek.date]
  );

  const handleSelectDay = useCallback(
    ({ dow }) => {
      if (dow !== selectedDay) {
        setSelectedDay(dow);
        scrollToIndex(dow - 1);
      } else {
        setSelectedDay(null);
      }
    },
    [scrollToIndex, selectedDay]
  );

  return (
    <Container>
      <HeaderContainer>
        <Pressable onPress={onPrev}>
          <ChevronLeft rtl={rtl} />
        </Pressable>

        <TitleContainer>
          <Title>
            {moment(selectedWeek.date).format(dayMonthFormat)} -{' '}
            {moment(selectedWeek.date).add(6, 'day').format(dayMonthFormat)}
          </Title>
          <Subtitle>
            {weeksDiff === 0
              ? t('thisWeek')
              : weeksDiff === 1
              ? t('nextWeek')
              : weeksDiff === -1
              ? t('lastWeek')
              : weeksDiff < 0
              ? `${t('xWeeksAgo', { weeks: weeksDiff * -1 })}`
              : `${t('xWeeksAfter', { weeks: weeksDiff })}`}
          </Subtitle>
        </TitleContainer>

        <Pressable onPress={onNext}>
          <ChevronRight rtl={rtl} />
        </Pressable>
      </HeaderContainer>
      {activeDays?.length > 0 && (
        <DayBoxContainer>
          {tempDays.map((day) => (
            <DayBox
              key={day.dow}
              onPress={() => handleSelectDay({ dow: day.dow })}
              disabled={!activeDays?.includes(day.dow)}
              selected={selectedDay === day.dow}
            >
              <DayText
                disabled={!activeDays?.includes(day.dow)}
                selected={selectedDay === day.dow}
              >
                {day.day.toUpperCase()}
              </DayText>
            </DayBox>
          ))}
        </DayBoxContainer>
      )}
      <SectionList
        contentContainerStyle={{ flexGrow: 1 }}
        sections={filteredSchedule}
        refreshing={isLoading}
        contentInset={{ bottom }}
        ref={sectionListRef}
        ListEmptyComponent={
          <EmptyState
            hide={isLoading}
            lottieSrc={lotties.noUpcomingShifts}
            title={t('emptyStateBookings')}
          />
        }
        refreshControl={
          <RefreshControl onRefresh={refetchSchedule} refreshing={isLoading} />
        }
        keyExtractor={({ data }) =>
          data
            .map((slot) =>
              [
                slot?.startTime,
                slot?.endTime,
                slot?.firstName,
                slot?.lastName,
                slot?.position?.id,
              ].join(',')
            )
            .join(',')
        }
        renderItem={({ item }) => (
          <WeeklyScheduleListRow
            key={item?.data?.map((slot) => slot?.id).join(',')}
            items={item}
          />
        )}
        ItemSeparatorComponent={null}
        renderSectionHeader={renderSectionHeader}
      />
    </Container>
  );
};
