import React, { FC, useContext, useMemo } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { usePeriods } from '@violetta/ubeya/entities';
import {
  ChevronLeft,
  ChevronRight,
  FlexRowBetween,
  H3Medium,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../contexts';
import { AppContext } from '../../../main/AppContext';

const Container = styled(FlexRowBetween)`
  flex: 1;
`;

const Title = styled(H3Medium)`
  font-weight: 500;
  margin: 0 20px;
`;

export const AvailabilityHeaderTitle: FC<StackHeaderProps> = () => {
  const {
    availability: { periodId, onNext, onPrev },
  } = useContext(AppContext);
  const { rtl } = useContext(ConfigContext);

  const { mappedPeriods, periods } = usePeriods();
  const period = mappedPeriods[periodId];

  const branchPeriods = useMemo(
    () => periods.filter(({ branchId }) => period?.branchId === branchId),
    [period?.branchId, periods]
  );

  const [startDate, endDate, showPrev, showNext] = useMemo(() => {
    const startDate = moment(period?.dates[0].date);
    const endDate = moment(period?.dates[period?.dates.length - 1].date);

    const idx = branchPeriods.findIndex(({ id }) => id === period?.id);

    const showPrev = idx !== 0;
    const showNext = idx !== branchPeriods.length - 1;

    return [startDate, endDate, showPrev, showNext] as const;
  }, [period, branchPeriods]);

  return (
    <Container>
      {showPrev && (
        <Pressable onPress={onPrev}>
          <ChevronLeft rtl={rtl} />
        </Pressable>
      )}
      <Title>
        {startDate.format('DD MMM')}-{endDate.format('DD MMM')}
      </Title>
      {showNext && (
        <Pressable onPress={onNext}>
          <ChevronRight rtl={rtl} />
        </Pressable>
      )}
    </Container>
  );
};
