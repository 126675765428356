import { useCallback, useState } from 'react';

interface Props {
  defaultValue?: boolean;
}

export const useLoader = ({ defaultValue = false }: Props = {}) => {
  const [loading, setLoading] = useState(defaultValue);

  const show = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const hide = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const toggle = useCallback(() => {
    setLoading((loading) => !loading);
  }, [setLoading]);

  return {
    isLoading: loading,
    show,
    hide,
    toggle,
  };
};
