import React, { FC } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { Pressable, PressableProps } from 'react-native';
import styled from 'styled-components/native';
import { BaseView } from '../Layout/Layout';
import { SvgIcon } from './SvgIcon';

const PressedXIcon = styled(BaseView)``;

const NotPressedXIcon = styled(BaseView)``;

interface Props {
  onPress?: () => void;
  pressed?: boolean;
}

type XIconProps = Props & PressableProps;

export const XIcon: FC<XIconProps> = ({ onPress, pressed = false, ...props }) =>
  !pressed ? (
    <NotPressedXIcon
      as={onPress ? Pressable : undefined}
      onPress={onPress}
      {...props}
    >
      <SvgIcon
        xml={icons.symbolIcons.notAvailableWhite}
        width={35}
        height={35}
      />
    </NotPressedXIcon>
  ) : (
    <PressedXIcon
      as={onPress ? Pressable : undefined}
      onPress={onPress}
      {...props}
    >
      <SvgIcon xml={icons.symbolIcons.notAvailable} width={35} height={35} />
    </PressedXIcon>
  );
