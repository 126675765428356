import React, { FC, useMemo } from 'react';
import * as native from '@react-navigation/native';
import { icons, lotties } from '@violetta/ubeya/assets';
import { usePosts, useLocations, feedTitle } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  FlexColumnDirection,
  H1Regular,
  SvgIcon,
  WideFlexColumnCenter,
  CustomLoaderWrapped,
  BaseView,
  EmptyState,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { FlatList } from 'react-native';
import styled from 'styled-components/native';
import { Post } from '../../feed/screens/feed/components';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.gray4};
`;

type ParamList = {
  params: {
    projectId: number;
  };
};

export const ProjectFeedScreen: FC = () => {
  const {
    params: { projectId },
  } = native.useRoute<native.RouteProp<ParamList, 'params'>>();

  const { mappedLocations } = useLocations();
  const { t } = useTranslation();

  const {
    isLoading,
    posts,
    employees,
    fetchMore,
    refetch,
    createPollAnswer,
    deletePollAnswer,
    createLike,
    deleteLike,
  } = usePosts({ projectId });

  const data = useMemo(
    () =>
      posts.map((props) => ({
        ...props,
        title: props.projects?.[0]
          ? feedTitle(props.projects?.[0], mappedLocations)
          : '',
      })),
    [mappedLocations, posts]
  );

  return (
    <Container>
      <FlatList
        contentContainerStyle={{ flexGrow: 1 }}
        ListEmptyComponent={
          !isLoading ? (
            <EmptyState lottieSrc={lotties.noFeedData} title={t('noPosts')} />
          ) : (
            <BaseView />
          )
        }
        data={data}
        refreshing={isLoading}
        onRefresh={refetch}
        onEndReachedThreshold={1}
        onEndReached={() => fetchMore()}
        keyExtractor={({ id }) => id.toString()}
        renderItem={({ item }) => (
          <Post
            key={item.id}
            post={item}
            employees={employees}
            createPollAnswer={createPollAnswer}
            deletePollAnswer={deletePollAnswer}
            createLike={createLike}
            deleteLike={deleteLike}
            title={item.title}
          />
        )}
      />
    </Container>
  );
};
