import { RouteProp, useRoute } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { useLabels, useTeam } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FlexColumnDirection,
  FlexRow,
  FlexRowBetween,
  FlexRowCenter,
  H5Regular,
  RipplePressable,
  SvgIcon,
  UserImage,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import moment from 'moment';
import React, { FC, useCallback, useMemo } from 'react';
import { Linking, RefreshControl } from 'react-native';
import { Alert } from '@violetta/ubeya/alert';
import { ScrollView } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { ShiftHeaderComponent } from './components';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.background};
`;

const CarContainer = styled(BaseView)`
  margin-left: 10px;
`;
const LabelContainer = styled(BaseView)`
  display: flex;
  align-items: flex-start;
  background-color: ${({ background, theme }) =>
    background || theme.colors.white};
  justify-content: flex-start;
`;
const Label = styled(H5Regular)`
  margin: 10px;
  border-radius: 4px;
  padding: 2px 5px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: center;
  color: ${({ color, theme }) => color || theme.colors.textPrimary};
`;
const ShiftContainer = styled(FlexColumnDirection)`
  margin-bottom: 25px;
`;

const PaddedContainer = styled(BaseView)<{ $withImage: boolean }>`
  padding: 0 16px;
  z-index: 10;
  margin-top: ${({ $withImage }) => ($withImage ? '-100px' : '0')};
`;

const EmployeeName = styled(H5Regular)`
  color: black;
  margin-left: 15px;
`;

const EmployeesContainer = styled(FlexColumnDirection)``;

const EmployeeContainer = styled(FlexRowBetween)`
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray11};
  padding: 12px 0;
`;

const RightEmployeeContainer = styled(FlexRowCenter)`
  margin: 0 5px;
`;
const LeftEmployeeContainer = styled(FlexRow)`
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-start;
`;

interface Props {}

type ParamList = {
  params: {
    projectId: number;
    date: Date;
  };
};

export const TeamScreen: FC<Props> = () => {
  const {
    params: { projectId, date },
  } = useRoute<RouteProp<ParamList, 'params'>>();

  const { t } = useTranslation();
  const { colors } = useTheme();

  const { team, mappedEmployees, isLoading, refetch } = useTeam({
    projectId,
    date,
  });

  const { mappedLabels } = useLabels();

  const call = useCallback(
    ({ phone }) => {
      if (!phone) {
        Alert.alert(t('contactHasNoPhone'));
        return;
      }
      const url = `tel:${phone}`;
      if (!Linking.canOpenURL(url)) {
        Alert.alert('Can not call from this device');
        return;
      }
      Linking.openURL(url);
    },
    [t]
  );

  const shifts = useMemo(
    () =>
      (team?.shifts || [])
        .filter(({ employees }) => employees.length > 0)
        .sort((shift1, shift2) =>
          moment(shift1?.startTime).isBefore(moment(shift2?.startTime)) ? 1 : -1
        ),
    [team]
  );

  const label = useMemo(
    () =>
      team?.project?.labelId ? mappedLabels[team?.project?.labelId] : undefined,
    [team, mappedLabels]
  );

  return (
    <ScrollView
      refreshControl={
        <RefreshControl onRefresh={refetch} refreshing={isLoading} />
      }
      style={{ backgroundColor: colors.background }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <Container>
        {label && (
          <LabelContainer background={label?.background}>
            <Label color={label?.color}>{label.title}</Label>
          </LabelContainer>
        )}
        <PaddedContainer>
          {shifts?.map((shift, idx) => {
            const allShiftSlotTimes = [
              ...new Set(
                shift?.employeesData?.map((e) =>
                  rangeFormat(e.slot?.startTime, e.slot?.endTime)
                )
              ),
            ];
            const employeesBySlotTime = {};
            allShiftSlotTimes.forEach((time) => {
              employeesBySlotTime[time] = shift.employeesData.filter(
                (e) => rangeFormat(e.slot?.startTime, e.slot?.endTime) === time
              );
            });
            return (
              <ShiftContainer key={idx}>
                <EmployeesContainer>
                  {allShiftSlotTimes.map((time) => {
                    const currEmployees = employeesBySlotTime[time];
                    currEmployees.sort(
                      (
                        { employeeId: employeeIdA },
                        { employeeId: employeeIdB }
                      ) =>
                        `${mappedEmployees[employeeIdA]?.firstName}${mappedEmployees[employeeIdA]?.lastName}`.localeCompare(
                          `${mappedEmployees[employeeIdB]?.firstName}${mappedEmployees[employeeIdB]?.lastName}`
                        )
                    );
                    return (
                      <React.Fragment key={time}>
                        <ShiftHeaderComponent
                          shift={{
                            startTime: currEmployees?.[0]?.slot?.startTime,
                            endTime: currEmployees?.[0]?.slot?.endTime,
                            positionId: shift?.positionId,
                            comments: shift?.comments,
                            employees: currEmployees,
                          }}
                        />
                        {(currEmployees || []).map(
                          ({ id, captainType, hasCar }) => (
                            <EmployeeContainer
                              key={id}
                              as={RipplePressable}
                              onPress={() =>
                                call({ phone: mappedEmployees[id]?.phone })
                              }
                            >
                              <UserImage
                                size={30}
                                firstName={mappedEmployees[id]?.firstName}
                                lastName={mappedEmployees[id]?.lastName}
                                image={mappedEmployees[id]?.image}
                              />
                              <LeftEmployeeContainer>
                                <EmployeeName>
                                  {mappedEmployees[id]?.firstName}{' '}
                                  {mappedEmployees[id]?.lastName}
                                </EmployeeName>
                                {!!captainType && (
                                  <EmployeeName>
                                    {t('shiftCaptain')}
                                  </EmployeeName>
                                )}
                                {hasCar && (
                                  <CarContainer>
                                    <SvgIcon
                                      xml={icons.uiIcons.carIcon}
                                      width={20}
                                      height={20}
                                    />
                                  </CarContainer>
                                )}
                              </LeftEmployeeContainer>
                              {!!mappedEmployees[id]?.phone && (
                                <RightEmployeeContainer>
                                  <SvgIcon
                                    xml={icons.actionIcons.phone}
                                    width={15}
                                    height={15}
                                  />
                                </RightEmployeeContainer>
                              )}
                            </EmployeeContainer>
                          )
                        )}
                      </React.Fragment>
                    );
                  })}
                </EmployeesContainer>
              </ShiftContainer>
            );
          })}
        </PaddedContainer>
      </Container>
    </ScrollView>
  );
};
