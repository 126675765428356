import React, { FC, useMemo, useState, useCallback } from 'react';
import { useAlert } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  FlexParent,
  FlexRow,
  FlexRowCenter,
  H3Medium,
  H6Medium,
  IconClose,
  Modal,
  FlexColumnDirection,
  CheckBox,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';

const ModalHeader = styled(FlexParent)`
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.surface};
  padding: 30px 15px 15px 15px;
`;

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  background-color: white;
`;

const ICON_SIZE = 22;

const H3BoldWhite = styled(H3Medium)`
  font-weight: 500;
`;

const ButtonContainer = styled(FlexRowCenter)`
  width: 100%;
  justify-content: flex-end;
  padding: 15px;
`;

const CopyToContainer = styled(FlexRow)`
  align-self: center;
  justify-content: center;
  margin: 15px 0;
`;

const CheckboxContainer = styled(BaseView)`
  margin-bottom: 20px;
`;

const WeekAvailabilitiesContainer = styled(FlexColumnDirection)`
  justify-content: center;
  margin: 15px 0;
  align-items: center;
`;

const CopyToTitle = styled(H6Medium)`
  margin-top: 15px;
  margin-right: 15px;
`;

const Strip = styled(BaseView)`
  margin: 15px 0;
  height: 1px;
  background-color: rgba(37, 37, 37, 0.1);
`;

interface Props {
  selectedWeek: Date;
  onClose: () => void;
  onCopy: (value: Number[]) => Promise<boolean>;
}

export const CopyTimeAvailabilityModal: FC<Props> = ({
  onClose,
  onCopy,
  selectedWeek,
}) => {
  const { t } = useTranslation();
  const [selectedWeeks, setSelectedWeeks] = useState<Number[]>([]);
  const [isCopying, setIsCopying] = useState<boolean>(false);

  const headerTitle = useMemo(
    () =>
      `${t('copyWeek')} - ${moment(selectedWeek).format('MM/DD/YY')} - ${moment(
        selectedWeek
      )
        .add(6, 'd')
        .format('MM/DD/YY')}`,
    [selectedWeek, t]
  );

  const nextWeeks = useMemo(
    () =>
      [1, 2, 3, 4].map((week) => ({
        id: week,
        startDate: moment(selectedWeek)
          .add(7 * week, 'd')
          .toDate(),
        endDate: moment(selectedWeek)
          .add(7 * week + 6, 'd')
          .toDate(),
      })),
    [selectedWeek]
  );

  const onWeekChange = useCallback(
    (value: boolean, weekId: number) => {
      setSelectedWeeks(
        !value
          ? selectedWeeks.filter((week) => Number(week) !== Number(weekId))
          : [...selectedWeeks, weekId]
      );
    },
    [selectedWeeks]
  );

  const WeekAvailabilities = useMemo(
    () => (
      <BaseView>
        {nextWeeks.map((week) => (
          <CheckboxContainer key={week.id}>
            <CheckBox
              title={`${moment(week.startDate).format(
                'ddd MM/DD/YY'
              )} - ${moment(week.endDate).format('ddd MM/DD/YY')}`}
              value={selectedWeeks.includes(week.id)}
              onChange={(value) => onWeekChange(value, week.id)}
            />
          </CheckboxContainer>
        ))}
      </BaseView>
    ),
    [nextWeeks, onWeekChange, selectedWeeks]
  );

  const handleOnCopyPress = useCallback(async () => {
    setIsCopying(true);
    await onCopy(selectedWeeks);
    setIsCopying(false);
    onClose();
  }, [onClose, onCopy, selectedWeeks]);

  return (
    <Modal animationType="slide">
      <SafeAreaView>
        <ModalHeader>
          <H3BoldWhite>{headerTitle}</H3BoldWhite>
          <IconClose color="black" size={ICON_SIZE} onPress={onClose} />
        </ModalHeader>

        <Strip />
        <CopyToContainer>
          <CopyToTitle>{t('selectWeeksToCopy')}</CopyToTitle>
        </CopyToContainer>
        <WeekAvailabilitiesContainer>
          {WeekAvailabilities}
        </WeekAvailabilitiesContainer>
        <Strip />
        <ButtonContainer>
          <Button
            type="primary"
            disabled={selectedWeeks.length === 0}
            onPress={handleOnCopyPress}
            title={t('copy')}
            paddingHorizontal={20}
            paddingVertical={10}
            radius={30}
            fontSize={16}
            isLoading={isCopying}
          />
        </ButtonContainer>
      </SafeAreaView>
    </Modal>
  );
};
