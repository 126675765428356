import React, { FC, memo } from 'react';
import { TouchableHighlight } from 'react-native';
import styled from 'styled-components/native';
import { BaseImage, FlexParent, H4Regular, H4Bold } from '@violetta/ubeya/ui';
import { ICountry } from '@violetta/ubeya/entities';
import { flags } from '@violetta/ubeya/assets';
import { getCountryCodeFromRegionCode } from '@violetta/ubeya/utils';

const Container = styled(FlexParent)`
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: #eee;
`;

const Flag = styled(BaseImage)`
  height: 30px;
  width: 42px;
  margin: 10px 15px;
  border-width: 1px;
  border-color: #eee;
  border-radius: 5px;
`;

const MarginedText = styled(H4Regular)`
  margin: 0 5px;
`;

interface Props {
  item: ICountry;
  index: number;
  onSelect?: (item: ICountry, index: number) => void;
}

export const CountryListItem: FC<Props> = memo(
  ({ item, index, onSelect = () => {} }) => {
    return (
      <TouchableHighlight
        onPress={() => onSelect(item, index)}
        activeOpacity={0.5}
        underlayColor={'#fff'}
      >
        <Container>
          <Flag source={flags[item.code.toUpperCase()]} />
          <MarginedText>{item.name}</MarginedText>
          <MarginedText>
            (<H4Bold>+{getCountryCodeFromRegionCode(item.code)}</H4Bold>)
          </MarginedText>
        </Container>
      </TouchableHighlight>
    );
  }
);
