export enum TimeclockValidationStatus {
  CLOCKIN_TOO_EARLY,
  CLOCKOUT_TOO_LATE,
  CLOCKOUT_TOO_EARLY,
  TOO_FAR,
  NO_LOCATION_AVAILABLE,
  LOCATION_PERMISSION_DENIED,
  LOCATION_TIMEOUT,
  LOCATION_UNKNOWN,
  OK,
  QR_CODE_NOT_VALID,
}
