import { useCallback, useRef, useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { generateChatId } from '../utils/generateChatId';
import { getUserSocket, removeUserSocket } from '../utils/socket';

let counter = 0;

const useBoardSocket = ({
  id,
  type,
  enabled = true,
}: {
  id: number;
  type: 'user' | 'admin';
  enabled?: boolean;
}) => {
  const queryClient = useQueryClient();

  const ref = useRef(false);

  const myId = generateChatId({ id, type });

  const join = useCallback(
    (room) => {
      const previousDataRooms =
        queryClient.getQueryData(['rooms'])?.rooms || [];
      const selectedRoomIndex = previousDataRooms.findIndex(
        ({ roomId }) => roomId === room.roomId
      );
      if (selectedRoomIndex > -1) {
        queryClient.setQueryData(['rooms'], {
          rooms: [
            ...previousDataRooms.slice(0, selectedRoomIndex),
            { ...previousDataRooms[selectedRoomIndex], ...room },
            ...previousDataRooms.slice(selectedRoomIndex + 1),
          ],
        });
      } else {
        queryClient.setQueryData(['rooms'], {
          rooms: [...previousDataRooms, room],
        });
      }
    },
    [queryClient]
  );

  const message = useCallback(
    (message) => {
      const storeKey = ['rooms', message.roomId];
      const { messages } = queryClient.getQueryData(storeKey) || {
        messages: [],
      };
      const roomsPayload = queryClient.getQueryData(['rooms']);
      queryClient.setQueryData(storeKey, { messages: [...messages, message] });
      queryClient.setQueryData(['rooms'], {
        rooms: [
          ...(roomsPayload.rooms || []).map(
            ({ roomId, lastMessagePayload, readByPayload, ...rest }) => {
              return {
                roomId,
                ...(rest || {}),
                lastMessagePayload:
                  message.roomId === roomId
                    ? message.lastMessagePayload
                    : lastMessagePayload,
                readByPayload:
                  message.roomId === roomId
                    ? message.sender === myId
                      ? [{ type: 'user', id }]
                      : []
                    : readByPayload,
              };
            }
          ),
        ],
      });
    },
    [id, myId, queryClient]
  );

  const read = useCallback(
    ({ roomId, participant }) => {
      const storeKey = ['rooms'];
      const previousData = queryClient.getQueryData(storeKey) || [];

      queryClient.setQueryData(['rooms'], {
        rooms: [
          ...(previousData.rooms || []).map(
            ({ roomId: currentRoomId, readByPayload, ...rest }) => ({
              roomId: currentRoomId,
              ...(rest || {}),
              readByPayload:
                currentRoomId === roomId
                  ? participant === myId
                    ? [{ type: 'user', id }]
                    : []
                  : readByPayload,
            })
          ),
        ],
      });
    },
    [id, myId, queryClient]
  );

  const attachToSocket = useCallback(() => {
    counter += 1;
    if (counter !== 1) {
      return;
    }

    const socket = getUserSocket();
    // socket?.r{emoveAllListeners?.();
    socket?.on('message', message);
    socket?.on('join', join);
    socket?.on('read', read);
  }, [join, message, read]);

  const detachFromSocket = useCallback(() => {
    counter -= 1;
    if (counter !== 0) {
      return;
    }

    const socket = getUserSocket();
    socket?.off('message', message);
    socket?.off('join', join);
    socket?.off('read', read);
    socket?.close?.();
    removeUserSocket();
  }, [join, message, read]);

  useEffect(() => {
    if (enabled === ref.current) {
      return;
    }

    if (enabled) {
      attachToSocket();
    }

    if (!enabled) {
      detachFromSocket();
    }

    ref.current = enabled;

    return () => {
      detachFromSocket();
    };
  }, [attachToSocket, detachFromSocket, enabled, join, message, read]);

  // useEffect(() => {
  //   if (!enabled) {
  //     return;
  //   }

  //   const socket = getUserSocket();

  //   socket?.on('message', message);
  //   socket?.on('join', join);
  //   socket?.on('read', read);

  //   return () => {
  //     socket?.off('message', message);
  //     socket?.off('join', join);
  //     socket?.off('read', read);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
};

export default useBoardSocket;
