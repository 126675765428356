import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  CreatePollAnswerParams,
  DeletePollAnswerParams,
  IPollQuestionAnswer,
  IPost,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import Toast from 'react-native-toast-message';
import { BaseView, FlexColumnDirection, H5Regular } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { QuestionOption } from './QuestionOption';
import { TextWithLinks } from './TextWithLinks';

const Container = styled(FlexColumnDirection)`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const Question = styled(H5Regular)`
  margin-bottom: 10px;
  font-family: Rubik;
  font-size: 15px;
  font-weight: normal;
`;

const TextContainer = styled(BaseView)``;

interface Props {
  post: IPost;
  createPollAnswer(params: CreatePollAnswerParams): Promise<any>;
  deletePollAnswer(params: DeletePollAnswerParams): Promise<any>;
}

export const QuestionPostContent: FC<Props> = ({
  post,
  deletePollAnswer,
  createPollAnswer,
}) => {
  const [optionLoading, setOptionLoading] = useState(0);

  const showToast = useCallback(({ text1, text2, type, onHide }) => {
    Toast.show({
      type,
      text1,
      text2,
      onHide,
    });
  }, []);

  const { t } = useTranslation();

  const {
    polls: [
      {
        id: pollId,
        questions: [{ id: questionId, text, options, isMultiSelect }],
      }, // NOTE: destruct just the first one
    ],
  } = post;

  const handleMultiSelect = useCallback(
    async (option) => {
      try {
        const answer = option?.answer;

        setOptionLoading(1);

        if (answer) {
          await deletePollAnswer({
            pollId,
            questionId,
            answerId: answer.id,
          });
        } else {
          await createPollAnswer({
            pollId,
            questionId,
            pollOptionId: option.id,
          });
        }
      } catch (error) {
        showToast({ text1: t('unknown'), type: 'error' });
      } finally {
        setOptionLoading(0);
      }
    },
    [createPollAnswer, deletePollAnswer, pollId, questionId, showToast, t]
  );

  const handleSingleSelect = useCallback(
    async (option) => {
      setOptionLoading(option.id);
      // Un-select option if it's selected
      if (option.answer) {
        await deletePollAnswer({
          pollId,
          questionId,
          answerId: option.answer.id,
        });
      } else {
        // Remove selected answer if we got any
        Promise.all(
          options
            .map((o) => o.answer)
            .filter((x): x is IPollQuestionAnswer => Boolean(x))
            .map((o) =>
              deletePollAnswer({ pollId, questionId, answerId: o.id })
            )
        );

        // Set new selected answer
        await createPollAnswer({
          pollId,
          questionId,
          pollOptionId: option.id,
        });
      }
      setOptionLoading(0);
    },
    [createPollAnswer, deletePollAnswer, options, pollId, questionId]
  );

  const totalAnswerNum = useMemo(
    () => Math.max(...options.map((opt) => opt.answersTotal)),
    [options]
  );

  return (
    <Container>
      <TextContainer>
        <TextWithLinks component={Question}>{text}</TextWithLinks>
      </TextContainer>
      {options
        .sort((a, b) => (a.id > b.id ? 1 : -1))
        .map((option) => (
          <QuestionOption
            key={option.id}
            option={option}
            totalAnswerNum={totalAnswerNum}
            selected={option.answer !== null}
            isDisabled={optionLoading !== 0}
            isLoading={option.id === optionLoading}
            onPress={() =>
              isMultiSelect
                ? handleMultiSelect(option)
                : handleSingleSelect(option)
            }
          />
        ))}
    </Container>
  );
};
