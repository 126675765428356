import React, { FC, ReactSVG } from 'react';
import { SvgXml } from 'react-native-svg';

interface Props {
  xml: string | FC<ReactSVG>;
  width: number;
  height: number;
  rotation?: string;
  color?: string;
  defaultViewBox?: boolean;
  style?: any;
}

export const SvgIcon: FC<Props> = ({
  height,
  width,
  xml,
  rotation = '0deg',
  color = '#595959',
  defaultViewBox = true,
  style = {},
}) => {
  return (
    <SvgXml
      xml={xml}
      width={width}
      height={height}
      fill={color}
      defaultViewBox={defaultViewBox}
      style={{ ...style, transform: [{ rotate: rotation }] }}
    />
  );
};
