import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { enumerateDaysBetweenDates } from '@violetta/ubeya/shared';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { useLabels } from './useLabels';
import { usePositions } from './usePositions';

const selector = createCachedSelector(
  (data) => data,
  (data, mappedPositions) => mappedPositions,
  (data, mappedPositions, mappedLabels) => mappedLabels,
  (data, mappedPositions, mappedLabels, weekDates) => weekDates,
  (data, mappedPositions, mappedLabels, weekDates) => {
    const schedule = data?.data.map((piece) => ({
      ...piece,
      position: mappedPositions?.[piece?.position_id],
      label: mappedLabels?.[piece?.labelId],
    }));

    const activeDays = [
      ...new Set(schedule.map((slot) => moment(slot.startTime).day())),
    ] as number[];

    const mappedScheduleByDayAndLabel = weekDates
      .sort((a, b) =>
        new Date(a)?.getTime() - new Date(b)?.getTime() > 0 ? 1 : -1
      )
      .map((day) => {
        const filteredSchedule = schedule.filter(
          (slot) => moment(slot.startTime).format('YYYY-MM-DD') === day
        );
        const uniqueLabels = [
          ...new Set(filteredSchedule.map((sc) => sc.label)),
        ];

        const filteredScheduleByLabel = uniqueLabels.map((label) => ({
          title: label,
          data: filteredSchedule.filter((sch) => sch?.labelId === label?.id),
        }));

        return {
          title: day,
          data: filteredScheduleByLabel,
        };
      });

    return { schedule, mappedScheduleByDayAndLabel, activeDays };
  }
)({
  keySelector: (data, mappedPositions, mappedLabels, weekDates, storeKey) =>
    storeKey,
});

const today = moment().format('YYYY-MM-DD');
const nextWeek = moment().add(6, 'd').format('YYYY-MM-DD');

export const useWeeklySchedule = ({
  accountId,
  from = today,
  to = nextWeek,
}) => {
  const storeKey = useMemo(
    () => ['weeklySchedule', accountId, from, to],
    [accountId, from, to]
  );

  const { mappedPositions } = usePositions();
  const { mappedLabels } = useLabels();

  const weekDates = useMemo(
    () => enumerateDaysBetweenDates(moment(from), moment(to)),
    [from, to]
  );

  const {
    isPending,
    data,
    refetch: refetchSchedule,
  } = useQuery({
    queryKey: storeKey,
    queryFn: () => api.getWeeklySchedule({ accountId, from, to }),
    enabled: !!accountId,
    select: (data) =>
      selector(
        data,
        mappedPositions,
        mappedLabels,
        weekDates,
        storeKey.join('#')
      ),
  });

  const {
    schedule = [],
    mappedScheduleByDayAndLabel = [],
    activeDays,
  } = data || {};

  return {
    isLoading: isPending,
    data: schedule,
    mappedScheduleByDayAndLabel,
    activeDays,
    refetchSchedule,
  };
};
