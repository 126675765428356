import React, { FC, useCallback, useMemo, useState } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { IOffer } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  FlexColumnDirection,
  FlexRow,
  H4Medium,
  SvgIcon,
  Switch,
  TextSubmitInput,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const Container = styled(FlexColumnDirection)`
  margin-top: 15px;
`;
const FormGroup = styled(FlexRow)`
  align-items: center;
  margin: 15px 0;
`;
const Title = styled(H4Medium)`
  margin-left: 13px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: #252525;
`;

const TextContainer = styled(FlexRow)`
  align-items: center;
  padding: 15px 5px;
  margin-right: 12px;
`;

interface Props {
  editApplication: (val: any) => Promise<void>;
  isLoading: boolean;
  offer: IOffer;
  isHasCarOnApply?: boolean;
}

export const OfferApplyDetails: FC<Props> = ({
  editApplication,
  isHasCarOnApply,
  offer: { shifts },
}) => {
  const { t } = useTranslation();
  const appliedShift = useMemo(
    () => (shifts || []).find(({ application }) => !!application),
    [shifts]
  );
  const { projectId, id: shiftId, application } = appliedShift || {};
  const [hasCar, setHasCar] = useState<boolean>(
    appliedShift?.application?.hasCar || false
  );
  const [initialComment, setInitialComment] = useState<string>(
    appliedShift?.application?.comment || ''
  );
  const [comment, setComment] = useState<string>(
    appliedShift?.application?.comment || ''
  );
  const [isCommentLoading, setIsCommentLoading] = useState<boolean>(false);

  const changeHasCar = useCallback(
    (value) => {
      setHasCar(value);
      editApplication({
        id: application!.id,
        projectId,
        shiftId,
        hasCar: value,
      });
    },
    [application, editApplication, projectId, shiftId]
  );
  const changeComment = useCallback(async () => {
    setIsCommentLoading(true);
    await editApplication({ id: application!.id, projectId, shiftId, comment });
    setInitialComment(comment);
    setIsCommentLoading(false);
  }, [application, comment, editApplication, projectId, shiftId]);

  if (!appliedShift) {
    return null;
  }

  return (
    <Container>
      <TextContainer>
        <SvgIcon
          xml={icons.symbolIcons.notes}
          width={15}
          height={15}
          color="#cccccc"
        />
        <TextSubmitInput
          multiline
          numberOfLines={1}
          placeholder={t('addANote')}
          buttonText={t('save')}
          value={comment}
          onChangeText={setComment}
          onBlur={changeComment}
          onSubmit={changeComment}
          isLoading={isCommentLoading}
          initialValue={initialComment}
        />
      </TextContainer>
      {isHasCarOnApply && (
        <FormGroup>
          <Switch
            value={hasCar}
            onValueChange={changeHasCar}
            style={{ transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] }}
          />
          <Title>{t('arrivingWithMyOwnCar')}</Title>
        </FormGroup>
      )}
    </Container>
  );
};
