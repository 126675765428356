import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray } from '@violetta/ubeya/utils';
import { useQuery } from '@tanstack/react-query';
import { IMandatory } from '../entities/IMandatory';

type ResponseType = {
  data: Array<IMandatory>;
};

export const useMandatories = () => {
  const { data: userData } = useUser();

  const storeKey = ['mandatories'];
  const { isPending, data, refetch } = useQuery<ResponseType>({
    queryKey: storeKey,
    queryFn: api.getMandatories,
    enabled: !!userData?.id,
  });

  return {
    isLoading: isPending,
    mandatories: data?.data,
    refetch,
  };
};
