import React, { FC, useCallback, useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  IAccount,
  IBranch,
  ILocation,
  IOffer,
  useLocations,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { FlexColumnDirection, H5Regular, SwiperView } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import { OffersScreenNames } from '../../../offers';
import { HomeScreenNames } from '../../navigation/ScreenNames';
import { CardTitle } from '../CardTitle/CardTitle';
import { ProjectOffer } from './ProjectOffer';

interface Props {
  offers: IOffer[];
  total: number;
  mappedBranches: Record<number, IBranch>;
  mappedLocations: Record<number, ILocation>;
  mappedAccounts: Record<number, IAccount>;
}

const EmptyStateTitle = styled(H5Regular)`
  text-align: center;
`;

export const ProjectOffersCard: FC<Props> = React.memo(
  ({ offers, mappedBranches, mappedLocations, mappedAccounts, total }) => {
    const { navigate } = useNavigation();
    const { t } = useTranslation();
    const { rtl } = useContext(ConfigContext);

    const navigateOffers = useCallback(
      () =>
        navigate(HomeScreenNames.TABS, { screen: OffersScreenNames.OFFERS }),
      [navigate]
    );

    const navigateOffer = useCallback(
      (offer: IOffer) => {
        navigate(OffersScreenNames.MAIN, {
          screen: OffersScreenNames.OFFER,
          params: { offerId: offer.id },
        });
      },
      [navigate]
    );

    return (
      <FlexColumnDirection>
        <CardTitle
          title={t('openShifts')}
          count={total}
          onShowAllPress={navigateOffers}
        />
        {offers.length === 0 && (
          <EmptyStateTitle>{t('emptyStateOffers')}</EmptyStateTitle>
        )}
        <SwiperView
          cards={offers}
          initialIndex={0}
          rtl={rtl}
          swipeComponent={(offer, i) => (
            <ProjectOffer
              offer={offer}
              key={i}
              mappedBranches={mappedBranches}
              onPress={navigateOffer}
              mappedLocations={mappedLocations}
              mappedAccounts={mappedAccounts}
            />
          )}
          onLastItemPress={navigateOffers}
          lastItemText={t('allOffers')}
        />
      </FlexColumnDirection>
    );
  }
);
