import React, { FC, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { useRequestsTypes } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Close,
  FlexRow,
  H7Light,
  SvgIcon,
  FlexCenter,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { theme } from '../../../../style/theme';

interface Props {
  requestStatus: number;
  jobTypeId: number;
}

const Container = styled(BaseView)`
  margin: 6px 0;
`;

const Hour = styled(H7Light)`
  margin-right: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: #252525;
`;
const Content = styled(FlexRow)`
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled(FlexCenter)`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 10px;
`;

export const TimeOffItemChip: FC<Props> = ({ requestStatus, jobTypeId }) => {
  const { t } = useTranslation();

  const { mappedRequestsTypesByTypeId } = useRequestsTypes();

  const requestTranslation = useMemo(
    () =>
      mappedRequestsTypesByTypeId['1']?.kinds.find(
        (kind) => jobTypeId === kind.id
      )?.slug || '',
    [mappedRequestsTypesByTypeId, jobTypeId]
  );

  const status = useMemo(() => {
    switch (requestStatus) {
      case 1:
        return {
          icon: icons.uiIcons.circleConfirmed,
          title: t('approvedRequest'),
        };
      case 2:
        return {
          icon: icons.uiIcons.circleDenied,
          title: t('declinedRequest'),
        };
      default:
        return {
          icon: icons.uiIcons.circlePending,
          title: t('pendingRequest'),
        };
    }
  }, [requestStatus, t]);

  return (
    <Container>
      <Content>
        <IconContainer>
          <SvgIcon xml={status.icon} width={20} height={20} />
        </IconContainer>
        <Hour>{`${t(requestTranslation)} - ${status.title}`}</Hour>
      </Content>
    </Container>
  );
};
