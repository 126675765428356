import Intercom from '@intercom/intercom-react-native';
import { useNetInfo } from '@react-native-community/netinfo';
import analytics from '@react-native-firebase/analytics';
import { FirebaseMessagingTypes } from '@react-native-firebase/messaging';
import { useMutation } from '@tanstack/react-query';
import { Alert } from '@violetta/ubeya/alert';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import {
  Booking,
  CustomTimesheetDTO,
  IAccount,
  IOffer,
  useAccounts,
  usePeriods,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { Prompt } from '@violetta/ubeya/ui';
import {
  promptsAtom,
  useConnectionQueue,
  usePolling,
  usePushNotification,
  useRefresh,
  useStats,
} from '@violetta/ubeya/utils';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Linking, Platform, StatusBar } from 'react-native';
import { Host } from 'react-native-portalize';
import { useRecoilState } from 'recoil';
import {
  useBackgroundTasks,
  useInitialFetch,
  useOfflinePersist,
} from '../../hooks';
import { hideTabsAtom } from '../../state';
import { colors } from '../../style/theme';
import { useTimesheetQueue } from '../timeclock/hooks';
import { AppContext } from './AppContext';
import { APP_VERSION, BUILD_NUMBER, DefaultView } from './constants';
import { MainNavigation } from './navigation';
import { config } from './appConfig';

interface Props {
  defaultView: DefaultView;
}
const LOGGED_IN_TIME = 'loggedInTime';

let logoutTimer: NodeJS.Timer;

export const AuthorizedApp: FC<Props> = ({ defaultView }) => {
  const { data: userData, intercom, logoutWithoutPrompt } = useUser();
  const { accounts, isEngagementMode } = useAccounts();
  const [, setHideTabs] = useRecoilState(hideTabsAtom);
  const { resolveQueue } = useTimesheetQueue();
  const { isConnected } = useNetInfo();
  const [periodId, setPeriod] = useState<number>(0);
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(
    accounts?.[0]
  );

  useEffect(() => {
    const startLogoutTimer = (logoutFn: () => void) => {
      clearTimeout(logoutTimer);
      const lastLoggedInTime = localStorage.getItem(LOGGED_IN_TIME);
      //time past since last login in milliseconds
      const loginTimeDiff = moment().diff(
        moment(lastLoggedInTime),
        'milliseconds'
      );

      logoutTimer = setTimeout(() => {
        logoutFn();
      }, 10 * 60 * 60 * 1000 - loginTimeDiff); // 10 hours in milliseconds minus time past
    };
    if (Platform.OS === 'web') {
      startLogoutTimer(logoutWithoutPrompt);
    }
  }, [logoutWithoutPrompt]);

  useEffect(() => {
    if (isEngagementMode) {
      setHideTabs(new Set(['scheduling', 'offers']));
    }
  }, [isEngagementMode, setHideTabs]);

  const { refetch: refetchStats } = useStats({ enabled: true });

  const [selectedDay, setSelectedDay] = useState<string | null>(null);

  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);

  const [selectedTimesheet, setSelectedTimesheet] =
    useState<CustomTimesheetDTO | null>(null);

  const [selectedOffer, setSelectedOffer] = useState<IOffer | null>(null);

  const [selectedAvailabilityDay, setSelectedAvailabilityDay] = useState(() =>
    moment().toDate()
  );
  const [prompts] = useRecoilState(promptsAtom);

  const { refresh } = useRefresh();
  const { t } = useTranslation();

  const { mappedPeriodsByBranch, mappedPeriods } = usePeriods();

  const onChange = useCallback(
    (type: 'next' | 'prev') => {
      const { branchId } = mappedPeriods[periodId];
      const periods = mappedPeriodsByBranch[branchId];
      const idx = periods?.findIndex(({ id }) => id === periodId);

      const nextIdx = type === 'next' ? idx + 1 : idx - 1;

      if (nextIdx > periods.length || nextIdx < 0) {
        return;
      }

      setPeriod(periods[nextIdx % periods.length]?.id);
    },
    [mappedPeriods, mappedPeriodsByBranch, periodId, setPeriod]
  );

  const { mutateAsync: sendFCMToken } = useMutation({
    mutationFn: (token: string) => api.sendFCMToken({ token }),
  });

  const { mutateAsync: sendDevice } = useMutation({
    mutationFn: () =>
      api.sendDevice({
        os: {
          platform: Platform.OS,
          version: Platform.Version?.toString() || '',
        },
        appVersion: APP_VERSION,
        appBuild: BUILD_NUMBER,
      }),
    onSuccess: ({ needsUpdate = false }: { needsUpdate: boolean }) => {
      if (needsUpdate) {
        Alert.alert(
          t('needsUpdatePromptTitle'),
          t('needsUpdatePromptBody'),
          [
            {
              type: 'confirm',
              text: t('needsUpdatePromptOk'),
              onPress: () => {
                const url = (() => {
                  if (Platform.OS === 'android')
                    return config.links.ANDROID_RATEAPP_URL;
                  return config.links.IPHONE_RATEAPP_MANAGER_URL;
                })();
                Linking.openURL(url);
              },
            },
            { type: 'cancel', text: t('cancel') },
          ],
          {
            cancelable: false,
          }
        );
      }
    },
  });

  const setAnalytics = useCallback(async () => {
    if (Platform.OS !== 'ios' && Platform.OS !== 'android') {
      return;
    }
    analytics().setUserId(`#${String(userData?.id)}`);
    analytics().setUserProperty(
      'accountIds',
      (accounts || []).map(({ id }) => `#${String(id)}#`).join('')
    );
    await Intercom.logout();
    Intercom.setUserHash(
      Platform.OS === 'android' ? intercom?.android : intercom?.ios
    );

    await Intercom.loginUserWithUserAttributes({
      userId: `#${String(userData?.id)}`,
      phone: userData?.phone,
      customAttributes: {
        type: 'employee',
      },
    });

    Intercom.updateUser({
      userId: `#${String(userData?.id)}`,
      phone: userData?.phone,
      email: null,
      customAttributes: {
        type: 'employee',
        accounts: (accounts || [])
          ?.map(({ name, id }) => `${name} (${id})`)
          ?.join(','),
      },
    });
  }, [
    accounts,
    intercom?.android,
    intercom?.ios,
    userData?.id,
    userData?.phone,
  ]);

  useEffect(() => {
    sendDevice();
  }, [sendDevice]);

  useEffect(() => {
    setAnalytics();
  }, [setAnalytics]);

  const onPushNotificationReceived = useCallback(
    async (
      remoteMessage: FirebaseMessagingTypes.RemoteMessage,
      type: 'quit' | 'background' | 'foreground'
    ) => {
      // console.log(`>> Got push of type: ${type}`);
      // console.log('>> Push content: ', remoteMessage);
      return refresh();
    },
    [refresh]
  );

  const onPushNotificationPress = useCallback(
    async (
      remoteMessage: FirebaseMessagingTypes.RemoteMessage,
      type: 'quit' | 'background' | 'foreground'
    ) => {
      // console.log(`>> PRESSED Got push of type: ${type}`);
      // console.log('>> PRESSED Push content: ', JSON.stringify(remoteMessage));

      const link = remoteMessage?.data?.link || remoteMessage?.link;
      // console.log('>> PRESSED Push content: ', { link });
      if (link) {
        Linking.openURL(link);
        // https://crew.ubeya.com/
      }

      // refresh();
    },
    []
  );

  useEffect(() => {
    resolveQueue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInitialFetch();
  // useBackgroundTasks();
  useOfflinePersist();
  usePushNotification({
    onTokenReceived: sendFCMToken,
    onNotificationPress: onPushNotificationPress,
    onNotificationReceived: onPushNotificationReceived,
    refetchStats,
  });
  usePolling();

  const providerValues = useMemo(
    () => ({
      availability: {
        periodId,
        setPeriod,
        selectedDay: selectedAvailabilityDay,
        selectDay: setSelectedAvailabilityDay,
        onNext: () => onChange('next'),
        onPrev: () => onChange('prev'),
      },
      profile: {
        selectAccount: setSelectedAccount,
        selectedAccount,
      },
      bookings: {
        selectedBooking,
        selectBooking: setSelectedBooking,
      },
      timesheets: {
        selectedTimesheet,
        selectTimesheet: setSelectedTimesheet,
      },
      offers: { selectedOffer, selectOffer: setSelectedOffer },
      generalAvailability: {
        selectedDay,
        selectDay: setSelectedDay,
      },
    }),
    [
      periodId,
      selectedAvailabilityDay,
      selectedAccount,
      selectedBooking,
      selectedTimesheet,
      selectedOffer,
      selectedDay,
      onChange,
    ]
  );

  return (
    <AppContext.Provider value={providerValues}>
      <StatusBar barStyle="dark-content" backgroundColor={colors.surface} />
      {Platform.OS === 'web' ? (
        <MainNavigation defaultView={defaultView} />
      ) : (
        <Host>
          <MainNavigation defaultView={defaultView} />
        </Host>
      )}
      {prompts.map((prompt, idx) => (
        <Prompt key={idx} {...prompt} />
      ))}
    </AppContext.Provider>
  );
};
