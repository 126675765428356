import { useMemo } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { useAdminUser } from './useAdminUser';

const selector = createCachedSelector(
  (data) => data?.data,
  (data: IDirectoryContact[]) => {
    const parsedContacts = data || [];

    return { parsedContacts };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useDirectory = (branchId: number) => {
  const storeKey = useMemo(() => ['directory', String(branchId)], [branchId]);

  const { data: userData } = useAdminUser();

  const { isPending, data, refetch } = useQuery({
    queryKey: storeKey,
    queryFn: () => adminApi.getDirectory(branchId),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { parsedContacts } = data || {};

  return {
    directory: parsedContacts,
    refetch,
    isLoading: isPending,
  };
};
