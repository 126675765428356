import React, { FC } from 'react';
import { ISequence } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  Card,
  FlexColumnCenter,
  FlexRow,
  H5Medium,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const Container = styled(Card)`
  padding: 12px;
`;

const Box = styled(FlexRow)`
  align-items: center;
`;

const SequenceDetails = styled(BaseView)`
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  flex: 1;
`;

const Title = styled(H5Medium)`
  flex-wrap: wrap;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: normal;
  font-style: normal;
`;

const ButtonContainer = styled(FlexColumnCenter)`
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

interface Props {
  sequence: ISequence;
  onPress: () => void;
}

export const Sequence: FC<Props> = ({ sequence, onPress }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Box>
        <SequenceDetails>
          <Title>{sequence?.title}</Title>
        </SequenceDetails>
        <ButtonContainer>
          <Button title={t('fill')} onPress={onPress} type="primary" />
        </ButtonContainer>
      </Box>
    </Container>
  );
};
