import React, { FC, useState } from 'react';
import { Pressable, PressableProps } from 'react-native';
import styled from 'styled-components/native';
import { BaseView } from '../Layout';
import { H5Medium } from '../Typography';

export const ShadowButton = styled(BaseView)`
  height: 44px;
  padding: 0 15px;
  justify-content: center;
  border-radius: 50px;
  align-items: center;
  shadow-color: rgba(0, 0, 0, 0.12);
  shadow-opacity: 1;
  shadow-offset: 0 2px;
  shadow-radius: 5px;
  elevation: 5;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const Title = styled(H5Medium)``;
const PressedTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.white};
`;

export const PressedShadowButton = styled(ShadowButton)`
  background-color: ${({ theme }) => theme.colors.green1};
`;

interface Props {
  title: string;
  pressed?: boolean;
  pressedTitle?: string;
}

export const PressableShadowButton: FC<PressableProps & Props> = (props: PressableProps & Props) => {
  const { title, pressed = false, pressedTitle = '' } = props;
  return (
    <Pressable {...props}>
      {pressed ? (
        <PressedShadowButton>
          <PressedTitle>{pressedTitle}</PressedTitle>
        </PressedShadowButton>
      ) : (
        <ShadowButton>
          <Title>{title}</Title>
        </ShadowButton>
      )}
    </Pressable>
  );
};
