import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNetInfo } from '@react-native-community/netinfo';
import { to } from '@violetta/ubeya/await';
import {
  IBranchTimesheetConfig,
  useBranches,
  useLocations,
  useTimesheets,
  useTimesheetsUtils,
} from '@violetta/ubeya/entities';
import {
  distance,
  GeolocationError,
  getCurrentLocation,
} from '@violetta/ubeya/utils';
import moment from 'moment';
import momentTz from 'moment-timezone';
import {
  IDuration,
  TimeclockState,
  TimeclockValidationStatus,
} from '../interfaces';
import { useBreaktimes } from './useBreaktimes';
import { useTimesheet } from './useTimesheet';
import { useBreakTypes } from './useBreakTypes';
import { useEmployeeSlotBreakPlots } from './useEmployeeSlotBreakPlots';

export const useTimeclock = (id: number) => {
  const [state, setState] = useState(TimeclockState.InitialState);
  const [changing, setChanging] = useState<TimeclockState | null>(null);
  const { mappedBranches } = useBranches();
  const { mappedLocations } = useLocations();
  const { mappedBreakTypes } = useBreakTypes();
  const { createReports } = useTimesheetsUtils();
  const { timesheets, refetch, isLoading } = useTimesheets();

  // Fetch data from server with useQuery
  const timesheet = useMemo(
    () => timesheets!.find((timesheet) => timesheet.id === id)!,
    [timesheets, id]
  );
  const {
    clockIn: startTimesheet,
    clockOut: endTimesheet,
    checkIn: checkInTimesheet,
    checkOut: checkOutTimesheet,
  } = useTimesheet(id);
  const { breaktimes, startBreaktime, endBreaktime } = useBreaktimes(id);
  const { data: employeeSlotBreakPlots } = useEmployeeSlotBreakPlots(
    timesheet?.employeeSlotId
  );

  const { isConnected } = useNetInfo();
  const config = useMemo((): IBranchTimesheetConfig | null => {
    if (!timesheet) {
      return null;
    }
    const { timesheetConfig } = mappedBranches?.[timesheet?.branchId] || {};
    return {
      ...timesheetConfig,
    };
  }, [timesheet, mappedBranches]);

  const defineState = useCallback(() => {
    const shouldRequireCheckIn = timesheet?.requireCheckInWithMobile;

    if (!timesheet?.startTime) {
      return TimeclockState.InitialState;
    }

    if (timesheet?.endTime) {
      return TimeclockState.TimeclockEnded;
    }

    if (
      breaktimes?.length > 0 &&
      !breaktimes?.[breaktimes?.length - 1]?.endTime
    ) {
      return TimeclockState.BreaktimeStarted;
    }

    if (shouldRequireCheckIn && timesheet?.checkOutTime) {
      return TimeclockState.TimeclockCheckedOut;
    }
    if (shouldRequireCheckIn && timesheet?.checkInTime) {
      return TimeclockState.TimeclockCheckedIn;
    }

    return TimeclockState.TimeclockStarted;
  }, [timesheet, breaktimes]);

  useEffect(() => {
    setState(defineState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    timesheet?.startTime,
    timesheet?.endTime,
    timesheet?.checkInTime,
    timesheet?.checkOutTime,
  ]);

  useEffect(() => {
    setState(defineState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = useCallback(
    async (state: TimeclockState) => {
      const { scheduledStartTime, scheduledEndTime, locationId, startTime } =
        timesheet!;

      const {
        toAllowGpsWhenClock,
        distanceFromShiftAddress,
        clockInBeforeShiftStart,
        clockOutAfterShiftEnd,
        toRequireCheckInWithMobile,
      } = config!;
      const location = locationId ? mappedLocations?.[locationId] : null;

      const now = moment();

      // location (gps) validation should not be run for check in/out
      const shouldAllowGPSValidation =
        state !== TimeclockState.TimeclockCheckedIn &&
        state !== TimeclockState.TimeclockCheckedOut;

      if (shouldAllowGPSValidation && toAllowGpsWhenClock && location) {
        // if (locationRequired && location) {
        const [userLocationErr, userLocation] = await to(getCurrentLocation());
        if (userLocationErr) {
          switch (userLocationErr as unknown as GeolocationError) {
            case GeolocationError.NO_LOCATION_AVAILABLE:
            case GeolocationError.POSITION_UNAVAILABLE:
              return TimeclockValidationStatus.NO_LOCATION_AVAILABLE;
            case GeolocationError.PERMISSION_DENIED:
              return TimeclockValidationStatus.LOCATION_PERMISSION_DENIED;
            case GeolocationError.TIMEOUT:
              return TimeclockValidationStatus.LOCATION_TIMEOUT;
            default:
              return TimeclockValidationStatus.LOCATION_UNKNOWN;
          }
        }

        if (
          userLocation &&
          distanceFromShiftAddress > 0 &&
          distance(userLocation, location) > distanceFromShiftAddress
        ) {
          return TimeclockValidationStatus.TOO_FAR;
        }
      }

      switch (state) {
        case TimeclockState.TimeclockStarted:
          if (clockInBeforeShiftStart && clockInBeforeShiftStart > 0) {
            const mStartTime = scheduledStartTime
              ? moment(scheduledStartTime)
              : null;
            if (
              mStartTime &&
              now.isBefore(
                mStartTime.subtract(clockInBeforeShiftStart, 'minutes')
              )
            ) {
              return TimeclockValidationStatus.CLOCKIN_TOO_EARLY;
            }
          }
          return TimeclockValidationStatus.OK;
        case TimeclockState.BreaktimeStarted:
          return TimeclockValidationStatus.OK;
        case TimeclockState.BreaktimeEnded:
          return TimeclockValidationStatus.OK;
        case TimeclockState.TimeclockEnded:
          if (clockOutAfterShiftEnd && clockOutAfterShiftEnd > 0) {
            const mEndTime = scheduledEndTime ? moment(scheduledEndTime) : null;
            if (
              mEndTime &&
              now.isAfter(mEndTime.add(clockOutAfterShiftEnd, 'minutes'))
            ) {
              return TimeclockValidationStatus.CLOCKOUT_TOO_LATE;
            }
          }
          {
            const mStartTime = startTime ? moment(startTime) : null;
            if (mStartTime && now.isBefore(mStartTime.add(30, 'minutes'))) {
              return TimeclockValidationStatus.CLOCKOUT_TOO_EARLY;
            }

            return TimeclockValidationStatus.OK;
          }
        default:
          return TimeclockValidationStatus.OK;
      }
    },
    [timesheet, config, mappedLocations]
  );

  const factory = useCallback(
    async (state: TimeclockState) => {
      const { toAllowGpsWhenClock } = config;
      const location = await (async () => {
        if (!toAllowGpsWhenClock) return null;

        const [locationError, location] = await to(getCurrentLocation());
        if (locationError) {
          return null;
        }
        return location;
      })();

      switch (state) {
        case TimeclockState.InitialState:
          return () => {};
        case TimeclockState.TimeclockStarted:
          return () => startTimesheet({ location });
        case TimeclockState.TimeclockCheckedIn:
          return () => checkInTimesheet({ location });
        case TimeclockState.TimeclockCheckedOut:
          return () => checkOutTimesheet({ location });
        case TimeclockState.BreaktimeStarted:
          return startBreaktime;
        case TimeclockState.BreaktimeEnded:
          return () =>
            endBreaktime({ breaktimeId: breaktimes[breaktimes.length - 1].id });
        case TimeclockState.TimeclockEnded:
          return () => endTimesheet({ location });
        default:
          return async () => {};
      }
    },
    [
      config,
      startBreaktime,
      startTimesheet,
      checkInTimesheet,
      checkOutTimesheet,
      endBreaktime,
      breaktimes,
      endTimesheet,
    ]
  );

  const changeState = useCallback(
    async (
      state: TimeclockState,
      { breakTypeId }: { breakTypeId?: number } = {}
    ) => {
      setChanging(state);
      const action = await factory(state);
      if (isConnected) {
        await action({ breakTypeId });
      } else {
        action({ breakTypeId });
      }
      setState(state);
      setChanging(null);
    },
    [factory, isConnected]
  );

  const reports = useMemo(
    () =>
      createReports(
        timesheet,
        breaktimes,
        mappedLocations,
        mappedBreakTypes,
        momentTz
      ),
    [breaktimes, createReports, mappedBreakTypes, mappedLocations, timesheet]
  );

  const duration: IDuration = useMemo(() => {
    const totalBreaks =
      breaktimes
        .filter(
          ({ startTime, endTime }) => startTime !== null && endTime !== null
        )
        .map(({ startTime, endTime }) =>
          moment.duration(moment(endTime).diff(moment(startTime))).asSeconds()
        )
        .reduce((carry, item) => carry + item, 0) || 0;

    const anchor = moment(
      state === TimeclockState.BreaktimeStarted
        ? breaktimes[breaktimes.length - 1].startTime!
        : new Date()
    );

    const totalWorktime = moment
      .duration(anchor.diff(timesheet?.startTime!))
      .asSeconds();

    return {
      totalBreaks,
      totalWorktime,
      worktime: totalWorktime - totalBreaks,
    };
  }, [breaktimes, state, timesheet?.startTime]);

  return {
    changeState,
    state,
    reports,
    timesheet,
    breaktimes,
    employeeSlotBreakPlots,
    duration,
    changing,
    validate,
    config,
    refetch,
    isLoadingRefetch: isLoading,
  };
};
