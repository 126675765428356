import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigation } from '@react-navigation/core';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  FlexRow,
  BaseView,
  H5Medium,
  SvgIcon,
  H5Regular,
  FlexColumnDirection,
  ChevronRight,
  FlexRowBetween,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import { Image as BaseImage } from 'react-native';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import { SwapScreenNames } from '../navigation/ScreenNames';

interface Props {
  item: {
    employee: {
      id: number;
      firstName: string;
      lastName: string;
      image: string;
    };
    slot: {
      id: number;
      startTime: string;
      endTime: string;
      positionId: number;
    };
  };
}

const Container = styled(FlexRowBetween)`
  padding: 13px 16px 0 16px;
  width: 100%;
`;

const DataContainer = styled(FlexColumnDirection)``;
const Row = styled(FlexRow)`
  align-items: center;
  margin-bottom: 13px;
`;
const ChevronContainer = styled(FlexRow)``;

const ProfilePictureContainer = styled(BaseView)`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  margin-right: 5px;
`;
const IconWrapper = styled(BaseView)`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray9};
`;

const EmployeePhoto = styled(BaseImage)`
  width: 24px;
  height: 24px;
  border-radius: 15px;
  margin-right: 10px;
`;

const EmployeeName = styled(H5Medium)`
  font-size: 14px;
  font-weight: normal;
`;

const IconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
`;

const CardDataDetails = styled(H5Regular)`
  margin-left: 5px;
  margin-right: 5px;
  font-size: 14px;
`;

const CardDataDetailsWarning = styled(CardDataDetails)`
  color: ${({ theme }) => theme.colors.error};
`;

export const SwapListRow: FC<Props> = ({ item }) => {
  const { rtl } = useContext(ConfigContext);
  const { navigate } = useNavigation();
  const { t } = useTranslation();

  const handleOnPress = useCallback(() => {
    navigate(SwapScreenNames.SWAP, {
      params: { ...item },
    });
  }, [item, navigate]);

  const formattedHours = useMemo(
    () => rangeFormat(item?.startTime, item?.endTime),
    [item]
  );

  return (
    <Container as={RipplePressable} onPress={handleOnPress}>
      <DataContainer>
        <Row>
          <ProfilePictureContainer>
            {item?.employee?.image ? (
              <EmployeePhoto source={{ uri: item?.employee?.image }} />
            ) : (
              <IconWrapper>
                <SvgIcon xml={icons?.uiIcons?.profile} />
              </IconWrapper>
            )}
          </ProfilePictureContainer>
          <EmployeeName>{`${item?.employee.firstName || ''} ${
            item?.employee.lastName || ''
          }`}</EmployeeName>
        </Row>
        <Row>
          <IconContainer>
            <SvgIcon xml={icons?.uiIcons?.clock2} height={18} width={18} />
          </IconContainer>
          <CardDataDetails>{formattedHours}</CardDataDetails>
          <IconContainer>
            <SvgIcon xml={icons?.uiIcons?.jobCase} height={18} width={18} />
          </IconContainer>
          <CardDataDetails>{t(item?.position?.slug) || '-'}</CardDataDetails>
        </Row>
        {(item?.warnings || []).map((warning) => (
          <Row>
            <CardDataDetailsWarning>{t(warning)}</CardDataDetailsWarning>
          </Row>
        ))}
      </DataContainer>
      <ChevronContainer>
        <ChevronRight size={30} rtl={rtl} />
      </ChevronContainer>
    </Container>
  );
};
