import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import {
  CAPTAIN_TYPE_ENUM,
  IQRShiftCaptain,
  useBranches,
  useCaptain,
  useLocations,
  usePositions,
} from '@violetta/ubeya/entities';
import { flatten } from 'lodash';
import {
  BaseView,
  CustomLoaderWrapped,
  EventInfoCard,
  FlexCenter,
  H4Medium,
  QrCodeModal,
  RipplePressable,
  SearchBox,
} from '@violetta/ubeya/ui';
import { openMap, rangeFormat } from '@violetta/ubeya/utils';
import { debounce } from 'lodash';
import moment from 'moment';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Image as BaseImage, RefreshControl, SectionList } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useRecoilState } from 'recoil';
import styled, { useTheme } from 'styled-components/native';
import { unsyncedTimesheetsAtom } from '../../../state';
import { ShiftHeaderComponent } from './components/ShiftHeaderComponent';
import { useTranslation } from '@violetta/ubeya/i18n';
import { getTimeRangeWithDates } from '@violetta/ubeya/shared';
import { ConfigContext } from '../../../contexts/ConfigContext';
import { QrCodeScannerScreenNames } from '../../qr-code-scanner/navigation/ScreenNames';
import { EmployeeCollapsible } from './components/Captain/EmployeeCollapsible';

const PaddedContainer = styled(BaseView)<{ $withImage: boolean }>`
  padding: 0 16px;
  z-index: 10;
  margin-top: ${({ $withImage }) => ($withImage ? '-100px' : '0')};
`;

const SearchContainer = styled(FlexCenter)`
  margin: 15px 15px;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  flex-grow: 0;
`;

const ImageContainer = styled(BaseView)`
  display: flex;
  z-index: 1;
  position: relative;
`;

const Image = styled(BaseImage)`
  height: 240px;
`;

const EmptySearchContainer = styled(BaseView)`
  margin-top: 30px;
`;
const EmptySearch = styled(H4Medium)`
  text-align: center;
  font-weight: 400;
`;

interface Props {}

type ParamList = {
  params: {
    bookingId: number;
    shiftDate: Date;
  };
};

export const CaptainScreen: FC<Props> = () => {
  const {
    params: { bookingId: bookingProjectId, shiftDate },
  } = useRoute<RouteProp<ParamList, 'params'>>();
  const { t } = useTranslation();
  const { timeFormat, dateFormat } = useContext(ConfigContext);

  const unsyncedTimesheetsState = useRecoilState(unsyncedTimesheetsAtom);
  const {
    team,
    mappedEmployees,
    updateShiftEmployee,
    updateOSShiftEmployee,
    isLoading,
    refetch,
    updateCaptainProject,
  } = useCaptain({
    projectId: bookingProjectId,
    unsyncedTimesheetsState,
    date: shiftDate,
  });
  const { project = {}, config = {} } = team || {};
  const { captainType, toRequireCheckInWithMobile, isCaptainAutoClock } =
    config || {};
  const { colors } = useTheme();
  const { mappedBranches } = useBranches();
  const { mappedLocations } = useLocations();
  const { mappedPositions } = usePositions();
  const { bottom } = useSafeAreaInsets();

  const [searchTerm, setSearchTerm] = useState('');
  const [showQrCodeModal, setShowQrCodeModal] = useState(false);

  const { navigate } = useNavigation();

  const location = mappedLocations[project?.locationId];

  const handleNavigateToScannerScreen = useCallback(() => {
    navigate(QrCodeScannerScreenNames.MAIN, {
      screen: QrCodeScannerScreenNames.SCANNER,
      params: {
        showCheckIn: toRequireCheckInWithMobile,
        showClockIn: captainType !== CAPTAIN_TYPE_ENUM.AREA,
        showClockOut: captainType !== CAPTAIN_TYPE_ENUM.AREA,
        projectId: bookingProjectId,
      },
    });
  }, [bookingProjectId, captainType, navigate, toRequireCheckInWithMobile]);

  const onEmployeeChange = useCallback(
    async ({ shiftId, employeeId, values, projectId, isExternal = false }) => {
      const updateFunc = isExternal
        ? updateShiftEmployee // updateOSShiftEmployee
        : updateShiftEmployee;

      await updateFunc({
        projectId,
        shiftId,
        employeeId,
        values,
      });
    },
    [updateShiftEmployee]
  );

  const onTimeChange = useCallback(
    async ({
      values,
      shiftId,
      employeeId,
      projectId,
      name,
      isExternal = false,
    }) => {
      if (
        !values?.startTime &&
        !values?.endTime &&
        !values?.checkInTime &&
        !values?.checkOutTime
      ) {
        return;
      }

      const {
        startTime: rawStartTime,
        checkInTime: rawCheckInTime,
        checkOutTime: rawCheckOutTime,
        endTime: rawEndTime,
      } = values || {};

      // normalize endTime
      const { startTime, endTime } = getTimeRangeWithDates({
        date: rawStartTime,
        startTime: rawStartTime,
        endTime: rawEndTime,
        checkBaseStartDate: true,
      });

      // normalize checkInTime
      const { startTime: checkInTime, endTime: checkOutTime } =
        getTimeRangeWithDates({
          date: rawCheckInTime,
          startTime: rawCheckInTime,
          endTime: rawCheckOutTime,
          checkBaseStartDate: true,
        });

      /* send only dirty to backend, but calculate according to what sent here */
      const formattedValues = {
        ...(name === 'startTime' && {
          startTime,
        }),
        ...(name === 'endTime' && {
          endTime,
        }),
        ...(name === 'checkInTime' && {
          checkInTime,
        }),
        ...(name === 'checkOutTime' && {
          checkOutTime,
        }),
      };

      await onEmployeeChange({
        shiftId,
        projectId,
        employeeId,
        values: formattedValues,
        isExternal,
      });
    },
    [onEmployeeChange]
  );

  const onFeedbackChange = debounce(
    useCallback(
      ({
        values,
        dirtyFields,
        shiftId,
        employeeId,
        projectId,
        isExternal = false,
      }) => {
        if (
          !dirtyFields?.rating &&
          !dirtyFields?.review &&
          !dirtyFields?.payrollNotes
        ) {
          return;
        }

        const formattedValues = {
          rating: dirtyFields?.rating ? values?.rating : undefined,
          review: dirtyFields?.review ? values?.review : undefined,
          payrollNotes: dirtyFields?.payrollNotes
            ? values?.payrollNotes
            : undefined,
        };
        onEmployeeChange({
          projectId,
          shiftId,
          employeeId,
          values: formattedValues,
          isExternal,
        });
      },
      [onEmployeeChange]
    ),
    1500
  );

  const shifts = useMemo(
    () => (team?.shifts || []).filter(({ employees }) => employees.length > 0),
    [team]
  );

  const allEmployees = useMemo(
    () => [
      ...(team?.shifts || []).map((shift) => shift?.employees).flat(),
      ...flatten(
        (team?.orders || [])
          .map(({ shifts }) => shifts?.map((shift) => shift?.employees))
          .flat()
      ),
    ],
    [team?.orders, team?.shifts]
  );

  const clockedIn = (allEmployees || []).reduce(
    (acc, emp) => acc + (emp?.startTime ? 1 : 0),
    0
  );
  const clockedOut = (allEmployees || []).reduce(
    (acc, emp) => acc + (emp?.endTime ? 1 : 0),
    0
  );

  const stats = useMemo(
    () => ({
      booked: allEmployees?.length || 0,
      clockedIn,
      clockedOut,
      needed: undefined,
    }),
    [allEmployees?.length, clockedIn, clockedOut]
  );

  const filterEmployeesBySearch = useCallback(
    ({ firstName, lastName }) =>
      searchTerm
        ? firstName
            ?.toLocaleLowerCase()
            ?.startsWith(searchTerm?.toLocaleLowerCase()) ||
          lastName
            ?.toLocaleLowerCase()
            ?.startsWith(searchTerm?.toLocaleLowerCase())
        : true,

    [searchTerm]
  );

  useEffect(() => {
    refetch();
  }, [refetch]); // refetch data since it might be kept in offline mode

  const internalShifts = useMemo(
    () => (team?.shifts || []).map(({ id }) => id),
    [team?.shifts]
  );
  const externalShifts = useMemo(
    () =>
      (team?.orders || [])
        .map(({ shifts }) => (shifts || []).map((shift) => shift?.id))
        .flat(),
    [team?.orders]
  );

  const qrCodeData = useMemo(
    () => ({
      branchId: project?.branchId,
      shiftIds: [...internalShifts, ...externalShifts],
    }),
    [externalShifts, internalShifts, project?.branchId]
  ) as IQRShiftCaptain;

  const areaName = useMemo(() => {
    const locationsSet = new Set(
      (team?.shifts || [])
        .filter(({ locationId }) => !!locationId)
        .map(({ locationId }) => locationId)
    );
    if (locationsSet.size !== 1) {
      return null;
    }
    const locationId = team?.shifts?.[0].locationId;
    return locationId && mappedLocations?.[locationId]?.name;
  }, [mappedLocations, team?.shifts]);

  const internalSections = useMemo(
    () =>
      flatten(
        shifts.map((shift) => {
          // allShiftSlotTimes - all times inside a shift
          const allShiftSlotTimes = [
            ...new Set(
              shift.employees.map((e) =>
                rangeFormat(e.slot?.startTime, e.slot?.endTime)
              )
            ),
          ] as (string | null)[];

          // dictionary of  time with all employees slots in it +  filter by search term
          const employeesBySlotTime = allShiftSlotTimes.reduce(
            (output, time) => {
              output[time] = shift.employees
                .filter(
                  (e) =>
                    rangeFormat(e.slot?.startTime, e.slot?.endTime) === time
                )
                .filter(({ employeeId }) =>
                  filterEmployeesBySearch({
                    firstName: mappedEmployees?.[employeeId]?.firstName,
                    lastName: mappedEmployees?.[employeeId]?.lastName,
                  })
                );

              return output;
            },
            {}
          );

          return allShiftSlotTimes.map((time) => {
            const currEmployees = employeesBySlotTime[time].map((employee) => ({
              ...employee,
              employeeObject: mappedEmployees[employee.employeeId],
            }));
            currEmployees.sort(
              ({ employeeId: employeeIdA }, { employeeId: employeeIdB }) =>
                `${mappedEmployees[employeeIdA]?.firstName}${mappedEmployees[employeeIdA]?.lastName}`.localeCompare(
                  `${mappedEmployees[employeeIdB]?.firstName}${mappedEmployees[employeeIdB]?.lastName}`
                )
            );

            const shiftLocation = mappedLocations?.[shift.locationId];
            const projectLocation = mappedLocations?.[project.locationId];
            const position = mappedPositions?.[shift.positionid]?.slug;

            const { startTime, endTime, checkInTime, checkOutTime } = shift;
            const employeeShift = {
              startTime,
              endTime,
              shiftLocation,
              projectLocation,
              position,
              shift,
            };

            const sectionData = {
              showLocation: project.locationId !== shift.locationId,
              showDate: !!project.isMultiDay,
              startTime: currEmployees?.[0]?.slot?.startTime,
              endTime: currEmployees?.[0]?.slot?.endTime,
              positionId: shift?.positionId,
              comments: shift?.comments,
              locationId: shift?.locationId,
              employees: currEmployees,
              date: shift?.date,
              employeeShift,
              shiftId: shift?.id,
              projectId: project.id,

              // shiftLocation,
              // projectLocation,
              // position,
            };

            return { sectionData, data: currEmployees };
          });
        })
      ),
    [
      filterEmployeesBySearch,
      mappedEmployees,
      mappedLocations,
      mappedPositions,
      project.id,
      project.isMultiDay,
      project.locationId,
      shifts,
    ]
  );

  const externalSections = useMemo(
    () =>
      flatten(
        (team?.orders || []).map((order) => {
          return flatten(
            (order?.shifts || []).map((shift) => {
              const allOrderShiftSlotTimes = [
                ...new Set(
                  shift.employees.map((e) =>
                    rangeFormat(e.slot?.startTime, e.slot?.endTime)
                  )
                ),
              ] as (string | null)[];

              const employeesByOrderSlotTime = allOrderShiftSlotTimes.reduce(
                (output, time) => {
                  output[time] = shift.employees
                    .filter(
                      (e) =>
                        rangeFormat(e.slot?.startTime, e.slot?.endTime) === time
                    )
                    .filter(({ firstName, lastName }) =>
                      filterEmployeesBySearch({ firstName, lastName })
                    );
                  return output;
                },
                {}
              );

              return allOrderShiftSlotTimes.map((time) => {
                const currOrderEmployees = employeesByOrderSlotTime[time].map(
                  (employee) => ({
                    ...employee,
                    shiftId: employee.supplierShiftId,
                    projectId: employee.supplierProjectId,
                    employeeObject: {
                      id: employee.employeeId,
                      firstName: employee.firstName,
                      lastName: employee.lastName,
                      image: employee.image,
                      userId: employee.userId,
                      phone: employee.phone,
                    },
                  })
                );

                currOrderEmployees.sort(
                  (
                    { firstName: firstNameA, lastName: lastNameA },
                    { firstName: firstNameB, lastName: lastNameB }
                  ) =>
                    `${firstNameA}${lastNameA}`.localeCompare(
                      `${firstNameB}${lastNameB}`
                    )
                );

                const sectionData = {
                  supplier: order.supplier,
                  showLocation: true,
                  showDate: !!project.isMultiDay,
                  startTime: currOrderEmployees?.[0]?.slot?.startTime,
                  endTime: currOrderEmployees?.[0]?.slot?.endTime,
                  // positionId: shift?.positionId,
                  position: shift?.position,
                  // location: shift?.location,
                  comments: shift?.comments,
                  locationId: shift?.locationId,
                  employees: currOrderEmployees,
                  date: shift?.date,
                  employeeShift: shift,
                  projectId: shift?.projectId,
                  shiftId: shift?.id,
                  isExternal: true,
                };

                return { sectionData, data: currOrderEmployees };
              });
            })
          );
        })
      ),
    [filterEmployeesBySearch, project.isMultiDay, team?.orders]
  );

  const sections = useMemo(
    () =>
      [...internalSections, ...externalSections].filter(
        ({ data }) => data?.length > 0
      ),
    [externalSections, internalSections]
  );

  const totalResults = useMemo(() => {
    return (sections || []).reduce((output, section) => {
      return output + (section?.data || []).length;
    }, 0);
  }, [sections]);

  if (!project) {
    return <CustomLoaderWrapped />;
  }
  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flexGrow: 1, paddingBottom: bottom }}
      style={{ backgroundColor: colors.background }}
      extraScrollHeight={200}
      refreshControl={
        <RefreshControl onRefresh={refetch} refreshing={isLoading} />
      }
    >
      <SearchContainer>
        <SearchBox
          searchTerm={searchTerm}
          onChange={setSearchTerm}
          placeholder={t('search')}
        />
      </SearchContainer>

      {location?.image && (
        <ImageContainer as={RipplePressable} onPress={() => openMap(location)}>
          <Image
            source={{ uri: location.image }}
            resizeMode="cover"
            resizeMethod="scale"
          />
        </ImageContainer>
      )}

      <PaddedContainer $withImage={location?.image}>
        <EventInfoCard
          timeFormat={timeFormat}
          dateFormat={dateFormat}
          shiftDate={shiftDate}
          project={project}
          updateProject={({ values }) =>
            updateCaptainProject({ projectId: project?.id, values })
          }
          location={location}
          stats={stats}
          showScanQrCode={handleNavigateToScannerScreen}
          showPresentQrCode={() => setShowQrCodeModal(true)}
        />
        {totalResults > 50 ? (
          <EmptySearchContainer>
            <EmptySearch>{t('captainEmptySearch')}</EmptySearch>
          </EmptySearchContainer>
        ) : (
          <SectionList
            contentContainerStyle={{ flexGrow: 1 }}
            sections={sections}
            refreshing={isLoading}
            renderSectionHeader={({ section }) => {
              const { sectionData } = section;
              const {
                startTime,
                endTime,
                positionId,
                comments,
                locationId,
                date,
                employees,
                showDate,
                showLocation,
                position,
                location,
                supplier,
              } = sectionData;
              return (
                <ShiftHeaderComponent
                  showDate={showDate}
                  showLocation={showLocation}
                  supplier={supplier}
                  shift={{
                    startTime,
                    endTime,
                    positionId,
                    comments,
                    locationId,
                    employees,
                    date,
                    position,
                    location,
                  }}
                />
              );
            }}
            // ListEmptyComponent={
            //   <EmptyState
            //     hide={isLoading}
            //     lottieSrc={lotties.noUpcomingShifts}
            //     title={t('emptyStateBookings')}
            //   />
            // }
            // refreshControl={
            //   <RefreshControl
            //     onRefresh={refetchSwapEmployees}
            //     refreshing={isLoading}
            //   />
            // }
            // keyExtractor={(props) => props.id.toString()}
            renderItem={({ section, item }) => {
              const {
                employeeId,
                startTime,
                endTime,
                checkInTime,
                checkOutTime,
                rating,
                review,
                payrollNotes,
                employeeObject,
              } = item;
              const { sectionData } = section;
              const { employeeShift, isExternal, projectId, shiftId } =
                sectionData;
              return (
                <EmployeeCollapsible
                  key={`${shiftId}-${employeeId}`}
                  employee={employeeObject}
                  isCaptainAutoClock={isCaptainAutoClock}
                  toRequireCheckInWithMobile={toRequireCheckInWithMobile}
                  allowClockIn={captainType !== CAPTAIN_TYPE_ENUM.AREA}
                  allowClockOut={captainType !== CAPTAIN_TYPE_ENUM.AREA}
                  startTime={startTime ? moment(startTime).toDate() : null}
                  endTime={endTime ? moment(endTime).toDate() : null}
                  checkInTime={
                    checkInTime ? moment(checkInTime).toDate() : null
                  }
                  checkOutTime={
                    checkOutTime ? moment(checkOutTime).toDate() : null
                  }
                  rating={rating}
                  review={review}
                  payrollNotes={payrollNotes}
                  shift={employeeShift}
                  showNotes={false}
                  onUpdateTime={({ values, name }) =>
                    onTimeChange({
                      projectId,
                      shiftId,
                      employeeId,
                      values,
                      name,
                      isExternal,
                    })
                  }
                  onUpdateSlot={({ values, dirtyFields }) =>
                    onFeedbackChange({
                      projectId,
                      shiftId,
                      employeeId,
                      values,
                      dirtyFields,
                      isExternal,
                    })
                  }
                />
              );
            }}
          />
        )}
      </PaddedContainer>
      {showQrCodeModal && (
        <QrCodeModal
          title={location?.name}
          subTitle={areaName}
          qrCodeData={qrCodeData}
          onClose={() => setShowQrCodeModal(false)}
        />
      )}
    </KeyboardAwareScrollView>
  );
};
