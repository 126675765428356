import React, { FC } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  FlexRowBetween,
  FlexRowCenter,
  H1Light,
  H2Regular,
  H4Regular,
} from '@violetta/ubeya/ui';
import { useDuration } from '@violetta/ubeya/utils';
import styled from 'styled-components/native';

const Container = styled(BaseView)`
  display: flex;
`;
const TimeContainer = styled(BaseView)``;
const TextContainer = styled(FlexRowCenter)``;

const Clock = styled(H1Light)`
  margin-top: 5px;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 1.3px;
  line-height: 36px;
`;

const ClockText = styled(H4Regular)`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 18px;
`;

export interface NotActiveTimeclockProps {
  type: 'not-active';
  scheduledStartTime: Date | null;
  onCardPress(): void;
}

export const NotActiveTimeclockCardBody: FC<NotActiveTimeclockProps> = ({
  scheduledStartTime,
  onCardPress,
}) => {
  const { t } = useTranslation();
  const { clock, isFuture } = useDuration(scheduledStartTime);

  return (
    <Container>
      {scheduledStartTime && isFuture ? (
        <TimeContainer>
          <ClockText style={{ textAlign: 'left' }}>
            {t('yourNextShiftStartsIn')}
          </ClockText>
        </TimeContainer>
      ) : (
        <TextContainer>
          <H2Regular style={{ textAlign: 'left' }}>
            {t('yourNextJob')}
          </H2Regular>
        </TextContainer>
      )}
      {scheduledStartTime && (
        <FlexRowBetween>
          {clock && <Clock>{clock}</Clock>}
          <Button
            type="primary"
            title={t('startClock')}
            onPress={onCardPress}
          />
        </FlexRowBetween>
      )}
    </Container>
  );
};
