import React, { FC, useMemo } from 'react';
import { useUser } from '@violetta/ubeya/auth';
import { useTranslation } from '@violetta/ubeya/i18n';
import { timeOfDay } from '@violetta/ubeya/utils';
import { useTimeclock } from '../hooks';
import { TimeclockContent } from './TimeclockContent';

interface Props {
  timesheetId: number;
}

export const TimeclockWrapper: FC<Props> = ({ timesheetId }) => {
  const { t } = useTranslation();
  const { data } = useUser();
  const partOfDay = useMemo(() => timeOfDay(), []);

  const title = useMemo(
    () =>
      `${t(`${partOfDay}Greeting`)} ${data?.firstName ? data.firstName : ''}`,
    [data?.firstName, partOfDay, t]
  );

  const {
    state,
    changeState,
    validate,
    reports,
    breaktimes,
    duration,
    timesheet,
    changing,
    employeeSlotBreakPlots,
    config,
    refetch,
    isLoadingRefetch,
  } = useTimeclock(timesheetId);

  return (
    <TimeclockContent
      title={title}
      state={state}
      validate={validate}
      changeState={changeState}
      reports={reports}
      breaktimes={breaktimes}
      timesheetId={timesheetId}
      employeeSlotBreakPlots={employeeSlotBreakPlots}
      timesheet={timesheet}
      duration={duration}
      changing={changing}
      config={config}
      refetch={refetch}
      isLoadingRefetch={isLoadingRefetch}
    />
  );
};
