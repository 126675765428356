import React from 'react';
import { BaseToast, ToastConfig } from 'react-native-toast-message';
import { colors, theme } from '../style';

export const toastConfig = {
  success: ({ text1, text2, ...rest }) => (
    <BaseToast
      style={{ borderLeftColor: colors.green3, borderLeftWidth: 14 }}
      text1NumberOfLines={2}
      text2NumberOfLines={1}
      {...rest}
      contentContainerStyle={{
        paddingHorizontal: 15,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
      renderTrailingIcon={undefined}
      text1Style={{
        fontSize: 16,
        fontFamily: theme.fonts.regular.family,
        fontWeight: theme.fonts.regular.weight.toString(),
        lineHeight: 20,
      }}
      text2Style={{ color: colors.green3, fontSize: 14, lineHeight: 18 }}
      text1={text1}
      text2={text2}
    />
  ),
  error: ({ text1, text2, ...rest }) => (
    <BaseToast
      style={{ borderLeftColor: colors.error, borderLeftWidth: 14 }}
      text2Style={{ color: colors.error, fontSize: 14, lineHeight: 18 }}
      text1NumberOfLines={2}
      text2NumberOfLines={1}
      {...rest}
      contentContainerStyle={{
        paddingHorizontal: 15,
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
      text1Style={{
        fontSize: 16,
        fontFamily: theme.fonts.regular.family,
        fontWeight: theme.fonts.regular.weight.toString(),
        lineHeight: 20,
      }}
      text1={text1}
      text2={text2}
      renderTrailingIcon={undefined}
    />
  ),
} as ToastConfig;
