import React, { FC } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { IChatRoom } from '@violetta/ubeya/entities';
import {
  BaseView,
  SvgIcon,
  FlexRow,
  H3Regular,
  H4Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { Image as BaseImage } from 'react-native';
import styled from 'styled-components/native';

const Container = styled(FlexRow)`
  justify-content: center;
  padding: 0 24px;
`;

const Title = styled(H3Regular)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
  flex-wrap: wrap;
  flex: 1;
`;

const Body = styled(BaseView)`
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray11};
  flex: 1;
  justify-content: center;
  margin-left: 16px;
  padding-bottom: 18px;
`;
const Content = styled(FlexRow)`
  justify-content: space-between;
`;
const Details = styled(FlexRow)`
  align-items: center;
  padding-bottom: 8px;
`;
const LastMessage = styled(H4Regular)`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.14px;
  color: ${({ theme }) => theme.colors.gray12};
  width: 60%;
`;
const LastMessageTime = styled(H4Regular)`
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.12px;
  text-align: right;
  color: ${({ theme }) => theme.colors.gray12};
`;

const Image = styled(BaseImage)`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-top: 5px;
`;

const Badge = styled(BaseView)`
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-right: 10px;

  border-radius: 9px;
  background-color: ${({ theme }) => theme.colors.primary};
`;
const BadgeText = styled(H4Regular)`
  text-align: center;
  color: ${({ theme }) => theme.colors.surface};
  font-size: 12px;
`;
interface Props {
  room: IChatRoom;
  onSelected?: (roomId: string) => void;
}

export const RoomRow: FC<Props> = ({ room, onSelected }) => {
  const { name, lastMessagePayload, image, unread } = room;

  return (
    <Container as={RipplePressable} onPress={() => onSelected?.(room.id)}>
      {image ? (
        <Image source={{ uri: image }} />
      ) : (
        <SvgIcon
          xml={icons.uiIcons.employeePlaceholder}
          width={50}
          height={50}
        />
      )}
      <Body>
        <Details>
          <Title>{name}</Title>
          <LastMessageTime>
            {lastMessagePayload?.message?.timestamp &&
              moment(lastMessagePayload?.message?.timestamp).fromNow()}
          </LastMessageTime>
        </Details>

        <Content>
          <LastMessage numberOfLines={1}>
            {lastMessagePayload
              ? `${lastMessagePayload?.sender?.firstName}: ${lastMessagePayload?.message?.message}`
              : 'No Messages'}
          </LastMessage>
          {unread && (
            <Badge>
              <BadgeText />
            </Badge>
          )}
        </Content>
      </Body>
    </Container>
  );
};
