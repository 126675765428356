import React, { FC, useContext } from 'react';
import {
  BaseView,
  ChevronRight,
  FlexRow,
  FlexRowCenter,
  H5Medium,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../../contexts/ConfigContext';

const Container = styled(FlexRow)`
  padding: 20px 0;
  border-top-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray11};
  align-items: center;
`;

const Content = styled(FlexRowCenter)`
  flex: 1;
  align-items: center;
`;

const Title = styled(H5Medium)`
  font-weight: 500;
  margin-left: 20px;
`;
const IconWrapper = styled(BaseView)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray9};
`;

interface Props {
  title: string;
  icon?: {
    xml: string;
    width: number;
    height: number;
  };
  image?: FC<any>;
  iconFull?: boolean;
  onPress?: () => void;
}

export const NavCard: FC<Props> = ({
  icon,
  image: Image,
  iconFull = false,
  title,
  onPress = () => {},
}) => {
  const { rtl } = useContext(ConfigContext);

  return (
    <Container as={RipplePressable} onPress={onPress}>
      <Content>
        {icon && !iconFull && (
          <IconWrapper>
            <SvgIcon xml={icon.xml} width={20} height={20} />
          </IconWrapper>
        )}
        {icon && iconFull && <SvgIcon xml={icon.xml} width={40} height={40} />}
        {Image && <Image />}
        <Title>{title}</Title>
      </Content>
      <ChevronRight size={25} rtl={rtl} />
    </Container>
  );
};
