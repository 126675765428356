import React, { FC } from 'react';

import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NetworkErrorScreen } from '../screens';
import { ErrorScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const NetworkErrorNavigation: FC = () => (
  <NavigationContainer>
    <Navigator
      initialRouteName={ErrorScreenNames.NETWORK_ERROR}
      screenOptions={{
        headerShown: false,
        // eslint-disable-next-line react/jsx-closing-bracket-location
      }}
    >
      <Screen
        name={ErrorScreenNames.NETWORK_ERROR}
        component={NetworkErrorScreen}
      />
    </Navigator>
  </NavigationContainer>
);
