import React, { FC, useMemo, useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { IconClose } from '../Icons';
import { BaseView, FlexColumnDirection, FlexRowCenter } from '../Layout';
import { H3Bold, H4Medium } from '../Typography';
import { GalleryItem } from './GalleryItem';
import { IGalleryItem } from './IGalleryItem';

interface Props {
  items: IGalleryItem<any>[];
  index: number;
  next: () => void;
  back: () => void;
  hide: () => void;
}

const Container = styled(FlexColumnDirection)`
  flex: 1;
`;

const Empty = styled(BaseView)`
  height: 15px;
  width: 15px;
`;

export const useGallery = ({
  items,
  idx = 0,
}: {
  items: IGalleryItem<any>[];
  idx?: number;
}) => {
  const [index, setIndex] = useState<number>(idx);
  const { goBack } = useNavigation();

  const next = useCallback(() => {
    setIndex((index) => (index < items.length - 1 ? index + 1 : index));
  }, [items.length]);

  const back = useCallback(() => {
    setIndex((index) => (index > 0 ? index - 1 : index));
  }, []);

  const hide = useCallback(() => {
    goBack();
  }, [goBack]);

  return {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    Gallery: () => (
      <Gallery
        items={items}
        index={index}
        next={next}
        back={back}
        hide={hide}
      />
    ),
    hide,
    next,
    back,
  };
};

const Header = styled(FlexRowCenter)`
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const Amount = styled(H3Bold)`
  margin-right: -30px;
`;

const GalleryItemContainer = styled(FlexColumnDirection)<{ visible: boolean }>`
  background-color: ${({ theme }) => theme.colors.surface};
  flex: 1;
  padding-top: 50px;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
`;

export const Gallery: FC<Props> = ({ items, index, next, back, hide }) => {
  const onSwap = useCallback(
    (dir) => (dir === 'left' ? back() : next()),
    [back, next]
  );

  const emptyOrSingleItem = useMemo(() => items?.length <= 1, [items]);

  return (
    // <GestureRecognizer
    // 	onSwipeLeft={back}
    // 	onSwipeRight={next}
    // 	style={{ flex: 1 }}>
    <Container>
      {items.map((item, idx) => (
        <GalleryItemContainer key={idx} visible={idx === index}>
          <GalleryItem
            item={item}
            onSwap={onSwap}
            singleItem={emptyOrSingleItem}
          />
          <Header>
            <Empty />
            <Amount>
              {index + 1}/{items.length}
            </Amount>
            <IconClose color="#000" size={22} onPress={hide} />
          </Header>
        </GalleryItemContainer>
      ))}
    </Container>
    // </GestureRecognizer>
  );
};
