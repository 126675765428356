import { InferProps } from '@violetta/ubeya/utils';
import React, { FC, forwardRef, Ref } from 'react';
import { FieldMetaState } from 'react-final-form';
import { TextInput as OriginalTextInput } from 'react-native';
import styled from 'styled-components/native';
import { BaseView } from '../Layout';
import { BaseText } from '../Typography';

export const BaseTextInput = styled.TextInput`
  font-family: ${({ theme }) => theme.fonts.regular.family};
  font-weight: ${({ theme }) => theme.fonts.regular.weight};
  font-size: 15px;
`;

const Container = styled(BaseView)`
  width: 100%;
`;

const ErrorMessage = styled(BaseText)`
  color: ${({ theme }) => theme.colors.red1};
  margin-top: 5px;
`;

export const AdaptedTextInput = styled(BaseTextInput)<{
  error?: boolean;
  numberOfLines?: number;
}>`
  border-width: 1px;
  border-radius: 3px;
  height: ${({ numberOfLines }) => `${(numberOfLines || 1) * 44}px`};
  border-color: ${({ error, theme }) =>
    error ? theme.colors.red1 : theme.colors.inputBorder};
  background-color: ${({ theme }) => theme.colors.white};
  padding: 10px;
  align-items: flex-start;
  justify-content: flex-start;
  text-align-vertical: top;
`;

export const TextInput: FC<
  InferProps<OriginalTextInput> & {
    ref?: Ref<any>;
    error?: string;
    hideError?: boolean;
    meta?: FieldMetaState<string>;
  }
> = forwardRef<any>(({ children, meta, hideError = false, ...props }, ref) => (
  <Container>
    <AdaptedTextInput {...props} ref={ref} maxFontSizeMultiplier={1}>
      {children}
    </AdaptedTextInput>
    {!hideError && meta?.error && meta?.touched && (
      <ErrorMessage>{meta?.error}</ErrorMessage>
    )}
  </Container>
));
