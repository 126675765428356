import React, { useCallback } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { IconClose, RipplePressable, SvgIcon } from '@violetta/ubeya/ui';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import { searchStateAtom } from '../../../state';

const IconContainer = styled(RipplePressable)`
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-left: 15px;
  border-radius: 10px;
`;

export const SwapHeaderRightContent = React.memo(() => {
  const [searchState, setSearchState] = useRecoilState(searchStateAtom);

  const openSearchBar = useCallback(
    () =>
      setSearchState(
        searchState.isSearchOpen
          ? { isSearchOpen: false, searchKey: '' }
          : { ...searchState, isSearchOpen: !searchState.isSearchOpen }
      ),
    [searchState, setSearchState]
  );
  return (
    <IconContainer onPress={openSearchBar}>
      <SvgIcon
        xml={
          searchState.isSearchOpen
            ? icons.actionIcons.xDisabled
            : icons.symbolIcons.searchIcon
        }
        width={20}
        height={20}
        color="#000000"
      />
    </IconContainer>
  );
});
