import React, { FC, useContext } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  ChevronRight,
  FlexCenter,
  H7Medium,
  BaseView,
  FlexRow,
  FlexRowCenter,
  H5Medium,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';

import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts/ConfigContext';

const Container = styled(FlexRow)`
  margin: 0;
  padding: 15px 10px;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray11};
`;

const Content = styled(FlexRowCenter)``;
const TextContainer = styled(BaseView)`
  margin-left: 20px;
  max-width: 250px;
`;

const Disabled = styled(H5Medium)`
  font-size: 15px;
  margin-top: 1px;
  color: ${({ theme }) => theme.colors.gray2};
`;
const Title = styled(H5Medium)<{ $disabled: boolean }>`
  font-weight: 500;
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.gray2 : theme.colors.gray1};
`;

const IconContainer = styled(FlexCenter)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.gray11};
`;

const ConfirmedChip = styled(BaseView)`
  margin-left: 8px;
  background-color: ${({ theme }) => theme.colors.seconday2};
  padding: 3px 8px;
  border-radius: 6px;
`;

const ConfirmedChipText = styled(H7Medium)`
  color: ${({ theme }) => theme.colors.surface};
  font-size: 9px;
  font-weight: 500;
  text-align: center;
`;

interface Icon {
  xml: string;
  height: number;
  width: number;
  color?: string;
}
interface Props {
  onPress?: () => void;
  icon: Icon;
  suffixIcon?: Icon;
  title: string;
  chipSlug?: string;
  disabled?: boolean;
  disbaledText?: string;
}

export const BookingActionCard: FC<Props> = ({
  onPress,
  icon: { xml, width, height },
  suffixIcon,
  title,
  chipSlug,
  disabled = false,
  disbaledText,
}) => {
  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();
  return (
    <Container as={RipplePressable} onPress={disabled ? undefined : onPress}>
      <Content>
        <IconContainer>
          <SvgIcon
            xml={xml}
            width={width || 15}
            height={height || 15}
            color="#6d6d78"
          />
        </IconContainer>
        <TextContainer>
          <Title $disabled={disabled}>{title}</Title>
          {disabled && disbaledText && <Disabled>{disbaledText}</Disabled>}
        </TextContainer>
      </Content>
      {chipSlug && (
        <ConfirmedChip>
          <ConfirmedChipText>{t(chipSlug).toUpperCase()}</ConfirmedChipText>
        </ConfirmedChip>
      )}
      {suffixIcon && (
        <IconContainer>
          <SvgIcon
            xml={suffixIcon?.xml}
            width={suffixIcon?.width || 15}
            height={suffixIcon?.height || 15}
            color="#6d6d78"
          />
        </IconContainer>
      )}
      {!disabled && <ChevronRight size={20} rtl={rtl} />}
    </Container>
  );
};
