import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import moment from 'moment';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { Button } from '../Buttons';
import { SvgIcon } from '../Icons/SvgIcon';
import {
  BaseView,
  FlexCenter,
  FlexColumn,
  FlexColumnCenter,
  FlexRow,
  FlexRowBetween,
  FlexRowEvenly,
  RipplePressable,
} from '../Layout';
import { TimeLabelContainerProps, TimePicker } from '../TimePicker';
import {
  BaseText,
  H4Bold,
  H4Light,
  H4Medium,
  H5Medium,
  H6Medium,
} from '../Typography';
import { Modal } from './Modal';
import { BaseSelect } from '../Select/BaseSelect';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import { BaseImage } from '../Images/Image';
import { t } from 'i18next';
import { ClockTimePicker } from '../Clock/ClockTimePicker';
import { clockApiKeyToSlug } from '@violetta/ubeya/shared';

type Times = 'startTime' | 'endTime' | 'checkInTime' | 'checkOutTime';

const Content = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ModalView = styled(Pressable)`
  margin-bottom: 50px;
  background-color: white;
  border-radius: 8px;
  width: 95%;
`;

const HeaderText = styled(H6Medium)`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
`;

const SubheaderText = styled(H6Medium)`
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  color: #7d7d7d;
  margin-bottom: 15px;
  line-height: 24px;
`;

const ImageContainer = styled(BaseView)`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
`;

const ModalHeader = styled(FlexRowBetween)`
  padding: 15px 20px;
`;

const ButtonContainer = styled(BaseView)`
  margin-top: 20px;
`;
const PickerContainer = styled(BaseView)`
  padding: 15px 0 20px 0;
`;

const OnTimeContainer = styled(FlexColumn)`
  padding: 10px 0;
  align-items: flex-end;
`;

const OnTimeLink = styled(H4Light)`
  font-size: 15px;
  color: #6467ef;
  font-weight: 500;
`;

const Error = styled(H5Medium)`
  font-size: 15px;
  text-align: center;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.error};
  margin-bottom: 20px;
`;

interface Props {
  isOpen: boolean;
  time?: any;
  shiftTimes?: {
    startTime?: Date | null;
    endTime?: Date | null;
    checkInTime?: Date | null;
    checkOutTime?: Date | null;
  };
  onTimeChange: (values: any) => void;
  onClose: () => void;
  employeeName: string;
  name: Times;
  values: {
    startTime?: Date | null;
    endTime?: Date | null;
  };
  isUpdatingEmployee: boolean;
  projectLocation?: string | null;
  shiftLocation?: string | null;
  position?: string | null;
  employeePhoto?: string | null;
  logo?: string | null;
  showCheckIn?: booelan;
  showClockIn?: booelan;
  showClockOut?: booelan;
}

const TimeLabelContainer = styled(FlexRow)`
  border-width: 1px;
  border-radius: 10px;
  border-color: #e6eaf0;
  background-color: #f3f4f8;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

const TimeSection = styled(FlexColumnCenter)`
  width: 80px;
`;
const TimeLabel = styled(H4Light)`
  font-size: 20px;
  font-weight: 400;
  flex: 1;
  text-align: center;
`;

const TimePickerComponent: FC<TimeLabelContainerProps> = React.memo(
  ({ setShow, value, placeholder }) => {
    return (
      <TimeLabelContainer as={Pressable} onPress={() => setShow(true)}>
        <TimeSection />
        <TimeLabel>
          {moment(value).isValid()
            ? moment(value).format('LT')
            : placeholder || '-'}
        </TimeLabel>
        <TimeSection>
          <SvgIcon
            width={16}
            height={16}
            xml={icons.actionIcons.edit}
            color="#9aa4ab"
          />
        </TimeSection>
      </TimeLabelContainer>
    );
  }
);

const Summary = styled(FlexRow)`
  margin-top: 10px;
  margin-bottom: 5px;
`;
const SummaryData = styled(FlexColumn)`
  flex: 1;
`;
const SummaryItemEmployeeName = styled(H4Medium)`
  text-align: left;
  font-weight: 400;
  margin-left: 10px;
`;

const SummaryItemTitle = styled(H6Medium)<{ bold?: boolean }>`
  flex: 1;
  ${({ bold }) => bold && `font-weight:500`};
`;

const AreaName = styled(H6Medium)`
  font-weight: 500;
  text-align: center;
  padding: 15px 0;
  font-size: 16px;
  line-height: 22px;
`;

const SummaryProfileImage = styled.Image`
  height: 100px;
  width: 100px;
  border-radius: 5px;
  border-color: ${({ theme }) => theme.colors.surface};
`;

const Separator = styled(FlexColumnCenter)`
  background-color: ${({ theme }) => theme.colors.gray11};
  width: 100%;
  height: 1px;
  margin: 10px 0;
`;

const SummaryItem = styled(FlexRow)`
  margin-bottom: 3px;
  margin-bottom: 10px;
`;
const SummaryIconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 20px;
`;

const BranchLogo = styled(BaseImage)`
  width: 18px;
  height: 18px;
  border-radius: 9;
`;

const UserDetails: FC<{
  projectLocation?: string | null;
  shiftLocation?: string | null;
  position?: string | null;
  employeePhoto?: string | null;
  employeeName?: string | null;
  logo?: string | null;
}> = React.memo(
  ({
    projectLocation,
    shiftLocation,
    position,
    employeeName,
    employeePhoto,
    logo,
  }) => {
    const { t } = useTranslation();
    return (
      <Summary>
        <SummaryData>
          {/* <SummaryItem>
            <SummaryIconContainer>
              <BranchLogo source={{ uri: logo }} />
            </SummaryIconContainer>
          </SummaryItem> */}
          {employeeName && (
            <SummaryItem>
              <SummaryItemEmployeeName>{employeeName}</SummaryItemEmployeeName>
            </SummaryItem>
          )}
          {position && (
            <SummaryItem>
              <SummaryIconContainer style={{ marginLeft: -7, marginRight: 2 }}>
                <SvgIcon
                  xml={icons?.uiIcons?.personIcon}
                  height={17}
                  width={17}
                />
              </SummaryIconContainer>
              <SummaryItemTitle>{t(position.slug)}</SummaryItemTitle>
            </SummaryItem>
          )}
          {shiftLocation && (
            <SummaryItem>
              <SummaryIconContainer>
                <SvgIcon
                  xml={icons?.uiIcons?.locationOutline}
                  height={18}
                  width={18}
                />
              </SummaryIconContainer>
              {shiftLocation && (
                <SummaryItemTitle>{shiftLocation.name}</SummaryItemTitle>
              )}
            </SummaryItem>
          )}
        </SummaryData>
        {employeePhoto ? (
          <SummaryProfileImage source={{ uri: employeePhoto }} />
        ) : (
          <></>
        )}
      </Summary>
    );
  }
);

export const TimeChangeModal: FC<Props> = React.memo((props) => {
  const { isOpen, values, onClose } = props;
  const modalizeRef = useRef<Modalize>(null);

  useEffect(() => {
    if (isOpen) {
      modalizeRef.current?.open();
    } else {
      modalizeRef.current?.close();
    }
  }, [values, isOpen]);

  return (
    <Portal>
      <Modalize
        ref={modalizeRef}
        adjustToContentHeight
        withHandle={false}
        onClosed={onClose}
        HeaderComponent={
          <ModalHeader>
            <HeaderText>{t('timeReport')}</HeaderText>
            <ImageContainer
              as={RipplePressable}
              onPress={() => {
                onClose();
              }}
            >
              <SvgIcon width={16} height={16} xml={icons.actionIcons.x} />
            </ImageContainer>
          </ModalHeader>
        }
      >
        {isOpen && <TimeChangeModalContent {...props} />}
      </Modalize>
    </Portal>
  );
});

export const TimeChangeModalContent: FC<Props> = React.memo(
  ({
    isOpen,
    employeeName,
    shiftTimes,
    onTimeChange,
    onClose,
    name,
    values,
    onStateChange,
    projectLocation,
    shiftLocation,
    position,
    employeePhoto,
    logo,
    showCheckIn,
    showClockIn,
    showClockOut,
  }) => {
    const [currentName, setCurrentName] = useState<Times>(name);

    const shiftTime: Date | undefined | null = shiftTimes?.[currentName];

    const now = useMemo(
      () =>
        moment(
          shiftTime ||
            (values.startTime === null ? undefined : values.startTime)
        )
          .set('hours', moment().hours())
          .set('minutes', moment().minutes())
          .toDate(),
      [shiftTime, values.startTime]
    );

    const { t } = useTranslation();
    const [selectedTime, setSelectedTime] = useState<Date | undefined>(
      values[currentName] || now
    );

    useEffect(() => {
      setSelectedTime(values[currentName] || now);
    }, [currentName, now, values]);

    const handleOnSave = useCallback(() => {
      onStateChange({ ...values, [currentName]: selectedTime });
      onTimeChange({
        name: currentName,
        values: {
          ...values,
          [currentName]: moment(selectedTime || now).toDate(),
        },
      });
      onClose();
    }, [
      currentName,
      onClose,
      onStateChange,
      onTimeChange,
      selectedTime,
      values,
      now,
    ]);

    // Currently not using this error because there is a bug ith the date check
    // const errorMessage = name === 'startTime' ? t('clockInBefore') : t('clockOutAfter');
    // const shouldShowError = useMemo(
    //   () =>
    //     name === 'startTime'
    //       ? moment(selectedTime).isBefore(moment(shiftTime))
    //       : moment(selectedTime).isAfter(moment(shiftTime)),
    //   [name, selectedTime, shiftTime],
    // );

    return (
      <Content>
        <ModalView>
          <UserDetails
            projectLocation={projectLocation}
            shiftLocation={shiftLocation}
            position={position}
            employeePhoto={employeePhoto}
            employeeName={employeeName}
            logo={logo}
          />
          <Separator />
          <ClockTimePicker
            showCheckIn={showCheckIn}
            showClockIn={showClockIn}
            showClockOut={showClockOut}
            currentName={currentName}
            setCurrentName={setCurrentName}
          />
          <PickerContainer>
            <TimePicker
              title=""
              size={18}
              transparent={false}
              placeholder={moment(now).format('LT')}
              onChange={(value) => setSelectedTime(value)}
              value={selectedTime}
              component={TimePickerComponent}
            />
            {!!shiftTime && (
              <OnTimeContainer
                as={Pressable}
                onPress={() => setSelectedTime(shiftTime)}
              >
                <OnTimeLink>
                  {t('setOnTime')} ({moment(shiftTime).format('LT')})
                </OnTimeLink>
              </OnTimeContainer>
            )}
          </PickerContainer>
          <Separator />
          {/* {shouldShowError && <Error>{errorMessage}</Error>} */}
          <ButtonContainer>
            <Button
              type="primary"
              paddingVertical={22}
              title={t(clockApiKeyToSlug(currentName))}
              onPress={handleOnSave}
            />
          </ButtonContainer>
        </ModalView>
      </Content>
    );
  }
);
