import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { ILocation } from '../entities';

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => ({
    locations: data,
    mappedLocations: mappedArray(data) as { [key: number]: ILocation },
  })
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useLocations = () => {
  const { data: userData } = useUser();
  const storeKey = ['locations'];
  const { isPending, data } = useQuery<{ data: ILocation[] }>({
    queryKey: storeKey,
    queryFn: () => api.getLocations(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { locations, mappedLocations } = useMemo(() => {
    const { locations = [], mappedLocations = {} } = data || {};
    return {
      locations,
      mappedLocations,
    };
  }, [data]);

  return {
    isLoading: isPending,
    locations,
    mappedLocations,
  };
};
