import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { palette } from '@violetta/ubeya/ui';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useMutation, useQuery } from '@tanstack/react-query';
import { IAccount } from '../entities';
import { useMemo } from 'react';

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const accounts = data.map((account, index) => ({
      ...account,
      color: palette[index % palette.length].dark,
      background: palette[index % palette.length].light,
    }));
    const mappedAccounts = mappedArray(accounts) as { [key: number]: IAccount };
    const isTimeAvailability = (accounts || []).some(
      ({ timeAvailability }) => !!timeAvailability
    );
    const showIncomeLink = (accounts || []).some(
      ({ isShowPayrollOnApp }) => !!isShowPayrollOnApp
    );
    const isEngagementMode =
      (accounts || []).length > 0 &&
      (accounts || []).every(({ isEngagementMode }) => !!isEngagementMode);

    return {
      accounts,
      mappedAccounts,
      isTimeAvailability,
      showIncomeLink,
      isEngagementMode,
    };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useAccounts = () => {
  const { data: userData } = useUser();

  const storeKey = ['accounts'];
  const { isLoading, isPending, data, refetch } = useQuery<{
    data: IAccount[];
  }>({
    queryKey: storeKey,
    queryFn: () => api.getAccounts(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const {
    accounts,
    mappedAccounts,
    isTimeAvailability,
    showIncomeLink,
    isEngagementMode,
  } = useMemo(() => {
    const {
      accounts = [],
      mappedAccounts = {},
      isTimeAvailability = false,
      showIncomeLink = false,
      isEngagementMode = false,
    } = data || {};
    return {
      accounts,
      mappedAccounts,
      isTimeAvailability,
      showIncomeLink,
      isEngagementMode,
    };
  }, [data]);

  const { mutateAsync: leaveAccount, isPending: isLeaveAccountLoading } =
    useMutation({
      mutationFn: (accountId: number) => api.leaveAccount({ accountId }),
    });

  return {
    isLoading: isPending,
    accounts,
    mappedAccounts,
    isTimeAvailability,
    showIncomeLink,
    refetch,
    leaveAccount,
    isLeaveAccountLoading,
    isInitialLoading: isLoading,
    isEngagementMode,
  };
};
