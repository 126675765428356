import React, { FC, useCallback, useState } from 'react';
import { api } from '@violetta/ubeya/api';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Card as BaseCard,
  FlexColumnCenter,
  FlexRow,
  H5Medium,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { pickImg } from '@violetta/ubeya/utils';
import { Animated } from 'react-native';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/native';
import { ActionButton } from '../Buttons/ActionButton';

const Container = styled(BaseView)``;
const Wrapper = styled(BaseCard)`
  border-width: 0px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.offwhitesmoke};
  elevation: 0;
  shadow-color: rgba(0, 0, 0, 0);
`;

const Box = styled(FlexRow)`
  padding: 16px 4px 16px 0;
  align-items: center;
`;

const Details = styled(FlexRow)`
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  padding-left: 12px;
`;

const Title = styled(H5Medium)`
  flex-wrap: wrap;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: normal;
  font-style: normal;
`;

const ButtonContainer = styled(FlexColumnCenter)`
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

interface Props {}

export const UploadProfilePhoto: FC<Props> = () => {
  const queryClient = useQueryClient();

  const { t } = useTranslation();
  const [fadeAnim] = useState(new Animated.Value(1));

  const { mutateAsync: uploadPhoto, isPending: isLoadingPhoto } = useMutation({
    mutationFn: ({ base64, type }: { base64: string; type: string }) =>
      api.uploadPhoto({ image: base64, type }),
  });

  const photoSelected = useCallback(async () => {
    const assets = await pickImg({
      texts: {
        cancel: t('cancel'),
        choosePhoto: t('choosePhoto'),
        takeAPhoto: t('takePhoto'),
        title: t('chooseImageUploadMethod'),
      },
      image: {
        includeBase64: true,
        mediaType: 'photo',
        maxWidth: 1000,
        maxHeight: 1000,
        quality: 0.7,
      },
    });

    if (!assets) {
      return;
    }
    const { base64, type } = assets?.[0] || {};
    if (!base64 || !type) {
      return;
    }
    await uploadPhoto({ base64, type });
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 1000,
    }).start();
    queryClient.invalidateQueries({ queryKey: ['user'] });
  }, [fadeAnim, queryClient, t, uploadPhoto]);

  return (
    <Animated.View
      style={{
        opacity: fadeAnim,
      }}
    >
      <Container>
        <Wrapper as={RipplePressable} onPress={photoSelected}>
          <Box>
            <Details>
              <Title>{t('thingsToDoUploadPhoto')}</Title>
            </Details>
            <ButtonContainer>
              <FlexRow>
                <ActionButton
                  title={t('upload')}
                  onPress={photoSelected}
                  isLoading={isLoadingPhoto}
                  disabled={isLoadingPhoto}
                />
              </FlexRow>
            </ButtonContainer>
          </Box>
        </Wrapper>
      </Container>
    </Animated.View>
  );
};
