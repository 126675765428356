import { useTheme } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { IAccount, IBranch, ILocation, IOffer } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  Card,
  DateTextComponent,
  FlexColumn,
  FlexRow,
  H4Medium,
  H6Regular,
  NotificationChip,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { Location, distance } from '@violetta/ubeya/utils';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components/native';
import { theme } from '../../../../style';
import { checkIsProjectMultiDay } from '../../../offers/utils';

export interface Shift {
  id: number;
  positionId: number;
  labelId: number | null;
  startTime: Date;
  endTime: Date;
}

const BranchLogo = styled.Image`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-right: 10px;
`;

const CardContainer = styled(BaseView)`
  padding: 20px 12px 12px 12px;
  height: 190px;
`;

const CardRow = styled(FlexRow)`
  align-items: center;
  margin-bottom: 10px;
  max-width: 90%;
`;

const Name = styled(H4Medium)`
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
`;

const DataContainer = styled(BaseView)`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const LocationName = styled(H6Regular)`
  font-weight: 300;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;
const Distance = styled(H6Regular)`
  font-size: 12px;
  font-weight: 300;
  line-height: 21px;
  margin-top: -4px;
  color: ${({ theme }) => theme.colors.gray12};
`;

const Wage = styled(H6Regular)`
  font-weight: 500;
  line-height: 21px;
  margin-left: 13px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const IconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 20px;
  margin-right: 10px;
`;

interface Props {
  offer: IOffer;
  mappedBranches: Record<number, IBranch>;
  mappedLocations: Record<number, ILocation>;
  mappedAccounts: Record<number, IAccount>;
  userLocation: Location;
  onPress: (offer: IOffer) => void;
}

const wageRange = (min: number, max: number, prefix = '', suffix = '') => {
  if (min === 0 && max === 0) {
    return '';
  }
  if (min === max || min === 0 || max === 0) {
    return `${prefix}${min}${suffix}`;
  }
  return `${prefix}${min}-${prefix}${max}${suffix}`;
};

export const ProjectOffer: FC<Props> = React.memo(
  ({
    offer,
    mappedBranches,
    onPress,
    mappedLocations,
    mappedAccounts,
    userLocation,
  }) => {
    const {
      date,

      /* show range of dates in case of multi date, according 
    to the earliest and latest shift */
      minShiftDate,
      maxShiftDate,
      branchId,
      endDate,
      compoundedName,
    } = offer;

    const { t } = useTranslation();
    const { colors } = useTheme();

    const logo = useMemo(
      () =>
        mappedBranches?.[branchId]?.accountId &&
        mappedBranches?.[branchId]?.logo,
      [branchId, mappedBranches]
    );

    const branchName = mappedBranches?.[branchId]?.name;

    const currency =
      offer?.accountId && mappedAccounts?.[offer?.accountId]?.currency?.symbol;

    const isAutoBook = useMemo(
      () => mappedBranches[branchId]?.schedulingConfig?.autoBook,
      [branchId, mappedBranches]
    );

    const isShowOfferWage = useMemo(
      () => mappedBranches[branchId]?.schedulingConfig?.isShowOfferWage,
      [branchId, mappedBranches]
    );

    const maskLocationAddressInAppOffers = useMemo(
      () =>
        mappedBranches[branchId]?.schedulingConfig
          ?.maskLocationAddressInAppOffers,
      [branchId, mappedBranches]
    );

    const userDistance = useMemo(() => {
      if (!userLocation) {
        return null;
      }

      const location = mappedLocations?.[offer?.locationId];
      if (!location) {
        return null;
      }

      if (
        mappedLocations?.[offer?.locationId]?.lat === 0 ||
        mappedLocations?.[offer?.locationId]?.lng === 0
      ) {
        return null;
      }

      return distance(userLocation, {
        lat: mappedLocations?.[offer?.locationId]?.lat,
        lng: mappedLocations?.[offer?.locationId]?.lng,
      })?.toFixed(0);
    }, [mappedLocations, offer?.locationId, userLocation]);

    const hourlyWageRange = useMemo(() => {
      if (!isShowOfferWage) {
        return null;
      }
      const { shifts = [] } = offer || {};
      const shiftsWithFlatWages = shifts
        .filter((shift) => shift?.wage?.flatWage > 0)
        .map(({ wage }) => wage.flatWage);
      const shiftsWithHourlyWages = shifts
        .filter((shift) => shift?.wage?.hourlyWage > 0)
        .map(({ wage }) => wage.hourlyWage);

      if (shiftsWithFlatWages.length > 0) {
        const minFlatWage = Math.min(...shiftsWithFlatWages);
        const maxFlatWage = Math.max(...shiftsWithFlatWages);
        return wageRange(minFlatWage, maxFlatWage, currency, `/${t('flat')}`);
      } else if (shiftsWithHourlyWages.length > 0) {
        const minHourlyWage = Math.min(...shiftsWithHourlyWages);
        const maxHourlyWage = Math.max(...shiftsWithHourlyWages);
        return wageRange(
          minHourlyWage,
          maxHourlyWage,
          currency,
          `/${t('hour')}`
        );
      }
      return null;
    }, [currency, isShowOfferWage, offer, t]);

    const shouldShowApplicationStatus =
      !offer?.didApply && !offer?.didReject && !isAutoBook;

    const isProjectMultiDay = useMemo(
      () =>
        checkIsProjectMultiDay({
          date: minShiftDate,
          endDate: maxShiftDate,
        }),
      [maxShiftDate, minShiftDate]
    );

    return (
      <Card as={RipplePressable} onPress={() => onPress(offer)}>
        <CardContainer>
          <DateTextComponent
            date={minShiftDate}
            endDate={maxShiftDate}
            isMultiDate={isProjectMultiDay}
            bold
            fontSize={16}
            style={{ maxWidth: '80%' }}
            numberOfLines={2}
          />

          <DataContainer>
            {/* <Hours>
            {shiftsNumber > 0 ? `${shiftsNumber}` : ''} {t(shiftsNumber > 1 ? 'availableShifts' : 'availableShift')}
          </Hours> */}
            <BaseView>
              <CardRow>
                {!!logo && <BranchLogo source={{ uri: logo }} />}
                <Name numberOfLines={2}>
                  {[branchName, compoundedName].join(', ')}
                </Name>
              </CardRow>
              {!maskLocationAddressInAppOffers && !!offer?.locationId && (
                <CardRow>
                  <IconContainer>
                    <SvgIcon
                      xml={icons?.uiIcons?.locationOutline}
                      height={18}
                      width={14}
                    />
                  </IconContainer>
                  <FlexColumn>
                    <LocationName numberOfLines={1}>
                      {mappedLocations?.[offer?.locationId]?.address}
                    </LocationName>
                    {userDistance && (
                      <Distance numberOfLines={1}>
                        {userDistance} {t('distanceKM')} {t('distanceKMaway')}
                      </Distance>
                    )}
                  </FlexColumn>
                </CardRow>
              )}
              {!!hourlyWageRange && (
                <CardRow>
                  <SvgIcon
                    xml={icons.uiIcons.incomeIcon2}
                    width={17}
                    height={17}
                    color={colors.gray1}
                  />
                  <Wage>
                    {hourlyWageRange} {t('wageEstimated')}
                  </Wage>
                </CardRow>
              )}
            </BaseView>

            {!shouldShowApplicationStatus && (
              <Button
                narrow
                type="primaryReversed"
                title={
                  offer?.didApply
                    ? t('pending')
                    : offer?.didReject
                    ? t('rejected')
                    : isAutoBook
                    ? t('offerViewAll')
                    : t('apply')
                }
                textStyle={
                  offer?.didApply || offer?.didReject
                    ? { color: theme.colors.white }
                    : {}
                }
                style={[
                  offer?.didApply
                    ? {
                        backgroundColor: theme.colors.seconday2,
                        borderColor: theme.colors.seconday2,
                      }
                    : offer?.didReject
                    ? {
                        backgroundColor: theme.colors.decline2,
                        borderColor: theme.colors.decline2,
                      }
                    : {},
                ]}
                onPress={() => onPress(offer)}
              />
            )}
          </DataContainer>
        </CardContainer>
        {offer?.spaceLeft ||
          (!offer?.shifts[0]?.seen && (
            <NotificationChip
              text={`${!offer?.shifts[0]?.seen ? t('new') : ''} ${
                offer?.spaceLeft
                  ? t('spaceLeft', {
                      spaceLeft: offer?.spaceLeft,
                    })
                  : ''
              }`}
              style={{ top: 8, right: 6 }}
            />
          ))}
      </Card>
    );
  }
);
