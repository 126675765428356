import { useMemo } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { createAdminStoreKey } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import * as sharedAtoms from '../../../shared/src/atoms';

const selector = createCachedSelector(
  (data) => data.data,
  ({ data }) => data
)({
  keySelector: (data, storeKey) => `${storeKey.join('#')}`,
});

export const useEmployee = ({ employeeId }) => {
  const [selectedAccount] = useRecoilState(sharedAtoms.selectedAccount);
  const accountId = selectedAccount?.id;
  const storeKey = useMemo(
    () => createAdminStoreKey(['employee', accountId, employeeId]),
    [accountId, employeeId]
  );
  const { isPending, data, refetch } = useQuery({
    queryKey: storeKey,
    queryFn: () => adminApi.getEmployee({ accountId, employeeId }),
    staleTime: 0,
    enabled: !!employeeId,
    select: (data) => selector(data, storeKey),
  });

  //   const { mutateAsync: updateEmployee } = useMutation(
  //     (values) =>
  //       employeeId
  //         ? adminApi.updateEmployee({ accountId, employeeId, ...values })
  //         : adminApi.addEmployee({ accountId, ...values }),
  //     {
  //       throwOnError: true,
  //       onSuccess: (data, { invalidateEmployee }) => {
  //         queryClient.invalidateQueries('employees');
  //         queryClient.invalidateQueries('employeesStats');
  //         invalidateEmployee && queryClient.invalidateQueries(storeKey, { exact: true });
  //       },
  //     },
  //   );

  //   const { mutateAsync: deleteEmployee } = useMutation(
  //     ({ fields }) => adminApi.updateEmployee({ accountId, employeeId, isDeleted: true, fields }),
  //     {
  //       onSuccess: (data, { onSuccess }) => {
  //         queryClient.invalidateQueries('employees');
  //         queryClient.invalidateQueries('employeesStats');
  //         onSuccess();
  //       },
  //     },
  //   );

  //   const { mutateAsync: inviteEmployee } = useMutation(() => api.inviteEmployee({ accountId, employeeId }), {
  //     onSuccess: () => {
  //       return addSuccess(t('invitationSentSuccess'));
  //     },
  //   });

  //   const { mutateAsync: addBulkEmployees } = useMutation(
  //     ({ employees }) => api.updateBulkEmployees({ accountId, employees }),
  //     {
  //       onSettled: () => {
  //         chatIframeRefValue?.current?.contentWindow?.postMessage?.({ event: 'newEmployees' }, '*');
  //         queryClient.invalidateQueries('employees');
  //         queryClient.invalidateQueries('employeesStats');
  //         queryClient.invalidateQueries('positions');
  //         queryClient.invalidateQueries('fields');
  //       },
  //       onSuccess: () => {
  //         chatIframeRefValue?.current?.contentWindow?.postMessage?.({ event: 'newEmployee' }, '*');
  //         const { data } = queryClient.getQueryData(['employeesStats', accountId], { exact: false });
  //         const isFirst = Object.values(data).every((value) => value === 0);
  //         isFirst && setFire(true);
  //       },
  //     },
  //   );

  //   const { mutateAsync: resendAdminEmail } = useMutation(() => adminApi.resendAdminEmail({ accountId, employeeId }));

  return {
    isLoading: isPending,
    data: data?.data,
    refetch,
  };
};
