import { useEffect, useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { IRequestType, RequestsTypes } from '../entities';

export enum RequestTypes {
  ABSENCE = 1,
  GENERAL_AVAILABILITY = 2,
  RELEASE_SHIFT = 3,
  SWAP_SHIFT = 4,
}

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const requestsTypes = data || [];

    const mappedRequestsTypesByType = mappedArray(
      requestsTypes,
      (value) => value.type
    ) as Record<RequestsTypes, IRequestType>;

    const mappedRequestsTypesByTypeId = mappedArray(requestsTypes) as Record<
      number,
      IRequestType
    >;

    return {
      requestsTypes,
      mappedRequestsTypesByType,
      mappedRequestsTypesByTypeId,
    };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useRequestsTypes = () => {
  const { data: userData } = useUser();
  const storeKey = useMemo(() => ['requestsTypes'], []);
  const { isPending, data } = useQuery<{ data: IRequestType[] }>({
    queryKey: storeKey,
    queryFn: () => api.getRequestsTypes(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const {
    requestsTypes,
    mappedRequestsTypesByType,
    mappedRequestsTypesByTypeId,
  } = useMemo(() => {
    const {
      requestsTypes = [],
      mappedRequestsTypesByType = {},
      mappedRequestsTypesByTypeId = {},
    } = data || {};
    return {
      requestsTypes,
      mappedRequestsTypesByType,
      mappedRequestsTypesByTypeId,
    };
  }, [data]);

  return {
    isLoading: isPending,
    requestsTypes,
    mappedRequestsTypesByType,
    mappedRequestsTypesByTypeId,
  };
};
