import React, { createContext, FC } from 'react';
import { TIME_FORMAT } from '../modules/main/constants';

export interface Config {
  timeFormat: string;
  dateFormat: string;
  shortDateFormat: string;
  firstDayOfWeek: number;
  currency: string;
  countryCode: string;
  rtl: boolean;
  t: () => void;
  loader: {
    isLoading: boolean;
    hide: () => void;
    show: () => void;
    toggle: () => void;
  };
}

export const ConfigContext = createContext({
  timeFormat: TIME_FORMAT[24],
  dateFormat: 'DD/MM/YYYY',
  shortDateFormat: 'DD/MM/YY',
  firstDayOfWeek: 1,
  currency: '$',
  countryCode: 'US',
  rtl: false,
  t: () => {},
  loader: {
    isLoading: false,
    hide: () => {},
    show: () => {},
    toggle: () => {},
  },
} as Config);

interface Props {
  config: Config;
}

export const ConfigContextProvider: FC<Props> = ({ config, children }) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
);
