import React, { FC, useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { Booking } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  ChevronRight,
  FlexColumnDirection,
  FlexRow,
  H3Medium,
  H5Medium,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts/ConfigContext';
import { SequencesScreenNames } from '../../sequences';

const Container = styled(FlexColumnDirection)`
  width: 100%;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
  border-bottom-width: 1px;
  padding: 12px 27px;
`;

const Title = styled(H5Medium)`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-left: 10px;
`;

const Header = styled(H3Medium)<{ $disabled: boolean }>`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const RowContainer = styled(FlexRow)`
  padding: 12px 0;
  align-items: center;
`;

interface Props {
  booking: Booking | null;
}
interface RowProps {
  onPress: () => void;
  title: string;
  checked: boolean;
  rtl: boolean;
}

const Row: FC<RowProps> = ({ rtl, title, onPress, checked }) => (
  <RowContainer as={RipplePressable} onPress={onPress}>
    <SvgIcon
      xml={
        checked
          ? icons.uiIcons.selectedCheckmark
          : icons.uiIcons.unselectedCheckmark
      }
      width={25}
      height={25}
    />
    <Title>{title}</Title>
  </RowContainer>
);

export const BookingSequences: FC<Props> = ({ booking }) => {
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const { rtl } = useContext(ConfigContext);
  const sequences = booking?.sequences;
  if (!sequences || sequences.length === 0) {
    return null;
  }

  return (
    <Container>
      <Header>{t('projectWorkflows')}</Header>
      {(sequences || []).map(({ id, instances, title }) =>
        (instances || []).map(({ id: instanceId, hasAnswered }) => (
          <Row
            onPress={() =>
              hasAnswered === true
                ? {}
                : navigate(SequencesScreenNames.MAIN, {
                    screen: SequencesScreenNames.SEQUENCE,
                    params: {
                      sequenceId: id,
                      instanceId,
                      employeeSlotId: booking?.employeeSlot?.id,
                    },
                  })
            }
            checked={hasAnswered === true}
            title={title}
            rtl={rtl}
            key={instanceId}
          />
        ))
      )}
    </Container>
  );
};
