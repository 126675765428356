import React, { FC, useMemo, useRef } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { Filter } from '@violetta/ubeya/ui';
import { useRecoilValue } from 'recoil';
import { offersFilterAtom } from '../../../../state';
import { Modalize } from 'react-native-modalize';
import { useOffers } from '@violetta/ubeya/entities';
import { OffersFilterModal } from '../OffersFilterModal';
import { OfferStatusFilterOptions } from '../../constants';

export const OffersHeaderRightComponent: FC<StackHeaderProps> = () => {
  const filters = useRecoilValue(offersFilterAtom);
  const modalizeRef = useRef<Modalize>(null);
  const { offers } = useOffers();
  const filtersCount = useMemo(
    () =>
      [
        filters?.branchIds.filter((val) => !!val)?.length > 0,
        filters.positionIds?.filter((val) => !!val).length > 0,
        filters?.offersStatus?.[OfferStatusFilterOptions.PENDING] ||
          filters?.offersStatus?.[OfferStatusFilterOptions.REJECTED] ||
          filters?.offersStatus?.[OfferStatusFilterOptions.OPEN],
      ].filter((val) => !!val).length,
    [filters]
  );

  return (
    <>
      <Filter
        onPress={() => modalizeRef?.current?.open()}
        total={filtersCount}
      />
      <OffersFilterModal offers={offers} modalizeRef={modalizeRef} />
    </>
  );
};
