import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import Intercom, { IntercomEvents } from '@intercom/intercom-react-native';
import { icons } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import { useAccounts, useBranches, useModules } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FlexColumnCenter,
  H7Regular,
  Link,
  RipplePressable,
  SvgIcon,
  TabNames,
} from '@violetta/ubeya/ui';
import { Image, Linking, Platform } from 'react-native';
import styled from 'styled-components/native';
import { ComplianceScreenNames } from '../../../compliance/navigation/ScreenNames';
import { DirectoryScreenNames } from '../../../directory/navigation/ScreenNames';
import { GeneralAvailabilityScreenNames } from '../../../general-availability/navigation';
import { IncomeScreenNames } from '../../../income/navigation/ScreenNames';
import {
  ANDROID_RATEAPP_URL,
  ANDROID_TOS_URL,
  APP_VERSION,
  BUILD_NUMBER,
  IPHONE_RATEAPP_URL,
  IPHONE_TOS_URL,
  THE_MIX_URL,
  THE_MIX_ZENDESK_URL,
  WEB_TOS_URL,
} from '../../../main/constants';
import { OnboardingScreenNames } from '../../../onboarding/navigation/ScreenNames';
import { ProfileScreenNames } from '../../../profile/navigation/ScreenNames';
import { RequestsScreenNames } from '../../../requests/navigation/ScreenNames';
import { SettingsScreenNames } from '../../../settings/navigation/ScreenNames';
import { TimeAvailabilityScreenNames } from '../../../time-availability/navigation/ScreenNames';
import { WeeklyScheduleScreenNames } from '../../../weekly-schedule';
import { HomeScreenNames } from '../../navigation/ScreenNames';
import Config from 'react-native-config';
import CodePush from 'react-native-code-push';

const CustomDrawerItem = styled(DrawerItem).attrs({
  labelStyle: {
    fontSize: 16,
    fontWeight: 'normal',
    lineHeight: 22,
    color: '#252525',
    textAlign: 'left',
  },
})`
  padding: 0 0;
  margin-bottom: 0;
`;

const ProfileCustomDrawerItem = styled(CustomDrawerItem)`
  padding: 5px 0;
`;

const IconWrapper = styled(BaseView)`
  width: 35px;
  height: 35px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray9};
  margin-right: -15px;
`;

const Border = styled(BaseView)`
  border: 0.5px solid ${({ theme }) => theme.colors.gray11};
`;

const Closure = styled(FlexColumnCenter)`
  margin: 30px 0;
`;
const AppVersion = styled(H7Regular)`
  margin-top: 5px;
`;

const LinkContainer = styled(BaseView)``;
const Logo = styled(BaseView)`
  padding-left: 11px;
  margin-top: 15px;
`;

export const ProfileImage = styled(Image)`
  height: 50px;
  width: 50px;
  border-width: 3px;
  border-color: ${({ theme }) => theme.colors.surface};
  border-radius: 25px;
  margin-right: -26px;
`;

export const ExtraImage = styled(Image)`
  height: 40px;
  width: 40px;
  border-width: 3px;
  border-color: ${({ theme }) => theme.colors.surface};
  border-radius: 25px;
  margin-right: -18px;
`;

export const CustomDrawerContent = (props) => {
  const { t } = useTranslation();
  const [intercomUnreadCount, setIntercomUnreadCount] = useState<number>(0);
  const [codePushVersion, setCodePushVersion] = useState<string>(null);
  const { logout, data: userData, logoutWithoutPrompt } = useUser();
  const { mappedModulesBySlug } = useModules();
  const { navigate } = useNavigation();
  const { accounts, isTimeAvailability, showIncomeLink, isEngagementMode } =
    useAccounts();
  const { canViewAllSchedules } = useBranches();
  const isMixLinkAccount = useMemo(
    () => accounts?.find(({ id }) => id === 179),
    [accounts]
  );
  const weeklyPreferenceAccountId = useMemo(
    () => accounts.find(({ timeAvailability }) => !!timeAvailability)?.id,
    [accounts]
  );

  const handleTOSClick = useCallback(() => {
    const url = (() => {
      if (Platform.OS === 'ios') return IPHONE_TOS_URL;
      if (Platform.OS === 'android') return ANDROID_TOS_URL;
      return WEB_TOS_URL;
    })();
    Linking.openURL(url);
  }, []);

  const handleRateAppClick = useCallback(() => {
    const url = (() => {
      if (Platform.OS === 'ios') return IPHONE_RATEAPP_URL;
      if (Platform.OS === 'android') return ANDROID_RATEAPP_URL;
      return WEB_TOS_URL;
    })();
    Linking.openURL(url);
  }, []);

  const toScreen = useCallback(
    (...screen) => {
      props.navigation.closeDrawer();
      navigate(...screen);
    },
    [navigate, props]
  );

  const fetchCodePushVersion = useCallback(async () => {
    const metadata = await CodePush.getUpdateMetadata();
    if (!metadata) return;
    setCodePushVersion(metadata.label);
  }, []);

  useEffect(() => {
    fetchCodePushVersion();
  }, [fetchCodePushVersion]);

  const initialIntercomValue = useCallback(async () => {
    const count = await Intercom.getUnreadConversationCount();
    setIntercomUnreadCount(count);
  }, []);

  // useEffect(() => {
  //   initialIntercomValue();
  //   const countListener = Intercom.addEventListener(
  //     IntercomEvents.IntercomUnreadCountDidChange,
  //     (response) => {
  //       setIntercomUnreadCount(response.count as number);
  //     }
  //   );

  //   return () => {
  //     countListener.remove();
  //   };
  // }, []);

  Intercom.getUnreadConversationCount();

  return (
    <DrawerContentScrollView {...props}>
      <Logo>
        <SvgIcon
          xml={icons.whiteLabelLogos.logo}
          width={250}
          height={Platform.OS === 'web' ? 35 : 35}
        />
      </Logo>
      <ProfileCustomDrawerItem
        icon={() =>
          userData?.image ? (
            <ProfileImage source={{ uri: userData.image }} />
          ) : (
            <IconWrapper>
              <SvgIcon
                xml={icons.uiIcons.employeePlaceholder}
                width={35}
                height={35}
              />
            </IconWrapper>
          )
        }
        label={
          !!userData?.firstName && !!userData?.lastName
            ? `${userData?.firstName || ''} ${userData?.lastName || ''}`
            : t('myProfile')
        }
        onPress={() => toScreen(ProfileScreenNames.MAIN)}
      />
      <Border />
      {/* <CustomDrawerItem
        label={t('upcomingShifts')}
        onPress={() => toScreen(HomeScreenNames.TABS, { screen: BookingsScreenNames.SCHEDULE })}
        icon={() => (
          <IconWrapper>
            <SvgIcon xml={icons.uiIcons.calendar} width={22} height={22} />
          </IconWrapper>
        )}
      />
      <CustomDrawerItem
        label={t('openShifts')}
        onPress={() => toScreen(HomeScreenNames.TABS, { screen: OffersScreenNames.OFFERS })}
        icon={() => (
          <IconWrapper>
            <SvgIcon xml={icons.uiIcons.offers} width={22} height={22} />
          </IconWrapper>
        )}
      /> */}
      {isTimeAvailability && (
        <CustomDrawerItem
          label={t('generalAvailability')}
          onPress={() => toScreen(GeneralAvailabilityScreenNames.MAIN)}
          icon={() => (
            <IconWrapper>
              <SvgIcon xml={icons.uiIcons.requests} width={22} height={22} />
            </IconWrapper>
          )}
        />
      )}
      {weeklyPreferenceAccountId && (
        <CustomDrawerItem
          label={t('homeAvailability')}
          onPress={() =>
            toScreen(TimeAvailabilityScreenNames.MAIN, {
              screen: TimeAvailabilityScreenNames.MAIN,
            })
          }
          icon={() => (
            <IconWrapper>
              <SvgIcon xml={icons.uiIcons.requests} width={22} height={22} />
            </IconWrapper>
          )}
        />
      )}
      {showIncomeLink && (
        <CustomDrawerItem
          label={t('yourEarnings')}
          onPress={() => toScreen(IncomeScreenNames.MAIN)}
          icon={() => (
            <IconWrapper>
              <SvgIcon xml={icons.uiIcons.incomeIcon2} width={22} height={22} />
            </IconWrapper>
          )}
        />
      )}

      {(accounts || []).length > 0 && !isEngagementMode && (
        <CustomDrawerItem
          label={t('myRequests')}
          onPress={() => toScreen(RequestsScreenNames.MAIN)}
          icon={() => (
            <IconWrapper>
              <SvgIcon xml={icons.uiIcons.requests} width={22} height={22} />
            </IconWrapper>
          )}
        />
      )}
      {(accounts || []).length > 0 && (
        <CustomDrawerItem
          label={t('documents')}
          onPress={() => toScreen(ComplianceScreenNames.MAIN)}
          icon={() => (
            <IconWrapper>
              <SvgIcon
                xml={icons.uiIcons.complianceIcon}
                width={35}
                height={40}
              />
            </IconWrapper>
          )}
        />
      )}
      {mappedModulesBySlug?.directory && (
        <CustomDrawerItem
          label={t('directory')}
          onPress={() => toScreen(DirectoryScreenNames.MAIN)}
          icon={() => (
            <IconWrapper>
              <SvgIcon xml={icons.uiIcons.directory} width={22} height={22} />
            </IconWrapper>
          )}
        />
      )}
      {/* {Config.APP_NAME === 'Ubeya' && isMixLinkAccount && (
        <CustomDrawerItem
          label="Support (The Mix)"
          onPress={() => Linking.openURL(THE_MIX_URL)}
          icon={() => (
            <ProfileImage
              source={{
                uri: 'https://yt3.ggpht.com/ytc/AKedOLRo3dQoR6-TEEbWHONqbS9wAqMWhLXhNApJDndxPQ=s900-c-k-c0x00ffffff-no-rj',
              }}
            />
          )}
        />
      )} */}
      {Config.APP_NAME === 'Ubeya' && isMixLinkAccount && (
        <CustomDrawerItem
          label="Arc Help Centre"
          onPress={() => Linking.openURL(THE_MIX_ZENDESK_URL)}
          icon={() => (
            <ExtraImage
              source={{
                uri: isMixLinkAccount?.logo,
              }}
            />
          )}
        />
      )}
      {(accounts || []).length > 0 && canViewAllSchedules && (
        <CustomDrawerItem
          label={t('weeklySchedule')}
          onPress={() => toScreen(WeeklyScheduleScreenNames.MAIN)}
          icon={() => (
            <IconWrapper>
              <SvgIcon xml={icons.uiIcons.calendar} width={22} height={22} />
            </IconWrapper>
          )}
        />
      )}
      {/* <CustomDrawerItem
        label={t('management')}
        onPress={() => toScreen(ManagementScreenNames.MAIN)}
        icon={() => (
          <IconWrapper>
            <SvgIcon xml={icons.uiIcons.admin} width={22} height={22} />
          </IconWrapper>
        )}
      /> */}
      <Border />
      <CustomDrawerItem
        label={t('settings')}
        onPress={() => {
          toScreen(SettingsScreenNames.MAIN);
        }}
        icon={() => (
          <IconWrapper>
            <SvgIcon xml={icons.uiIcons.settings} width={22} height={22} />
          </IconWrapper>
        )}
      />
      {/* {Config.APP_NAME === 'Ubeya' && (
        <CustomDrawerItem
          label={t('rateThisApp')}
          onPress={handleRateAppClick}
          icon={() => (
            <IconWrapper>
              <SvgIcon xml={icons.actionIcons.rate} width={22} height={22} />
            </IconWrapper>
          )}
        />
      )} */}
      {Config.APP_NAME === 'Ubeya' && (
        <CustomDrawerItem
          label={t('joinBranch')}
          onPress={() => {
            toScreen(OnboardingScreenNames.MAIN);
          }}
          icon={() => (
            <IconWrapper>
              <SvgIcon xml={icons.uiIcons.team} width={22} height={22} />
            </IconWrapper>
          )}
        />
      )}
      {Config.APP_NAME === 'Ubeya' && (
        <CustomDrawerItem
          label={`${t('helpCenter')} ${
            intercomUnreadCount > 0 ? `(${intercomUnreadCount})` : ''
          }`}
          onPress={() => {
            Intercom.present();
            // Linking.openURL(HELP_CENTER_URL);
          }}
          icon={() => (
            <IconWrapper>
              <SvgIcon
                xml={icons.cardIcons.helpCenter}
                width={22}
                height={22}
              />
            </IconWrapper>
          )}
        />
      )}
      <CustomDrawerItem
        label={t('logout')}
        onPress={async () => {
          if (Platform.OS === 'web') {
            await logoutWithoutPrompt();
          } else {
            await logout();
          }
          toScreen(TabNames.HOME, { screen: HomeScreenNames.HOME });
        }}
        icon={() => (
          <IconWrapper>
            <SvgIcon xml={icons.cardIcons.logout} width={22} height={22} />
          </IconWrapper>
        )}
      />
      <Closure>
        <AppVersion>
          {t('version')} {APP_VERSION} ({BUILD_NUMBER})
        </AppVersion>
        {!!codePushVersion && <AppVersion>({codePushVersion})</AppVersion>}
        <LinkContainer as={RipplePressable} onPress={handleTOSClick}>
          <Link component={AppVersion}>{t('termsAndConditions')}</Link>
        </LinkContainer>
      </Closure>
    </DrawerContentScrollView>
  );
};
