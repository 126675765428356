import { icons } from '@violetta/ubeya/assets';
import React, { FC } from 'react';
import styled from 'styled-components/native';
import { SvgIcon } from '../Icons';
import { BaseView, FlexCenter, RipplePressable } from '../Layout';
import { H7Regular } from '../Typography';

const IconContainer = styled(FlexCenter)`
  width: 32px;
  height: 32px;
  border-radius: 20px;


  background-color: ${({ theme }) => theme.colors.whitesmoke};
  justify-self: flex-end
  margin-left: 10px;
`;

const Badge = styled(BaseView)`
  position: absolute;
  left: 5px;
  bottom: 5px;
  height: 15px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.chip};
`;

const BadgeText = styled(H7Regular)`
  color: ${({ theme }) => theme.colors.white};
`;

type FilterProps = {
  onPress: () => void;
  total: number;
};

const Filter: FC<FilterProps> = ({ onPress, total }) => {
  return (
    <IconContainer as={RipplePressable} onPress={onPress}>
      <SvgIcon xml={icons.actionIcons.filterIconNew} width={18} height={18} />
      {total > 0 && (
        <Badge>
          <BadgeText>{total}</BadgeText>
        </Badge>
      )}
    </IconContainer>
  );
};

export default Filter;
