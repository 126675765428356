import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import styled from 'styled-components/native';
import { useTranslation } from '@violetta/ubeya/i18n';
import { SignatureField } from '../FormFields';
import { IconClose } from '../Icons';
import { BaseView, FlexRowBetween } from '../Layout';
import { BaseText } from '../Typography';
import { Button } from '../Buttons';

const SIGNATURE_FIELD_NAME = `field-signature`;
const SIGNATURE_FIELD_ID = `signature`;

const ModalSection = styled(BaseView)`
  padding: 20px 30px;
`;

const ModalHeader = styled(FlexRowBetween)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 30px;
`;

const HeaderIcon = styled(BaseView)`
  margin-left: -10px;
`;

const ModalRow = styled(FlexRowBetween)`
  margin-bottom: 15px;
  align-items: center;
`;

const ModalRowText = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #1e2534;
`;

const ButtonContainer = styled(BaseView)`
  padding: 24px 16px;
`;

interface Props {
  modalizeRef: any;
  approveTimesheet: any;
  isLoadingApproveTimesheet: boolean;
}

export const ApproveTimesheetModal: FC<Props> = ({
  approveTimesheet,
  isLoadingApproveTimesheet = false,
  modalizeRef,
}) => {
  const { t } = useTranslation();

  const Header = (
    <ModalHeader>
      <HeaderIcon>
        <IconClose
          color="#000"
          size={22}
          onPress={() => modalizeRef?.current?.close()}
        />
      </HeaderIcon>
    </ModalHeader>
  );

  return (
    <Portal>
      <Form
        onSubmit={async (values) => {
          await approveTimesheet({
            approve: { signature: values[SIGNATURE_FIELD_NAME]?.[0]?.content },
          });
          modalizeRef?.current?.close();
        }}
        keepDirtyOnReinitialize
        initialValues={{
          [SIGNATURE_FIELD_NAME]: null,
        }}
        render={({ handleSubmit, values }) => (
          <Modalize
            adjustToContentHeight
            panGestureEnabled={false}
            ref={modalizeRef}
            withHandle={false}
            HeaderComponent={Header}
            FooterComponent={
              <ButtonContainer>
                <Button
                  isLoading={isLoadingApproveTimesheet}
                  title={t('approveShiftHours') || ''}
                  type="primary"
                  disabled={!values[SIGNATURE_FIELD_NAME]?.[0]?.content}
                  onPress={handleSubmit}
                />
              </ButtonContainer>
            }
          >
            <ModalSection>
              <ModalRow>
                <ModalRowText>{t('approveTimesheetAddSignature')}</ModalRowText>
              </ModalRow>
              <ModalRow>
                <SignatureField id={SIGNATURE_FIELD_ID} loadDelay={500} />
              </ModalRow>
            </ModalSection>
          </Modalize>
        )}
      />
    </Portal>
  );
};
