import React, { FC, useContext } from 'react';
import {
  BaseView,
  H3Regular,
  Card as BaseCard,
  H5Regular,
  SvgIcon,
  EmployeeDocumentPreview,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import { COMPLIANCE_SECTIONS } from '../constants';
import { API_DATE_TIME_FORMAT } from '../../main';
import { ConfigContext } from '../../../contexts';
import { Pressable } from 'react-native';

const Card = styled(BaseCard)`
  margin-top: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const UploadedFileCard = styled(Card)`
  height: ${({ $withExpiration }) => ($withExpiration ? 170 : 150)}px;
`;

const MissingFileCard = styled(Card)`
  height: 130px;
`;

const ExpiredFileCard = styled(Card)`
  height: 170px;
`;

const IconContainer = styled(BaseView)`
  margin-top: 15px;
`;

const Title = styled(BaseView)`
  align-items: center;
`;

const FieldName = styled(H3Regular)`
  font-weight: 600;
  font-size: 15px;
`;

const ExpirationDate = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.compliance.expired.light};
  width: 100%;
  margin-top: 5px;
  font-size: 10px;
  padding: 5px;
  position: absolute;
  bottom: 10px;
`;

const Text = styled(H5Regular)`
  margin-top: 2px;
  font-weight: 400;
  font-size: 15px;
`;

const BoldText = styled(Text)`
  font-weight: 600;
  color: ${({ theme, $color }) =>
    $color ? theme.colors.compliance.expired.dark : theme.colors.textPrimary};
`;

const ExpirationDateText = styled(Text)`
  color: ${({ theme, $color }) =>
    $color ? theme.colors.compliance.expired.dark : theme.colors.textPrimary};
`;

const UploadedSection = styled(BaseView)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.compliance.approved.light};
  width: 100%;
  margin-top: 5px;
  position: absolute;
  height: 30px;
  bottom: 10px;
`;

const UploadedSectionText = styled(Text)`
  color: ${({ theme }) => theme.colors.compliance.approved.dark};
  margin-left: 10px;
  font-weight: 500;
`;

type FieldType = {
  id: number;
  accountId: number;
  name: string;
  hasExpiration: boolean;
  value: {
    link: string;
    expirationDate: Date;
  };
};

interface Props {
  field: FieldType;
  sectionTitle: string;
  setSelectedField: (any) => void;
}

const ExpiredFileRenderer = ({ t, field, dateFormat, handleSelectedField }) => {
  const { expirationDate } = field?.value?.[0] || {};

  return (
    <ExpiredFileCard as={Pressable} onPress={handleSelectedField} $withDocument>
      <Title>
        <FieldName>{t('updateDocument')}</FieldName>
        <Text> {field.name}</Text>
      </Title>

      <EmployeeDocumentPreview field={field} />

      <ExpirationDate>
        <ExpirationDateText $color>
          <BoldText $color> {t('expirationDate')}</BoldText>
          {` - ${
            expirationDate
              ? moment(expirationDate, API_DATE_TIME_FORMAT).format(dateFormat)
              : ''
          }`}
        </ExpirationDateText>
      </ExpirationDate>
    </ExpiredFileCard>
  );
};

const MissingFileRenderer = ({ t, field, handleSelectedField }) => (
  <MissingFileCard>
    <Title>
      <FieldName>{t('uploadDocument')}</FieldName>
      <Text> {field.name}</Text>

      <IconContainer as={Pressable} onPress={() => handleSelectedField()}>
        <SvgIcon
          xml={icons?.complianceIcons?.complianceUploadField}
          height={50}
          width={50}
        />
      </IconContainer>
    </Title>
  </MissingFileCard>
);

const UploadedFileRenderer = ({
  t,
  field,
  dateFormat,
  handleSelectedField,
}) => {
  const { expirationDate, createdAt } = field.value[0] || {};

  return (
    <UploadedFileCard
      as={Pressable}
      onPress={handleSelectedField}
      $withExpiration={!!expirationDate}
    >
      <Title>
        <FieldName>{field.name}</FieldName>

        {expirationDate && (
          <ExpirationDateText>
            {`${t('expirationDate')} ${
              expirationDate
                ? moment(expirationDate, API_DATE_TIME_FORMAT).format(
                    dateFormat
                  )
                : ''
            }`}
          </ExpirationDateText>
        )}
      </Title>

      <EmployeeDocumentPreview field={field} />
      <UploadedSection>
        <SvgIcon
          xml={icons?.complianceIcons?.complianceUploadedFile}
          height={20}
          width={20}
        />
        <UploadedSectionText>
          {`${t('uploadedDocument')} ${t('on')} ${moment(createdAt).format('L')}
            `}
        </UploadedSectionText>
      </UploadedSection>
    </UploadedFileCard>
  );
};

export const FieldCard: FC<Props> = ({
  field,
  setSelectedField,
  sectionTitle,
}) => {
  const { dateFormat } = useContext(ConfigContext);
  const { t } = useTranslation();

  const handleSelectedField = () => setSelectedField?.(field);

  if (sectionTitle === COMPLIANCE_SECTIONS.MISSING) {
    return (
      <MissingFileRenderer
        t={t}
        field={field}
        handleSelectedField={handleSelectedField}
      />
    );
  }

  if (sectionTitle === COMPLIANCE_SECTIONS.INVALID) {
    return (
      <ExpiredFileRenderer
        t={t}
        field={field}
        dateFormat={dateFormat}
        handleSelectedField={handleSelectedField}
      />
    );
  }

  if (sectionTitle === COMPLIANCE_SECTIONS.UPLOADED) {
    return (
      <UploadedFileRenderer
        t={t}
        field={field}
        dateFormat={dateFormat}
        handleSelectedField={handleSelectedField}
      />
    );
  }

  return <></>;
};
