import moment from 'moment';

export const generateRange = (start: Date, end: Date) => {
  let pointer = moment(start);
  const last = moment(end);

  const dates = [] as Date[];

  while (pointer.isSameOrBefore(last, 'date')) {
    dates.push(pointer.toDate());
    pointer = pointer.add(1, 'day');
  }

  return dates;
};
