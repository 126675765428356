import React, { FC } from 'react';
import {
  BaseView,
  FlexCenter,
  FlexRow,
  H4Light,
  RipplePressable,
  SearchBox,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

interface Props {
  setSearch: (value: string) => void;
  search: string;
  t: (s: string, options?: any) => string;
  totalOffers?: number;
  showClearFiltersMessage: boolean;
  removeFilters: () => void;
}

const Container = styled(BaseView)`
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #eaeaea;
`;

const SearchContainer = styled(FlexCenter)`
  padding: 10px;
  flex-grow: 0;
`;

const FilterContainer = styled(FlexRow)`
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 8px 5px;
  justify-content: center;
`;

const FilterMessage = styled(H4Light)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.surface};
  text-align: center;
`;
const FilterMessageLink = styled(FilterMessage)`
  text-decoration: underline;
  text-decoration-color: #fff;
`;

export const OfferFilterButtons: FC<Props> = ({
  search,
  setSearch,
  t,
  totalOffers,
  showClearFiltersMessage,
  removeFilters,
}) => (
  <Container>
    {showClearFiltersMessage && totalOffers > 0 && (
      <FilterContainer as={RipplePressable} onPress={removeFilters}>
        <FilterMessage>{t('filterOffersTitle1')} </FilterMessage>
        <FilterMessageLink>
          {t('filterOffersTitle2', {
            suffix: totalOffers,
          })}
        </FilterMessageLink>
      </FilterContainer>
    )}
    <SearchContainer>
      <SearchBox autoCorrect={false} onChange={setSearch} searchTerm={search} />
    </SearchContainer>
  </Container>
);
