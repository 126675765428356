import React, { FC, useContext, useRef } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  AccountImage,
  BaseView,
  ChevronDown,
  FlexRowBetween,
  H3Medium,
  RipplePressable,
  SearchBox,
} from '@violetta/ubeya/ui';
import { throttle } from 'lodash';
import { Modalize } from 'react-native-modalize';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import { AccountPickerModal } from '../../../../components/Modals/AccountPickerModal';
import { ConfigContext } from '../../../../contexts';
import { searchStateAtom } from '../../../../state';
import { AppContext } from '../../../main/AppContext';

const Container = styled(FlexRowBetween)`
  flex: 1;
  justify-content: center;
  width: 100%;
  align-items: center;
  height: 100%;
`;

const Title = styled(H3Medium)`
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-left: 10px;
`;

export const WeeklyScheduleHeaderTitle: FC<StackHeaderProps> = () => {
  const { rtl } = useContext(ConfigContext);
  const [searchState, setSearchState] = useRecoilState(searchStateAtom);
  const modalizeRef = useRef<Modalize>(null);
  const {
    profile: { selectedAccount },
  } = useContext(AppContext);

  return (
    <>
      {searchState.isSearchOpen ? (
        <SearchBox
          autoFocus
          searchTerm={searchState.searchKey}
          onChange={throttle(
            (text: string) =>
              setSearchState({ ...searchState, searchKey: text }),
            750
          )}
          autoCorrect={false}
          rtl={rtl}
        />
      ) : (
        <Container
          as={RipplePressable}
          onPress={() => modalizeRef.current?.open()}
        >
          <AccountImage size={26} image={selectedAccount?.logo} />
          <Title>{selectedAccount?.name || ''}</Title>
          <ChevronDown />
        </Container>
      )}
      <AccountPickerModal modalizeRef={modalizeRef} />
    </>
  );
};
