import React, { FC } from 'react';
import styled from 'styled-components/native';
import { FlexColumnCenter, FlexRow } from '../Layout';
import { BaseText } from '../Typography';

const RowContainer = styled(FlexRow)`
  width: 100%;
  justify-content: center;
`;

const TypeContainer = styled(FlexColumnCenter)`
  border-right-width: 1px;
  border-right-color: ${({ theme }) => theme.colors.gray6};
  flex: 1;
  align-items: center;
  text-align: left;
  padding: 10px 0;
`;

const TimeContainer = styled(FlexColumnCenter)`
  flex: 1;
  align-items: center;
  padding: 10px 0;
`;

const TimeDescriptionText = styled(BaseText)`
  font-size: 20px;
  line-height: 22px;
  color: #252525;
`;

const TimeText = styled(TimeDescriptionText)`
  font-weight: 500;
`;

interface IEmployeeTimeRowProps {
  type: string;
  time: any;
}

export const EmployeeTimeRow: FC<IEmployeeTimeRowProps> = ({ time, type }) => (
  <RowContainer>
    <TypeContainer>
      <TimeDescriptionText>{type}</TimeDescriptionText>
    </TypeContainer>
    <TimeContainer>
      <TimeText>{time}</TimeText>
    </TimeContainer>
  </RowContainer>
);
