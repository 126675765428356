import React, { FC, useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { INotification, useBookings } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Card,
  FlatList,
  FlexColumnDirection,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { NotificationsScreenNames } from '../../../notifications/navigation';
import { AuditLog } from '../AuditLog';
import { CardTitle } from '../CardTitle/CardTitle';

const Content = styled(Card)`
  padding: 10px 16px;
`;

interface Props {
  notifications: INotification[];
  total: number;
}

export const NotificationsCard: FC<Props> = React.memo(
  ({ notifications, total }) => {
    const { t } = useTranslation();
    const { navigate } = useNavigation();
    const { mappedBookings } = useBookings();
    const bookingIds = useMemo(
      () => Object.keys(mappedBookings),
      [mappedBookings]
    );
    const navigateToNotifications = useCallback(
      () => navigate(NotificationsScreenNames.MAIN),
      [navigate]
    );

    const renderItem = useCallback(
      (item, index, arr) => (
        <AuditLog
          key={item.id}
          type={item.type}
          data={JSON.parse(item?.data)}
          id={item?.id}
          createdAt={item?.createdAt}
          notification={item}
          canNavigate={bookingIds?.includes(item?.shiftId?.toString())}
          isLast={index === arr?.length - 1}
        />
      ),
      [bookingIds]
    );

    return (
      <FlexColumnDirection>
        <CardTitle
          title={t('notifications')}
          count={total}
          onShowAllPress={navigateToNotifications}
          showAllKey="showAllNotifications"
        />
        <Content>{notifications.map(renderItem)}</Content>
      </FlexColumnDirection>
    );
  }
);
