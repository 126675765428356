import React, { FC, useCallback } from 'react';
import { IRequestTypeKind } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  FlatList,
  FlexRow,
  SelectListItem,
  SelectProps,
} from '@violetta/ubeya/ui';
import { useField } from 'react-final-form';
import styled from 'styled-components/native';

type Props = Omit<
  SelectProps<IRequestTypeKind, IRequestTypeKind['id']>,
  'optionToSelectedOption'
>;

const ModalRow = styled(FlexRow)`
  align-items: center;
  border-bottom-width: ${({ hideBottomBorder }) =>
    hideBottomBorder ? 0 : 1}px;
  border-bottom-color: ${({ theme }) => theme.colors.gray16};
`;

const ModalRowText = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #1e2534;
`;

const ListOption: FC<IRequestTypeKind> = ({ id, slug }, isLast) => {
  const { t } = useTranslation();

  return (
    <ModalRow hideBottomBorder={isLast} key={id}>
      <ModalRowText>{t(slug)}</ModalRowText>
    </ModalRow>
  );
};

export const GeneralAvailabilitySelect: FC<Props> = ({ value, options }) => {
  const {
    input: { onChange },
  } = useField('type');

  const keyExtractor = useCallback((option) => option.id.toString(), []);

  const onSelectedInternal = useCallback(
    (value) => {
      onChange?.(value.type);
    },
    [onChange]
  );

  return (
    <FlatList
      contentContainerStyle={{ flexGrow: 1 }}
      data={options}
      renderItem={({ item: option, index }) => (
        <SelectListItem
          isMulti={false}
          key={index}
          option={option}
          index={index}
          onPress={onSelectedInternal}
          isSelected={value === option.type}
          renderOption={ListOption}
        />
      )}
      keyExtractor={keyExtractor}
    />
  );
};
