import React, { FC, useCallback, useContext, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { PutAvailabilities } from '@violetta/ubeya/api';
import { to } from '@violetta/ubeya/await';
import { useAvailabilityManager, usePeriods } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  FlexColumnDirection,
  BaseScreen,
  FlexRow,
  Button,
  CustomLoaderWrapped,
} from '@violetta/ubeya/ui';
import { useRefresh } from '@violetta/ubeya/utils';
import moment from 'moment';
import { Alert } from '@violetta/ubeya/alert';
import styled from 'styled-components/native';

import { AppContext } from '../../main/AppContext';
import {
  AvailabilityStartScreen,
  AvailabilitySummary,
} from '../components/Availability';
import { AvailabilitiesScreenNames } from '../navigation/ScreenNames';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  padding: 15px 20px;
`;
const ButtonContainer = styled(FlexRow)``;

interface Props {
  title: string;
  date: Date;
}

type ParamList = {
  params: {
    branchId: number;
  };
};

export const AvailabilityScreen: FC<Props> = () => {
  const { navigate, goBack } = useNavigation();
  const { t } = useTranslation();
  const { refreshAvailabilities, refreshPeriods } = useRefresh();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mappedPeriods } = usePeriods();
  const {
    params: { branchId },
  } = useRoute<RouteProp<ParamList, 'params'>>();

  const {
    availability: { selectDay, periodId },
  } = useContext(AppContext);

  const period = mappedPeriods[periodId];

  const {
    filledPeriod,
    updateAvailabilities,
    localAvailabilities,
    dirty,
    isUpdatingAvailability,
    isLoadingAvailabilities,
  } = useAvailabilityManager({
    branchId,
    periodId,
  });

  const navigateAvailability = useCallback(
    ({ date = undefined }: { date?: Date } = {}) => {
      const actualDate =
        date || period.dates.find(({ labels }) => labels.length > 0)?.date;

      if (actualDate) {
        selectDay(actualDate);
        navigate(AvailabilitiesScreenNames.AVAILABILITY_PERIOD);
      }
    },
    [navigate, selectDay, period]
  );

  const showWarningAlert = useCallback(
    () =>
      new Promise((resolve) => {
        Alert.alert(
          t('unsavedChanges'),
          t('areYouSureYouWantToLeave'),
          [
            {
              type: 'confirm',
              text: t('areYouSureYouWantToLeaveOk'),
              onPress: () => resolve(true),
            },
            {
              type: 'cancel',
              text: t('areYouSureYouWantToLeaveCancel'),
              onPress: () => resolve(false),
            },
          ],
          { cancelable: false }
        );
      }),
    [t]
  );

  const showSuccessAlert = useCallback(
    () =>
      new Promise((resolve) => {
        Alert.alert(
          t('availabilitySuccessTitle'),
          '',
          [
            {
              type: 'confirm',
              text: t('ok'),
              onPress: () => resolve(true),
            },
          ],
          { cancelable: false }
        );
      }),
    [t]
  );

  const beforeLeave = useCallback(
    async (cb: () => void) => {
      if (!dirty) {
        return cb();
      }

      const [, proceed] = await to(showWarningAlert());
      if (!proceed) {
        return;
      }

      cb();
    },
    [dirty, showWarningAlert]
  );

  const onUpdate = useCallback(async () => {
    setIsSubmitting(true);
    const params = (localAvailabilities || []).reduce(
      (carry, { id, date, labelId, status }) => ({
        ...carry,
        [id < 0 ? 'added' : 'edited']: [
          ...(carry[id < 0 ? 'added' : 'edited'] || []),
          id < 0
            ? { status, labelId, date: moment(date).format('YYYY-MM-DD') }
            : { id, status },
        ],
      }),
      {} as PutAvailabilities
    );

    await updateAvailabilities({ ...params, periodId });
    await refreshPeriods();
    goBack();
    showSuccessAlert();
    setIsSubmitting(false);
  }, [
    updateAvailabilities,
    localAvailabilities,
    periodId,
    showSuccessAlert,
    goBack,
    refreshPeriods,
  ]);

  if (isLoadingAvailabilities || !period) {
    return <CustomLoaderWrapped />;
  }

  return (
    <BaseScreen onRefresh={refreshAvailabilities} beforeLeave={beforeLeave}>
      <Container>
        {filledPeriod && (
          <AvailabilitySummary
            period={period}
            onDayPress={navigateAvailability}
          />
        )}

        {!filledPeriod && (
          <AvailabilityStartScreen
            onStart={navigateAvailability}
            period={period}
          />
        )}

        <ButtonContainer>
          {dirty && filledPeriod ? (
            <Button
              isLoading={isUpdatingAvailability || isSubmitting}
              disabled={
                isUpdatingAvailability ||
                isLoadingAvailabilities ||
                isSubmitting
              }
              type="primary"
              title={t('submitAvailability')}
              onPress={onUpdate}
            />
          ) : (
            <Button
              isLoading={isSubmitting}
              type="primary"
              disabled={
                isUpdatingAvailability ||
                isLoadingAvailabilities ||
                isSubmitting
              }
              title={t('fillAvailability')}
              onPress={navigateAvailability}
            />
          )}
        </ButtonContainer>
      </Container>
    </BaseScreen>
  );
};
