import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useUser } from '@violetta/ubeya/auth';
import {
  useBookings,
  usePositions,
  useRequestReasons,
  useSwap,
  useBranches,
  SwapType,
  ShiftSwapStatus,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  ChevronDown,
  H4Regular,
  FlexColumnDirection,
  FlexRowCenter,
  H5Medium,
  FlexColumnBetween,
  FlexRow,
  BaseText,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import moment from 'moment';
import { ScrollView } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { ShiftInfoCard } from '../../../components/Cards';
import { TwoButtonModal } from '../../../components/Modals';
import { BookingsScreenNames } from '../../bookings/navigation';
import { AppContext } from '../../main/AppContext';
import { ReasonSelect } from './ReasonSelect';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const TopWrapper = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.primaryLight4};
  padding: 32px 16px 16px 16px;
`;

const BottomWrapper = styled(BaseView)`
  padding: 16px 16px 24px 16px;
  display: flex;
  flex: 1;
`;

const BranchName = styled(H5Medium)`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
`;

const HeaderRow = styled(FlexRowCenter)`
  justify-content: center;
`;

const ButtonContainer = styled(BaseView)`
  margin: 0 16px 24px 16px;
`;

const ButtonContainer2 = styled(FlexRow)`
  margin: 0 16px 34px 16px;
  justify-content: space-around;
`;

const AsteriskText = styled(BaseText)`
  color: red;
  font-size: 20px;
`;

const ClockinFooter = styled(FlexColumnBetween)`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 40px;
  padding-top: 15px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const DontForgetText = styled(H4Regular)`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
`;
const placeholder = {
  label: 'Reason*',
  value: null,
  color: '#000',
  inputLabel: 'Reason*',
};

export const SwapScreen: FC = () => {
  const { params } = useRoute<RouteProp<any, 'params'>>();

  const {
    bookings: { selectedBooking },
  } = useContext(AppContext);
  const { goBack, navigate } = useNavigation();
  const { t } = useTranslation();
  const { requestReasons } = useRequestReasons();
  const { colors } = useTheme();
  const { data } = useUser();
  const { mappedPositions } = usePositions();
  const { mappedBranches } = useBranches();
  const { mappedBookings } = useBookings();
  const {
    createSwap,
    isCreatingSwap,
    cancelSwap,
    isCancellingSwap,
    acceptSwap,
    isAcceptingSwap,
  } = useSwap(selectedBooking?.project?.id, selectedBooking?.id);

  const [isSentModalOpen, setIsSentModalOpen] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isApprovedSwapModalOpen, setIsApprovedSwapModalOpen] =
    useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState(null);

  const booking = mappedBookings[selectedBooking?.id];
  const branch = mappedBranches[booking?.project?.branchId];
  const hasSwap = useMemo(() => !!booking?.swap?.id, [booking?.swap]);

  const autoSwapShift = branch?.schedulingConfig?.autoSwapShift;

  const navigateBooking = useCallback(() => {
    navigate(BookingsScreenNames.MAIN, {
      screen: BookingsScreenNames.BOOKING,
      params: { bookingId: booking?.id },
    });
  }, [booking?.id, navigate]);

  const handleSendSwapRequest = useCallback(async () => {
    await createSwap({
      dstEmployeeId: params?.params?.employee?.id,
      dstSlotId: params?.params?.id,
      reasonId: selectedReason,
    });
    setIsSentModalOpen(false);
    navigateBooking();
  }, [
    createSwap,
    navigateBooking,
    params?.params?.employee?.id,
    params?.params?.id,
    selectedReason,
  ]);

  const handleCancelSwapRequest = useCallback(async () => {
    await cancelSwap({ swapId: booking?.swap?.id });
    setIsCancelModalOpen(false);
    navigateBooking();
  }, [booking?.swap?.id, cancelSwap, navigateBooking]);

  const handleAcceptSwap = useCallback(
    async () =>
      acceptSwap({
        swapId: booking?.swap?.id,
        accountId: branch?.accountId,
      }),
    [acceptSwap, booking?.swap?.id, branch?.accountId]
  );

  const handleAcceptSwapRequest = useCallback(async () => {
    await handleAcceptSwap();
    setIsApprovedSwapModalOpen(false);
    navigateBooking();
  }, [handleAcceptSwap, navigateBooking]);

  const handleApprovedSwapButton = useCallback(async () => {
    if (autoSwapShift) {
      await handleAcceptSwap();
      goBack();
      return;
    }
    setIsApprovedSwapModalOpen(true);
  }, [autoSwapShift, goBack, handleAcceptSwap]);

  const position = mappedPositions[booking?.positionId];

  const day = useMemo(
    () => moment(booking?.startTime).format('dddd MMM, DD'),
    [booking?.startTime]
  );
  const time = useMemo(
    () => rangeFormat(booking?.startTime, booking?.endTime),
    [booking?.endTime, booking?.startTime]
  );

  const secondEmployee = useMemo(
    () =>
      hasSwap
        ? { employee: booking?.swap?.employee, ...booking?.swap?.slot }
        : params?.params,
    [booking?.swap?.employee, booking?.swap?.slot, hasSwap, params?.params]
  );

  const day2 = useMemo(
    () => moment(secondEmployee?.startTime).format('dddd MMM, DD'),
    [secondEmployee?.startTime]
  );
  const time2 = useMemo(
    () => rangeFormat(secondEmployee?.startTime, secondEmployee?.endTime),
    [secondEmployee?.endTime, secondEmployee?.startTime]
  );
  const position2 = mappedPositions[secondEmployee?.positionId];

  const requestReasonsOptions = useMemo(
    () =>
      (requestReasons || []).map(({ id, label, value }) => ({
        id,
        slug: label,
        value,
      })),
    [requestReasons]
  );

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ backgroundColor: 'white' }}
    >
      <Container>
        <TopWrapper>
          <HeaderRow>
            <BranchName>
              {booking?.swap?.typeId === 1
                ? t('yourShiftToSwap')
                : t('yourCurrentShift')}
            </BranchName>
          </HeaderRow>
          <ShiftInfoCard
            image={data?.image}
            name={`${data?.firstName || ''} ${data?.lastName || ''}`}
            day={day}
            time={time}
            position={t(position?.slug)}
          />
        </TopWrapper>
        <BottomWrapper>
          <HeaderRow>
            <BranchName>{t('swapWith')}</BranchName>
          </HeaderRow>
          <ShiftInfoCard
            image={secondEmployee?.employee?.image}
            name={`${secondEmployee?.employee?.firstName || ''} ${
              secondEmployee?.employee?.lastName || ''
            }`}
            day={day2}
            time={time2}
            position={t(position2?.slug)}
          />
          {!booking?.swap?.id && (
            <BaseView>
              <AsteriskText>*</AsteriskText>
              <ReasonSelect
                value={selectedReason ? [selectedReason] : null}
                options={requestReasonsOptions}
                isMulti={false}
                rtl
                placeholder="Reason*"
                onSelected={(value) => setSelectedReason(value?.[0])}
              />
            </BaseView>
          )}
        </BottomWrapper>
        {booking?.swap?.typeId === SwapType.RECEIVER &&
        booking?.swap?.status === ShiftSwapStatus.REQUEST_INITIATED ? (
          <ButtonContainer2>
            <Button
              title={t('decline')}
              radius={30}
              fontSize={18}
              paddingHorizontal={40}
              onPress={handleCancelSwapRequest}
              type="primary"
              style={{
                backgroundColor: colors.decline,
                borderColor: colors.decline,
              }}
              isLoading={isCancellingSwap}
            />
            <Button
              paddingHorizontal={40}
              title={t('accept')}
              radius={30}
              fontSize={18}
              onPress={handleApprovedSwapButton}
              type="primary"
              style={{
                backgroundColor: colors.approve,
                borderColor: colors.approve,
              }}
              isLoading={isAcceptingSwap}
            />
          </ButtonContainer2>
        ) : booking?.swap?.status === ShiftSwapStatus.REQUEST_INITIATED ||
          !booking?.swap?.id ? (
          <ButtonContainer>
            <Button
              title={!hasSwap ? t('sendRequest') : t('cancelSwap')}
              radius={30}
              fontSize={18}
              onPress={() =>
                !hasSwap ? setIsSentModalOpen(true) : setIsCancelModalOpen(true)
              }
              type="primary"
              disabled={!hasSwap && !selectedReason}
            />
          </ButtonContainer>
        ) : (
          <BaseView />
        )}
        {booking?.swap?.status === 1 && (
          <ClockinFooter>
            <DontForgetText>{t('swapConfirmed').toUpperCase()}</DontForgetText>
          </ClockinFooter>
        )}
        <TwoButtonModal
          firstButton={{
            title: t('cancel'),
            onPress: () => setIsSentModalOpen(false),
            isLoading: false,
          }}
          secondButton={{
            title: t('ok'),
            onPress: handleSendSwapRequest,
            isLoading: isCreatingSwap,
          }}
          isOpen={isSentModalOpen}
          title={t('terms')}
          subtitle={t('initiatedSwapMessage')}
        />
        <TwoButtonModal
          firstButton={{
            title: t('cancel'),
            onPress: () => setIsApprovedSwapModalOpen(false),
            isLoading: false,
          }}
          secondButton={{
            title: t('ok'),
            onPress: handleAcceptSwapRequest,
            isLoading: isAcceptingSwap,
          }}
          isOpen={isApprovedSwapModalOpen}
          title={t('terms')}
          subtitle={t('approvedSwapMessage')}
        />
        <TwoButtonModal
          secondButton={{
            title: t('yes'),
            onPress: handleCancelSwapRequest,
            isLoading: isCancellingSwap,
          }}
          firstButton={{
            title: t('no'),
            onPress: () => setIsCancelModalOpen(false),
            isLoading: false,
          }}
          isOpen={isCancelModalOpen}
          title={t('confirmOperation')}
          subtitle={t('cancelSwapMessage', {
            employeeName: `${secondEmployee?.employee?.firstName || ''} ${
              secondEmployee?.employee?.lastName || ''
            }`,
          })}
        />
      </Container>
    </ScrollView>
  );
};
