import React, { FC, useCallback } from 'react';
import { IFieldOption } from '@violetta/ubeya/entities';
import styled from 'styled-components/native';
import { FlexRow } from '../Layout';
import { H5Medium, H6Medium } from '../Typography';
import { BaseSelect } from './BaseSelect';
import { SelectProps } from './types';

type Props = Omit<
  SelectProps<IFieldOption, IFieldOption['id']>,
  'optionToSelectedOption'
>;

const Container = styled(FlexRow)``;

const Title = styled(H6Medium)`
  color: ${({ theme }) => theme.colors.surface};
  font-weight: 500;
`;

const ListTitle = styled(H5Medium)`
  color: ${({ theme }) => theme.colors.gray1};
  font-weight: 500;
`;

const SelectedOptionChip: FC<IFieldOption> = ({ title }) => (
  <Container>
    <Title>{title}</Title>
  </Container>
);
const ListOption: FC<IFieldOption> = ({ title }) => (
  <Container>
    <ListTitle>{title}</ListTitle>
  </Container>
);

export const FieldSelect: FC<Props> = ({ ...props }) => {
  const optionToSelectedOption = useCallback(
    ({ id }: IFieldOption) => id as IFieldOption['id'],
    []
  );

  return (
    <BaseSelect
      {...props}
      optionToSelectedOption={optionToSelectedOption}
      renderSelectedOptionChip={SelectedOptionChip}
      renderOption={ListOption}
    />
  );
};
