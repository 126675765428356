import React, { FC, useContext } from 'react';

import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderBar, HeaderlessBar } from '@violetta/ubeya/ui';
import { useTheme } from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import { TimeAvailabilityScreen } from '../screens';
import { TimeAvailabilityScreenNames } from './ScreenNames';
import { TimeAvailabilityHeader } from '../components';

const { Navigator, Screen } = createNativeStackNavigator();

export const TimeAvailabilityNavigation: FC = () => {
  const { colors } = useTheme();
  const { rtl } = useContext(ConfigContext);

  return (
    <Navigator
      initialRouteName={TimeAvailabilityScreenNames.MAIN}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
      }}
    >
      <Screen
        name={TimeAvailabilityScreenNames.MAIN}
        component={TimeAvailabilityScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <TimeAvailabilityHeader {...props} shadowed={false} />
          ),
        }}
      />
    </Navigator>
  );
};
