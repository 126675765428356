export const StatsColorsMap = {
  timesheetCheckedIn: '#7581C2',
  inShift: '#8C9FFF',
  finished: '#6467EF',
  noStart: '#D2D7EB',
  absent: '#A82865',
  onTimeClockIn: '#10B07C',
  lateClockIn: '#F24F63',
  earlyClockOut: '#F4B346',
  lateClockOut: '#F83',
};
