import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderlessBar } from '@violetta/ubeya/ui';
import React, { FC } from 'react';
import { useTheme } from 'styled-components/native';
import { DirectoryHeader } from '../components';
import { ContactScreen } from '../screens/ContactScreen';
import { DirectoryScreen } from '../screens/DirectoryScreen';
import { DirectoryScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const DirectoryNavigation: FC = () => {
  const { colors } = useTheme();
  return (
    <Navigator
      initialRouteName={DirectoryScreenNames.DIRECTORY}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
        // eslint-disable-next-line react/jsx-closing-bracket-location
      }}
    >
      <Screen
        name={DirectoryScreenNames.DIRECTORY}
        component={DirectoryScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <DirectoryHeader {...props} />
          ),
        }}
      />
      <Screen
        name={DirectoryScreenNames.CONTACT}
        component={ContactScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <DirectoryHeader {...props} />
          ),
        }}
      />
    </Navigator>
  );
};
