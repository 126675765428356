import React, { FC, useCallback } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import { debounce } from 'lodash';
import { Form, FormSpy } from 'react-final-form';
import styled, { useTheme } from 'styled-components/native';
import { InputField, StarRatingField } from '../FormFields';
import { BaseView, FlexColumnDirection, FlexRowBetween } from '../Layout';
import { StarRating } from '../StarRating';
import { TextInput } from '../TextInputs';
import { BaseText } from '../Typography';
import { BaseCollapsible } from './BaseCollapsible';

const Container = styled(FlexColumnDirection)`
  margin-top: 10px;
`;

const StarContainer = styled(BaseView)`
  width: 200px;
  margin: 10px 0;
`;

const InputContainer = styled(BaseView)``;

const RateShift = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray15};
`;

const EventFeedback = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
`;

interface Props {
  project: any;
  updateProject: ({ values }: { values: any }) => void;
}

const inputStyle = (colors) => ({
  borderTopWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  marginBottom: 10,
  paddingLeft: 0,
  paddingBottom: 0,
  fontSize: 14,
  backgroundColor: colors.whitesmoke,
  color: colors.textPrimary,
});

const Trigger = React.memo(() => {
  const { t } = useTranslation();

  return (
    <FlexRowBetween>
      <BaseView>
        <EventFeedback>{t('addGeneralFeedback')}</EventFeedback>
      </BaseView>
    </FlexRowBetween>
  );
});

export const FeedbackCollapsible: FC<Props> = React.memo(
  ({ project, updateProject }) => {
    const { t } = useTranslation();
    const { colors } = useTheme();
    const onFeedbackChange = debounce(
      useCallback(
        ({ dirty, values }) => {
          if (!dirty) {
            return;
          }

          if (
            !values?.managerRating &&
            !values?.managerClientFeedback &&
            !values?.managerNotes &&
            !values?.managerIssues
          ) {
            return;
          }

          const formattedValues = {
            managerRating: values?.managerRating || undefined,
            managerClientFeedback: values?.managerClientFeedback || undefined,
            managerNotes: values?.managerNotes || undefined,
            managerIssues: values?.managerIssues || undefined,
          };

          updateProject({ values: formattedValues });
        },
        [updateProject]
      ),
      1500
    );

    return (
      <BaseCollapsible
        trigger={Trigger}
        containerStyle={{
          marginBottom: 20,
          paddingHorizontal: 15,
          borderBottomColor: 'transparent',
        }}
      >
        <Form
          onSubmit={() => {}}
          initialValues={{
            managerRating: project?.managerRating,
            managerClientFeedback: project?.managerClientFeedback,
            managerNotes: project?.managerNotes,
            managerIssues: project?.managerIssues,
          }}
          render={() => (
            <Container>
              <FormSpy
                subscription={{ values: true, dirty: true, dirtyFields: true }}
                onChange={onFeedbackChange}
              />
              <RateShift>{t('rate15')}</RateShift>
              <StarContainer>
                <StarRatingField
                  component={StarRating}
                  fieldProps={{
                    name: 'managerRating',
                  }}
                  props={{
                    total: 5,
                  }}
                />
              </StarContainer>
              <InputContainer>
                <InputField
                  fieldProps={{
                    name: 'managerClientFeedback',
                  }}
                  component={TextInput}
                  props={{
                    placeholder: t('addAReview'),
                    style: inputStyle(colors),
                    placeholderTextColor: colors.gray2,
                  }}
                />
                <InputField
                  fieldProps={{
                    name: 'managerNotes',
                  }}
                  props={{
                    placeholder: t('notes'),
                    style: inputStyle(colors),
                    placeholderTextColor: colors.gray2,
                  }}
                  component={TextInput}
                />
                <InputField
                  fieldProps={{
                    name: 'managerIssues',
                  }}
                  props={{
                    placeholder: t('problems'),
                    style: inputStyle(colors),
                    placeholderTextColor: colors.gray2,
                  }}
                  component={TextInput}
                />
              </InputContainer>
            </Container>
          )}
        />
      </BaseCollapsible>
    );
  }
);
