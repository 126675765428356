import React, { FC, useCallback, useMemo } from 'react';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { BaseView, FlexRow } from '../Layout/Layout';
import { H4Medium } from '../Typography/Typography';
import { BaseSelect } from './BaseSelect';

const Container = styled(FlexRow)``;

const Title = styled(H4Medium)`
  font-weight: 500;
`;

const ListTitle = styled(H4Medium)`
  font-weight: 500;
`;

const OptionChip = styled(BaseView)``;

const SelectedOptionChip: FC<IClockTime> = ({ id, slug }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>{t(slug)}</Title>
    </Container>
  );
};
const ListOption: FC<IClockTime> = ({ id, slug }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <ListTitle>{t(slug)}</ListTitle>
    </Container>
  );
};

interface IClockTime {
  id: string;
}

type Props = Omit<
  SelectProps<IClockTime, IClockTime['id']>,
  'optionToSelectedOption' | 'options'
> & { showCheckIn?: boolean; showClockIn?: boolean; showClockOut?: boolean };

const times = ['startTime', 'checkInTime', 'checkOutTime', 'endTime'];

export const ClockTimeSelect: FC<Props> = ({
  showCheckIn,
  showClockIn,
  showClockOut,
  ...props
}) => {
  const options = useMemo(
    () => [
      ...(showClockIn ? [{ id: 'startTime', slug: 'clockIn' }] : []),
      ...(showCheckIn
        ? [
            { id: 'checkInTime', slug: 'timesheetCheckIn' },
            { id: 'checkOutTime', slug: 'timesheetCheckOut' },
          ]
        : []),
      ...(showClockOut ? [{ id: 'endTime', slug: 'clockOut' }] : []),
    ],
    [showCheckIn, showClockIn, showClockOut]
  );

  const optionToSelectedOption = useCallback(
    ({ id }: IClockTime) => id as IClockTime['id'],
    []
  );

  return (
    <BaseSelect
      {...props}
      options={options}
      optionToSelectedOption={optionToSelectedOption}
      renderSelectedOptionChip={SelectedOptionChip}
      renderOption={ListOption}
      renderOptionChip={OptionChip}
    />
  );
};
