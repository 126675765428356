import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigation } from '@react-navigation/native';
import { lotties } from '@violetta/ubeya/assets';
import {
  IOffer,
  useAccounts,
  useBranches,
  useLocations,
  useOffers,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { EmptyState } from '@violetta/ubeya/ui';
import { cloneDeep, findKey, set, update } from 'lodash';
import moment from 'moment';
import { FlatList, RefreshControl, SafeAreaView } from 'react-native';
import { DateObject } from 'react-native-calendars';
import { useRecoilState } from 'recoil';
import styled, { useTheme } from 'styled-components/native';
import {
  isOfferCalendarOpenAtom,
  isOffersCalendarClickedAtom,
  offerCalendarMonthAtom,
  offersFilterAtom,
  offersFilterAtomDefault,
  userLocationAtom,
} from '../../../state';
import { ProjectOffer } from '../../home/components/ProjectOffersCard/ProjectOffer';
import { FlatListWrapper } from '../components/FlatListWrapper';
import { OfferCalendar } from '../components/OfferCalendar';
import { OffersScreenNames } from '../navigation/ScreenNames';
import { OfferFilterButtons } from '../components/OfferFilterButtons';
import { OfferStatusFilterOptions } from '../constants';
import { distance } from '@violetta/ubeya/utils';

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

interface Props {}

const ITEM_HEIGHT = 210;

export const OffersScreen: FC<Props> = () => {
  const [offerCalendarMonth, setOfferCalendarMonth] = useRecoilState(
    offerCalendarMonthAtom
  );
  const [, setIsOfferCalendarOpen] = useRecoilState(isOfferCalendarOpenAtom);
  const [isOfferCalendarClicked] = useRecoilState(isOffersCalendarClickedAtom);
  const [userLocation] = useRecoilState(userLocationAtom);
  const [filters, setFilters] = useRecoilState(offersFilterAtom);
  const { offers, isLoading, refetch } = useOffers();
  const [calendarDays, setCalendarDays] = useState({});
  const [search, setSearch] = useState('');
  const { navigate } = useNavigation();
  const { mappedAccounts } = useAccounts();
  const { mappedBranches } = useBranches();
  const { colors } = useTheme();
  const today = useMemo(() => moment().format('YYYY-MM-DD'), []);
  const flatListRef = useRef<FlatList>(null);
  const { mappedLocations } = useLocations();

  const handleOfferStatusFilter = useCallback(
    (offer, offerStatusFilter) =>
      offerStatusFilter[OfferStatusFilterOptions.PENDING]
        ? offer.didApply
        : offerStatusFilter[OfferStatusFilterOptions.REJECTED]
        ? offer.didReject
        : offerStatusFilter[OfferStatusFilterOptions.OPEN]
        ? !offer.didReject && !offer.didApply
        : true,

    []
  );

  const filterByDistance = useCallback(
    (offer: IOffer, distanceFilter) => {
      const offerLocation = mappedLocations?.[offer?.locationId];
      if (
        !userLocation ||
        !offerLocation?.lat ||
        !offerLocation?.lng ||
        offerLocation?.lat === 0 ||
        offerLocation?.lng === 0 ||
        !distanceFilter ||
        distanceFilter.length === 0 ||
        distanceFilter?.[0] === 110
      ) {
        return true;
      }
      const currentDistance = distance(userLocation, {
        lat: offerLocation?.lat,
        lng: offerLocation?.lng,
      });

      return currentDistance <= distanceFilter[0];
    },
    [mappedLocations, userLocation]
  );

  const filterBySearchTerm = useCallback(
    (offer) => {
      const branchName = mappedBranches?.[offer?.branchId]?.name;
      const locationAdress = mappedLocations?.[offer?.locationId]?.address;
      const locationName = mappedLocations?.[offer?.locationId]?.name;

      return search
        ? (branchName &&
            branchName.toLowerCase().includes(search.toLowerCase())) ||
            (locationAdress &&
              locationAdress.toLowerCase().includes(search.toLowerCase())) ||
            (locationName &&
              locationName.toLowerCase().includes(search.toLowerCase())) ||
            (offer.compoundedName &&
              offer.compoundedName.toLowerCase().includes(search.toLowerCase()))
        : true;
    },
    [mappedBranches, mappedLocations, search]
  );

  const filteredOffers = useMemo(() => {
    const filtersClone = cloneDeep(filters);

    let allOffers = offers;

    Object.keys(filtersClone).map((key) =>
      update(filtersClone, key, (valuesProp) =>
        Array.isArray(valuesProp) ? valuesProp.filter(Boolean) : valuesProp
      )
    );

    if (
      filtersClone.branchIds?.length > 0 ||
      filtersClone.positionIds.length > 0 ||
      filtersClone.offersStatus
    ) {
      allOffers = offers
        .filter(
          (offer) =>
            (filtersClone.branchIds?.length > 0
              ? filtersClone.branchIds?.includes(offer.branchId)
              : true) &&
            (filtersClone.positionIds.length > 0
              ? filtersClone.positionIds.some((item) =>
                  offer.shifts.map((shift) => shift.positionId).includes(item)
                )
              : true)
        )
        .filter((offer) =>
          handleOfferStatusFilter(offer, filtersClone.offersStatus)
        )
        .filter(filterBySearchTerm)
        .filter((offer) => filterByDistance(offer, filtersClone.distance));
    }

    return allOffers;
  }, [
    filters,
    offers,
    filterBySearchTerm,
    filterByDistance,
    handleOfferStatusFilter,
  ]);

  const scrollToIndex = useCallback(
    (index) => {
      if (index <= filteredOffers.length - 1) {
        flatListRef?.current?.scrollToIndex({ animated: true, index });
      }
    },
    [filteredOffers.length]
  );

  const handleSelectDate = useCallback(
    (date: DateObject) => {
      const clonedDays = cloneDeep(calendarDays);
      const currentlySelectedKey = findKey(
        clonedDays,
        (value) => value.selected && !value.isToday
      );

      if (currentlySelectedKey) {
        set(clonedDays, [currentlySelectedKey, 'selected'], false);
      }
      set(clonedDays, [date.dateString, 'selected'], true);
      setCalendarDays(clonedDays);
      const firstOfferInDateIndex = (filteredOffers || []).findIndex(
        ({ date: offerDate }) => date.dateString === offerDate
      );
      if (firstOfferInDateIndex !== -1) {
        scrollToIndex(firstOfferInDateIndex);
      }
    },
    [calendarDays, filteredOffers, scrollToIndex]
  );
  const { t } = useTranslation();

  useEffect(() => {
    handleSelectDate({ dateString: today });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOfferCalendarClicked]);

  useEffect(() => {
    const tempCalendarDays = filteredOffers.reduce(
      (acc, offer) => ({
        ...acc,
        [offer?.date]: {
          disabled: false,
        },
      }),
      {}
    );
    set(tempCalendarDays, today, {
      selected: true,
      isToday: true,
      disabled: false,
      selectedColor: colors.primary,
      selectedTextColor: colors.surface,
      color: colors.primary,
      textColor: colors.surface,
    });
    setCalendarDays(tempCalendarDays);
  }, [
    colors.primary,
    colors.surface,
    colors.textPrimary,
    filteredOffers,
    today,
  ]);

  const navigateOffer = useCallback(
    (offer: IOffer) => {
      navigate(OffersScreenNames.MAIN, {
        screen: OffersScreenNames.OFFER,
        params: {
          offerId: offer.id,
        },
      });
    },
    [navigate]
  );

  const handleViewableItemsChanged = useCallback(
    ({ viewableItems }) => {
      setOfferCalendarMonth(
        moment(viewableItems[0]?.item?.date).format('MMM YYYY')
      );
    },
    [setOfferCalendarMonth]
  );

  const renderItem = useCallback(
    ({ item }) => (
      <ProjectOffer
        key={item.id}
        offer={item}
        mappedBranches={mappedBranches}
        onPress={navigateOffer}
        mappedLocations={mappedLocations}
        mappedAccounts={mappedAccounts}
        userLocation={userLocation}
      />
    ),
    [
      mappedAccounts,
      mappedBranches,
      mappedLocations,
      navigateOffer,
      userLocation,
    ]
  );

  const handleOnScrollBeginDrag = useCallback(() => {
    setIsOfferCalendarOpen(false);
  }, [setIsOfferCalendarOpen]);

  const handleGetItemLayout = useCallback(
    (data, index) => ({
      length: ITEM_HEIGHT,
      offset: ITEM_HEIGHT * index,
      index,
    }),
    []
  );

  const removeFilters = useCallback(
    () => setFilters(offersFilterAtomDefault),
    [setFilters]
  );

  return (
    <Container>
      <OfferCalendar dates={calendarDays} handleSelectDate={handleSelectDate} />
      <OfferFilterButtons
        setSearch={setSearch}
        search={search}
        t={t}
        showClearFiltersMessage={filteredOffers?.length !== offers?.length}
        totalOffers={offers?.length}
        removeFilters={removeFilters}
      />
      <FlatListWrapper
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderItem}
        ref={flatListRef}
        data={filteredOffers}
        scrollEventThrottle={16}
        onScrollBeginDrag={handleOnScrollBeginDrag}
        contentContainerStyle={{
          flexGrow: 1,
        }}
        ListEmptyComponent={
          <EmptyState
            hide={isLoading}
            lottieSrc={lotties.noOffers}
            title={t('emptyStateOffers')}
          />
        }
        refreshing={isLoading || !calendarDays}
        refreshControl={
          <RefreshControl onRefresh={refetch} refreshing={isLoading} />
        }
        getItemLayout={handleGetItemLayout}
        onViewableItemsChanged={handleViewableItemsChanged}
        viewabilityConfig={{ itemVisiblePercentThreshold: 80 }}
      />
    </Container>
  );
};
