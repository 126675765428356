import { useMemo } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { selectedAccount as selectedAccountAtom } from '@violetta/ubeya/shared';
import {
  createAdminStoreKey,
  useOptimisticMutation,
} from '@violetta/ubeya/utils';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

const selector = createCachedSelector(
  (data) => data?.data,
  ({ data }) => {
    const neededData = data.filter(
      (request) => !request?.data?.swapId && request.typeId !== 4
    );
    const pastRequests = neededData
      .filter((req) => req.status !== 0)
      .sort((request1, request2) =>
        !request1.createdAt || !request2.createdAt
          ? -1
          : moment(request1.createdAt).isAfter(request2.createdAt)
          ? -1
          : 1
      );
    const activeRequests = neededData
      .filter((req) => req.status === 0)
      .sort((request1, request2) =>
        !request1.createdAt || !request2.createdAt
          ? -1
          : moment(request1.createdAt).isAfter(request2.createdAt)
          ? -1
          : 1
      );
    return { pastRequests, activeRequests };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useRequests = () => {
  const [selectedAccount] = useRecoilState(selectedAccountAtom);
  const accountId = useMemo(() => selectedAccount?.id, [selectedAccount?.id]);
  const storeKey = createAdminStoreKey(['requests', accountId]);
  const { isPending, data, refetch } = useQuery({
    queryKey: storeKey,
    queryFn: () => adminApi.fetchRequests({ accountId }),
    refetchInterval: 1 * 60 * 1000,
    select: (data) => selector(data, storeKey.join('#')),
  });
  const { activeRequests, pastRequests } = data || {};
  const queryClient = useQueryClient();

  const { mutateAsync: updateRequestStatus, isPending: isUpdatingRequest } =
    useMutation({
      mutationFn: ({
        requestId,
        status,
        managerReason,
      }: {
        requestId: number;
        status: 1 | 2;
        managerReason?: string;
      }) =>
        adminApi.updateRequest({ accountId, requestId, status, managerReason }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['projects', accountId] });
        queryClient.invalidateQueries({ queryKey: storeKey });
      },
    });

  return {
    isLoading: isPending,
    refetch,
    activeRequests,
    pastRequests,
    updateRequestStatus,
    isUpdatingRequest,
  };
};
