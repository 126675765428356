import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  ChevronLeft,
  ChevronRight,
  FlexCenter,
  FlexRow,
  H5Light,
  SvgIcon,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { Pressable } from 'react-native';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../contexts';
import { timeAvailabilitySelectedWeek } from '../../../../state';
import { icons } from '@violetta/ubeya/assets';

const Wrapper = styled(BaseView)``;

const Container = styled(FlexCenter)`
  margin-bottom: 8px;
`;

const TitleContainer = styled(BaseView)`
  align-items: center;
`;

const Legend = styled(BaseView)`
  margin: 0 15px 5px;
  padding: 0 20px;
  align-items: center;
  justify-content: center;
`;
const LegendItem = styled(FlexRow)`
  padding: 5px 0;
  align-items: center;
  justify-content: center;
`;
const LegendSeparator = styled(H5Light)`
  margin-right: 10px;
`;
const LegendIcon = styled(BaseView)`
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  width: 20px;
`;
const LegendContent = styled(H5Light)`
  flex: 1;
  font-size: 13px;
`;

const Title = styled(H5Light)`
  margin: 0 20px;
  font-size: 19px;
`;

const Subtitle = styled(H5Light)`
  margin-top: 4px;
  font-size: 15px;
  font-weight: 400;
`;

const DisabledChip = styled<{ status: number }>(BaseView)`
  padding: 6px 22px;
  background-color: ${({ theme }) => theme.colors.error2};
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Disabled = styled(H5Light)`
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.surface};
`;

interface Props {}

export const TimeAvailabilityHeader: FC<Props> = () => {
  const [selectedWeek, setSelectedWeek] = useRecoilState(
    timeAvailabilitySelectedWeek
  );
  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();
  const onPrev = useCallback(() => {
    setSelectedWeek(({ date }) => ({
      date: moment(date).subtract(1, 'week').toDate(),
    }));
  }, [setSelectedWeek]);
  const onNext = useCallback(() => {
    setSelectedWeek(({ date }) => ({
      date: moment(date).add(1, 'week').toDate(),
    }));
  }, [setSelectedWeek]);

  const weeksDiff = useMemo(
    () => moment(selectedWeek.date).diff(moment().startOf('week'), 'week'),
    [selectedWeek.date]
  );

  return (
    <Wrapper>
      <Container>
        <Pressable onPress={onPrev}>
          <ChevronLeft rtl={rtl} size={35} />
        </Pressable>
        <TitleContainer>
          <Title>
            {moment(selectedWeek.date).format('DD MMM')} -{' '}
            {moment(selectedWeek.date).add(6, 'day').format('DD MMM')}
          </Title>
          <Subtitle>
            {weeksDiff === 0
              ? t('thisWeek')
              : weeksDiff === 1
              ? t('nextWeek')
              : weeksDiff === -1
              ? t('lastWeek')
              : weeksDiff < 0
              ? `${t('xWeeksAgo', { weeks: weeksDiff * -1 })}`
              : `${t('xWeeksAfter', { weeks: weeksDiff })}`}
          </Subtitle>
        </TitleContainer>

        <Pressable onPress={onNext}>
          <ChevronRight rtl={rtl} size={35} />
        </Pressable>
      </Container>
      {!selectedWeek?.enabled && (
        <DisabledChip>
          <Disabled>{t('weeklyPreferencesDisabled')}</Disabled>
        </DisabledChip>
      )}
      <Legend>
        <LegendItem>
          <LegendIcon>
            <SvgIcon
              xml={icons.actionIcons.likeStroke}
              height={15}
              width={15}
            />
          </LegendIcon>
          <LegendSeparator>-</LegendSeparator>
          <LegendContent>{t('legendPreferToWork')}</LegendContent>
        </LegendItem>
        <LegendItem>
          <LegendIcon>
            <SvgIcon xml={icons.actionIcons.plus2} height={12} width={10} />
          </LegendIcon>
          <LegendSeparator>-</LegendSeparator>
          <LegendContent>{t('legendAddConstraint')}</LegendContent>
        </LegendItem>
      </Legend>
    </Wrapper>
  );
};
