import React, { FC, useCallback, useMemo } from 'react';
import { IPeriod, useLocalAvailabilities } from '@violetta/ubeya/entities';
import {
  BaseView,
  FlexColumnBetween,
  H5Medium,
  H7Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

const DateContainer = styled(FlexColumnBetween)`
  min-height: 44px;
  flex: 1;
`;
const DateLetter = styled(H7Regular)<{ hasLabels: boolean }>`
  opacity: ${({ hasLabels }) => (hasLabels ? '1.0' : '0.4')};
  margin-bottom: 5px;
`;
const DateDay = styled(H5Medium)<{
  hasLabels: boolean;
  filled: boolean;
  selected: boolean;
}>`
  font-weight: 500;
  opacity: ${({ hasLabels }) => (hasLabels ? '1.0' : '0.4')};
  color: ${({ selected, filled, hasLabels, theme }) =>
    selected
      ? theme.colors.primary
      : filled
      ? theme.colors.surface
      : hasLabels
      ? theme.colors.primary
      : theme.colors.gray1};
`;

const DateDayContainer = styled(BaseView)<{
  selected: boolean;
  filled: boolean;
}>`
  height: 35px;
  width: 35px;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  ${({ filled, theme, selected }) =>
    selected
      ? `border-width: 2px;
			border-color: ${theme.colors.primary};`
      : filled
      ? `border-radius: 50px;
			background-color: ${theme.colors.primary};`
      : ''}
`;

interface Props {
  date: Date;
  period: IPeriod;
  labels: number[];
  selectedDay: Date;
  onDayPress?: (date: Date) => void;
  markSelectedDate?: boolean;
}

export const WeekListDay: FC<Props> = ({
  date,
  period: { id: periodId },
  labels,
  selectedDay,
  markSelectedDate = true,
  onDayPress,
}) => {
  const { filledDay } = useLocalAvailabilities({ periodId });
  const dayMarked = useMemo(() => filledDay(date), [filledDay, date]);
  const selected = useMemo(
    () => moment(date).isSame(moment(selectedDay), 'date'),
    [date, selectedDay]
  );

  const onDatePress = useCallback(
    () => (dayMarked ? undefined : onDayPress?.(date)),
    [onDayPress, date, dayMarked]
  );

  return (
    <DateContainer as={RipplePressable} onPress={onDatePress}>
      <DateLetter hasLabels={labels.length > 0}>
        {moment(date).format('ddd')}
      </DateLetter>

      <DateDayContainer
        selected={selected && markSelectedDate}
        filled={!selected && dayMarked && labels.length > 0}
      >
        <DateDay
          hasLabels={labels.length > 0}
          selected={selected && markSelectedDate}
          // eslint-disable-next-line react/jsx-closing-bracket-location
          filled={!selected && dayMarked && labels.length > 0}
        >
          {moment(date).format('D')}
        </DateDay>
      </DateDayContainer>
    </DateContainer>
  );
};
