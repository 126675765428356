import React, { FC, useContext } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { useTranslation } from '@violetta/ubeya/i18n';

import { FlexRowBetween, BaseText, SearchBox } from '@violetta/ubeya/ui';
import { throttle } from 'lodash';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import { searchStateAtom } from '../../../state';
import { ConfigContext } from '../../../contexts/ConfigContext';

const Container = styled(FlexRowBetween)`
  flex: 1;
  justify-content: center;
`;

const Title = styled(BaseText)`
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: #252631;
`;

export const SwapHeaderTitle: FC<StackHeaderProps> = () => {
  const [searchState, setSearchState] = useRecoilState(searchStateAtom);

  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();
  return (
    <Container>
      {searchState.isSearchOpen ? (
        <SearchBox
          autoFocus
          searchTerm={searchState.searchKey}
          onChange={throttle(
            (text: string) =>
              setSearchState({ ...searchState, searchKey: text }),
            750
          )}
          autoCorrect={false}
          rtl={rtl}
        />
      ) : (
        <Title>{t('swapShift')}</Title>
      )}
    </Container>
  );
};
