import React, { FC, useContext, useRef } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import {
  AccountImage,
  BaseText,
  BaseView,
  ChevronDown,
  FlexRowBetween,
  H3Medium,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { Modalize } from 'react-native-modalize';
import styled from 'styled-components/native';
import { AccountPickerModal } from '../../../components/Modals/AccountPickerModal';
import { AppContext } from '../../main/AppContext';

const Container = styled(FlexRowBetween)`
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Title = styled(H3Medium)`
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-left: 10px;
`;

export const TimeAvailabilityHeaderTitle: FC<StackHeaderProps> = () => {
  const modalizeRef = useRef<Modalize>(null);
  const {
    profile: { selectedAccount },
  } = useContext(AppContext);

  return (
    <>
      <Container
        as={RipplePressable}
        onPress={() => modalizeRef.current?.open()}
      >
        <AccountImage size={26} image={selectedAccount?.logo} />
        <Title>{selectedAccount?.name || ''}</Title>
        <ChevronDown />
      </Container>
      <AccountPickerModal modalizeRef={modalizeRef} />
    </>
  );
};
