import React, { FC, useEffect, useMemo, useState } from 'react';
import { images } from '@violetta/ubeya/assets';
import { Dimensions } from 'react-native';
import Swiper from 'react-native-swiper';
import styled, { useTheme } from 'styled-components/native';
import { BaseText, Card, SvgIcon } from '..';
import { BaseView, RipplePressable } from '../Layout';
import { useTranslation } from '@violetta/ubeya/i18n';
import { Platform } from 'react-native';

const SwiperContainer = styled(BaseView)<{ $height: number }>`
  height: ${({ $height }) => $height}px;
  margin-bottom: 22px;
`;

const LastItemCard = styled(Card)`
  height: 190px;
  position: relative;
`;

const LastItemText = styled(BaseText)<{ rtl: boolean }>`
  font-size: 20px;
  font-weight: bold;
  line-height: 32px;
  color: #252525;
  position: absolute;
  ${({ rtl }) => (rtl ? 'left: 20px;' : 'right: -30px;')}
  width: 150px;
  top: 33%;
`;

interface Props {
  cards: any[];
  initialIndex: number;
  swipeComponent: (item: any, index: number) => React.Element<any>;
  maxNumOfItems?: number;
  hideLastItem?: boolean;
  onLastItemPress?: () => void;
  lastItemText?: string;
  height?: number;
  rtl?: boolean;
  changeEveryInterval?: boolean;
  interval?: number;
}

const sliderWidth = Dimensions.get('window').width - 30;

export const SwiperView: FC<Props> = ({
  cards,
  initialIndex = 0,
  swipeComponent,
  maxNumOfItems = 4,
  onLastItemPress,
  rtl = false,
  hideLastItem = false,
  height = 220,
  changeEveryInterval = false,
  interval = 60000, //minute
}) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const [activeCardIndex, setActiveCardIndex] = useState(initialIndex);

  const showLastItem = useMemo(
    () => cards.length > maxNumOfItems,
    [cards.length, maxNumOfItems]
  );
  const currentCards = useMemo(
    () => cards.slice(0, maxNumOfItems),
    [cards, maxNumOfItems]
  );
  const allItems = useMemo(
    () =>
      !hideLastItem && showLastItem
        ? [
            ...currentCards.map(swipeComponent),
            <LastItemCard
              key={maxNumOfItems + 1}
              as={RipplePressable}
              onPress={onLastItemPress}
            >
              <SvgIcon
                xml={images.svgs.lastItem}
                width={sliderWidth}
                height={190}
              />
              <LastItemText rtl={rtl}>{t('lastItemText')}</LastItemText>
            </LastItemCard>,
          ]
        : currentCards.map(swipeComponent),
    [
      currentCards,
      hideLastItem,
      maxNumOfItems,
      onLastItemPress,
      rtl,
      showLastItem,
      swipeComponent,
      t,
    ]
  );

  useEffect(() => {
    if (!changeEveryInterval) {
      return;
    }

    const intervalId = setInterval(() => {
      setActiveCardIndex((prevIndex) => {
        const prevIndexFormatted =
          prevIndex < 0 ? prevIndex + allItems.length : prevIndex;
        return (prevIndexFormatted + 1) % allItems.length;
      });
    }, interval);

    return () => clearInterval(intervalId);
  }, [activeCardIndex, allItems.length, changeEveryInterval, interval]);

  return (
    <SwiperContainer $height={height}>
      <Swiper
        index={activeCardIndex}
        loop={false}
        onIndexChanged={(index) => {
          const indexFormatted = index < 0 ? index + allItems.length : index;
          return setActiveCardIndex(indexFormatted % allItems.length);
        }}
        dotColor={colors.gray19}
        activeDotColor={colors.primary}
        dotStyle={{ width: 6, height: 6, marginBottom: -60 }}
        style={{
          ...(rtl &&
            Platform.OS === 'android' && { flexDirection: 'row-reverse' }),
        }}
        paginationStyle={{
          ...(rtl &&
            Platform.OS === 'android' && { flexDirection: 'row-reverse' }),
        }}
        activeDotStyle={{ width: 8, height: 8, marginBottom: -60 }}
      >
        {allItems}
      </Swiper>
    </SwiperContainer>
  );
};
