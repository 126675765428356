import { useCallback, useMemo } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { createAdminStoreKey, mappedArray } from '@violetta/ubeya/utils';
import moment from 'moment';
import { createCachedSelector } from 're-reselect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import * as sharedAtoms from '../../../shared/src/atoms';

export const API_DATE_FORMAT = 'YYYY-MM-DD';

const getDate = (date) => moment(date).format(API_DATE_FORMAT);

const selector = createCachedSelector(
  (data) => data.data,
  ({ data }) => {
    const project = (() => {
      if (!data?.project) return null;
      return {
        ...data.project,
        shifts: data.project.shifts.map((shift) => ({
          ...shift,
          unbookings: (data?.unbookings || []).filter(
            ({ shiftId }) => shiftId === shift.id
          ),
        })),
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    })();

    const mappedShiftsByDay = (data?.project?.shifts || []).reduce(
      (all, shift) => {
        const shiftDate = shift.date;
        if (!all[shiftDate]) {
          all[shiftDate] = [];
        }

        all[shiftDate].push(shift);
        return all;
      },
      {}
    );
    const mappedShiftsByOrders = (data?.orders || []).map(() =>
      (data?.project?.shifts || []).reduce((all, shift) => {
        const shiftDate = shift.date;
        if (!all[shiftDate]) {
          all[shiftDate] = [];
        }

        all[shiftDate].push(shift);
        return all;
      }, {})
    );

    const shiftsByEmployee = (() => {
      const mappedShifts = {};
      if (!data?.project) {
        return {};
      }
      const { id, labelId, name, date, shifts } = data?.project;
      shifts.forEach(({ id: shiftId, slots, positionId }) => {
        (slots || []).forEach(({ employeeId, ...slot }) => {
          if (!employeeId) {
            return;
          }
          const formattedDate = getDate(date);
          const key = `${formattedDate}-${employeeId}`;

          mappedShifts[key] = [
            ...(mappedShifts[key] || []),
            {
              projectId: id,
              labelId,
              name,
              positionId,
              shiftId,
              slotId: slot.id,
              ...slot,
            },
          ];
        });
      });

      return mappedShifts;
    })();

    const employeesMap = mappedArray(data?.employees || []);

    const unbookings = data?.unbookings;

    return {
      data,
      project,
      shiftsByEmployee,
      employeesMap,
      unbookings,
      mappedShiftsByDay,
      mappedShiftsByOrders,
    };
  }
)({
  keySelector: (data, storeKey) => `${storeKey.join('#')}`,
});

export const useProject = ({ projectId, branchId }) => {
  const [selectedAccount] = useRecoilState(sharedAtoms.selectedAccount);
  const queryClient = useQueryClient();
  const storeKey = useMemo(
    () => createAdminStoreKey(['project', 'specific', Number(projectId)]),
    [projectId]
  );
  const accountId = selectedAccount?.id;

  const {
    isPending,
    data: selectorData,
    refetch,
  } = useQuery({
    queryKey: storeKey,
    queryFn: () =>
      adminApi.getProject({
        accountId,
        branchId,
        projectId,
      }),
    enabled: !!(branchId && projectId),
    select: (data) => selector(data, storeKey),
  });

  const {
    project,
    shiftsByEmployee = {},
    employeesMap = {},
    mappedShiftsByDay = {},
    mappedShiftsByOrders = [],
  } = selectorData || {};

  const refetchProject = useCallback(() => {
    refetch?.();
  }, [refetch]);

  const { mutateAsync: updateTimesheet, isPending: isUpdatingTimesheet } =
    useMutation({
      mutationFn: ({
        timesheetId,
        start,
        end,
      }: {
        timesheetId: string;
        start?: { time: Date };
        end?: { time: Date };
      }) => adminApi.updateTimesheet({ accountId, timesheetId, start, end }),
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: storeKey, exact: true });
      },
    });

  const { mutateAsync: updateSlot, isPending: isUpdatingSlot } = useMutation({
    mutationFn: ({
      shiftId,
      slotId,
      rating,
      review,
      notes,
      payrollNotes,
    }: {
      shiftId: number;
      slotId: number;
      rating?: number;
      review?: string;
      payrollNotes?: string;
      notes?: string;
    }) =>
      adminApi.updateSlot({
        accountId,
        branchId,
        projectId,
        shiftId,
        slotId,
        rating,
        review,
        notes,
        payrollNotes,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: storeKey, exact: true });
    },
  });

  const {
    mutateAsync: updateOutsourcedTimesheet,
    isPending: isUpdatingOutsourcedTimesheet,
  } = useMutation({
    mutationFn: ({
      timesheetId,
      start,
      end,
      clientApproved,
      clientDeclined,
    }: {
      timesheetId: number;
      start?: { time: Date };
      end?: { time: Date };
      clientApproved?: { status: boolean; signature?: string };
      clientDeclined?: { status: boolean; reason: string };
    }) =>
      adminApi.updateOutsourcedTimesheet({
        accountId,
        timesheetId,
        start,
        end,
        clientApproved,
        clientDeclined,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: storeKey, exact: true });
    },
  });

  const {
    mutateAsync: updateOutsourcedSlot,
    isPending: isUpdatingOutsourcedSlot,
  } = useMutation({
    mutationFn: ({
      slotId,
      rating,
      review,
    }: {
      shiftId: number;
      slotId: number;
      rating?: number;
      review?: string;
    }) =>
      adminApi.updateOutsourcedSlot({
        accountId,
        branchId,
        projectId,
        slotId,
        rating,
        review,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: storeKey, exact: true });
    },
  });

  const { mutateAsync: updateProject, isPending: isUpdatingProject } =
    useMutation({
      mutationFn: ({
        values,
      }: {
        values: {
          managerRating?: number;
          managerClientFeedback?: string;
          managerNotes?: string;
          managerIssues?: string;
        };
      }) =>
        adminApi.updateProject({
          projectId,
          branchId,
          accountId,
          data: values,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: storeKey, exact: true });
      },
    });

  return {
    isLoading: isPending,
    project,
    employeesMap,
    shiftsByEmployee,
    mappedShiftsByDay,
    mappedShiftsByOrders,
    refetch: refetchProject,
    updateTimesheet,
    isUpdatingTimesheet,
    updateSlot,
    isUpdatingSlot,
    updateProject,
    isUpdatingProject,
    isUpdatingOutsourcedSlot,
    updateOutsourcedTimesheet,
    updateOutsourcedSlot,
    isUpdatingOutsourcedTimesheet,
  };
};
