import styled from 'styled-components/native';
import { BaseView } from '../Layout';

export const Marker = styled(BaseView)<{ color: string | null }>`
  position: absolute;
  width: 4px;
  top: 0;
  bottom: 0;
  background-color: ${({ theme, color }) => color || theme.colors.lightSurface};
`;
