import React, { FC, memo } from 'react';
import { TextInput, BaseView, RipplePressable } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

interface Props {
  digit: string;
  onPress?: () => void;
}

const Container = styled(BaseView)`
  height: 60px;
  width: 45px;
  margin: 0 7px;
  flex: 1;
`;

const DigitTextInput = styled(TextInput)<{ isEmpty: boolean }>`
  font-size: 38px;
  height: 100%;
  text-align: center;
  color: ${({ isEmpty, theme }) =>
    isEmpty ? theme.colors.gray4 : theme.colors.gray1};
`;

export const CodeDigit: FC<Props> = memo(({ digit, onPress }) => (
  <Container as={RipplePressable} onPress={onPress}>
    <DigitTextInput editable={false} value={digit || '-'} isEmpty={!digit} />
  </Container>
));
