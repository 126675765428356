import AsyncStorage from '@react-native-async-storage/async-storage';

const OFFLINE_MUTATION_KEY = 'offline_mutations';

export const getOfflineMutation = async () => {
  const item = await AsyncStorage.getItem(OFFLINE_MUTATION_KEY);
  if (item) {
    return JSON.parse(item);
  }
  return [];
};

export const addOfflineMutation = async (item) => {
  const queue = await getOfflineMutation();
  AsyncStorage.setItem(
    OFFLINE_MUTATION_KEY,
    JSON.stringify([...(queue || []), item])
  );
};
export const flushOfflineMutation = async () =>
  AsyncStorage.removeItem(OFFLINE_MUTATION_KEY);
