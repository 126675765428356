import React, { FC, useContext } from 'react';
import { IAccount } from '@violetta/ubeya/entities';
import {
  AccountImage,
  BaseView,
  ChevronRight,
  FlexRowCenter,
  H5Medium,
  RipplePressable,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../../contexts';

const Container = styled(BaseView)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex: 1;
  padding: 10px 20px;
  border-top-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray11};
`;

const Details = styled(FlexRowCenter)`
  flex: 1;
`;

const Name = styled(H5Medium)`
  font-weight: 500;
  margin-left: 20px;
  flex: 1;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: normal;
`;

interface Props {
  account: IAccount;
  onPress?: () => void;
}

export const AccountCard: FC<Props> = ({
  account: { name, logo },
  onPress,
}) => {
  const { rtl } = useContext(ConfigContext);

  return (
    <Container as={RipplePressable} onPress={onPress}>
      <Details>
        <AccountImage image={logo} name={name} size={50} />
        <Name>{name}</Name>
      </Details>
      <ChevronRight size={32} rtl={rtl} />
    </Container>
  );
};
