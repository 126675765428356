import AsyncStorage from '@react-native-async-storage/async-storage';
import CookieManager from '@react-native-cookies/cookies';
import { AxiosInstance } from 'axios';

export const refreshTokenInterceptorCreator = ({
  refreshToken,
  axios,
}: {
  refreshToken: () => Promise<unknown>;
  axios: AxiosInstance;
}) => {
  let isRefreshing = false;
  let failedRequests = [] as {
    resolve: (value?: unknown) => void;
    reject: (reason?: any) => void;
  }[];

  const processQueue = ({ error }: { error: Error | null }) => {
    failedRequests.forEach((p) => {
      if (error) {
        p.reject(error);
      } else {
        p.resolve();
      }
    });

    failedRequests = [];
  };

  const onResponseSuccess = async (response: any) => {
    const cookieHeader: string = response.headers['set-cookie']?.[0];

    if (cookieHeader) {
      const url = response.config.baseURL;
      await Promise.all(
        cookieHeader
          .split(', ')
          .map(async (cookie: string) =>
            CookieManager.setFromResponse(url, cookie)
          )
      );

      const cookies = await CookieManager.get(url);
      await AsyncStorage.setItem(`cookie_${url}`, JSON.stringify(cookies));
      await Promise.all(
        Object.keys(cookies).map((key) => CookieManager.set(url, cookies[key]))
      );
    }

    return response;
  };

  const onResponseFailed = async (err) => {
    const originalReq = err.config;
    // eslint-disable-next-line no-underscore-dangle
    if (err.response?.status === 401 && !originalReq._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) =>
          failedRequests.push({ resolve, reject })
        )
          .then(() => axios(originalReq))
          .catch((err) => Promise.reject(err));
      }

      isRefreshing = true;
      // eslint-disable-next-line no-underscore-dangle
      originalReq._retry = true;

      try {
        await refreshToken();
        processQueue({ error: null });
        return axios(originalReq);
      } catch (error: any) {
        processQueue({ error });
      } finally {
        isRefreshing = false;
      }
    }

    throw err;
  };

  return { onResponseSuccess, onResponseFailed };
};
