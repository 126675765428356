import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { IModule } from '../entities';
import { useMemo } from 'react';

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const parsedModules = (data || []).map(
      ({ id, slug }) =>
        ({
          id,
          slug,
        } as IModule)
    );

    const mappedModulesById = mappedArray(parsedModules) as {
      [key: number]: IModule;
    };

    const mappedModulesBySlug = mappedArray(
      parsedModules,
      (value) => value.slug
    ) as {
      [key: number]: IModule;
    };
    return { parsedModules, mappedModulesById, mappedModulesBySlug };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useModules = () => {
  const { data: userData } = useUser();
  const storeKey = ['modules'];
  const { isPending, data } = useQuery<{ data: IModule[] }>({
    queryKey: storeKey,
    queryFn: () => api.getModules(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { parsedModules, mappedModulesById, mappedModulesBySlug } =
    useMemo(() => {
      const {
        parsedModules = [],
        mappedModulesById = {},
        mappedModulesBySlug = {},
      } = data || {};
      return {
        parsedModules,
        mappedModulesById,
        mappedModulesBySlug,
      };
    }, [data]);

  return {
    isLoading: isPending,
    modules: parsedModules,
    mappedModulesById,
    mappedModulesBySlug,
  };
};
