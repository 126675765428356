import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export const formatPhone = (phone: string, code: string) => {
  if (phone.startsWith(code)) {
    phone = phone.slice(code.length);
  }
  const phoneNumberUtil = new PhoneNumberUtil();

  const parsedPhone = phoneNumberUtil.format(phoneNumberUtil.parse(`+${code ?? ''}${phone}`), PhoneNumberFormat.E164);

  return parsedPhone.substr(1);
};

export const formatPhoneNational = (phone: string) => {
  const phoneNumberUtil = new PhoneNumberUtil();

  return phoneNumberUtil.format(phoneNumberUtil.parse(`+${phone}`), PhoneNumberFormat.NATIONAL);
};
