import { icons, lotties } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import { Trans } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Card,
  FlexCenter,
  Lottie,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { theme } from '../../../../style';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import { useWindowDimensions } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import styled from 'styled-components/native';

type Props = {
  birthdates: {
    birthdate: Date;
    accountId: number;
    birthdayMessage?: string;
  }[];
};

const TextContainer = styled(BaseView)`
  width: 100%;
  height: 100%;
  padding: 12px;
  flex: 3;
`;

const GradientBackground = styled(LinearGradient).attrs({
  colors: [
    'rgba(100, 156, 239, 0.2)',
    'rgba(100, 139, 239, 0.2)',
    'rgba(153, 100, 239, 0.2)',
    'rgba(100, 103, 239, 0.2)',
  ],
  start: { x: 0.5831, y: 0.5059 },
  end: { x: 1, y: 0.5 },
  locations: [0, 0.4866, 0.8229, 1],
})`
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;
  border-radius: 8px;
`;

const IconContainer = styled(BaseView)`
  position: absolute;
  right: -10px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1;
  wisth: 100%;
  height: 100%;
`;

const SideContainer = styled(BaseView)`
  flex: 1;
`;

const LottieContainer = styled(FlexCenter)`
  height: 100%;
  width: 100%;
  position: absolute;
`;

const Title = styled(BaseText)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const SubTitle = styled(BaseText)`
  font-size: 14px;
  font-weight: 400;
`;

const MainCard = styled(Card)`
  margin-top: 20px;
  flex: 1;
  position: relative;
`;

export const BirthdayCard: FC<Props> = React.memo(({ birthdates }) => {
  const { data } = useUser();
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const isTodayBirthday = useMemo(
    () =>
      (birthdates || []).find(
        ({ birthdate }) =>
          moment(birthdate).format('MM-DD') === moment().format('MM-DD')
      ),
    [birthdates]
  );

  const { birthdayMessage = '' } = isTodayBirthday || {};

  const birthdayMessageWithName = useMemo(
    () => birthdayMessage?.replace('**name**', data?.firstName || ''),
    [birthdayMessage, data?.firstName]
  );

  if ((birthdates || []).length === 0 || !isTodayBirthday) {
    return null;
  }

  if (!birthdayMessage) {
    return null;
  }

  return (
    <MainCard>
      <GradientBackground>
        <TextContainer>
          <Title>
            <Trans
              i18nKey="happyBirthday"
              values={{
                name: [data.firstName].filter((curr) => !!curr).join(' '),
              }}
              components={[
                <BaseText style={{ color: theme.colors.primary }} />,
              ]}
            />
          </Title>

          <SubTitle>{birthdayMessageWithName}</SubTitle>
        </TextContainer>
        <SideContainer>
          <IconContainer>
            <SvgIcon
              xml={icons?.uiIcons?.birthdayIcon}
              width="100%"
              height="90%"
              preserveAspectRatio="xMinyMin slice"
            />
          </IconContainer>
        </SideContainer>
      </GradientBackground>

      <LottieContainer>
        <Lottie
          name={lotties.birthdayLottie}
          autoPlay
          width={windowWidth}
          loop={false}
          height={windowHeight}
          style={{ marginTop: 80 }}
        />
      </LottieContainer>
    </MainCard>
  );
});
