import { LinkSchema } from '@violetta/ubeya/entities';
import {
  BaseText,
  BaseView,
  FlexRow,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import React, { FC, ReactSVG } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking, Platform } from 'react-native';
import styled from 'styled-components/native';

const AboutUsContainer = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.surface};
  margin: -10% 30px 0 30px;
  position: relative;
  padding: 12px 16px 16px;
  margin-bottom: 2px;
  min-height: 100px;
  border-radius: 8px;

  ${Platform.OS === 'ios'
    ? 'shadow-color: rgba(10, 34, 74, 0.14);'
    : 'shadow-color: rgba(10, 34, 74, 0.5);'};
  shadow-opacity: 1;
  shadow-offset: 0 2px;
  shadow-radius: 2px;
  elevation: 3;
  z-index: 1;
`;

const AboutUsDescription = styled(BaseText)`
  font-size: 14px;
  line-height: 22px;
`;

const AboutUsTitle = styled(BaseText)`
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
`;

const AboutUsLinks = styled(FlexRow)`
  margin: 10px 0;
  font-size: 24px;
`;

const AboutUsLinkContainer = styled(BaseView)``;

type LinkTypeWithIcon = LinkSchema & { icon: FC<ReactSVG> };

const AboutUsSection = ({
  links = [],
  aboutUs,
}: {
  links: LinkTypeWithIcon[];
  aboutUs: string;
}) => {
  const { t } = useTranslation();
  const aboutUsDescription = aboutUs || t('aboutUsSectionPlaceholder');

  return (
    <AboutUsContainer withLinks={links.length > 0}>
      <AboutUsTitle>{t('aboutUs')}</AboutUsTitle>
      <AboutUsDescription>{aboutUsDescription}</AboutUsDescription>

      {links.length > 0 && (
        <AboutUsLinks>
          {links.map(({ icon, value }) => (
            <AboutUsLinkContainer
              as={RipplePressable}
              onPress={() => Linking.openURL(value)}
            >
              <SvgIcon
                style={{ marginRight: 10 }}
                width={40}
                height={40}
                xml={icon}
              />
            </AboutUsLinkContainer>
          ))}
        </AboutUsLinks>
      )}
    </AboutUsContainer>
  );
};

export default AboutUsSection;
