import { IOffer, useBranches, usePositions } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Button,
  CheckBox,
  CheckBoxField,
  FlexRowBetween,
} from '@violetta/ubeya/ui';
import { flatten } from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import styled, { useTheme } from 'styled-components/native';
import { SliderFilter } from '../../../components/Slider/SliderFilter';
import { offersFilterAtom } from '../../../state';
import { OffersFilterModalHeader } from './OffersFilterModalHeader';
import { OffersFilterModalStatusFilter } from './OffersFilterModalStatusFilter';

const ModalContainer = styled(BaseView)``;

const ModalSection = styled(BaseView)`
  padding: 10px 23px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
`;

const ModalRow = styled(FlexRowBetween)`
  margin-bottom: 15px;
  align-items: center;
`;

const ModalRowText = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #1e2534;
`;

const ButtonContainer = styled(BaseView)`
  padding: 24px 16px;
`;

export type IFilters = {
  distance: number[];
  branchIds: number[];
  positionIds: number[];
  offersStatus: { string: boolean };
};

interface Props {
  offers: IOffer[];
  filters: IFilters;
  setFilters(values): any;
  isOffersModalOpen: boolean;
  setIsOffersModalOpen: SetterOrUpdater<boolean>;
}

export const OffersFilterModal: FC<Props> = ({ offers, modalizeRef }) => {
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const { t } = useTranslation();
  const { colors } = useTheme();
  const { mappedBranches } = useBranches();
  const { mappedPositions } = usePositions();
  const [filters, setFilters] = useRecoilState(offersFilterAtom);

  const allBranches = useMemo(
    () => [...new Set((offers || []).map((offer) => offer.branchId))],
    [offers]
  );
  // const allDistances = useMemo(
  //   () => [
  //     ...new Set(
  //       offers
  //         .map((offer) => mappedBranches[offer.branchId]?.timesheetConfig?.distanceFromShiftAddress)
  //         .filter((a) => a)
  //         .filter((a) => a.length > 0),
  //     ),
  //   ],
  //   [mappedBranches, offers],
  // );

  const allPositions = useMemo(
    () =>
      [
        ...new Set(
          flatten(
            (offers || []).map((offer) => [
              ...new Set(offer.shifts.map((shift) => shift.positionId)),
            ])
          )
        ),
      ].filter((a) => !!a),
    [offers]
  );

  return (
    <Portal>
      <Form
        onSubmit={(values) => {
          setFilters(values);
          modalizeRef?.current?.close();
        }}
        mutators={{
          clearValues: (args, state, utils) => {
            utils.changeValue(state, args[0].sectionName, () => []);
          },
          clearDistance: (args, state, utils) => {
            utils.changeValue(state, 'distance', () => null);
          },
        }}
        keepDirtyOnReinitialize
        initialValues={{
          ...filters,
          distance: [110],
        }}
        render={({ handleSubmit, form }) => (
          <Modalize
            ref={modalizeRef}
            panGestureEnabled={scrollEnabled}
            scrollViewProps={{ scrollEnabled }}
            withHandle={false}
            HeaderComponent={<OffersFilterModalHeader />}
            FooterComponent={
              <ButtonContainer>
                <Button
                  title={t('done')}
                  type="primary"
                  onPress={handleSubmit}
                />
              </ButtonContainer>
            }
          >
            <ModalContainer>
              <ModalSection>
                <Field
                  name="distance"
                  render={({ input }) => (
                    <SliderFilter
                      setScrollEnabled={setScrollEnabled}
                      onClear={() => form.mutators.clearDistance()}
                      onChange={input.onChange}
                      value={input.value}
                      t={t}
                    />
                  )}
                />
              </ModalSection>
              <ModalSection>
                <ModalRow>
                  <ModalRowText>{t('offersStatus')}</ModalRowText>
                </ModalRow>

                <OffersFilterModalStatusFilter />
              </ModalSection>
              <ModalSection>
                <ModalRow>
                  <ModalRowText>{t('position')}</ModalRowText>
                  <Button
                    title={t('clear')}
                    onPress={() =>
                      form.mutators.clearValues({ sectionName: 'positionIds' })
                    }
                    type="primary"
                    fontSize={14}
                    radius={18}
                    paddingVertical={6}
                    style={{
                      backgroundColor: colors.primaryLight4,
                      borderColor: colors.primaryLight4,
                    }}
                    textStyle={{ color: colors.textPrimary }}
                  />
                </ModalRow>
                {(allPositions || []).map((positionId, i) => (
                  <CheckBoxField
                    key={positionId}
                    props={{
                      title: t(mappedPositions?.[positionId]?.slug),
                      color: colors.surface,
                      style: { marginBottom: 5 },
                    }}
                    fieldProps={{
                      name: `positionIds[${i}]`,
                      parse: (value, name) => (value ? positionId : false),
                    }}
                    component={CheckBox}
                  />
                ))}
              </ModalSection>
            </ModalContainer>
            <ModalSection>
              <ModalRow>
                <ModalRowText>{t('employer')}</ModalRowText>
                <Button
                  title={t('clear')}
                  onPress={() =>
                    form.mutators.clearValues({ sectionName: 'branchIds' })
                  }
                  type="primary"
                  fontSize={14}
                  radius={18}
                  paddingVertical={6}
                  style={{
                    backgroundColor: colors.primaryLight4,
                    borderColor: colors.primaryLight4,
                  }}
                  textStyle={{ color: colors.textPrimary }}
                />
              </ModalRow>
              {(allBranches || []).map((branchId, i) => (
                <CheckBoxField
                  key={branchId}
                  props={{
                    image: mappedBranches[branchId]?.logo,
                    title: mappedBranches[branchId]?.name,
                    color: colors.surface,
                    style: { marginBottom: 5 },
                  }}
                  fieldProps={{
                    name: `branchIds[${i}]`,
                    parse: (value, name) => (value ? branchId : false),
                  }}
                  component={CheckBox}
                />
              ))}
            </ModalSection>
          </Modalize>
        )}
      />
    </Portal>
  );
};
