import { useMemo, useState } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { createAdminStoreKey, mappedArray } from '@violetta/ubeya/utils';
import { groupBy } from 'lodash';
import moment, { Moment } from 'moment';
import createCachedSelector from 're-reselect';
import { useQuery, useInfiniteQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import * as sharedAtoms from '../../../shared/src/atoms';

const API_DATE_FORMAT = 'YYYY-MM-DD';
const getDate = (date) => moment(date).format(API_DATE_FORMAT);

const selector = createCachedSelector(
  (pages) => pages,
  (pages, sorting) => sorting,
  (pages, sorting) => {
    const projects = (pages || [])
      .reduce((acc, page) => {
        acc.push(
          ...(page?.data.projects.map((project) => ({
            ...project,
          })) || [])
        );
        return acc;
      }, [])
      .sort((a, b) =>
        new Date(a.date).getTime() - new Date(b.date).getTime() > 0
          ? sorting === 'asc'
            ? 1
            : -1
          : sorting === 'asc'
          ? -1
          : 1
      );

    const projectById = mappedArray(projects, ({ id }) => id);

    const projectsByDate = (() => {
      const dates = groupBy(projects, 'date');

      const map = {};
      Object.entries(dates).forEach(
        ([date, projects]) => (map[getDate(date)] = projects)
      );
      return map;
    })();

    return { projects, projectById, projectsByDate };
  }
)({
  keySelector: (data, sorting, storeKey) => storeKey,
});

export const useProjects = ({
  branchIds,
  from,
  to,
  sorting = 'asc',
}: {
  branchIds: number[] | null;
  from?: moment.Moment;
  to?: moment.Moment;
  sorting: 'asc' | 'desc';
}) => {
  const selectedAccount = useRecoilValue(sharedAtoms.selectedAccount);

  const accountId = selectedAccount?.id;
  const storeKey = useMemo(
    () => createAdminStoreKey(['projects', accountId, branchIds, from, to]),
    [accountId, branchIds, from, to]
  );

  const {
    isLoading,
    isIdle,
    data,
    fetchNextPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: storeKey,
    queryFn: async ({ pageParam = 0 }) => {
      const result = await adminApi.getProjects({
        accountId,
        branchIds,
        from: from && from.format(API_DATE_FORMAT),
        to: to && to.format(API_DATE_FORMAT),
        page: pageParam,
        statsForEntireProject: true,
      });
      return { ...result, page: pageParam + 1 };
    },
    enabled:
      !!accountId && !!branchIds && branchIds?.length > 0 && !!(from || to),
    getNextPageParam: (lastGroup, allGroups) => {
      if (
        !lastGroup ||
        !lastGroup?.data ||
        lastGroup.data.projects?.length === 0
      ) {
        return undefined;
      }
      return allGroups.length;
    },
  });

  const {
    projects = [],
    projectById = {},
    projectsByDate = {},
  } = useMemo(
    () => selector(data?.pages, sorting, storeKey.join('#')),
    [data?.pages, sorting, storeKey]
  );

  return {
    isLoading,
    projects,
    projectById,
    projectsByDate,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  };
};
