import React, { FC, useMemo, useContext, useEffect } from 'react';

import { useNavigation } from '@react-navigation/native';
import {
  useAccounts,
  useBranches,
  useRequestsTypes,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  BranchSelect,
  Button,
  DatePickerTogglerField,
  DateTogglerPicker,
  FlexRowCenter,
  FormGroup,
  InputField,
  SelectField,
  TextInput,
  ValueProps,
} from '@violetta/ubeya/ui';
import { required } from '@violetta/ubeya/utils';
import { Form } from 'react-final-form';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import { RequestsScreenNames } from '../navigation/ScreenNames';
import { KindsSelect } from './KindsSelect';

const ButtonContainer = styled(BaseView)`
  margin: 20px 30px 20px;
`;
const Divider = styled(BaseView)`
  margin: 5px 0;
`;

const FormContainer = styled(BaseView)``;

const FieldsContainer = styled(BaseView)``;

const FormCard = styled(BaseView)`
  padding: 10px 20px 30px;
  margin: 30px 10px 10px;
`;

export interface FormValues {
  branchId: [number];
  date: ValueProps;
  jobTypeId: [number];
  employeeReason: string;
}

interface Props {
  onSubmit?: (values: FormValues) => void;
  dateProp?: Date;
  isLoading?: boolean;
  branchId?: number;
}

export const AddRequestForm: FC<Props> = React.memo(
  ({ onSubmit = () => {}, dateProp, isLoading, branchId }) => {
    const { rtl } = useContext(ConfigContext);

    const { navigate } = useNavigation();
    const { t } = useTranslation();
    const { mappedRequestsTypesByType } = useRequestsTypes();
    const { branches } = useBranches();
    const { isTimeAvailability } = useAccounts();

    const options = useMemo(
      () =>
        (mappedRequestsTypesByType?.absence?.kinds || [])
          .filter(
            ({ id }) => (isTimeAvailability && id === 4) || !isTimeAvailability
          )
          .map(({ id, slug }) => ({
            id,
            slug: t(slug),
          })),
      [isTimeAvailability, mappedRequestsTypesByType?.absence?.kinds, t]
    );

    const initialValues = useMemo(
      () => ({
        branchId: branchId
          ? [branchId]
          : branches.length === 1
          ? branches.map(({ id }) => id)
          : {},
        date: dateProp
          ? { value: { end: dateProp, start: dateProp }, isRange: false }
          : undefined,
        jobTypeId: [4],
      }),
      [branches, dateProp, branchId]
    );

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, valid }) => (
          <FormContainer>
            <FormCard>
              <FieldsContainer>
                <FormGroup title={t('requestType')}>
                  <SelectField
                    fieldProps={{
                      name: 'jobTypeId',
                      validate: required,
                    }}
                    props={{
                      options,
                      isMulti: false,
                      rtl,
                      placeholder: t('jobTypePlaceholder'),
                    }}
                    selectComponent={KindsSelect}
                  />
                </FormGroup>
                <FormGroup title={t('branch')} isHidden={branches.length === 1}>
                  <SelectField
                    fieldProps={{
                      name: 'branchId',
                      validate: required,
                    }}
                    props={{
                      options: branches,
                      isMulti: false,
                    }}
                    selectComponent={BranchSelect}
                  />
                </FormGroup>
                <FormGroup title={t('when')}>
                  <DatePickerTogglerField
                    props={{
                      rtl,
                      value: {
                        isRange: false,
                      },
                    }}
                    fieldProps={{ name: 'date' }}
                    component={DateTogglerPicker}
                  />
                </FormGroup>
                <FormGroup title={`${t('comment')} (*)`}>
                  <InputField
                    props={{
                      multiline: true,
                      numberOfLines: 2,
                    }}
                    fieldProps={{
                      name: 'employeeReason',
                    }}
                    component={TextInput}
                  />
                </FormGroup>
              </FieldsContainer>
            </FormCard>
            <ButtonContainer>
              <Button
                disabled={isLoading || submitting || !valid}
                isLoading={isLoading}
                title={t('sendForApproval')}
                onPress={handleSubmit}
                type="primary"
              />
              <Divider />
              <Button
                disabled={isLoading || submitting}
                title={t('cancel')}
                onPress={() => navigate(RequestsScreenNames.MY_REQUESTS)}
                type="primaryReversed"
              />
            </ButtonContainer>
          </FormContainer>
        )}
      />
    );
  }
);
