import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import {
  CustomDTO,
  mappedArray,
  PickPrimitiveKeys,
} from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { IShift, IUser } from '../entities';

type Booking = CustomDTO<IShift, PickPrimitiveKeys<IShift>> & {
  employees: number[];
};

export type Employee = CustomDTO<
  IUser,
  'id' | 'firstName' | 'lastName' | 'image' | 'phone'
> & { shiftId: number };

type ResponseType = {
  shifts: Booking[];
  employees: Employee[];
  project?: {
    id: number;
    labelId?: number;
  };
};

const selector = createCachedSelector(
  (data) => data,
  (data) => {
    const team = data || { shifts: [], employees: [] };
    const mappedEmployees = mappedArray(team.employees) as {
      [key: number]: Employee;
    };

    return { team, mappedEmployees };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useTeam = ({
  projectId,
  date,
}: {
  projectId: number;
  date: Date;
}) => {
  const { data: userData } = useUser();

  const storeKey = useMemo(() => ['team', String(projectId)], [projectId]);
  const { isPending, data, refetch } = useQuery<ResponseType>({
    queryKey: storeKey,
    queryFn: () => api.getTeam({ projectId, date }),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { team = [], mappedEmployees = {} } = data || {};

  return {
    isLoading: isPending,
    team,
    mappedEmployees,
    refetch,
  };
};
