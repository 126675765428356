import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { to } from '@violetta/ubeya/await';
import { FieldValues, useEditEmployeeAccount } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, Button, UbeyaForm } from '@violetta/ubeya/ui';
import { uiAlert } from '@violetta/ubeya/utils';
import moment from 'moment';
import { ScrollView } from 'react-native';
import Toast from 'react-native-toast-message';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts';

const Container = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  justify-content: space-between;
  padding: 0 20px 33px 20px;
  flex: 1;
`;

interface Props {}

type ParamList = {
  params: {
    field: any;
    refetch: any;
  };
};

export const EditEmployeeFieldScreen: FC<Props> = () => {
  const {
    params: { field, refetch },
  } = useRoute<RouteProp<ParamList, 'params'>>();

  // each employee can have different compliances from different accounts
  const { accountId, hasExpiration } = field || {};
  const [isScrollEnabled, setIsScrollEnabled] = useState(true);

  const { t } = useTranslation();
  const { goBack } = useNavigation();

  const { editEmployeeDetails, isEditingEmployeeDetails } =
    useEditEmployeeAccount(accountId, false);

  const [fieldValues, setFieldValues] = useState<{
    values: FieldValues;
    dirty: boolean;
    initialValues: FieldValues;
  }>({});
  const { rtl } = useContext(ConfigContext);

  const form = useMemo(
    () => ({
      id: 1,
      fields: [
        {
          ...field,
          value: field.value.filter(({ link }) => link),
          hideTitle: true,
          complianceField: true,
        },
      ],
    }),
    [field]
  );

  const showToast = useCallback(({ text1, text2, type, onHide }) => {
    Toast.show({
      type,
      text1,
      text2,
      onHide,
    });
  }, []);

  const onChange = useCallback(
    ({
      dirty,
      initialValues,
      dirtyFields,
    }: {
      values: FieldValues;
      dirty: boolean;
      initialValues: FieldValues;
      dirtyFields: FieldValues;
    }): void => {
      setFieldValues({ dirty, initialValues, values: dirtyFields });
    },

    []
  );

  const onSubmit = useCallback(
    async (values, form) => {
      const formattedValues = Object.entries(fieldValues?.values)
        .map((value) => {
          const formFieldId = value?.[0]?.split('-')?.[1];
          const formFieldValue = value?.[1];
          return { id: formFieldId, value: formFieldValue };
        })
        .filter((value) => !!value?.value);

      const documentAttached =
        formattedValues?.length > 0 &&
        formattedValues?.[0]?.value &&
        formattedValues[0].value[0]?.link;

      const documentAttachedExpirationDate =
        formattedValues?.length > 0 &&
        formattedValues?.[0]?.value &&
        formattedValues[0].value[0]?.expirationDate;

      const isFilled =
        documentAttached && hasExpiration
          ? documentAttachedExpirationDate &&
            moment().isBefore(moment(documentAttachedExpirationDate))
          : true;

      if (!isFilled) {
        await to(
          uiAlert({ title: t('documentExpired'), save: { text: t('ok') } })
        );

        return;
      }

      const [error, resp] = await to(editEmployeeDetails(formattedValues));

      if (resp?.data) {
        goBack();
        showToast({
          text1: t('changedProfileDataSuccessfully'),
          type: 'success',
        });
        refetch?.();
      } else if (error) {
        showToast({ text1: t('unknown'), type: 'error' });
      }
    },
    [
      editEmployeeDetails,
      fieldValues?.values,
      goBack,
      hasExpiration,
      refetch,
      showToast,
      t,
    ]
  );

  return (
    <Container>
      <ScrollView
        style={{ flex: 1 }}
        scrollEnabled={isScrollEnabled}
        contentContainerStyle={{ paddingBottom: 70 }}
      >
        <UbeyaForm
          setIsScrollEnabled={(value) => setIsScrollEnabled(value)}
          form={form!}
          onSubmit={onSubmit}
          onChange={onChange}
          rtl={rtl}
        />
      </ScrollView>

      <Button
        title={t('saveChanges')}
        type="primary"
        style={{ marginTop: 30 }}
        onPress={onSubmit}
        isLoading={isEditingEmployeeDetails}
        disabled={isEditingEmployeeDetails || !fieldValues?.dirty}
      />
    </Container>
  );
};

export default EditEmployeeFieldScreen;
