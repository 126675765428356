import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useContext,
  useEffect,
} from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import {
  useAccounts,
  useAlert,
  useBranches,
  useRequestsTypes,
  useTimeAvailabilities,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  CustomLoaderWrapped,
  FlatList,
  FlexColumn,
  FlexRowBetween,
  ChevronRight,
  H5Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { generateRange } from '@violetta/ubeya/utils';
import _ from 'lodash';
import moment from 'moment';
import { SafeAreaView } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import { timeAvailabilitySelectedWeek } from '../../../state';
import { RequestsScreenNames } from '../../requests/navigation';
import { AddTimeAvailabilityModal } from '../components';
import { CopyTimeAvailabilityModal } from '../components/CopyTimeAvailabilityModal';
import { TimeAvailabilityDay } from './components/TimeAvailabilityDay';
import { TimeAvailabilityHeader } from './components/TimeAvailabilityHeader';
import { TimeAvailabilityModalHeader } from './components/TimeAvailabilityModalHeader';
import { AppContext } from '../../main/AppContext';
import { Portal } from 'react-native-portalize';

const Text = styled(H5Regular)`
  color: ${({ theme }) => theme.colors.gray2};
  margin-left: 32px;
`;

const Container = styled(SafeAreaView)`
  flex: 1;
`;

const Content = styled(FlexColumn)`
  background: ${({ theme }) => theme.colors.lightSurface};
`;

const Footer = styled(FlexRowBetween)`
  margin-top: 10px;
  padding-right: 23px;
  padding-bottom: 20px;
`;

const TextView = styled(BaseView)`
  display: flex;
  align-items: center;
  text-align: center;
`;

const ModalContainer = styled(BaseView)`
  padding: 23px;
`;

const ModalRow = styled(FlexRowBetween)`
  margin-bottom: 33px;
`;

const ModalRowText = styled(BaseText)<{ color: string }>`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: ${({ color }) => color || '#1e2534'};
`;

const Oval = styled<{ isSelected: boolean }>(BaseView)`
  width: 16px;
  height: 16px;
  border-width: ${({ isSelected }) => (isSelected ? 5 : 1)}px;
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.colors.gray16};
  border-radius: 32px;
`;

type ParamList = {
  params: {
    accountId: number;
  };
};

export const TimeAvailabilityScreen = () => {
  const { t } = useTranslation();
  const { navigate, setParams } = useNavigation();
  const { rtl } = useContext(ConfigContext);
  const { createAlert: createAlertDelete } = useAlert();
  const { branches, mappedBranches } = useBranches();
  const { mappedAccounts } = useAccounts();
  const {
    profile: { selectedAccount, selectAccount },
  } = useContext(AppContext);

  const { params } = useRoute<RouteProp<ParamList, 'params'>>();
  const [availabilityModalType, setAvailabilityModalType] = useState<0 | 1>(0);

  useEffect(() => {
    if (params?.accountId) {
      selectAccount(mappedAccounts?.[params?.accountId]);
      setParams({ accountId: null });
    }
  }, [params?.accountId, mappedAccounts, selectAccount, setParams]);

  const [showModal, setShowModal] = useState<{
    isOpen: boolean;
    status: 0 | 1;
  }>({ isOpen: false, status: 0 });
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState<{
    date: Date;
    periods: any[];
  }>({ date: new Date(), periods: [] });
  const [selectedWeek, setSelectedWeek] = useRecoilState(
    timeAvailabilitySelectedWeek
  );
  const modalizeRef = useRef<Modalize>(null);
  const availabilityModalizeRef = useRef<Modalize>(null);

  const branchId = branches?.find(
    ({ accountId: branchAccountId }) =>
      branchAccountId === (params?.accountId || selectedAccount?.id)
  )?.id;
  const config = branchId && mappedBranches[branchId]?.timesheetConfig;
  const {
    isWeeklyPreferencesSubmissionsActive,
    untillDayNotificationHour,
    untillDayNotificationDay,
  } = config || {};

  const isSendingEnabled = useMemo(() => {
    if (!isWeeklyPreferencesSubmissionsActive) {
      return true;
    }
    if (!untillDayNotificationDay || !untillDayNotificationHour) {
      return true;
    }
    const weeksDiff = moment(selectedWeek.date).diff(
      moment().startOf('week'),
      'week'
    );
    if (weeksDiff < 1) {
      // disable changind past weeks
      return false;
    }
    if (weeksDiff > 1) {
      // enable changing future weeks
      return true;
    }
    const today = (moment().isoWeekday() + 1) % 7;
    if (today < untillDayNotificationDay) {
      return true;
    }
    if (today === untillDayNotificationDay) {
      const endHours = moment(untillDayNotificationHour, 'HH:mm:ss').hours();
      const todayHours = moment().hours();
      if (todayHours < endHours) {
        return true;
      }
      if (todayHours === endHours) {
        return (
          moment().minutes() <=
          moment(untillDayNotificationHour, 'HH:mm:ss').minutes()
        );
      }
      return false;
    }
    return false;
  }, [
    isWeeklyPreferencesSubmissionsActive,
    selectedWeek.date,
    untillDayNotificationDay,
    untillDayNotificationHour,
  ]);

  useEffect(
    () => setSelectedWeek((value) => ({ ...value, enabled: isSendingEnabled })),
    [
      isSendingEnabled,
      setSelectedWeek,
      selectedWeek.date /* needed to update isSending every time the date changed */,
    ]
  );

  const [weekStart, weekEnd] = useMemo(
    () =>
      [
        moment(selectedWeek.date).toDate(),
        moment(selectedWeek.date).add(6, 'day').toDate(),
      ] as const,
    [selectedWeek]
  );

  const onOpen = ({ item, type }) => {
    setSelectedDay(item);
    setAvailabilityModalType(type);
    modalizeRef.current?.open();
  };

  const onClose = () => {
    modalizeRef.current?.close();
  };

  const openAvailabilityModal = ({ item, type }) => {
    setSelectedDay(item);
    setAvailabilityModalType(type);
    availabilityModalizeRef.current?.open();
  };

  const closeAvailabilityModal = () => {
    setSelectedDay(null);
    setAvailabilityModalType(0);
    availabilityModalizeRef.current?.close();
  };

  const {
    isLoading,
    timeAvailabilities,
    deleteTimeAvailability,
    addTimeAvailabilities,
    refetch,
    timeOffs,
  } = useTimeAvailabilities({
    accountId: params?.accountId || selectedAccount?.id,
    from: weekStart,
    to: weekEnd,
  });

  const weekDates = useMemo(
    () => generateRange(weekStart, weekEnd),
    [weekEnd, weekStart]
  );

  const totalHours = useMemo(
    () =>
      timeAvailabilities.reduce(
        (acc, curr) =>
          acc +
          moment
            .duration(moment(curr.endTime).diff(moment(curr.startTime)))
            .asHours(),
        0
      ),
    [timeAvailabilities]
  );

  const listData = useMemo(
    () =>
      weekDates.map((date) => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        return {
          date,
          periods: [
            ...timeAvailabilities
              .filter(
                ({ startTime }) =>
                  startTime?.toString().split(' ')?.[0] ===
                  formattedDate.split(' ')?.[0]
              )
              .map((avail) => ({ ...avail, type: 'availability' })),
            ...timeOffs
              .filter(
                ({ startTime, requestId }) =>
                  startTime?.toString().split(' ')?.[0] ===
                    formattedDate.split(' ')?.[0] && !!requestId
              )
              .map((avail) => ({ ...avail, type: 'timeOff' })),
          ],
        };
      }),
    [timeAvailabilities, timeOffs, weekDates]
  );

  const onSave = useCallback(
    ({ dates, endTime, startTime, status, comment }) => {
      addTimeAvailabilities({
        timeAvailabilities: dates.map((date) => ({
          startTime: moment(
            `${moment(date).format('YYYY-MM-DD')} ${moment(startTime).format(
              'HH:mm:ss'
            )}`
          ).toDate(),
          endTime: moment(
            `${moment(date).format('YYYY-MM-DD')} ${moment(endTime).format(
              'HH:mm:ss'
            )}`
          ).toDate(),
          status,
          comment: comment || undefined,
        })),
      });
      availabilityModalizeRef.current?.close();
    },
    [addTimeAvailabilities]
  );

  const onCopy = useCallback(
    async (weeks: Number[]) => {
      const addedWeeks = _.flatten(
        weeks.map((week: Number) =>
          timeAvailabilities.map((availability) => ({
            startTime: moment(availability.startTime)
              .add(7 * week, 'day')
              .toDate(),
            endTime: moment(availability.endTime)
              .add(7 * week, 'day')
              .toDate(),
          }))
        )
      );

      await addTimeAvailabilities({
        timeAvailabilities: addedWeeks,
      });
      setShowCopyModal(false);
      refetch();
      return true;
    },
    [addTimeAvailabilities, refetch, timeAvailabilities]
  );

  const onDeletePeriod = useCallback(
    (id, startTime, endTime) =>
      createAlertDelete({
        title: t('timeAvailabilitiesDeletePrompt', {
          startTime: moment(startTime).format('hh:mm A'),
          endTime: moment(endTime).format('hh:mm A'),
        }),
        options: [
          {
            text: t('cancel'),
            style: 'cancel',
          },
          { text: t('ok'), onPress: () => deleteTimeAvailability({ id }) },
        ],
      }),
    [createAlertDelete, deleteTimeAvailability, t]
  );

  return (
    <Container>
      <Content>
        <AddTimeAvailabilityModal
          availabilityModalizeRef={availabilityModalizeRef}
          branchId={branchId}
          onClose={closeAvailabilityModal}
          status={availabilityModalType}
          onSave={onSave}
          date={selectedDay?.date}
          weekStart={weekStart}
          weekEnd={weekEnd}
        />
        {showCopyModal && (
          <CopyTimeAvailabilityModal
            onClose={() => {
              setShowCopyModal(false);
            }}
            onCopy={onCopy}
            selectedWeek={selectedWeek.date}
          />
        )}

        <TimeAvailabilityHeader />
        {isLoading && (timeAvailabilities || []).length === 0 ? (
          <CustomLoaderWrapped />
        ) : (
          <FlatList
            contentContainerStyle={{ flexGrow: 1 }}
            ListFooterComponent={
              <Footer>
                <TextView>
                  {/* <Text>{t('timeAvailabilityWeekSummarize', { hours: totalHours.toFixed(1) })}</Text> */}
                </TextView>
                {/* <Button
                  title={t('copy') }
                  type="primary"
                  fontSize={16}
                  onPress={() => setShowCopyModal(true)}
                  paddingHorizontal={20}
                  paddingVertical={10}
                  radius={30}
                  isLoading={isLoading}
                  disabled={timeAvailabilities?.length === 0}
                /> */}
              </Footer>
            }
            data={listData}
            refreshing={isLoading}
            keyExtractor={(props) => moment(props.date).format('DD-MM-YYYY')}
            renderItem={({ item }) => (
              <TimeAvailabilityDay
                item={item}
                openOptionsModal={onOpen}
                onDeletePeriod={onDeletePeriod}
                isSendingEnabled={isSendingEnabled}
                openAvailabilityModal={openAvailabilityModal}
              />
            )}
            onRefresh={refetch}
          />
        )}
      </Content>
      <Portal>
        <Modalize
          ref={modalizeRef}
          adjustToContentHeight
          withHandle={false}
          HeaderComponent={<TimeAvailabilityModalHeader onClose={onClose} />}
        >
          <ModalContainer>
            <ModalRow
              as={RipplePressable}
              onPress={() => {
                modalizeRef.current?.close();
                availabilityModalizeRef.current?.open();
              }}
            >
              <ModalRowText color="red">{t('preferNotWork')}</ModalRowText>
              <ChevronRight size={24} rtl={rtl} />
            </ModalRow>
            <ModalRow
              as={RipplePressable}
              onPress={() => {
                onClose();
                navigate(RequestsScreenNames.MAIN, {
                  screen: RequestsScreenNames.ADD_REQUEST,
                  params: {
                    date: selectedDay?.date,
                    branchId,
                    onSucess: refetch,
                  },
                });
              }}
            >
              <ModalRowText>{t('dayOff')}</ModalRowText>
              <ChevronRight size={24} rtl={rtl} />
            </ModalRow>
          </ModalContainer>
        </Modalize>
      </Portal>
    </Container>
  );
};
