import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { IDirectoryContact } from '../entities';

const selector = createCachedSelector(
  (data) => data?.data,
  (data: IDirectoryContact[]) => {
    const parsedContacts = data || [];

    return { parsedContacts };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useDirectory = (branchId: number) => {
  const storeKey = useMemo(() => ['directory', String(branchId)], [branchId]);

  const { data: userData } = useUser();

  const { isPending, data, refetch } = useQuery<{ data: IDirectoryContact[] }>({
    queryKey: storeKey,
    queryFn: () => api.getDirectory(branchId),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { parsedContacts } = data || {};

  return {
    directory: parsedContacts,
    refetch,
    isLoading: isPending,
  };
};
