import { useNavigation } from '@react-navigation/native';
import { useBranches, useLocations } from '@violetta/ubeya/entities';
import { AccountProfileCard } from '../../components';
import { ProfileScreenNames } from '../../navigation';

const ContactsSection = ({ branches = [] }) => {
  const { mappedBranches } = useBranches();
  const { navigate } = useNavigation();
  const { mappedLocations } = useLocations();

  return (
    <>
      {branches
        .filter(({ branchId }) => !!mappedBranches?.[branchId])
        .map((branchInfo) => (
          <AccountProfileCard
            key={branchInfo.branchId}
            onPress={() => {
              return navigate(ProfileScreenNames.BRANCH_CONTACT, {
                branchInfo: {
                  ...branchInfo,
                  location:
                    mappedLocations?.[
                      mappedBranches[branchInfo.branchId].locationId
                    ],
                },
              });
            }}
            title={mappedBranches[branchInfo.branchId].name}
            hideIcon
          />
        ))}
    </>
  );
};

export default ContactsSection;
