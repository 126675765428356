import React, { FC, useMemo } from 'react';
import { IEmployee, IPost } from '@violetta/ubeya/entities';
import { FlexColumnDirection } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { MyComment } from './MyComment';
import { PostComment } from './PostComment';

const Container = styled(FlexColumnDirection)`
  padding: 20px 20px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

interface Props {
  post: IPost;
  employees: Pick<IEmployee, 'id' | 'firstName' | 'lastName' | 'image'>[];
}

export const PostComments: FC<Props> = ({ post: { comments }, employees }) => {
  const mappedEmployees = useMemo(
    () =>
      employees.reduce(
        (carry, item) => ({ ...carry, [item.id]: item }),
        {} as { [key: number]: IEmployee }
      ),
    [employees]
  );

  return (
    <Container>
      <MyComment />
      {comments.map((comment, idx) => (
        <PostComment
          key={idx}
          comment={comment}
          employee={mappedEmployees[comment.employeeId]}
        />
      ))}
    </Container>
  );
};
