import React, { FC, useCallback, useMemo, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { images } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import { to } from '@violetta/ubeya/await';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  CustomLoader,
  FlexCenter,
  FlexColumnDirection,
  FlexRowCenter,
  H5Regular,
  SvgIcon,
  TextInput as BaseTextInput,
} from '@violetta/ubeya/ui';
import { pickImg } from '@violetta/ubeya/utils';
import { omitBy, isUndefined } from 'lodash';
import { Pressable, Image, ScrollView } from 'react-native';
import Toast from 'react-native-toast-message';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const Container = styled(FlexColumnDirection)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  flex: 1;
  padding: 30px 30px;
`;

const ImagePressable = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 30px 0;
`;

const ImageContainer = styled(FlexRowCenter)`
  justify-content: center;
  border-radius: 50px;
  border-color: ${({ theme }) => theme.colors.surface};
  border-width: 3px;
  shadow-color: rgba(0, 0, 0, 0.05);
  shadow-opacity: 0.3;
  shadow-offset: 0 2px;
  shadow-radius: 20px;
  elevation: 10;
  height: 150px;
  width: 150px;
`;

const LargeProfileImage = styled(Image)`
  height: 150px;
  width: 150px;
  border-radius: 100px;
`;

const TextInput = styled(BaseTextInput)`
  margin-bottom: 10px;
  height: 40px;
  margin-left: 0;
`;

const LoaderContainer = styled(FlexCenter)`
  height: 62px;
  width: 62px;
  justify-content: center;
  align-items: center;
`;

const Title = styled(H5Regular)`
  margin: 10px 0;
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme, error }) =>
    error ? theme.colors.error : theme.colors.textPrimary};
`;

interface Props {}

export const EditUserScreen: FC<Props> = () => {
  const { editUserName, data, uploadPhoto, isLoadingPhoto, isLoadingUser } =
    useUser();
  const [uploadedImage, setUploadedImage] = useState<{
    base64: string;
    type: string;
    uri: string;
  } | null>(null);

  const { image: userImage } = data || {};
  const queryClient = useQueryClient();
  const image = useMemo(
    () => uploadedImage?.uri || userImage,
    [uploadedImage, userImage]
  );

  const { t } = useTranslation();
  const { goBack } = useNavigation();

  const [firstName, setFirstName] = useState(data.firstName);
  const [lastName, setLastName] = useState(data.lastName);

  const showToast = useCallback(({ text1, text2, type, onHide }) => {
    Toast.show({
      type,
      text1,
      text2,
      onHide,
    });
  }, []);

  const onSubmit = useCallback(async () => {
    const dirtyNameFields = omitBy(
      {
        firstName: firstName !== data.firstName ? firstName : undefined,
        lastName: lastName !== data.lastName ? lastName : undefined,
      },
      isUndefined
    );

    const isNameDirty = Object.keys(dirtyNameFields).length !== 0;

    const promises = [
      isNameDirty ? editUserName(dirtyNameFields) : undefined,
      uploadedImage
        ? uploadPhoto({
            base64: uploadedImage?.base64,
            type: uploadedImage?.type,
          })
        : undefined,
    ].filter((func) => !!func);

    if (promises?.length === 0) {
      return goBack();
    }

    const [err, respData] = await to(Promise.all(promises));

    if (respData) {
      queryClient.invalidateQueries({ queryKey: ['user'], exact: true });
      goBack();
      showToast({
        text1: t('changedProfileDataSuccessfully'),
        type: 'success',
      });
    } else if (err) {
      showToast({ text1: t('unknown'), type: 'error' });
    }
  }, [
    firstName,
    data.firstName,
    data.lastName,
    lastName,
    editUserName,
    uploadedImage,
    uploadPhoto,
    goBack,
    queryClient,
    showToast,
    t,
  ]);

  const photoSelected = useCallback(async () => {
    const assets = await pickImg({
      texts: {
        cancel: t('cancel'),
        choosePhoto: t('choosePhoto'),
        takeAPhoto: t('takePhoto'),
        title: t('chooseImageUploadMethod'),
      },
      image: {
        includeBase64: true,
        mediaType: 'photo',
        maxWidth: 1000,
        maxHeight: 1000,
        quality: 0.7,
      },
    });

    if (!assets) {
      return;
    }
    const { base64, type, uri } = assets?.[0] || {};

    if (!base64 || !type || !uri) {
      return;
    }

    setUploadedImage({ base64, type, uri });
  }, [t]);

  return (
    <Container>
      <KeyboardAwareScrollView
        contentContainerStyle={{ flexGrow: 1, paddingBottom: 70 }}
      >
        <ImagePressable as={Pressable} onPress={photoSelected}>
          <>
            {isLoadingPhoto && (
              <LoaderContainer>
                <CustomLoader size={100} />
              </LoaderContainer>
            )}
            {!isLoadingPhoto && !image && (
              <ImageContainer>
                <SvgIcon
                  xml={images.svgs.emptyProfileImage}
                  height={94}
                  width={94}
                />
              </ImageContainer>
            )}
            {!isLoadingPhoto && image && (
              <LargeProfileImage source={{ uri: image }} />
            )}
          </>
        </ImagePressable>

        <Title>{t('firstName')}</Title>
        <TextInput
          editable
          autoCapitalize="none"
          style={{ fontSize: 12 }}
          value={firstName}
          onChangeText={setFirstName}
        />

        <Title>{t('lastName')}</Title>
        <TextInput
          editable
          autoCapitalize="none"
          style={{ fontSize: 12 }}
          value={lastName}
          onChangeText={setLastName}
        />
      </KeyboardAwareScrollView>

      <Button
        title={t('saveChanges')}
        type="primary"
        style={{ marginTop: 30 }}
        onPress={onSubmit}
        isLoading={isLoadingUser || isLoadingPhoto}
      />
    </Container>
  );
};
