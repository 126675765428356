import React from 'react-native';
import './UserAgent';
import io from 'socket.io-client';
import { api } from '@violetta/ubeya/api';

const ENDPOINT = api.chatWSURL;

let userSocket;

export const removeUserSocket = () => {
  userSocket = null;
};

export const getUserSocket = () => {
  if (!userSocket) {
    userSocket = io(ENDPOINT, { withCredentials: true, reconnection: true });
  }

  return userSocket;
};

let adminSocket;

export const getAdminSocket = () => {
  if (!adminSocket) {
    adminSocket = io(ENDPOINT, { withCredentials: true, reconnection: true });
  }

  return adminSocket;
};
