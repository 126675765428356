import React, { FC, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import { useModules } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FlexColumnCenter,
  FlexColumnDirection,
  H7Regular,
  Link,
  RipplePressable,
  SvgIcon,
  TabNames,
  UserImage,
} from '@violetta/ubeya/ui';
import { Linking, Platform, Pressable, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { BookingsScreenNames } from '../../../bookings';
import { DirectoryScreenNames } from '../../../directory/navigation/ScreenNames';
import { HomeScreenNames } from '../../../home/navigation/ScreenNames';
import {
  ANDROID_RATEAPP_URL,
  ANDROID_TOS_URL,
  APP_VERSION,
  HELP_CENTER_URL,
  IPHONE_RATEAPP_URL,
  IPHONE_TOS_URL,
  WEB_TOS_URL,
} from '../../../main/constants';
import { ManagementScreenNames } from '../../../management/navigation/ManagementScreenNames';
import { OffersScreenNames } from '../../../offers';
import { OnboardingScreenNames } from '../../../onboarding';
import { ProfileScreenNames } from '../../../profile/navigation/ScreenNames';
import { RequestsScreenNames } from '../../../requests';
import { SettingsScreenNames } from '../../../settings';
import { TimesheetsScreenNames } from '../../../timesheets/navigation/ScreenNames';
import { NavCard, NavLightCard } from './components';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  padding: 15px 20px;
`;

const Cards = styled(FlexColumnDirection)`
  width: 100%;
`;

const Closure = styled(FlexColumnCenter)`
  margin-top: 60px;
`;
const AppVersion = styled(H7Regular)`
  margin-top: 5px;
`;

const LinkContainer = styled(BaseView)``;

interface Props {}

export const MoreScreen: FC<Props> = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { logout, data } = useUser();
  const { mappedModulesBySlug } = useModules();

  const handleTOSClick = useCallback(() => {
    const url = (() => {
      if (Platform.OS === 'ios') return IPHONE_TOS_URL;
      if (Platform.OS === 'android') return ANDROID_TOS_URL;
      return WEB_TOS_URL;
    })();
    Linking.openURL(url);
  }, []);

  const handleRateAppClick = useCallback(() => {
    const url = (() => {
      if (Platform.OS === 'ios') return IPHONE_RATEAPP_URL;
      if (Platform.OS === 'android') return ANDROID_RATEAPP_URL;
      return WEB_TOS_URL;
    })();
    Linking.openURL(url);
  }, []);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Container>
        <Cards>
          <NavCard
            iconFull
            icon={{
              xml: icons.uiIcons.profile,
            }}
            title={t('myProfile')}
            onPress={() => navigate(ProfileScreenNames.MAIN)}
          />
          <NavCard
            icon={{
              xml: icons.uiIcons.calendar,
            }}
            title={t('upcomingShifts')}
            onPress={() => navigate(BookingsScreenNames.MAIN)}
          />
          <NavCard
            icon={{
              xml: icons.uiIcons.history,
            }}
            title={t('pastBookings')}
            onPress={() => navigate(TimesheetsScreenNames.MAIN)}
          />
          <NavCard
            icon={{
              xml: icons.uiIcons.offers,
            }}
            title={t('openShifts')}
            onPress={() => navigate(OffersScreenNames.MAIN)}
          />
          <NavCard
            icon={{
              xml: icons.uiIcons.requests,
            }}
            title={t('myRequests')}
            onPress={() => navigate(RequestsScreenNames.MAIN)}
          />
          {mappedModulesBySlug?.directory && (
            <NavCard
              icon={{
                xml: icons.uiIcons.directory,
              }}
              title={t('directory')}
              onPress={() => navigate(DirectoryScreenNames.MAIN)}
            />
          )}

          <NavCard
            icon={{
              xml: icons.uiIcons.admin,
            }}
            title={t('admin')}
            onPress={() => navigate(ManagementScreenNames.MAIN)}
          />

          <NavLightCard
            icon={{
              xml: icons.uiIcons.settings,
            }}
            title={t('settings')}
            onPress={() => {
              navigate(SettingsScreenNames.MAIN);
            }}
          />
          <NavLightCard
            icon={{
              xml: icons.actionIcons.rate,
            }}
            title={t('rateThisApp')}
            onPress={handleRateAppClick}
          />
          <NavLightCard
            icon={{
              xml: icons.uiIcons.team,
            }}
            title={t('joinBranch')}
            onPress={() => {
              navigate(OnboardingScreenNames.MAIN);
            }}
          />
          <NavLightCard
            icon={{
              xml: icons.cardIcons.helpCenter,
            }}
            title={t('helpCenter')}
            onPress={() => {
              Linking.openURL(HELP_CENTER_URL);
            }}
          />
          <NavLightCard
            icon={{
              xml: icons.cardIcons.logout,
            }}
            title={t('logout')}
            onPress={async () => {
              await logout();
              navigate(TabNames.HOME, { screen: HomeScreenNames.HOME });
            }}
          />
        </Cards>
        <Closure>
          <AppVersion>
            {t('version')} {APP_VERSION}
          </AppVersion>
          <LinkContainer as={RipplePressable} onPress={handleTOSClick}>
            <Link component={AppVersion}>{t('termsAndConditions')}</Link>
          </LinkContainer>
        </Closure>
      </Container>
    </ScrollView>
  );
};
