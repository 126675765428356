import React, { FC } from 'react';
import styled from 'styled-components/native';
import { BaseView, FlexColumnDirection } from '../Layout';
import { TimelineItem } from './TimelineItem';

const Container = styled(FlexColumnDirection)``;

const Nob = styled(BaseView)`
  width: 5px;
  height: 5px;
  background-color: ${({ theme }) => theme.colors.gray3};
  border-radius: 10px;
  margin-left: -2px;
  margin-top: 8px;
`;

interface Props {
  data: any[];
  item: FC<any>;
  nob?: FC<any>;
}

export const Timeline: FC<Props> = ({ data, item, nob = Nob }) => (
  <Container>
    {data.map((dataItem, idx) => (
      <TimelineItem
        key={idx}
        item={item}
        data={dataItem}
        nob={dataItem?.nob || nob}
        first={idx === 0}
        last={idx === data.length - 1}
      />
    ))}
  </Container>
);
