import { BaseText, BaseView, H7Light } from '@violetta/ubeya/ui';
import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/native';

const TimeText = styled(BaseText)`
  font-size: 14px;
  color: #252525;
  font-weight: 300;
  color: ${({ theme, $disabled }) =>
    $disabled ? theme.colors.gray2 : theme.colors.gray1};
  text-decoration: ${({ $disabled }) => !$disabled && 'underline'};
`;

const TimeContainer = styled(BaseView)`
  margin-left: 10px;
`;

const TimeBadge = styled(BaseView)<{ empty?: boolean }>`
  width: 80px;
  border-radius: 5px;
  border-bottom-width: 1px;
  border-color: ${({ theme, empty }) =>
    empty ? '#cbcbcb' : theme.colors.primary};
`;

const TimeTextReadOnly = styled(TimeText)<{ empty?: boolean }>`
  text-align: center;
  text-decoration: none;
  padding: 5px 4px;
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme, empty }) => (empty ? '#a8a8a8' : theme.colors.primary)};
`;

const TimeType = styled(H7Light)<{ empty?: boolean }>`
  margin-top: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme, empty }) => (empty ? '#a8a8a8' : '#8b8b8b')};
`;

interface Props {
  slug: string;
  time: Date;
}

export const TimeItem: FC<Props> = ({ time, slug }) => {
  const { t } = useTranslation();

  if (!moment(time).isValid()) {
    return (
      <TimeContainer>
        <TimeBadge empty>
          <TimeTextReadOnly empty>+</TimeTextReadOnly>
        </TimeBadge>
        <TimeType empty>{t(slug)}</TimeType>
      </TimeContainer>
    );
  }

  return (
    <TimeContainer>
      <TimeBadge>
        <TimeTextReadOnly>{moment(time).format('LT')}</TimeTextReadOnly>
      </TimeBadge>
      <TimeType>{t(slug)}</TimeType>
    </TimeContainer>
  );
};
