import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderlessBar } from '@violetta/ubeya/ui';
import { useTheme } from 'styled-components/native';
import { MoreScreen } from '../screens/more';
import { MoreScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const MoreNavigation: FC = () => {
  const { colors } = useTheme();

  return (
    <Navigator
      initialRouteName={MoreScreenNames.MORE}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
      }}
    >
      <Screen name={MoreScreenNames.MORE} component={MoreScreen} />
    </Navigator>
  );
};
