import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import {
  ChooseAvailabilityHeader,
  GeneralAvailabilityHeader,
  GeneralAvailabilityHeaderRight,
} from '../components/GeneralAvailabilityHeader';
import { ChooseAvailabilityScreen } from '../screens/ChooseAvailabilityScreen';
import { GeneralAvailabilityScreen } from '../screens/GeneralAvailabilityScreen';
import { GeneralAvailabilityScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const GeneralAvailabilityNavigation: FC = () => (
  <Navigator
    initialRouteName={GeneralAvailabilityScreenNames.GENERAL_AVAILABILITY}
  >
    <Screen
      name={GeneralAvailabilityScreenNames.GENERAL_AVAILABILITY}
      component={GeneralAvailabilityScreen}
      options={{
        header: (props: NativeStackHeaderProps) => (
          <GeneralAvailabilityHeader
            {...props}
            hasRightContent
            rightContent={GeneralAvailabilityHeaderRight}
          />
        ),
      }}
    />
    <Screen
      name={GeneralAvailabilityScreenNames.CHOOSE_AVAILABILITY}
      component={ChooseAvailabilityScreen}
      options={{
        header: (props: NativeStackHeaderProps) => (
          <ChooseAvailabilityHeader {...props} />
        ),
      }}
    />
  </Navigator>
);
