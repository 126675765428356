import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components/native';
import { FlexCenter, RipplePressable } from '../Layout';
import { CustomLoader } from '../Loaders/CustomLoader';
import { H4Regular } from '../Typography';

const PADDING = 20;
const SHADOW = {
  offset: '0px 2px',
  opacity: 0.7,
  color: 'rgba(0, 0, 0, 0.09)',
  radius: 3,
};

const BaseButton = styled(FlexCenter)`
  padding: ${PADDING}px;
  border-width: 1px;
  shadow-radius: ${SHADOW.radius}px;
  shadow-color: ${SHADOW.color};
  shadow-opacity: ${SHADOW.opacity};
  shadow-offset: ${SHADOW.offset};
  elevation: 0;
`;

const BaseTitle = styled(H4Regular)`
  font-weight: 500;
`;

interface Props {
  background: string;
  pressedBackground: string;
  disabledBackground: string;
  titleColor: string;
  borderColor: string;
  title?: string;
  radius?: number;
  onPress?: () => void;
  disabled?: boolean;
  paddingVertical?: number;
  paddingHorizontal?: number;

  isLoading?: boolean;
}

export const CustomButton: FC<Props> = ({
  children,
  background,
  pressedBackground,
  disabledBackground,
  titleColor,
  borderColor,
  title,
  onPress,
  disabled = false,
  paddingVertical = PADDING,
  paddingHorizontal = PADDING,
  radius = 12,
  isLoading = false,
}) => {
  const [pressed, setPressed] = useState(false);
  const currentBackgroundColor = useMemo(
    () => (disabled ? disabledBackground : pressed ? pressedBackground : background),
    [background, disabled, disabledBackground, pressed, pressedBackground],
  );

  const ButtonContainer = styled(BaseButton)`
    border-color: ${borderColor};
    background-color: ${currentBackgroundColor};
    padding: ${paddingVertical}px ${paddingHorizontal}px;
    border-radius: ${radius}px;
  `;

  const Title = styled(BaseTitle)`
    color: ${titleColor};
  `;

  return (
    <RipplePressable
      onPress={disabled ? undefined : onPress}
      style={{ flexGrow: 1 }}
      onPressIn={() => setPressed(true)}
      onPressOut={() => setPressed(false)}>
      <ButtonContainer pressed={pressed}>
        <FlexCenter>{children}</FlexCenter>
        <FlexCenter>{isLoading ? <CustomLoader size={20} /> : <Title>{title}</Title>}</FlexCenter>
      </ButtonContainer>
    </RipplePressable>
  );
};
