import React, { FC } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { IDirectoryContact } from '@violetta/ubeya/entities';
import {
  BaseImage,
  BaseView,
  Card,
  FlexCenter,
  FlexRowCenter,
  H3Medium,
  H4Bold,
  H5Regular,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

const Container = styled(BaseView)`
  padding: 10px 20px;
  display: flex;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BetweenContainer = styled(FlexRowCenter)``;

const Data = styled(BaseView)`
  margin-left: 15px;
  font-weight: 500;
`;

const Name = styled(H4Bold)`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #252525;
`;
const Phone = styled(H5Regular)``;

const ProfileImage = styled(BaseImage)`
  height: 30px;
  width: 30px;
  border-radius: 15px;
`;

const UserAvatar = styled(FlexCenter)`
  height: 30px;
  width: 30px;
  background-color: ${({ theme }) => theme.colors.gray3};
  border-radius: 15px;
`;

const AvatarText = styled(H3Medium)``;

interface Props {
  contact: IDirectoryContact;
  onPress: (item: IDirectoryContact) => void;
  onIconPress: (item: IDirectoryContact) => void;
}

export const DirectoryCard: FC<Props> = ({ contact, onPress, onIconPress }) => {
  const { firstName, lastName, image, phone } = contact;

  return (
    <Container as={RipplePressable} onPress={() => onPress(contact)}>
      <BetweenContainer>
        {image ? (
          <ProfileImage source={{ uri: image }} />
        ) : (
          <UserAvatar>
            <AvatarText>{firstName?.charAt(0)}</AvatarText>
          </UserAvatar>
        )}
        <Data>
          <Name>
            {firstName} {lastName}
          </Name>
        </Data>
      </BetweenContainer>
      <RipplePressable onPress={() => onIconPress(contact)}>
        <SvgIcon xml={icons.actionIcons.phone} width={20} height={20} />
      </RipplePressable>
    </Container>
  );
};
