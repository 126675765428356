import { ILocation } from '@violetta/ubeya/entities';
import { ActionSheetIOS } from '@violetta/ubeya/actionsheet';
import { Alert } from '@violetta/ubeya/alert';
import { Linking, Platform } from 'react-native';

// export const openMap = (location: ILocation) => {
//   if (!location) {
//     return null;
//   }
//   const { lat, lng, normalizedName, address } = location;

//   const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
//   const latLng = `${lat},${lng}`;
//   const label = normalizedName;
//   const url = Platform.select({
//     ios: `${scheme}${label}@${latLng}`,
//     android: `${scheme}${address}`,
//     // android: `https://www.google.com/maps/search/?api=1&query=${lat}%2C${lng}`,
//   });
//   if (url) {
//     Linking.openURL(url);
//   }
//   return null;
// };

const apps = [
  { id: 'WAZE', scheme: 'waze://', title: 'Waze' },
  {
    id: 'GOOGLE_MAPS',
    scheme:
      Platform.OS === 'ios' ? 'comgooglemaps://' : 'https://maps.google.com/',
    title: 'Google Maps',
  },
  {
    id: 'APPLE_MAPS',
    scheme: Platform.OS === 'ios' ? 'maps://' : 'applemaps://',
    title: 'Apple Maps',
  },
  { id: 'MOOVIT', scheme: 'moovit://', title: 'Moovit' },
  { id: 'UBER', scheme: 'uber://', title: 'Uber' },
  { id: 'LYFT', scheme: 'lyft://', title: 'Lyft' },
];

const isInstalled = (scheme) => Linking.canOpenURL(scheme);

export const getInstalledAppsArray = async () =>
  Promise.all(apps.map((app) => isInstalled(app.scheme))).then((results) => {
    return apps.filter((app, index) => results[index]);
  });

const getAppChoiceIOS = () =>
  new Promise(async (resolve) => {
    const availableApps = await getInstalledAppsArray();

    const options = availableApps.map((app) => app.title);

    ActionSheetIOS.showActionSheetWithOptions(
      {
        title: 'Choose application',
        options: ['Cancel', ...options],
        cancelButtonIndex: 0,
      },
      (buttonIndex) => {
        resolve(availableApps[buttonIndex - 1]);
      }
    );
  });

const getAppChoiceAndroid = async () =>
  new Promise(async (resolve) => {
    const availableApps = await getInstalledAppsArray();
    const options = availableApps.map((app) => ({
      text: app.title,
      type: 'confirm',
      onPress: () => resolve(app),
    }));
    options.push({
      text: 'Cancel',
      onPress: () => resolve(null),
      style: 'cancel',
    });
    Alert.alert('Open Address', 'Choose application', options, {
      onDismiss: () => resolve(null),
    });
  });

export const getAppChoice = () =>
  Platform.OS === 'ios' ? getAppChoiceIOS() : getAppChoiceAndroid();

export const openMap = async (location: ILocation) => {
  const { lat, lng, address } = location;
  const app = await getAppChoice();
  if (!app) {
    return;
  }

  let url = app?.scheme;

  switch (app?.id) {
    case 'APPLE_MAPS':
      url += `?ll=${lat},${lng}&q=${encodeURIComponent(address || 'Location')}`;
      break;
    case 'GOOGLE_MAPS':
      url += `?api=1&ll=${lat},${lng}&q=${encodeURIComponent(
        address || 'Location'
      )}`;
      break;
    case 'UBER':
      url += `?action=setPickup&pickup=my_location&dropoff[latitude]=${lat}&dropoff[longitude]${lng}`;
      if (address) {
        url += `&dropoff[nickname]=${encodeURIComponent(address)}`;
      }
      break;
    case 'LYFT':
      url += `?ridetype?id=lyft&destination[latitude]=${lat}&destination[longitude]${lng}`;
      break;
    case 'WAZE':
      url += `?ll=${lat},${lng}&navigate=yes`;
      break;
    case 'MOOVIT':
      // directions?dest_lat=&dest_lon=&dest_name=&orig_lat=&orig_lon=&orig_name=&partner_id=<YOUR_APP_NAME>
      url += `directions?dest_lat=${lat}&dest_lon=${lng}`;
      if (address) {
        url += `&dest_name=${encodeURIComponent(address)}`;
      }
      break;
    default:
      break;
  }

  Linking.openURL(url);
};

// // export const openMap = (location: ILocation) => {
// //   if (!location) {
// //     return null;
// //   }
// //   const { lat, lng } = location;

// //   if (Platform.OS === 'android') {
// //     return Linking.openURL(`geo:${lat},${lng}?q=${lat},${lng}`);
// //   }
// //   if (Platform.OS === 'ios') {
// //     return Linking.openURL(`http://maps.apple.com/?ll=${lat},${lng}`);
// //   }
// //   return null;
// // };
