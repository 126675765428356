import React, { useCallback } from 'react';
import { FlatList } from 'react-native';

export const FlatListWrapper = React.forwardRef((props, ref) => {
  const onViewChange = useCallback(props.onViewableItemsChanged, []);

  return (
    <FlatList
      ref={(refProp) => {
        ref.current = refProp;
      }}
      {...props}
      onViewableItemsChanged={onViewChange}
    />
  );
});
