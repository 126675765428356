import React, { FC } from 'react';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { useAccounts, useModules } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView } from '@violetta/ubeya/ui';
import { BookingsScreenNames } from '../../../bookings/navigation/ScreenNames';
import { DirectoryScreenNames } from '../../../directory/navigation/ScreenNames';
import { MoreScreenNames } from '../../../more';
import { NavCard } from '../../../more/screens/more/components/NavCard';
import { OffersScreenNames } from '../../../offers';
import { ProfileScreenNames } from '../../../profile/navigation/ScreenNames';
import { HomeCard } from '../HomeCard/HomeCard';

const Container = styled(BaseView)`
  padding: 20px 10px;
`;

interface Props {}

export const MoreCard: FC<Props> = ({}) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { mappedAccounts } = useAccounts();
  const { mappedModulesBySlug } = useModules();

  return (
    <HomeCard
      title={t('more')}
      onMoreClick={() => navigate(MoreScreenNames.MAIN)}
      body={() => (
        <>
          {mappedAccounts?.[76] && (
            <NavCard
              icon={{
                xml: icons.uiIcons.calendar,
              }}
              title={t('yourPhotoGallery')}
              onPress={() =>
                navigate(ProfileScreenNames.MAIN, {
                  screen: ProfileScreenNames.ACCOUNT_GALLERY,
                  params: { accountId: 76 },
                })
              }
            />
          )}
          <NavCard
            icon={{
              xml: icons.uiIcons.calendar,
            }}
            title={t('upcomingShifts')}
            onPress={() => navigate(BookingsScreenNames.MAIN)}
          />
          <NavCard
            icon={{
              xml: icons.uiIcons.offers,
            }}
            title={t('openShifts')}
            onPress={() => navigate(OffersScreenNames.MAIN)}
          />
          {mappedModulesBySlug?.directory && (
            <NavCard
              icon={{
                xml: icons.uiIcons.directory,
              }}
              title={t('directory')}
              onPress={() => navigate(DirectoryScreenNames.MAIN)}
            />
          )}
        </>
      )}
    />
  );
};
