const auditLogTransMap = {
  EmployeeBroadcastForShift: 'employeeBroadcastForShiftAuditLog',
  EmployeeUnBookedForShift: 'employeeUnBookedForShiftAuditLog',
  EmployeeUnBookedForShiftWithMessage:
    'employeeUnBookedForShiftAuditLogWithMessage',
  RequestApproved: 'requestApprovedAuditLog',
  RequestDeclined: 'requestDeclinedAuditLog',
  EmployeeShiftUpdated: 'employeeShiftUpdated',
  EmployeeShiftTimeUpdated: 'employeeShiftTimeUpdated',
  EmployeeShiftStartTimeUpdated: 'employeeShiftStartTimeUpdated',
  EmployeeShiftEndTimeUpdated: 'employeeShiftEndTimeUpdated',
  EmployeeApplicationDeclined: 'employeeApplicationDeclined',
  EmployeeApplicationDeclinedWithMessage:
    'employeeApplicationDeclinedWithMessage',
  EmployeeRegistrationProjectOnHold: 'employeeRegistrationProjectOnHold',
  EmployeeRegistrationProjectOnHoldWithMessage:
    'employeeRegistrationProjectOnHoldWithMessage',
  EmployeeRegistrationShiftOnHold: 'employeeRegistrationShiftOnHold',
  EmployeeRegistrationShiftOnHoldWithMessage:
    'employeeRegistrationShiftOnHoldWithMessage',
  EmployeeTimesheetChanged: 'EmployeeTimesheetChanged',
  EmployeeChangeShiftAutoLogUseCase: 'employeeChangeShiftAutoLogUseCase',
};

export const getAuditLogTransMapping = ({
  reportedType,
  type,
  checkShift,
  checkMessage,
  shiftDate,
  message,
}) =>
  auditLogTransMap[
    `${type}${reportedType || ''}${checkShift && shiftDate ? 'WithShift' : ''}${
      !!checkMessage && !!message ? 'WithMessage' : ''
    }`
  ];
