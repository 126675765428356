import React, { FC, useMemo, useState } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { icons } from '@violetta/ubeya/assets';
import { useOffers } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { TabBar } from '@violetta/ubeya/ui';
import { useStats } from '@violetta/ubeya/utils';
import { I18nManager, Platform } from 'react-native';
import {
  AvailabilitiesNavigation,
  AvailabilitiesScreenNames,
} from '../../availabilities/navigation';
import { BookingsNavigation, ScheduleNavigation } from '../../bookings';
import { BookingsScreenNames } from '../../bookings/navigation/ScreenNames';
import {
  ChatInnerNavigation,
  ChatNavigation,
  ChatScreenNames,
} from '../../chat';
import { DirectoryNavigation, DirectoryScreenNames } from '../../directory';
import { FeedNavigation, FeedScreenNames, GalleryScreen } from '../../feed';
import {
  GeneralAvailabilityNavigation,
  GeneralAvailabilityScreenNames,
} from '../../general-availability/navigation';
import { HomeNavigation } from '../../home';
import { CustomDrawerContent } from '../../home/components/CustomDrawerContent/CustomDrawerContent';
import { HomeScreenNames } from '../../home/navigation/ScreenNames';
import { IncomeNavigation } from '../../income/navigation';
import { IncomeScreenNames } from '../../income/navigation/ScreenNames';
import { ManagementNavigation } from '../../management/navigation';
import { ManagementScreenNames } from '../../management/navigation/ManagementScreenNames';
import {
  NotificationsScreenNames,
  NotifictionsNavigation,
} from '../../notifications';
import {
  OfferNavigation,
  OffersNavigation,
  OffersScreenNames,
} from '../../offers';
import { OnboardingNavigation, OnboardingScreenNames } from '../../onboarding';
import { PdfReaderNavigation } from '../../pdf-reader-screen/navigation/navigation';
import { PdfReaderScreenNames } from '../../pdf-reader-screen/navigation/ScreenNames';
import { ProfileNavigation } from '../../profile';
import { ProfileScreenNames } from '../../profile/navigation/ScreenNames';
import { QrCodeScannerNavigation } from '../../qr-code-scanner/navigation/navigation';
import { QrCodeScannerScreenNames } from '../../qr-code-scanner/navigation/ScreenNames';
import { ReleaseNavigation, ReleaseScreenNames } from '../../release';
import { RequestsNavigation, RequestsScreenNames } from '../../requests';
import { SequencesNavigation } from '../../sequences';
import { SequencesScreenNames } from '../../sequences/navigation/ScreenNames';
import { SettingsNavigation, SettingsScreenNames } from '../../settings';
import { SwapNavigation, SwapScreenNames } from '../../swap';
import {
  TimeAvailabilityNavigation,
  TimeAvailabilityScreenNames,
} from '../../time-availability';
import { TimeclockNavigation } from '../../timeclock/navigation';
import { TimeclockScreenNames } from '../../timeclock/navigation/ScreenNames';
import { TimesheetsNavigation, TimesheetsScreenNames } from '../../timesheets';
import { TodoNavigation, TodoScreenNames } from '../../todo/navigation';
import {
  WeeklyScheduleNavigation,
  WeeklyScheduleScreenNames,
} from '../../weekly-schedule/navigation';
import { DefaultView } from '../constants';
import { linking } from './linking';
import { ComplainceNavigation, ComplianceScreenNames } from '../../compliance';
import { useRecoilState } from 'recoil';
import { hideTabsAtom } from '../../../state';
import { config } from '../../../modules/main/appConfig';

const { navIcons } = icons;

type Props = {
  defaultView: DefaultView;
};

const { Navigator, Screen } = createNativeStackNavigator();
const { Navigator: TabNavigator, Screen: TabScreen } =
  createBottomTabNavigator();
const { Navigator: DrawerNavigator, Screen: DrawerScreen } =
  createDrawerNavigator();

const Tabs: FC = () => {
  const { t } = useTranslation();
  const { newOffersCount } = useOffers();
  const { data: stats } = useStats();
  const [hideTabs] = useRecoilState(hideTabsAtom);

  const items = useMemo(
    () => [
      {
        name: HomeScreenNames.TABS,
        displayName: t('tabHome'),
        screen: HomeScreenNames.MAIN,
        icons: { active: navIcons.homeLineActive, normal: navIcons.homeLine },
        notifNum: 0,
      },
      ...(!hideTabs.has('scheduling')
        ? [
            {
              name: HomeScreenNames.TABS,
              displayName: t('tabSchedule'),
              screen: BookingsScreenNames.SCHEDULE,
              icons: {
                active: navIcons.scheduleActive,
                normal: navIcons.scheduleIcon,
              },
              notifNum: 0,
            },
          ]
        : []),
      ...(!hideTabs.has('offers')
        ? [
            {
              name: HomeScreenNames.TABS,
              displayName: t('tabOffers'),
              screen: OffersScreenNames.OFFERS,
              icons: {
                active: navIcons.offersActive,
                normal: navIcons.offersIcon,
              },
              notifNum: newOffersCount,
            },
          ]
        : []),
      {
        name: FeedScreenNames.MAIN,
        displayName: t('tabFeed'),
        screen: FeedScreenNames.FEED,
        icons: { active: navIcons.feedLineActive, normal: navIcons.feedLine },
        notifNum: stats?.hasUnreadPosts ? -1 : 0,
      },
      ...(Platform.OS !== 'web' && !hideTabs.has('chat')
        ? [
            {
              name: ChatScreenNames.MAIN,
              displayName: t(config.chatTabSlug),
              icons: {
                active: navIcons.chatLineActive,
                normal: navIcons.chatEmpty,
              },
              screen: ChatScreenNames.ROOMS_LIST,
              notifNum: stats?.hasUnreadMessages ? -1 : 0,
            },
          ]
        : []),
    ],
    [
      hideTabs,
      newOffersCount,
      stats?.hasUnreadMessages,
      stats?.hasUnreadPosts,
      t,
    ]
  );
  return (
    <TabNavigator
      tabBar={(props: any) => <TabBar {...props} items={items} />}
      screenOptions={{
        unmountOnBlur: true,
        headerShown: false,
        tabBarHideOnKeyboard: true,
      }}
      initialRouteName={HomeScreenNames.MAIN}
    >
      <TabScreen name={HomeScreenNames.MAIN} component={HomeNavigation} />
      <TabScreen
        name={BookingsScreenNames.SCHEDULE}
        component={ScheduleNavigation}
      />
      <TabScreen name={OffersScreenNames.OFFERS} component={OffersNavigation} />
      <TabScreen name={FeedScreenNames.MAIN} component={FeedNavigation} />
      <TabScreen name={ChatScreenNames.MAIN} component={ChatNavigation} />
    </TabNavigator>
  );
};

const DrawerNavigation: FC = () => (
  <DrawerNavigator
    drawerStyle={
      Platform.OS === 'web' && {
        left: 0,
      }
    }
    screenOptions={{ headerShown: false }}
    drawerType={Platform.OS === 'web' ? 'back' : 'front'}
    drawerPosition={I18nManager.getConstants().isRTL ? 'right' : 'left'}
    initialRouteName={HomeScreenNames.TABS}
    drawerContent={(props: DrawerContentComponentProps) => (
      <CustomDrawerContent {...props} />
    )}
  >
    <DrawerScreen name={HomeScreenNames.TABS} component={Tabs} />
  </DrawerNavigator>
);

export const MainNavigation: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <NavigationContainer
      linking={linking}
      documentTitle={{
        formatter: (options, route) =>
          `Ubeya - ${options?.title ? t(options?.title) : t(route?.name)}`,
      }}
    >
      <Navigator
        initialRouteName={HomeScreenNames.DRAWER}
        screenOptions={{
          headerShown: false,
          // eslint-disable-next-line react/jsx-closing-bracket-location
        }}
      >
        <Screen name={HomeScreenNames.DRAWER} component={DrawerNavigation} />
        <Screen
          name={RequestsScreenNames.MAIN}
          component={RequestsNavigation}
        />
        <Screen
          name={TimeclockScreenNames.MAIN}
          component={TimeclockNavigation}
        />
        <Screen
          name={OffersScreenNames.MAIN}
          component={OfferNavigation}
          options={{ animationEnabled: Platform.OS !== 'android' }}
        />
        <Screen
          name={AvailabilitiesScreenNames.MAIN}
          component={AvailabilitiesNavigation}
        />
        <Screen
          name={TimeAvailabilityScreenNames.MAIN}
          component={TimeAvailabilityNavigation}
        />
        <Screen
          name={BookingsScreenNames.MAIN}
          component={BookingsNavigation}
        />
        <Screen
          name={TimesheetsScreenNames.MAIN}
          component={TimesheetsNavigation}
        />
        <Screen
          name={DirectoryScreenNames.MAIN}
          component={DirectoryNavigation}
        />
        <Screen name={ProfileScreenNames.MAIN} component={ProfileNavigation} />
        <Screen
          name={OnboardingScreenNames.MAIN}
          component={OnboardingNavigation}
        />
        <Screen
          name={NotificationsScreenNames.MAIN}
          component={NotifictionsNavigation}
        />
        <Screen
          name={SettingsScreenNames.MAIN}
          component={SettingsNavigation}
        />
        <Screen
          name={ManagementScreenNames.MAIN}
          component={ManagementNavigation}
        />
        <Screen
          name={FeedScreenNames.GALLERY}
          component={GalleryScreen}
          options={{
            gestureEnabled: false,
          }}
        />
        <Screen
          name={SequencesScreenNames.MAIN}
          component={SequencesNavigation}
        />
        <Screen name={ChatScreenNames.ROOM} component={ChatInnerNavigation} />
        <Screen name={IncomeScreenNames.MAIN} component={IncomeNavigation} />
        <Screen
          name={ComplianceScreenNames.MAIN}
          component={ComplainceNavigation}
        />
        <Screen name={ReleaseScreenNames.MAIN} component={ReleaseNavigation} />
        <Screen name={SwapScreenNames.MAIN} component={SwapNavigation} />
        <Screen
          name={GeneralAvailabilityScreenNames.MAIN}
          component={GeneralAvailabilityNavigation}
        />
        <Screen
          name={WeeklyScheduleScreenNames.MAIN}
          component={WeeklyScheduleNavigation}
        />
        <Screen name={TodoScreenNames.MAIN} component={TodoNavigation} />
        <Screen
          name={QrCodeScannerScreenNames.MAIN}
          component={QrCodeScannerNavigation}
        />
        <Screen
          name={PdfReaderScreenNames.MAIN}
          component={PdfReaderNavigation}
        />
      </Navigator>
    </NavigationContainer>
  );
};
