import React, { FC } from 'react';

import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { SwapHeader } from '../components/SwapHeader';
import { SwapHeaderRightContent } from '../components/SwapHeaderRightContent';
import { SwapScreen, SwapListScreen } from '../screens';
import { SwapScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const SwapNavigation: FC = () => (
  <Navigator initialRouteName={SwapScreenNames.MAIN}>
    <Screen
      name={SwapScreenNames.MAIN}
      component={SwapListScreen}
      options={{
        header: (props: NativeStackHeaderProps) => (
          <SwapHeader
            {...props}
            hasRightContent
            rightContent={SwapHeaderRightContent}
          />
        ),
      }}
    />
    <Screen
      name={SwapScreenNames.SWAP}
      component={SwapScreen}
      options={{
        header: (props: NativeStackHeaderProps) => <SwapHeader {...props} />,
      }}
    />
  </Navigator>
);
