import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { useQuery } from '@tanstack/react-query';

import { ISequenceInstance } from '../entities/ISequenceInstance';

type ResponseType = ISequenceInstance;

export const useSequenceInstance = (sequenceId: number, instanceId: number) => {
  const { data: userData } = useUser();

  const storeKey = ['sequence', String(sequenceId), String(instanceId)];
  const { isPending, data, isStale } = useQuery<ResponseType>({
    queryKey: storeKey,
    queryFn: () => api.getSequenceInstance({ sequenceId, instanceId }),
    enabled: !!userData?.id,
  });

  const sequenceInstance = useMemo(() => data, [data]);

  return {
    isLoading: isPending,
    sequenceInstance,
    isStale,
  };
};
