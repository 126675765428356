import React, { FC, useMemo, useContext } from 'react';
import { Sequence } from '@violetta/ubeya/entities';
import {
  FlexColumnDirection,
  FlexRow,
  FlexRowBetween,
  H3Regular,
  H6Medium,
  H7Regular,
  ChevronRight,
  ProgressBar,
} from '@violetta/ubeya/ui';

import moment from 'moment';
import styled from 'styled-components/native';

import { ConfigContext } from '../../../../contexts';

const Container = styled(FlexRowBetween)``;

const Date = styled(FlexColumnDirection)`
  width: 80px;
  align-items: flex-start;
`;

const SequenceDetails = styled(FlexColumnDirection)`
  justify-content: space-evenly;
  align-items: flex-start;
  flex: 3;
`;

const LightDate = styled(H7Regular)`
  color: ${({ theme }) => theme.colors.gray2};
`;

const SequenceName = styled(H6Medium)`
  font-weight: 500;
`;

const StartContainer = styled(FlexRow)`
  flex: 4;
`;

const EndContainer = styled(FlexRow)`
  flex: 1;
  justify-content: flex-end;
`;

interface Props {
  sequence: Sequence;
}

export const SequencesCardRow: FC<Props> = ({ sequence }) => {
  const { rtl } = useContext(ConfigContext);

  const { day, date } = useMemo(() => {
    const date = moment(sequence.createdAt);

    return {
      day: date.format('dddd'),
      date: date.format('D MMM'),
    };
  }, [sequence.createdAt]);

  const title = useMemo(() => sequence.title, [sequence.title]);

  return (
    <Container>
      <StartContainer>
        <SequenceDetails>
          <SequenceName>{title}</SequenceName>
          {/* <ProgressBar percentage={0.6} /> */}
        </SequenceDetails>
      </StartContainer>
      <EndContainer>
        <ChevronRight size={20} rtl={rtl} />
      </EndContainer>
    </Container>
  );
};
