import React, { FC, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { lotties } from '@violetta/ubeya/assets';
import { useRequests } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  EmptyState,
  CustomLoaderWrapped,
  FlexColumnDirection,
  WideFlexColumnCenter,
  Lottie,
  H3Regular,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { FlatList } from 'react-native';
import styled from 'styled-components/native';
import { AddRequestIcon } from '../components/AddRequestIcon';
import { RequestCard } from '../components/RequestCard';
import { RequestsScreenNames } from '../navigation/ScreenNames';
import Config from 'react-native-config';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const EmptyTitle = styled(H3Regular)`
  padding: 10px 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  color: #252525;
`;

// const Menu = styled(FlexRow)`
//   padding: 20px 30px 10px;
// `;
// const Item = styled(BaseView)<{ isSelected?: boolean }>`
//   padding: 10px 15px;
//   background-color: ${({ theme, isSelected }) => (isSelected ? theme.colors.lightSurface : theme.colors.gray11)};
//   border-color: ${({ theme }) => theme.colors.gray11};
//   border-width: 1px;
//   border-radius: 15px;
//   margin-right: 15px;
// `;
// const ItemTitle = styled(H5Regular)`
//   font-weight: 400;
// `;

interface Props {}

export const RequestsScreen: FC<Props> = () => {
  const { navigate } = useNavigation();
  const { requests, isLoading, refetch } = useRequests();
  const { t } = useTranslation();

  // currently disabling toggling between past and upcming requests */
  // const [isCurrent, setIsCurrent] = useState<boolean>(true);
  // const filteredRequests = useMemo(
  //   () => requests?.data.filter((request) => moment(request.data.startTime).isAfter(moment()) === isCurrent),
  //   [isCurrent, requests?.data],
  // );

  const sortedRequests = useMemo(
    () =>
      requests.sort((request1, request2) =>
        !request1.startTime || !request2.startTime
          ? -1
          : moment(request1.startTime).isAfter(request2.startTime)
          ? -1
          : 1
      ),
    [requests]
  );

  return (
    <Container>
      {/* currently disabling toggling between past and upcming requests */}
      {/* <Menu>
        <Item isSelected={isCurrent} as={Pressable} onPress={() => setIsCurrent(true)}>
          <ItemTitle>Current</ItemTitle>
        </Item>
        <Item isSelected={!isCurrent} as={Pressable} onPress={() => setIsCurrent(false)}>
          <ItemTitle>History</ItemTitle>
        </Item>
      </Menu> */}
      {!isLoading && (
        <FlatList
          contentContainerStyle={{
            flexGrow: 1,
            paddingBottom: sortedRequests?.length > 0 ? 50 : 0,
          }}
          ListEmptyComponent={
            <EmptyState
              lottieSrc={lotties.noRequests}
              height={'65%'}
              width={'100%'}
              title={t('noRequests')}
            />
          }
          data={sortedRequests}
          refreshing={isLoading}
          onRefresh={refetch}
          onEndReachedThreshold={1}
          keyExtractor={(item) => item.id?.toString()}
          renderItem={({ item }) => (
            <RequestCard request={item} key={item.id} />
          )}
        />
      )}
      {isLoading && <CustomLoaderWrapped />}
      {Config.APP_NAME !== 'Constellation' && (
        <AddRequestIcon
          onPress={() => navigate(RequestsScreenNames.ADD_REQUEST)}
        />
      )}
    </Container>
  );
};
