export const IMAGE_FILE_TYPES = ['JPG', 'PNG', 'PEG', 'JPEG', 'PNEG'];

export const getFileExtension = (fileName: string) => {
  if (!fileName) {
    return null;
  }
  const formatedFileName = fileName.split('?')?.[0];
  const pattern = /(?:\.([^.]+))?$/;
  return pattern.exec(formatedFileName)?.[1];
};

export const isFileImage = (fileName: string) => {
  if (!fileName) {
    return false;
  }
  const ext = getFileExtension(fileName)?.toUpperCase();
  if (!ext) {
    return false;
  }
  return IMAGE_FILE_TYPES.includes(ext);
};
