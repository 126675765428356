import { api } from '@violetta/ubeya/api';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { useUser } from '@violetta/ubeya/auth';
import { compoundedNameBuilder } from '@violetta/ubeya/shared';
import {
  CustomDTO,
  mappedArray,
  PickPrimitiveKeys,
  useOptimisticMutation,
} from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import {
  IDocument,
  IProject,
  ISequence,
  IShift,
  EmployeeSlotDTO,
  IChatRoom,
  IWage,
} from '../entities';
import { IConfirmation } from '../entities/IConfirmation';
import { ISwap } from '../entities/ISwap';
import { useLabels } from './useLabels';
import { useLocations } from './useLocations';
import { useMemo } from 'react';
import { useBranches } from './useBranches';

export type Booking = CustomDTO<
  IShift,
  PickPrimitiveKeys<IShift>,
  {
    project: CustomDTO<IProject>;
    confirmation: CustomDTO<IConfirmation>;
    captainType: number | null;
    isRead?: boolean;
    swap: CustomDTO<ISwap>;
    documents: CustomDTO<IDocument>[];
    sequences: ISequence[];
    employeeSlot: EmployeeSlotDTO;
    projectChat?: IChatRoom;
    accountChat?: IChatRoom;
    wage?: IWage;
  }
>;
type ResponseType = {
  data: Booking[];
};

type MappingByProjects = { [key: number]: Booking[] };

const normalizedBookingDate = ({
  booking,
  mappedLocations,
  isRemoteWork,
}: {
  booking: Booking;
  mappedLocations: any;
  isRemoteWork?: boolean;
}) => {
  const { startTime: rawStartTime } = booking || {};

  if (!isRemoteWork) {
    return booking.project?.date?.split(' ')?.[0];
  }

  const location =
    !!booking.locationId && mappedLocations?.[booking.locationId];
  if (!location) {
    return booking.project?.date?.split(' ')?.[0];
  }
  const startTime = momentTz.tz(rawStartTime, location?.timezoneName).toDate();
  return moment(startTime)?.format('YYYY-MM-DD');
};

const normalizedShiftTimes = ({
  booking,
  mappedLocations,
  isRemoteWork = false,
}: {
  booking: Booking;
  mappedLocations: any;
  isRemoteWork?: boolean;
}) => {
  const {
    startTime: rawStartTime,
    endTime: rawEndTime,
    date: rawDate,
  } = booking || {};
  if (!isRemoteWork) {
    return { startTime: rawStartTime, endTime: rawEndTime, date: rawDate };
  }
  const location =
    !!booking.locationId && mappedLocations?.[booking.locationId];
  if (!location) {
    return { startTime: rawStartTime, endTime: rawEndTime, date: rawDate };
  }

  const startTime = momentTz.tz(rawStartTime, location?.timezoneName).toDate();
  const endTime = momentTz.tz(rawEndTime, location?.timezoneName).toDate();

  const date = momentTz.tz(rawStartTime, location?.timezoneName).toDate();

  return {
    startTime,
    endTime,
    date: moment(date)?.format('YYYY-MM-DD') || rawDate,
  };
};

const selector = createCachedSelector(
  (data) => data?.data,
  (data, mappedLabels) => mappedLabels,
  (data, mappedLabels, mappedLocations) => mappedLocations,
  (data, mappedLabels, mappedLocations, mappedBranches) => mappedBranches,
  (data, mappedLabels, mappedLocations, mappedBranches) => {
    const bookings = (data || [])
      .map((booking) => ({
        ...booking,
        ...normalizedShiftTimes({
          booking,
          mappedLocations,
          isRemoteWork:
            booking?.project?.branchId &&
            !!mappedBranches?.[booking.project.branchId]?.schedulingConfig
              ?.isRemoteWork,
        }),
        project: {
          ...booking.project,
          date: normalizedBookingDate({
            booking,
            mappedLocations,
            isRemoteWork:
              booking?.project?.branchId &&
              !!mappedBranches?.[booking.project.branchId]?.schedulingConfig
                ?.isRemoteWork,
          }),
        },
        compoundedName: compoundedNameBuilder(
          booking,
          mappedLabels,
          mappedLocations
        ),
      }))
      .sort((a, b) =>
        new Date(a.project?.date).getTime() -
          new Date(b.project?.date).getTime() >
        0
          ? 1
          : -1
      );

    const mappedBookingsByProjects = (data || []).reduce(
      (output: MappingByProjects, booking: Booking) => {
        const projectId = booking.project?.id;
        if (!projectId) {
          return output;
        }
        if (!output[projectId]) {
          output[projectId] = [];
        }
        output[projectId].push(booking);
        return output;
      },
      {} as MappingByProjects
    );

    const mappedBookings = mappedArray(bookings) as { [key: number]: Booking };

    return { bookings, mappedBookings, mappedBookingsByProjects };
  }
)({
  keySelector: (
    data,
    mappedLabels,
    mappedLocations,
    mappedBranches,
    storeKey
  ) => storeKey,
});

export const useBookings = () => {
  const { data: userData } = useUser();
  const { mappedLabels } = useLabels();
  const { mappedLocations } = useLocations();
  const { mappedBranches } = useBranches();

  const storeKey = ['bookings'];
  const { isPending, data, refetch, isStale } = useQuery<ResponseType>({
    queryKey: storeKey,
    queryFn: () => api.getBookings(),
    enabled: !!userData?.id,
    select: (data) =>
      selector(
        data,
        mappedLabels,
        mappedLocations,
        mappedBranches,
        storeKey.join('#')
      ),
  });

  const { bookings, mappedBookings, mappedBookingsByProjects } = useMemo(() => {
    const {
      bookings = [],
      mappedBookings = {},
      mappedBookingsByProjects = {},
    } = data || {};
    return {
      bookings,
      mappedBookings,
      mappedBookingsByProjects,
    };
  }, [data]);

  const { mutateAsync: updateConfirmation, isPending: isUpdatingConfirmation } =
    useOptimisticMutation<
      { data: IProject[] },
      { projectId: number; id: string },
      any,
      any
    >(
      storeKey,
      api.updateConfirmation,
      ({ previousData, payload }) => ({
        ...previousData,
        data: previousData.data.map((item) =>
          item.confirmation?.id !== payload.id
            ? item
            : {
                ...item,
                confirmation: { ...item.confirmation, isConfirmed: true },
              }
        ),
      }),
      {
        mutateOnSettled: true,
        refetchOnSuccess: false,
      }
    );

  const {
    mutateAsync: markAsRead,
    isError: isMarkReadError,
    isPending: isMarkReadLoading,
  } = useOptimisticMutation<
    { data: Booking[] },
    { projectId: number; isRead?: boolean },
    { data: boolean }
  >(
    storeKey,
    ({ projectId, isRead }: { projectId: number; isRead?: boolean }) =>
      api.updateBookingsStats({ projectId, isRead }),
    ({ previousData, payload }) => ({
      data: previousData.data.map(({ project, ...rest }) =>
        project.id === payload.projectId
          ? { ...rest, project, isRead: payload.isRead }
          : { project, ...rest }
      ),
    }),
    {
      mutateOnlyOnSuccess: true,
      mutateOnSettled: true,
      refetchOnSuccess: false,
    }
  );

  return {
    markAsRead,
    isLoading: isPending,
    refetch,
    bookings,
    mappedBookings,
    mappedBookingsByProjects,
    updateConfirmation,
    isUpdatingConfirmation,
    isStale,
    isMarkReadError,
    isMarkReadLoading,
  };
};
