import { useCallback } from 'react';
import { api } from '@violetta/ubeya/api';
import { to } from '@violetta/ubeya/await';
import moment from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  flushOfflineMutation,
  getOfflineMutation,
} from '@violetta/ubeya/utils';

export const useTimesheetQueue = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: clockIn } = useMutation({
    mutationFn: ({
      id,
      location,
      delta,
    }: {
      id: number;
      delta?: number;
      location: { lat: number; lng: number } | null;
    }) => api.clock({ clockType: 'in', id, location, delta }),
  });

  const { mutateAsync: checkIn } = useMutation({
    mutationFn: ({
      id,
      location,
      delta,
    }: {
      id: number;
      delta?: number;
      location: { lat: number; lng: number } | null;
    }) => api.clock({ clockType: 'checkIn', id, location, delta }),
  });

  const { mutateAsync: checkOut } = useMutation({
    mutationFn: ({
      id,
      location,
      delta,
    }: {
      id: number;
      delta?: number;
      location: { lat: number; lng: number } | null;
    }) => api.clock({ clockType: 'checkOut', id, location, delta }),
  });

  const { mutateAsync: clockOut } = useMutation({
    mutationFn: ({
      id,
      location,
      delta,
    }: {
      id: number;
      delta?: number;
      location: { lat: number; lng: number } | null;
    }) => api.clock({ clockType: 'out', id, location, delta }),
  });

  const { mutateAsync: updateCaptainShiftEmployee } = useMutation({
    mutationFn: (params) => api.updateCaptainShiftEmployee(params),
  });

  const resolveQueue = useCallback(async () => {
    const mutationQueue = await getOfflineMutation();
    for (const queueItem of mutationQueue) {
      switch (queueItem.mutation) {
        case 'clockIn': {
          const delta = moment().diff(
            moment(queueItem.timeRequested),
            'minute'
          );
          await to(
            clockIn({
              id: queueItem.params.timesheetId,
              location: queueItem.params.location,
              delta,
            })
          );
          break;
          // return clockInErr || clockInResp;
        }
        case 'checkIn': {
          const delta = moment().diff(
            moment(queueItem.timeRequested),
            'minute'
          );
          await to(
            checkIn({
              id: queueItem.params.timesheetId,
              location: queueItem.params.location,
              delta,
            })
          );
          break;
          // return clockInErr || clockInResp;
        }
        case 'checkOut': {
          const delta = moment().diff(
            moment(queueItem.timeRequested),
            'minute'
          );
          await to(
            checkOut({
              id: queueItem.params.timesheetId,
              location: queueItem.params.location,
              delta,
            })
          );
          break;
          // return clockInErr || clockInResp;
        }
        case 'clockOut': {
          const delta = moment().diff(
            moment(queueItem.timeRequested),
            'minute'
          );
          await to(
            clockOut({
              id: queueItem.params.timesheetId,
              location: queueItem.params.location,
              delta,
            })
          );
          break;
        }
        case 'updateCaptainShiftEmployee': {
          const delta = moment().diff(
            moment(queueItem.timeRequested),
            'minute'
          );
          // eslint-disable-next-line no-case-declarations
          await to(updateCaptainShiftEmployee({ ...queueItem.params, delta }));
          break;
        }
        default:
          break;
      }
    }
    if (mutationQueue?.length > 0) {
      queryClient.invalidateQueries();
    }
    flushOfflineMutation();
  }, [
    checkIn,
    checkOut,
    clockIn,
    clockOut,
    queryClient,
    updateCaptainShiftEmployee,
  ]);

  return { resolveQueue };
};
