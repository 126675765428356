import React, { useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { lotties } from '@violetta/ubeya/assets';
import { useCompliances } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseScreen,
  BaseView,
  H3Medium,
  Lottie,
  WideFlexColumnCenter,
  H1Regular,
  BaseCollapsible,
  H5Medium,
  FlexRowCenter,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { SafeAreaView, SectionList } from 'react-native';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components/native';
import { complianceFilterAtom } from '../atoms';
import { FieldCard } from '../components/FieldCard';
import { COMPLIANCE_OPTIONS, COMPLIANCE_SECTIONS } from '../constants';
import { ComplianceScreenNames } from '../navigation';

const LoadingContainer = styled(BaseView)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Content = styled(BaseView)`
  flex: 1;
  padding-top: 20px;
`;

const Footer = styled(BaseView)`
  padding: 10px 20px;
`;

const Disclaimer = styled(H3Medium)`
  text-align: center;
  font-size: 15px;
`;

const EmptyTitle = styled(H1Regular)`
  padding: 10px 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  color: #252525;
`;

const TitleContainer = styled(FlexRowCenter)`
  padding: 10px;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

const SectionTitle = styled(H5Medium)`
  font-weight: 500;
  margin-left: 10px;
  align-self: center;
  font-size: 20px;
  text-align: center;
`;

const NumberTitle = styled(SectionTitle)``;

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

const EmptyScreen = () => {
  const { t } = useTranslation();
  return (
    <WideFlexColumnCenter>
      <Lottie
        source={lotties.noIncomes}
        autoPlay
        resizeMode="cover"
        height={450}
        width={365}
        style={{ marginRight: -60, marginTop: -100 }}
      />
      <EmptyTitle>{t('emptyCompliance')}</EmptyTitle>
    </WideFlexColumnCenter>
  );
};

export const ComplianceScreen = () => {
  const complianceViewOption = useRecoilValue(complianceFilterAtom);
  const { navigate } = useNavigation();
  const { data: compliances, isLoading, refetch } = useCompliances();
  const { t } = useTranslation();

  const allFields = useMemo(
    () =>
      (compliances || []).reduce((all, { accountId, fields }) => {
        all.push(...fields.map((field) => ({ ...field, accountId })));
        return all;
      }, []),
    [compliances]
  );

  const uploadedDocuments = useMemo(
    () =>
      (allFields || []).filter((field) =>
        field.value && field.value?.[0]?.link
          ? field.value?.[0]?.expirationDate
            ? moment().isBefore(moment(field.value[0].expirationDate))
            : true
          : false
      ),
    [allFields]
  );

  const missingDocuments = useMemo(
    () =>
      (allFields || []).filter(
        (field) => !field.value?.[0] || !field.value?.[0]?.link
      ),
    [allFields]
  );

  const invalidDocuments = useMemo(
    () =>
      (allFields || []).filter((field) =>
        field.value && field.value[0]?.link && field.value[0]?.expirationDate
          ? moment().isSameOrAfter(moment(field.value[0].expirationDate))
          : false
      ),
    [allFields]
  );

  const missingComplianceTab = useMemo(
    () => [
      {
        title: COMPLIANCE_SECTIONS.MISSING,
        data: [missingDocuments],
      },
      {
        title: COMPLIANCE_SECTIONS.INVALID,
        data: [invalidDocuments],
      },
    ],
    [invalidDocuments, missingDocuments]
  );

  const uploadedComplianceTab = useMemo(
    () => [
      {
        title: COMPLIANCE_SECTIONS.UPLOADED,
        data: [uploadedDocuments],
      },
    ],
    [uploadedDocuments]
  );

  const navigateField = useCallback(
    (field) =>
      navigate(ComplianceScreenNames.MAIN, {
        screen: ComplianceScreenNames.COMPLIANCE_FIELD,
        params: {
          field,
          refetch,
          title: field?.value[0]?.link
            ? t('updateDocument')
            : t('uploadDocument'),
        },
      }),
    [refetch, t, navigate]
  );

  return (
    <BaseScreen>
      <Container>
        <SectionList
          contentContainerStyle={{
            flexGrow: 1,
            paddingBottom: 50,
            marginTop: 15,
            marginLeft: 20,
            marginRight: 20,
          }}
          sections={
            complianceViewOption === COMPLIANCE_OPTIONS.UPLOADED
              ? uploadedComplianceTab
              : missingComplianceTab
          }
          keyExtractor={() => Math.random().toString()}
          renderItem={({ item: items, section }) => (
            <BaseCollapsible
              triggerStyle={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              withBorder={false}
              isCollpased={false}
              trigger={() => (
                <TitleContainer>
                  <SectionTitle>{t(section.title)}</SectionTitle>
                  <NumberTitle>({items?.length})</NumberTitle>
                </TitleContainer>
              )}
            >
              {items.map((item, index) => (
                <FieldCard
                  field={item}
                  key={index + item.id}
                  sectionTitle={section.title}
                  setSelectedField={navigateField}
                />
              ))}
            </BaseCollapsible>
          )}
          ListEmptyComponent={isLoading ? <BaseView /> : <EmptyScreen />}
          refreshing={isLoading}
          onRefresh={refetch}
          stickySectionHeadersEnabled={false}
        />
      </Container>
    </BaseScreen>
  );
};
