import { images } from '@violetta/ubeya/assets';
import { AccountImage, BaseView } from '@violetta/ubeya/ui';
import React from 'react';
import { Image } from 'react-native';
import styled from 'styled-components/native';

const Header = styled(BaseView)`
  width: 100%;
`;

const LargeProfileImage = styled(Image)`
  width: 100%;
  height: 200px;
`;

const ImageContainer = styled(BaseView)`
  position: relative;
`;

const ProfileCoverSection = ({ coverPhoto, logo, name }) => {
  return (
    <Header>
      <ImageContainer>
        <LargeProfileImage
          source={{ uri: coverPhoto || images.jpg.defaultCoverPhoto }}
        />

        <AccountImage
          image={logo}
          limitContainerSize={false}
          size={100}
          showNameAsEmptyLogo
          name={name}
          style={{
            position: 'absolute',
            top: '20%',
            left: '40%',
            transform: [{ translateX: 0.5 }, { translateY: 0.5 }],
          }}
          imageStyle={{
            borderWidth: 2,
            borderColor: 'white',
          }}
        />
      </ImageContainer>
    </Header>
  );
};

export default ProfileCoverSection;
