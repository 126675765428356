import React, { FC, useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  Booking,
  IAccount,
  IBranch,
  ILabel,
  IPosition,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { SORT_BY_DATE_AND_TIME } from '@violetta/ubeya/shared';
import { FlexColumnDirection, H5Regular } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { BookingsScreenNames } from '../../../bookings';
import { HomeScreenNames } from '../../navigation/ScreenNames';
import { CardTitle } from '../CardTitle/CardTitle';
import { UpcomingShift } from './UpcomingShift';

interface Props {
  bookings: Booking[];
  mappedBranches: Record<number, IBranch>;
  mappedAccounts: Record<number, IAccount>;
  mappedLabels: Record<number, ILabel>;
  mappedPositions: Record<number, IPosition>;
  total: number;
  hideSeeAllButton?: boolean;
}

const EmptyStateTitle = styled(H5Regular)`
  text-align: center;
`;

export const UpcomingShiftsCard: FC<Props> = React.memo(
  ({
    bookings,
    mappedAccounts,
    mappedLabels,
    mappedPositions,
    mappedBranches,
    total,
    hideSeeAllButton,
  }) => {
    const { t } = useTranslation();

    const { navigate } = useNavigation();

    const navigateBookings = useCallback(() => {
      navigate(HomeScreenNames.TABS, { screen: BookingsScreenNames.SCHEDULE });
    }, [navigate]);

    const navigateBooking = useCallback(
      (booking: Booking) => {
        navigate(BookingsScreenNames.MAIN, {
          screen: BookingsScreenNames.BOOKING,
          params: { bookingId: booking?.id },
        });
      },
      [navigate]
    );

    const sortByDateAndTime = SORT_BY_DATE_AND_TIME('startTime');

    const bookingsByDate = useMemo(
      () => bookings.slice(0, 3).sort(sortByDateAndTime),
      [bookings, sortByDateAndTime]
    );

    return (
      <FlexColumnDirection>
        <CardTitle
          title={t('upcomingShifts')}
          count={total}
          onShowAllPress={navigateBookings}
        />
        {bookings.length === 0 && (
          <EmptyStateTitle>{t('emptyStateBookings')}</EmptyStateTitle>
        )}
        {bookingsByDate.map((booking, i) => (
          <UpcomingShift
            isFirst={i === 0}
            key={booking.id}
            booking={booking}
            mappedAccounts={mappedAccounts}
            mappedLabels={mappedLabels}
            mappedPositions={mappedPositions}
            mappedBranches={mappedBranches}
            onPress={navigateBooking}
          />
        ))}
      </FlexColumnDirection>
    );
  }
);
