import { icons } from '@violetta/ubeya/assets';
import {
  BaseImage,
  BaseText,
  BaseView,
  FlexRow,
  H6Medium,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { FC } from 'react';
import styled from 'styled-components/native';

const Summary = styled(FlexRow)`
  margin-top: 20px;
`;
const SummaryData = styled(BaseView)`
  flex: 1;
`;
const SummaryUserName = styled(BaseText)`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
`;

const SummaryItemTitleContainer = styled(BaseView)`
  align-itenms: cetner;
  flex: 1;
`;
const SummaryItemTitle = styled(H6Medium)<{ bold?: boolean }>`
  flex: 1;
  ${({ bold }) => bold && `font-weight:500`};
`;

const SummaryProfileImage = styled.Image`
  height: 100px;
  width: 100px;
  border-radius: 5px;
  border-color: ${({ theme }) => theme.colors.surface};
`;

const SummaryItem = styled(FlexRow)`
  flex: 1;
  margin-bottom: 3px;
  align-items: center;
  justify-content: center;
`;
const SummaryIconContainer = styled(BaseView)`
  margin-left: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 20px;
`;

const BranchLogo = styled(BaseImage)`
  width: 18px;
  height: 18px;
  border-radius: 9;
`;

interface Props {
  firstName: string;
  lastName: string;
  image: string;
  branchLogo?: string;
  branchName?: string;
  positionName?: string;
  projectLocationName?: string;
  shiftLocationName?: string;
}

export const UserCard: FC<Props> = ({
  firstName,
  lastName,
  image,
  branchLogo,
  branchName,
  positionName,
  projectLocationName,
  shiftLocationName,
}) => {
  return (
    <Summary>
      <SummaryData>
        <SummaryUserName>
          {firstName} {lastName}
        </SummaryUserName>
        <SummaryItem>
          <SummaryIconContainer>
            <BranchLogo source={{ uri: branchLogo }} />
          </SummaryIconContainer>
          <SummaryItemTitle>{branchName}</SummaryItemTitle>
        </SummaryItem>
        {positionName && (
          <SummaryItem>
            <SummaryIconContainer style={{ marginLeft: -7, marginRight: 2 }}>
              <SvgIcon
                xml={icons?.uiIcons?.personIcon}
                height={17}
                width={17}
              />
            </SummaryIconContainer>
            <SummaryItemTitle>{positionName}</SummaryItemTitle>
          </SummaryItem>
        )}
        {!projectLocationName &&
          shiftLocationName && ( // daily employees
            <SummaryItem>
              <SummaryIconContainer>
                <SvgIcon
                  xml={icons?.uiIcons?.locationOutline}
                  height={18}
                  width={18}
                />
              </SummaryIconContainer>
              <SummaryItemTitle>{shiftLocationName}</SummaryItemTitle>
            </SummaryItem>
          )}
        {projectLocationName && (
          <SummaryItem>
            <SummaryIconContainer>
              <SvgIcon
                xml={icons?.uiIcons?.locationOutline}
                height={18}
                width={18}
              />
            </SummaryIconContainer>
            <SummaryItemTitleContainer>
              <SummaryItemTitle>{projectLocationName}</SummaryItemTitle>
              {shiftLocationName && (
                <SummaryItemTitle bold>{shiftLocationName}</SummaryItemTitle>
              )}
            </SummaryItemTitleContainer>
          </SummaryItem>
        )}
      </SummaryData>
      {!!image && <SummaryProfileImage source={{ uri: image }} />}
    </Summary>
  );
};
