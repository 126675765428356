import { useCallback, useEffect, useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { IBranch } from '../entities';

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const branches = data;

    const mappedBranches = mappedArray(branches) as { [key: number]: IBranch };
    const canViewAllSchedules = (branches || []).some(
      ({ scheduleConfig }) => !!scheduleConfig?.usersCanViewAllSchedules
    );

    const isSingleBranch = (branches || []).length === 1;

    return { branches, mappedBranches, isSingleBranch, canViewAllSchedules };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useBranches = () => {
  const { data: userData } = useUser();

  const storeKey = ['branches'];
  const { isPending, data } = useQuery<{ data: IBranch[] }>({
    queryKey: storeKey,
    queryFn: () => api.getBranches(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { branches, mappedBranches, isSingleBranch, canViewAllSchedules } =
    useMemo(() => {
      const {
        branches = [],
        mappedBranches = {},
        isSingleBranch = false,
        canViewAllSchedules,
      } = data || {};
      return {
        branches,
        mappedBranches,
        isSingleBranch,
        canViewAllSchedules,
      };
    }, [data]);

  return {
    isLoading: isPending,
    isSingleBranch,
    branches,
    mappedBranches,
    canViewAllSchedules,
  };
};
