import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderlessBar } from '@violetta/ubeya/ui';
import { useTheme } from 'styled-components/native';
import { TimesheetHeader } from '../headers/TimesheetHeader';
import { TimesheetScreen } from '../screens/TimesheetScreen';
import { TimesheetsScreenNames } from './ScreenNames';
import { EditTimesheetScreen } from '../screens/EditTimesheetScreen';

const { Navigator, Screen } = createNativeStackNavigator();

interface Props {}

export const TimesheetsNavigation: FC<Props> = () => {
  const { colors } = useTheme();

  return (
    <Navigator
      initialRouteName={TimesheetsScreenNames.TIMESHEET}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
        // eslint-disable-next-line react/jsx-closing-bracket-location
      }}
    >
      <Screen
        name={TimesheetsScreenNames.TIMESHEET}
        component={TimesheetScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <TimesheetHeader {...props} />
          ),
        }}
      />
      <Screen
        name={TimesheetsScreenNames.EDIT_TIMESHEET}
        component={EditTimesheetScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <TimesheetHeader {...props} />
          ),
        }}
      />
    </Navigator>
  );
};
