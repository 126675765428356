import React, { FC } from 'react';
import { ScrollView } from 'react-native';

interface Props {
  extraScrollHeight?: number;
}

export const KeyboardAwareScrollView: FC<Props> = ({ children, ...props }) => {
  return <ScrollView {...props}>{children}</ScrollView>;
};
