import React, { FC, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import {
  Booking,
  ITimesheet,
  useBranches,
  useLocations,
  usePositions,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseCollapsible,
  BaseImage,
  BaseView,
  FlexColumnDirection,
  FlexRowCenter,
  H5Medium,
  H5Regular,
  H6Medium,
  H6Regular,
  H7Medium,
  H7Regular,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import moment from 'moment';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import Markdown from 'react-native-markdown-display';

const Container = styled(FlexColumnDirection)`
  width: 100%;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
  border-bottom-width: 1px;
  padding-bottom: 10px;
`;

const RowDetail = styled(FlexRowCenter)`
  padding: 4px 15px;
  align-items: center;
`;

const CardDataDetails = styled(H5Regular)`
  margin-left: 15px;
  margin-right: 25px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: #252525;
  max-width: 80%;
`;

const CardDataDetailsLink = styled(CardDataDetails)``;

const IconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 20px;
`;

const BranchLogo = styled(BaseImage)`
  width: 36px;
  height: 36px;
  border-radius: 23px;
  margin-right: 5px;
`;

const BranchData = styled(FlexColumnDirection)`
  margin-left: 10px;
  flex: 1;
  justify-content: center;
`;

const BranchName = styled(H5Medium)`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const EventName = styled(H6Medium)`
  font-size: 16px;
  font-weight: normal;
  margin-top: 4px;
`;

const BranchRow = styled(FlexRowCenter)`
  margin: 20px 14px;
`;

const ConfirmedChip = styled(BaseView)`
  margin-left: 8px;
  background-color: ${({ theme }) => theme.colors.seconday2};
  padding: 3px 8px;
  border-radius: 6px;
`;

const ConfirmedChipText = styled(H7Medium)`
  color: ${({ theme }) => theme.colors.surface};
  font-size: 9px;
  font-weight: 500;
  text-align: center;
`;

const Wage = styled(H6Regular)`
  font-size: 16px;
  font-weight: 500;
  margin-left: 13px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const TriggerContainer = styled(BaseView)``;

const TriggerText = styled(H7Regular)`
  font-size: 15px;
  font-weight: 500;
  margin-left: 65px;
`;

const ShiftJobDescription = styled(H7Regular)`
  margin-left: 26px;
  padding: 15px;
`;

interface Props {
  locationId: number | null;
  booking: Booking;
  withLogo?: boolean;
  isShowBookingWage?: boolean;
  branchId?: number;
  showBreaksNotice?: boolean;
  currency?: string;
  openLocationMap?: () => void;
}

export const BookingSummary: FC<Props> = ({
  locationId,
  booking,
  openLocationMap,
  branchId,
  withLogo = true,
  showBreaksNotice = false,
  isShowBookingWage = false,
  currency = '',
}) => {
  const { mappedLocations } = useLocations();
  const { mappedPositions } = usePositions();
  const { mappedBranches } = useBranches();
  const duration = useMemo(
    () =>
      moment.duration(
        moment(booking?.endTime).diff(moment(booking?.startTime))
      ),
    [booking?.endTime, booking?.startTime]
  );
  const hours = duration.asHours() || 0;

  const { t } = useTranslation();

  const time = useMemo(
    () => rangeFormat(booking?.startTime, booking?.endTime),
    [booking?.endTime, booking?.startTime]
  );
  const showBreakMessage = useMemo(() => {
    if (!showBreaksNotice) {
      return false;
    }
    if (!booking?.startTime || !booking?.endTime) {
      return false;
    }
    return hours >= 6;
  }, [booking?.endTime, booking?.startTime, hours, showBreaksNotice]);

  const position = useMemo(
    () => booking?.positionId && mappedPositions[booking?.positionId],
    [booking?.positionId, mappedPositions]
  );
  const branch = useMemo(
    () => mappedBranches[branchId || booking?.project?.branchId],
    [booking?.project?.branchId, branchId, mappedBranches]
  );

  const { wage } = booking || {};

  const Trigger = ({ triggerParams }) => {
    const { position } = triggerParams || {};
    return (
      <RowDetail>
        <IconContainer>
          <SvgIcon xml={icons?.uiIcons?.personIcon} height={18} width={18} />
        </IconContainer>
        <TriggerContainer>
          <CardDataDetails>
            {position ? t(position?.slug) : '-'}
          </CardDataDetails>
        </TriggerContainer>
      </RowDetail>
    );
  };

  return (
    <Container>
      <BranchRow>
        {withLogo && branch?.logo && (
          <BranchLogo source={{ uri: branch?.logo }} />
        )}
        <BranchData>
          <BranchName>{branch?.name}</BranchName>
          {!!booking?.compoundedName && (
            <EventName>{booking?.compoundedName}</EventName>
          )}
        </BranchData>
      </BranchRow>

      {!!position && (
        <BaseCollapsible
          trigger={Trigger}
          disabled={!position.jobDescription}
          triggerParams={{ position }}
          withBorder={false}
          triggerStyle={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
          containerStyle={{
            width: '100%',
            marginBottom: 10,
            marginTop: 7,
          }}
        >
          <ShiftJobDescription>
            <Markdown style={{ text: { lineHeight: 24 } }}>
              {position.jobDescription}
            </Markdown>
          </ShiftJobDescription>
        </BaseCollapsible>
      )}
      {!!isShowBookingWage && !!wage?.flatWage && (
        <RowDetail>
          <IconContainer>
            <SvgIcon xml={icons.uiIcons.incomeIcon2} width={17} height={17} />
          </IconContainer>
          <Wage>
            {currency}
            {wage?.flatWage}/{t('flat')} {t('wageEstimated')}
          </Wage>
        </RowDetail>
      )}
      {!!isShowBookingWage && !!wage?.hourlyWage && !wage?.flatWage && (
        <RowDetail>
          <IconContainer>
            <SvgIcon xml={icons.uiIcons.incomeIcon2} width={17} height={17} />
          </IconContainer>
          <Wage>
            {currency}
            {wage?.hourlyWage}/{t('hour')} {t('wageEstimated')}
          </Wage>
        </RowDetail>
      )}
      {!!time && (
        <RowDetail>
          <IconContainer>
            <SvgIcon xml={icons?.uiIcons?.clock2} height={18} width={18} />
          </IconContainer>
          <CardDataDetails>
            {time} {hours > 0 && `(${hours?.toFixed(2)} ${t('shortHour')})`}
          </CardDataDetails>
        </RowDetail>
      )}
      {booking?.project?.clientName && (
        <RowDetail>
          <IconContainer>
            <SvgIcon xml={icons?.uiIcons?.jobCase} height={18} width={18} />
          </IconContainer>
          <CardDataDetails>{booking?.project?.clientName}</CardDataDetails>
        </RowDetail>
      )}
      {!!showBreakMessage && (
        <RowDetail>
          <IconContainer>
            <SvgIcon xml={icons?.uiIcons?.clock2} height={18} width={18} />
          </IconContainer>
          <CardDataDetails>30 minutes break</CardDataDetails>
        </RowDetail>
      )}
      {locationId && (
        <RowDetail as={Pressable} onPress={openLocationMap || (() => {})}>
          <IconContainer>
            <SvgIcon
              xml={icons?.uiIcons?.locationOutline}
              height={18}
              width={14}
            />
          </IconContainer>
          <CardDataDetailsLink>
            {mappedLocations[locationId]?.address}
          </CardDataDetailsLink>
        </RowDetail>
      )}
      {[0, 1].includes(booking?.swap?.status) && (
        <RowDetail>
          <IconContainer>
            <SvgIcon
              xml={icons?.symbolIcons?.swapIcon}
              height={18}
              width={18}
            />
          </IconContainer>
          <CardDataDetails>{t('status')}</CardDataDetails>
          <ConfirmedChip>
            <ConfirmedChipText>
              {t('swapPendingChip').toUpperCase()}
            </ConfirmedChipText>
          </ConfirmedChip>
        </RowDetail>
      )}
      {booking?.release?.status === 0 && (
        <RowDetail>
          <IconContainer>
            <SvgIcon
              xml={icons?.symbolIcons?.releaseIcon}
              height={18}
              width={22}
            />
          </IconContainer>
          <CardDataDetails>{t('status')}</CardDataDetails>
          <ConfirmedChip>
            <ConfirmedChipText>
              {t('releasePendingChip').toUpperCase()}
            </ConfirmedChipText>
          </ConfirmedChip>
        </RowDetail>
      )}
    </Container>
  );
};
