import React, { FC } from 'react';
import { useRequests } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, Button } from '@violetta/ubeya/ui';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import { isEditingGeneralAvailabilityAtom } from '../../../../state';

const Container = styled(BaseView)`
  width: 45px;
`;

export const GeneralAvailabilityHeaderRight: FC<{
  tintColor?: string | undefined;
}> = () => {
  const [isEditingGeneralAvailability, setIsEditingGeneralAvailability] =
    useRecoilState(isEditingGeneralAvailabilityAtom);
  const { hasApprovedRequest } = useRequests();
  const { t } = useTranslation();

  return (
    <Container>
      {hasApprovedRequest && (
        <Button
          onPress={() =>
            setIsEditingGeneralAvailability(!isEditingGeneralAvailability)
          }
          fontSize={18}
          title={t('edit')}
          type="transparent"
          paddingHorizontal={0}
          paddingVertical={0}
          textStyle={{
            fontWeight: 'normal',
            letterSpacing: 0,
            textDecorationLine: 'underline',
            paddingRight: 5,
            marginLeft: -20,
          }}
        />
      )}
    </Container>
  );
};
