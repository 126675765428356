import { useMemo } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { IRequestType, RequestsTypes } from '@violetta/ubeya/entities';
import { createAdminStoreKey, mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { useAdminUser } from './useAdminUser';

export const REQUESTS_COLORS = [
  { background: '#5a93ff' },
  { background: '#ff77a3' },
  { background: '#7788ff' },
];

export enum RequestTypes {
  ABSENCE = 1,
  GENERAL_AVAILABILITY = 2,
  RELEASE_SHIFT = 3,
  SWAP_SHIFT = 4,
}

export const TYPES_COLROS = {
  [RequestTypes.SWAP_SHIFT]: {
    color: '#b929ff',
    background: 'rgba(185, 41, 255, 0.1)',
  },
  [RequestTypes.RELEASE_SHIFT]: { color: '#db6b12', background: '#ffdfc3' },
  [RequestTypes.GENERAL_AVAILABILITY]: {
    color: '#ff3f6d',
    background: 'rgba(255, 63, 109, 0.2)',
  },
};

const selector = createCachedSelector(
  (data) => data?.data,
  ({ data }) => {
    const requestsTypes = data || [];

    const mappedRequestsTypesByType = mappedArray(
      requestsTypes,
      (value) => value.type
    ) as Record<RequestsTypes, IRequestType>;

    const mappedRequestsTypesByTypeId = mappedArray(requestsTypes) as Record<
      number,
      IRequestType
    >;

    return {
      requestsTypes,
      mappedRequestsTypesByType,
      mappedRequestsTypesByTypeId,
    };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useRequestsTypes = () => {
  const { data: userData } = useAdminUser();
  const storeKey = useMemo(() => createAdminStoreKey(['requestsTypes']), []);
  const { isPending, data } = useQuery<{ data: IRequestType[] }>({
    queryKey: storeKey,
    queryFn: () => adminApi.getRequestsTypes(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const {
    requestsTypes = [],
    mappedRequestsTypesByType = {},
    mappedRequestsTypesByTypeId = {},
  } = data || {};

  return {
    isLoading: isPending,
    requestsTypes,
    mappedRequestsTypesByType,
    mappedRequestsTypesByTypeId,
  };
};
