import { useMemo } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { flatten } from 'lodash';
import createCachedSelector from 're-reselect';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import * as sharedAtoms from '../../../shared/src/atoms';
import { useBranches } from './useBranches';

const selector = createCachedSelector(
  (data) => data,
  (data) => {
    const allEmployees = flatten(data?.pages.map((page) => page?.data?.data));
    return { employees: allEmployees };
  }
)({
  keySelector: (data, storeKey) => `${storeKey.join('#')}`,
});

export const useEmployeesInfinite = ({ search, pageSize = 50 }) => {
  const { mappedBranches } = useBranches();
  const [selectedAccount] = useRecoilState(sharedAtoms.selectedAccount);
  const accountId = selectedAccount?.id;

  const branchesIds = useMemo(
    () => Object.keys(mappedBranches).map((key) => Number(key)) || [],
    [mappedBranches]
  );

  const storeKey = useMemo(
    () => ['employees', pageSize, accountId, search, branchesIds.join(',')],
    [accountId, branchesIds, pageSize, search]
  );

  const { isLoading, isIdle, data, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: storeKey,
      queryFn: ({ pageParam }) =>
        adminApi.fetchEmployees({
          page: pageParam || 1,
          ...{
            pageSize,
            accountId,
            searchTerm: search,
            branches: branchesIds,
            statusFilter: 'active',
            dataKey: 'active',
            orderBy: [{ col: 'name', dir: 'asc' }],
          },
        }),
      enabled: branchesIds?.length > 0,
      select: (data) => selector(data, storeKey),
      getNextPageParam: (lastGroup, allGroups) => {
        const totalItems = allGroups.reduce(
          (sum, { data }) => sum + data?.data?.length,
          0
        );
        return totalItems === lastGroup?.data?.total
          ? false
          : allGroups.length + 1;
      },
    });

  const { employees } = data || {};

  return {
    isLoading,
    isIdle,
    data: employees,
    fetchMore: fetchNextPage,
    canFetchMore: hasNextPage,
    totalItems: data?.pages?.[0]?.total,
  };
};
