import Config from 'react-native-config';

export type APP_NAMES = 'Constellation' | 'Ubeya';
type AppConfig = {
  appName: APP_NAMES;
  links: { ANDROID_RATEAPP_URL: string; IPHONE_RATEAPP_MANAGER_URL: string };
  headers: { appName: string };
  version: { APP_VERSION: string; BUILD_NUMBER: string };
  prefixes: string[];
  showSupportModal: boolean;
  chatTabSlug?: string;
};

export const config = (() => {
  switch (Config.APP_NAME as APP_NAMES) {
    case 'Constellation':
      return {
        appName: 'Constellation',
        version: {
          APP_VERSION: '3.1',
          BUILD_NUMBER: '25',
        },
        links: {
          ANDROID_RATEAPP_URL:
            'market://details?id=com.ubeya.constellation.app',
          IPHONE_RATEAPP_MANAGER_URL:
            'itms-apps://itunes.apple.com/app/id6449157866',
        },
        headers: {
          appName: 'X-App-Constellation',
        },
        prefixes: [
          'ubeya://crew',
          'http://crew.ubeya.com',
          'https://crew.ubeya.com',
        ],
        showSupportModal: true,
        chatTabSlug: 'tabSupport',
      };
    default:
      return {
        appName: 'Ubeya',
        version: {
          APP_VERSION: '38.7',
          BUILD_NUMBER: '2000',
        },
        links: {
          ANDROID_RATEAPP_URL: 'market://details?id=com.ubeya.crew.app',
          IPHONE_RATEAPP_MANAGER_URL:
            'itms-apps://itunes.apple.com/app/id1605334181',
        },
        headers: {
          appName: 'X-App-Ubeya',
        },
        prefixes: [
          'ubeya://crew',
          'http://crew.ubeya.com',
          'https://crew.ubeya.com',
        ],
        showSupportModal: false,
        chatTabSlug: 'tabChat',
      };
  }
})() as AppConfig;
