import React, { FC, useCallback, useEffect } from 'react';
import { IBranch, useBranches } from '@violetta/ubeya/entities';
import styled from 'styled-components/native';
import { Field } from 'react-final-form';
import { BaseSelect } from '../Select/BaseSelect';
import { FlexRow } from '../Layout/Layout';
import { H5Medium, H6Medium } from '../Typography/Typography';
import { SelectProps } from '../Select/types';

type Props = Omit<
  SelectProps<IBranch, IBranch['id']>,
  'optionToSelectedOption'
>;

const Container = styled(FlexRow)``;

const Title = styled(H6Medium)`
  color: ${({ theme }) => theme.colors.surface};
  font-weight: 500;
`;

const ListTitle = styled(H5Medium)`
  color: ${({ theme }) => theme.colors.gray1};
  font-weight: 500;
`;

const SelectedOptionChip: FC<IBranch> = ({ name }) => (
  <Container>
    <Title>{name}</Title>
  </Container>
);
const ListOption: FC<IBranch> = ({ name }) => (
  <Container>
    <ListTitle>{name}</ListTitle>
  </Container>
);

export const BranchSelectField: FC<Props> = ({ ...props }) => {
  const { branches } = useBranches();
  const optionToSelectedOption = useCallback(
    ({ id }: IBranch) => id as IBranch['id'],
    []
  );

  useEffect(() => {
    if (branches.length === 1) {
      props.onSelected?.([branches[0].id], [branches[0]]);
    }
  }, [branches, props]);

  if (branches.length === 1) {
    return <Field<number> name={props.name} render={() => null} />;
  }

  return (
    <BaseSelect
      {...props}
      optionToSelectedOption={optionToSelectedOption}
      renderSelectedOptionChip={SelectedOptionChip}
      renderOption={ListOption}
    />
  );
};
