import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigation } from '@react-navigation/core';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import {
  Booking,
  IBranch,
  useBookings,
  useLocations,
  useMandatories,
  useSequences,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, SwiperView } from '@violetta/ubeya/ui';
import { pickImg, rangeFormat, uiAlert } from '@violetta/ubeya/utils';
import moment from 'moment';
import { Animated, Linking, Pressable } from 'react-native';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import { BookingsScreenNames } from '../../../bookings/navigation/ScreenNames';
import { AppContext } from '../../../main/AppContext';
import { SequencesScreenNames } from '../../../sequences/navigation/ScreenNames';
import { SwapScreenNames } from '../../../swap/navigation';
import { TodoScreenNames } from '../../../todo';
import { CardTitle } from '../CardTitle/CardTitle';
import { Mandatory } from './Mandatory';
import { Swap } from './Swap';
import {
  SwiperCardItemProps,
  ThingsToDoSwiperCard,
} from './ThingsToDoSwiperCard';
import { HIDE_LOCATION_NAME_ACCOUNT_IDS } from '../../../main/constants';

type Props = {
  mappedBranches: Record<number, IBranch>;
};

export const ThingsToDoCard: FC<Props> = React.memo(({ mappedBranches }) => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const {
    bookings: { selectBooking },
  } = useContext(AppContext);
  const [currentConfirmationLoading, setCurrentConformationLoading] =
    useState<number>(0);
  const [lastLoadedMandatory, setLastLoadedMandatory] = useState<number>(-1);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const { mappedLocations } = useLocations();
  const {
    bookings,
    updateConfirmation,
    isUpdatingConfirmation,
    isLoading: isLoadingBookings,
  } = useBookings();
  const { data } = useUser();
  const queryClient = useQueryClient();
  const [fadeAnim] = useState(new Animated.Value(1));
  const { rtl } = useContext(ConfigContext);

  const navigateBooking = useCallback(
    (bookingId: number) => {
      navigate(BookingsScreenNames.MAIN, {
        screen: BookingsScreenNames.BOOKING,
        params: { bookingId },
      });
    },
    [navigate]
  );

  const navigateSwap = useCallback(
    (bookingProp: Booking) => {
      selectBooking(bookingProp);
      navigate(SwapScreenNames.MAIN, {
        screen: SwapScreenNames.SWAP,
      });
    },
    [navigate, selectBooking]
  );

  const navigateSequence = useCallback(
    (sequenceId: number, instanceId: number) => {
      navigate(SequencesScreenNames.MAIN, {
        screen: SequencesScreenNames.SEQUENCE,
        params: { sequenceId, instanceId },
      });
    },
    [navigate]
  );

  const confirmationCB = useCallback(
    async (projectId, id) => {
      setCurrentConformationLoading(projectId);
      updateConfirmation({ projectId, id });
    },
    [updateConfirmation]
  );

  const confirm = useCallback(
    async ({ booking, projectId, confirmationId }) => {
      const res = await uiAlert({
        title: t('areYouSureYouWishToConfirm'),
        message: booking?.confirmation?.message,
        cancel: {
          text: t('no'),
        },
        save: {
          text: t('yes'),
        },
      });

      if (!res) {
        return;
      }
      confirmationCB(projectId, confirmationId);
    },
    [confirmationCB, t]
  );

  const navigateMandatory = useCallback(async (url) => {
    if (Linking.canOpenURL(url)) {
      Linking.openURL(url);
    }
  }, []);

  const confirmations = useMemo(
    () =>
      bookings.filter(
        ({ confirmation }) => confirmation && !confirmation.isConfirmed
      ),
    [bookings]
  );

  const swaps = useMemo(
    () =>
      bookings.filter(
        ({ swap }) => !!swap?.id && swap?.typeId === 2 && swap?.status === 0
      ),
    [bookings]
  );

  const {
    isLoading: isLoadingSequencs,
    sequences,
    mappedSequences,
  } = useSequences();
  const {
    isLoading: isLoadingMandatories,
    mandatories,
    refetch: refetchMandatories,
  } = useMandatories();

  const refretchMandatories = useCallback(
    async (idx) => {
      setLastLoadedMandatory(idx);
      await refetchMandatories();
      setLastLoadedMandatory(-1);
    },
    [refetchMandatories, setLastLoadedMandatory]
  );

  const instances = useMemo(
    () =>
      sequences
        .map(({ id, instances }) =>
          instances.map((instanceId) => ({
            id,
            instanceId,
          }))
        )
        .reduce((carry, item) => [...carry, ...item], []),
    [sequences]
  );

  const image = useMemo(() => data?.image, [data?.image]);

  const confirmationCardItems: SwiperCardItemProps[] = useMemo(
    () =>
      confirmations.map((confirmation) => {
        const daysLeft = Math.ceil(
          moment(confirmation.date).diff(moment(), 'days', true)
        );
        const location = mappedLocations?.[confirmation.locationId];
        const showLocationName = confirmation?.project?.branchId
          ? HIDE_LOCATION_NAME_ACCOUNT_IDS.includes(
              mappedBranches?.[confirmation?.project?.branchId]?.accountId
            )
          : true;

        const time = rangeFormat(confirmation.startTime, confirmation.endTime);
        return {
          title: confirmation.compoundedName,
          subtitle:
            !!showLocationName && location
              ? `${location.address}, ${location.name}`
              : '',
          date: moment(confirmation.date).format('MMM, D'),
          time,
          buttonText: t('confirm'),
          onCardPress: () => navigateBooking(confirmation.id),
          onButtonPress: () =>
            confirm({
              booking: confirmation,
              projectId: confirmation?.project.id,
              confirmationId: confirmation.confirmation.id,
            }),
          isLoading:
            isUpdatingConfirmation &&
            currentConfirmationLoading === confirmation.project.id,
          hasChip: true,
          chipText:
            daysLeft > 1
              ? t('inDays', { daysLeft })
              : daysLeft === 1
              ? t('tomorrow')
              : t('today'),
        };
      }),
    [
      confirm,
      confirmations,
      currentConfirmationLoading,
      isUpdatingConfirmation,
      mappedBranches,
      mappedLocations,
      navigateBooking,
      t,
    ]
  );

  const sequencesCardItems: SwiperCardItemProps[] = useMemo(
    () =>
      instances.map((instance) => {
        const realInstance = mappedSequences?.[instance.id];
        return {
          title: realInstance.title,
          subtitle: realInstance.description,
          buttonText: t('start'),
          onButtonPress: () =>
            navigateSequence(instance.id, instance.instanceId),
          onCardPress: () => navigateSequence(instance.id, instance.instanceId),
        };
      }),
    [instances, mappedSequences, navigateSequence, t]
  );

  const { mutateAsync: uploadPhoto, isPending: isLoadingPhoto } = useMutation({
    mutationFn: ({ base64, type }: { base64: string; type: string }) =>
      api.uploadPhoto({ image: base64, type }),
  });

  const photoSelected = useCallback(async () => {
    const assets = await pickImg({
      texts: {
        cancel: t('cancel'),
        choosePhoto: t('choosePhoto'),
        takeAPhoto: t('takePhoto'),
        title: t('chooseImageUploadMethod'),
      },
      image: {
        includeBase64: true,
        mediaType: 'photo',
        maxWidth: 1000,
        maxHeight: 1000,
        quality: 0.7,
      },
    });

    if (!assets) {
      return;
    }
    const { base64, type } = assets?.[0] || {};
    if (!base64 || !type) {
      return;
    }
    await uploadPhoto({ base64, type });
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 1000,
      useNativeDriver: false,
    }).start();
    queryClient.invalidateQueries({ queryKey: ['user'] });
  }, [fadeAnim, queryClient, t, uploadPhoto]);

  const uploadProfilePhotoCardData = useMemo(
    () => ({
      buttonText: t('upload'),
      isLoading: isLoadingPhoto,
      onButtonPress: photoSelected,
      title: t('thingsToDoUploadPhoto'),
    }),
    [isLoadingPhoto, photoSelected, t]
  );

  const allData = useMemo(
    () =>
      [
        ...confirmationCardItems,
        ...sequencesCardItems,
        !image && uploadProfilePhotoCardData,
      ].filter((item) => item),
    [
      confirmationCardItems,
      image,
      sequencesCardItems,
      uploadProfilePhotoCardData,
    ]
  );

  const handleShowAllTodos = useCallback(() => {
    navigate(TodoScreenNames.MAIN, {
      screen: TodoScreenNames.TODO_LIST,
    });
  }, [navigate]);

  if (isFirstLoad) {
    if (isLoadingSequencs || isLoadingBookings || isLoadingMandatories) {
      return null;
    }
    setIsFirstLoad(false);
  }

  if (
    (confirmations || []).length === 0 &&
    (swaps || []).length === 0 &&
    (instances || []).length === 0 &&
    (sequences || []).length === 0 &&
    (mandatories || []).length === 0 &&
    image
  ) {
    return null;
  }

  return (
    <BaseView>
      <CardTitle
        title={t('thingsToDo')}
        count={(allData || []).length}
        onShowAllPress={handleShowAllTodos}
      />
      <SwiperView
        cards={allData}
        rtl={rtl}
        initialIndex={0}
        swipeComponent={(item, i) => (
          <ThingsToDoSwiperCard key={i} todo={item} />
        )}
        onLastItemPress={handleShowAllTodos}
      />

      {swaps.length > 0 && (
        <SwiperView
          rtl={rtl}
          cards={swaps}
          initialIndex={0}
          swipeComponent={(item, i) => (
            <BaseView as={Pressable} key={i} onPress={() => navigateSwap(item)}>
              <Swap
                key={item.id}
                booking={item}
                onPress={() => navigateSwap(item)}
              />
            </BaseView>
          )}
          onLastItemPress={handleShowAllTodos}
        />
      )}

      {(mandatories || []).length > 0 && (
        <SwiperView
          rtl={rtl}
          height={190}
          cards={mandatories}
          initialIndex={0}
          onLastItemPress={handleShowAllTodos}
          swipeComponent={(item, index) => (
            <Mandatory
              key={index}
              mandatory={item}
              onPress={() => refretchMandatories(index)}
              onUrlPress={() => item.url && navigateMandatory(item.url)}
              isLoading={lastLoadedMandatory === index}
            />
          )}
        />
      )}
    </BaseView>
  );
});
