import React, { FC, useContext } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { useTranslation } from '@violetta/ubeya/i18n';
import { FlexRow, H3Medium } from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';
import { AppContext, IAppContext } from '../../../main/AppContext';

const Container = styled(FlexRow)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Title = styled(H3Medium)`
  font-weight: 500;
`;

export const AvailabilityWeekHeaderTitle: FC<StackHeaderProps> = () => {
  const {
    availability: { selectedDay },
  } = useContext<IAppContext>(AppContext);
  const { t } = useTranslation();

  return (
    <Container>
      <Title>
        {t('weeklyHeaderPrefix')}{' '}
        {moment(selectedDay).format('ddd D MMM, YYYY')}
      </Title>
    </Container>
  );
};
