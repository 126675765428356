export const BACKGROUNDS = [
  {
    start: {
      x: 0,
      y: 0,
    },
    end: {
      x: 1,
      y: 1,
    },
    colors: ['#ff2bd9', '#0016fa'],
  },
  {
    start: {
      x: 0,
      y: 0,
    },
    end: {
      x: 1,
      y: 1,
    },
    colors: ['#00e6f5', '#f8017b'],
  },
  {
    start: {
      x: 0,
      y: 0,
    },
    end: {
      x: 1,
      y: 1,
    },
    colors: ['#08fc44', '#101dff'],
  },
  {
    start: {
      x: 0,
      y: 0,
    },
    end: {
      x: 1,
      y: 1,
    },
    colors: ['#00eaff', '#1300fe'],
  },
  {
    start: {
      x: 0,
      y: 0,
    },
    end: {
      x: 1,
      y: 1,
    },
    colors: ['#ffe23f', '#ff2629'],
  },
  {
    start: {
      x: 0,
      y: 0,
    },
    end: {
      x: 1,
      y: 1,
    },
    colors: ['#fad29f', '#ff005e'],
  },
  {
    start: {
      x: 0,
      y: 0,
    },
    end: {
      x: 1,
      y: 1,
    },
    colors: ['#ffe04a', '#00fffa'],
  },
  {
    start: {
      x: 0,
      y: 0,
    },
    end: {
      x: 1,
      y: 1,
    },
    colors: ['#fd0a6c', '#2927ff'],
  },
  {
    start: {
      x: 0,
      y: 0,
    },
    end: {
      x: 1,
      y: 1,
    },
    colors: ['#000000', '#000000'],
  },
];
