import React, { FC } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@violetta/ubeya/i18n';
import { ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { Button } from '../Buttons';
import { ClockComponent } from '../Clock';
import { BaseImage } from '../Images';
import { BaseView, FlexColumn } from '../Layout';
import { CustomLoader } from '../Loaders';
import { EmployeeTimeRow } from '../Rows';
import { H1Medium } from '../Typography';

const SafeView = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
`;

const TopData = styled(ScrollView).attrs({
  contentContainerStyle: { alignItems: 'center' },
})`
  flex: 3;
  width: 100%;
`;

const ImageContainer = styled(BaseView)`
  margin: 20px 0;
  position: relative;
`;

const UserImage = styled(BaseImage)`
  border-radius: 12px;
  background-color: antiquewhite;
  resize-mode: cover;
`;

const FooterContainer = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.gray11};
  height: 150px;
  width: 100%;
  padding: 20px 0;
`;

const HeaderText = styled(H1Medium)`
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 10px;
`;
const BoldText = styled(HeaderText)`
  font-weight: 500;
`;

type Props = {
  employeeData: any;
  photo: string;
  employeeName: string;
  projectName: string;
  locationName: string;
  shiftTime?: string;
  startTime?: Date;
  endTime?: Date;
  checkInTime?: Date;
  checkOutTime?: Date;
  toRequireCheckInWithMobile: boolean;
  handleUpdateEmployeeTime: () => void;
  isUpdatingEmployeeTime: boolean;
  isUpdatingOSEmployee: boolean;
  times: {
    name: string;
    time: string;
  }[];
  timeFormat: {
    '24': string;
    '12': string;
  };
};

export const SharedScannedEmployeeScreen: FC<Props> = React.memo((props) => {
  const {
    employeeData,
    photo,
    employeeName,
    projectName,
    locationName,
    shiftTime,
    startTime,
    endTime,
    checkInTime,
    checkOutTime,
    toRequireCheckInWithMobile,
    handleUpdateEmployeeTime,
    isUpdatingEmployeeTime,
    isUpdatingOSEmployee,
    times,
    timeFormat,
  } = props || {};
  const { t } = useTranslation();

  const { goBack } = useNavigation();
  const isLoading = isUpdatingOSEmployee || isUpdatingEmployeeTime;

  const clockType = !startTime
    ? 'clockIn'
    : !toRequireCheckInWithMobile
    ? 'clockOut'
    : !checkInTime
    ? 'timesheetCheckIn'
    : !checkOutTime
    ? 'timesheetCheckOut'
    : 'clockOut';

  return (
    <SafeView>
      <TopData showsVerticalScrollIndicator={false}>
        <ClockComponent mode="dark" timeFormat={timeFormat} />
        <ImageContainer>
          {photo && (
            <UserImage width={150} height={150} source={{ uri: photo }} />
          )}
        </ImageContainer>
        {!props && <CustomLoader width={150} height={150} />}
        {!employeeData && !isLoading ? (
          <>
            <HeaderText>
              <BoldText>Unexpected error, please try again</BoldText>
            </HeaderText>
            <Button
              type="primary"
              onPress={goBack}
              title={t('goBack')}
              paddingHorizontal={80}
            />
          </>
        ) : (
          <>
            <HeaderText>
              <BoldText>{employeeName}</BoldText>
            </HeaderText>
            <HeaderText>{`${projectName || ''} ${
              locationName ? ` - ${locationName}` : ''
            }`}</HeaderText>
            <HeaderText>{shiftTime}</HeaderText>
            {!(startTime && endTime) && props && (
              <Button
                type="primary"
                onPress={handleUpdateEmployeeTime}
                title={t(clockType)}
                isLoading={isLoading}
                disabled={isLoading}
                paddingHorizontal={80}
              />
            )}
          </>
        )}
      </TopData>
      {startTime && (
        <FooterContainer>
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{ alignItems: 'center' }}
          >
            {(times || []).map((times) => (
              <EmployeeTimeRow
                type={times.name}
                time={times.time}
                key={times.name}
              />
            ))}
          </ScrollView>
        </FooterContainer>
      )}
    </SafeView>
  );
});
