import { MutationConfig, MutationFunction } from '@tanstack/react-query';
import { useOptimisticMutation } from './useOptimisticMutation';

type Entity = { id: number };
type DefaultDeleteItemParams = { id: number };

type ApiFunctions<
  Entity,
  AddItemParams,
  EditItemParams,
  DeleteItemParams = DefaultDeleteItemParams
> = {
  addApi: MutationFunction<Entity, AddItemParams>;
  editApi: MutationFunction<Entity, EditItemParams>;
  deleteApi: MutationFunction<{}, DeleteItemParams>;
};

function* generatorId() {
  let i = -1;
  while (true) {
    yield i;
    // eslint-disable-next-line no-plusplus
    i--;
  }
}

const counter = generatorId();

export const useCRUD = <
  DataStructure extends { data: Entity[] },
  AddItemParams,
  EditItemParams extends { id: number },
  DeleteItemParams extends { id: number }
>(
  storeKey: string[],
  {
    addApi,
    editApi,
    deleteApi,
  }: ApiFunctions<Entity, AddItemParams, EditItemParams, DeleteItemParams>,
  config?: MutationConfig<any, any, any, any>
) => {
  const { mutateAsync: addItem, isPending: isLoadingAddItem } =
    useOptimisticMutation<DataStructure, AddItemParams, Entity>(
      storeKey,
      addApi,
      ({ previousData, payload }) => ({
        ...previousData,
        data: [...previousData.data, { ...payload, id: counter.next().value }],
      }),
      {
        refetchOnSuccess: true,
      },
      config
    );

  const { mutateAsync: editItem } = useOptimisticMutation<
    DataStructure,
    EditItemParams,
    Entity
  >(
    storeKey,
    editApi,
    ({ previousData, payload }) => ({
      ...previousData,
      data: previousData.data.map((item) =>
        item.id !== payload.id ? item : { ...item, ...payload }
      ),
    }),
    {
      refetchOnSuccess: false,
    },
    config
  );

  const { mutateAsync: deleteItem } = useOptimisticMutation<
    DataStructure,
    DeleteItemParams,
    {}
  >(
    storeKey,
    deleteApi,
    ({ previousData, payload: { id } }) => ({
      ...previousData,
      data: previousData.data.filter((item) => item.id !== id),
    }),
    {
      refetchOnSuccess: false,
    },
    config
  );

  return { addItem, editItem, deleteItem, isLoadingAddItem };
};
