import React, { FC } from 'react';
import styled from 'styled-components/native';
import { icons } from '@violetta/ubeya/assets';
import { Pressable } from 'react-native';
import { SvgIcon } from '../Icons/SvgIcon';
import { BaseView, FlexRow } from '../Layout';

const Container = styled(FlexRow)``;
const IconContainer = styled(BaseView)`
  margin-right: 10px;
`;

interface Props {
  component: FC<any>;
  onPress?: () => void;
}

export const DocumentLink: FC<Props> = ({
  component: Component,
  children,
  onPress,
}) => (
  <Pressable onPress={onPress}>
    <Container>
      <IconContainer>
        <SvgIcon xml={icons.navIcons.feedLine} height={20} width={20} />
      </IconContainer>
      <Component
        style={{
          textDecorationLine: 'underline',
          color: '#005aff',
          textAlign: 'left',
        }}
      >
        {children}
      </Component>
    </Container>
  </Pressable>
);
