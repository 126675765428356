import Config from 'react-native-config';

/* eslint-disable import/order */
import carIcon from './car.svg';
import managerHomeActive from './manager-home-active.svg';
import managerHome from './manager-home.svg';

import managerScheduleActiveInline from './manager-schedule-active.svg';
import managerScheduleInline from './manager-schedule.svg';

import managerPastActiveInline from './manager-past-active.svg';
import managerPastInline from './manager-past.svg';
import QRIcon from './QR-icon.svg';
import torch from './torch.svg';

import managerRequestsActive from './manager-requests-active.svg';
import managerRequests from './manager-requests.svg';

import managerChatActive from './manager-chat-active.svg';
import managerChat from './manager-chat.svg';

import actionsMenu from './actions-menu.svg';
import addPhoto from './add-photo.svg';
import addToCalendar from './add-to-calendar.svg';
import admin from './admin.svg';
import airplane from './airplane.svg';
import arrowRight from './arrow-right.svg';
import availabilityEmptyState from './availability-empty-state.svg';
import availabilityIcon from './availability-icon.svg';
import availability from './availability.svg';
import availableWhite from './available-white.svg';
import birthdayIcon from './birthday-icon.svg';
import available from './available.svg';
import back from './back.svg';
import bellIcon from './bell-icon.svg';
import buttonRight from './button-right.svg';
import play from './play.svg';
import requestsEmpty from './calander-empty.svg';
import calendarWithDot from './calendar-with-dot.svg';
import calendar from './calendar.svg';
import calendar2 from './calendar2.svg';
import chatLineActive from './chat-active.svg';
import chatEmpty from './chat-icon.svg';
import checkedGreen from './checked-green.svg';
import checkmark from './checkmark.svg';
import chevronDown from './chevron-down.svg';
import chevronLeft from './chevron-left.svg';
import chevronRight from './chevron-right.svg';
import chevronUp from './chevron-up.svg';
import circleConfirmed from './circle-confirmed.svg';
import circleDenied from './circle-denied.svg';
import circlePending from './circle-pending.svg';
import circledChecked from './circled-checked.svg';
import circledCheckmarkFill from './circled-checkmark-fill.svg';
import circledCheckmarkFull from './circled-checkmark-full.svg';
import circledCheckmarkOutline from './circled-checkmark-outline.svg';
import circledCheckmarkStroke from './circled-checkmark-stroke.svg';
import circledOutline from './circled-outline.svg';
import clockStopwatch from './clock-stopwatch.svg';
import clock from './clock.svg';
import clock2 from './clock2.svg';
import comment from './comment.svg';

// compliance
import complianceDefaultFile from './compliance-default-file.svg';
import complianceExpiredWarning from './compliance-expired-warning.svg';
import complianceFileDelete from './compliance-file-delete.svg';
import complianceFileDownload from './compliance-file-download.svg';
import complianceFilePreview from './compliance-file-preview.svg';
import complianceIcon from './compliance-icon.svg';
import complianceUploadDocument from './compliance-upload-document.svg';
import complianceUploadField from './compliance-upload-field.svg';
import complianceUploadedFile from './compliance-uploaded-file.svg';

// docx
import complianceDOCXFile from './compliance-docx-file.svg';
import complianceDOCXFileExpired from './compliance-docx-file-expired.svg';
import complianceDOCXFileLarge from './compliance-docx-file-large.svg';
import complianceDOCXFileLargeExpired from './compliance-docx-large-expired.svg';

// pdf
import compliancePDFFile from './compliance-pdf-file.svg';
import compliancePDFFileExpired from './compliance-pdf-file-expired.svg';
import compliancePDFFileLarge from './compliance-pdf-file-large.svg';
import compliancePDFFileLargeExpired from './compliance-pdf-file-large-expired.svg';

// pptx
import compliancePPTXFile from './compliance-pptx-file.svg';
import compliancePPTXFileExpired from './compliance-pptx-file-expired.svg';
import compliancePPTXFileLarge from './compliance-pptx-file-large.svg';
import compliancePPTXFileLargeExpired from './compliance-pptx-file-large-expired.svg';

// xlsx
import complianceXLSXFile from './compliance-xlsx-file.svg';
import complianceXLSXFileExpired from './compliance-xlsx-file-expired.svg';
import complianceXLSXFileLarge from './compliance-xlsx-file-large.svg';
import complianceXLSXFileLargeExpired from './compliance-xlsx-file-large-expired.svg';

//timesheet approval
import timesheetDeclined from './timesheet-declined.svg';
import timesheetApproved from './timesheet-approved.svg';
import timesheetGeneralApproved from './timesheet-general-approved.svg';
import timesheetNoAnswer from './timehseet-no-answer.svg';

//compan-profile
import facebookIcon from './profile-facebook.svg';
import instagramIcon from './profile-instagram.svg';
import linkedInIcon from './profile-linkedIn.svg';
import websiteIcon from './profile-website.svg';
import generalLinkIcon from './profile-general.svg';

import contacts from './contacts.svg';
import datePicker from './date-picker.svg';
import directory from './directory.svg';
import done from './done.svg';
import employeePlaceholder from './employee-placeholder.svg';
import feedLineActive from './feed-active.svg';
import feedEmpty from './feed-empty.svg';
import feedLine from './feed-icon.svg';
import fileIcon from './file-icon.svg';
import filterIconNew from './filter-icon.svg';
import foodAndDrink from './food-and-drink.svg';
import gallery from './gallery.svg';
import group from './group.svg';
import hamburger from './hamburger.svg';
import heartLiked from './heart-liked.svg';
import heart from './heart.svg';
import helpCenter from './help-center.svg';
import history from './history.svg';
import homeLineActive from './home-active.svg';
import homeLine from './home-icon.svg';
import imgIcon from './img-icon.svg';
import incomeCalendar from './income-calendar.svg';
import incomeCardIcon from './income-card-icon.svg';
import incomeIcon2 from './income-icon-2.svg';
import incomeIcon from './income-icon.svg';
import income from './income.svg';
import jobCase from './job-case.svg';
import like from './like.svg';
import likeStroke from './like_stroke.svg';
import locationOutline from './location-outline.svg';
import locationPointerFull from './location-pointer-full.svg';
import locationPointer from './location-pointer.svg';
import location2 from './location2.svg';
import loginIcon from './login-icon.svg';
import logout from './logout.svg';
import menuIconWhite from './menu-icon-white.svg';
import menuIcon from './menu-icon.svg';
import moreLineActive from './more-active.svg';
import moreLine from './more-line.svg';
import endShift from './nd-shift.svg';
import newAvailabilityCardIcon from './new-availability-card-icon.svg';
import newAvailabilityIcon from './new-availability-icon.svg';
import notAvailableWhite from './not-available-white.svg';
import notAvailable from './not-available.svg';
import notes from './notes.svg';
import notification from './notification.svg';
import offersActive from './offers-active.svg';
import offersIcon from './offers-icon.svg';
import offers from './offers.svg';
import openShifts from './open-shifts.svg';
import pdfIcon from './pdf-icon.svg';

import pencilIcon from './pencil-icon.svg';
import peopleCalendar from './peopleCalendar.svg';
import personIcon from './person-icon.svg';
import person from './person.svg';
import phone from './phone.svg';
import planeEmptyState from './plane-empty-state.svg';
import planeGreen from './plane-green.svg';
import planeRed from './plane-red.svg';
import planeSuccess from './plane-success.svg';
import planeYellow from './plane-yellow.svg';
import plus from './plus.svg';
import plus2 from './plus2.svg';
import profile from './profile.svg';
import rateStarEmpty from './rate-star-empty.svg';
import rateStarFull from './rate-star-full.svg';
import rate from './rate.svg';
import releaseIcon from './release-icon.svg';
import requestsActive from './requests-active.svg';
import requestsIcon from './requests-icon.svg';
import requests from './requests.svg';
import scheduleActive from './schedule-active.svg';
import scheduleCardIcon from './schedule-card-icon.svg';
import scheduleIcon from './schedule-icon.svg';
import schedule from './schedule.svg';
import schedule2 from './schedule2.svg';
import searchIcon from './search-icon.svg';
import selectedCheckmark from './selected-checkmark.svg';
import send from './send.svg';
import settings from './settings.svg';
import signup from './signup.svg';
import starEmpty from './star-empty.svg';
import startShift from './start-shift.svg';
import suitcase from './suitcase.svg';
import swapIcon from './swap-icon.svg';
import swing from './swing.svg';
import tabletLogout from './tablet-logout.svg';
import tabletSettings from './tablet-settings.svg';
import team from './team.svg';
import timeclock from './timeclock.svg';
import timesheet from './timesheet.svg';
import trash from './trash.svg';
import ubeyaLogo from './ubeya-logo.svg';
import ubeyaPurpleSmall from './ubeya-purple-24.svg';
import ubeyaPurpleBig from './ubeya-purple-36.svg';
import ubeyaPurpleReverse from './ubeya-purple-reverse.svg';
import ubeyaRedSmall from './ubeya-red-24.svg';
import ubeyaRedBig from './ubeya-red-36.svg';
import ubeyaRedReverse from './ubeya-red-reverse.svg';
import unknownTypeIcon from './unknown-type-icon.svg';
import unselectedCheckmark from './unselected-checkmark.svg';
import employeeSlotBreakPlots from './employee-slot-break-plot.svg';

import verification from './verification.svg';
import xDisabled from './x-disabled.svg';
import xPrimary from './x-primary.svg';
import xSecondary from './x-secondary.svg';
import x from './x.svg';
import edit from './edit.svg';
import google from './google.svg';
import hourglass from './hourglass.svg';

import appLogo from './whitelabels/ubeya-logo.svg';
import constellationAppLogo from './whitelabels/constellation-logo.svg';

import accountLiveViewEmptyState from './account-live-view-empty-state.svg';
import projectLiveViewEmptyState from './project-live-view-empty-state.svg';

import breakPlay from './break-play.svg';
import breakPause from './break-pause.svg';

const navIcons = {
  managerHomeActive,
  managerHome,
  managerScheduleActiveInline,
  managerScheduleInline,
  managerPastActiveInline,
  managerPastInline,
  managerRequestsActive,
  managerRequests,
  managerChatActive,
  managerChat,
  homeLine,
  chatEmpty,
  feedLine,
  moreLine,
  homeLineActive,
  chatLineActive,
  feedLineActive,
  moreLineActive,
  offersIcon,
  offersActive,
  scheduleIcon,
  scheduleActive,
  menuIcon,
  menuIconWhite,
  notification,
  requestsActive,
  requestsIcon,
};

const actionIcons = {
  done,
  trash,
  back,
  rate,
  addPhoto,
  phone,
  actionsMenu,
  comment,
  heart,
  heartLiked,
  like,
  likeStroke,
  plus,
  edit,
  plus2,
  x,
  rateStarEmpty,
  rateStarFull,
  send,
  hamburger,
  arrowRight,
  xPrimary,
  xSecondary,
  xDisabled,
  checkmark,
  filterIconNew,
  torch,
  breakPlay,
  breakPause,
};

const timeclockIcons = {
  startShift,
  endShift,
  foodAndDrink,
};

const companyProfileIcon = {
  facebookIcon,
  instagramIcon,
  linkedInIcon,
  websiteIcon,
  generalLinkIcon,
};

const cardIcons = {
  timeclock,
  chevronRight,
  chevronLeft,
  chevronUp,
  chevronDown,
  buttonRight,
  availability,
  schedule,
  openShifts,
  income,
  scheduleCardIcon,
  incomeCardIcon,
  settings,
  contacts,
  logout,
  loginIcon,
  helpCenter,
  group,
  requestsEmpty,
};

const symbolIcons = {
  starEmpty,
  available,
  availableWhite,
  notAvailable,
  notAvailableWhite,
  location2,
  schedule2,
  notes,
  calendar,
  calendar2,
  person,
  pencilIcon,
  group,
  feedEmpty,
  planeYellow,
  planeRed,
  planeGreen,
  planeEmptyState,
  planeSuccess,
  requests,
  bellIcon,
  checkedGreen,
  swapIcon,
  releaseIcon,
  searchIcon,
  gallery,
  hourglass,
};

const timesheetApprovalIcons = {
  timesheetDeclined,
  timesheetGeneralApproved,
  timesheetApproved,
  timesheetNoAnswer,
};

const complianceIcons = {
  complianceUploadField,
  complianceXLSXFile,
  compliancePPTXFile,
  complianceDOCXFile,
  complianceUploadDocument,
  complianceDefaultFile,
  complianceUploadedFile,
  compliancePDFFile,
  complianceFileDelete,
  complianceFilePreview,
  complianceFileDownload,
  complianceExpiredWarning,
  complianceDOCXFileLarge,
  compliancePPTXFileLarge,
  compliancePDFFileLarge,
  complianceXLSXFileLarge,
  complianceDOCXFileExpired,
  complianceDOCXFileLargeExpired,
  compliancePDFFileExpired,
  compliancePDFFileLargeExpired,
  compliancePPTXFileExpired,
  compliancePPTXFileLargeExpired,
  complianceXLSXFileExpired,
  complianceXLSXFileLargeExpired,
};

const inputFieldsIcons = {
  datePicker,
};
const uiIcons = {
  carIcon,
  clock,
  clock2,
  clockStopwatch,
  history,
  calendar,
  calendarWithDot,
  directory,
  requests,
  complianceIcon,
  birthdayIcon,
  admin,
  profile,
  team,
  QRIcon,
  settings,
  offers,
  selectedCheckmark,
  unselectedCheckmark,
  signup,
  play,
  verification,
  personIcon,
  addToCalendar,
  locationPointer,
  locationPointerFull,
  jobCase,
  locationOutline,
  availabilityIcon,
  newAvailabilityCardIcon,
  newAvailabilityIcon,
  circledChecked,
  circledCheckmarkFull,
  circledCheckmarkOutline,
  circledOutline,
  circledCheckmarkFill,
  circledCheckmarkStroke,
  circleDenied,
  circleConfirmed,
  circlePending,
  ubeyaPurpleBig,
  ubeyaRedBig,
  ubeyaPurpleSmall,
  ubeyaRedSmall,
  ubeyaRedReverse,
  ubeyaPurpleReverse,
  incomeCalendar,
  incomeIcon,
  incomeIcon2,
  employeePlaceholder,
  unknownTypeIcon,
  employeeSlotBreakPlots,
  pdfIcon,
  imgIcon,
  fileIcon,
  google,
};
const emptyStates = {
  availabilityEmptyState,
  accountLiveViewEmptyState,
  projectLiveViewEmptyState,
};

const people = {
  swing,
  calendar: peopleCalendar,
  suitcase,
  airplane,
};

const drawerIcons = {
  timesheet,
  tabletSettings,
  tabletLogout,
};

const whiteLabelLogos = {
  logo: Config.APP_NAME === 'Constellation' ? constellationAppLogo : appLogo,
  sideBarLogo:
    Config.APP_NAME === 'Constellation' ? constellationAppLogo : appLogo,
};

export {
  uiIcons,
  complianceIcons,
  navIcons,
  actionIcons,
  companyProfileIcon,
  timeclockIcons,
  cardIcons,
  symbolIcons,
  inputFieldsIcons,
  emptyStates,
  people,
  drawerIcons,
  timesheetApprovalIcons,
  whiteLabelLogos,
};
