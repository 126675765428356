import React, { FC, MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Platform, RefreshControl, SafeAreaView, ScrollView, StyleProp, ViewStyle } from 'react-native';
import { useTheme } from 'styled-components/native';
import { CustomLoaderWrapped, MainLoader } from '../Loaders';

interface Props {
  onRefresh?: () => Promise<unknown>;
  scrollable?: boolean;
  isLoading?: boolean;
  beforeLeave?: (cb: () => void) => void;
  scrollRef?: MutableRefObject<ScrollView>;
  contentContainerStyle?: StyleProp<ViewStyle>;
}

export const BaseScreen: FC<Props> = ({ children, onRefresh, isLoading = false, beforeLeave, scrollRef }) => {
  const { addListener, dispatch } = useNavigation();
  const [refreshing, setRefreshing] = useState(false);
  const theme = useTheme();

  const onRefreshInternal = useCallback(async () => {
    setRefreshing(true);

    if (onRefresh) {
      await onRefresh();
      setRefreshing(false);
    } else {
      setTimeout(() => {
        setRefreshing(false);
      }, 2000);
    }
  }, [onRefresh]);

  useEffect(() => {
    if (!beforeLeave) {
      return;
    }

    return addListener('beforeRemove', (e) => {
      e.preventDefault();

      beforeLeave(() => {
        dispatch(e.data.action);
      });
    });
  }, [addListener, beforeLeave, dispatch]);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
        ...(Platform.OS === 'web' && { height: '100vh' }),
      }}>
      <ScrollView
        ref={scrollRef}
        contentContainerStyle={{ flexGrow: 1 }}
        nestedScrollEnabled
        refreshControl={
          onRefresh ? <RefreshControl refreshing={refreshing} onRefresh={onRefreshInternal} /> : undefined
          // eslint-disable-next-line react/jsx-closing-bracket-location
        }>
        {isLoading && <CustomLoaderWrapped />}
        {!isLoading && children}
      </ScrollView>
    </SafeAreaView>
  );
};
