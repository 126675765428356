import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useClockView } from './useClockView';
import useInterval from './useInterval';

const useDuration = (anchor: Date, initialValue: Date = moment().toDate()) => {
  const [currentTime, setCurrentTime] = useState(() => moment(initialValue));

  const setTime = useCallback(() => {
    setCurrentTime((prev) => moment(prev).add(1, 'seconds'));
  }, []);

  const { start, stop } = useInterval(setTime, 1000);

  const rawSeconds = useMemo(() => currentTime.diff(moment(anchor)), [anchor, currentTime]);

  const seconds = useMemo(() => Math.floor(moment.duration(Math.abs(rawSeconds)).asSeconds()), [anchor, currentTime]);

  const isFuture = useMemo(() => rawSeconds <= 0, [rawSeconds]);

  const clock = useClockView({
    seconds,
  });

  return { start, stop, seconds, clock, isFuture };
};

export default useDuration;
