import { icons, lotties } from '@violetta/ubeya/assets';
import { BaseView, FlexCenter, Lottie, SvgIcon } from '@violetta/ubeya/ui';
import React, { FC } from 'react';
import { ActivityIndicator, Platform, useWindowDimensions } from 'react-native';
import styled from 'styled-components/native';
import { colors } from './style/theme';
import { config } from './modules/main/appConfig';

const Container = styled(FlexCenter)`
  flex-grow: 1;
`;

const Space = styled(BaseView)`
  height: 30px;
`;
const ContainerCompass = styled(BaseView)`
  flex: 1;
  align-items: center;
  margin-top: -700px;
  height: 0px;
`;

export const SplashScreen: FC<{}> = () => {
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  return (
    <Container>
      {config.appName === 'Constellation' ? (
        <ContainerCompass>
          <SvgIcon
            xml={icons.whiteLabelLogos.logo}
            width={500}
            height={Platform.OS === 'web' ? 35 : 90}
          />
          <Space />
          <ActivityIndicator color={colors.primary} size="large" />
        </ContainerCompass>
      ) : (
        <Lottie
          name={lotties.mainLoader}
          autoPlay
          width={windowWidth}
          height={windowHeight}
          loop={false}
        />
      )}
    </Container>
  );
};
