import { IFieldOption } from './IFieldOption';

export enum FieldTypes {
  FIELD_TYPE_TEXT = 1,
  FIELD_TYPE_DATE = 2,
  FIELD_TYPE_OPTIONS = 3,
  FIELD_TYPE_READONLY_IMAGE = 4,
  FIELD_TYPE_READONLY_TITLE = 5,
  FIELD_TYPE_READONLY_DOCUMENT = 6,
  FIELD_TYPE_BOOLEAN = 7,
  FIELD_TYPE_ADDRESS = 8,
  FIELD_TYPE_DOCUMENT = 9,
  FIELD_TYPE_SIGNATURE = 10,
  FIELD_TYPE_NUMBER = 11,
}

export type FieldValue = string | number[] | Date | boolean;

export type FieldType = 'Regular' | 'Payroll';

export interface IField {
  id: number;
  name: string;
  type?: FieldType;
  slug?: string;
  fieldTypeId: FieldTypes;
  isRequired: boolean;
  //   isReadOnly: boolean;
  allowMultiple?: boolean;
  isMultiline?: boolean;
  isReadOnly?: boolean;
  options?: IFieldOption[];
  value?: FieldValue;
  metadata?: any;
}
