import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import {
  useAccounts,
  useBookings,
  useBranches,
  useLabels,
  useLocations,
  usePositions,
  useTimesheets,
} from '@violetta/ubeya/entities';
import {
  BaseView,
  CustomLoaderWrapped,
  FlexColumnDirection,
} from '@violetta/ubeya/ui';
import { openMap } from '@violetta/ubeya/utils';
import { Image as BaseImage, Pressable, RefreshControl } from 'react-native';
import { ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { AppContext } from '../../main/AppContext';
import { BookingSummary } from '../components';
import { BookingActions } from '../components/BookingActions';
import { BookingBreakPlots } from '../components/BookingBreakPlots';
import { BookingDetails } from '../components/BookingDetails';
import { BookingSequences } from '../components/BookingSequences';
import { BookingFooter } from './BookingFooter';

const Wrapper = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  display: flex;
  flex: 1;
`;

const BookingBreakPlotsContainer = styled(FlexColumnDirection)`
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
  border-bottom-width: 1px;
  padding: 12px 27px;
  margin-right: 20px;
`;

const Image = styled(BaseImage)`
  height: 143px;
`;

const ImageContainer = styled(BaseView)``;

interface Props {}

type ParamList = {
  params: {
    bookingId: number;
  };
};

export const BookingScreen: FC<Props> = () => {
  const {
    bookings: { selectBooking, selectedBooking },
  } = useContext(AppContext);

  const { goBack } = useNavigation();

  const {
    params: { bookingId: bookingIdParam },
  } = useRoute<RouteProp<ParamList, 'params'>>();
  const bookingId = bookingIdParam && Number(bookingIdParam);
  const { bottom } = useSafeAreaInsets();
  const isFocused = useIsFocused();
  const { isLoading: isLoadingLabels } = useLabels();
  const { isLoading: isLoadingPositions } = usePositions();
  const {
    timesheets,
    isLoading: isLoadingTimesheets,
    refetch: refetchTimesheets,
  } = useTimesheets();
  const {
    mappedBookings,
    isLoading: isLoadingBookings,
    isStale,
    markAsRead,
    isMarkReadError,
    isMarkReadLoading,
    refetch,
  } = useBookings();
  const { mappedAccounts } = useAccounts();
  const { mappedBranches } = useBranches();
  const { mappedLocations } = useLocations();

  const isLoading =
    isLoadingBookings ||
    isLoadingLabels ||
    isLoadingPositions ||
    isLoadingTimesheets;

  useEffect(() => {
    if (
      !selectedBooking?.isRead &&
      selectedBooking?.project?.id &&
      !isMarkReadError &&
      !isMarkReadLoading
    ) {
      markAsRead({ projectId: selectedBooking.project.id, isRead: true });
    }
  }, [
    isMarkReadError,
    isMarkReadLoading,
    markAsRead,
    selectedBooking?.isRead,
    selectedBooking?.project.id,
  ]);

  useEffect(() => {
    let timeout;

    if (isFocused && !isStale && !isLoading && !mappedBookings[bookingId]?.id) {
      timeout = setTimeout(() => {
        if (isFocused && !isLoading && !mappedBookings[bookingId]?.id) {
          goBack();
        }
        selectBooking(mappedBookings[bookingId]);
      }, 1000);
    }
    selectBooking(mappedBookings[bookingId]);
    if (
      bookingId &&
      mappedBookings[bookingId] &&
      selectedBooking?.id !== bookingId
    ) {
      selectBooking(mappedBookings[bookingId]);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    mappedBookings,
    isLoading,
    goBack,
    bookingId,
    selectBooking,
    selectedBooking,
    isStale,
    isFocused,
  ]);

  const timesheet = useMemo(
    () => timesheets.find(({ shift }) => shift && shift.id === bookingId),
    [timesheets, bookingId]
  );

  const locationId = useMemo(() => {
    const locationId = mappedBookings[bookingId]?.locationId;
    const projectLocationId = mappedBookings[bookingId]?.project?.locationId;

    return locationId || projectLocationId || null;
  }, [mappedBookings, bookingId]);

  const projectId = mappedBookings[bookingId]?.project?.id;
  const branchId = mappedBookings[bookingId]?.project?.branchId;

  const currency =
    branchId &&
    mappedBranches?.[branchId]?.accountId &&
    mappedAccounts?.[mappedBranches?.[branchId]?.accountId]?.currency?.symbol;

  const confirmation = mappedBookings[bookingId]?.confirmation;

  const location = useMemo(
    () => (locationId ? mappedLocations[locationId] : null),
    [locationId, mappedLocations]
  );

  const shouldRender = useMemo(
    () => !isLoading && mappedBookings[bookingId]?.id,
    [bookingId, isLoading, mappedBookings]
  );
  const shouldRenderFooter = useMemo(
    () =>
      shouldRender &&
      (timesheet || (confirmation && !confirmation.isConfirmed)),
    [confirmation, shouldRender, timesheet]
  );

  const config = useMemo(
    () => (branchId ? mappedBranches[branchId]?.schedulingConfig : null),
    [branchId, mappedBranches]
  );
  const showBreaksNotice = useMemo(
    () => config?.showBreaksNotice,
    [config?.showBreaksNotice]
  );

  const isShowBookingWage = useMemo(
    () => config && config.isShowBookingWage,
    [config]
  );

  const openLocationMap = useCallback(() => openMap(location), [location]);

  return (
    <Wrapper>
      {!shouldRender ? (
        <CustomLoaderWrapped />
      ) : (
        <ScrollView
          contentContainerStyle={{ paddingBottom: bottom }}
          refreshControl={
            <RefreshControl
              onRefresh={() => {
                refetch();
                refetchTimesheets();
              }}
              refreshing={isLoadingBookings}
            />
          }
        >
          {location?.image && (
            <ImageContainer as={Pressable} onPress={openLocationMap}>
              <Image
                source={{ uri: location.image }}
                resizeMode="cover"
                resizeMethod="scale"
              />
            </ImageContainer>
          )}

          <BookingSummary
            locationId={locationId}
            timesheet={timesheet}
            booking={selectedBooking}
            showBreaksNotice={showBreaksNotice}
            openLocationMap={openLocationMap}
            isShowBookingWage={isShowBookingWage}
            currency={currency}
          />

          {selectedBooking?.employeeSlot?.employeeSlotBreakPlots?.length >
            0 && (
            <BookingBreakPlotsContainer>
              <BookingBreakPlots
                employeeSlotBreakPlots={
                  selectedBooking?.employeeSlot?.employeeSlotBreakPlots || null
                }
              />
            </BookingBreakPlotsContainer>
          )}

          <BookingSequences booking={selectedBooking} />
          <BookingDetails booking={selectedBooking} />
          <BookingActions booking={selectedBooking} />
        </ScrollView>
      )}
      {shouldRenderFooter && (
        <BookingFooter
          projectId={projectId}
          timesheet={timesheet}
          confirmation={confirmation}
        />
      )}
    </Wrapper>
  );
};
