import React, { FC, useCallback, useMemo } from 'react';
import moment from 'moment';
import { CalendarList, DateCallbackHandler } from 'react-native-calendars';

type ValueParam = {
  start: Date | null;
  end: Date | null;
};

interface Props {
  onSelected?: ({ start, end }: ValueParam) => void;
  value?: ValueParam;
  color?: string;
}

export const DateRangePicker: FC<Props> = ({
  onSelected,
  value,
  color = 'blue',
}) => {
  const { end, start } = useMemo(
    () => ({
      start: value?.start ? moment(value.start) : null,
      end: value?.end ? moment(value.end) : null,
    }),
    [value?.start, value?.end]
  );

  const markedDates = useMemo(() => {
    if (start === null || end === null) {
      if (start !== null && end === null) {
        return {
          [start.format('YYYY-MM-DD')]: {
            startingDay: true,
            endingDay: false,
            selected: true,
            color,
          },
        };
      }
      return {};
    }

    const daysDiff = end.diff(start, 'days');
    const diffStartDate = moment(start).add(1, 'days');

    const days =
      daysDiff > 0
        ? [...new Array(daysDiff)].map((_, idx) =>
            moment(diffStartDate).add(idx, 'days')
          )
        : [];

    return {
      [start.format('YYYY-MM-DD')]: {
        startingDay: true,
        endingDay: false,
        selected: true,
        color,
      },
      ...days.reduce(
        (output, day) => ({
          ...output,
          [day.format('YYYY-MM-DD')]: {
            selected: true,
            color,
          },
        }),
        {}
      ),
      [end.format('YYYY-MM-DD')]: {
        startingDay: false,
        endingDay: true,
        selected: true,
        color,
      },
    };
  }, [start, end, color]);

  const onDayPress = useCallback<DateCallbackHandler>(
    (selectedDate) => {
      const { dateString } = selectedDate;
      const date = moment(dateString);

      const newStartDate =
        start === null || date.isBefore(start) ? date : start;
      const newEndDate =
        start !== null && date.isAfter(newStartDate)
          ? date
          : date.isBefore(start)
          ? null
          : end;

      onSelected?.({
        end: newEndDate?.toDate() || null,
        start: newStartDate?.toDate() || null,
      });
    },
    [end, onSelected, start]
  );
  return (
    <CalendarList
      theme={{ textDayFontSize: 18 }}
      onDayPress={onDayPress}
      monthFormat="MMMM yyyy"
      hideArrows
      hideExtraDays={false}
      disableMonthChange={false}
      onDayLongPress={() => {}}
      onMonthChange={() => {}}
      firstDay={1}
      hideDayNames={false}
      showWeekNumbers={false}
      onPressArrowLeft={(subtractMonth) => subtractMonth()}
      onPressArrowRight={(addMonth) => addMonth()}
      disableArrowLeft
      disableArrowRight
      markedDates={markedDates}
      markingType="period"
      value={value}
    />
  );
};
