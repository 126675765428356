import React, { FC, useCallback, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FlexRow,
  FlexRowCenter,
  H6Medium,
  SvgIcon,
  FlexColumnDirection,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { TimeAvailabilityItemChip } from './TimeAvailabilityItemChip';
import { TimeOffItemChip } from './TimeOffItemChip';

interface Props {
  openOptionsModal: (item: any) => void;
  onDeletePeriod: (id: number, startTime: Date, endTime: Date) => void;
  openAvailabilityModal: (item: any) => void;
  item: any;
  isSendingEnabled?: boolean;
}

const Container = styled(BaseView)``;

const DayStrip = styled<{ today: boolean }>(BaseView)`
  background-color: ${({ theme, today }) =>
    today ? theme.colors.primaryLight3 : theme.colors.primaryLight2};
  padding: 8px 16px;
`;
const DayTitle = styled<{ today: boolean }>(H6Medium)`
  font-weight: 500;
  color: ${({ theme, today }) =>
    today ? theme.colors.primary : theme.colors.textPrimary};
`;

const EmptyTitle = styled(H6Medium)`
  flex: 1;
  font-weight: 500;
`;

const PlusButton = styled(FlexRowCenter)`
  height: 100%;
  align-items: center;
  justify-content: center;
`;
const Chips = styled(FlexColumnDirection)`
  align-items: flex-start;
  flex: 1;
  padding: 8px 0;
`;

const Content = styled(FlexRow)`
  align-items: center;
  min-height: 60px;
  padding: 0 16px;
`;

const LeftBorder = styled(BaseView)`
  border-radius: 2px;
  height: 20px;
  width: 3px;
  background-color: #46cc88;
`;

const BorderContainer = styled(BaseView)`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IconContainer = styled(BaseView)`
  display: flex;
  justify-content: center;
  padding: 10px 10px;
  height: 40px;
`;

export const TimeAvailabilityDay: FC<Props> = ({
  openOptionsModal,
  onDeletePeriod,
  item,
  isSendingEnabled,
  openAvailabilityModal,
}) => {
  const { t } = useTranslation();
  const { date, periods } = item;
  const today = useMemo(() => moment(date).isSame(moment(), 'date'), [date]);

  //   const onPreferToWork = useCallback(() => {
  //     setSelectedDay(item);
  //     showPreferToWork();
  //   }, [item, setSelectedDay, showPreferToWork]);

  return (
    <Container>
      <DayStrip today={today}>
        <DayTitle today={today}>
          {moment(date).format('ddd L').toUpperCase()}{' '}
          {today ? ` (${t('today')})` : ''}
        </DayTitle>
      </DayStrip>

      <Content>
        {(periods || []).length === 0 ? (
          <>
            <BorderContainer>
              <LeftBorder />
            </BorderContainer>
            <EmptyTitle>{t('timeAvailabilityEmptyDay')}</EmptyTitle>
          </>
        ) : (
          <Chips>
            {periods.map(
              ({
                id,
                startTime,
                endTime,
                status,
                type,
                jobTypeId,
                requestStatus,
              }) => (
                <BaseView key={id}>
                  {type === 'availability' ? (
                    <TimeAvailabilityItemChip
                      key={id}
                      id={id}
                      startTime={startTime}
                      endTime={endTime}
                      onDeletePeriod={onDeletePeriod}
                      status={status}
                      isEditEnabled={isSendingEnabled}
                    />
                  ) : (
                    <TimeOffItemChip
                      requestStatus={requestStatus}
                      jobTypeId={jobTypeId}
                    />
                  )}
                </BaseView>
              )
            )}
          </Chips>
        )}
        {isSendingEnabled && (
          <PlusButton>
            <IconContainer
              as={Pressable}
              onPress={
                isSendingEnabled
                  ? () => openAvailabilityModal({ item, type: 0 })
                  : undefined
              }
            >
              <SvgIcon
                xml={icons.actionIcons.likeStroke}
                height={20}
                width={20}
              />
            </IconContainer>
            <IconContainer
              as={Pressable}
              onPress={
                isSendingEnabled
                  ? () => openOptionsModal({ item, type: 1 })
                  : undefined
              }
            >
              <SvgIcon xml={icons.actionIcons.plus2} height={15} width={18} />
            </IconContainer>
          </PlusButton>
        )}
      </Content>
    </Container>
  );
};
