import React, { FC, useMemo } from 'react';
import { IComment, IEmployee } from '@violetta/ubeya/entities';
import {
  BaseImage,
  FlexColumnDirection,
  FlexColumnEvenly,
  FlexRow,
  H6Medium,
  H6Regular,
  H7Regular,
} from '@violetta/ubeya/ui';

import moment from 'moment';
import styled from 'styled-components/native';

const Container = styled(FlexRow)`
  background-color: ${({ theme }) => theme.colors.surface};
  margin-bottom: 15px;
`;

const Image = styled(BaseImage)`
  height: 36px;
  width: 36px;
  border-radius: 50px;
`;

const CommentContainer = styled(FlexColumnDirection)`
  margin-left: 10px;
`;

const Comment = styled(FlexColumnEvenly)`
  background-color: #edf1f9;
  border-radius: 5px;
  min-height: 56px;
  align-items: flex-start;
  padding: 4px 10px;
`;

const EmployeeName = styled(H6Medium)`
  font-weight: 500;
`;

const Message = styled(H6Regular)``;

const Timestamp = styled(H7Regular)`
  color: ${({ theme }) => theme.colors.gray2};
  margin-top: 4px;
`;

interface Props {
  comment: IComment;
  employee: IEmployee;
}

export const PostComment: FC<Props> = ({
  comment: { comment, createdAt },
  employee: { image, firstName, lastName },
}) => {
  const ago = useMemo(() => moment(createdAt).fromNow(), [createdAt]);

  return (
    <Container>
      <Image source={{ uri: image }} />
      <CommentContainer>
        <Comment>
          <EmployeeName>
            {firstName} {lastName}
          </EmployeeName>
          <Message>{comment}</Message>
        </Comment>
        <Timestamp>{ago}</Timestamp>
      </CommentContainer>
    </Container>
  );
};
