import React, { FC, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { IPeriod } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  FlexColumn,
  FlexColumnCenter,
  H3Medium,
  SvgIcon,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';
import { theme } from '../../../../style/theme';

const TitleContainer = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
  margin-top: -50px;
`;

const Container = styled(FlexColumnCenter)`
  flex: 1;
`;

const AvailabilityTitle = styled(H3Medium)`
  font-weight: 500;
  margin-bottom: 7px;
`;
const Period = styled(H3Medium)`
  font-weight: 500;
`;

interface Props {
  onStart?: () => void;
  period: IPeriod;
}

export const AvailabilityStartScreen: FC<Props> = ({ period }) => {
  const { t } = useTranslation();

  const [startDate, endDate] = useMemo(() => {
    const startDate = moment(period.dates[0].date);
    const endDate = moment(period.dates[period.dates.length - 1].date);

    return [startDate, endDate] as const;
  }, [period.dates]);

  return (
    <Container>
      <TitleContainer>
        <SvgIcon
          xml={icons.emptyStates.availabilityEmptyState}
          height={200}
          width={200}
          color={theme.colors.green1}
        />
        <AvailabilityTitle>{t('availabilityTitle')}</AvailabilityTitle>
        <Period>
          {startDate.format('DD MMM')}-{endDate.format('DD MMM')}
        </Period>
      </TitleContainer>
    </Container>
  );
};
