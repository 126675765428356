import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray, useOptimisticMutation } from '@violetta/ubeya/utils';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { IPeriod } from '../entities/IPeriod';

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const periods = (data || [])
      .sort((a, b) =>
        moment(a.dates[0].date, 'YYYY-MM-DD').isSameOrAfter(
          moment(b.dates[0].date, 'YYYY-MM-DD')
        )
          ? 1
          : -1
      )
      .map((period) => ({
        ...period,
        dates: period.dates.map((dateObj) => ({
          ...dateObj,
          date: moment(dateObj.date, 'YYYY-MM-DD').toDate(),
        })),
      }));

    const mappedPeriods = mappedArray(periods) as { [key: number]: IPeriod };

    const mappedPeriodsByBranch = periods.reduce(
      (carry, period) => {
        if (!carry[period.branchId]) {
          carry[period.branchId] = [];
        }
        carry[period.branchId].push(period);
        return carry;
      },

      {} as { [key: number]: IPeriod[] }
    );
    for (const key of Object.keys(mappedPeriodsByBranch)) {
      mappedPeriodsByBranch[key] = (mappedPeriodsByBranch[key] || []).sort(
        (a, b) =>
          moment(a.dates[0].date, 'YYYY-MM-DD').isSameOrAfter(
            moment(b.dates[0].date, 'YYYY-MM-DD')
          )
            ? 1
            : -1
      );
    }

    return { periods, mappedPeriods, mappedPeriodsByBranch };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const usePeriods = () => {
  const { data: userData } = useUser();

  const storeKey = useMemo(() => ['periods'], []);

  const { isPending, data } = useQuery<{ data: IPeriod[] }>({
    queryKey: storeKey,
    queryFn: () => api.getPeriods(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const {
    periods = [],
    mappedPeriods = {},
    mappedPeriodsByBranch = {},
  } = data || {};

  const { mutateAsync: updatePeriod, isPending: isUpdatingPeriod } =
    useOptimisticMutation<
      { data: IPeriod[] },
      { id: number; seen?: boolean },
      any,
      any
    >(
      storeKey,
      api.updatePeriod,
      ({ previousData, payload }) => ({
        ...previousData,
        data: previousData.data.map((item) =>
          item.id !== payload.id ? item : { ...item, ...payload }
        ),
      }),
      {
        refetchOnSuccess: false,
      }
    );

  return {
    isLoading: isPending,
    periods,
    mappedPeriods,
    mappedPeriodsByBranch,
    updatePeriod,
    isUpdatingPeriod,
  };
};
