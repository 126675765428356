import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  useBranches,
  useLocalAvailabilities,
  usePeriods,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  CustomButton,
  FlexColumnBetween,
  FlexColumnDirection,
  FlexRow,
  FlexRowEvenly,
  XIcon,
  VIcon,
  H4Bold,
} from '@violetta/ubeya/ui';
import { Alert } from '@violetta/ubeya/alert';
import moment from 'moment';
import { Platform, SafeAreaView } from 'react-native';
import styled from 'styled-components/native';
import { colors, theme } from '../../../style/theme';
import { AppContext } from '../../main/AppContext';
import { LabelCard } from '../components/LabelCard';
import { WeekList } from '../components/WeekList';

const Container = styled(FlexColumnBetween)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  padding: 15px 20px;
  z-index: 0;
`;

const LabelsContainer = styled(FlexColumnDirection)`
  width: 100%;
`;
const AllLabelsContainer = styled(FlexRow)`
  width: 100%;
  margin-left: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
`;

const AllLabelsText = styled(H4Bold)`
  flex: 1;
  text-align: left;
  font-size: 18px;
`;

const Footer = styled(FlexRowEvenly)``;
const ButtonPrimaryContainer = styled(FlexRow)`
  flex: 2;
`;
const ButtonSecondaryContainer = styled(FlexRow)`
  margin-right: 25px;
  flex: 1;
`;

const ScrollView = styled.ScrollView``;

interface Props {
  title: string;
  date: Date;
}

export const AvailabilityPeriodScreen: FC<Props> = () => {
  const { goBack } = useNavigation();
  const { t } = useTranslation();
  const { mappedPeriods } = usePeriods();
  const { mappedBranches } = useBranches();
  const {
    availability: { selectDay, selectedDay, periodId },
  } = useContext(AppContext);

  const {
    localAvailabilities,
    addLocalAvailability: add,
    updateLocalAvailability: edit,
  } = useLocalAvailabilities({ periodId });

  const period = useMemo(
    () => mappedPeriods[periodId],
    [periodId, mappedPeriods]
  );

  const periodMarkAllDay = useMemo(
    () =>
      period?.branchId
        ? mappedBranches[period.branchId]?.schedulingConfig?.periodMarkAllDay
        : false,
    [mappedBranches, period.branchId]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showWarningAlert = useCallback(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      new Promise<void>((resolve, reject) => {
        Alert.alert(
          t('didntFillAllDayPromptTitle'),
          t('didntFillAllDayPromptBody'),
          [
            {
              type: 'confirm',
              text: t('ok'),
              onPress: () => {
                resolve();
              },
            },
          ],
          { cancelable: false }
        );
      }),
    [t]
  );

  const [dates, currentIdx] = useMemo(() => {
    const mDate = moment(selectedDay);

    const currentIdx = period.dates.findIndex(({ date }) =>
      moment(date).isSame(mDate, 'date')
    );

    return [period.dates, currentIdx] as const;
  }, [period, selectedDay]);

  const lastAvailabilityDayIndex = useMemo(
    () =>
      dates.findIndex(
        ({ labels }, idx) => labels.length > 0 && idx > currentIdx
      ),
    [dates, currentIdx]
  );

  const isDayFilled = useMemo(() => {
    const availabilities = localAvailabilities.filter(({ date }) =>
      moment(date).isSame(moment(selectedDay), 'date')
    );

    return !dates?.[currentIdx]?.labels?.some(
      (labelId) => !availabilities.find(({ labelId: lId }) => lId === labelId)
    );
  }, [selectedDay, localAvailabilities, dates, currentIdx]);

  const finish = useCallback(async () => {
    if (!isDayFilled) {
      await showWarningAlert();
      return;
    }
    goBack();
  }, [goBack, isDayFilled, showWarningAlert]);

  const onChange = useCallback(
    async (type: 'next' | 'prev') => {
      if (currentIdx === -1) {
        return;
      }

      if (!isDayFilled) {
        await showWarningAlert();
        return;
      }

      const nextIdx =
        type === 'next'
          ? lastAvailabilityDayIndex
          : currentIdx -
            1 -
            dates
              .slice(0, currentIdx)
              .reverse()
              .findIndex(({ labels }) => labels.length > 0);

      if (nextIdx > dates.length - 1 || nextIdx < 0) {
        return;
      }

      selectDay(dates[nextIdx].date);
    },
    [
      dates,
      selectDay,
      currentIdx,
      lastAvailabilityDayIndex,
      isDayFilled,
      showWarningAlert,
    ]
  );

  const availabilities = useMemo(
    () =>
      (localAvailabilities || []).filter(({ date }) =>
        moment(date).isSame(moment(dates[currentIdx].date), 'days')
      ),
    [localAvailabilities, dates, currentIdx]
  );

  const onVPress = useCallback(() => {
    const toEdit = availabilities.filter(
      ({ labelId }) => dates[currentIdx].labels.indexOf(labelId) > -1
    );
    const toAdd = dates[currentIdx].labels.filter(
      (lId: number) => !toEdit.find(({ labelId }) => lId === labelId)
    );

    toAdd.forEach((labelId) => {
      add({
        branchId: period.branchId,
        accountId: mappedBranches?.[period.branchId]?.accountId,
        date: selectedDay,
        labelId,
        status: 1,
      });
    });

    toEdit.forEach(({ id }) => {
      edit({
        id,
        status: 1,
      });
    });
  }, [
    add,
    availabilities,
    currentIdx,
    dates,
    edit,
    mappedBranches,
    period.branchId,
    selectedDay,
  ]);

  const onXPress = useCallback(() => {
    const toEdit = availabilities.filter(
      ({ labelId }) => dates[currentIdx].labels.indexOf(labelId) > -1
    );
    const toAdd = dates[currentIdx].labels.filter(
      (lId: number) => !toEdit.find(({ labelId }) => lId === labelId)
    );

    toAdd.forEach((labelId) => {
      add({
        branchId: period.branchId,
        accountId: mappedBranches?.[period.branchId]?.accountId,
        date: selectedDay,
        labelId,
        status: 0,
      });
    });

    toEdit.forEach(({ id }) => {
      edit({
        id,
        status: 0,
      });
    });
  }, [
    add,
    availabilities,
    currentIdx,
    dates,
    edit,
    mappedBranches,
    period.branchId,
    selectedDay,
  ]);

  const allVPressed = useMemo(
    () =>
      (dates[currentIdx]?.labels || []).every(
        (labelId) =>
          !!availabilities.find(
            ({ labelId: availabilityLabelId, status }) =>
              availabilityLabelId === labelId && status === 1
          )
      ),
    [availabilities, currentIdx, dates]
  );

  const allXPressed = useMemo(
    () =>
      (dates[currentIdx]?.labels || []).every(
        (labelId) =>
          !!availabilities.find(
            ({ labelId: availabilityLabelId, status }) =>
              availabilityLabelId === labelId && status === 0
          )
      ),
    [availabilities, currentIdx, dates]
  );

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: theme.colors.background }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <WeekList />
        <Container>
          {periodMarkAllDay ? (
            <AllLabelsContainer>
              <AllLabelsText>{t('markAllAvailableLabelsInDay')}</AllLabelsText>
              <XIcon
                style={{ marginRight: 20 }}
                onPress={onXPress}
                pressed={allXPressed}
              />
              <VIcon
                style={{ marginRight: 20 }}
                onPress={onVPress}
                pressed={allVPressed}
              />
            </AllLabelsContainer>
          ) : (
            <LabelsContainer>
              {dates[currentIdx]?.labels?.map((labelId, idx) => (
                <LabelCard key={idx} labelId={labelId} />
              ))}
            </LabelsContainer>
          )}
          <Footer>
            <ButtonSecondaryContainer>
              <CustomButton
                background={colors.surface}
                disabledBackground={colors.surface}
                pressedBackground={colors.surface}
                titleColor={colors.gray13}
                borderColor={colors.gray13}
                title={t('back')}
                onPress={() => onChange('prev')}
              />
            </ButtonSecondaryContainer>
            <ButtonPrimaryContainer>
              {lastAvailabilityDayIndex !== -1 && (
                <CustomButton
                  background={isDayFilled ? colors.primary : colors.gray13}
                  disabledBackground={colors.gray13}
                  pressedBackground={colors.primaryLight}
                  titleColor={colors.surface}
                  borderColor={isDayFilled ? colors.primary : colors.gray13}
                  title={t('continue')}
                  onPress={() => onChange('next')}
                />
              )}
              {lastAvailabilityDayIndex === -1 && (
                <CustomButton
                  background={isDayFilled ? colors.primary : colors.gray13}
                  disabledBackground={colors.gray13}
                  pressedBackground={colors.primaryLight}
                  titleColor={colors.surface}
                  borderColor={isDayFilled ? colors.primary : colors.gray13}
                  title={t('finish')}
                  onPress={finish}
                />
              )}
            </ButtonPrimaryContainer>
          </Footer>
        </Container>
      </ScrollView>
    </SafeAreaView>
  );
};
