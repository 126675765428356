import React, { FC } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { FlexRowBetween } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const Container = styled(FlexRowBetween)`
  flex: 1;
`;

export const ManagemetHeaderTitle: FC<StackHeaderProps> = () => (
  <Container>{/* <Title>{title}</Title> */}</Container>
);
