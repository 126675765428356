import React, { FC, useCallback, useMemo } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { useUser } from '@violetta/ubeya/auth';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  AccountImage,
  BaseView,
  FlexRow,
  H3Medium,
  RipplePressable,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { ProfileScreenNames } from '../../../profile/navigation';

const Container = styled(FlexRow)`
  flex: 1;
  height: 100%;
  justify-content: center;
`;

const TitleContainer = styled(BaseView)`
  flex-direction: row;
  align-items: center;
`;

const Title = styled(H3Medium)<{ isPrimaryColor: boolean }>`
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-left: 10px;
  color: ${({ isPrimaryColor, theme }) =>
    isPrimaryColor ? theme.colors.white : theme.colors.textPrimary};
`;

export const HomeHeaderTitle: FC<StackHeaderProps> = ({ isPrimaryColor }) => {
  const { t } = useTranslation();
  const { data } = useUser();
  const firstName = useMemo(() => data?.firstName || '', [data]);
  const { navigate } = useNavigation();

  const handleOnTitlePress = useCallback(() => {
    navigate(ProfileScreenNames.MAIN);
  }, [navigate]);

  return (
    <Container as={RipplePressable} onPress={handleOnTitlePress}>
      <TitleContainer>
        <AccountImage size={30} image={data?.image} />
        <Title isPrimaryColor={isPrimaryColor}>
          {t('hi')} {firstName || t('hiYou')}
        </Title>
      </TitleContainer>
    </Container>
  );
};
