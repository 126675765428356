import { getStats } from '@violetta/ubeya/api';
import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export type StatsResult = {
  data: {
    hasUnreadMessages: boolean;
    hasUnreadPosts: boolean;
  };
};

export const useStats = (
  { enabled = true }: { enabled: boolean } = { enabled: true }
) => {
  const storeKey = useMemo(() => ['stats'], []);

  const queryClient = useQueryClient();

  const { isPending, data, refetch } = useQuery({
    queryKey: storeKey,
    queryFn: () => getStats(),
    enabled,
  });

  const invalidateHasUnreadMessages = useCallback(() => {
    const previousDataRooms = (queryClient.getQueryData(storeKey) || {
      data: {},
    }) as StatsResult;

    queryClient.setQueryData(storeKey, {
      data: {
        ...previousDataRooms.data,
        hasUnreadMessages: false,
      },
    });
  }, [queryClient, storeKey]);

  const invalidateHasUnreadPosts = useCallback(() => {
    const previousDataRooms = (queryClient.getQueryData(storeKey) || {
      data: {},
    }) as StatsResult;

    queryClient.setQueryData(storeKey, {
      data: {
        ...previousDataRooms.data,
        hasUnreadPosts: false,
      },
    });
  }, [queryClient, storeKey]);

  return {
    isLoading: isPending,
    invalidateHasUnreadMessages,
    invalidateHasUnreadPosts,
    refetch,
    data: data?.data || {},
  };
};
