import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { PdfReaderHeader } from '../components/PdfReaderHeader';
import { PdfReaderScreen } from '../screens/PdfReaderScreen';
import { PdfReaderScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const PdfReaderNavigation: FC = () => (
  <Navigator initialRouteName={PdfReaderScreenNames.READER}>
    <Screen
      name={PdfReaderScreenNames.READER}
      component={PdfReaderScreen}
      options={{
        header: (props: NativeStackHeaderProps) => (
          <PdfReaderHeader {...props} />
        ),
      }}
    />
  </Navigator>
);
