import { useNetInfo } from '@react-native-community/netinfo';
import { api } from '@violetta/ubeya/api';
import {
  Location,
  addOfflineMutation,
  useOptimisticMutation,
} from '@violetta/ubeya/utils';
import moment from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { timesheetsPersister } from '@violetta/ubeya/entities';

export const useTimesheet = (id: number) => {
  const queryClient = useQueryClient();
  const { isConnected } = useNetInfo();
  const storeKey = ['timesheets'];

  const { mutateAsync: clockIn } = useOptimisticMutation(
    storeKey,
    ({ location, delta }: { location: Location | null; delta?: number }) =>
      api.clock({ id, clockType: 'in', location, delta }),
    ({ previousData, payload }) => {
      const now = moment().toDate();

      if (!isConnected) {
        addOfflineMutation({
          mutation: 'clockIn',
          params: { location: payload?.location, timesheetId: id },
          timeRequested: now,
        });
      }

      const timesheet = previousData!?.data.find(
        (timesheet) => timesheet?.id === id
      );
      const filteredTimesheets = previousData!.data.filter(
        (timesheet) => timesheet.id !== id
      );
      const nextData = {
        data: [...filteredTimesheets, { ...timesheet, id, startTime: now }],
      };

      // HACK to have setQueryData save on the persister
      queryClient.setQueryData(storeKey, nextData);
      const query = queryClient.getQueryCache().find({ queryKey: storeKey });
      timesheetsPersister.save(query);

      return nextData;
    },
    {
      onSuccess: (newData, prevData, variables) => {
        const { data } = newData || {};
        const { startTime, endTime, actualStartTime, actualEndTime } =
          data || {};
        const timesheet = prevData!.data.find(
          (timesheet) => timesheet.id === id
        );

        const filteredTimesheets = prevData!.data.filter(
          (timesheet) => timesheet.id !== id
        );

        const nextData = {
          data: [
            ...filteredTimesheets,
            {
              ...timesheet,
              id,
              startTime,
              endTime,
              actualStartTime,
              actualEndTime,
            },
          ],
        };

        queryClient.setQueryData(storeKey, nextData);
      },
      shouldRollbackOnError: false,
      mutateOnlyOnSuccess: isConnected,
    }
  );

  const { mutateAsync: clockOut } = useOptimisticMutation(
    storeKey,
    ({ location }: { location: Location | null }) =>
      api.clock({ id, clockType: 'out', location }),
    ({ previousData, payload }) => {
      const now = moment().toDate();
      if (!isConnected) {
        addOfflineMutation({
          mutation: 'clockOut',
          params: { location: payload?.location, timesheetId: id },
          timeRequested: now,
        });
      }
      const timesheet = previousData!?.data.find(
        (timesheet) => timesheet?.id === id
      );
      const filteredTimesheets = previousData!.data.filter(
        (timesheet) => timesheet.id !== id
      );
      const nextData = {
        data: [...filteredTimesheets, { ...timesheet, id, endTime: now }],
      };
      // HACK to have setQueryData save on the persister
      queryClient.setQueryData(storeKey, nextData);
      const query = queryClient.getQueryCache().find({ queryKey: storeKey });
      timesheetsPersister.save(query);
      return nextData;
    },
    {
      onSuccess: (newData, prevData, variables) => {
        const { data } = newData || {};
        const { startTime, endTime, actualStartTime, actualEndTime } =
          data || {};
        const timesheet = prevData!.data.find(
          (timesheet) => timesheet.id === id
        );
        const filteredTimesheets = prevData!.data.filter(
          (timesheet) => timesheet.id !== id
        );
        const nextData = {
          data: [
            ...filteredTimesheets,
            {
              ...timesheet,
              id,
              startTime,
              endTime,
              actualStartTime,
              actualEndTime,
            },
          ],
        };
        queryClient.setQueryData(storeKey, nextData);
      },
      shouldRollbackOnError: false,
      mutateOnlyOnSuccess: isConnected,
    }
  );

  const { mutateAsync: checkIn } = useOptimisticMutation(
    storeKey,
    ({ location, delta }: { location: Location | null; delta?: number }) =>
      api.clock({ id, clockType: 'checkIn', location, delta }),
    ({ previousData, payload }) => {
      const now = moment().toDate();

      if (!isConnected) {
        addOfflineMutation({
          mutation: 'checkIn',
          params: { location: payload?.location, timesheetId: id },
          timeRequested: now,
        });
      }

      const timesheet = previousData!?.data.find(
        (timesheet) => timesheet?.id === id
      );
      const filteredTimesheets = previousData!.data.filter(
        (timesheet) => timesheet.id !== id
      );
      const nextData = {
        data: [...filteredTimesheets, { ...timesheet, id, checkInTime: now }],
      };
      // HACK to have setQueryData save on the persister
      queryClient.setQueryData(storeKey, nextData);
      const query = queryClient.getQueryCache().find({ queryKey: storeKey });
      timesheetsPersister.save(query);

      return nextData;
    },
    {
      onSuccess: (newData, prevData, variables) => {
        const { data } = newData || {};
        const {
          startTime,
          endTime,
          checkInTime,
          actualStartTime,
          actualEndTime,
        } = data || {};
        const timesheet = prevData!.data.find(
          (timesheet) => timesheet.id === id
        );
        const filteredTimesheets = prevData!.data.filter(
          (timesheet) => timesheet.id !== id
        );

        const nextData = {
          data: [
            ...filteredTimesheets,
            {
              ...timesheet,
              id,
              checkInTime,
              startTime,
              endTime,
              actualStartTime,
              actualEndTime,
            },
          ],
        };

        queryClient.setQueryData(storeKey, nextData);
      },
      shouldRollbackOnError: false,
      mutateOnlyOnSuccess: isConnected,
    }
  );

  const { mutateAsync: checkOut } = useOptimisticMutation(
    storeKey,
    ({ location, delta }: { location: Location | null; delta?: number }) =>
      api.clock({ id, clockType: 'checkOut', location, delta }),
    ({ previousData, payload }) => {
      const now = moment().toDate();

      if (!isConnected) {
        addOfflineMutation({
          mutation: 'checkOut',
          params: { location: payload?.location, timesheetId: id },
          timeRequested: now,
        });
      }

      const timesheet = previousData!?.data.find(
        (timesheet) => timesheet?.id === id
      );
      const filteredTimesheets = previousData!.data.filter(
        (timesheet) => timesheet.id !== id
      );
      const nextData = {
        data: [...filteredTimesheets, { ...timesheet, id, checkOutTime: now }],
      };

      // HACK to have setQueryData save on the persister
      queryClient.setQueryData(storeKey, nextData);
      const query = queryClient.getQueryCache().find({ queryKey: storeKey });
      timesheetsPersister.save(query);
      return nextData;
    },
    {
      onSuccess: (newData, prevData, variables) => {
        const { data } = newData || {};
        const {
          startTime,
          endTime,
          checkOutTime,
          actualStartTime,
          actualEndTime,
        } = data || {};
        const timesheet = prevData!.data.find(
          (timesheet) => timesheet.id === id
        );
        const filteredTimesheets = prevData!.data.filter(
          (timesheet) => timesheet.id !== id
        );

        const nextData = {
          data: [
            ...filteredTimesheets,
            {
              ...timesheet,
              id,
              checkOutTime,
              startTime,
              endTime,
              actualStartTime,
              actualEndTime,
            },
          ],
        };

        queryClient.setQueryData(storeKey, nextData);
      },
      shouldRollbackOnError: false,
      mutateOnlyOnSuccess: isConnected,
    }
  );

  const {
    mutateAsync: approveTimesheet,
    isPending: isLoadingApproveTimesheet,
  } = useMutation({
    mutationFn: ({ approve }: { approve: { signature: string } }) =>
      api.approveTimesheet({
        timesheetId: id,
        approve,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['timesheetsHistory'] });
      queryClient.invalidateQueries({ queryKey: storeKey });
    },
  });

  const {
    mutateAsync: declineTimesheet,
    isPending: isLoadingDeclineTimesheet,
  } = useOptimisticMutation(
    ['timesheetsHistory'],
    ({ decline }: { decline: { reason: string } }) =>
      api.declineTimesheet({ timesheetId: id, decline }),
    ({ previousData, payload }) => {
      const nextData = {
        data: previousData!.data.map((currTimesheet) =>
          currTimesheet.id !== id
            ? currTimesheet
            : {
                ...currTimesheet,
                employeeDeclined: { reason: payload.decline.reason },
              }
        ),
      };

      return nextData;
    },
    {
      onSuccess: (_, previousData, payload) => {
        const nextData = {
          data: previousData!.data.map((currTimesheet) =>
            currTimesheet.id !== id
              ? currTimesheet
              : {
                  ...currTimesheet,
                  employeeDeclined: { reason: payload.decline.reason },
                }
          ),
        };

        queryClient.setQueryData(['timesheetsHistory'], nextData);
        queryClient.setQueryData(storeKey, nextData);
      },
    }
  );

  return {
    clockIn,
    clockOut,
    checkIn,
    checkOut,
    approveTimesheet,
    declineTimesheet,
    isLoadingApproveTimesheet,
    isLoadingDeclineTimesheet,
  };
};
