import React, { FC } from 'react';

export type Props = {
  captchaDomain: string;
  onReceiveToken: (options: { data: string; type: 'success' | 'failure' }) => void;
  siteKey: string;
  action: string;
};

export const RecaptchaV3: FC<Props> = React.memo(() => <div />);
