import React, { FC, useContext, useMemo } from 'react';
import { usePeriods } from '@violetta/ubeya/entities';
import { FlexRowAround } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { AppContext } from '../../../main/AppContext';
import { WeekListDay } from './WeekListDay';

const Container = styled(FlexRowAround)`
  justify-content: space-around;
  background-color: ${({ theme }) => theme.colors.surface};
  padding: 12px 0;
  shadow-color: rgba(0, 0, 0, 0.12);
  shadow-opacity: 0.3;
  shadow-offset: 0 0px;
  shadow-radius: 4px;
  elevation: 4;
  z-index: 1;
`;

interface Props {
  onDayPress?: (date: Date) => void;
}

export const WeekList: FC<Props> = ({ onDayPress = () => {} }) => {
  const {
    availability: { selectedDay, periodId },
  } = useContext(AppContext);

  const { mappedPeriods } = usePeriods();

  const period = useMemo(
    () => mappedPeriods[periodId],
    [periodId, mappedPeriods]
  );

  return (
    <Container>
      {period.dates.map(({ date, labels }, idx) => (
        <WeekListDay
          key={idx}
          period={period}
          date={date}
          labels={labels}
          selectedDay={selectedDay}
          onDayPress={onDayPress}
        />
      ))}
    </Container>
  );
};
