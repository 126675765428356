import React, { FC } from 'react';
import { Insets, Pressable } from 'react-native';

interface Props {
  component: FC<any>;
  onPress?: () => void;
  hitSlop?: null | Insets | number;
  color?: string;
}

export const Link: FC<Props> = ({
  component: Component,
  children,
  onPress,
  hitSlop,
  color = '#005aff',
}) => (
  <Pressable onPress={onPress} hitSlop={hitSlop}>
    <Component
      style={{
        textDecorationLine: 'underline',
        color,
        textAlign: 'left',
      }}
    >
      {children}
    </Component>
  </Pressable>
);
