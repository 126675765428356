import React, { FC, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { getFileExtension } from '@violetta/ubeya/utils';
import moment from 'moment';
import { Image } from 'react-native';
import styled from 'styled-components/native';
import { BaseView } from '../Layout/Layout';
import { SvgIcon } from '../Icons/SvgIcon';

const DocumentWrapper = styled(BaseView)`
  display: flex;
  align-items: center;
  position: relative;
`;

const IconContainer = styled(BaseView)`
  margin: 10px 0;
`;

const WarningContainer = styled(BaseView)`
  position: absolute;
  top:${({ $extraMargin }) => ($extraMargin ? 24 : 16)}px
  left:${({ $extraMargin }) => ($extraMargin ? 20 : 6)}px
`;

const LargeProfileImage = styled(Image)`
  border-radius: 6px;
  margin: 10px 0;
  height: ${({ $height }) => $height}px;
  width: ${({ $width }) => $width}px;
`;

const ALLOWED_PHOTO_TYPES = '.jpeg,.jpg,.gif,.png';

const FIXED_SIZE = 60;

type StyleProps = {
  height?: number;
  width?: number;
};

type FieldType = {
  value: {
    link: string;
  };
};

type Props = {
  field: FieldType;
  style?: StyleProps;
  showWarningExpired?: boolean;
  useLargeIcon?: boolean;
};

export const EmployeeDocumentPreview: FC<Props> = ({
  field,
  showWarningExpired = false,
  style,
  useLargeIcon = false,
}) => {
  const extension = field?.value[0]?.link
    ? getFileExtension(field.value[0].link) || ''
    : '';

  const fileExpired = useMemo(
    () =>
      field?.value[0]?.expirationDate
        ? moment().isSameOrAfter(moment(field?.value[0].expirationDate))
        : false,
    [field?.value]
  );
  const fileIcon = useMemo(() => {
    switch (extension.toLowerCase()) {
      case 'pdf':
        return fileExpired
          ? useLargeIcon
            ? icons?.complianceIcons?.compliancePDFFileLargeExpired
            : icons?.complianceIcons?.compliancePDFFileExpired
          : useLargeIcon
          ? icons?.complianceIcons?.compliancePDFFileLarge
          : icons?.complianceIcons?.compliancePDFFile;

      case 'pptx':
      case 'ppt':
        return fileExpired
          ? useLargeIcon
            ? icons?.complianceIcons?.compliancePPTXFileLargeExpired
            : icons?.complianceIcons?.compliancePPTXFileExpired
          : useLargeIcon
          ? icons?.complianceIcons?.compliancePPTXFileLarge
          : icons?.complianceIcons?.compliancePPTXFile;

      case 'docx':
        return fileExpired
          ? useLargeIcon
            ? icons?.complianceIcons?.complianceDOCXFileLargeExpired
            : icons?.complianceIcons?.complianceDOCXFileExpired
          : useLargeIcon
          ? icons?.complianceIcons?.complianceDOCXFileLarge
          : icons?.complianceIcons?.complianceDOCXFile;

      case 'xlsx':
      case 'xls':
        return fileExpired
          ? useLargeIcon
            ? icons?.complianceIcons?.complianceXLSXFileLargeExpired
            : icons?.complianceIcons?.complianceXLSXFileExpired
          : useLargeIcon
          ? icons?.complianceIcons?.complianceXLSXFileLarge
          : icons?.complianceIcons?.complianceXLSXFile;

      default:
        return icons?.complianceIcons?.complianceDefaultFile;
    }
  }, [useLargeIcon, fileExpired, extension]);

  const displayStyle = useMemo(
    () => ({
      height: style?.height || FIXED_SIZE,
      width: style?.width || FIXED_SIZE,
    }),
    [style?.height, style?.width]
  );

  if (!field) {
    return <></>;
  }

  if (extension && ALLOWED_PHOTO_TYPES.includes(extension)) {
    return (
      <DocumentWrapper>
        {fileExpired && (
          <LargeProfileImage
            source={{ uri: field.value[0].link }}
            $height={displayStyle.height}
            $width={displayStyle.width}
            style={
              fileExpired ? { position: 'absolute', tintColor: 'black' } : {}
            }
          />
        )}

        <LargeProfileImage
          source={{ uri: field.value[0].link }}
          $height={displayStyle.height}
          $width={displayStyle.width}
          style={fileExpired ? { opacity: 0.7 } : {}}
        />

        {showWarningExpired && fileExpired && (
          <WarningContainer>
            <SvgIcon
              xml={icons?.complianceIcons?.complianceExpiredWarning}
              height={25}
              width={25}
            />
          </WarningContainer>
        )}
      </DocumentWrapper>
    );
  }

  return (
    <DocumentWrapper>
      <IconContainer>
        <SvgIcon
          xml={fileIcon}
          height={displayStyle.height}
          width={displayStyle.width}
        />
      </IconContainer>

      {showWarningExpired && fileExpired && (
        <WarningContainer $extraMargin>
          <SvgIcon
            xml={icons?.complianceIcons?.complianceExpiredWarning}
            height={30}
            width={30}
          />
        </WarningContainer>
      )}

      {/* <Doc>
        <Title>
          <Text>{fileName}</Text>
        </Title>
      </Doc> */}
    </DocumentWrapper>
  );
};
