import React, { FC, useEffect, useRef, useState } from 'react';
import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  ClockTimeSelect,
  FlexColumn,
  FlexColumnDirection,
  FlexRow,
  H3Bold,
  Loader,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import QRCodeScanner from 'react-native-qrcode-scanner';
import Toast from 'react-native-toast-message';
import styled from 'styled-components/native';
import { QrCodeScannerScreenNames } from '../navigation/ScreenNames';
import { StyleSheet } from 'react-native';
import { icons } from '@violetta/ubeya/assets';
import { useRecoilState } from 'recoil';
import { qrCodeTorchState } from '../../../state';
import { ClockTimes, IQRShiftEmployee } from '@violetta/ubeya/entities';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const SelectContainer = styled(FlexColumn)`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 30px;
  margin-right: 5px;
  flex: 1;
`;

const LoaderContainer = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
`;

const IconsContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  padding: 20px 10px 10px;
`;

const PermissionsText = styled(H3Bold)`
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`;

const IconContainer = styled(RipplePressable)`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin: 0 5px;
`;

type ParamList = {
  params: {
    showCheckIn?: boolean;
    showClockIn?: boolean;
    showClockOut?: boolean;
    projectId?: number;
  };
};

export const QrCodeScannerScreen: FC = React.memo(() => {
  const { params } = useRoute<RouteProp<ParamList, 'params'>>();
  const {
    showCheckIn = true,
    showClockIn = true,
    showClockOut = true,
    projectId,
  } = params || {};
  const [torch, setTorch] = useRecoilState(qrCodeTorchState);
  const [isLoading, setIsLoading] = useState(false);
  const isFocused = useIsFocused();
  const [clockTime, setClockTime] = useState<ClockTimes>(
    showClockIn ? 'startTime' : showCheckIn ? 'checkInTime' : undefined
  );
  const scannerRef = useRef<QRCodeScanner>(null);
  const isActive = isFocused;

  const { navigate } = useNavigation();
  const { t } = useTranslation();

  const onSuccess = (value: string) => {
    try {
      const data = JSON.parse(value?.data) as IQRShiftEmployee;
      if (data?.employeeId) {
        navigate(QrCodeScannerScreenNames.MAIN, {
          screen: QrCodeScannerScreenNames.EMPLOYEE,
          params: {
            ...data,
            initialClockTime: clockTime,
            ...(projectId && { projectId }), //override the one from employee QR
          },
        });
      }
    } catch (err) {
      Toast.show({
        type: 'error',
        position: 'bottom',
        text1: t('qrCodeReadError'),
        bottomOffset: 83,
      } as any);
      setTimeout(() => scannerRef?.current?.reactivate(), 3000);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isFocused && scannerRef?.current) {
      scannerRef?.current?.reactivate();
    }
    if (isFocused) {
      setIsLoading(false);
    }
  }, [isFocused]);

  return (
    <Container>
      {/* {device != null && (
        <Camera
          style={StyleSheet.absoluteFill}
          device={device}
          isActive={isActive}
          codeScanner={codeScanner}
          torch={torch ? 'on' : 'off'}
          enableZoomGesture={true}
        />
      )} */}
      <IconsContainer>
        <SelectContainer>
          <ClockTimeSelect
            border="transparent"
            background="transparet"
            value={[clockTime]}
            showCheckIn={showCheckIn}
            showClockIn={showClockIn}
            showClockOut={showClockOut}
            onSelected={(val) => setClockTime(val?.[0] as ClockTimes)}
            style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 20 }}
          />
        </SelectContainer>
        {/* <IconContainer onPress={() => setTorch(!torch)}>
          <SvgIcon
            xml={icons.actionIcons.torch}
            width={40}
            height={40}
            color="#000000"
          />
        </IconContainer> */}
      </IconsContainer>
      <QRCodeScanner onRead={onSuccess} ref={scannerRef} />
    </Container>
  );
});
