import { Card as BaseCard } from '../Cards/Card';
import { BaseCollapsible } from '../Collapsible/BaseCollapsible';
import { ChevronDown } from '../Icons/ChevronDown';
import { ChevronUp } from '../Icons/ChevronUp';
import { SvgIcon } from '../Icons/SvgIcon';
import { BaseView, FlexCenter, FlexRow, FlexRowCenter } from '../Layout/Layout';
import { Loader } from '../Loaders/Loader';

import { LiveViewChart } from './LiveViewChart';
import { LiveViewChartBar } from './LiveViewChartBar';
import { LiveViewFilterModal } from './LiveViewFilterModal';

import { icons } from '@violetta/ubeya/assets';
import { css } from 'styled-components';
import styled from 'styled-components/native';

const Card = styled(BaseCard)`
  border-radius: 4px;
  border-width: 0px;
  elevation: 3;
  shadow-color: rgba(0, 0, 0, 0.3);
  shadow-opacity: 1;
  shadow-offset: 0px 1px;
  shadow-radius: 1px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const TitleSection = styled(FlexRowCenter)`
  display: flex;
  width: ${({ $isCollpasible }) => ($isCollpasible ? 90 : 100)}%;
  padding: 0 2px;
`;

const LoaderContainer = styled(FlexCenter)`
  background-color: ${({ theme }) => theme.colors.surface};
  margin-bottom: 25px;
  height: 100%;
`;

const ChartsWrapper = styled(BaseView)`
  ${({ $showBottomBorder }) =>
    $showBottomBorder &&
    css`
      border-bottom-width: 1px;
      border-bottom-color: ${({ theme }) => theme.colors.gray11};
    `}
`;

const ContentWrapper = styled(BaseView)``;

const TitleComponent = ({ isVisible = true, title, isCollpasible = false }) => {
  return isVisible && title ? (
    <TitleSection $isCollpasible={isCollpasible}>{title}</TitleSection>
  ) : (
    <></>
  );
};

const TitleComponentTriggerWrapper = styled(FlexRow)`
  width: 100%;
  align-items: flex-start;
`;

const ArrowWrapper = styled(BaseView)`
  margin-top: 10px;
  background-color: ${({ theme }) => theme.colors.whitesmoke};
  border-radius: 50;
  padding: 5px;
`;

const TitleComponentTrigger = ({
  triggerParams: { title } = {},
  isCollapsed,
}) => {
  return (
    <TitleComponentTriggerWrapper>
      <TitleComponent title={title} isCollpasible />
      <ArrowWrapper>
        {isCollapsed ? (
          <BaseView>
            <ChevronDown />
          </BaseView>
        ) : (
          <ChevronUp />
        )}
      </ArrowWrapper>
    </TitleComponentTriggerWrapper>
  );
};

const IconContainer = styled(FlexCenter)`
  border-radius: 20px;
  margin-top: 20px;
`;

const LiveViewContent = ({
  stats: liveViewStats,
  shiftsInfo,
  title,
  filters,
  setFilters,
  filterModalizeRef,
  isLoading,
  isCollpasible = false,
  isCollpased = true,
  showEmptyState = false,
  emptyStateIcon,
  renderBottom,
  timeFormat,
  dateFormat,
  showDateFilter = true,
}) => {
  return (
    <Card>
      <BaseCollapsible
        isCollpased={isCollpased}
        hideRightChevron
        trigger={isCollpasible ? TitleComponentTrigger : undefined}
        triggerParams={{ title }}
        containerStyle={{
          borderBottomColor: 'transparent',
        }}
      >
        {isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <>
            <TitleComponent title={title} isVisible={!isCollpasible} />

            {showEmptyState ? (
              <IconContainer>
                <SvgIcon
                  xml={
                    emptyStateIcon ||
                    icons?.emptyStates?.accountLiveViewEmptyState
                  }
                  width={300}
                  height={300}
                />
              </IconContainer>
            ) : (
              <ChartsWrapper $showBottomBorder={!!renderBottom}>
                <LiveViewChart liveViewStats={liveViewStats} />
                <LiveViewChartBar liveViewStats={liveViewStats} />
              </ChartsWrapper>
            )}

            <LiveViewFilterModal
              showDateFilter={showDateFilter}
              timeFormat={timeFormat}
              dateFormat={dateFormat}
              shiftsInfo={shiftsInfo}
              modalizeRef={filterModalizeRef}
              filters={filters}
              setFilters={setFilters}
            />
          </>
        )}
      </BaseCollapsible>

      <ContentWrapper>{renderBottom}</ContentWrapper>
    </Card>
  );
};

export default LiveViewContent;
