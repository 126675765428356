import React, { FC } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { WideFlexColumnStart } from '../Layout';
import { Lottie } from '../Lotties';
import { H3Regular } from '../Typography';

const EmptyTitle = styled(H3Regular)`
  padding: 10px 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  color: #252525;
`;

const EmptyStateContainer = styled(WideFlexColumnStart)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

interface Props {
  hide?: boolean;
  lottieSrc: any;
  title?: string;
  width?: number;
  height?: number;
  style?: StyleProp<ViewStyle>;
}

export const EmptyState: FC<Props> = React.memo(
  ({ hide = false, lottieSrc, title, width = 350, height = 370, style }) => {
    if (hide) {
      return null;
    }
    return (
      <EmptyStateContainer>
        <Lottie source={lottieSrc} autoPlay resizeMode="cover" height={height} width={width} style={style} />
        {!!title && <EmptyTitle>{title}</EmptyTitle>}
      </EmptyStateContainer>
    );
  },
);
