import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { IPosition } from '../entities';

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const positions = (data || []).map(
      ({ id, slug, jobDescription }) =>
        ({
          id,
          slug: slug?.replace('position__', '')?.replace('_', ' '), // remove i18n prefix until localization is added
          jobDescription,
        } as IPosition)
    );

    const mappedPositions = mappedArray(positions);

    return { positions, mappedPositions };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const usePositions = () => {
  const { data: userData } = useUser();
  const storeKey = useMemo(() => ['positions'], []);
  const { isPending, data } = useQuery<{ data: IPosition[] }>({
    queryKey: storeKey,
    queryFn: () => api.getPositions(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { positions, mappedPositions } = useMemo(() => {
    const { positions = [], mappedPositions = {} } = data || {};
    return {
      positions,
      mappedPositions,
    };
  }, [data]);

  return {
    isLoading: isPending,
    positions,
    mappedPositions,
  };
};
