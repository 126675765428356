export const mappedArray = <T, P extends keyof T = keyof T>(array: T[], keyGetter?: (value: T) => P) => {
  const map = {};
  array.forEach((item) => {
    map[keyGetter?.(item) ?? (item?.id || item?._id)] = item;
  }, {});

  return map;
};

export const mappedMultipleArray = <T, P extends keyof T = keyof T>(array: T[], keyGetter?: (value: T) => P) => {
  const map = {};
  array.forEach((item) => {
    const mappedItem = map[keyGetter?.(item) ?? item?.id];
    if (!mappedItem) {
      map[keyGetter?.(item) ?? item?.id] = item;
    } else if (typeof mappedItem === 'object') {
      map[keyGetter?.(item) ?? item?.id] = [mappedItem, item];
    } else if (mappedItem?.length > 0) map[keyGetter?.(item) ?? item?.id] = [...mappedItem, item];
  }, {});

  return map;
};
