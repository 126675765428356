import React, { FC, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import {
  Booking,
  IAccount,
  IBranch,
  ILabel,
  IPosition,
  ShiftSwapStatus,
  SwapType,
  useLocations,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Card,
  DateTextComponent,
  FlexRow,
  H5Regular,
  H6Medium,
  H6Regular,
  H7Regular,
  NotificationChip,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import moment from 'moment';
import styled from 'styled-components/native';
import { theme } from '../../../../style';
import { HIDE_LOCATION_NAME_ACCOUNT_IDS } from '../../../main/constants';

export interface Shift {
  id: number;
  positionId: number;
  labelId: number | null;
  startTime: Date;
  endTime: Date;
}

const ShiftContainer = styled(BaseView)`
  padding: 20px 12px 12px 12px;
`;

const LocationName = styled(H6Regular)`
  font-weight: 300;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.textPrimary};
  max-width: 90%;
`;

const Wage = styled(H6Regular)`
  font-weight: 500;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.textPrimary};
  max-width: 90%;
`;

const BranchName = styled(H7Regular)`
  max-width: 90%;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const SwapDataContainer = styled(FlexRow)`
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 12px 0;
  width: 100%;
  justify-content: center;
  border: solid 0.5px ${({ theme }) => theme.colors.offwhitesmoke};
`;

const EmployeeContainer = styled(BaseView)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ProfilePictureContainer = styled(BaseView)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-bottom: 5px;
`;

const BranchLogo = styled.Image`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  align-self: center;
  margin-right: 10px;
`;

const UserImage = styled.Image`
  width: 24px;
  height: 24px;
  border-radius: 12px;
`;

const EventName = styled(H6Medium)`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
`;

const CardDataDetails = styled(H5Regular)`
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray15};
  font-size: 12px;
`;

const Border = styled(BaseView)`
  width: 1px;
  border: solid 0.5px ${({ theme }) => theme.colors.gray15};
`;

const InfoRow = styled(FlexRow)`
  margin-bottom: 12px;
`;

const IconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 20px;
  margin-right: 10px;
`;

interface Props {
  booking: Booking;
  mappedBranches: Record<number, IBranch>;
  mappedAccounts: Record<number, IAccount>;
  mappedLabels: Record<number, ILabel>;
  mappedPositions: Record<number, IPosition>;
  onPress: (booking: Booking) => void;
  isFirst?: boolean;
}

export const UpcomingShift: FC<Props> = React.memo(
  ({ booking, mappedPositions, mappedAccounts, mappedBranches, onPress }) => {
    const { t } = useTranslation();
    const { mappedLocations } = useLocations();
    const { data } = useUser();

    const {
      project: { branchId, name },
      positionId,
      confirmation,
      isRead,
      wage,
    } = booking;

    const config = useMemo(
      () => (branchId ? mappedBranches[branchId]?.schedulingConfig : null),
      [branchId, mappedBranches]
    );

    const isShowBookingWage = useMemo(
      () => config && config.isShowBookingWage,
      [config]
    );

    const position = useMemo(
      () => !!positionId && t(mappedPositions[positionId]?.slug),
      [positionId, t, mappedPositions]
    );

    const currency =
      branchId &&
      mappedBranches?.[branchId]?.accountId &&
      mappedAccounts?.[mappedBranches?.[branchId]?.accountId]?.currency?.symbol;

    const logo = useMemo(
      () =>
        mappedBranches[branchId]?.accountId && mappedBranches[branchId]?.logo,
      [branchId, mappedBranches]
    );

    const branchName = useMemo(
      () => mappedBranches[branchId]?.name,
      [branchId, mappedBranches]
    );
    const location = useMemo(
      () => booking.locationId && mappedLocations[booking?.locationId],
      [booking.locationId, mappedLocations]
    );

    const hasReleaseOrSwap = useMemo(
      () => booking?.swap?.status === 0 || booking?.release?.status === 0,
      [booking?.release?.status, booking?.swap?.status]
    );

    const swapName = useMemo(
      () =>
        `${booking?.swap?.employee?.firstName || ''} ${
          booking?.swap?.employee?.laseName || ''
        }`,
      [booking?.swap?.employee?.firstName, booking?.swap?.employee?.laseName]
    );

    const time = useMemo(
      () => rangeFormat(booking?.startTime, booking?.endTime),
      [booking?.endTime, booking?.startTime]
    );

    const showSwapBar = useMemo(
      () =>
        [
          ShiftSwapStatus.REQUEST_INITIATED,
          ShiftSwapStatus.REQUEST_APPROVED_BY_REQUESTED,
        ].includes(booking?.swap?.status),
      [booking?.swap?.status]
    );

    const showSwapChip = showSwapBar;
    const showReleaseChip = booking?.release?.status === 0;
    const showConfirmedChip =
      !hasReleaseOrSwap &&
      !showSwapBar &&
      isRead &&
      confirmation &&
      confirmation.isConfirmed;
    const showNewChip = !isRead;
    const hasNotificationChip = [
      showSwapChip,
      showReleaseChip,
      showConfirmedChip,
    ].some((val) => val);

    const showLocationName = useMemo(
      () =>
        branchId
          ? !HIDE_LOCATION_NAME_ACCOUNT_IDS.includes(
              mappedBranches?.[branchId]?.accountId
            )
          : true,
      [branchId, mappedBranches]
    );

    if (!mappedBranches?.[branchId]) {
      return null;
    }

    return (
      <Card as={RipplePressable} onPress={() => onPress(booking)}>
        <ShiftContainer>
          <DateTextComponent
            date={booking.startTime}
            bold
            fontSize={16}
            style={{ maxWidth: hasNotificationChip ? '70%' : '80%' }}
          />
          <InfoRow style={{ marginTop: 12 }}>
            <BranchLogo source={{ uri: logo }} />
            <BranchName>
              {[branchName, name].filter((a) => !!a).join(', ')}
              {!!location?.name && !!showLocationName && ` - ${location?.name}`}
            </BranchName>
          </InfoRow>
          {!!isShowBookingWage && !!wage?.flatWage && (
            <InfoRow>
              <IconContainer>
                <SvgIcon
                  xml={icons.uiIcons.incomeIcon2}
                  width={17}
                  height={17}
                />
              </IconContainer>
              <Wage>
                {currency}
                {wage?.flatWage}/{t('flat')} {t('wageEstimated')}
              </Wage>
            </InfoRow>
          )}
          {!!isShowBookingWage && !!wage?.hourlyWage && !wage?.flatWage && (
            <InfoRow>
              <IconContainer>
                <SvgIcon
                  xml={icons.uiIcons.incomeIcon2}
                  width={17}
                  height={17}
                />
              </IconContainer>
              <Wage>
                {currency}
                {wage?.hourlyWage}/{t('hour')} {t('wageEstimated')}
              </Wage>
            </InfoRow>
          )}
          {!!position && (
            <InfoRow>
              <IconContainer>
                <SvgIcon
                  xml={icons?.uiIcons?.personIcon}
                  height={18}
                  width={18}
                />
              </IconContainer>
              <LocationName>{position}</LocationName>
            </InfoRow>
          )}
          <InfoRow>
            <IconContainer>
              <SvgIcon xml={icons?.uiIcons?.clock2} height={18} width={18} />
            </IconContainer>
            <LocationName>{time}</LocationName>
          </InfoRow>
          {location && (
            <InfoRow>
              <IconContainer>
                <SvgIcon
                  xml={icons?.uiIcons?.locationOutline}
                  height={18}
                  width={14}
                />
              </IconContainer>
              <LocationName>{location.address}</LocationName>
            </InfoRow>
          )}
          {showSwapChip && (
            <NotificationChip
              text={t('swapPendingChip')}
              color={theme.colors.seconday2}
              style={{ top: 13, right: 6 }}
            />
          )}
          {showReleaseChip && (
            <NotificationChip
              text={t('releasePendingChip')}
              color={theme.colors.seconday2}
              style={{ top: 13, right: 6 }}
            />
          )}
          {showConfirmedChip && (
            <NotificationChip
              text={t('confirmed')}
              style={{ top: 13, right: 6 }}
            />
          )}
          {showNewChip && (
            <NotificationChip text={t('new')} style={{ top: 13, right: 6 }} />
          )}
        </ShiftContainer>

        {!!booking?.swap?.id &&
          booking?.swap?.typeId === SwapType.RECEIVER &&
          showSwapBar && (
            <SwapDataContainer>
              <EmployeeContainer>
                <ProfilePictureContainer>
                  {!!booking?.swap?.employee?.image && (
                    <UserImage
                      source={{ uri: booking?.swap?.employee?.image }}
                    />
                  )}
                </ProfilePictureContainer>
                <EventName>{swapName}</EventName>
                <CardDataDetails>
                  {moment(booking?.swap?.slot?.startTime).format(
                    'MMM, DD | dddd'
                  )}
                </CardDataDetails>
              </EmployeeContainer>
              <Border />
              <EmployeeContainer>
                <ProfilePictureContainer>
                  {!!data?.image && <UserImage source={{ uri: data?.image }} />}
                </ProfilePictureContainer>
                <EventName>({t('you')})</EventName>
                <CardDataDetails>
                  {moment(booking?.startTime).format('MMM, DD | dddd')}
                </CardDataDetails>
              </EmployeeContainer>
            </SwapDataContainer>
          )}
        {!!booking?.swap?.id &&
          booking?.swap?.typeId === SwapType.INITIATOR &&
          showSwapBar && (
            <SwapDataContainer>
              <EmployeeContainer>
                <ProfilePictureContainer>
                  {!!data?.image && <UserImage source={{ uri: data?.image }} />}
                </ProfilePictureContainer>
                <EventName>({t('you')})</EventName>
                <CardDataDetails>
                  {moment(booking?.startTime).format('MMM, DD | dddd')}
                </CardDataDetails>
              </EmployeeContainer>
              <Border />
              <EmployeeContainer>
                <ProfilePictureContainer>
                  {!!booking?.swap?.employee?.image && (
                    <UserImage
                      source={{ uri: booking?.swap?.employee?.image }}
                    />
                  )}
                </ProfilePictureContainer>
                <EventName>{swapName}</EventName>
                <CardDataDetails>
                  {moment(booking?.swap?.slot?.startTime).format(
                    'MMM, DD | dddd'
                  )}
                </CardDataDetails>
              </EmployeeContainer>
            </SwapDataContainer>
          )}
      </Card>
    );
  }
);
