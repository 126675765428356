import React, { FC } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ComplianceHeader } from '../components/ComplianceHeader';
import { FieldHeader } from '../components/FieldHeader/FieldHeader';
import { ComplianceScreen } from '../screens/ComplianceScreen';
import EditEmployeeFieldScreen from '../screens/EditEmployeeFieldScreen';
import { ComplianceScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const ComplainceNavigation: FC = () => (
  <Navigator initialRouteName={ComplianceScreenNames.COMPLIANCE}>
    <Screen
      name={ComplianceScreenNames.COMPLIANCE}
      component={ComplianceScreen}
      options={{
        headerTitle: 'Compliance',
        header: (props) => <ComplianceHeader {...props} />,
      }}
    />
    <Screen
      name={ComplianceScreenNames.COMPLIANCE_FIELD}
      component={EditEmployeeFieldScreen}
      options={{
        headerTitle: 'Compliance',
        header: (props) => <FieldHeader {...props} title={props.title} />,
      }}
    />
  </Navigator>
);
