import { ActionSheetIOS } from '@violetta/ubeya/actionsheet';
import { Alert } from '@violetta/ubeya/alert';
import { PermissionsAndroid, Platform } from 'react-native';
import { launchImageLibrary, launchCamera } from 'react-native-image-picker';

type PhotoTypes = 'capture' | 'library';

type ImageOptions = {
  includeBase64?: boolean;
  mediaType?: 'photo';
  maxWidth?: number;
  maxHeight?: number;
  quality?: number;
};

type TakeImgParams = {
  texts: {
    title: string;
    cancel: string;
    takeAPhoto: string;
    choosePhoto: string;
  };
  image?: ImageOptions;
};

const PHOTO_OPTIONS = { includeBase64: true, mediaType: 'photo', quality: 0.5 };

const getChoiceIOS = async (params: TakeImgParams) =>
  new Promise(async (resolve) => {
    const { texts } = params;
    const { title, cancel, choosePhoto, takeAPhoto } = texts || {};
    ActionSheetIOS.showActionSheetWithOptions(
      {
        title,
        options: [cancel, takeAPhoto, choosePhoto],
        cancelButtonIndex: 0,
      },
      (buttonIndex) => {
        resolve(buttonIndex);
      }
    );
  });

const getChoiceAndroid = async (params: TakeImgParams) =>
  new Promise(async (resolve) => {
    const { texts } = params;
    const { title, cancel, choosePhoto, takeAPhoto } = texts || {};
    const options = [
      {
        text: cancel,
        onPress: () => resolve(0),
        style: 'cancel',
        type: 'cancel',
      },
      {
        text: takeAPhoto,
        onPress: () => resolve(1),
        style: 'cancel',
      },
      {
        text: choosePhoto,
        onPress: () => resolve(2),
        style: 'cancel',
        type: 'confirm',
      },
    ];
    Alert.alert(title, '', options, {
      onDismiss: () => resolve(null),
    });
  });

const pickImgInternal = async (type: PhotoTypes, options?: ImageOptions) => {
  try {
    if (Platform.OS === 'android') {
      await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.CAMERA);
    }

    const { assets } = await (() => {
      if (type === 'library') {
        return launchImageLibrary({ ...PHOTO_OPTIONS, ...(options || {}) });
      }
      return launchCamera({ ...PHOTO_OPTIONS, ...(options || {}) });
    })();

    return assets;
  } catch (err) {
    return null;
  }
};

export const pickImg = async (params: TakeImgParams) => {
  const option = await (Platform.OS === 'ios'
    ? getChoiceIOS(params)
    : getChoiceAndroid(params));
  switch (option) {
    case 1:
      return pickImgInternal('capture', params?.image);
    case 2:
      return pickImgInternal('library', params?.image);
    default:
      break;
  }
  return null;
};
