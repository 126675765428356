import React, { FC } from 'react';

import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderlessBar } from '@violetta/ubeya/ui';
import { useTheme } from 'styled-components/native';
import { AvailabilityHeader } from '../../home/components/AvailabilityHeader/AvailabilityHeader';
import { AvailabilityWeekHeader } from '../../home/components/AvailabilityHeader/AvailabilityWeekHeader';
import { AvailabilityPeriodScreen } from '../screens/AvailabilityPeriodScreen';
import { AvailabilityScreen } from '../screens/AvailabilityScreen';
import { AvailabilitiesScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const AvailabilitiesNavigation: FC = () => {
  const { colors } = useTheme();

  return (
    <Navigator
      initialRouteName={AvailabilitiesScreenNames.AVAILABILITY}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
        // eslint-disable-next-line react/jsx-closing-bracket-location
      }}
    >
      <Screen
        name={AvailabilitiesScreenNames.AVAILABILITY}
        component={AvailabilityScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <AvailabilityHeader {...props} />
          ),
        }}
      />
      <Screen
        name={AvailabilitiesScreenNames.AVAILABILITY_PERIOD}
        component={AvailabilityPeriodScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <AvailabilityWeekHeader {...props} />
          ),
        }}
      />
    </Navigator>
  );
};
