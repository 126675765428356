import React, { FC, useMemo, useContext } from 'react';
import styled from 'styled-components/native';
import {
  useLocalAvailabilities,
  useLabels,
  usePeriods,
  useAvailabilities,
  IBranch,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  Card,
  FlexColumnDirection,
  FlexRow,
  FlexRowBetween,
  H3Bold,
  H5Medium,
  ChevronRight,
  BaseView,
  RipplePressable,
} from '@violetta/ubeya/ui';

import moment from 'moment';
import { Pressable } from 'react-native';
import { ConfigContext } from '../../../../contexts/ConfigContext';

interface Props {
  periodId: number;
  onPress?: () => void;
  date: Date;
  mappedBranches: Record<number, IBranch>;
}

const DayCard = styled(BaseView)`
  margin: 0;
  padding: 10px 13px;
  flex: 1;
  border-color: ${({ theme }) => theme.colors.gray11};
  border-radius: 16px;
  border-width: 1px;
`;

const CardContent = styled(FlexRowBetween)`
  flex: 1;
`;

const Labels = styled(FlexRow)`
  align-items: center;
  flex-wrap: wrap;
`;

const SetYourAvailability = styled(H5Medium)`
  color: ${({ theme }) => theme.colors.blue2};
  font-weight: 500;
`;

const NoShiftContainer = styled(FlexColumnDirection)`
  padding: 10px 7px;
`;

const Shifts = styled(H5Medium)`
  font-weight: 400;
`;

const NoShifts = styled(H5Medium)`
  color: ${({ theme }) => theme.colors.gray1};
  font-weight: 500;
  opacity: 0.4;
`;

export const DayAvailabilityCard: FC<Props> = ({
  onPress = () => {},
  periodId,
  date: currentDate,
  mappedBranches,
}) => {
  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();
  const { mappedLabels, isLoading: isLoadingLabels } = useLabels();
  const { mappedPeriods, isLoading: isLoadingPeriods } = usePeriods();
  const { localAvailabilities } = useLocalAvailabilities({
    periodId,
  });

  const { isLoading: isLoadingAvailabilities } = useAvailabilities({
    periodId,
  });

  const isLoading = useMemo(
    () => isLoadingAvailabilities || isLoadingLabels || isLoadingPeriods,
    [isLoadingAvailabilities, isLoadingLabels, isLoadingPeriods]
  );

  const labelsLength = useMemo(
    () =>
      (
        mappedPeriods[periodId].dates.find(({ date }) =>
          moment(date).isSame(moment(currentDate), 'date')
        )?.labels || []
      ).length,
    [mappedPeriods, periodId, currentDate]
  );

  const currentDayAvailabilities = useMemo(
    () =>
      localAvailabilities?.filter(
        ({ date, labelId }) =>
          moment(date).isSame(moment(currentDate), 'date') &&
          mappedLabels[labelId]?.branches?.includes(
            mappedPeriods[periodId]?.branchId
          )
      ),
    [currentDate, localAvailabilities, mappedLabels, mappedPeriods, periodId]
  );

  const availableLabels = useMemo(
    () =>
      currentDayAvailabilities
        ?.filter(({ status }) => status > 0)
        .map(({ labelId }) => mappedLabels[labelId].title)
        .join(', '),
    [currentDayAvailabilities, mappedLabels]
  );

  const period = useMemo(
    () => mappedPeriods?.[periodId],
    [mappedPeriods, periodId]
  );

  const periodMarkAllDay = useMemo(
    () =>
      period?.branchId
        ? mappedBranches[period.branchId]?.schedulingConfig?.periodMarkAllDay
        : false,
    [mappedBranches, period.branchId]
  );

  if (labelsLength <= 0) {
    return (
      <NoShiftContainer>
        <NoShifts>{t('noShifts')}</NoShifts>
      </NoShiftContainer>
    );
  }

  return (
    <DayCard
      as={RipplePressable}
      onPress={labelsLength > 0 ? onPress : undefined}
    >
      <CardContent>
        <Labels>
          {!isLoading && (
            <FlexRow>
              {labelsLength <= 0 && <NoShifts>{t('noShifts')}</NoShifts>}
              {labelsLength > 0 && !periodMarkAllDay && (
                <Shifts>{availableLabels}</Shifts>
              )}
              {labelsLength > 0 && currentDayAvailabilities?.length === 0 && (
                <SetYourAvailability>
                  {t('setDayAvailability')}
                </SetYourAvailability>
              )}
              {labelsLength === currentDayAvailabilities?.length &&
                labelsLength > 0 &&
                currentDayAvailabilities.every(
                  ({ status }) => status === 0
                ) && <H3Bold>{t('IamNotAvailable')}</H3Bold>}
              {labelsLength === currentDayAvailabilities?.length &&
                labelsLength > 0 &&
                periodMarkAllDay &&
                currentDayAvailabilities.every(
                  ({ status }) => status === 1
                ) && <H3Bold>{t('markAllAvailableLabelsInDay')}</H3Bold>}
            </FlexRow>
          )}

          {/* {isLoading && <CustomLoader color={theme.colors.primary} size={15} />} */}
        </Labels>

        {labelsLength > 0 && <ChevronRight size={15} rtl={rtl} />}
      </CardContent>
    </DayCard>
  );
};
