import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { SwapHeaderRightContent } from '../../swap/components/SwapHeaderRightContent';
import { WeeklyScheduleHeader } from '../components/WeeklyScheduleHeader';
import { WeeklyScheduleScreen } from '../screens';
import { WeeklyScheduleScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const WeeklyScheduleNavigation: FC = () => (
  <Navigator
    initialRouteName={WeeklyScheduleScreenNames.WEEKLY_SCHEDULE}
    screenOptions={{
      header: (props: NativeStackHeaderProps) => (
        <WeeklyScheduleHeader
          {...props}
          hasRightContent
          rightContent={SwapHeaderRightContent}
        />
      ),
    }}
  >
    <Screen
      name={WeeklyScheduleScreenNames.WEEKLY_SCHEDULE}
      component={WeeklyScheduleScreen}
    />
  </Navigator>
);
