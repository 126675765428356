import { useCallback, useEffect, useRef } from 'react';

const useInterval = (callback: () => any, delay: number, deps: any[] = []) => {
  const savedCallback = useRef<() => any>();
  const savedId = useRef<NodeJS.Timeout>();

  const stop = useCallback(() => {
    const id = savedId.current;
    if (id) {
      savedId.current = undefined;
      clearInterval(id);
    }
  }, []);

  const start = useCallback(() => {
    const handler = () => savedCallback.current!();

    stop();

    if (delay !== null) {
      savedId.current = setInterval(handler, delay);
    }

    return stop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    stop();

    return start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, ...deps]);

  return { start, stop, active: savedId.current !== undefined };
};

export default useInterval;
