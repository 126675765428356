import React, { useContext, FC } from 'react';
import { Linking } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { ThemeContext } from 'styled-components/native';

interface Props {
  component: FC<any>;
  onPress?: () => void;
}

export const TextWithLinks: FC<Props> = ({ children, onPress, component: Component }) => {
  const theme = useContext(ThemeContext);

  return (
    <Hyperlink
      onPress={(url) => {
        if (Linking.canOpenURL(url)) {
          Linking.openURL(url);
        }
      }}
      linkStyle={{
        color: theme.colors.blue1,
        textDecorationLine: 'underline',
        textAlign: 'left',
      }}>
      <Component>{children}</Component>
    </Hyperlink>
  );
};
