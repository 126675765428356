import React, { FC } from 'react';
import { BaseView, CustomLoader, H4Medium } from '@violetta/ubeya/ui';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { ActionLabel } from '../Labels/ActionLabel';

const Container = styled(BaseView)`
  padding: 5px 18px;
`;

interface Props {
  title: string;
  isLoading?: boolean;
  disabled?: boolean;
  paddingVertical?: number;
  onPress?: () => void;
}

export const ActionButton: FC<Props> = React.memo(
  ({ title, onPress, isLoading, disabled, paddingVertical }) =>
    isLoading ? (
      <CustomLoader width={60} height={22} />
    ) : (
      <Container
        as={disabled || !onPress ? undefined : Pressable}
        onPress={onPress}
      >
        <ActionLabel paddingVertical={paddingVertical} title={title} />
      </Container>
    )
);
