import React, { FC, useCallback, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { useEmployeeRequest } from '@violetta/ubeya/entities';
import { useTranslatedApp, useTranslation } from '@violetta/ubeya/i18n';
import {
  Button,
  FlexColumnDirection,
  H7Regular,
  SvgIcon,
  BaseView,
  TabNames,
} from '@violetta/ubeya/ui';
import {
  InferProps as PropsOf,
  required,
  useRefresh,
} from '@violetta/ubeya/utils';
import { Field, Form } from 'react-final-form';
import { Alert } from '@violetta/ubeya/alert';
import { TextInput as BaseTextInput, View, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { HomeScreenNames } from '../../home/navigation/ScreenNames';

const createAlert = (condition, { title, buttons }) =>
  condition
    ? Alert.alert(title, undefined, buttons, { cancelable: false })
    : undefined;

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  padding: 0 40px;
  justify-content: space-between;
`;
const InputContainer = styled(View)`
  width: 100%;
  margin-top: 40px;
`;
const InputLabel = styled(H7Regular)`
  font-size: 14px;
  font-weight: normal;
  color: #858585;
  margin-bottom: 10px;
  width: 100%;
`;
const ButtonContainer = styled(View)`
  margin-bottom: 60px;
`;

const CustomTextInput = styled.TextInput`
  text-align: ${({ isRTL }) => (isRTL ? 'right' : 'left')};
  padding: 15px;
  width: 100%;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
`;

const LocalInputField = ({ name, label, ...rest }) => {
  const isRTL = useTranslatedApp();

  return (
    <Field name={name} validate={required}>
      {({ input, meta }) => (
        <InputContainer>
          <InputLabel>{label}</InputLabel>
          <CustomTextInput
            isRTL={isRTL}
            {...(input as PropsOf<typeof BaseTextInput>)}
            error={meta.error && meta.touched}
            {...rest}
          />
        </InputContainer>
      )}
    </Field>
  );
};

type Props = {};

export const OnboardingFormScreen: FC<Props> = ({}) => {
  const { t } = useTranslation();
  const {
    createEmployeeRequest,
    isPending: isLoading,
    isError,
    isSuccess,
  } = useEmployeeRequest();
  const { navigate } = useNavigation();
  const { refresh, refreshUser } = useRefresh();

  const handleSubmit = useCallback(
    async (values, formApi) => {
      navigate(TabNames.HOME, {
        screen: HomeScreenNames.HOME,
      });
      await createEmployeeRequest({
        first_name: values.firstName,
        last_name: values.lastName,
        code: values.companyCode,
        image: '',
      });
      refresh();
      refreshUser();
    },
    [createEmployeeRequest, navigate, refresh, refreshUser]
  );

  useEffect(
    () =>
      createAlert(isSuccess, {
        title: t('requestToJoinBranchSuccess'),
        buttons: [
          {
            text: t('ok'),
            onPress: () => {},
          },
        ],
      }),
    [isSuccess, t]
  );
  useEffect(
    () =>
      createAlert(isError, {
        title: t('requestToJoinBranchFailed'),
        buttons: [{ text: t('ok') }],
      }),
    [isError, t]
  );

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit, submitting, valid }) => (
        <Container>
          {/* <UserProfileImage /> */}
          <BaseView>
            <LocalInputField name="firstName" label={t('firstName')} />
            <LocalInputField name="lastName" label={t('lastName')} />
            <LocalInputField name="companyCode" label={t('companyCode')} />
          </BaseView>
          <ButtonContainer>
            <Button
              type="primary"
              title={t('next')}
              onPress={handleSubmit}
              isLoading={isLoading}
              disabled={isLoading || submitting || !valid}
            />
          </ButtonContainer>
        </Container>
      )}
    />
  );
};
