import React, { FC } from 'react';
import { InferProps } from '@violetta/ubeya/utils';
import { Field } from 'react-final-form';
import { DateInputPicker } from '../DatePicker';
import { FormFieldProps } from './types';

export const DatePickerField: FC<
  FormFieldProps<Date, InferProps<typeof DateInputPicker>>
> = ({ fieldProps, props, component: Component }) => (
  <Field<Date>
    {...fieldProps}
    render={({ input }) => (
      <Component {...props} onChange={input.onChange} value={input.value} />
    )}
  />
);
