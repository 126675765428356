import { useLocale, useTranslation } from '@violetta/ubeya/i18n';
import moment from 'moment';
import React, { FC, useMemo, useState } from 'react';
import DatePickerComponent from 'react-native-date-picker';
import styled from 'styled-components/native';
import { Button } from '../Buttons';
import { BaseView, FlexRow } from '../Layout';

const Container = styled(BaseView)`
  margin-bottom: 20px;
  align-items: center;
`;

const ButtonContainer = styled(BaseView)`
  width: 100%;
  margin-bottom: 20px;
  padding: 0 20px;
`;

interface Props {
  onSelected?: (value: Date) => void;
  value?: Date;
  color?: string;
  rtl: boolean;
}

export const DatePicker: FC<Props> = ({ onSelected = () => {}, value }) => {
  const [selectedDate, setSelectedDate] = useState(
    moment(value || undefined).toDate()
  );
  const { locale } = useLocale();
  const { t } = useTranslation();

  return (
    <Container>
      <DatePickerComponent
        locale={locale}
        mode="date"
        date={selectedDate}
        onDateChange={setSelectedDate}
        androidVariant="nativeAndroid"
        textColor="#000000"
      />
      <ButtonContainer>
        <Button
          type="primary"
          title={t('saveChanges')}
          onPress={() => onSelected(selectedDate)}
        />
      </ButtonContainer>
    </Container>
  );
};
