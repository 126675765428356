import styled from 'styled-components/native';

export const BaseText = styled.Text.attrs({ maxFontSizeMultiplier: 1 })`
  color: ${({ theme }) => theme.colors.textPrimary};
  text-align: left;
`;

/**
 * Light
 */
export const BaseLightText = styled(BaseText)`
  font-family: ${({ theme }) => theme.fonts.light.family};
  font-weight: ${({ theme }) => theme.fonts.light.weight};
`;

export const H1Light = styled(BaseLightText)`
  font-size: 28px;
`;

export const H2Light = styled(BaseLightText)`
  font-size: 24px;
`;

export const H3Light = styled(BaseLightText)`
  font-size: 20px;
`;

export const H4Light = styled(BaseLightText)`
  font-size: 18px;
`;

export const H5Light = styled(BaseLightText)`
  font-size: 16px;
`;

export const H6Light = styled(BaseLightText)`
  font-size: 14px;
`;

export const H7Light = styled(BaseLightText)`
  font-size: 12px;
`;

/**
 * Regular
 */
export const BaseRegularText = styled(BaseText)`
  font-family: ${({ theme }) => theme.fonts.regular.family};
  font-weight: ${({ theme }) => theme.fonts.regular.weight};
`;

export const H1Regular = styled(BaseRegularText)`
  font-size: 28px;
`;

export const H2Regular = styled(BaseRegularText)`
  font-size: 24px;
`;

export const H3Regular = styled(BaseRegularText)`
  font-size: 20px;
`;

export const H4Regular = styled(BaseRegularText)`
  font-size: 18px;
`;

export const H5Regular = styled(BaseRegularText)`
  font-size: 16px;
`;

export const H6Regular = styled(BaseRegularText)`
  font-size: 14px;
`;

export const H7Regular = styled(BaseRegularText)`
  font-size: 12px;
`;

/**
 * Medium
 */
export const BaseMediumText = styled(BaseText)`
  font-family: ${({ theme }) => theme.fonts.medium.family};
  font-weight: ${({ theme }) => theme.fonts.medium.weight};
`;

export const H1Medium = styled(BaseMediumText)`
  font-size: 28px;
`;

export const H2Medium = styled(BaseMediumText)`
  font-size: 24px;
`;

export const H3Medium = styled(BaseMediumText)`
  font-size: 20px;
`;

export const H4Medium = styled(BaseMediumText)`
  font-size: 18px;
`;

export const H5Medium = styled(BaseMediumText)`
  font-size: 16px;
`;

export const H6Medium = styled(BaseMediumText)`
  font-size: 14px;
`;

export const H7Medium = styled(BaseMediumText)`
  font-size: 12px;
`;

/**
 * Bold
 */
export const BaseBoldText = styled(BaseText)`
  font-family: ${({ theme }) => theme.fonts.bold.family};
  font-weight: ${({ theme }) => theme.fonts.bold.weight};
`;

export const H1Bold = styled(BaseBoldText)`
  font-size: 28px;
`;

export const H2Bold = styled(BaseBoldText)`
  font-size: 24px;
`;

export const H3Bold = styled(BaseBoldText)`
  font-size: 18px;
`;

export const H4Bold = styled(BaseBoldText)`
  font-size: 14px;
`;

export const H5Bold = styled(BaseBoldText)`
  font-size: 12px;
`;
