import React, { FC } from 'react';
import { IAccount } from '@violetta/ubeya/entities';
import {
  BaseView,
  FlexRow,
  H3Regular,
  H4Regular,
  RipplePressable,
  SmallLoader,
} from '@violetta/ubeya/ui';
import { Image as BaseImage } from 'react-native';
import styled from 'styled-components/native';
import { useTranslation } from '@violetta/ubeya/i18n';

const Container = styled(FlexRow)`
  shadow-color: rgba(0, 0, 0, 0.07);
  shadow-opacity: 1;
  shadow-offset: 0px 2px;
  shadow-radius: 20px;
  elevation: 10;

  justify-content: center;
  align-items: center;
  padding: 0 25px;
`;

const Title = styled(H3Regular)`
  font-weight: 500;
  flex: 1;
  padding-left: 5px;
  font-size: 18px;
`;

const Body = styled(BaseView)`
  padding: 15px 0;
  border-bottom-width: 1px;
  margin-left: 10px;
  border-color: ${({ theme }) => theme.colors.gray11};
  flex: 1;
  justify-content: center;
`;
const Content = styled(FlexRow)``;
const Details = styled(FlexRow)`
  align-items: center;
  padding: 10px 0 8px;
`;

const Image = styled(BaseImage)`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-top: 5px;
`;

const Message = styled(H4Regular)`
  font-size: 14px;
  margin-right: 10px;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray2};
  padding-left: 5px;
  flex: 1;
`;

const LoaderContainer = styled(BaseView)`
  margin-right: 10px;
`;
interface Props {
  account: IAccount;
  onSelected: (accountId: number) => void;
  isLoading: boolean;
}

export const AccountRoomRow: FC<Props> = ({
  account,
  onSelected,
  isLoading,
}) => {
  const { name, logo } = account;
  const { t } = useTranslation();

  return (
    <Container
      as={RipplePressable}
      onPress={() => !isLoading && onSelected(account.id)}
    >
      <Image source={{ uri: logo }} />
      <Body>
        <Details>
          <Title>{name} </Title>
          {isLoading && (
            <LoaderContainer>
              <SmallLoader />
            </LoaderContainer>
          )}
        </Details>

        <Content>
          <Message numberOfLines={1}>{t('startChat')}</Message>
        </Content>
      </Body>
    </Container>
  );
};
