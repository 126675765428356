import axios from 'axios';

const api = (fn: any, args: any) =>
  fn.apply(axios, args).then(
    (res: any) => res.data,
    (res: any) => Promise.reject(res)
  );

export function get(...rest: any[]) {
  return api(axios.get, rest);
}

export function post(...rest: any[]) {
  return api(axios.post, rest);
}

export function put(...rest: any[]) {
  return api(axios.put, rest);
}

export function httpDelete(...rest: any[]) {
  return api(axios.delete, rest);
}

export function patch(...rest: any[]) {
  return api(axios.patch, rest);
}

export function delay<T>(
  data: T,
  millisecs = 1000,
  error: boolean = false
): Promise<T> {
  return new Promise<T>((resolve, reject) =>
    setTimeout(() => {
      const func = error ? reject : resolve;
      func(typeof data === 'function' ? data() : data);
    }, millisecs)
  );
}
