import ar from './ar.json';
import de from './de.json';
import en from './en.json';
import es from './es.json';
import he from './he.json';
import nl from './nl.json';
import ru from './ru.json';

const languages = {
  en,
  he,
  ar,
  de,
  es,
  nl,
  ru,
} as Record<string, any>;

const DEFAULT_FALLBACK_LNG = 'en';

const resources = Object.keys(languages).map((key) => {
  return {
    key,
    lng: languages[key],
    fallback: [key, DEFAULT_FALLBACK_LNG],
  };
});

export default resources;
