import React, { FC } from 'react';

import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { ManagementHeader } from '../components/ManagementHeader';
import { ManagementScreen } from '../screens/ManagementScreen';
import { ManagementScreenNames } from './ManagementScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const ManagementNavigation: FC = () => (
  <Navigator>
    <Screen
      name={ManagementScreenNames.MAIN}
      component={ManagementScreen}
      options={{
        header: (props: NativeStackHeaderProps) => (
          <ManagementHeader {...props} />
        ),
      }}
    />
  </Navigator>
);
