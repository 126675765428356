import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { icons, images } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import { IChatRoom } from '@violetta/ubeya/entities';
import { SvgIcon, BaseView, Loader } from '@violetta/ubeya/ui';
import {
  GiftedChat,
  InputToolbar,
  Bubble as BubbleBase,
  Send,
} from 'react-native-gifted-chat';
import { SvgXml } from 'react-native-svg';
import styled from 'styled-components/native';
import useMessages from '../hooks/useMessages';
import { generateChatId } from '../utils/generateChatId';

const SafeView = styled.SafeAreaView`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.chatGray};
`;

const CustomImageBackground = styled(BaseView)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const SendComponent: FC = (props: Send['props']) => (
  <Send
    {...props}
    containerStyle={{
      borderColor: 'transparent',
      justifyContent: 'center',
      paddingHorizontal: 15,
    }}
  >
    <SvgIcon xml={icons.uiIcons.requests} width={22} height={22} />
  </Send>
);

export const Toolbar: FC = (props) => (
  <InputToolbar
    {...props}
    containerStyle={{
      marginHorizontal: 10,
      paddingTop: 5,
      borderWidth: 1,
      borderColor: 'rgba(131, 134, 163, 0.32)',
      borderRadius: 30,
      marginBottom: 10,
    }}
  />
);

export const Bubble: FC = (props) => (
  <BubbleBase
    {...props}
    wrapperStyle={{
      right: {
        backgroundColor: '#fff',
        padding: 6,
      },
      left: {
        backgroundColor: '#e7e4ff',
        padding: 6,
      },
    }}
    textStyle={{
      left: {
        color: '#252525',
      },
      right: {
        color: '#252525',
      },
    }}
  />
);

interface Props {
  room: IChatRoom;
  id: number;
  type: 'user' | 'admin';
  sendMessage: (params: { roomId: string; message: string }) => Promise<any>;
  readMoreMessages: (params: {
    roomId: string;
    firstMessageId: string;
  }) => Promise<any>;
  isLoadingMoreMessages: boolean;
  isLoading: boolean;
}

export const ChatRoom: FC<Props> = ({
  id,
  type,
  isLoadingMoreMessages,
  readMoreMessages,
  sendMessage,
  room,
}) => {
  const { data: userData } = useUser();
  const { roomId, name } = room;
  const { isLoading, data: messages } = useMessages({
    roomId,
    userId: userData?.id,
    userType: 'user',
  });

  const invertedMessages = useMemo(() => messages?.reverse(), [messages]);

  useEffect(() => {
    if (roomId) api.markReadAllRoomMessages({ roomId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  const onSend = useCallback(
    (messages: any[]) => {
      if (messages[0].text) {
        sendMessage({ roomId, message: messages[0].text });
      }
    },
    [roomId, sendMessage]
  );

  const onLoadEarlier = useCallback(
    // eslint-disable-next-line no-underscore-dangle
    () => readMoreMessages({ roomId, firstMessageId: messages?.[0]?._id }),
    [messages, readMoreMessages, roomId]
  );

  const user = useMemo(
    () => ({
      _id: generateChatId({ id, type }),
    }),
    [id, type]
  );

  return (
    <SafeView>
      <CustomImageBackground>
        <SvgXml
          xml={images.svgs.chatBackground}
          width="100%"
          height="100%"
          preserveAspectRatio="xMinyMin slice"
        />
      </CustomImageBackground>
      {isLoading ? (
        <Loader />
      ) : (
        <GiftedChat
          renderAvatarOnTop
          isLoadingEarlier={isLoadingMoreMessages}
          loadEarlier
          onLoadEarlier={onLoadEarlier}
          renderUsernameOnMessage
          messages={invertedMessages}
          onSend={onSend}
          renderSend={SendComponent}
          renderBubble={Bubble}
          user={user}
          dateFormat="ddd, DD MMM"
          messagesContainerStyle={{ paddingBottom: 20 }}
          renderInputToolbar={Toolbar}
          timeTextStyle={{
            right: { color: '#6d6d78' },
            left: { color: '#6d6d78' },
          }}
        />
      )}
    </SafeView>
  );
};
