import React, { FC } from 'react';

import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { ReleaseHeader } from '../components/ReleaseHeader';
import { ReleaseScreen } from '../screens/ReleaseScreen';
import { ReleaseScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const ReleaseNavigation: FC = () => (
  <Navigator>
    <Screen
      name={ReleaseScreenNames.MAIN}
      component={ReleaseScreen}
      options={{
        header: (props: NativeStackHeaderProps) => <ReleaseHeader {...props} />,
      }}
    />
  </Navigator>
);
