import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useUser } from '@violetta/ubeya/auth';
import {
  Booking,
  useBookings,
  useMandatories,
  usePositions,
  useSequences,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView } from '@violetta/ubeya/ui';
import { uiAlert } from '@violetta/ubeya/utils';
import { Linking, Pressable, SafeAreaView } from 'react-native';
import { ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { BookingsScreenNames } from '../../bookings/navigation';
import { Confirmation } from '../../home/components/ThingsToDoCard/Confirmation';
import { Mandatory } from '../../home/components/ThingsToDoCard/Mandatory';
import { Sequence } from '../../home/components/ThingsToDoCard/Sequence';
import { Swap } from '../../home/components/ThingsToDoCard/Swap';
import { UploadProfilePhoto } from '../../home/components/ThingsToDoCard/UploadProfilePhoto';
import { AppContext } from '../../main/AppContext';
import { SequencesScreenNames } from '../../sequences/navigation';
import { SwapScreenNames } from '../../swap/navigation';

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

type Props = {};

export const TodoListScreen: FC<Props> = () => {
  const [currentConfirmationLoading, setCurrentConformationLoading] =
    useState<number>(0);
  const [lastLoadedMandatory, setLastLoadedMandatory] = useState<number>(-1);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const { t } = useTranslation();
  const { mappedPositions } = usePositions();
  const { data } = useUser();
  const {
    bookings,
    updateConfirmation,
    isUpdatingConfirmation,
    isLoading: isLoadingBookings,
  } = useBookings();
  const { navigate } = useNavigation();
  const {
    bookings: { selectBooking },
  } = useContext(AppContext);

  const image = useMemo(() => data?.image, [data?.image]);

  const navigateBooking = useCallback(
    (bookingId: number) => {
      navigate(BookingsScreenNames.MAIN, {
        screen: BookingsScreenNames.BOOKING,
        params: { bookingId },
      });
    },
    [navigate]
  );

  const navigateSwap = useCallback(
    (bookingProp: Booking) => {
      selectBooking(bookingProp);
      navigate(SwapScreenNames.MAIN, {
        screen: SwapScreenNames.SWAP,
      });
    },
    [navigate, selectBooking]
  );

  const navigateSequence = useCallback(
    (sequenceId: number, instanceId: number) => {
      navigate(SequencesScreenNames.MAIN, {
        screen: SequencesScreenNames.SEQUENCE,
        params: { sequenceId, instanceId },
      });
    },
    [navigate]
  );

  const confirmationCB = useCallback(
    async (projectId, id) => {
      setCurrentConformationLoading(projectId);
      updateConfirmation({ projectId, id });
    },
    [updateConfirmation]
  );

  const confirm = useCallback(
    async ({ booking, projectId, confirmationId }) => {
      const res = await uiAlert({
        title: t('areYouSureYouWishToConfirm'),
        message: booking?.confirmation?.message,
        cancel: {
          text: t('no'),
        },
        save: {
          text: t('yes'),
        },
      });

      if (!res) {
        return;
      }
      confirmationCB(projectId, confirmationId);
    },
    [confirmationCB, t]
  );

  const navigateMandatory = useCallback(async (url) => {
    if (await Linking.canOpenURL(url)) {
      Linking.openURL(url);
    }
  }, []);

  const confirmations = useMemo(
    () =>
      bookings.filter(
        ({ confirmation }) => confirmation && !confirmation.isConfirmed
      ),
    [bookings]
  );

  const swaps = useMemo(
    () =>
      bookings.filter(
        ({ swap }) => !!swap?.id && swap?.typeId === 2 && swap?.status === 0
      ),
    [bookings]
  );

  const {
    isLoading: isLoadingSequencs,
    sequences,
    mappedSequences,
  } = useSequences();
  const {
    isLoading: isLoadingMandatories,
    mandatories,
    refetch: refetchMandatories,
  } = useMandatories();

  const instances = useMemo(
    () =>
      sequences
        .map(({ id, instances }) =>
          instances.map((instanceId) => ({
            id,
            instanceId,
          }))
        )
        .reduce((carry, item) => [...carry, ...item], []),
    [sequences]
  );

  const refretchMandatories = useCallback(
    async (idx) => {
      setLastLoadedMandatory(idx);
      await refetchMandatories();
      setLastLoadedMandatory(-1);
    },
    [refetchMandatories, setLastLoadedMandatory]
  );

  if (isFirstLoad) {
    if (isLoadingSequencs || isLoadingBookings || isLoadingMandatories) {
      return null;
    }
    setIsFirstLoad(false);
  }

  if (
    (confirmations || []).length === 0 &&
    (swaps || []).length === 0 &&
    (instances || []).length === 0 &&
    (sequences || []).length === 0 &&
    (mandatories || []).length === 0 &&
    image
  ) {
    return null;
  }

  return (
    <Container>
      <ScrollView style={{ flex: 1 }}>
        {confirmations.map((item) => (
          <BaseView
            as={Pressable}
            key={item.id}
            onPress={() => navigateBooking(item.id)}
          >
            <Confirmation
              key={item.id}
              isLoading={
                isUpdatingConfirmation &&
                currentConfirmationLoading === item.project.id
              }
              booking={item}
              mappedPositions={mappedPositions}
              onConfirm={() =>
                confirmationCB(item?.project.id, item.confirmation.id)
              }
            />
          </BaseView>
        ))}
        {swaps.map((booking) => (
          <BaseView
            as={Pressable}
            key={booking.id}
            onPress={() => navigateSwap(booking)}
          >
            <Swap
              key={booking.id}
              booking={booking}
              onPress={() => navigateSwap(booking)}
            />
          </BaseView>
        ))}

        {instances.map((item) => (
          <BaseView
            as={Pressable}
            key={item.id}
            onPress={() => navigateSequence(item.id, item.instanceId)}
          >
            <Sequence
              key={item.id}
              sequence={mappedSequences[item.id]}
              onPress={() => navigateSequence(item.id, item.instanceId)}
            />
          </BaseView>
        ))}
        {(mandatories || []).map((item, index) => (
          <Mandatory
            key={index}
            mandatory={item}
            onPress={() => refretchMandatories(index)}
            onUrlPress={() => item.url && navigateMandatory(item.url)}
            isLoading={lastLoadedMandatory === index}
          />
        ))}
        {!image && <UploadProfilePhoto />}
      </ScrollView>
    </Container>
  );
};
