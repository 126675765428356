import {
  BaseText,
  BaseView,
  H1Bold,
  H2Bold,
  H7Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { useStats } from '@violetta/ubeya/utils';
import React, { FC, useCallback } from 'react';
import { Alert, Linking, SafeAreaView } from 'react-native';
import { ScrollView } from 'react-native';
import styled from 'styled-components/native';

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const Box = styled(BaseView)`
  background-color: #f7f8fa;
  padding: 20px 15px;
  margin: 10px 20px;
  border-radius: 10px;
`;

const Title = styled(BaseText)`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
`;

const Topic = styled(BaseText)`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
`;

const ChatTopic = styled(Topic)`
  font-size: 18px;
  margin-bottom: 0;
`;

const Text = styled(BaseText)`
  font-size: 15px;
  margin-bottom: 15px;
`;

const LinkContainer = styled(BaseView)``;
const ChatLinkContainer = styled(BaseView)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const Link = styled(BaseText)`
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #e4418a;
  text-decoration: underline;
  text-decoration-color: #e4418a;
`;

const ChatLink = styled(BaseText)`
  font-size: 20px;
  font-weight: 500;
  color: #676879;
  margin-right: 5px;
  padding: 15px 0;
`;

const Row = styled(BaseView)<{ $withBorder?: boolean }>`
  margin-top: 10px;
  ${({ $withBorder = true }) =>
    $withBorder &&
    `
    border-bottom-width: 1px;
    border-bottom-color: ${({ theme }) => theme.colors.gray11};`}
  padding: 10px 0;
`;
const ChatContainer = styled(BaseView)`
  padding: 10px 15px;
  margin: 10px 20px;
  border-radius: 10px;
`;

const Badge = styled(BaseView)`
  margin-left: 4px;
  margin-top: 3px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.chip};
`;

type Params = {
  onClose?: () => void;
};
export const UserSupport: FC<Params> = ({ onClose }) => {
  const { data: stats } = useStats();

  const email = useCallback((email) => {
    const url = `mailto:${email}`;
    if (!Linking.canOpenURL(url)) {
      Alert.alert('Can not send mail from this device');
      return;
    }
    Linking.openURL(url);
  }, []);

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Container>
        <Box>
          <Title>Hey there,</Title>
          <Title>How can we help you today?</Title>
          <Row>
            <Topic>For pay queries</Topic>
            <LinkContainer
              as={RipplePressable}
              onPress={() => email('payroll@constellation.co.uk')}
            >
              <Link>payroll@constellation.co.uk</Link>
            </LinkContainer>
          </Row>
          <Row>
            <Topic>For HR issues</Topic>
            <LinkContainer
              as={RipplePressable}
              onPress={() => email('hr@constellation.co.uk')}
            >
              <Link>HR@constellation.co.uk</Link>
            </LinkContainer>
          </Row>
          <Row $withBorder={false}>
            <Topic>For general queries</Topic>
            <Text>
              (branch issues, position issues, updating personal information &
              being asked for Company Code)
            </Text>
            <LinkContainer
              as={RipplePressable}
              onPress={() => email('appsupport@constellation.co.uk')}
            >
              <Link>appsupport@constellation.co.uk</Link>
            </LinkContainer>
          </Row>
        </Box>
        <ChatContainer>
          <ChatTopic>Still can't find what you're looking for?</ChatTopic>
          <ChatLinkContainer as={RipplePressable} onPress={onClose}>
            <ChatLink>Chat with us</ChatLink>
            {stats?.hasUnreadMessages && <Badge />}
          </ChatLinkContainer>
        </ChatContainer>
      </Container>
    </ScrollView>
  );
};
