import React, { FC } from 'react';
import { Platform, StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { FlexColumnCenter, FlexRowBetween } from '../Layout/Layout';
import { H4Light } from '../Typography/Typography';
import { RipplePressable } from '../Layout/RipplePressable';

const ClockTimeContainer = styled(FlexColumnCenter)``;
const ClockTimeTitle = styled(H4Light)`
  font-size: 13px;
  font-weight: 500;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : `#2f2f2f`};
`;
const ClockOptions = styled(FlexRowBetween)`
  margin-top: 10px;
`;
const ClockOption = styled(FlexColumnCenter)<{ selected?: boolean }>`
  flex: 1;
  ${Platform.OS === 'web' && 'width: 100px;'}
  border-radius: 10px;
  border-width: 1px;
  padding: 15px 0;
  margin: 0 5px;
  border-color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : `#C2C3C9`};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.surface : theme.colors.surface};
`;

type ClockType = 'startTime' | 'endTime' | 'checkInTime' | 'checkOutTime';

export const ClockTimePicker: FC<{
  showCheckIn: boolean;
  showClockIn: boolean;
  showClockOut: boolean;
  currentName: ClockType;
  setCurrentName: (name: ClockType) => void;
  containerStyle?: StyleProp<ViewStyle>;
}> = React.memo(
  ({
    containerStyle = {},
    showCheckIn,
    showClockIn = true,
    showClockOut = true,
    currentName,
    setCurrentName,
  }) => {
    return (
      <ClockTimeContainer style={containerStyle}>
        <ClockOptions>
          {showClockIn && (
            <ClockOption
              selected={currentName === 'startTime'}
              as={RipplePressable}
              onPress={() => setCurrentName('startTime')}
            >
              <ClockTimeTitle selected={currentName === 'startTime'}>
                Clock In
              </ClockTimeTitle>
            </ClockOption>
          )}
          {showCheckIn && (
            <>
              <ClockOption
                selected={currentName === 'checkInTime'}
                as={RipplePressable}
                onPress={() => setCurrentName('checkInTime')}
              >
                <ClockTimeTitle selected={currentName === 'checkInTime'}>
                  Check In
                </ClockTimeTitle>
              </ClockOption>
              <ClockOption
                selected={currentName === 'checkOutTime'}
                as={RipplePressable}
                onPress={() => setCurrentName('checkOutTime')}
              >
                <ClockTimeTitle selected={currentName === 'checkOutTime'}>
                  Check Out
                </ClockTimeTitle>
              </ClockOption>
            </>
          )}
          {showClockOut && (
            <ClockOption
              selected={currentName === 'endTime'}
              as={RipplePressable}
              onPress={() => setCurrentName('endTime')}
            >
              <ClockTimeTitle selected={currentName === 'endTime'}>
                Clock Out
              </ClockTimeTitle>
            </ClockOption>
          )}
        </ClockOptions>
      </ClockTimeContainer>
    );
  }
);
