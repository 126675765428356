import React, { FC } from 'react';
import styled from 'styled-components/native';
import { BaseView } from '../Layout';

export const Badge = styled(BaseView)`
	border-radius: 3px;
	background-color: ${({ theme }) => theme.colors.green2};
	shadow-color: rgba(0, 0, 0, 0.1);
	shadow-opacity: 1;
	shadow-offset: 0 1px;
	shadow-radius: 10px;
	elevation: 3;
	padding: 1px 10px;
`;
