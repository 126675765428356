import React, { FC, useMemo, useContext } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { DateTextComponent, FlexRowBetween } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { AppContext } from '../../../main/AppContext';
import { checkIsProjectMultiDay } from '../../utils';

const Container = styled(FlexRowBetween)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const OfferHeaderTitle: FC<StackHeaderProps> = () => {
  const {
    offers: { selectedOffer },
  } = useContext(AppContext);

  const isProjectMultiDay = useMemo(
    () =>
      checkIsProjectMultiDay({
        date: selectedOffer?.minShiftDate,
        endDate: selectedOffer?.maxShiftDate,
      }),
    [selectedOffer?.maxShiftDate, selectedOffer?.minShiftDate]
  );

  return (
    <Container>
      <DateTextComponent
        style={{ textAlign: 'center' }}
        date={selectedOffer?.minShiftDate}
        fontSize={18}
        endDate={selectedOffer?.maxShiftDate}
        isMultiDate={isProjectMultiDay}
      />
    </Container>
  );
};
