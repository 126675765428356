import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '@violetta/ubeya/api';
import { IAccountProfile } from '../entities/IAccountProfile';
import { useBranches } from './useBranches';
import { useMemo } from 'react';

export const useAccountProfile = (accountId: number) => {
  const queryClient = useQueryClient();

  const storeKey = ['account-profile', accountId];

  const { mappedBranches } = useBranches();

  const { isPending, data, refetch } = useQuery<{
    data: IAccountProfile;
  }>({
    queryKey: storeKey,
    queryFn: () => api.getAccountProfile(accountId),
    enabled: !!accountId,
  });

  const { mutateAsync: editProfile, isPending: isLoadingEditProfile } =
    useMutation({
      mutationFn: ({ profile }: { profile: IAccountProfile }) =>
        api.updateAccountProfile(accountId, profile),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['account-profile', accountId],
          exact: true,
        });
      },
    });

  const formattedProfile = useMemo(
    () => ({
      links: data?.data?.links || [],
      aboutUs: data?.data?.aboutUs || '',
      branches: (data?.data?.branches || []).filter(
        ({ branchId }) => !!mappedBranches[branchId]
      ),
    }),
    [
      data?.data?.aboutUs,
      data?.data?.branches,
      data?.data?.links,
      mappedBranches,
    ]
  );

  return {
    isLoading: isPending,
    profile: formattedProfile || {},
    isLoadingEditProfile,
    editProfile,
    refetch,
  };
};
