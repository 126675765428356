import { useCallback, useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { IUser, UpdateStatus } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { Alert } from '@violetta/ubeya/alert';
import { createCachedSelector } from 're-reselect';

import { Platform } from 'react-native';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clearCookies } from '../cookies';

import CodePush from 'react-native-code-push';
import AsyncStorage from '@react-native-async-storage/async-storage';

const LOGGED_IN_TIME = 'loggedInTime';
const BUILD_NUMBER: number = 11;

const selector = createCachedSelector(
  (data: IUser) => data,
  (data: IUser) => ({
    userData: data,
    languageId: data?.languageId ?? 7,
    accounts: data?.accounts,
    birthdates: data?.birthdates,
    updateStatus: data?.updateStatus,
    intercom: data?.intercom,
  })
)({
  keySelector: (data, storeKey) => storeKey,
});

type QuerySeelctor = {
  user: IUser;
  languageId: number;
  accounts: number[];
  updateStatus: UpdateStatus;
};

export const useUser = (options?: any) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const storeKey = ['user'];
  const { isPending, isSuccess, data, isError, error, failureCount } = useQuery<
    IUser,
    any
  >({
    queryKey: storeKey,
    queryFn: () => api.fetchUser(BUILD_NUMBER),
    select: (data) => selector(data, storeKey.join('#')),
    retry: false,
    ...options,
  });

  const { userData, languageId, accounts, updateStatus, intercom, birthdates } =
    useMemo(() => {
      const {
        userData = [],
        languageId = 0,
        accounts = [],
        birthdates = [],
        updateStatus = 0,
        intercom = {},
      } = data || {};
      return {
        userData,
        languageId,
        birthdates,
        accounts,
        updateStatus,
        intercom,
      };
    }, [data]);

  const { mutateAsync: login, isPending: isLoginning } = useMutation({
    mutationFn: ({
      code,
      phone,
      email,
      tokenId,
    }: {
      phone?: string;
      code?: string;
      email: string;
      tokenId: string;
    }) => api.userLogin({ phone, code, email, tokenId }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: storeKey, exact: true });
      if (Platform.OS === 'web') {
        localStorage.setItem(LOGGED_IN_TIME, new Date().toString());
      } else {
        CodePush.restartApp();
      }
    },
  });

  const { mutateAsync: subscribe } = useMutation({
    mutationFn: ({ email }: { email: boolean }) =>
      api.updateUserSubscprition({ email }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: storeKey, exact: true });
    },
  });

  const { mutateAsync: editUserName, isPending: isLoadingUser } = useMutation({
    mutationFn: ({
      firstName,
      lastName,
    }: {
      firstName: string;
      lastName: string;
    }) => api.editUserName({ firstName, lastName }),
  });

  const { mutateAsync: uploadPhoto, isPending: isLoadingPhoto } = useMutation({
    mutationFn: ({ base64, type }: { base64: string; type: string }) =>
      api.uploadPhoto({ image: base64, type }),
  });

  const prompt = useCallback(
    ({ title, body, onConfirm }) =>
      Alert.alert(
        title,
        body,
        [
          { text: t('cancel'), type: 'cancel' },
          { text: t('ok'), onPress: onConfirm, type: 'confirm' },
        ],
        { cancelable: false }
      ),
    [t]
  );

  const logoutWithoutPrompt = useCallback(async () => {
    await clearCookies();
    if (Platform.OS === 'web') {
      localStorage.removeItem(LOGGED_IN_TIME);
      await api.userLogout();
    }
    queryClient.setQueryData(storeKey, null);
    await queryClient.invalidateQueries({ queryKey: storeKey });
    queryClient.setQueryData(storeKey, null);
    queryClient.clear();

    // remove all keys (including react query persister cache)
    const allAsyncKeys = await AsyncStorage.getAllKeys();
    await AsyncStorage.multiRemove(allAsyncKeys);
    if (Platform.OS !== 'web') {
      CodePush.restartApp();
    } else {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  }, [queryClient, storeKey]);

  const logout = useCallback(
    async () => {
      prompt({
        title: t('confirmOperation'),
        body: t('logoutAreYouSure'),
        onConfirm: async () => logoutWithoutPrompt(),
      });
    },
    // push("/login");
    [logoutWithoutPrompt, prompt, t]
  );

  const reload = useCallback(async () => {
    queryClient.setQueryData(storeKey, null);
    return queryClient.invalidateQueries({ queryKey: storeKey });
  }, [queryClient, storeKey]);

  return {
    isLoading: isPending,
    isAuth: isSuccess && !isPending && !isError,
    isError,
    data: userData,
    languageId,
    accounts,
    birthdates,
    intercom,
    updateStatus,
    error,
    isSuccess,
    login,
    logout,
    logoutWithoutPrompt,
    reload,
    subscribe,
    uploadPhoto,
    isLoadingPhoto,
    editUserName,
    isLoadingUser,
    failureCount,
    isLoginning,
  };
};
