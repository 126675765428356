import React, { FC, useContext } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Button,
  FlexRow,
  FormGroup,
  H2Bold,
  H3Bold,
  H4Bold,
  H4Light,
  TimeLabelContainerProps,
  TimePicker,
  TimePickerField,
} from '@violetta/ubeya/ui';
import { required } from '@violetta/ubeya/utils';
import moment from 'moment';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts/ConfigContext';
import { useField } from 'react-final-form';
import momentTz from 'moment-timezone';

const TimePickerContainer = styled(FlexRow)`
  justify-content: space-between;
`;

const Dash = styled(BaseText)`
  margin: 10px 20px 0 20px;
`;

const TimeLabelContainer = styled(BaseView)`
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.inputBorder};
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 120px;
`;

const TimeLabel = styled<{ size: number; hasPlaceholder: boolean }>(H4Light)`
  font-size: ${({ size }) => size}px;
  color: ${({ theme, hasPlaceholder }) =>
    hasPlaceholder ? theme.colors.gray2 : theme.colors.textPrimary};
`;

const Container = styled(BaseView)`
  padding: 0 50px;
`;
const FormContent = styled(BaseView)`
  margin: 10px 0 50px;
`;

const Title = styled(H3Bold)`
  margin: 15px 0 5px;
`;

interface Props {}

const CustomTimeLabelContainer: FC<TimeLabelContainerProps> = ({
  setShow,
  value,
  placeholder,
  size = 14,
  required: requiredProp,
}) => (
  <TimeLabelContainer as={Pressable} onPress={() => setShow(true)}>
    <TimeLabel size={size} hasPlaceholder={!moment(value).isValid()}>
      {moment(value).isValid()
        ? moment(value).format('LT')
        : placeholder || '-'}{' '}
      {requiredProp && !moment(value).isValid() && '*'}
    </TimeLabel>
  </TimeLabelContainer>
);
export const EditTimesheetForm: FC<Props> = ({ handleSubmit, dirty }) => {
  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('editTimesheetTitle')}:</Title>
      <FormContent>
        <FormGroup>
          <TimePickerContainer>
            <TimePickerField
              fieldProps={{
                name: 'startTime',
                validate: required,
              }}
              props={{
                transparent: false,
                component: ({ setShow, value }) => (
                  <CustomTimeLabelContainer
                    setShow={setShow}
                    value={value}
                    placeholder={t('startTime')}
                    required
                  />
                ),
              }}
              component={TimePicker}
            />
            <Dash>-</Dash>
            <TimePickerField
              fieldProps={{
                name: 'endTime',
                validate: required,
              }}
              props={{
                transparent: false,
                component: ({ setShow, value }) => (
                  <CustomTimeLabelContainer
                    setShow={setShow}
                    value={value}
                    placeholder={t('endTime')}
                    required
                  />
                ),
              }}
              component={TimePicker}
            />
          </TimePickerContainer>
        </FormGroup>
      </FormContent>
      <Button
        disabled={!dirty}
        title={t('sendForApproval')}
        onPress={handleSubmit}
        type="primary"
      />
    </Container>
  );
};
