import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { api } from '@violetta/ubeya/api';
import { icons } from '@violetta/ubeya/assets';
import { to } from '@violetta/ubeya/await';
import {
  Booking,
  IBranchSchedulingConfig,
  ShiftSwapStatus,
  useBranches,
  useLocations,
  useTimesheets,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { FlexColumnDirection } from '@violetta/ubeya/ui';
import { useCalendar } from '@violetta/ubeya/utils';
import moment from 'moment';
import { Linking, Platform } from 'react-native';
import { Alert } from '@violetta/ubeya/alert';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/native';
import { ChatScreenNames } from '../../chat';
import { ReleaseScreenNames } from '../../release';
import { SwapScreenNames } from '../../swap';
import { TimesheetsScreenNames } from '../../timesheets/navigation/ScreenNames';
import { BookingsScreenNames } from '../navigation/ScreenNames';
import { BookingActionCard } from './BookingActionCard';

const Container = styled(FlexColumnDirection)`
  width: 100%;
  padding: 0 15px;
`;

interface Props {
  booking: Booking | null;
}

export const BookingActions: FC<Props> = React.memo(({ booking }) => {
  const { t } = useTranslation();
  const { timesheets } = useTimesheets();
  const { navigate, replace } = useNavigation();
  const { mappedBranches } = useBranches();
  const { mappedLocations } = useLocations();
  const queryClient = useQueryClient();

  const { getPermissions, saveToCalendar: saveEvent } = useCalendar();

  const saveToCalendar = useCallback(async () => {
    const [err, hasPermissions] = await to(getPermissions());

    if (!hasPermissions || err) {
      Alert.alert(t('youDontHavePermissions'), t('changeCalendarPermissions'), [
        { type: 'cancel', text: 'Close', onPress: () => {} },
        {
          type: 'confirm',
          text: 'Settings',
          onPress: () => Linking.openSettings(),
        },
      ]);
      return;
    }

    await saveEvent({
      title: booking?.compoundedName,
      description: booking?.comments || '',
      startDate: booking?.startTime
        ? moment(booking?.startTime).toDate()
        : moment(booking?.project?.date).toDate(),
      endDate: booking?.endTime
        ? moment(booking?.endTime).toDate()
        : moment(booking?.startTime).add(1, 'hour').toDate() || new Date(),
      location: mappedLocations?.[booking!.locationId!]?.address,
    });
  }, [booking, getPermissions, mappedLocations, saveEvent, t]);

  const bookingId = useMemo(() => booking?.id, [booking]);

  // const shouldDisplayReleaseAction = true;
  const hasSwap = !!booking?.swap?.id;

  const timesheet = useMemo(
    () =>
      timesheets.find((timesheet) => {
        const { shift } = timesheet;
        return shift?.id === bookingId;
      }),
    [bookingId, timesheets]
  );

  const config = useMemo(
    () =>
      booking?.project?.branchId
        ? mappedBranches[booking?.project?.branchId]?.schedulingConfig
        : null,
    [booking?.project?.branchId, mappedBranches]
  ) as IBranchSchedulingConfig;

  const shouldDisplayReleaseAction = useMemo(
    () =>
      config?.showReleaseShift &&
      ![
        ShiftSwapStatus.REQUEST_INITIATED,
        ShiftSwapStatus.REQUEST_APPROVED_BY_REQUESTED,
      ].includes(booking?.swap?.status) &&
      (!booking?.startTime ||
        moment(booking.startTime)
          .subtract(config?.hoursToRequestReleaseBeforeShiftStarts, 'hours')
          .isAfter(moment())),
    [
      booking?.startTime,
      booking?.swap?.status,
      config?.hoursToRequestReleaseBeforeShiftStarts,
      config?.showReleaseShift,
    ]
  );

  const shouldDisplaySwapAction = useMemo(
    () =>
      !(booking?.release && booking?.release?.status === 0) &&
      config?.showSwapShift &&
      moment().isBefore(moment(booking?.startTime)),
    [booking?.release, booking?.startTime, config?.showSwapShift]
  );

  const shouldDisplayPassCardAction = useMemo(
    () => config?.showPassCard,
    [config?.showPassCard]
  );

  return (
    <Container>
      {!!booking?.captainType && (
        <BookingActionCard
          onPress={() =>
            navigate(BookingsScreenNames.MAIN, {
              screen: BookingsScreenNames.CAPTAIN,
              params: {
                bookingId: booking?.project.id,
                shiftDate: booking?.startTime
                  ? moment(booking?.startTime).toDate()
                  : moment(booking?.project?.date).toDate(),
              },
            })
          }
          icon={{
            xml: icons.uiIcons.admin,
            width: 20,
            height: 20,
          }}
          title={t('captain')}
        />
      )}
      <BookingActionCard
        title={t('addToCalendar')}
        icon={{ xml: icons.uiIcons.addToCalendar, height: 20, width: 20 }}
        onPress={saveToCalendar}
      />
      {booking?.projectChat?.roomId !== undefined && Platform.OS !== 'web' && (
        <BookingActionCard
          onPress={() =>
            navigate(ChatScreenNames.ROOM, {
              screen: ChatScreenNames.ROOM,
              params: { roomId: booking?.projectChat?.roomId },
            })
          }
          icon={{
            xml: icons.navIcons.chatEmpty,
            width: 20,
            height: 20,
          }}
          title={t('projectChat')}
        />
      )}
      {Platform.OS !== 'web' && (
        <BookingActionCard
          onPress={async () => {
            const roomId = await (async () => {
              if (booking?.accountChat?.roomId) {
                return booking?.accountChat?.roomId;
              }

              const { roomId } = await api.createRoom({
                accountId: booking.metadata.accountId,
              });
              await queryClient.invalidateQueries({ queryKey: ['rooms'] });
              return roomId;
            })();

            navigate(ChatScreenNames.ROOM, {
              screen: ChatScreenNames.ROOM,
              params: { roomId },
            });
          }}
          icon={{
            xml: icons.navIcons.chatEmpty,
            width: 20,
            height: 20,
          }}
          title={t('oneOnOneChat')}
        />
      )}
      {config?.showTeam && booking?.project?.id && (
        <BookingActionCard
          onPress={() =>
            navigate(BookingsScreenNames.MAIN, {
              screen: BookingsScreenNames.TEAM,
              params: {
                projectId: booking.project.id,
                date: booking?.startTime
                  ? moment(booking?.startTime).toDate()
                  : moment(booking?.project?.date).toDate(),
              },
            })
          }
          icon={{
            xml: icons.uiIcons.team,
            width: 20,
            height: 20,
          }}
          title={t('team')}
        />
      )}
      {booking?.project?.id && (
        <BookingActionCard
          onPress={() =>
            navigate(BookingsScreenNames.MAIN, {
              screen: BookingsScreenNames.PROJECT_FEED,
              params: { projectId: booking?.project?.id },
            })
          }
          icon={{
            xml: icons.navIcons.feedLine,
            width: 20,
            height: 20,
          }}
          title={t('projectFeed')}
        />
      )}
      {timesheet?.startTime && timesheet?.endTime && (
        <BookingActionCard
          title={t('viewTimesheet')}
          icon={{ xml: icons.symbolIcons.schedule2, height: 17, width: 17 }}
          onPress={() => {
            replace(TimesheetsScreenNames.MAIN, {
              screen: TimesheetsScreenNames.TIMESHEET,
              params: { timesheet, timesheetId: timesheet.id },
            });
          }}
        />
      )}
      {config?.showSwapShift && (
        <BookingActionCard
          title={t('swapShift')}
          icon={{ xml: icons.symbolIcons.swapIcon, height: 16, width: 18 }}
          disabled={!shouldDisplaySwapAction}
          disbaledText={t('swapShiftDisable')}
          onPress={() => {
            navigate(SwapScreenNames.MAIN, {
              screen: hasSwap ? SwapScreenNames.SWAP : SwapScreenNames.MAIN,
              params: {
                projectId: booking?.project?.id,
                bookingId: booking?.id,
              },
            });
          }}
          chipSlug={
            [0, 1].includes(booking?.swap?.status)
              ? 'swapPendingChip'
              : undefined
          }
        />
      )}
      {config?.showReleaseShift && (
        <BookingActionCard
          title={t('releaseShift')}
          icon={{ xml: icons?.symbolIcons?.releaseIcon, height: 20, width: 22 }}
          // disabled={shouldDisableRelease}
          disabled={!shouldDisplayReleaseAction}
          disbaledText={t('releaseShiftDisable')}
          onPress={() => {
            navigate(ReleaseScreenNames.MAIN);
          }}
          chipSlug={
            booking?.release?.status === 0 ? 'releasePendingChip' : undefined
          }
        />
      )}
    </Container>
  );
});
