import React, { FC } from 'react';
import NetInfo from '@react-native-community/netinfo';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import { StackHeaderProps } from '@react-navigation/stack';
import { icons } from '@violetta/ubeya/assets';
import { Platform } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { BackButton } from '../Buttons';
import { SvgIcon } from '../Icons';
import { BaseView, FlexRow, FlexRowCenter, RipplePressable } from '../Layout';
import { H4Bold, H4Light } from '../Typography';
import { HeaderTitle } from './HeaderTitle';
import { useRecoilState } from 'recoil';
import { SmallLoader } from '../Loaders';

const SyncingLoader = styled(BaseView)`
  margin-left: 10px;
`;

const Top = styled(BaseView)<{
  isVisible: boolean;
  isPrimaryColor: boolean;
  additionalTop: number;
}>`
  width: 100%;
  padding-top: ${({ additionalTop }) => additionalTop}px;
  min-height: ${Platform.OS === 'ios' ? '120px' : '70px'};
  background-color: ${({ theme, isPrimaryColor }) =>
    isPrimaryColor ? theme.colors.primary : theme.colors.surface};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const Offline = styled(BaseView)`
  background-color: #828393;
  padding: 8px 5px;
`;

const Syncing = styled(FlexRowCenter)`
  background-color: ${({ theme }) => theme.colors.green3};
  padding: 8px 5px;
  justify-content: center;
`;

const OfflineTitle = styled(H4Light)`
  color: ${({ theme }) => theme.colors.surface};
  text-align: center;
  margin-bottom: 2px;
`;

const SyncingTitle = styled(H4Bold)`
  color: ${({ theme }) => theme.colors.surface};
  text-align: center;
  margin-bottom: 2px;
  font-size: 17px;
`;

const OfflineMessage = styled(H4Light)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.surface};
  text-align: center;
`;

const HeaderComponentContainer = styled(BaseView)<{
  hasRightContent?: boolean;
}>`
  ${({ hasRightContent }) =>
    !hasRightContent &&
    `width: 40px;
  height: 40px;`}
  border-radius: 20px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Empty = styled(BaseView)`
  height: 15px;
  width: 50px;
`;

const IconLeftContainer = styled(BaseView)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export type CustomHeaderProps = {
  headerTitle?: FC<StackHeaderProps> | false;
  shadowed?: boolean;
  isVisible?: boolean;
  color?: string;
  hideBack?: boolean;
  hasRightContent?: boolean;
  isPrimaryColor?: boolean;
  rightContent?: FC | false;
  hasLeftMenu?: boolean;
  hasRightNotifiction?: boolean;
  rtl: boolean;
  t: (prop: string) => {};
  unsyncedTimesheets?: Set<number>;
};

type Props = StackHeaderProps & CustomHeaderProps;

export const HeaderBar: FC<Props> = (props: Props) => {
  const {
    hideBack = false,
    hasRightContent,
    rightContent: RightContentComponent,
    headerTitle: HeaderTitleComponent = HeaderTitle,
    isPrimaryColor = false,
    isVisible = true,
    hasLeftMenu = false,
    rtl,
    t,
    unsyncedTimesheets,
  } = props;

  const { goBack, canGoBack, toggleDrawer } = useNavigation();
  const { top } = useSafeAreaInsets();
  const { isConnected } = NetInfo.useNetInfo();
  const linkTo = useLinkTo();

  const HeaderLeftMenu = () => (
    <IconLeftContainer as={RipplePressable} onPress={toggleDrawer}>
      <SvgIcon
        xml={
          isPrimaryColor
            ? icons.navIcons.menuIconWhite
            : icons.navIcons.menuIcon
        }
        width={24}
        height={24}
        color={isPrimaryColor ? '#ffffff' : '#252525'}
      />
    </IconLeftContainer>
  );

  return (
    <BaseView>
      <Top
        isVisible={isVisible}
        isPrimaryColor={isPrimaryColor}
        additionalTop={top || 0}
      >
        <HeaderComponentContainer>
          {!hideBack && canGoBack() ? (
            <BackButton rtl={rtl} onPress={goBack} />
          ) : hasLeftMenu ? (
            <HeaderLeftMenu />
          ) : Platform.OS === 'web' ? (
            <BackButton
              rtl={rtl}
              onPress={() => {
                const currUrl = document.location.pathname
                  ?.split('/')
                  ?.slice(0, -1)
                  ?.join('/');
                if (currUrl) {
                  linkTo(currUrl);
                }
              }}
            />
          ) : (
            <Empty />
          )}
        </HeaderComponentContainer>
        <HeaderTitleComponent {...props} />
        <HeaderComponentContainer hasRightContent={hasRightContent}>
          {hasRightContent ? <RightContentComponent /> : <Empty />}
        </HeaderComponentContainer>
      </Top>
      {isConnected === false && t && (
        <Offline>
          <OfflineTitle>{t('youAreOfflineTitle')}</OfflineTitle>
          <OfflineMessage>{t('youAreOfflineMessage')}</OfflineMessage>
          {unsyncedTimesheets && unsyncedTimesheets?.size > 0 && (
            <OfflineMessage>
              ({unsyncedTimesheets.size} timesheets will be synced once online)
            </OfflineMessage>
          )}
        </Offline>
      )}
      {isConnected && unsyncedTimesheets && unsyncedTimesheets?.size > 1 && (
        <Syncing>
          <SyncingTitle>Syncing timesheets to server</SyncingTitle>
          <SyncingLoader>
            <SmallLoader color="#ffffff" />
          </SyncingLoader>
        </Syncing>
      )}
    </BaseView>
  );
};
