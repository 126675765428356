import React, { FC } from 'react';

interface Props {
  xml: FC<{ width?: number; height?: number; viewBox?: string }>;
  width: number;
  height: number;
  rotation?: number;
  color?: string;
  defaultViewBox?: boolean;
}

// const getViewPortSize = (size: number) => size + 5; //Math.pow(Math.ceil(Math.sqrt(size)), 2);

export const SvgXml: FC<Props> = ({
  xml: Icon,
  width,
  height,
  defaultViewBox = true,
}) => {
  const defaultProps = {
    width,
    height,
  };

  const props = {
    ...defaultProps,
    ...(defaultViewBox ? { viewBox: `0 0 ${width} ${height}` } : {}),
  };
  return <Icon {...props} />;
};
