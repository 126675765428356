import React, { FC, useCallback } from 'react';
import {
  BaseSelect,
  FlexRow,
  H5Medium,
  H6Medium,
  SelectProps,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

interface ILanguage {
  id: string;
  title: string;
}

type Props = Omit<
  SelectProps<ILanguage, ILanguage['id']>,
  'optionToSelectedOption'
>;

const Container = styled(FlexRow)``;

const Title = styled(H6Medium)`
  color: ${({ theme }) => theme.colors.surface};
  font-weight: 500;
`;

const ListTitle = styled(H5Medium)`
  color: ${({ theme }) => theme.colors.gray1};
  font-weight: 500;
`;

const SelectedOptionChip: FC<ILanguage> = ({ title }) => (
  <Container>
    <Title>{title}</Title>
  </Container>
);
const ListOption: FC<ILanguage> = ({ title }) => (
  <Container>
    <ListTitle>{title}</ListTitle>
  </Container>
);

export const LanguageSelect: FC<Props> = ({ ...props }) => {
  const optionToSelectedOption = useCallback(
    ({ id }: ILanguage) => id as ILanguage['id'],
    []
  );

  return (
    <BaseSelect
      {...props}
      optionToSelectedOption={optionToSelectedOption}
      renderSelectedOptionChip={SelectedOptionChip}
      renderOption={ListOption}
    />
  );
};
