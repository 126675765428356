export type AvailabilityPeriod = 'weekly' | 'monthly' | 'bi-weekly';

export enum CLOCK_METHODS {
  SELF = 0,
  EMPLOYEE_QR_SCAN = 1,
  MANAGER_QR_SCAN = 2,
}
export type MobileClockMethods = {
  clockIn?: {
    method: CLOCK_METHODS;
  };
  clockOut?: {
    method: CLOCK_METHODS;
  };
  checkIn?: {
    method: CLOCK_METHODS;
  };
  checkOut?: {
    method: CLOCK_METHODS;
  };
};
export interface IBranch {
  id: number;
  accountId: number;
  locationId: number;
  name: string;
  logo: string | null;
  onsiteContact: string;
  onsiteContactPhone: string;
  timesheetConfig: IBranchTimesheetConfig;
  schedulingConfig: IBranchSchedulingConfig;
  availabilityPeriod: AvailabilityPeriod;
}

export interface IBranchSchedulingConfig {
  period: AvailabilityPeriod;
  showTeam: boolean;
  autoBook: boolean;
  isShowOfferWage: boolean;
  isShowBookingWage: boolean;
  maskLocationAddressInAppOffers: boolean;
  maskClientInAppOffers: boolean;
  maskGeneralCommentsInAppOffers: boolean;
  maskNotesInAppOffers: boolean;
  isHasCarOnApply: boolean;
  periodMarkAllDay: boolean;
  showReleaseShift: boolean;
  showBreaksNotice: boolean;
  showSwapShift: boolean;
  showPassCard: boolean;
  hoursToRequestReleaseBeforeShiftStarts?: number;
  isRemoteWork?: boolean;
}

export interface IBranchTimesheetConfig {
  isQRCodeRequired: boolean;
  qrCodeSecret?: string;
  toRequireClockBreaks: boolean;
  toAllowGpsWhenClock: boolean;
  toAllowClockWithMobile: boolean;
  toRequireCheckInWithMobile: boolean;
  distanceFromShiftAddress: number;
  defaultJobStartTime: Date | null;
  defaultJobEndTime: Date | null;
  toForceRestrictions: boolean;
  clockInBeforeShiftStart: number | null;
  clockOutAfterShiftEnd: number | null;
  isCaptainAutoClock?: boolean;
  mobileClockMethods: MobileClockMethods;
  automaticBreakTime?: boolean | null;
}
