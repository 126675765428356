import { useMemo, useContext } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { useTranslation } from '@violetta/ubeya/i18n';
import { selectedAccount } from '@violetta/ubeya/shared';
import { mappedArray } from '@violetta/ubeya/utils';
import { createCachedSelector } from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';

const selector = createCachedSelector(
  (data) => data.data,
  (data, t) => t,
  (data, t) => {
    const suppliers = data.data || [];
    const activeSuppliers = suppliers.filter(({ isDeleted }) => !isDeleted);
    const inactiveSuppliers = suppliers.filter(({ isDeleted }) => isDeleted);
    const mappedActiveSuppliers = mappedArray(
      activeSuppliers,
      (item) => item?.accountId
    );

    const suppliersOptions = activeSuppliers.reduce(
      (prev, { name, branches }) => [
        ...prev,
        ...branches.map((branch) => ({
          value: branch.id,
          id: branch.id,
          label: name === branch.name ? name : `${name} - ${branch.name}`,
        })),
      ],
      []
    );

    const mappedSupplierBranches = activeSuppliers.reduce(
      (prev, supplier) => ({
        ...prev,
        ...(supplier?.branches || []).reduce(
          (all, { id, name, timesheetConfig }) => ({
            ...all,
            [id]: {
              ...supplier,
              timesheetConfig,
              name:
                supplier.name === name
                  ? supplier.name
                  : `${supplier.name} - ${name}`,
            },
          }),
          {}
        ),
      }),
      {}
    );

    const mappedSupplierPositions = activeSuppliers.reduce(
      (prev, supplier) => ({
        ...prev,
        ...(supplier?.positions || []).reduce(
          (all, { id, ...position }) => ({
            ...all,
            [id]: {
              ...position,
              slug: t(position.slug),
              label: t(position.slug),
            },
          }),
          {}
        ),
      }),
      {}
    );

    const hasSuppliers = activeSuppliers.length > 0;
    return {
      suppliers,
      activeSuppliers,
      inactiveSuppliers,
      mappedActiveSuppliers,
      suppliersOptions,
      mappedSupplierBranches,
      mappedSupplierPositions,
      hasSuppliers,
    };
  }
)({
  keySelector: (data, t, storeKey) => storeKey.join('#'),
});

export const useSuppliers = () => {
  const [account] = useRecoilState(selectedAccount);
  const { t } = useTranslation();
  const storeKey = ['suppliers', account?.id];
  const { isPending, data } = useQuery({
    queryKey: storeKey,
    queryFn: () => adminApi.fetchSuppliers({ accountId: account?.id }),
    enabled: !!account?.id,
    select: (data) => selector(data, t, storeKey),
  });

  const {
    suppliers,
    hasSuppliers,
    inactiveSuppliers,
    activeSuppliers,
    suppliersOptions,
    mappedSupplierBranches,
    mappedSupplierPositions = [],
    mappedActiveSuppliers,
  } = useMemo(() => {
    const {
      suppliers: rawSuppliers,
      hasSuppliers: rawHasSuppliers,
      inactiveSuppliers: rawInactiveSuppliers,
      activeSuppliers: rawActiveSuppliers,
      suppliersOptions: rawSuppliersOptions,
      mappedSupplierBranches: rawMappedSupplierBranches,
      mappedSupplierPositions: rawMappedSupplierPositions,
      mappedActiveSuppliers: rawMappedActiveSuppliers,
    } = data || {};
    return {
      suppliers: rawSuppliers,
      hasSuppliers: rawHasSuppliers,
      inactiveSuppliers: rawInactiveSuppliers,
      activeSuppliers: rawActiveSuppliers,
      suppliersOptions: rawSuppliersOptions,
      mappedSupplierBranches: rawMappedSupplierBranches,
      mappedSupplierPositions: rawMappedSupplierPositions,
      mappedActiveSuppliers: rawMappedActiveSuppliers,
    };
  }, [data]);

  return {
    isLoading: isPending,
    suppliers,
    hasSuppliers,
    inactiveSuppliers,
    activeSuppliers,
    suppliersOptions,
    mappedSupplierBranches,
    mappedSupplierPositions,
    mappedActiveSuppliers,
  };
};
