export const to = <T,>(promise: Promise<T>, errorExt?: object): Promise<readonly [Error, null] | readonly [null, T]> =>
  promise
    .then((data: T) => [null, data] as const)
    .catch((err: Error) => {
      if (errorExt) {
        // eslint-disable-next-line no-param-reassign
        err = Object.assign(err, errorExt);
      }

      return [err, null] as const;
    });
