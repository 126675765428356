import { DefaultTheme } from 'styled-components/native';
import Config from 'react-native-config';

const defaultColors = {
  primary: '#6467EF',
  primaryDark: '#03399c',
  primarySemiLight: '#0672ff',
  primaryLighter: '#eceef9',
  primaryLight: '#6659FF',
  primaryLight5: '#6467EF4D',
  primaryLight2: '#efefef',
  primaryLight3: '#efeefe',
  primaryLight4: '#eeeeee',
  primary3: '#f5f5ff',
  buttonPrimaryNormal: '#645DF6',
  buttonPrimaryHover: '#5a53dd',
  whitesmoke: '#f5f5f5',
  offwhitesmoke: '#f5f5f7',
  background: '#ffffff',
  lightBackground: '#f2f3f5',
  selectBackground: '#f4f8ff',
  secondary: '#5ae2e2',
  secondaryTitle: '#1e2534',
  seconday2: '#FF8833',
  lightOrange: '#f6cc0c',
  surface: '#ffffff',
  decline: '#ff0000',
  decline2: '#ff6163',
  auditLogOrange: '#FF8833',
  auditLogYellow: '#FFD12F',
  lightSurface: '#ffffff',
  red1: '#fb8f8f',
  redLight1: '#ff7f7f',
  chip: '#ff3f6d',
  link: '#005aff',
  blue1: '#005aff',
  blue2: '#016dff',
  green1: '#2fcda3',
  green2: '#89eaa8',
  green3: '#46cb88',
  gray1: '#595959',
  gray2: '#8c8c8c',
  gray3: '#d8d8d8',
  gray4: '#e8e8e8',
  gray5: '#fafcff',
  gray6: '#bfbfbf',
  gray7: '#bcbcbc',
  gray8: '#fbfdff',
  gray9: '#f0f3f8',
  gray10: '#d5d4d4',
  gray11: '#eaeaea',
  gray12: '#6d6d78',
  gray13: '#c4c6ca',
  gray15: '#7D7D7D',
  gray16: '#2525254c',
  gray17: '#f1f1f1',
  gray18: '#979797',
  gray19: '#ccd8ff',
  textPrimary: '#252525',
  white: '#ffffff',
  lightYellow: '#f9f9f9',
  yellow1: '#fbbe4d',
  inputBorder: '#eaeaea',
  inputBackground: '#fafcff',
  inputTextPlaceholder: '#bfbfbf',
  inputText: '#595959',
  success: '#3cd7a9',
  successSemiLight: '#38dfad',
  successLight: '#9eebd4',
  income: '#46cc88',
  approve: '#46cc88',
  lighterGreen: 'rgba(70, 204, 136, 0.1)',
  lighterOrange: 'rgba(255, 136, 51, 0.1)',
  lighterRed: 'rgba(255, 63, 109, 0.1)',
  error: '#e22212',
  error2: '#ff3333',
  chatPurple: '#e7e4ff',
  chatGray: '#f6f7fb',
  skyBlue: '#eaf9fe',
  beige: '#fff2de',
  lightPurple: '#dde5ff',

  compliance: {
    full: '#00ba8d',
    partial: '#e9a11b',
    none: '#E8E8E8',
    plus: '#f6f6f6',

    expired: {
      light: 'rgba(255,100, 100,0.10)',
      dark: '#ff6464',
    },
    approved: {
      light: 'rgba( 0,186, 141,0.10)',
      dark: '#00BA8D',
    },
    pending: {
      light: 'rgba(254,177, 31,0.10)',
      dark: '#FEB11F',
    },
    declined: {
      light: 'rgba(255,100, 100,0.10)',
      dark: '#FE6464',
    },
  },
};

export const constellationColors = {
  ...defaultColors,
  primary: '#081b3e',
  buttonPrimaryNormal: '#0f0f0f',
  secondary: '#32c2ed',
  chip: '#f8298c',
  seconday2: '#6f0a3c',
};

export const colors =
  Config.APP_NAME === 'Constellation' ? constellationColors : defaultColors;

export const theme: DefaultTheme = {
  fonts: {
    light: {
      family: 'Rubik-Light',
      weight: 300,
    },
    regular: {
      family: 'Rubik-Regular',
      weight: 'normal',
    },
    medium: {
      family: 'Rubik-Medium',
      weight: 300,
    },
    bold: {
      family: 'Rubik-Bold',
      weight: 700,
    },
  },
  colors,
  button: {
    primary: {
      normal: {
        background: colors.buttonPrimaryNormal,
        color: 'white',
        border: 'transparent',
      },
      hover: {
        background: colors.buttonPrimaryHover,
        color: 'white',
        border: 'transparent',
      },
      disabled: {
        background: 'rgba(151, 151, 151, 0.7)',
        color: 'white',
        border: 'transparent',
      },
    },
    primaryReversed: {
      normal: {
        background: 'white',
        color: colors.primary,
        border: colors.primary,
      },
      hover: {
        background: colors.surface,
        color: colors.primary,
        border: colors.primary,
      },
      disabled: {
        background: 'white',
        color: 'rgba(151, 151, 151, 0.7)',
        border: 'rgba(151, 151, 151, 0.7)',
      },
    },
    secondary: {
      normal: {
        background: colors.secondary,
        color: 'white',
        border: colors.secondary,
      },
      hover: {
        background: '#51cbcb',
        color: 'white',
        border: '#51cbcb',
      },
      disabled: {
        background: '#51cbcb',
        color: 'white',
        border: '#51cbcb',
      },
    },
    tertiary: {
      normal: {
        background: colors.surface,
        color: colors.primary,
        border: 'transparent',
      },
      hover: {
        background: colors.background,
        color: colors.primary,
        border: 'transparent',
      },
      disabled: {
        background: colors.surface,
        color: colors.gray2,
        border: 'transparent',
      },
    },
    success: {
      normal: {
        background: colors.success,
        color: colors.surface,
        border: 'transparent',
      },
      hover: {
        background: colors.successSemiLight,
        color: colors.surface,
        border: 'transparent',
      },
      disabled: {
        background: colors.successLight,
        color: colors.surface,
        border: 'transparent',
      },
    },
    link: {
      general: {
        borderWidth: '0 0 1px 0',
        padding: 0,
        borderRadius: 0,
      },
      normal: {
        background: 'transparent',
        color: colors.primary,
        border: colors.primarySemiLight,
      },
      hover: {
        background: 'transparent',
        color: colors.primarySemiLight,
        border: colors.primarySemiLight,
      },
      disabled: {
        background: 'transparent',
        color: colors.gray5,
        border: colors.gray5,
      },
    },
  },
};
