import React, { FC } from 'react';

import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderlessBar } from '@violetta/ubeya/ui';
import { useTheme } from 'styled-components/native';
import { JoinBranchHeader } from '../components/headers/JoinBranchHeader';
import { OnboardingFormScreen } from '../screens';
import { OnboardingScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const OnboardingNavigation: FC = () => {
  const { colors } = useTheme();

  return (
    <Navigator
      initialRouteName={OnboardingScreenNames.ONBOARDING_FORM}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
      }}
    >
      <Screen
        name={OnboardingScreenNames.ONBOARDING_FORM}
        component={OnboardingFormScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <JoinBranchHeader {...props} />
          ),
        }}
      />
    </Navigator>
  );
};
