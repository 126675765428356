import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useNetInfo } from '@react-native-community/netinfo';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { lotties } from '@violetta/ubeya/assets';
import {
  CustomTimesheetDTO,
  useAccounts,
  useBranches,
  useLabels,
  useLocations,
  usePositions,
  useTimesheetsHistory,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  CustomLoaderWrapped,
  EmptyState,
  FlatList,
  FlexColumnDirection,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { RefreshControl } from 'react-native';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/native';
import { TimesheetCard } from '../components/TimesheetCard';
import { TimesheetsScreenNames } from '../navigation/ScreenNames';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

interface Props {}

type ParamList = {
  params: {
    accountId?: number;
  };
};

export const TimesheetsScreen: FC<Props> = () => {
  const queryClient = useQueryClient();
  const { params } = useRoute<RouteProp<ParamList, 'params'>>();
  const accountId = useMemo(() => params?.accountId, [params]);
  const { t } = useTranslation();
  const {
    timesheets,
    isLoading: isLoadingTimesheetsHistory,
    refetch,
  } = useTimesheetsHistory(accountId);
  const { navigate } = useNavigation();
  const { isConnected } = useNetInfo();
  const { mappedAccounts, isLoading: isLoadingAccounts } = useAccounts();
  const { mappedPositions } = usePositions();
  const { mappedBranches } = useBranches();
  const { mappedLabels } = useLabels();
  const { mappedLocations } = useLocations();

  const isLoading = isLoadingTimesheetsHistory || isLoadingAccounts;
  const navigateTimesheet = useCallback(
    (timesheet: CustomTimesheetDTO) => {
      navigate(TimesheetsScreenNames.MAIN, {
        screen: TimesheetsScreenNames.TIMESHEET,
        params: { timesheet, timesheetId: timesheet.id },
      });
    },
    [navigate]
  );

  useEffect(
    () =>
      // if we are in context of a specific account, remove it from query cache
      () => {
        if (accountId && isConnected) {
          queryClient.invalidateQueries({
            predicate: ({ queryKey }) =>
              queryKey?.[0] === 'timesheetsHistory' &&
              queryKey?.[1] === String(accountId),
          });
        }
      },
    [accountId, isConnected, queryClient]
  );

  const sortedTimesheets = useMemo(
    () =>
      timesheets
        .filter((timesheet) => {
          const date = moment(
            timesheet.job?.date ||
              timesheet.shift?.date ||
              timesheet.shift?.project?.date
          );
          return date.isBefore(moment(), 'date') || !!timesheet.endTime;
        })
        .sort((timesheet1, timesheet2) => {
          const m1 = moment(
            timesheet1.job?.date ||
              timesheet1.shift?.date ||
              timesheet1.shift?.project?.date
          );
          const m2 = moment(
            timesheet2.job?.date ||
              timesheet2.shift?.date ||
              timesheet2.shift?.project?.date
          );
          return moment(m1).isAfter(m2) ? -1 : 1;
        }),
    [timesheets]
  );

  if ((!timesheets || timesheets.length === 0) && isLoading) {
    return <CustomLoaderWrapped />;
  }

  return (
    <Container>
      <FlatList
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 50,
          marginTop: 15,
        }}
        ListEmptyComponent={
          <EmptyState
            lottieSrc={lotties.noUpcomingShifts}
            title={t('noPastBookings')}
          />
        }
        data={sortedTimesheets}
        scrollEnabled
        refreshControl={
          <RefreshControl onRefresh={refetch} refreshing={isLoading} />
        }
        onEndReachedThreshold={1}
        keyExtractor={(item) => item.id?.toString()}
        renderItem={({ item }) => (
          <TimesheetCard
            key={item.id}
            timesheet={item}
            onPress={() => navigateTimesheet(item)}
            mappedAccounts={mappedAccounts}
            mappedPositions={mappedPositions}
            mappedBranches={mappedBranches}
            mappedLabels={mappedLabels}
            mappedLocations={mappedLocations}
          />
        )}
      />
    </Container>
  );
};
