import React, { useCallback, useContext } from 'react';
import { useAccounts } from '@violetta/ubeya/entities';
import {
  BaseText,
  BaseView,
  FlexRowBetween,
  H4Medium,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import styled from 'styled-components/native';
import { AppContext } from '../../modules/main/AppContext';

const ModalRow = styled(FlexRowBetween)`
  margin-bottom: 33px;
  align-items: center;
`;

const ModalRowText = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #1e2534;
`;

const Oval = styled<{ isSelected: boolean }>(BaseView)`
  width: 16px;
  height: 16px;
  border-width: ${({ isSelected }) => (isSelected ? 5 : 1)}px;
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.colors.gray16};
  border-radius: 32px;
  margin-right: 10px;
`;

const ModalRowComponent = React.memo(({ isSelected, name, onPress }) => (
  <ModalRow as={RipplePressable} onPress={onPress}>
    <FlexRowBetween>
      <Oval isSelected={isSelected} />
      <ModalRowText>{name}</ModalRowText>
    </FlexRowBetween>
  </ModalRow>
));

export const AccountPickerModal = React.memo(({ modalizeRef }) => {
  const { accounts, mappedAccounts } = useAccounts();
  const {
    profile: { selectAccount, selectedAccount },
  } = useContext(AppContext);

  const handleOnChangeAccount = useCallback(
    ({ accountId }) => {
      const tempAccount = mappedAccounts[accountId];
      selectAccount(tempAccount);

      setTimeout(() => {
        modalizeRef.current?.close();
      }, 100);
    },
    [mappedAccounts, modalizeRef, selectAccount]
  );

  return (
    <Portal>
      <Modalize
        ref={modalizeRef}
        adjustToContentHeight
        flatListProps={{
          contentContainerStyle: { padding: 23 },
          data: accounts,
          keyExtractor: () => (Math.random() * 100).toString(),
          renderItem: ({ item }) => (
            <ModalRowComponent
              key={item.id}
              isSelected={selectedAccount?.id === item.id}
              name={item?.name}
              onPress={() => handleOnChangeAccount({ accountId: item.id })}
            />
          ),
          ListEmptyComponent: <BaseView />,
        }}
        handlePosition="inside"
      />
    </Portal>
  );
});
