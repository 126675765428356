import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { IBranch } from '@violetta/ubeya/entities';
import styled from 'styled-components/native';
import { H5Medium, H6Medium } from '../Typography/Typography';
import { FlexRow } from '../Layout/Layout';
import { BaseSelect } from './BaseSelect';
type Props = Omit<
  SelectProps<IBranch, IBranch['id']>,
  'optionToSelectedOption'
>;

const Container = styled(FlexRow)``;

const Title = styled(H6Medium)`
  color: ${({ theme }) => theme.colors.surface};
  font-weight: 500;
`;

const ListTitle = styled(H5Medium)`
  color: ${({ theme }) => theme.colors.gray1};
  font-weight: 500;
`;

const SelectedOptionChip: FC<IBranch> = ({ name }) => (
  <Container>
    <Title>{name}</Title>
  </Container>
);
const ListOption: FC<IBranch> = ({ name }) => (
  <Container>
    <ListTitle>{name}</ListTitle>
  </Container>
);

export const BranchSelect: FC<Props> = React.memo(({ ...props }) => {
  const optionToSelectedOption = useCallback(
    ({ id }: IBranch) => id as IBranch['id'],
    []
  );
  useEffect(() => {
    if ((props.options || []).length === 1) {
      props.onSelected?.([props.options?.[0]?.id], [props.options?.[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // eslint-disable-next-line react/destructuring-assignment
  }, [props.options]);

  // eslint-disable-next-line react/destructuring-assignment
  if ((props.options || []).length === 1) {
    return null;
  }

  return (
    <BaseSelect
      {...props}
      optionToSelectedOption={optionToSelectedOption}
      renderSelectedOptionChip={SelectedOptionChip}
      renderOption={ListOption}
    />
  );
});
