import React, { FC, useContext } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { HeaderBar } from '@violetta/ubeya/ui';
import { ConfigContext } from '../../contexts';
import { useRecoilState, useRecoilValue } from 'recoil';
import { unsyncedTimesheetsAtom } from '../../state';

export const headerFactory = (
  props: StackHeaderProps,
  headerTitle: FC<StackHeaderProps>,
  shadowed: boolean = true
) => {
  const { rtl, t } = useContext(ConfigContext);
  const unsyncedTimesheets = useRecoilValue(unsyncedTimesheetsAtom);

  return (
    <HeaderBar
      {...props}
      rtl={rtl}
      headerTitle={headerTitle}
      shadowed={shadowed}
      t={t}
      unsyncedTimesheets={unsyncedTimesheets}
    />
  );
};
