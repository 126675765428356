import React, { FC, useEffect, useState } from 'react';
import { Image, useWindowDimensions } from 'react-native';
import styled from 'styled-components/native';
import { BaseImage } from '../Images';
import { IGalleryItem } from './IGalleryItem';

interface Props {
	item: IGalleryItem<any>;
}

const GalleryImage = styled(BaseImage)`
	resize-mode: cover;
`;

export const ImageGalleryItem: FC<Props> = ({ item: { uri } }) => {
	const { height: windowHeight, width: windowWidth } = useWindowDimensions();
	const [size, setSize] = useState<{ width: number; height: number } | null>(
		null,
	);

	useEffect(() => {
		Image.getSize(uri, (width, height) => {
			setSize({
				width: windowWidth,
				height: Math.floor((height / width) * windowWidth),
			});
		});
	}, [uri, windowHeight, windowWidth]);

	return (
		<GalleryImage
			// onLoadStart={() => alert("start")}
			// onLoadEnd={() => alert("end")}
			width={size?.width || 0}
			height={size?.height || 0}
			source={{ uri }}
		/>
	);
};
