import moment, { Moment } from 'moment';

export const rangeFormatMoment = (start: Moment | null | undefined, end: Moment | null | undefined | false) => {
  if (!start) return null;
  if (!start.isValid()) return null;
  return `${start.format('LT')}${end && end.isValid() ? ` - ${end.format('LT')}` : ''}`;
};

export const rangeFormat = (startTime: Date | null | undefined, endTime: Date | null | undefined) => {
  const start = !!startTime && moment(startTime);
  const end = !!endTime && moment(endTime);
  if (!start) return null;

  return rangeFormatMoment(start, end);
};
