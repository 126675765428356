import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { useTranslation } from '@violetta/ubeya/i18n';
import { useMutation } from '@tanstack/react-query';

export const useEmployeeRequest = () => {
  const { t } = useTranslation();
  const { data: userData } = useUser();

  const { mutateAsync: createEmployeeRequest, ...result } = useMutation({
    mutationFn: ({
      first_name,
      last_name,
      code,
    }: Record<'first_name' | 'last_name' | 'image' | 'code', string>) =>
      api.createEmployeeCandidate({
        first_name,
        last_name,
        code,
      }),
    throwOnError: false,
  });

  return {
    createEmployeeRequest,
    ...result,
  };
};
