import React, { FC, SVGProps, useContext } from 'react';
import {
  AccountImage,
  BaseView,
  ChevronRight,
  FlexRowCenter,
  H5Medium,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../../contexts';
import { SvgProps } from 'react-native-svg';

const Container = styled(BaseView)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 11px 18px;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray11};
`;

const Details = styled(FlexRowCenter)`
  flex: 1;
`;

const Name = styled(H5Medium)`
  font-weight: normal;
  font-size: 16px;

  margin-left: ${({ $hideIcon }) => ($hideIcon ? 0 : 25)}px;

  flex-wrap: wrap;
`;

const IconWrapper = styled(BaseView)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray11};
`;

interface Props {
  title: string;
  icon?: string | FC<SvgProps>;
  image?: string | undefined;
  onPress?: () => void;
  iconProps?: { height?: number; width?: number };
  hideIcon?: boolean;
  hideArrow?: boolean;
  limitContainerSize?: boolean;
  titleStyle?: any;
}

export const AccountProfileCard: FC<Props> = ({
  title,
  icon,
  image,
  onPress,
  iconProps = {},
  hideIcon = false,
  titleStyle,
  hideArrow = false,
  limitContainerSize = true,
}) => {
  const { rtl } = useContext(ConfigContext);

  return (
    <Container as={RipplePressable} onPress={onPress}>
      <Details>
        {!hideIcon && (
          <IconWrapper>
            {icon && <SvgIcon xml={icon} {...iconProps} />}
            {!icon && (
              <AccountImage
                image={image}
                name={title}
                size={40}
                limitContainerSize={limitContainerSize}
              />
            )}
          </IconWrapper>
        )}
        <Name $hideIcon={hideIcon} style={titleStyle}>
          {title}
        </Name>
      </Details>
      {!hideArrow && <ChevronRight size={25} rtl={rtl} />}
    </Container>
  );
};
