import { HttpMultiton } from '@violetta/ubeya/utils';
// local
// export const baseURL = process.env.API_BASE_URL ?? 'http://localhost:7401';
// export const apiBaseURL = process.env.AUTH_BASE_URL ?? 'http://localhost:7404';
// export const chatURL = 'http://localhost:7405';
// export const chatWSURL = 'http://localhost:7406';
// export const authURL = 'http://localhost:3004';
// export const webURL = 'http://localhost:3000';

// staging
// export const baseURL = process.env.API_BASE_URL ?? 'https://tablet.api.ubeya.dev';
// export const apiBaseURL = process.env.AUTH_BASE_URL ?? 'https://auth.api.ubeya.dev';
// export const chatURL = 'https://chat.api.ubeya.dev';
// export const chatWSURL = 'https://chat-ws.api.ubeya.dev';
// export const authURL = 'https://auth.ubeya.dev';
// export const webURL = 'https://ottavio.ubeya.dev';

// production
export const baseURL =
  process.env.API_BASE_URL ?? 'https://tablet.api.ubeya.com';
export const apiBaseURL =
  process.env.AUTH_BASE_URL ?? 'https://auth.api.ubeya.com';
export const chatURL = 'https://chat.api.ubeya.com';
export const chatWSURL = 'https://chat-ws.api.ubeya.com';
export const authURL = 'https://auth.ubeya.com';
export const webURL = 'https://ottavio.ubeya.com';

const axios = HttpMultiton.getInstance('tablet');

axios.Axios.defaults.baseURL = baseURL;
axios.Axios.defaults.withCredentials = true;
axios.Axios.defaults.responseType = 'json';

export const fetchAdmin = async () => axios.get('/auth/me');

export const refreshToken = () => axios.post(`${apiBaseURL}/tablet/refresh`);

export const tabletLogin = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) =>
  axios.post(
    `${apiBaseURL}/tablet/login`,
    {},
    { email, password, type: 'password' }
  );

export const getBranches = () => axios.get('/branches');

export const getFilledTimesheets = ({
  accountId,
  branchId,
  dateFrom,
  dateTo,
}) =>
  axios.get(
    `/accounts/${accountId}/branches/${branchId}/timesheets?from=${dateFrom}&to=${dateTo}`
  );

export const getEmployeeById = ({
  accountId,
  branchId,
  type,
  data,
  picture,
}: {
  accountId: number;
  branchId: number;
  type: 'photo' | 'phone';
  data: string;
  picture?: string;
}) =>
  axios.post(
    `accounts/${accountId}/branches/${branchId}/employees`,
    {},
    { type, data, picture }
  );

export const getNextTimesheets = ({ accountId, branchId, employeeId }) =>
  axios.get(
    `/accounts/${accountId}/branches/${branchId}/employees/${employeeId}/timesheets`
  );

export const clockInOut = ({
  accountId,
  branchId,
  timesheetId,
  type,
}: {
  accountId: number;
  branchId: number;
  timesheetId: number;
  type: 'in' | 'out';
}) =>
  axios.patch(
    `/accounts/${accountId}/branches/${branchId}/timesheets/${timesheetId}`,
    {},
    { type }
  );

export const breakStartEnd = ({
  accountId,
  branchId,
  timesheetId,
  type,
}: {
  accountId: number;
  branchId: number;
  timesheetId: number;
  type: 'start' | 'end';
}) =>
  axios.put(
    `/accounts/${accountId}/branches/${branchId}/timesheets/${timesheetId}`,
    {},
    { type }
  );
