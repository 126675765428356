import React from 'react';
import { InferProps } from '@violetta/ubeya/utils';
import { FlatList as OriginalFlatList } from 'react-native';

export const FlatList = <T,>({
  children,
  ...props
}: InferProps<OriginalFlatList>) => (
  <OriginalFlatList<T> contentContainerStyle={{ flexGrow: 1 }} {...props}>
    {children}
  </OriginalFlatList>
);
