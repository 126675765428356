import React, { useMemo } from 'react';
import { Defined } from '@violetta/ubeya/utils';
import styled from 'styled-components/native';
import { CheckBox } from '../CheckBoxes';
import { FlexParent, FlexRow, RipplePressable } from '../Layout';
import { Radio } from '../Radios';
import { SelectProps } from './types';

const Container = styled(FlexParent)`
  flex-direction: row;
  align-items: center;
  padding: 20px 0;
  margin: 0 30px;
`;

const Margined = styled(FlexRow)`
  margin: 0 15px;
  text-align: left;
`;

interface Props<Option> {
  option: Option;
  index: number;
  isSelected: boolean;
  isMulti?: boolean;
  onPress?: (option: Option) => void;
  renderOption: Defined<SelectProps<Option, any>['renderOption']>;
}

export const SelectListItem = <Option,>({
  option,
  isSelected = false,
  isMulti = false,
  onPress = () => {},
  renderOption: RenderOptionComponent,
}: Props<Option>) => {
  const SelectCmp = useMemo(() => (isMulti ? CheckBox : Radio), [isMulti]);

  return (
    <RipplePressable onPress={() => onPress(option)}>
      <Container>
        <SelectCmp value={isSelected} onChange={() => onPress(option)} />
        <Margined>
          <RenderOptionComponent {...option} />
        </Margined>
      </Container>
    </RipplePressable>
  );
};
