import React, { useMemo } from 'react';
import moment from 'moment';

interface Params {
  seconds: number;
  format?: string;
  deps?: any[];
}

export const clockFormat = (seconds: number, format: string) =>
  moment.utc(moment.duration(seconds, 'seconds').asMilliseconds()).format(format);

export const useClockView = ({ seconds, format = 'HH:mm:ss', deps = [] }: Params) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const clock = useMemo(() => clockFormat(seconds, format), [seconds, ...deps]);

  return clock;
};
