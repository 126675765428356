import React, { FC } from 'react';
import { InferProps } from '@violetta/ubeya/utils';
import { Field } from 'react-final-form';
import {
  DateTogglerPicker,
  ValueProps as DateTogglerPickerValueProps,
} from '../DatePicker/DateTogglerPicker';
import { FormFieldProps } from './types';

export const DatePickerTogglerField: FC<
  FormFieldProps<
    DateTogglerPickerValueProps,
    InferProps<typeof DateTogglerPicker>
  >
> = ({ fieldProps, props, component: Component }) => (
  <Field<DateTogglerPickerValueProps>
    {...fieldProps}
    render={({ input }) => (
      <Component {...props} onChange={input.onChange} value={input.value} />
    )}
  />
);
