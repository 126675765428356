import { useEffect, useState } from 'react';
import { useAppState } from '@react-native-community/hooks';
import PushNotificationsIOS from '@react-native-community/push-notification-ios';
import { Platform } from 'react-native';
import useInterval from './useInterval';
import { useRefresh } from './useRefresh';

export const usePolling = () => {
  const currentAppState = useAppState();
  const { refresh } = useRefresh();
  const [previousState, setPreviousState] = useState(currentAppState);

  const { start, stop } = useInterval(() => {
    if (currentAppState === 'active') {
      // refreshAll();
    }
  }, 8000);

  useEffect(() => {
    if (currentAppState === previousState) {
      return;
    }
    if (currentAppState === 'active') {
      if (Platform.OS === 'ios') {
        PushNotificationsIOS.setApplicationIconBadgeNumber(0);
      }
      refresh();
      start();
    }

    if (currentAppState !== 'active') {
      stop();
    }
    setPreviousState(currentAppState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAppState, previousState]);
};
