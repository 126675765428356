import React, { FC } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import { Radio, RadioField } from '@violetta/ubeya/ui';
import { useField } from 'react-final-form';
import { OfferStatusFilterOptions } from '../constants';

export type IFilters = {
  distance: number[];
  branchIds: number[];
  positionIds: number[];
  offersStatus: { string: boolean };
};

export const OffersFilterModalStatusFilter: FC<{}> = () => {
  const { t } = useTranslation();

  const {
    input: { onChange: onChangeOffersStatus },
  } = useField('offersStatus');

  return Object.values(OfferStatusFilterOptions).map((statusValue) => (
    <RadioField
      key={statusValue}
      props={{
        title: t(statusValue),
        style: { marginBottom: 5 },
        onChange: () => {
          onChangeOffersStatus({ [statusValue]: true });
        },
      }}
      fieldProps={{
        name: `offersStatus.${statusValue}`,
        parse: (value) => !!value,
      }}
      component={Radio}
    />
  ));
};
