import React, { FC, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { IOffer, useBranches, useLocations } from '@violetta/ubeya/entities';
import {
  BaseImage,
  BaseView,
  Card,
  DateTextComponent,
  FlexColumnDirection,
  FlexRowBetween,
  FlexRowCenter,
  H5Medium,
  H5Regular,
  H6Medium,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { openMap } from '@violetta/ubeya/utils';
import styled from 'styled-components/native';
import { checkIsProjectMultiDay } from '../utils';

const Container = styled(FlexColumnDirection)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  position: relative;
`;

const LocationContainer = styled(BaseView)`
  display: flex;
  z-index: 1;
  width: 100%;
  height: 240px;
  background-color: ${({ theme }) => theme.colors.gray11};
`;

const ImageContainer = styled(BaseView)`
  display: flex;
`;

const Image = styled.Image`
  width: 100%;
  height: 240px;
`;

const RowDetail = styled(FlexRowCenter)`
  padding: 5px 0;
`;

const DetailsCard = styled(Card)`
  border-radius: 4px;
  border-width: 0px;
  elevation: 3;
  shadow-color: rgba(0, 0, 0, 0.3);
  shadow-opacity: 1;
  shadow-offset: 0px 1px;
  shadow-radius: 1px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: -80px 20px 0 20px;
  z-index: 10;
`;

const IconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 20px;
`;

const BranchLogo = styled(BaseImage)`
  width: 36px;
  height: 36px;
  border-radius: 23px;
`;

const BranchData = styled(FlexColumnDirection)`
  margin-left: 15px;
  flex: 1;
`;

const BranchName = styled(H5Medium)`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 4px;
`;

const EventName = styled(H6Medium)`
  font-size: 16px;
  font-weight: normal;
`;

const BranchRow = styled(FlexRowCenter)`
  margin-bottom: 20px;
`;

const CardDataDetails = styled(H5Regular)`
  margin-left: 15px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray15};
  max-width: 80%;
`;

interface Props {
  offer: IOffer;
  isProjectMultiDay?: boolean;
  hideLocation?: boolean;
}

export const OfferHeader: FC<Props> = ({
  isProjectMultiDay = false,
  offer: { locationId, branchId, minShiftDate, maxShiftDate, compoundedName },
  hideLocation = false,
}) => {
  const { mappedLocations } = useLocations();
  const { mappedBranches } = useBranches();
  const location = useMemo(
    () => !hideLocation && locationId && mappedLocations[locationId],
    [hideLocation, locationId, mappedLocations]
  );
  const branch = useMemo(
    () => mappedBranches[branchId],
    [branchId, mappedBranches]
  );

  return (
    <Container>
      <LocationContainer>
        {location && !!location.image && (
          <ImageContainer
            as={RipplePressable}
            onPress={() => openMap(location)}
          >
            <Image
              source={{ uri: location.image }}
              resizeMode="cover"
              resizeMethod="scale"
            />
          </ImageContainer>
        )}
      </LocationContainer>
      <DetailsCard>
        <BranchRow>
          <BranchLogo source={{ uri: branch?.logo }} />
          <BranchData>
            <FlexRowBetween>
              <BranchName>{branch?.name}</BranchName>
            </FlexRowBetween>
            <EventName>{compoundedName}</EventName>
            <DateTextComponent
              date={minShiftDate}
              fontSize={14}
              endDate={maxShiftDate}
              isMultiDate={isProjectMultiDay}
            />
          </BranchData>
        </BranchRow>
        {!hideLocation && !!locationId && (
          <RowDetail>
            <IconContainer>
              <SvgIcon
                xml={icons.uiIcons.locationOutline}
                height={18}
                width={14}
              />
            </IconContainer>
            <CardDataDetails numberOfLines={2} lineBreakMode="head">
              {mappedLocations[locationId]?.address}
            </CardDataDetails>
          </RowDetail>
        )}
      </DetailsCard>
    </Container>
  );
};
