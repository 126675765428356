import React, { FC } from 'react';
import { InferProps } from '@violetta/ubeya/utils';
import { Field } from 'react-final-form';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';
import { FormFieldProps } from './types';

export const AddressField: FC<
  FormFieldProps<string, InferProps<typeof GooglePlacesAutocomplete>>
> = ({ fieldProps, props, component: Component }) => (
  <Field<string>
    {...fieldProps}
    render={({ input, meta }) => (
      <Component
        {...props}
        onChange={input.onChange}
        value={input.value}
        onFocus={input.onFocus as any}
        onBlur={input.onBlur as any}
        meta={meta}
      >
        {fieldProps.value}
      </Component>
    )}
  />
);
