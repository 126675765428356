import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderlessBar } from '@violetta/ubeya/ui';
import { colors } from '../../../style/theme';
import { ChatRoomHeader } from '../components/headers/ChatRoomHeader';
import { UserRoomScreen } from '../screens/user/UserRoomScreen';
import { ChatScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const ChatInnerNavigation: FC = () => (
  <Navigator
    initialRouteName={ChatScreenNames.ROOM}
    screenOptions={{
      header: (props: NativeStackHeaderProps) => (
        <HeaderlessBar {...props} color={colors.lightSurface} />
      ),
      // eslint-disable-next-line react/jsx-closing-bracket-location
    }}
  >
    <Screen
      name={ChatScreenNames.ROOM}
      component={UserRoomScreen}
      options={{
        header: (props: NativeStackHeaderProps) => (
          <ChatRoomHeader {...props} />
        ),
      }}
    />
  </Navigator>
);
