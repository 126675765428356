import React, { FC } from 'react';
import LottieView from 'lottie-react-native';
import styled from 'styled-components/native';
import { BaseView } from '../Layout';
import { Platform } from 'react-native';

const Container = styled(BaseView)`
  margin-bottom: ${Platform.OS === 'web' ? 20 : 0}px;
`;
type Props = {
  name: string;
  width: number;
  height: number;
};

export const Lottie: FC<Props> = ({ name, width, height, style, ...props }) => (
  <Container style={{ width, height, ...style }}>
    <LottieView source={name} {...props} />
  </Container>
);
