import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import {
  IAccountProfile,
  useAccountProfile,
  useAccounts,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  CustomLoaderWrapped,
  FlexColumn,
  FlexColumnDirection,
  FlexRow,
  H2Bold,
  H3Light,
  Loader,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { uiAlert, useRefresh } from '@violetta/ubeya/utils';
import React, { FC, useCallback, useContext, useEffect } from 'react';
import { Alert, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { AppContext } from '../../main/AppContext';
import { AccountProfileCard } from '../components/account-profile/components';
import { ProfileScreenNames } from '../navigation/ScreenNames';
import AboutUsSection from './CompanyProfile/AboutUsSection';
import ContactsSection from './CompanyProfile/ContactsSection';
import LinksSection from './CompanyProfile/LinksSection';
import ProfileCoverSection from './CompanyProfile/ProfileCoverSection';
import { MAPPED_GENERAL_LINKS } from './constants';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  padding: 30px 30px;
  padding-top: 0;
`;

const MainContainer = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const Header = styled(FlexColumn)`
  margin-bottom: 35px;
  width: 100%;
`;

const Nav = styled(BaseView)``;

const Section = styled(FlexRow)`
  margin-top: 10px;
  align-items: center;
`;

const EmptySection = styled(FlexRow)`
  margin-top: 30px;
`;

const SectionTitle = styled(H2Bold)`
  margin: 25px 0;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 500;
`;

const LeaveAccountLink = styled(FlexColumn)`
  margin-top: 30px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
`;

const LeaveAccount = styled(H3Light)`
  font-size: 16px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray2};
`;

const LoaderContainer = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
`;

interface Props {}

type ParamList = {
  params: {
    accountId: number;
  };
};

export const AccountProfileScreen: FC<Props> = () => {
  const {
    profile: { selectAccount, selectedAccount },
  } = useContext(AppContext);

  const { goBack } = useNavigation();

  const {
    params: { accountId },
  } = useRoute<RouteProp<ParamList, 'params'>>();

  const { profile }: { profile: IAccountProfile } =
    useAccountProfile(accountId);

  const { t } = useTranslation();
  const { refresh } = useRefresh();
  const { navigate } = useNavigation();
  const { mappedAccounts, isLoading, leaveAccount, isLeaveAccountLoading } =
    useAccounts();
  const shouldRender = !isLoading && mappedAccounts[accountId]?.id;
  const { data, logoutWithoutPrompt } = useUser();

  useEffect(() => {
    if (!isLoading && !mappedAccounts[accountId]?.id) {
      goBack();
    }
    selectAccount(mappedAccounts[accountId]);
  }, [mappedAccounts, accountId, isLoading, goBack, selectAccount]);

  const leaveAccountFunc = useCallback(async () => {
    const res = await uiAlert({
      title: t('areYouSureLeaveAccount'),
      cancel: {
        text: t('no'),
      },
      save: {
        text: t('yes'),
      },
    });

    if (!res) {
      return;
    }

    try {
      const response = await leaveAccount(accountId);
      if (response?.isUserReset) {
        await logoutWithoutPrompt();
      }
      await refresh();
      goBack();
    } catch (error) {
      Alert.alert(
        t(error?.response?.data?.message || 'user_booked_to_future_jobs')
      );
    }
  }, [accountId, goBack, leaveAccount, logoutWithoutPrompt, refresh, t]);

  if (!shouldRender) return <CustomLoaderWrapped />;

  if (!selectedAccount) {
    return null;
  }

  const { logo, coverPhoto, name } = selectedAccount;

  const { generalLinks, customLinks } = (profile?.links || []).reduce(
    (output, { isGeneral, name, value, type }) => {
      const formattedLink = {
        name: t(name),
        value,
        type,
        icon: MAPPED_GENERAL_LINKS?.[type]?.icon || undefined,
      };

      output[isGeneral ? 'generalLinks' : 'customLinks'].push(formattedLink);

      return output;
    },
    { generalLinks: [], customLinks: [] }
  );

  const existsInfoAboutSomeBranch = (profile?.branches || []).some(
    ({ contacts = [], officeHoursText = '' }) =>
      contacts.length > 0 || !!officeHoursText
  );

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <MainContainer>
        <ProfileCoverSection coverPhoto={coverPhoto} name={name} logo={logo} />
        <AboutUsSection aboutUs={profile?.aboutUs} links={generalLinks} />

        <Container>
          {isLeaveAccountLoading ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <Nav>
              <Section>
                <SectionTitle>{t('myInfo')}</SectionTitle>
              </Section>

              <AccountProfileCard
                onPress={() =>
                  navigate(ProfileScreenNames.CHANGE_ACCOUNT_DETAILS, {
                    accountId,
                  })
                }
                title={t('personalDetails')}
                image={data?.image}
              />
              <AccountProfileCard
                onPress={() =>
                  navigate(ProfileScreenNames.ACCOUNT_GALLERY, { accountId })
                }
                title={t('yourPhotoGallery')}
                icon={icons.symbolIcons.gallery}
              />

              {customLinks?.length > 0 && (
                <>
                  <Section>
                    <SectionTitle>{t('links')}</SectionTitle>
                  </Section>

                  <LinksSection links={customLinks} />
                </>
              )}

              {existsInfoAboutSomeBranch && (
                <>
                  <Section>
                    <SectionTitle>{t('companyContacts')}</SectionTitle>
                  </Section>
                  <ContactsSection branches={profile.branches} />
                  <EmptySection />
                </>
              )}

              <LeaveAccountLink as={RipplePressable} onPress={leaveAccountFunc}>
                <LeaveAccount>{t('leaveAccount')}</LeaveAccount>
              </LeaveAccountLink>
            </Nav>
          )}
        </Container>
      </MainContainer>
    </ScrollView>
  );
};
