import React, { FC, useCallback, useMemo, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { api } from '@violetta/ubeya/api';
import { icons, images } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import { useAccounts } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  CustomLoader,
  FlexCenter,
  FlexColumnDirection,
  FlexRow,
  FlexRowCenter,
  H2Bold,
  H4Regular,
  H5Regular,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { formatPhoneNational, pickImg } from '@violetta/ubeya/utils';
import { Image, Pressable, ScrollView } from 'react-native';
import { launchImageLibrary } from 'react-native-image-picker';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styled, { useTheme } from 'styled-components/native';
import { AccountCard } from '../components/my-profile/components/AccountCard';
import { ProfileScreenNames } from '../navigation/ScreenNames';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  padding: 33px 20px;
`;

const ImageContainer = styled(FlexRowCenter)`
  justify-content: center;
  border-radius: 50px;
  border-color: ${({ theme }) => theme.colors.surface};
  border-width: 3px;
  shadow-color: rgba(0, 0, 0, 0.05);
  shadow-opacity: 0.3;
  shadow-offset: 0 2px;
  shadow-radius: 20px;
  elevation: 10;
  height: 62px;
  width: 62px;
`;

const Header = styled(FlexRow)`
  padding: 0 15px;
`;

const Details = styled(BaseView)`
  margin-left: 12px;
  display: flex;
  justify-content: space-between;
`;

const UserName = styled(H2Bold)`
  font-weight: 500;
  font-size: 20px;
  max-width: 90%;
`;

const EditTitle = styled(BaseText)`
  font-weight: 300;
  font-size: 12px;
  margin-left: 8px;
  margin-top: 2px;
`;

const ImagePressable = styled(BaseView)`
  position: relative;
`;

const Phone = styled(H5Regular)`
  font-weight: 400;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const Accounts = styled(FlexColumnDirection)``;

const LoaderContainer = styled(FlexCenter)`
  height: 62px;
  width: 62px;
  justify-content: center;
  align-items: center;
`;

const Employers = styled(FlexRow)`
  margin-top: 20px;
  align-items: center;
`;
const EmployersTitle = styled(H2Bold)`
  margin: 25px 0;
  padding-left: 25px;
  font-size: 18px;
  font-weight: 500;
`;
const EmployersCount = styled(H4Regular)`
  color: ${({ theme }) => theme.colors.gray1};
  font-size: 15px;
  margin-left: 10px;
`;

const EmptyContainer = styled(BaseView)`
  height: 9px;
`;

export const LargeProfileImage = styled(Image)`
  height: 62px;
  width: 62px;
  border-radius: 31px;
  margin-top: 15px;
`;

const RemoveChip = styled(RipplePressable)`
  width: 25px;
  height: 25px;
  border-radius: 13px;
  position: absolute;
  right: -7px;
  top: -7px;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled(BaseView)`
  display: flex;
  margin-top: 5px;
  align-items: center;
  flex-direction: row;
`;

interface Props {}

export const ProfileScreen: FC<Props> = () => {
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const { accounts } = useAccounts();
  const { colors } = useTheme();
  const { uploadPhoto, isLoadingPhoto } = useUser();

  const navigateAccount = useCallback(
    (id: number) => {
      navigate(ProfileScreenNames.ACCOUNT_PROFILE, {
        accountId: id,
      });
    },
    [navigate]
  );

  const navigateUserDataEdit = useCallback(() => {
    navigate(ProfileScreenNames.EDIT_USER);
  }, [navigate]);

  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const { data } = useUser();
  const { firstName, lastName, image: userImage } = data || {};
  const queryClient = useQueryClient();
  const image = useMemo(
    () => uploadedImage || userImage,
    [uploadedImage, userImage]
  );

  const photoSelected = useCallback(async () => {
    const assets = await pickImg({
      texts: {
        cancel: t('cancel'),
        choosePhoto: t('choosePhoto'),
        takeAPhoto: t('takePhoto'),
        title: t('chooseImageUploadMethod'),
      },
      image: {
        includeBase64: true,
        mediaType: 'photo',
        maxWidth: 1000,
        maxHeight: 1000,
        quality: 0.7,
      },
    });

    if (!assets) {
      return;
    }
    const { base64, type } = assets?.[0] || {};
    if (!base64 || !type) {
      return;
    }
    const result = await uploadPhoto({ base64, type });
    queryClient.invalidateQueries({ queryKey: ['user'], exact: true });
    setUploadedImage(result?.image);
  }, [queryClient, t, uploadPhoto]);

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ backgroundColor: colors.background }}
    >
      <Container>
        <Header>
          <ImagePressable as={Pressable} onPress={photoSelected}>
            <>
              {isLoadingPhoto && (
                <LoaderContainer>
                  <CustomLoader size={100} />
                </LoaderContainer>
              )}
              {!isLoadingPhoto && !image && (
                <ImageContainer>
                  <SvgIcon
                    xml={images.svgs.emptyProfileImage}
                    height={94}
                    width={94}
                  />
                </ImageContainer>
              )}
              {!isLoadingPhoto && image && (
                <LargeProfileImage source={{ uri: image }} />
              )}
              {/* {!isLoadingPhoto && image && (
                <RemoveChip>
                  <SvgIcon width={25} height={25} xml={icons.cardIcons.availability} />
                </RemoveChip>
              )} */}
            </>
          </ImagePressable>
          <Details as={Pressable} onPress={navigateUserDataEdit}>
            <EmptyContainer />
            <UserName>
              {[firstName, lastName].filter((text) => !!text).join(' ')}
            </UserName>
            {!!data?.phone && <Phone>{formatPhoneNational(data?.phone)}</Phone>}

            <IconContainer>
              <SvgIcon xml={icons.actionIcons.edit} height={15} width={15} />
              <EditTitle>{t('edit')}</EditTitle>
            </IconContainer>
          </Details>
        </Header>
        <Accounts>
          <Employers>
            <EmployersTitle>{t('myEmployers')}</EmployersTitle>
            <EmployersCount>({(accounts || []).length})</EmployersCount>
          </Employers>
          {accounts.map((account, idx) => (
            <FlexRow key={idx}>
              <AccountCard
                account={account}
                onPress={() => navigateAccount(account.id)}
              />
            </FlexRow>
          ))}
        </Accounts>
      </Container>
    </ScrollView>
  );
};
