import { useEffect, useMemo } from 'react';
import { useUser } from '@violetta/ubeya/auth';
import moment from 'moment';
import { useAvailabilities } from './useAvailabilities';
import {
  ILocalAvailabilities,
  useLocalAvailabilities,
} from './useLocalAvailabilities';
import { usePeriods } from './usePeriods';

export const useAvailabilityManager = ({
  branchId,
  periodId,
}: {
  branchId: number;
  periodId: number;
}) => {
  const { data: userData } = useUser();
  const { isUpdatingPeriod, updatePeriod, mappedPeriods } = usePeriods();

  const { putLocalAvailabilities, localAvailabilities, filledPeriod, dirty } =
    useLocalAvailabilities({
      periodId,
    });
  const {
    availabilities,
    updateAvailabilities,
    isUpdatingAvailability,
    isLoading: isLoadingAvailabilities,
  } = useAvailabilities({
    periodId,
  });

  const serverAvailabilities = useMemo(() => {
    if (!availabilities) {
      return {};
    }

    return availabilities.reduce((carry, item) => {
      carry[`${moment(item.date).format('YYYY-MM-DD')}-${item.labelId}`] = item;
      return carry;
    }, {} as { [key: string]: ILocalAvailabilities });
  }, [availabilities]);

  useEffect(() => {
    putLocalAvailabilities((prevData) => ({
      ...prevData,
      dirty: false,
      data: [
        ...Object.values(serverAvailabilities).reduce((carry, item) => {
          const availability =
            localAvailabilities?.find(
              ({ date, labelId }) =>
                labelId === item.labelId &&
                moment(date).isSame(moment(item.date), 'date')
            ) || {};

          return [...carry, { ...item, ...availability }];
        }, [] as ILocalAvailabilities[]),
        ...(localAvailabilities?.filter(
          ({ date, labelId }) =>
            !serverAvailabilities[
              `${moment(date).format('YYYY-MM-DD')}-${labelId}`
            ]
        ) || []),
      ],
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverAvailabilities, putLocalAvailabilities]);

  useEffect(() => {
    if (!isUpdatingPeriod && !mappedPeriods[periodId]?.seen) {
      updatePeriod({ id: periodId, seen: true });
    }
  }, [periodId, branchId, isUpdatingPeriod, updatePeriod, mappedPeriods]);

  return {
    localAvailabilities,
    availabilities,
    filledPeriod,
    isLoadingAvailabilities,
    updateAvailabilities,
    isUpdatingAvailability,
    dirty,
  };
};
