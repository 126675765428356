import React, { FC } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { SvgIcon } from './SvgIcon';

interface Props {
  size?: number;
  style?: any;
}

export const ChevronDown: FC<Props> = ({ size = 25, style = {} }) => (
  <SvgIcon
    style={style}
    xml={icons.cardIcons.chevronDown}
    width={size}
    height={size}
  />
);
