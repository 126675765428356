import React, { FC } from 'react';
import { View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';

interface Props {
  size: number;
  color: string;
}

export const Close: FC<Props> = ({ size, color }) => (
  <View>
    <Icon name="clear" color={color} size={size} />
  </View>
);
