import React, { FC } from 'react';
import {
  ActivityIndicator,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native';
import styled from 'styled-components/native';
import { FlexCenter, RipplePressable } from '../Layout';
import { H4Regular } from '../Typography';

const PADDING_VERTICAL_NARROW = 10;
const PADDING_VERTICAL = 20;
const PADDING_HORIZONTAL = 20;

const BaseButton = styled(FlexCenter)`
  padding: ${PADDING_VERTICAL}px ${PADDING_HORIZONTAL}px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.primary};
  elevation: 0;
`;

export const NormalPrimaryButton = styled(BaseButton)`
  border-color: ${({ theme }) => theme.button.primary.normal.background};
  background-color: ${({ theme, pressed }) =>
    pressed
      ? theme.button.primary.hover.background
      : theme.button.primary.normal.background};
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
`;

export const NormalPrimaryButtonTitle = styled(H4Regular)`
  letter-spacing: 0.61px;
  color: ${({ theme }) => theme.button.primary.normal.color};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
`;

export const DisabledPrimaryButton = styled(BaseButton)`
  border-color: ${({ theme }) => theme.button.primary.disabled.background};
  background-color: ${({ theme }) => theme.button.primary.disabled.background};
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
`;

export const DisabledPrimaryButtonTitle = styled(H4Regular)`
  color: ${({ theme }) => theme.button.primary.disabled.color};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
  letter-spacing: 0.61px;
`;

export const NormalPrimaryReversedButton = styled(BaseButton)`
  border-color: ${({ theme }) => theme.button.primaryReversed.normal.color};
  background-color: ${({ theme }) =>
    theme.button.primaryReversed.normal.background};
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
`;

export const NormalPrimaryReversedButtonTitle = styled(H4Regular)`
  color: ${({ theme }) => theme.button.primaryReversed.normal.color};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
  letter-spacing: 0.61px;
`;

export const DisabledPrimaryReversedButton = styled(BaseButton)`
  border-color: ${({ theme }) => theme.button.primaryReversed.disabled.color};
  background-color: ${({ theme }) =>
    theme.button.primaryReversed.disabled.background};
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
`;

export const DisabledPrimaryReversedButtonTitle = styled(H4Regular)`
  color: ${({ theme }) => theme.button.primaryReversed.disabled.color};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
  letter-spacing: 0.61px;
`;

export const NormalSecondaryButton = styled(BaseButton)`
  border-color: ${({ theme }) => theme.button.secondary.normal.background};
  background-color: ${({ theme, pressed }) =>
    pressed
      ? theme.button.secondary.hover.background
      : theme.button.secondary.normal.background};
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
`;

export const NormalSecondaryButtonTitle = styled(H4Regular)`
  color: ${({ theme }) => theme.button.secondary.normal.color};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
  letter-spacing: 0.61px;
`;

export const DisabledSecondaryButton = styled(BaseButton)`
  border-color: ${({ theme }) => theme.button.secondary.disabled.background};
  background-color: ${({ theme }) =>
    theme.button.secondary.disabled.background};
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
  border-radius: ${({ borderRadius }) => `${borderRadius}px`};
`;

export const DisabledSecondaryButtonTitle = styled(H4Regular)`
  color: ${({ theme }) => theme.button.secondary.disabled.color};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
  letter-spacing: 0.61px;
`;

export const NormalTransparentButton = styled(BaseButton)`
  border-color: ${({ theme }) => theme.button.link.normal.background};
  background-color: ${({ theme, pressed }) =>
    pressed
      ? theme.button.link.hover.background
      : theme.button.link.normal.background};
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
`;

export const NormalTransparentButtonTitle = styled(H4Regular)`
  letter-spacing: 0.61px;
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
  color: ${({ theme }) => theme.button.link.normal.color};
`;

export const DisabledTransparentButton = styled(BaseButton)`
  border-color: ${({ theme }) => theme.button.link.disabled.background};
  background-color: ${({ theme }) => theme.button.link.disabled.background};
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    `${paddingVertical}px ${paddingHorizontal}px`};
`;

export const DisabledTransparentButtonTitle = styled(H4Regular)`
  color: ${({ theme }) => theme.button.link.disabled.color};
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
  letter-spacing: 0.61px;
`;

interface Props {
  title?: string;
  radius?: number;
  fontSize?: number;
  onPress?: () => void;
  disabledOnPress?: () => void;
  disabled?: boolean;
  type: 'primary' | 'primaryReversed' | 'secondary' | 'transparent';
  paddingVertical?: number;
  paddingHorizontal?: number;
  isLoading?: boolean;
  loaderColor?: string;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  lowercase?: boolean;
  narrow?: boolean;
  fullWidth?: boolean;
}

const buttonFactory = (disabled: boolean, type: Props['type']) => {
  switch (type) {
    case 'primary':
      if (disabled) {
        return {
          Container: DisabledPrimaryButton,
          Title: DisabledPrimaryButtonTitle,
        };
      }

      return {
        Container: NormalPrimaryButton,
        Title: NormalPrimaryButtonTitle,
      };
    case 'primaryReversed':
      if (disabled) {
        return {
          Container: DisabledPrimaryReversedButton,
          Title: DisabledPrimaryReversedButtonTitle,
        };
      }

      return {
        Container: NormalPrimaryReversedButton,
        Title: NormalPrimaryReversedButtonTitle,
      };
    case 'secondary':
      if (disabled) {
        return {
          Container: DisabledSecondaryButton,
          Title: DisabledSecondaryButtonTitle,
        };
      }

      return {
        Container: NormalSecondaryButton,
        Title: NormalSecondaryButtonTitle,
      };
    case 'transparent':
      if (disabled) {
        return {
          Container: DisabledTransparentButton,
          Title: DisabledTransparentButtonTitle,
        };
      }

      return {
        Container: NormalTransparentButton,
        Title: NormalTransparentButtonTitle,
      };
    default:
      return {
        Container: <></>,
        Title: H4Regular,
      };
  }
};

export const Button: FC<Props> = ({
  children,
  title,
  onPress,
  disabledOnPress,
  disabled = false,
  type,
  fontSize = 14,
  paddingVertical = PADDING_VERTICAL,
  paddingHorizontal = PADDING_HORIZONTAL,
  radius = 6,
  isLoading = false,
  loaderColor = '#ffffff',
  style,
  textStyle,
  lowercase,
  narrow = false,
  fullWidth = false,
}) => {
  //   const [pressed, setPressed] = useState(false);

  const { Container, Title: BaseTitle } = buttonFactory(
    disabled && !isLoading,
    type
  );

  return (
    <RipplePressable
      style={fullWidth ? { width: '100%' } : {}}
      onPress={disabled ? disabledOnPress : onPress}
    >
      <Container
        style={style}
        paddingVertical={narrow ? PADDING_VERTICAL_NARROW : paddingVertical}
        paddingHorizontal={paddingHorizontal}
        borderRadius={radius}
      >
        <FlexCenter>{children}</FlexCenter>
        <FlexCenter>
          {isLoading ? (
            // <CustomLoader width={60} height={22} />
            <ActivityIndicator color={loaderColor} size="small" />
          ) : (
            <BaseTitle style={textStyle} fontSize={fontSize}>
              {!lowercase ? title?.toUpperCase() : title}
            </BaseTitle>
          )}
        </FlexCenter>
      </Container>
    </RipplePressable>
  );
};
