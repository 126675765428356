import { DTO } from '@violetta/ubeya/utils';

export enum SwapType {
  INITIATOR = 1,
  RECEIVER = 2,
}

export enum ShiftSwapStatus {
  REQUEST_INITIATED = 0,
  REQUEST_APPROVED_BY_REQUESTED = 1,
  DECLINED = 2,
  APPROVED = 3,
}

export interface ISwap {
  id: number;
  typeId: SwapType;
  status: ShiftSwapStatus;
}

export type SwapDTO = DTO<ISwap>;
