import React, { FC, useCallback, useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FlexRow,
  FlexRowCenter,
  H6Medium,
  SvgIcon,
  FlexColumnDirection,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { Pressable } from 'react-native';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import { isEditingGeneralAvailabilityAtom } from '../../../state';
import { AppContext } from '../../main/AppContext';
import { GeneralAvailabilityScreenNames } from '../navigation/ScreenNames';
import { Day } from '../screens/GeneralAvailabilityScreen';
import { GeneralAvailabilityItemChip } from './GeneralAvailabilityItemChip';

interface Props {
  item: Day;
}

const Container = styled(BaseView)``;

const DayStrip = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.primaryLight2};
  padding: 8px 16px;
`;
const DayTitle = styled(H6Medium)`
  font-weight: 500;
`;

const PlusButton = styled(FlexRowCenter)`
  width: 40px;
  height: 40px;
`;
const Chips = styled(FlexColumnDirection)`
  align-items: flex-start;
  flex: 1;
`;

const Content = styled(FlexRow)`
  align-items: center;
  min-height: 50px;
  padding: 0 16px;
`;

export const GeneralAvailabilityDay: FC<Props> = ({ item }) => {
  const { period, day, title, dow } = item;
  const [isEditingGeneralAvailability] = useRecoilState(
    isEditingGeneralAvailabilityAtom
  );
  const { navigate } = useNavigation();
  const {
    generalAvailability: { selectDay },
  } = useContext(AppContext);

  const handleNavigate = useCallback(() => {
    selectDay(title);
    navigate(GeneralAvailabilityScreenNames.CHOOSE_AVAILABILITY, {
      params: { title, day: dow },
    });
  }, [dow, navigate, selectDay, title]);

  return (
    <Container>
      <DayStrip>
        <DayTitle>{day}</DayTitle>
      </DayStrip>
      <Content>
        <Chips>
          <GeneralAvailabilityItemChip
            key={dow}
            start={period?.start}
            end={period?.end}
            type={period?.type}
          />
        </Chips>
        {isEditingGeneralAvailability && (
          <PlusButton as={RipplePressable} onPress={handleNavigate}>
            <SvgIcon xml={icons.actionIcons.plus2} height={20} width={20} />
          </PlusButton>
        )}
      </Content>
    </Container>
  );
};
