import React, { FC } from 'react';

import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderlessBar } from '@violetta/ubeya/ui';
import { useTheme } from 'styled-components/native';
import { SequenceFormScreen, SequenceScreen } from '../screens';
import { SequencesScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const SequencesNavigation: FC = () => {
  const { colors } = useTheme();

  return (
    <Navigator
      initialRouteName={SequencesScreenNames.SEQUENCE}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
        // eslint-disable-next-line react/jsx-closing-bracket-location
      }}
    >
      <Screen name={SequencesScreenNames.MAIN} component={SequenceScreen} />
      <Screen
        name={SequencesScreenNames.SEQUENCE}
        component={SequenceScreen}
        options={
          {
            // header: (props: NativeStackHeaderProps) => <HomeHeader {...props} />,
          }
        }
      />
      <Screen
        name={SequencesScreenNames.SEQUENCE_FORMS}
        component={SequenceFormScreen}
        options={
          {
            // header: (props: NativeStackHeaderProps) => <HomeHeader {...props} />,
          }
        }
      />
    </Navigator>
  );
};
