import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { I18nManager } from 'react-native';
import RNRestart from 'react-native-restart';
import '../services/i18n';
import { useLocale } from './useLocale';

const RTL_MAP = {
  he: true,
  ar: true,
  'en-gb': false,
  en: false,
  de: false,
  es: false,
  ru: false,
  nl: false,
} as const;

// TODO: use this when we actually have more than "he" and "en"
// const extractLanguage = (languages, id) =>
// 	(languages && languages?.[id]?.iso6391) || "en";

/**
 * - change translations
 * - change moment locale
 * - change document orientation
 */
export const useTranslatedApp = (): boolean => {
  const { locale } = useLocale();

  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<string>(locale);

  const change = useCallback(() => {
    moment.locale(locale, {
      week: {
        dow: locale === 'he' || locale === 'ar' ? 0 : 1,
      },
    });
    const nextLanguage = locale;

    if (currentLanguage === nextLanguage && currentLanguage === i18n.language)
      return;

    const isNextRTL = RTL_MAP[nextLanguage];
    const isReactNativeRTL = I18nManager.isRTL;

    async function changeLanguage() {
      try {
        const lang = (() => {
          switch (nextLanguage) {
            case 'en':
            case 'en-gb':
              return 'en';
            default:
              return nextLanguage;
          }
        })();

        await i18n.changeLanguage(lang);
        // moving from non-rtl to rtl
        if (isNextRTL && !isReactNativeRTL) {
          // force rtl and restart
          I18nManager.forceRTL(true);
          RNRestart.Restart();
        }
        // moving from rtl to non-rtl
        else if (!isNextRTL && isReactNativeRTL) {
          // "force non rtl and restart"
          I18nManager.forceRTL(false);
          RNRestart.Restart();
        }
      } catch (e) {
        // lang change failed
      } finally {
        setCurrentLanguage(nextLanguage);
      }
    }
    changeLanguage();
  }, [locale, currentLanguage, i18n]);

  useEffect(change, [currentLanguage, locale, change]);

  return RTL_MAP[currentLanguage];
};
