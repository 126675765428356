import { useMemo } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { createAdminStoreKey, mappedArray } from '@violetta/ubeya/utils';
import { createCachedSelector } from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import * as sharedAtoms from '../../../shared/src/atoms';
import { IBranch } from '../entities/IBranch';

const selector = createCachedSelector(
  (data) => data.data,
  (data) => {
    const branches = data?.data as IBranch[];
    const mappedBranches = mappedArray(branches || []);
    return { branches, mappedBranches };
  }
)({
  keySelector: (data, storeKey) => storeKey.join('#'),
});

export const useBranches = () => {
  const selectedAccount = useRecoilValue(sharedAtoms.selectedAccount);
  const selectedAccountId = selectedAccount?.id;
  const storeKey = useMemo(
    () => createAdminStoreKey(['branches', selectedAccountId]),
    [selectedAccountId]
  );
  const { isPending, data, refetch } = useQuery({
    queryKey: storeKey,
    queryFn: () => adminApi.getBranches({ accountId: selectedAccountId }),
    enabled: !!selectedAccountId,
    select: (data) => selector(data, storeKey),
  });

  const { branches = [], mappedBranches = {} } = useMemo(() => {
    const {
      branches: rawBranches = [],
      mappedBranches: rawMappedBranches = {},
    } = data || {};
    return { branches: rawBranches, mappedBranches: rawMappedBranches };
  }, [data]);

  return {
    isLoading: isPending,
    refetch,
    branches,
    mappedBranches,
  };
};
