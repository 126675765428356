import { useBranches } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  ApproveTimesheetModal,
  BaseView,
  Button,
  DeclineTimesheetModal,
  FlexRow,
  FlexRowBetween,
  FlexRowEvenly,
  H4Regular,
  H5Regular,
} from '@violetta/ubeya/ui';
import React, { FC, useCallback, useMemo, useRef } from 'react';
import { Modalize } from 'react-native-modalize';
import Toast from 'react-native-toast-message';
import styled from 'styled-components/native';
import { useTimesheet } from '../../timeclock';

const Text = styled(H5Regular)`
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-left: 10px;
  padding: 10px;
  max-width: 200px;
  font-size: 18px;
`;

const TextContainer = styled(BaseView)``;

const TimesheetResponseStatus = styled(H4Regular)`
  margin-top: 2px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.surface};
`;

const TimesheetApprovalContainer = styled(FlexRowBetween)`
  justify-content: flex-end;
  align-items: center;  
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0

  background: ${({ theme }) => theme.colors.surface};
  padding: 25px 15px 30px 0;
  shadow-color: rgba(0, 0, 0, 0.6);
  shadow-opacity: 0.3;
  shadow-offset: 0 0px;
  shadow-radius: 4px;
  elevation: 4;

`;

const ButtonContainer = styled(FlexRow)``;

const ResponseContainer = styled(FlexRowEvenly)`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;

  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.surface};
  padding: 30px;
`;

interface Props {
  timesheet: any;
}

export const EmployeeTimesheetResponse: FC<Props> = ({ timesheet }) => {
  const { mappedBranches } = useBranches();
  const { t } = useTranslation();

  const {
    approveTimesheet,
    isLoadingApproveTimesheet,
    isLoadingDeclineTimesheet,
    declineTimesheet,
  } = useTimesheet(timesheet?.id);

  const approveModalizeRef = useRef<Modalize>(null);
  const declineModalizeRef = useRef<Modalize>(null);

  const approveTimesheetHandler = useCallback(
    async (values) => {
      try {
        await approveTimesheet(values);

        Toast.show({
          text1: t('changedProfileDataSuccessfully'),
          type: 'success',
        });
      } catch (e) {
        Toast.show({ text1: t('unknown'), type: 'error' });
      }
    },
    [approveTimesheet, t]
  );

  const declineTimesheetHandler = useCallback(
    async (values) => {
      try {
        await declineTimesheet(values);
        Toast.show({
          text1: t('changedProfileDataSuccessfully'),
          type: 'success',
        });
      } catch (e) {
        Toast.show({ text1: t('unknown'), type: 'error' });
      }
    },
    [declineTimesheet, t]
  );

  const requireEmployeeTimesheetSignoff = useMemo(() => {
    const branchId =
      timesheet?.job?.branchId ||
      timesheet?.shift?.project?.branchId ||
      timesheet?.branchId;

    if (!branchId) {
      return false;
    }

    return mappedBranches?.[branchId]?.timesheetConfig
      ?.requireEmployeeTimesheetSignoff;
  }, [
    mappedBranches,
    timesheet?.branchId,
    timesheet?.job?.branchId,
    timesheet?.shift?.project?.branchId,
  ]);

  if (!requireEmployeeTimesheetSignoff) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  /* employee clocked in but have not responded yet */
  const showResponseTimesheetButton =
    !!timesheet?.startTime &&
    !!timesheet?.endTime &&
    !timesheet?.employeeApproved &&
    !timesheet?.employeeDeclined;

  if (showResponseTimesheetButton) {
    return (
      <>
        <TimesheetApprovalContainer>
          <ButtonContainer>
            <Button
              type="primaryReversed"
              title={t('declineTimesheet')}
              onPress={() => {
                declineModalizeRef?.current?.open();
              }}
              fontSize={16}
              paddingVertical={12}
              lowercase
            />
            <Button
              type="primary"
              title={t('approveTimesheet')}
              onPress={() => {
                approveModalizeRef?.current?.open();
              }}
              fontSize={16}
              paddingVertical={12}
              style={{ marginLeft: 10, marginRight: 10 }}
              lowercase
            />
          </ButtonContainer>
        </TimesheetApprovalContainer>

        <ApproveTimesheetModal
          modalizeRef={approveModalizeRef}
          approveTimesheet={approveTimesheetHandler}
          isLoadingApproveTimesheet={isLoadingApproveTimesheet}
        />
        <DeclineTimesheetModal
          modalizeRef={declineModalizeRef}
          declineTimesheet={declineTimesheetHandler}
          isLoadingDeclineTimesheet={isLoadingDeclineTimesheet}
        />
      </>
    );
  }

  return (
    <>
      {timesheet.employeeApproved && (
        <ResponseContainer>
          <TimesheetResponseStatus>
            {t('employeeTimesheetApproved')}
          </TimesheetResponseStatus>
        </ResponseContainer>
      )}

      {timesheet.employeeDeclined && (
        <ResponseContainer>
          <TimesheetResponseStatus>
            {t('employeeTimesheetDeclined')}
          </TimesheetResponseStatus>
        </ResponseContainer>
      )}
    </>
  );
};
