import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useTranslation } from '@violetta/ubeya/i18n';
import { useQuery } from '@tanstack/react-query';
import { generateChatId } from '../utils/generateChatId';

const useMessages = ({ roomId, userId, userType }) => {
  const storeKey = ['rooms', roomId];

  const { t } = useTranslation();
  const myId = generateChatId({ id: userId, type: userType });

  const { isPending, data } = useQuery({
    queryKey: storeKey,
    queryFn: () => api.getMessages({ roomId }),
    enabled: !!roomId,
  });
  const messages = useMemo(
    () =>
      (data?.messages || []).map(
        ({ _id, message, timestamp, senderPayload, readByPayload }) => ({
          _id,
          text: message,
          createdAt: timestamp,
          user: {
            _id: `${senderPayload?.id}-${senderPayload?.type}`,
            name: `${
              senderPayload?.firstName ? senderPayload?.firstName : ''
            } ${senderPayload?.lastName ? ` ${senderPayload?.lastName}` : ''}${
              senderPayload?.type === 'admin' ? ` (${t('chatManager')})` : ''
            }`,
            avatar: senderPayload?.image,
          },
          isMe: `${senderPayload?.id}-${senderPayload?.type}` === myId,
          // received: (readByPayload || []).find(({ id, type }) => `${id}-${type}` !== myId) !== null,
          isAdmin: senderPayload?.type === 'admin',
        })
      ),
    [data?.messages, myId, t]
  );

  return {
    isLoading: isPending,
    data: messages,
  };
};

export default useMessages;
