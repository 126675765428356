import React, { FC, useEffect, useMemo, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useUser } from '@violetta/ubeya/auth';
import { selectedUserChatRoom } from '@violetta/ubeya/shared';
import { BaseView, Loader } from '@violetta/ubeya/ui';
import { useStats } from '@violetta/ubeya/utils';
import { useSetRecoilState } from 'recoil';
import { useRooms } from '../../hooks/useRooms';
import { useUserChatFunctions } from '../../hooks/useUserChatFunctions';
import { ChatRoom } from '../ChatRoom';
import useBoardSocket from '../../hooks/useBoardSocket';

type ParamList = {
  params: {
    roomId: number;
  };
};

export const UserRoomScreen: FC = () => {
  const {
    params: { roomId },
  } = useRoute<RouteProp<ParamList, 'params'>>();

  const { goBack } = useNavigation();
  const { data: userData } = useUser();
  const { invalidateHasUnreadMessages } = useStats({ enabled: false });

  useBoardSocket({ id: userData?.id, type: 'user', enabled: true });

  const selectChatRoom = useSetRecoilState(selectedUserChatRoom);

  const { imageFunc, nameFunc } = useUserChatFunctions();

  const {
    isLoading,
    mappedRooms,
    sendMessage,
    readMoreMessages,
    isLoadingMoreMessages,
    hasUnread,
  } = useRooms({
    id: userData?.id,
    type: 'user',
    imageFunc,
    nameFunc,
  });

  useEffect(() => {
    if (!hasUnread) {
      invalidateHasUnreadMessages();
    }
  }, [invalidateHasUnreadMessages, hasUnread]);

  const room = useMemo(() => mappedRooms[roomId], [mappedRooms, roomId]);

  useEffect(() => {
    if (room) {
      selectChatRoom(room);
    }
  }, [goBack, isLoading, room, selectChatRoom]);

  if (isLoading || !room) {
    return <Loader />;
  }

  return (
    <ChatRoom
      id={userData?.id}
      type="user"
      sendMessage={sendMessage}
      readMoreMessages={readMoreMessages}
      isLoadingMoreMessages={isLoadingMoreMessages}
      room={room}
    />
  );
};
