import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { useSequenceInstance } from './useSequenceInstance';
import { formsResultsAtom, useUbeyaForm } from './useUbeyaForm';

export const useSequence = (sequenceId: number, instanceId: number) => {
  const { isLoading, sequenceInstance, isStale } = useSequenceInstance(
    sequenceId,
    instanceId
  );
  const { isFormCompleted } = useUbeyaForm();

  const [allFormsResults, setFormsResults] = useRecoilState(formsResultsAtom);
  const formsResults = useMemo(
    () =>
      sequenceInstance?.sequence?.forms
        ?.map(({ id }) => id)
        .reduce((carry, id) => {
          carry[id] = allFormsResults[id];
          return carry;
        }, {}),
    [allFormsResults, sequenceInstance?.sequence?.forms]
  );

  useEffect(() => {
    setFormsResults((formsResults) => {
      if (isLoading) {
        return formsResults;
      }
      const filteredForms = sequenceInstance?.sequence?.forms?.filter(
        (form) => !formsResults?.[form.id]
      );

      if (!filteredForms || filteredForms.length <= 0) {
        return formsResults;
      }

      return {
        ...formsResults,
        ...filteredForms.reduce((carry, form) => {
          carry[form.id] = {
            id: form.id,
            valid: form.valid !== undefined ? form.valid : true,
            fields: form.fields.reduce((carryIn, { id, value, type }) => {
              carryIn[[id, type].filter((cur) => !!cur).join('-')] = {
                id,
                value,
                type,
              };

              return carryIn;
            }, {}),
          };
          return carry;
        }, {}),
      };
    });
  }, [sequenceInstance?.sequence?.forms, isLoading, setFormsResults]);

  const [completedFormIds, notCompletedFormIds] = useMemo(() => {
    const completedFormIds = new Set<number>();
    const notCompletedFormIds = new Set<number>();

    const forms = sequenceInstance?.sequence?.forms || [];

    forms.forEach((form) => {
      if (isFormCompleted(form)) {
        completedFormIds.add(form.id);
      } else {
        notCompletedFormIds.add(form.id);
      }
    });

    return [completedFormIds, notCompletedFormIds] as const;
  }, [sequenceInstance, isFormCompleted]);

  return {
    isLoading,
    sequenceInstance,
    completedFormIds,
    notCompletedFormIds,
    formsResults,
    isStale,
  };
};
