import React, { FC, useContext } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  Card,
  FlexColumnBetween,
  H6Medium,
  H7Regular,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { ChevronRight } from '@violetta/ubeya/ui';
import { ConfigContext } from '../../../../../../contexts';

const Container = styled(Card)`
  padding: 13px 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const UpperTitle = styled(H6Medium)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blue2};
`;

const LowerTitle = styled(H7Regular)`
  margin-top: 5px;
`;

const TitlesContainer = styled(FlexColumnBetween)`
  align-items: flex-start;
`;

interface Props {}

export const PersonalDetailsCard: FC<Props> = () => {
  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();

  return (
    <Container>
      <TitlesContainer>
        <UpperTitle>{t('completeYourPersonalDetails')}</UpperTitle>
        <LowerTitle>7/10 {t('detailsCompleted')}</LowerTitle>
      </TitlesContainer>
      <ChevronRight size={15} rtl={rtl} />
    </Container>
  );
};
