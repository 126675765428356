export const palette = [
  { light: '#fff2d9', dark: '#d28313', gradient: 'linear-gradient(to left, #ffce73 83%, #ffefd2 1%)' },
  { light: '#d5f5ff', dark: '#2290b0', gradient: 'linear-gradient(to right, #e0f9ff 0%, #a0d7e7 70%)' },
  { light: '#d4d0ff', dark: '#4825d2', gradient: 'linear-gradient(to right, #b091f4 -12%, #4825d2 112%)' },
  { light: '#ffd4e2', dark: '#ee3873', gradient: 'linear-gradient(to right, #ffdfeb -4%, #ff5d91 126%)' },
  { light: '#e5ebf2', dark: '#798a9e', gradient: 'linear-gradient(to right, #c5ccd4 -20%, #798a9e 109%)' },
  { light: '#ecd6ff', dark: '#9720e0', gradient: 'linear-gradient(to right, #e7b0f9 -20%, #9720e0 116%)' },
  { light: '#e7f0ff', dark: '#1c66f2', gradient: 'linear-gradient(to bottom, #e4f2ff 0%, #1c66f2)' },
  { light: '#ffe6d0', dark: '#df6a0c', gradient: 'linear-gradient(to left, #ee750f 93%, #ffeada 1%)' },
];
