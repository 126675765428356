import React, { FC } from 'react';
import {
  BaseView,
  H6Medium,
  FlexRowAround,
  Modal,
  Button,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

interface IButtonProps {
  title: string;
  onPress: any;
  isLoading: boolean;
}

interface Props {
  isOpen: boolean;
  firstButton: IButtonProps;
  secondButton: IButtonProps;
  title: string;
  subtitle: string;
}

const Content = styled(BaseView)`
  align-items: center;
  flex: 1;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.08);
`;
const ModalView = styled(BaseView)`
  margin: 20px;
  background-color: white;
  border-radius: 8px;
  padding: 22px 34px;
`;

const HeaderText = styled(H6Medium)`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-bottom: 15px;
`;

const SubheaderText = styled(H6Medium)`
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  color: #7d7d7d;
  margin-bottom: 15px;
  line-height: 24px;
`;

const ButtonContainer = styled(FlexRowAround)``;

export const TwoButtonModal: FC<Props> = ({
  firstButton,
  secondButton,
  isOpen,
  title,
  subtitle,
}) => (
  <Modal animationType="fade" transparent visible={isOpen}>
    <Content>
      <ModalView>
        <HeaderText>{title}</HeaderText>
        <SubheaderText>{subtitle}</SubheaderText>
        <ButtonContainer>
          <Button
            type="primaryReversed"
            title={firstButton.title}
            onPress={firstButton.onPress}
            fontSize={14}
            paddingHorizontal={firstButton.isLoading ? 15 : 30}
            paddingVertical={12}
            radius={30}
            isLoading={firstButton.isLoading}
          />
          <Button
            type="primary"
            title={secondButton.title}
            onPress={secondButton.onPress}
            fontSize={14}
            radius={30}
            paddingHorizontal={secondButton.isLoading ? 30 : 45}
            paddingVertical={12}
            isLoading={secondButton.isLoading}
          />
        </ButtonContainer>
      </ModalView>
    </Content>
  </Modal>
);
