import React from 'react';
import { icons } from '@violetta/ubeya/assets';

import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { SvgIcon } from '../Icons';
import { BaseView, FlexRow } from '../Layout';

const Wrapper = styled(FlexRow)`
  margin: 20px 0;
  background-color: ${({ theme }) => theme.colors.surface};
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled(BaseView)`
  margin: 0 5px;
`;

const AttachmentActionsRenderer = ({ onDelete, onPreview, showPreview }) => (
  <Wrapper $showPreview={showPreview}>
    <IconContainer as={Pressable} onPress={onDelete}>
      <SvgIcon
        xml={icons?.complianceIcons?.complianceFileDelete}
        height={70}
        width={70}
      />
    </IconContainer>

    <IconContainer as={Pressable} onPress={onPreview}>
      <SvgIcon
        xml={icons?.complianceIcons?.complianceFileDownload}
        height={70}
        width={70}
      />
    </IconContainer>

    {showPreview && (
      <IconContainer as={Pressable} onPress={onPreview}>
        <SvgIcon
          xml={icons?.complianceIcons?.complianceFilePreview}
          height={70}
          width={70}
        />
      </IconContainer>
    )}
  </Wrapper>
);

export default AttachmentActionsRenderer;
