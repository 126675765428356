import React, { FC, useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  Booking,
  CustomTimesheetDTO,
  IBranch,
  IBranchTimesheetConfig,
  ILocation,
  IPosition,
} from '@violetta/ubeya/entities';
import {
  BaseImage,
  BaseView,
  Card,
  RipplePressable,
  H6Medium,
} from '@violetta/ubeya/ui';
import momentTz from 'moment-timezone';
import styled from 'styled-components/native';
import { HIDE_LOCATION_NAME_ACCOUNT_IDS } from '../../../main/constants';
import { TimeclockScreenNames } from '../../../timeclock';
import { ActiveTimeclockCardBody } from './ActiveTimeclockCardBody';
import { NotActiveTimeclockCardBody } from './NotActiveTimeclockCardBody';

const ImageContainer = styled(BaseView)`
  display: flex;
  position: relative;
`;

const Image = styled(BaseImage)`
  height: 106px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  resize-mode: cover;
`;

const CardDataContainer = styled(BaseView)`
  padding: 18px 16px;
`;

const BranchName = styled(H6Medium)`
  font-size: 15px;
  font-weight: normal;
  color: #252525;
  padding-top: 10px;
`;
const ProjectName = styled(H6Medium)`
  font-size: 15px;
  font-weight: normal;
  color: #252525;
  padding-top: 5px;
`;
const PositionName = styled(H6Medium)`
  font-size: 15px;
  font-weight: normal;
  color: #252525;
  padding-top: 5px;
`;

interface Props {
  timesheet: CustomTimesheetDTO;
  mappedPositions: { [key: number]: IPosition };
  mappedLocations: { [key: number]: ILocation };
  mappedBranches: { [key: number]: IBranch };
  type: 'active' | 'not-active' | 'break';
}

export const TimeclockCard: FC<Props> = ({
  type,
  timesheet,
  mappedPositions,
  mappedLocations,
  mappedBranches,
  t,
}) => {
  const { navigate } = useNavigation();
  const scheduledStartTime = useMemo(() => {
    if (timesheet.shift !== undefined) {
      return timesheet.shift.startTime;
    }
    return null;
  }, [timesheet]);

  const location = mappedLocations[timesheet?.locationId];
  const position =
    timesheet?.shift?.positionId &&
    t(mappedPositions?.[timesheet?.shift?.positionId]?.slug || '');
  const projectName = timesheet?.shift?.project?.name;
  const activeBreak = useMemo(
    () =>
      timesheet.breaktimes.find(
        (time) => !!time.startTime && time.endTime === null
      ),
    [timesheet.breaktimes]
  );

  const onCardPress = useCallback(() => {
    navigate(TimeclockScreenNames.MAIN, {
      screen: TimeclockScreenNames.TIMECLOCK_REPORT,
      params: { timesheet, timesheetId: timesheet.id },
    });
  }, [navigate, timesheet]);

  const branch = useMemo(
    () => timesheet?.branchId && mappedBranches?.[timesheet?.branchId],
    [mappedBranches, timesheet?.branchId]
  ) as IBranch;

  const reportedStartTime = momentTz
    .tz(
      activeBreak ? activeBreak?.startTime! : timesheet?.startTime!,
      location?.timezoneName
    )
    .toDate();

  const timesheetConfig = branch?.timesheetConfig as IBranchTimesheetConfig;

  const showLocationName = useMemo(
    () =>
      timesheet?.branchId
        ? !HIDE_LOCATION_NAME_ACCOUNT_IDS.includes(
            mappedBranches?.[timesheet?.branchId]?.accountId
          )
        : true,
    [mappedBranches, timesheet?.branchId]
  );

  return (
    <Card as={RipplePressable} onPress={onCardPress}>
      {location?.image && (
        <ImageContainer>
          <Image
            source={{ uri: location.image }}
            resizeMode="cover"
            resizeMethod="scale"
          />
        </ImageContainer>
      )}
      <CardDataContainer>
        {type === 'active' && (
          <ActiveTimeclockCardBody
            reportedStartTime={reportedStartTime}
            onCardPress={onCardPress}
            activeBreak={activeBreak}
            timesheet={timesheet}
            toRequireCheckInWithMobile={
              timesheetConfig.toRequireCheckInWithMobile
            }
          />
        )}
        {type === 'not-active' && (
          <NotActiveTimeclockCardBody
            scheduledStartTime={scheduledStartTime}
            type={type}
            onCardPress={onCardPress}
          />
        )}
        <BranchName>
          {branch?.name || ''}
          {branch?.name && !!location?.name && !!showLocationName ? ', ' : ''}
          {!!showLocationName && !!location?.name && `${location?.name}`}
        </BranchName>
        {projectName && <ProjectName>{projectName}</ProjectName>}
        <PositionName>{position}</PositionName>
      </CardDataContainer>
    </Card>
  );
};
