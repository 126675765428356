import styled from 'styled-components/native';

export const BaseImage = styled.Image<{ width?: number; height?: number }>`
  resize-mode: contain;
  ${({ height }) => !!height && `height: ${height}px;`}
  ${({ width }) => !!width && `width: ${width}px;`}
`;

export const LargeProfileImage = styled(BaseImage)`
  height: 94px;
  width: 94px;
  border-width: 3px;
  border-color: ${({ theme }) => theme.colors.surface};
  border-radius: 47px;
`;
