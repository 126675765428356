import { Alert } from '@violetta/ubeya/alert';
import { useTranslation } from '@violetta/ubeya/i18n';
import { debounce } from 'lodash';
import React, { FC, useCallback } from 'react';
import { Form, FormSpy } from 'react-final-form';
import styled, { useTheme } from 'styled-components/native';
import { InputField } from '../FormFields';
import { BaseView, FlexColumnDirection, FlexRowBetween } from '../Layout';
import { TextInput } from '../TextInputs';
import { BaseText, H5Regular } from '../Typography';
import { BaseCollapsible } from './BaseCollapsible';

const Container = styled(FlexColumnDirection)`
  margin-top: 10px;
`;

const InputContainer = styled(BaseView)``;

const EventComments = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
`;

const TextContainer = styled(BaseView)`
  margin-top: 10px;
`;

const Title = styled(H5Regular)`
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const Call = styled(H5Regular)`
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.link};
`;

interface Props {
  project: any;
  updateProject: ({ values }: { values: any }) => void;
  call: (phone: string) => void;
  containerStyle;
}

const inputStyle = (colors) => ({
  borderTopWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  marginBottom: 10,
  paddingLeft: 0,
  paddingBottom: 5,
  fontSize: 14,
  backgroundColor: colors.whitesmoke,
  color: colors.textPrimary,
});

const EventCommentsWrapper = styled(BaseView)``;

const Trigger = React.memo(() => {
  const { t } = useTranslation();

  return (
    <EventCommentsWrapper>
      <BaseView>
        <EventComments>{t('addComments')}</EventComments>
      </BaseView>
    </EventCommentsWrapper>
  );
});

export const CommentsCollapsible: FC<Props> = React.memo(
  ({ project, updateProject, call, containerStyle = {} }) => {
    const { t } = useTranslation();
    const { colors } = useTheme();

    const onCommentsChange = debounce(
      useCallback(
        ({ dirty, values }) => {
          if (!dirty) {
            return;
          }

          const formattedValues = {
            publicComments: values?.publicComments,
            comments: values?.comments,
          };

          updateProject({ values: formattedValues });
        },
        [updateProject]
      ),
      1500
    );

    return (
      <BaseCollapsible
        trigger={Trigger}
        containerStyle={{
          marginTop: 20,
          marginBottom: 20,
          paddingHorizontal: 15,
          borderBottomColor: 'transparent',
          ...containerStyle,
        }}
      >
        <Form
          onSubmit={() => {}}
          initialValues={{
            publicComments: project?.publicComments,
            comments: project?.comments,
            onsiteContact: project?.onsiteContact,
          }}
          render={() => (
            <Container>
              <FormSpy
                subscription={{ values: true, dirty: true, dirtyFields: true }}
                onChange={onCommentsChange}
              />

              <InputContainer>
                <Title>{t('generalEventComments')}</Title>
                <InputField
                  fieldProps={{
                    name: 'publicComments',
                  }}
                  component={TextInput}
                  props={{
                    placeholder: t('addGeneralComments'),
                    style: inputStyle(colors),
                    multiline: true,
                    placeholderTextColor: colors.gray2,
                  }}
                />
                <Title>{t('managersComments')}</Title>
                <InputField
                  fieldProps={{
                    name: 'comments',
                  }}
                  props={{
                    placeholder: t('addManagersComments'),
                    style: inputStyle(colors),
                    multiline: true,
                    placeholderTextColor: colors.gray2,
                  }}
                  component={TextInput}
                />
                <Title>{t('managersOnsiteContact')}</Title>
                <InputField
                  fieldProps={{
                    name: 'onsiteContact',
                  }}
                  props={{
                    placeholder: t('addManagersOnsiteContact'),
                    style: inputStyle(colors),
                    multiline: true,
                    placeholderTextColor: colors.gray2,
                  }}
                  component={TextInput}
                />
                {project?.onsiteContactPhone && (
                  <TextContainer>
                    <Title>{t('managersOnsiteContactPhone')}</Title>
                    <Call onPress={() => call(project.onsiteContactPhone)}>
                      {project.onsiteContactPhone}
                    </Call>
                  </TextContainer>
                )}
                {project?.meetingSpot && (
                  <TextContainer>
                    <Title>{t('managerMeetingSpot')}</Title>
                    <Title>{project.meetingSpot}</Title>
                  </TextContainer>
                )}
              </InputContainer>
            </Container>
          )}
        />
      </BaseCollapsible>
    );
  }
);
