import React, { FC } from 'react';
import { InferProps } from '@violetta/ubeya/utils';
import { Field } from 'react-final-form';
import { CheckBox } from '../CheckBoxes';
import { FormFieldProps } from './types';

export const CheckBoxField: FC<
  FormFieldProps<boolean, InferProps<typeof CheckBox>>
> = ({ fieldProps, props, component: Component }) => (
  <Field<boolean>
    {...fieldProps}
    render={({ input }) => {
      return (
        <Component {...props} onChange={input.onChange} value={input.value} />
      );
    }}
  />
);
