import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import {
  useAccounts,
  useLabels,
  useLocations,
  useTimesheetsUtils,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { compoundedNameBuilder } from '@violetta/ubeya/shared';
import {
  BaseText,
  BaseView,
  FlexColumnCenter,
  FlexColumnDirection,
  FlexRow,
  RipplePressable,
} from '@violetta/ubeya/ui';
import {
  hoursToTotalDuration,
  openMap,
  totalDuration,
} from '@violetta/ubeya/utils';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Form } from 'react-final-form';
import { ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import { BookingSummary } from '../../bookings/components/BookingSummary';
import { AppContext } from '../../main/AppContext';
import { useBreakTypes } from '../../timeclock';
import { EditTimesheetForm } from '../components/EditTimesheetForm';

const Wrapper = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  display: flex;
  flex: 1;
`;

const ImageContainer = styled(BaseView)`
  display: flex;
`;

const Image = styled.Image`
  width: 100%;
  height: 143px;
`;

const LocationContainer = styled(BaseView)`
  display: flex;
  width: 100%;
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.gray11};
`;

const TimeText = styled(BaseText)`
  font-size: 14px;
  font-weight: 500;
  color: #1e2534;
  width: 60px;
`;

const TitleText = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  color: #252525;
`;

const TotalCircle = styled(FlexColumnCenter)`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.primary};
  position: absolute;
  right: 10px;
  bottom: 15px;
  z-index: 11;
`;

const TotalText = styled(BaseText)`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #fff;
`;

const TimelineRow = styled(FlexRow)`
  align-items: center;
`;

const TimelineContainer = styled(BaseView)`
  padding-left: 14px;
  margin: 15px 0 5px;
  padding-bottom: 10px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
  border-bottom-width: 1px;
`;

interface Props {}

type ParamList = {
  params: {
    timesheet: any;
    viewDone?: boolean;
  };
};

const BottomContentContainer = styled(FlexColumnDirection)`
  background-color: ${({ theme }) => theme.colors.background};
  padding: 10px;
`;

export const EditTimesheetScreen: FC<Props> = () => {
  const {
    timesheets: { selectTimesheet },
  } = useContext(AppContext);
  const { mappedAccounts } = useAccounts();

  const {
    params: { timesheet },
  } = useRoute<RouteProp<ParamList, 'params'>>();
  const { mappedLocations } = useLocations();
  const { mappedBreakTypes } = useBreakTypes();
  const { mappedLabels } = useLabels();
  const { createReports } = useTimesheetsUtils();
  const { goBack } = useNavigation();
  const { bottom } = useSafeAreaInsets();
  const { t } = useTranslation();

  const branchId = useMemo(
    () =>
      timesheet?.job?.branchId ||
      timesheet?.shift?.project?.branchId ||
      timesheet?.branchId,
    [
      timesheet?.branchId,
      timesheet?.job?.branchId,
      timesheet?.shift?.project?.branchId,
    ]
  );

  useEffect(() => {
    if (!timesheet) {
      goBack();
    }
    selectTimesheet(timesheet);
  }, [timesheet, goBack, selectTimesheet]);

  const location = useMemo(() => {
    if (!timesheet) {
      return null;
    }
    const locationId =
      timesheet.shift?.locationId || timesheet.shift?.project?.locationId;
    return locationId ? mappedLocations[locationId] : null;
  }, [timesheet, mappedLocations]);

  const reports = useMemo(
    () =>
      timesheet
        ? createReports(
            timesheet,
            timesheet?.breaktimes,
            mappedLocations,
            mappedBreakTypes,
            momentTz
          )
        : [],
    [createReports, mappedBreakTypes, mappedLocations, timesheet]
  );

  const compoundedName = compoundedNameBuilder(
    timesheet?.shift,
    mappedLabels,
    mappedLocations
  );

  const total = useMemo(() => {
    const deductBreaktimesIsActive =
      timesheet?.shift?.project?.branch?.accountId &&
      mappedAccounts?.[timesheet?.shift?.project?.branch?.accountId]
        ?.payrollConfig?.deductBreaktimesIsActive;

    const breakDureation = deductBreaktimesIsActive
      ? (timesheet?.breaktimes || []).reduce(
          (sum, { startTime, endTime }) =>
            sum +
            totalDuration(moment(startTime), moment(endTime)).as('minutes'),
          0
        )
      : 0;

    return timesheet?.startTime && timesheet?.endTime
      ? hoursToTotalDuration(
          totalDuration(
            moment(timesheet?.startTime),
            moment(timesheet?.endTime)
          ).subtract(breakDureation, 'minutes')
        )
      : null;
  }, [
    mappedAccounts,
    timesheet?.breaktimes,
    timesheet?.endTime,
    timesheet?.shift?.project?.branch?.accountId,
    timesheet?.startTime,
  ]);

  const openLocationMap = useCallback(() => openMap(location), [location]);
  const tempBooking = useMemo(
    () =>
      ({ ...timesheet?.shift, compoundedName } || {
        ...timesheet,
        compoundedName,
      }),
    [compoundedName, timesheet]
  );
  return (
    <Wrapper>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingBottom: bottom }}
      >
        <LocationContainer>
          {location && !!location.image && (
            <ImageContainer as={RipplePressable} onPress={openLocationMap}>
              <Image
                source={{ uri: location.image }}
                resizeMode="cover"
                resizeMethod="scale"
              />
            </ImageContainer>
          )}
        </LocationContainer>
        <BottomContentContainer>
          <BookingSummary
            locationId={location?.id}
            booking={tempBooking}
            branchId={branchId}
            showBreaksNotice={false}
            openLocationMap={openLocationMap}
          />
        </BottomContentContainer>
        <Form
          onSubmit={() => {}}
          initialValues={{
            startTime: timesheet?.startTime,
            endTime: timesheet?.endTime,
          }}
          render={({ handleSubmit, dirty }) => (
            <EditTimesheetForm handleSubmit={handleSubmit} dirty={dirty} />
          )}
        />
      </ScrollView>
    </Wrapper>
  );
};
