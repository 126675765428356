import React, { FC } from 'react';
import { IPeriod, useBranches } from '@violetta/ubeya/entities';
import {
  FlexColumnDirection,
  FlexRow,
  FlexRowBetween,
  H2Regular,
  H7Regular,
  Timeline,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';
import { DayAvailabilityCard } from '../DayAvailabilityCard';

const TimelineRow = styled(FlexRow)`
  flex: 1;
  align-items: center;
`;

const DateContainer = styled(FlexColumnDirection)`
  width: 50px;
  align-items: center;
`;

const DayContainer = styled(FlexRowBetween)`
  flex: 1;
`;

const DayText = styled(H2Regular)``;
const DateText = styled(H7Regular)``;

interface Day {
  date: Date;
  labels: { id: number; status: number }[];
}

interface Props {
  period: IPeriod;
  onDayPress?: (params?: { date?: Date | undefined }) => void;
}

export const AvailabilitySummary: FC<Props> = ({ period, onDayPress }) => {
  const { mappedBranches } = useBranches();
  return (
    <>
      <Timeline
        data={period.dates}
        // eslint-disable-next-line react/no-unused-prop-types
        item={({ nob: Nob, data: { date } }: { data: Day; nob: FC<any> }) => (
          <TimelineRow>
            <Nob />
            <DateContainer>
              <DayContainer>
                <DayText>{moment(date).format('D')}</DayText>
              </DayContainer>
              <DateText>{moment(date).format('ddd')}</DateText>
            </DateContainer>
            <DayAvailabilityCard
              date={date}
              onPress={() => onDayPress?.({ date })}
              periodId={period.id}
              mappedBranches={mappedBranches}
            />
          </TimelineRow>
        )}
      />
    </>
  );
};
