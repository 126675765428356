import moment from 'moment';

type TimeOfDay = 'morning' | 'noon' | 'afterNoon' | 'evening' | 'night';

export const timeOfDay = (): TimeOfDay => {
	const now = moment().hour();

	if (now > 6 && now <= 12) {
		return 'morning';
	}
	if (now > 12 && now <= 17) {
		return 'afterNoon';
	}
	if (now > 17 && now <= 20) {
		return 'evening';
	}

	return 'night';
};
