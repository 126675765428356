import React, { FC, useState } from 'react';
import styled from 'styled-components/native';
import { icons } from '@violetta/ubeya/assets';
import { BaseView, Card, H3Bold, H5Regular, SvgIcon } from '@violetta/ubeya/ui';
import { Dimensions } from 'react-native';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import { theme } from '../../../../style';

const Container = styled(BaseView)`
  margin: 10px 0 0;
`;

const Box = styled(Card)`
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f0fbff;
  margin: 2px 0;
`;
const IconContainer = styled(BaseView)``;
const Title = styled(H3Bold)`
  margin: 15px 0;
`;
const Body = styled(H5Regular)`
  text-align: center;
  line-height: 21px;
  font-size: 15px;
  font-weight: 300;
`;
const sliderWidth = Dimensions.get('window').width - 40;
const itemWidth = sliderWidth - 5;

const Item: FC<{ item: WalkItem; index: number }> = ({ item }) => (
  <Box>
    <IconContainer>
      <SvgIcon xml={item.icon} width={197} height={197} color="#6d6d78" />
    </IconContainer>
    <Title>{item.title}</Title>
    <Body>{item.body}</Body>
  </Box>
);

type WalkItem = {
  icon: string;
  title: string;
  body: string;
};
const ITEMS: WalkItem[] = [
  {
    icon: icons.people.swing,
    title: 'Be in the know!',
    body: 'Apply for shifts, communicate with your team, track your time, and never miss anything.',
  },
  {
    icon: icons.people.suitcase,
    title: "Let's Get To Work",
    body: 'Your one-top shop for all your employment essentials',
  },
];

export const WalkThroughCard: FC<{}> = () => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  return (
    <Container>
      <Carousel
        data={ITEMS}
        renderItem={Item}
        sliderWidth={sliderWidth}
        itemWidth={itemWidth}
        onSnapToItem={setActiveSlide}
      />
      <Pagination
        containerStyle={{ margin: 0, paddingVertical: 15 }}
        dotsLength={ITEMS.length}
        activeDotIndex={activeSlide}
        dotStyle={{
          width: 10,
          height: 10,
          borderRadius: 5,
          marginHorizontal: 1,
          backgroundColor: theme.colors.secondary,
        }}
        inactiveDotStyle={
          {
            // Define styles for inactive dots here
          }
        }
        inactiveDotOpacity={0.4}
        inactiveDotScale={0.6}
      />
    </Container>
  );
};
