import { useTranslation } from '@violetta/ubeya/i18n';

import React, { FC } from 'react';
import { Form } from 'react-final-form';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import styled, { useTheme } from 'styled-components/native';
import { Button } from '../Buttons/Button';
import { InputField } from '../FormFields/InputField';
import { IconClose } from '../Icons';
import { BaseView, FlexRowBetween } from '../Layout';
import { TextInput } from '../TextInputs/TextInput';
import { BaseText } from '../Typography';

const ModalSection = styled(BaseView)`
  padding: 50px 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justifty-content: center;
`;

const ModalRow = styled(FlexRowBetween)`
  margin-bottom: 15px;
  align-items: center;
`;

const ModalRowText = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #1e2534;
`;

const ButtonContainer = styled(BaseView)`
  padding: 24px 16px;
`;

const ModalHeader = styled(FlexRowBetween)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 30px;
`;

const HeaderIcon = styled(BaseView)`
  margin-left: -10px;
`;

interface Props {
  modalizeRef: any;
  declineTimesheet: any;
  isLoadingDeclineTimesheet: boolean;
}

export const DeclineTimesheetModal: FC<Props> = ({
  declineTimesheet,
  modalizeRef,
  isLoadingDeclineTimesheet,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const Header = (
    <ModalHeader>
      <HeaderIcon>
        <IconClose
          color="#000"
          size={22}
          onPress={() => modalizeRef?.current?.close()}
        />
      </HeaderIcon>
    </ModalHeader>
  );

  return (
    <Portal>
      <Form
        onSubmit={async (values) => {
          await declineTimesheet({
            decline: { reason: values?.reason },
          });
          modalizeRef?.current?.close();
        }}
        mutators={{
          clearValues: (args, state, utils) => {
            utils.changeValue(state, args[0].sectionName, () => []);
          },
        }}
        keepDirtyOnReinitialize
        initialValues={{
          reason: '',
        }}
        render={({ handleSubmit, form, values }) => (
          <Modalize
            adjustToContentHeight
            ref={modalizeRef}
            panGestureEnabled={false}
            scrollViewProps={{ scrollEnabled: true }}
            withHandle={false}
            HeaderComponent={Header}
            FooterComponent={
              <ButtonContainer>
                <Button
                  disabled={!values?.reason}
                  isLoading={isLoadingDeclineTimesheet}
                  title={t('declineShiftHours') || ''}
                  type="primary"
                  onPress={handleSubmit}
                />
              </ButtonContainer>
            }
          >
            <ModalSection>
              <ModalRow>
                <ModalRowText>{t('declineTimesheetAddReason')}</ModalRowText>
              </ModalRow>

              <InputField
                fieldProps={{
                  name: 'reason',
                }}
                props={{
                  multiline: true,
                  numberOfLines: 3,
                }}
                component={TextInput}
              />
              <Button
                title={t('clear') || ''}
                onPress={() =>
                  form.mutators.clearValues({ sectionName: 'reason' })
                }
                type="primary"
                fontSize={14}
                radius={18}
                paddingVertical={6}
                style={{
                  backgroundColor: colors.primaryLight4,
                  borderColor: colors.primaryLight4,
                  width: 100,
                  marginTop: 20,
                }}
                textStyle={{ color: colors.textPrimary }}
              />
            </ModalSection>
          </Modalize>
        )}
      />
    </Portal>
  );
};
