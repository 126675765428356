import React, { FC, useCallback, useMemo } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import styled from 'styled-components/native';
import { BaseView, FlexColumnDirection } from '../Layout';
import { Toggler } from '../Toggler';
import { DateInputPicker, DateInputPickerProps } from './DateInputPicker';
import {
  DateRangeInputPicker,
  Props as DateRangeInputPickerProps,
} from './DateRangeInputPicker';

const TogglerContainer = styled(BaseView)`
  margin: 0 0 20px;
`;

const Container = styled(FlexColumnDirection)``;

export type ValueProps = {
  value?: DateRangeInputPickerProps['value'] | DateInputPickerProps['value'];
  isRange: boolean;
};

export interface DateTogglerPickerProps {
  value: ValueProps;
  onChange?: (value: ValueProps) => void;
  rtl: boolean;
}

export const DateTogglerPicker: FC<DateTogglerPickerProps> = ({
  onChange = () => {},
  value,
  rtl,
}) => {
  const { t } = useTranslation();

  const dateTypeTogglerItems = useMemo(
    () => [
      {
        id: 1,
        title: t('datePickerOptionSingleDate'),
      },
      {
        id: 2,
        title: t('datePickerOptionMultipleDates'),
      },
    ],
    [t]
  );

  const onRangeChange = useCallback<(itemId: number) => void>(
    (itemId) =>
      onChange?.({
        value: { ...value } as any,
        isRange: itemId === 2,
      }),
    [onChange, value]
  );

  const onValueChange = useCallback(
    (newValue: ValueProps) =>
      onChange?.({
        value: !value.isRange
          ? { start: newValue, end: newValue }
          : ({ ...newValue } as any),
        isRange: value.isRange,
      }),
    [onChange, value.isRange]
  );

  return (
    <Container>
      <TogglerContainer>
        <Toggler
          selectedItemId={value?.isRange ? 2 : 1}
          onItemSelected={onRangeChange}
          items={dateTypeTogglerItems}
        />
      </TogglerContainer>

      {value.isRange ? (
        <DateRangeInputPicker
          value={value.value as DateRangeInputPickerProps['value']}
          onChange={
            onValueChange as unknown as DateRangeInputPickerProps['onChange']
          }
          rtl={rtl}
        />
      ) : (
        <DateInputPicker
          value={value.value?.start as DateInputPickerProps['value']}
          onChange={
            onValueChange as unknown as DateInputPickerProps['onChange']
          }
          rtl={rtl}
        />
      )}
    </Container>
  );
};
