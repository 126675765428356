import { FC } from 'react';

export enum MAP_ITEM_TYPE {
  CLOCK_IN = 0,
  CHECK_IN = 1,
  BREAK_STARTED = 2,
  BREAK_ENDED = 3,
  CHECK_OUT = 4,
  CLOCK_OUT = 5,
}

export interface IReport {
  timestamp: Date;
  title: string;
  subTitle?: string;
  nob?: Element<any>;
  type: MAP_ITEM_TYPE;
}
