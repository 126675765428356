import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { TodoHeader } from '../components';
import { TodoListScreen } from '../screens';
import { TodoScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const TodoNavigation: FC = () => (
  <Navigator
    initialRouteName={TodoScreenNames.TODO_LIST}
    screenOptions={{
      header: () => null,
    }}
  >
    <Screen
      name={TodoScreenNames.TODO_LIST}
      component={TodoListScreen}
      options={{
        header: (props: NativeStackHeaderProps) => <TodoHeader {...props} />,
      }}
    />
  </Navigator>
);
