import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { BaseView } from '../Layout';

export const Card = styled(BaseView)`
  shadow-color: #777777;
  shadow-opacity: ${Platform.OS === 'ios' ? 0.2 : 0.1};
  shadow-radius: 6px;
  shadow-offset: 2px 4px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: 8px;
  margin: 10px 16px;
  elevation: 2;
  ${Platform.OS === 'web' ? 'box-shadow: 0 1px 10px #0e34740f;' : ''};
`;
