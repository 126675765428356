import React, { FC, useCallback, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  FlexColumnDirection,
  FlexRow,
  FlexRowBetween,
  H5Medium,
  H5Regular,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import { Image as BaseImage, Linking } from 'react-native';
import styled from 'styled-components/native';
import moment from 'moment';

const Container = styled(FlexRow)`
  margin: 0 16px;
  padding: 16px 0;
  border-bottom-width: 0.5px;
  border-bottom-color: ${({ isLast, theme }) =>
    isLast ? 'transparent' : theme.colors.gray7};
`;

const DataContainer = styled(FlexColumnDirection)``;
const Row = styled(FlexRow)`
  align-items: center;
  margin-bottom: 13px;
`;

const ProfilePictureContainer = styled(BaseView)`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  margin-right: 10px;
`;
const ColorVerticalLine = styled(BaseView)`
  width: 3px;
  height: 100%;
  border-radius: 2px;
  margin-right: 10px;
  background-color: ${({ theme, color }) => color || theme.colors.gray9};
`;
const IconWrapper = styled(BaseView)`
  width: 22px;
  height: 22px;
  border-radius: 11px;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray9};
`;

const EmployeePhoto = styled(BaseImage)`
  width: 24px;
  height: 24px;
  border-radius: 15px;
  margin-right: 10px;
`;

const EmployeeName = styled(H5Medium)`
  font-size: 14px;
  font-weight: normal;
`;
const LabelName = styled(H5Medium)`
  font-size: 14px;
  font-weight: 500;
`;

const IconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
`;

const CardDataDetails = styled(H5Regular)`
  margin-left: 8px;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 300;
`;

const CardDataDetailsWarning = styled(CardDataDetails)`
  color: ${({ theme }) => theme.colors.error};
`;

const LabelStrip = styled(FlexRow)`
  padding: 10px 16px;
  background-color: ${({ label }) => (label ? label.background : '#ffffff')};
  justify-content: space-between;
`;

interface Props {
  items: {
    title: any;
    data: {
      startTime: Date | null;
      endTime: Date | null;
      firstName: string;
      lastName: string;
      image: string;
      position: {
        id: number;
        slug: string;
      };
      warnings: string[];
      phone?: string;
    }[];
  };
}

const WeeklyScheduleLabelSectionRow = React.memo(({ item, isLast }) => {
  const { t } = useTranslation();

  const handleOnPress = useCallback(() => {
    if (item?.phone) {
      Linking.openURL(`tel:${item?.phone}`);
    }
  }, [item?.phone]);

  const formattedHours = useMemo(
    () => rangeFormat(item?.startTime, item?.endTime),
    [item]
  );

  return (
    <Container as={RipplePressable} onPress={handleOnPress} isLast={isLast}>
      {!!item.label && <ColorVerticalLine color={item.label.color} />}
      <DataContainer>
        <Row>
          <ProfilePictureContainer>
            {item?.image ? (
              <EmployeePhoto source={{ uri: item?.image }} />
            ) : (
              <IconWrapper>
                <SvgIcon xml={icons?.uiIcons?.profile} />
              </IconWrapper>
            )}
          </ProfilePictureContainer>
          <EmployeeName>{`${item?.firstName || ''} ${
            item?.lastName || ''
          }`}</EmployeeName>
        </Row>
        <Row>
          <IconContainer>
            <SvgIcon xml={icons?.uiIcons?.clock2} height={18} width={18} />
          </IconContainer>
          <CardDataDetails>{formattedHours}</CardDataDetails>
        </Row>
        <Row>
          <IconContainer>
            <SvgIcon xml={icons?.uiIcons?.jobCase} height={18} width={18} />
          </IconContainer>
          <CardDataDetails>{t(item?.position?.slug) || '-'}</CardDataDetails>
        </Row>

        {(item?.warnings || []).map((warning) => (
          <Row>
            <CardDataDetailsWarning>{t(warning)}</CardDataDetailsWarning>
          </Row>
        ))}
      </DataContainer>
    </Container>
  );
});

export const WeeklyScheduleListRow: FC<Props> = React.memo(({ items }) => {
  const time = useMemo(
    () =>
      rangeFormat(
        moment(items?.title?.startTime, 'HH:mm:ss').toDate(),
        moment(items?.title?.endTime, 'HH:mm:ss').toDate()
      ),
    [items?.title?.endTime, items?.title?.startTime]
  );

  return (
    <BaseView>
      {items?.data?.length > 0 && items?.title && (
        <LabelStrip label={items?.title}>
          <LabelName>{items?.title?.title}</LabelName>
          <EmployeeName>{time}</EmployeeName>
        </LabelStrip>
      )}
      {items?.data?.map((item, index, arr) => (
        <WeeklyScheduleLabelSectionRow
          item={item}
          isLast={index === (arr || []).length - 1}
        />
      ))}
    </BaseView>
  );
});
