import React, { FC, useCallback } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  FlexRowBetween,
  H6Medium,
  InputField,
  Modal,
  TextInput,
} from '@violetta/ubeya/ui';
import { Form } from 'react-final-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';

interface Props {
  isOpen: boolean;
  setIsOpen: any;
  handleUpdate: any;
  isLoading: boolean;
  disabled: boolean;
}

const Content = styled(BaseView)`
  align-items: center;
  flex: 1;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.08);
`;
const ModalView = styled(BaseView)`
  margin: 20px;
  background-color: white;
  border-radius: 8px;
  padding: 22px 27px;
`;

const HeaderText = styled(H6Medium)`
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-bottom: 30px;
`;

const SubheaderText = styled(H6Medium)`
  /* color: ${({ theme }) => theme.colors.textPrimary}; */
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  color: #7d7d7d;
  margin-bottom: 30px;
`;

const ButtonContainer = styled(FlexRowBetween)``;

export const GeneralAvailabilityModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  handleUpdate,
  isLoading,
  disabled,
}) => {
  const { t } = useTranslation();

  const handleOnUpdatePress = useCallback(
    async ({ employeeReason, reset }) => {
      await handleUpdate(employeeReason);
      reset();
      setIsOpen(false);
    },
    [handleUpdate, setIsOpen]
  );

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      extraScrollHeight={200}
    >
      <Form
        onSubmit={handleOnUpdatePress}
        initialValues={{
          employeeReason: '',
        }}
        render={({ handleSubmit }) => (
          <Modal animationType="fade" transparent visible={isOpen}>
            <Content>
              <ModalView>
                <HeaderText>{`${t('update')} ${t('availability')}`}</HeaderText>
                <SubheaderText>
                  {t('areYouSureYouWantToUpdateAvailability')}
                </SubheaderText>
                <InputField
                  fieldProps={{
                    name: 'employeeReason',
                  }}
                  props={{
                    placeholder: t('addComment'),
                    style: { fontSize: 14, width: 300 },
                    multiline: true,
                    numberOfLines: 3,
                  }}
                  component={TextInput}
                />
                <ButtonContainer>
                  <Button
                    type="transparent"
                    title={t('cancel')}
                    onPress={() => setIsOpen(false)}
                    fontSize={14}
                    paddingHorizontal={30}
                    disabled={disabled}
                  />
                  <Button
                    type="transparent"
                    title={t('update')}
                    onPress={handleSubmit}
                    fontSize={14}
                    paddingHorizontal={30}
                    disabled={disabled}
                    isLoading={isLoading}
                  />
                </ButtonContainer>
              </ModalView>
            </Content>
          </Modal>
        )}
      />
    </KeyboardAwareScrollView>
  );
};
