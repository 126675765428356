import React, { FC, useEffect, useState } from 'react';
import { RouteProp, useRoute } from '@react-navigation/native';
import { api } from '@violetta/ubeya/api';
import { CustomTimesheetDTO, IJob } from '@violetta/ubeya/entities';
import {
  BaseView,
  CustomLoader,
  FlexCenter,
  WideFlexCenter,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styled, { useTheme } from 'styled-components/native';
import { TimeclockWrapper } from '../components/TimeclockWrapper';

const Container = styled(BaseView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.surface};
`;

interface Props {}

type ParamList = {
  params: {
    timesheet: CustomTimesheetDTO;
  };
};

export const TimeclockScreen: FC<Props> = () => {
  const {
    params: { timesheet: timesheetParam },
  } = useRoute<RouteProp<ParamList, 'params'>>();
  const queryClient = useQueryClient();
  const { colors } = useTheme();
  const [timesheetId, setTimesheetId] = useState<number | undefined>(undefined);

  const { mutateAsync: addJob } = useMutation({
    mutationFn: ({ date, branchId }: { date: Date; branchId: number }) =>
      api.addJob({ date, branchId }),
    onSuccess: async ({ data: { timesheetId } }: { data: IJob }) => {
      await queryClient.invalidateQueries({ queryKey: ['timesheets'] });
      setTimesheetId(timesheetId);
    },
  });

  useEffect(() => {
    if (!timesheetParam.id && timesheetParam.job) {
      const { branchId } = timesheetParam.job;
      addJob({ date: moment().toDate(), branchId });
    } else {
      setTimesheetId(timesheetParam.id);
    }
  }, [addJob, timesheetParam, timesheetParam.id, timesheetParam.job]);

  return (
    <Container>
      {timesheetId === undefined ? (
        <WideFlexCenter>
          <CustomLoader color={colors.primary} />
        </WideFlexCenter>
      ) : (
        <TimeclockWrapper timesheetId={timesheetId} />
      )}
    </Container>
  );
};
