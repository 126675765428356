import React, { FC, useMemo, useCallback, useState, useEffect } from 'react';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { useUser } from '@violetta/ubeya/auth';
import { useAccounts } from '@violetta/ubeya/entities';
import useBoardSocket from '../../hooks/useBoardSocket';
import { useRooms } from '../../hooks/useRooms';
import { useUserChatFunctions } from '../../hooks/useUserChatFunctions';
import { ChatScreenNames } from '../../navigation/ScreenNames';
import { RoomsList } from '../RoomsList';
import { UserSupport } from './UserSupport';
import { useRecoilState } from 'recoil';
import { supportSectionOpen } from '../../../../state';
import { config } from '../../../main/appConfig';

interface Props {}

export const UserRoomsListScreen: FC<Props> = () => {
  const { data: userData } = useUser();
  const isFocused = useIsFocused();
  const [isSocketEnabled, setIsSocketEnabled] = useState(false);
  const [showSupportModal, setShowSupportModal] =
    useRecoilState(supportSectionOpen);

  useEffect(() => {
    if (isFocused && !isSocketEnabled) {
      setIsSocketEnabled(true);
    }
  }, [isFocused, isSocketEnabled]);

  useEffect(() => {
    if (isFocused) {
      setShowSupportModal(config.showSupportModal);
    }
  }, [isFocused, setShowSupportModal]);

  const { navigate } = useNavigation();
  useBoardSocket({ id: userData?.id, type: 'user', enabled: isSocketEnabled });

  const onRoomClick = useCallback(
    (roomId: string) => {
      navigate(ChatScreenNames.ROOM, {
        screen: ChatScreenNames.ROOM,
        params: { roomId },
      });
    },
    [navigate]
  );

  const { accounts } = useAccounts();

  const { imageFunc, nameFunc } = useUserChatFunctions();

  const {
    isLoading,
    data: rooms,
    mappedRoomsByContextId,
    refetchAllRooms,
  } = useRooms({ id: userData.id!, type: 'user', nameFunc, imageFunc });

  const accountNonExistedRooms = useMemo(
    () =>
      isLoading
        ? []
        : accounts.filter(
            ({ id }) =>
              !mappedRoomsByContextId[id] ||
              mappedRoomsByContextId[id].metadata?.projectId
          ),
    [accounts, isLoading, mappedRoomsByContextId]
  );

  if (showSupportModal) {
    return <UserSupport onClose={() => setShowSupportModal(false)} />;
  }

  return (
    <RoomsList
      rooms={rooms}
      isLoading={isLoading}
      context="user"
      accountNonExistedRooms={accountNonExistedRooms}
      onRoomClick={onRoomClick}
      refetch={refetchAllRooms}
    />
  );
};
