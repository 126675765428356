import React from 'react';
import { BaseText } from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';

const Time = styled(BaseText)`
  align-items: flex-end;
  font-size: 12px;
  font-weight: 300;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.gray1};
`;

const formattedDate = (locale, date) => {
  if (locale === 'he') {
    return `${moment(date).format('D MMMM')}`;
  }
  return moment(date).format('dddd, MMMM D');
};

export const TimeAgoRenderer = ({ time, locale }) => (
  <Time>
    {moment(time).fromNow()}, {formattedDate(locale, time)},{' '}
    {moment(time).format('LT')}
  </Time>
);
