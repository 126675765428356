import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray, useOptimisticMutation } from '@violetta/ubeya/utils';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { usePositions } from '.';

export type ISwapEmployee = {
  id: number; // slot id
  startTime: string;
  endTime: string;
  positionId: number;
  employee: {
    id: number;
    firstName: string;
    lastName: string;
  };
};

type ResponseType = {
  data: ISwapEmployee[];
};

type CreateSwapRequestBody = {
  dstSlotId: number;
  reasonId: number; // enum
  dstEmployeeId: number; // for sanity check
};

type CancelSwapParams = {
  swapId: number;
};

type AcceptSwapParams = {
  swapId: number;
  accountId: number;
};

const selector = createCachedSelector(
  (data) => data?.data,
  (data, mappedPositions) => mappedPositions,
  (data, mappedPositions) => {
    const swapSlots = (data || []).map((swapSlot) => ({
      ...swapSlot,
      position: mappedPositions[swapSlot?.positionId],
    }));

    const mappedSwapSlots = mappedArray(swapSlots) as {
      [key: number]: ISwapEmployee;
    };
    const days = [
      ...new Set(
        swapSlots.map((slot) => moment(slot.startTime).format('YYYY-MM-DD'))
      ),
    ] as string[];

    const mappedSwapEmployeesByDay = days
      .sort((a, b) =>
        new Date(a)?.getTime() - new Date(b)?.getTime() > 0 ? 1 : -1
      )
      .map((day) => {
        const filteredSwaps = swapSlots.filter(
          (slot) => moment(slot.startTime).format('YYYY-MM-DD') === day
        );
        return {
          title: day,
          data: filteredSwaps,
        };
      });

    return { swapSlots, mappedSwapSlots, mappedSwapEmployeesByDay };
  }
)({
  keySelector: (data, mappedPositions, storeKey) => storeKey,
});

export const useSwap = (projectId, shiftId) => {
  const { data: userData } = useUser();
  const { mappedPositions, isLoading: isLoadingPositions } = usePositions();

  const queryClient = useQueryClient();
  const storeKey = useMemo(
    () => ['swaps', projectId, shiftId],
    [projectId, shiftId]
  );
  const { isPending, data, refetch } = useQuery<ResponseType>({
    queryKey: storeKey,
    queryFn: () => api.getSwapEmployees(projectId, shiftId),
    enabled: !!userData?.id && !isLoadingPositions,
    select: (data) => selector(data, mappedPositions, storeKey.join('#')),
  });

  const {
    swapSlots = [],
    mappedSwapSlots = {},
    mappedSwapEmployeesByDay = undefined,
  } = data || {};

  const { mutateAsync: createSwap, isPending: isCreatingSwap } = useMutation({
    mutationFn: (params: CreateSwapRequestBody) =>
      api.createSwap({ ...params, projectId, bookingId: shiftId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: storeKey });
      await queryClient.invalidateQueries({ queryKey: ['bookings'] });
    },
  });

  const { mutateAsync: acceptSwap, isPending: isAcceptingSwap } = useMutation({
    mutationFn: (params: AcceptSwapParams) => api.acceptSwap(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: storeKey });
      await queryClient.invalidateQueries({ queryKey: ['bookings'] });
    },
  });

  const { mutateAsync: cancelSwap, isPending: isCancellingSwap } = useMutation({
    mutationFn: (params: CancelSwapParams) =>
      api.cancelSwap({ ...params, projectId, shiftId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: storeKey });
      await queryClient.invalidateQueries({ queryKey: ['bookings'] });
    },
  });

  return {
    isLoading: isPending,
    swapSlots,
    mappedSwapSlots,
    mappedSwapEmployeesByDay,
    createSwap,
    isCreatingSwap,
    acceptSwap,
    isAcceptingSwap,
    cancelSwap,
    isCancellingSwap,
    refetchSwapEmployees: refetch,
  };
};
