import AsyncStorage from '@react-native-async-storage/async-storage';
import { useCallback } from 'react';
import { I18nManager } from 'react-native';
import * as RNLocalize from 'react-native-localize';
import { atom, useRecoilState } from 'recoil';

// We only have translations for 'hebrew' and 'english'
const SUPPORTED_LANGUAGES = [
  'he',
  'en',
  'en-gb',
  'de',
  'es',
  'nl',
  'ru',
  'ar',
] as const;
interface ILanguage {
  id: string;
  title: string;
}

export const languages = [
  { id: 'he', title: 'Hebrew' },
  { id: 'en', title: 'English (US)' },
  { id: 'en-gb', title: 'English (UK)' },
  { id: 'de', title: 'Deutsch' },
  { id: 'es', title: 'Español' },
  { id: 'nl', title: 'Dutch' },
  { id: 'ar', title: 'Arabic' },
  { id: 'ru', title: 'Russian' },
] as ILanguage[];

export type Locale = 'he' | 'en' | 'en-gb' | 'ar' | 'de' | 'es' | 'nl' | 'ru';

const STORE_KEY = 'locale';

export const localeAtom = atom<Locale>({
  key: 'localeAtom',
  default: 'en',
});

export const useLocale = () => {
  const [locale, setLocale] = useRecoilState(localeAtom);

  const saveLocale = useCallback(
    async (newLocale: Locale) => {
      const result = await AsyncStorage.setItem(STORE_KEY, newLocale);
      setLocale(newLocale);
      return result;
    },
    [setLocale]
  );

  const loadLocale = useCallback(async () => {
    const locale = (await AsyncStorage.getItem(STORE_KEY)) as Locale;
    if (locale && SUPPORTED_LANGUAGES.includes(locale)) {
      if (locale === 'he' || locale === 'ar') {
        I18nManager.forceRTL(true);
      } else {
        I18nManager.forceRTL(false);
      }
      return saveLocale(locale);
    }

    const rnLocales = RNLocalize.getLocales();
    if (rnLocales && rnLocales.length > 0) {
      // eslint-disable-next-line no-restricted-syntax
      for (const rnLocale of rnLocales) {
        const languageLocale =
          rnLocale.languageCode?.toLocaleLowerCase() as Locale;
        if (SUPPORTED_LANGUAGES.includes(languageLocale)) {
          const actualLocale = (() => {
            const doesUse24 = RNLocalize.uses24HourClock();
            if (languageLocale === 'en' && doesUse24) {
              return 'en-gb';
            }
            return languageLocale;
          })();
          return saveLocale(actualLocale);
        }
      }
    }

    return saveLocale('en');
  }, [saveLocale]);

  return { saveLocale, loadLocale, locale, languages };
};
