import React, { FC } from 'react';
import { Pressable, StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { SvgIcon } from '../Icons';
import { FlexRow } from '../Layout';

export const Container = styled(FlexRow)<{
  error: boolean;
  border: string | undefined;
  background: string | undefined;
}>`
  flex-wrap: wrap;
	border-width: 1px;
	border-radius: 30px;
	border-color: ${({ theme, error, border = theme.colors.inputBorder }) =>
    error ? theme.colors.red1 : border}
	background-color: ${({ theme, background }) =>
    background || theme.colors.surface};
	padding: 15px 10px;
	width: 100%;
  align-items: center;
`;

interface Props {
  icon?: {
    xml: string;
    width: number;
    height: number;
  };
  onPress?: () => void;
  error?: boolean;
  border?: string;
  background?: string;
  style: StyleProp<ViewStyle>;
}

export const InputBox: FC<Props> = ({
  children,
  icon,
  onPress,
  error = false,
  border,
  background,
  style = {},
}) => (
  <Container
    as={Pressable}
    onPress={onPress}
    error={error}
    border={border}
    background={background}
    style={style}
  >
    {!!icon && (
      <SvgIcon xml={icon.xml} width={icon.width} height={icon.height} />
    )}
    {children}
  </Container>
);
