import React, { FC, useCallback, useMemo, useRef } from 'react';
import { Modalize } from 'react-native-modalize';
import styled from 'styled-components/native';
import { ChevronDown } from '../Icons';
import { BaseView, FlexRow, RipplePressable } from '../Layout';
import { InputBox } from '../TextInputs';
import { BaseText, H5Regular } from '../Typography';
import { SelectOptionsList } from './SelectOptionsList';
import { SelectProps } from './types';

const Container = styled(BaseView)`
  z-index: 9999999999;
`;
const PreviewContainer = styled(FlexRow)``;

const RenderSelectedOption: FC<any> = () => <></>;

const RenderOption: FC<any> = () => <></>;

const OptionChips = styled(FlexRow)`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
`;
const OptionChip = styled(FlexRow)`
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.selectBackground};
  padding: 10px 15px;
  border-radius: 3px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
`;

const Placeholder = styled(H5Regular)`
  font-size: 17px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.gray7};
`;

const ErrorMessage = styled(BaseText)`
  color: ${({ theme }) => theme.colors.red1};
  margin-top: 5px;
`;

export const BaseSelect = <Option, SelectedOption>({
  options,
  title,
  headerTitle,
  onSelected,
  border,
  background,
  style,
  isMulti = false,
  rtl = false,
  searchBy,
  value,
  meta,
  readonly = false,
  placeholder,
  renderOptionChip: RenderOptionChip = OptionChip,
  renderSelectedOptionChip:
    RenderSelectedOptionComponent = RenderSelectedOption,
  renderOption: RenderOptionComponent = RenderOption,
  optionToSelectedOption = (option: Option) =>
    option as unknown as SelectedOption,
}: SelectProps<Option, SelectedOption>) => {
  const modalizeRef = useRef<Modalize>(null);

  const selectedOptions = useMemo(() => {
    if (!Array.isArray(value)) {
      return [];
    }
    const selected =
      value
        ?.filter(Boolean)
        ?.map((selectedOption) =>
          options?.find(
            (option) => optionToSelectedOption(option) === selectedOption
          )
        ) || [];

    return selected;
  }, [value, options, optionToSelectedOption]);

  const onSelectedInternal = useCallback(
    (value: number[]) => {
      onSelected?.(
        options
          .filter((option) => value.includes(option.id))
          .map(optionToSelectedOption),
        options.filter((option) => value.includes(option.id))
      );
      modalizeRef.current?.close();
    },
    [onSelected, options, optionToSelectedOption]
  );

  const open = useCallback(() => {
    modalizeRef.current?.open();
  }, []);

  if (selectedOptions.length > 0 && selectedOptions.some((id) => id < 0)) {
    return null;
  }

  return (
    <Container>
      <PreviewContainer as={RipplePressable} onPress={open}>
        <InputBox
          onPress={open}
          border={border}
          background={background}
          style={style}
        >
          <OptionChips>
            {selectedOptions.map((option) => (
              <RenderOptionChip key={option.id}>
                <RenderSelectedOptionComponent {...option} />
              </RenderOptionChip>
            ))}
            {selectedOptions.length === 0 && !!placeholder && (
              <Placeholder>{placeholder}</Placeholder>
            )}
          </OptionChips>
          <ChevronDown />
        </InputBox>
      </PreviewContainer>
      {meta?.error && meta?.touched && (
        <ErrorMessage>{meta?.error}</ErrorMessage>
      )}
      {!readonly && (
        <SelectOptionsList<Option>
          headerTitle={headerTitle || title}
          rtl={rtl}
          modalizeRef={modalizeRef}
          selectedOptions={selectedOptions}
          options={options}
          isMulti={isMulti}
          searchBy={searchBy}
          onSelected={onSelectedInternal}
          renderOption={RenderOptionComponent}
        />
      )}
    </Container>
  );
};
