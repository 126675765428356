import React, { FC, useMemo } from 'react';
import { IBranch, IPayroll, useAccounts } from '@violetta/ubeya/entities';
import {
  BaseView,
  FlexRow,
  FlexColumnDirection,
  H3Regular,
  H4Regular,
  FlexRowBetween,
  RipplePressable,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';
import { IncomeScreenNames } from '../navigation';
import { navigate } from '../../main/navigation/RootNavigation';
import { TimesheetsScreenNames } from '../../timesheets';
import { useNavigation, useTheme } from '@react-navigation/native';

const Container = styled(FlexRow)`
  padding: 15px 0;
`;

const RightContainer = styled(FlexRow)`
  flex: 1;
`;

const DateContainer = styled(FlexColumnDirection)`
  padding: 2px 5px 0;
  border-right-color: red;
  border-right-width: 2px;
  margin-right: 15px;
  width: 50px;
  align-items: center;
  border-right-color: ${({ theme }) => theme.colors.primary};
`;

const NameContainer = styled(FlexColumnDirection)`
  flex: 1;
`;

const DataContainer = styled(FlexColumnDirection)`
  flex: 1;
`;

const WageContainer = styled(BaseView)``;

const Name = styled(H4Regular)`
  font-size: 15px;
  font-weight: 400;
`;

const Branch = styled(H4Regular)`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 3px;
`;

const Fields = styled(BaseView)`
  margin-top: 10px;
`;
const Header = styled(FlexRowBetween)`
  align-items: flex-start;
`;

const Field = styled(FlexRowBetween)``;

const Day = styled(H4Regular)`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 1px;
`;
const DayName = styled(H4Regular)`
  font-size: 15px;
`;

const Total = styled(H4Regular)`
  font-size: 15px;
  margin-right: 10px;
`;
const Wage = styled(H3Regular)`
  font-size: 15px;
  margin-top: 5px;
  margin-right: 10px;
  font-size: 12px;
  text-align: right;
`;

const FieldTotal = styled(H3Regular)<{ bold?: boolean }>`
  font-size: 15px;
  margin-top: 5px;
  margin-right: 10px;
  font-size: 12px;
  text-align: right;
  ${({ bold }) => bold && 'font-weight:500'}
`;

const FieldSlug = styled(H3Regular)<{ bold?: boolean }>`
  font-size: 15px;
  margin-top: 5px;
  margin-right: 10px;
  font-size: 12px;
  text-align: right;
  ${({ bold }) => bold && 'font-weight:500'}
`;

interface Props {
  payroll: IPayroll;
  mappedBranches: Record<number, IBranch>;
  t: (string) => string;
}

export const IncomeRow: FC<Props> = ({ t, payroll, mappedBranches }) => {
  const { mappedAccounts } = useAccounts();
  const { push } = useNavigation();

  const branch = mappedBranches[payroll?.branchId];
  const account = mappedAccounts[branch?.accountId];
  const dayNumber = moment(payroll.date).format('DD');
  const dayString = moment(payroll.date).format('ddd');
  const startTime = moment(payroll?.startTime).format('LT');
  const endTime = moment(payroll?.endTime).format('LT');
  const totalTime = useMemo(
    () =>
      Object.values(payroll?.aggregatedHours || {}).reduce(
        (output, current) => output + current,
        0
      ),
    [payroll?.aggregatedHours]
  );
  const wage = payroll?.wage?.hourlyWage;
  const currency = account?.currency?.symbol;
  const totalWithoutPayrollFields = useMemo(
    () =>
      (payroll?.value || 0) -
      (payroll?.fields || []).reduce((acc, { total }) => acc + total, 0),
    [payroll?.fields, payroll?.value]
  );

  const onPress = () => {
    push(TimesheetsScreenNames.MAIN, {
      screen: TimesheetsScreenNames.TIMESHEET,
      params: {
        timesheetId: payroll.timesheetId,
      },
    });
  };

  return (
    <Container key={payroll.timesheetId} as={RipplePressable} onPress={onPress}>
      <DateContainer>
        <Day>{dayNumber}</Day>
        <DayName>{dayString}</DayName>
      </DateContainer>
      <RightContainer>
        <DataContainer>
          <Header>
            <NameContainer>
              <Branch>{branch?.name}</Branch>
              {payroll?.name && <Name>{payroll?.name}</Name>}
            </NameContainer>
            <WageContainer>
              <Total>
                {currency}
                {parseFloat(payroll?.value || 0).toFixed?.(2)}
              </Total>
              <Wage>
                {currency}
                {wage?.toFixed?.(2)}/{t('shortHour')}
              </Wage>
            </WageContainer>
          </Header>
          {(payroll?.fields || []).length > 0 &&
            totalWithoutPayrollFields !== payroll?.value && (
              <Fields>
                {totalWithoutPayrollFields > 0 && (
                  <Field>
                    <FieldSlug>
                      {startTime} - {endTime} ({totalTime?.toFixed?.(2)}
                      {t('shortHour')})
                    </FieldSlug>
                    <FieldTotal>
                      {currency}
                      {totalWithoutPayrollFields.toFixed?.(2)}
                    </FieldTotal>
                  </Field>
                )}
                {(payroll?.fields || [])
                  .filter(({ total }) => total > 0)
                  .map((field) => (
                    <Field>
                      <FieldSlug>{t(field.slug)}</FieldSlug>
                      <FieldTotal>
                        {currency}
                        {field.total?.toFixed?.(2)}
                      </FieldTotal>
                    </Field>
                  ))}
                <Field>
                  <FieldSlug bold>{t('total')}</FieldSlug>
                  <FieldTotal bold>
                    {currency}
                    {parseFloat(payroll?.value || 0).toFixed?.(2)}
                  </FieldTotal>
                </Field>
              </Fields>
            )}
        </DataContainer>
      </RightContainer>
    </Container>
  );
};
