import React, { FC } from 'react';
import { icons } from '@violetta/ubeya/assets';
import {
  Card,
  BaseImage,
  BaseView,
  FlexRowCenter,
  H6Medium,
  H5Regular,
  SvgIcon,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const DetailsCard = styled(Card)`
  border-radius: 4px;
  border-width: 0px;
  elevation: 3;
  shadow-color: rgba(0, 0, 0, 0.3);
  shadow-opacity: 1;
  shadow-offset: 0px 1px;
  shadow-radius: 1px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin-bottom: 20px;
`;

const IconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 20px;
`;

const ProfilePictureContainer = styled(BaseView)`
  width: 36px;
  height: 36px;
  border-radius: 23px;
  margin-right: 10px;
`;
const IconWrapper = styled(BaseView)`
  width: 36px;
  height: 36px;
  border-radius: 23px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray9};
`;

const BranchLogo = styled(BaseImage)`
  width: 36px;
  height: 36px;
  border-radius: 23px;
`;

const EventName = styled(H6Medium)`
  font-size: 16px;
  font-weight: 500;
`;

const BranchRow = styled(FlexRowCenter)`
  margin-bottom: 20px;
`;

const RowDetail = styled(FlexRowCenter)`
  padding: 5px 0;
`;

const CardDataDetails = styled(H5Regular)`
  margin-left: 10px;
  font-weight: normal;
  font-size: 14px;
`;

interface Props {
  image?: string;
  name?: string;
  day?: string;
  time?: string;
  position?: string;
}

export const ShiftInfoCard: FC<Props> = ({
  image,
  name,
  day,
  time,
  position,
}) => (
  <DetailsCard>
    <BranchRow>
      {!!image ? (
        <ProfilePictureContainer>
          <BranchLogo source={{ uri: image }} resizeMode="cover" />
        </ProfilePictureContainer>
      ) : (
        <IconWrapper>
          <SvgIcon xml={icons?.uiIcons?.profile} />
        </IconWrapper>
      )}
      <EventName>{name}</EventName>
    </BranchRow>
    <RowDetail>
      <IconContainer>
        <SvgIcon xml={icons?.symbolIcons?.calendar2} height={18} width={18} />
      </IconContainer>
      <CardDataDetails>{day}</CardDataDetails>
    </RowDetail>
    <RowDetail>
      <IconContainer>
        <SvgIcon xml={icons?.uiIcons?.clock2} height={18} width={18} />
      </IconContainer>
      <CardDataDetails>{time}</CardDataDetails>
    </RowDetail>
    <RowDetail>
      <IconContainer>
        <SvgIcon xml={icons?.uiIcons?.jobCase} height={18} width={18} />
      </IconContainer>
      <CardDataDetails>{position || '-'}</CardDataDetails>
    </RowDetail>
  </DetailsCard>
);
