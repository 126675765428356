import { atom } from 'recoil';

export type Prompt = {
	visible: boolean;
	onCancel?: {
		cb: () => void;
		title?: string;
	};

	onSave?: {
		cb: <T>(value?: T | undefined) => void;
		title?: string;
	};

	text?: string;
	title?: string;
};

export const promptsAtom = atom<Prompt[]>({
	key: 'prompts',
	default: [],
});
