import React, { FC } from 'react';
import styled from 'styled-components/native';

const Top = styled.SafeAreaView`
  width: 100%;
  height: 0;
  background-color: ${({ theme }) => theme.colors.surface};
`;

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  color: string;
};

export const HeaderlessBar: FC<Props> = () => <Top />;
