import React, { FC } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { Pressable, PressableProps } from 'react-native';
import styled from 'styled-components/native';

import { BaseView } from '../Layout/Layout';
import { SvgIcon } from './SvgIcon';

const PressedVIcon = styled(BaseView)``;

const NotPressedVIcon = styled(BaseView)``;

interface Props {
  onPress?: () => void;
  pressed?: boolean;
}

type VIconProps = Props & PressableProps;

export const VIcon: FC<VIconProps> = ({ onPress, pressed = false, ...props }) =>
  !pressed ? (
    <NotPressedVIcon
      as={onPress ? Pressable : undefined}
      onPress={onPress}
      {...props}
    >
      <SvgIcon xml={icons.symbolIcons.availableWhite} width={35} height={35} />
    </NotPressedVIcon>
  ) : (
    <PressedVIcon
      as={onPress ? Pressable : undefined}
      onPress={onPress}
      {...props}
    >
      <SvgIcon xml={icons.symbolIcons.available} width={35} height={35} />
    </PressedVIcon>
  );
