import React, { FC, useContext } from 'react';

import { StackHeaderProps } from '@react-navigation/stack';
import { DateTextComponent, FlexRowBetween } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { AppContext } from '../../../main/AppContext';

const Container = styled(FlexRowBetween)`
  flex: 1;
  justify-content: center;
`;

export const BookingHeaderTitle: FC<StackHeaderProps> = () => {
  const {
    bookings: { selectedBooking },
  } = useContext(AppContext);

  return (
    <Container>
      <DateTextComponent date={selectedBooking?.startTime} fontSize={18} />
    </Container>
  );
};
