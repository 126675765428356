import { StackHeaderProps } from '@react-navigation/stack';
import { icons } from '@violetta/ubeya/assets';
import { BaseView, RipplePressable, SvgIcon } from '@violetta/ubeya/ui';
import React, { FC } from 'react';
import styled from 'styled-components/native';
import { supportSectionOpen } from '../../../../state';

import { useRecoilState } from 'recoil';

const IconContainer = styled(BaseView)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChatHeaderRightComponent: FC<StackHeaderProps> = () => {
  const [showSupportModal, setShowSupportModal] =
    useRecoilState(supportSectionOpen);

  return (
    <IconContainer
      as={RipplePressable}
      onPress={() => setShowSupportModal(true)}
    >
      {!showSupportModal && (
        <SvgIcon xml={icons.cardIcons.helpCenter} width={22} height={22} />
      )}
    </IconContainer>
  );
};
