import React, { FC, useMemo } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, Toggler } from '@violetta/ubeya/ui';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import { complianceFilterAtom } from '../../atoms';
import { COMPLIANCE_OPTIONS } from '../../constants';

const TogglerContainer = styled(BaseView)`
  width: 250px;
`;

export const ComplianceHeaderTitle: FC<StackHeaderProps> = () => {
  const { t } = useTranslation();

  const [complianceViewOption, setComplianceViewOption] =
    useRecoilState(complianceFilterAtom);

  const complianceViewOptions = useMemo(
    () => [
      { id: COMPLIANCE_OPTIONS.MISSING, title: t('missingCompliance') },
      { id: COMPLIANCE_OPTIONS.UPLOADED, title: t('uploadedCompliance') },
    ],
    [t]
  );

  return (
    <TogglerContainer>
      <Toggler
        padding={6}
        items={complianceViewOptions}
        selectedItemId={complianceViewOption}
        onItemSelected={setComplianceViewOption}
      />
    </TogglerContainer>
  );
};
