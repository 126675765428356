import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { useCRUD } from '@violetta/ubeya/utils';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IBoardPayload } from '../entities';
import { IRequest } from '../entities/IRequest';

type AddParams = Pick<
  IRequest,
  'typeId' | 'branchId' | 'data' | 'employeeReason'
>;

type EditParams = Pick<IRequest, 'id'>;
type DeleteParams = Pick<IRequest, 'id'>;

type CreateReleaseFromShiftParams = {
  accountId: number;
  shiftId: number;
  employeeReason: string;
  employeeReasonOther: string;
};

type CancelReleaseFromShiftParams = {
  requestId: number;
};
const API_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const selector = createCachedSelector(
  (data) => data?.data,
  (data, mappedBranches) => {
    const requests = data;
    const generalRequests = data.filter(
      (request) => request?.typeId === 2 && !!request?.data
    );

    const appropriateRequests = generalRequests.filter((req) =>
      [0, 1, 2].includes(req.data.status)
    );
    const newestDate = moment
      .max(appropriateRequests.map((e) => moment(e.createdAt)))
      .format(API_DATE_TIME_FORMAT);
    const generalRequest =
      appropriateRequests.length > 0
        ? appropriateRequests.find((req) => req.createdAt === newestDate)
        : null;

    const boardData = generalRequest?.data?.board;

    const hasApprovedRequest =
      generalRequest !== null &&
      generalRequest !== undefined &&
      generalRequest !== {} &&
      generalRequest?.data?.status === 1;

    return {
      requests,
      generalRequests,
      generalRequest,
      boardData,
      hasApprovedRequest,
    };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useRequests = () => {
  const storeKey = useMemo(() => ['requests'], []);
  const queryClient = useQueryClient();

  const { data: userData } = useUser();

  const { isPending, data, refetch } = useQuery<{ data: IRequest[] }>({
    queryKey: storeKey,
    queryFn: () => api.getRequests(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { addItem: addRequest, isLoadingAddItem: isLoadingAddRequest } =
    useCRUD(
      storeKey,
      {
        addApi: (params: AddParams) => api.addRequest(params),
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        editApi: (params: EditParams) => {},
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        deleteApi: (params: DeleteParams) => {},
      },
      { throwOnError: true }
    );

  const {
    mutateAsync: createReleaseFromShift,
    isPending: isCreatingReleaseFromShift,
  } = useMutation({
    mutationFn: (params: CreateReleaseFromShiftParams) =>
      api.createReleaseFromShiftRequest(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: storeKey });
      await queryClient.invalidateQueries({ queryKey: ['bookings'] });
    },
  });

  const {
    mutateAsync: cancelReleaseFromShift,
    isPending: isCancellingReleaseFromShift,
  } = useMutation({
    mutationFn: (params: CancelReleaseFromShiftParams) =>
      api.cancelReleaseFromShiftRequest(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: storeKey });
      await queryClient.invalidateQueries({ queryKey: ['bookings'] });
    },
  });
  const {
    mutateAsync: addGeneralRequest,
    isPending: isAddGeneralRequestLoading,
  } = useMutation({
    mutationFn: ({
      typeId,
      accountId,
      board,
      employeeReason,
    }: {
      typeId: number;
      accountId: number;
      board: IBoardPayload[];
      employeeReason?: string;
    }) => api.addGeneralRequest({ typeId, accountId, board, employeeReason }),
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: storeKey, exact: true }), // NOTE: these are wasteful, invalidating all just for 1 item,
  });

  const {
    requests = [],
    generalRequests = [],
    boardData = [],
    generalRequest = {},
    hasApprovedRequest,
  } = useMemo(() => {
    const {
      requests: rawRequests = [],
      generalRequests: rawGeneralRequests = [],
      boardData: rawBoardData = [],
      generalRequest: rawGeneralRequest = {},
      hasApprovedRequest: rawHasApprovedRequest,
    } = data || {};
    return {
      requests: rawRequests,
      generalRequests: rawGeneralRequests,
      boardData: rawBoardData,
      generalRequest: rawGeneralRequest,
      hasApprovedRequest: rawHasApprovedRequest,
    };
  }, [data]);

  return {
    requests,
    generalRequests,
    generalRequest,
    boardData,
    isLoading: isPending,
    isLoadingAddRequest,
    addRequest,
    refetch,
    createReleaseFromShift,
    isCreatingReleaseFromShift,
    cancelReleaseFromShift,
    isCancellingReleaseFromShift,
    addGeneralRequest,
    isAddGeneralRequestLoading,
    hasApprovedRequest,
  };
};
