import React, { FC } from 'react';
import { Pressable, StyleProp, ViewStyle } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { BaseView, FlexRowCenter } from '../Layout';
import { H4Regular } from '../Typography';

const Container = styled(FlexRowCenter)<{ pressed: boolean }>``;

const Title = styled(H4Regular)`
  margin-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: #252525;
`;

interface Props {
  value: boolean;
  title?: string;
  onChange?: (value: boolean) => void;
  style?: StyleProp<ViewStyle>;
}

export const Radio: FC<Props> = ({ title, value, onChange, style }) => {
  const { colors } = useTheme();
  return (
    <Container as={Pressable} onPress={onChange} pressed={value}>
      <BaseView
        style={[
          {
            height: 22,
            width: 22,
            marginTop: 5,
            borderRadius: 12,
            borderWidth: 1,
            borderColor: '#d9d9d9',
            alignItems: 'center',
            justifyContent: 'center',
          },
          style,
        ]}
      >
        {value ? (
          <BaseView
            style={{
              height: 10,
              width: 10,
              borderRadius: 6,
              backgroundColor: colors.primary,
            }}
          />
        ) : null}
      </BaseView>
      {!!title && <Title>{title}</Title>}
    </Container>
  );
};
