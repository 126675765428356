import React, { useMemo, FC, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { lotties } from '@violetta/ubeya/assets';
import {
  Booking,
  useAccounts,
  useBookings,
  useBranches,
  useLabels,
  usePositions,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { SORT_BY_DATE_AND_TIME } from '@violetta/ubeya/shared';
import { EmptyState, FlatList } from '@violetta/ubeya/ui';
import { RefreshControl, SafeAreaView } from 'react-native';
import styled from 'styled-components/native';
import { UpcomingShift } from '../../home/components/UpcomingShiftsCard/UpcomingShift';
import { BookingsScreenNames } from '../navigation/ScreenNames';

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

interface Props {}

export const BookingsScreen: FC<Props> = () => {
  const { bookings, refetch, isLoading } = useBookings();
  const { mappedAccounts } = useAccounts();
  const { mappedPositions } = usePositions();
  const { mappedBranches } = useBranches();
  const { mappedLabels } = useLabels();
  const { navigate } = useNavigation();
  const { t } = useTranslation();

  const navigateBooking = useCallback(
    (booking: Booking) => {
      navigate(BookingsScreenNames.MAIN, {
        screen: BookingsScreenNames.BOOKING,
        params: { bookingId: booking?.id },
      });
    },
    [navigate]
  );

  const sortedBookings = useMemo(
    () => (bookings || []).sort(SORT_BY_DATE_AND_TIME('startTime')),
    [bookings]
  );

  return (
    <Container>
      <FlatList
        data={sortedBookings}
        keyExtractor={(item) => item.id.toString()}
        contentContainerStyle={{ flexGrow: 1, paddingBottom: 20 }}
        refreshControl={
          <RefreshControl onRefresh={refetch} refreshing={isLoading} />
        }
        ListEmptyComponent={
          <EmptyState
            hide={isLoading}
            lottieSrc={lotties.noUpcomingShifts}
            title={t('emptyStateBookings')}
          />
        }
        renderItem={({ item, index }) => (
          <UpcomingShift
            isFirst={index === 0}
            key={item.id}
            booking={item}
            mappedAccounts={mappedAccounts}
            mappedLabels={mappedLabels}
            mappedPositions={mappedPositions}
            mappedBranches={mappedBranches}
            onPress={navigateBooking}
          />
        )}
      />
    </Container>
  );
};
