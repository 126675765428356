import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { to } from '@violetta/ubeya/await';
import { flatten } from 'lodash';
import {
  CAPTAIN_TYPE_ENUM,
  ClockTimes,
  IQRShiftEmployee,
  useAccounts,
  useCaptain,
  useLocations,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseImage,
  BaseView,
  Button,
  ClockComponent,
  ClockTimePicker,
  CustomLoader,
  EmployeeTimeRow,
  FlexCenter,
  FlexColumn,
  H1Medium,
  H3Medium,
  Loader,
  SharedScannedEmployeeScreen,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import moment from 'moment';
import Toast from 'react-native-toast-message';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/native';
import { API_DATE_TIME_FORMAT, TIME_FORMAT } from '../../main/constants';
import { useRecoilState } from 'recoil';
import { unsyncedTimesheetsAtom } from '../../../state';
import { icons } from '@violetta/ubeya/assets';
import { ScrollView } from 'react-native';

const LoaderContainer = styled(FlexCenter)`
  align-items: center;
  margin-right: 10px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const ErrorContainer = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.surface};
  flex: 1;
  padding: 10px 30px;
  align-items: center;
`;

const ErrorTitle = styled(H3Medium)`
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
`;

const Container = styled(FlexColumn)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 30px;
`;

const HeaderText = styled(H1Medium)`
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 10px;
`;
const BoldText = styled(HeaderText)`
  font-weight: 500;
`;

const Body = styled(FlexColumn)`
  flex: 1;
`;

const Header = styled(FlexColumn)`
  align-items: center;
`;
const ImageContainer = styled(BaseView)`
  margin: 20px 0;
  position: relative;
`;

const UserImage = styled(BaseImage)`
  border-radius: 12px;
  background-color: antiquewhite;
  resize-mode: cover;
`;

type ParamList = {
  params: IQRShiftEmployee & { initialClockTime?: ClockTimes };
};

type EmployeeData = {
  firstName: string;
  lastName: string;
};

type EmployeeSlot = {
  startTime?: Date;
  endTime?: Date;
  checkInTime?: Date;
  checkOutTime?: Date;
};

type Shift = {
  id: number;
  locationId?: number;
  startTime?: Date;
  endTime?: Date;
};

export const ScannedEmployeeScreen: FC = React.memo(() => {
  const { params } = useRoute<RouteProp<ParamList, 'params'>>();
  const {
    photo,
    shiftId,
    employeeId,
    accountId,
    projectId,
    clientProjectId,
    initialClockTime,
  } = params || {};
  const { t } = useTranslation();
  const { goBack } = useNavigation();
  const queryClient = useQueryClient();
  const { accounts, mappedAccounts } = useAccounts();
  const [scanError, setScanError] = useState<
    'qrInvalidShift' | 'qrEmployeeNotFound' | null
  >(null);
  const [clockTime, setClockTime] = useState<ClockTimes>(initialClockTime);

  const unsyncedTimesheetsState = useRecoilState(unsyncedTimesheetsAtom);

  const {
    team,
    mappedEmployees,
    updateShiftEmployee,
    isLoading,
    isFetched,
    refetch,
    isUpdatingEmployee,
    isUpdatingOSEmployee,
  } = useCaptain({
    projectId: Number(projectId),
    unsyncedTimesheetsState,
  });

  const toRequireCheckInWithMobile = team?.config?.toRequireCheckInWithMobile;
  const captainType = team?.config?.captainType;

  const { mappedLocations } = useLocations();
  const orders = flatten(team?.orders || []);
  const allOrdersShifts = flatten(orders.map(({ shifts }) => shifts));

  const mappedOrdersEmployees = useMemo(
    () =>
      allOrdersShifts.reduce((output, current) => {
        if (!current) {
          return output;
        }
        (current?.employees || []).forEach((employee) => {
          output[employee.employeeId] = employee;
        });
        return output;
      }, {}),
    [allOrdersShifts]
  );

  const employeeData: EmployeeData = useMemo(
    () => mappedEmployees?.[employeeId] || mappedOrdersEmployees?.[employeeId],
    [employeeId, mappedEmployees, mappedOrdersEmployees]
  );

  const shift = (() => {
    const internalShift = (team?.shifts || [])?.find(
      (shift) => shift?.id === shiftId
    );
    if (internalShift) {
      return internalShift;
    }

    return (allOrdersShifts || [])?.find((shift) => shift?.id === shiftId);
  })() as Shift;

  const employeeSlot = shift?.employees?.find(
    (emp) => emp.employeeId === employeeId
  );

  useEffect(() => {
    if (!employeeData) {
      setScanError('qrEmployeeNotFound');
    }
  }, [employeeData, mappedEmployees, shift]);

  const location = mappedLocations?.[shift?.locationId];
  const employeeName = useMemo(
    () => `${employeeData?.firstName || ''} ${employeeData?.lastName || ''}`,
    [employeeData?.firstName, employeeData?.lastName]
  );

  useEffect(() => {
    refetch();
  }, [projectId, queryClient, refetch]);

  const handleUpdateTimesheet = useCallback(async () => {
    const startEndTimes = {
      [clockTime]: moment().format(API_DATE_TIME_FORMAT),
    };

    updateShiftEmployee({
      projectId: Number(shift?.projectId || employeeSlot.projectId),
      shiftId: shift?.id,
      employeeId: Number(employeeId),
      values: startEndTimes,
    })
      .then(() => {
        refetch();
      })
      .catch(() => {
        Toast.show({
          type: 'error',
          position: 'bottom',
          text1: t('qrCodeTimeclockError', { name: employeeName }),
          bottomOffset: 83,
        });
      });
    Toast.show({
      type: 'success',
      position: 'bottom',
      text1: t('qrCodeTimeclockSuccess', { name: employeeName }),
      bottomOffset: 83,
    });
    goBack();
  }, [
    clockTime,
    employeeId,
    employeeName,
    goBack,
    refetch,
    shift?.id,
    shift?.projectId,
    t,
    updateShiftEmployee,
  ]);

  const isLoadingUpdate = isUpdatingOSEmployee || isUpdatingEmployee;

  const time = useMemo(
    () => rangeFormat(shift?.startTime, shift?.endTime),
    [shift?.endTime, shift?.startTime]
  );

  if (isLoading || !isFetched) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  if (scanError) {
    return (
      <ErrorContainer>
        <SvgIcon
          xml={icons.complianceIcons.complianceExpiredWarning}
          width={150}
          height={150}
        />
        <ErrorTitle>{t(scanError)}</ErrorTitle>

        <Button
          type="primary"
          onPress={goBack}
          title={t('goBack')}
          paddingHorizontal={80}
        />
      </ErrorContainer>
    );
  }

  if (scanError) {
    return (
      <ErrorContainer>
        <SvgIcon
          xml={icons.complianceIcons.complianceExpiredWarning}
          width={150}
          height={150}
        />
        <ErrorTitle>{t(scanError)}</ErrorTitle>

        <Button
          type="primary"
          onPress={goBack}
          title={t('goBack')}
          paddingHorizontal={80}
        />
      </ErrorContainer>
    );
  }
  if (!employeeData && !isLoadingUpdate) {
    return (
      <>
        <HeaderText>
          <BoldText>Unexpected error, please try again</BoldText>
        </HeaderText>
        <Button
          type="primary"
          onPress={goBack}
          title={t('goBack')}
          paddingHorizontal={80}
        />
      </>
    );
  }

  const slug =
    clockTime === 'startTime'
      ? 'clockIn'
      : clockTime === 'checkInTime'
      ? 'timesheetCheckIn'
      : clockTime === 'checkOutTime'
      ? 'timesheetCheckOut'
      : clockTime === 'endTime'
      ? 'clockOut'
      : '';

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ backgroundColor: 'white' }}
    >
      <Container>
        <Header>
          <ClockComponent mode="dark" timeFormat={TIME_FORMAT} />
          <ImageContainer>
            {photo && (
              <UserImage width={150} height={150} source={{ uri: photo }} />
            )}
          </ImageContainer>
          <HeaderText>
            <BoldText>{employeeName}</BoldText>
          </HeaderText>
          <HeaderText>{location?.name || ''}</HeaderText>
          <HeaderText>{time}</HeaderText>
        </Header>
        <Body>
          <ClockTimePicker
            showCheckIn={toRequireCheckInWithMobile}
            showClockIn={captainType !== CAPTAIN_TYPE_ENUM.AREA}
            showClockOut={captainType !== CAPTAIN_TYPE_ENUM.AREA}
            currentName={clockTime}
            setCurrentName={setClockTime}
            containerStyle={{ marginBottom: 20 }}
          />
          <Button
            type="primary"
            onPress={handleUpdateTimesheet}
            title={t(slug)}
            isLoading={isLoadingUpdate}
            disabled={isLoadingUpdate}
            paddingHorizontal={80}
          />
        </Body>
        {/* {startTime && (
        <FooterContainer>
          <ScrollView
            style={{ flex: 1 }}
            contentContainerStyle={{ alignItems: 'center' }}
          >
            {(times || []).map((times) => (
              <EmployeeTimeRow
                type={times.name}
                time={times.time}
                key={times.name}
              />
            ))}
          </ScrollView>
        </FooterContainer>
      )} */}
      </Container>
    </ScrollView>
  );
});
