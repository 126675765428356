import { createContext, Dispatch, SetStateAction } from 'react';
import { Booking, IAccount, IChatRoom, IOffer } from '@violetta/ubeya/entities';
import { CustomTimesheetDTO } from 'entities/src/hooks/useTimesheetsHistory';
import moment from 'moment';

export interface IAppContext {
  availability: {
    periodId: number;
    setPeriod: Dispatch<SetStateAction<number>>;
    selectedDay: Date;
    selectDay: (date: Date) => void;
    onPrev: () => void;
    onNext: () => void;
  };
  generalAvailability: {
    selectedDay: string | null;
    selectDay: (day: string | null) => void;
  };
  profile: {
    selectedAccount: IAccount | null;
    selectAccount: (account: IAccount | null) => void;
  };
  bookings: {
    selectedBooking: Booking | null;
    selectBooking: (booking: Booking | null) => void;
  };
  timesheets: {
    selectedTimesheet: CustomTimesheetDTO | null;
    selectTimesheet: (timesheet: CustomTimesheetDTO | null) => void;
  };
  offers: {
    selectedOffer: IOffer | null;
    selectOffer: (offer: IOffer | null) => void;
  };
}

export const defaultContext = {
  availability: {
    periodId: 0,
    setPeriod: () => {},
    selectedDay: moment().toDate(),
    selectDay: () => {},
    onNext: () => {},
    onPrev: () => {},
  },
  profile: {
    selectedAccount: null,
    selectAccount: () => {},
  },
  bookings: {
    selectedBooking: null,
    selectBooking: () => {},
  },
  timesheets: {
    selectedTimesheet: null,
    selectTimesheet: () => {},
  },
  offers: {
    selectedOffer: null,
    selectOffer: () => {},
  },
  generalAvailability: {
    selectedDay: null,
    selectDay: () => {},
  },
} as IAppContext;

export const AppContext = createContext<IAppContext>(defaultContext);
