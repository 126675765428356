import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { IChatRoom } from '@violetta/ubeya/entities';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import _ from 'lodash';

const useBoard = ({ id, type }: { type: 'admin' | 'user'; id: number }) => {
  const myId = useMemo(() => `${id}-${type}`, [id, type]);
  const queryClient = useQueryClient();
  const storeKey = ['rooms'];

  const {
    isPending,
    data,
    refetch: refetchAllRooms,
  } = useQuery<{ rooms: IChatRoom[]; users: any }>({
    queryKey: storeKey,
    queryFn: () => api.getAllRooms(),
  });

  const { mutateAsync: sendMessage } = useMutation({
    mutationFn: ({ ...props }) => api.sendMessage({ ...props, type }),
  });

  const { mutateAsync: readMoreMessages, isPending: isLoadingMoreMessages } =
    useMutation({
      mutationFn: ({
        roomId,
        firstMessageId,
      }: {
        roomId: string;
        firstMessageId: string;
      }) => api.readRoomMessages({ roomId, firstMessageId, type }),
      onSuccess: (data, { roomId }) => {
        const previousData = queryClient.getQueryData(storeKey) || [];
        const newData = (previousData?.rooms || []).map((room) =>
          room.roomId === roomId
            ? {
                ...room,
              }
            : room
        );

        queryClient.setQueryData(storeKey, {
          ...previousData,
          rooms: newData,
        });
      },
    });

  const formattedData = useMemo(
    () =>
      (data?.rooms || []).sort(
        (
          { lastMessagePayload: aLastMessagePayload },
          { lastMessagePayload: bLastMessagePayload }
        ) => {
          if (!aLastMessagePayload?.message?.timestamp) {
            return 1;
          }

          if (!bLastMessagePayload?.message?.timestamp) {
            return -1;
          }

          return (
            new Date(bLastMessagePayload?.message?.timestamp).getTime() -
            new Date(aLastMessagePayload?.message?.timestamp).getTime()
          );
        }
      ),
    [data?.rooms]
  );
  return {
    isLoading: isPending,
    data: formattedData,
    sendMessage,
    storeKey,
    readMoreMessages,
    isLoadingMoreMessages,
    myId,
    refetchAllRooms,
  };
};

export default useBoard;
