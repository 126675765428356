import React, { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import styled from 'styled-components/native';

const Container = styled.View`
  margin-top: 6px;
  margin-right: 5px;
  width: 8px;
  height: 8px;
`;

const Circle = styled(Animated.View)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const LiveViewFadingCircle = () => {
  const fadeInAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    startFadeInAnimation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startFadeInAnimation = () => {
    Animated.timing(fadeInAnimation, {
      toValue: 1,
      duration: 500, // Adjust the duration as per your preference
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      startFadeOutAnimation();
    });
  };

  const startFadeOutAnimation = () => {
    Animated.timing(fadeInAnimation, {
      toValue: 0,
      duration: 1000, // Adjust the duration as per your preference
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      startFadeInAnimation();
    });
  };

  return (
    <Container>
      <Circle
        style={{
          width: 8,
          height: 8,
          opacity: fadeInAnimation,
        }}
      />
    </Container>
  );
};

export default LiveViewFadingCircle;
