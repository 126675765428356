import React, { FC, useCallback, useMemo, useState } from 'react';
import { api } from '@violetta/ubeya/api';
import { lotties } from '@violetta/ubeya/assets';
import { IAccount } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, EmptyState, FlatList } from '@violetta/ubeya/ui';
import { SafeAreaView } from 'react-native';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/native';
import { AccountRoomRow } from '../components/AccountRoomRow';
import { RoomRow } from '../components/RoomRow';

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const Separator = styled(BaseView)`
  padding-top: 15px;
`;

interface FooterProps {
  accounts: IAccount[];
  onPressed?: (roomId: string) => void;
}

const Footer: FC<FooterProps> = ({ accounts, onPressed }) => {
  const queryClient = useQueryClient();

  const [isLoadingAccount, setIsLoadingAccount] = useState<number>(0);
  const onSelected = useCallback(
    async (accountId: number) => {
      setIsLoadingAccount(accountId);
      const { roomId } = await api.createRoom({ accountId });
      await queryClient.invalidateQueries({ queryKey: ['rooms'] });
      setIsLoadingAccount(0);
      onPressed?.(roomId);
    },
    [onPressed, queryClient]
  );

  return (
    <>
      {accounts.map((account) => (
        <AccountRoomRow
          key={account.id}
          account={account}
          onSelected={onSelected}
          isLoading={isLoadingAccount === account.id}
        />
      ))}
    </>
  );
};

interface Props {
  rooms: any;
  isLoading?: boolean;
  context: 'user' | 'admin';
  accountNonExistedRooms?: any[];
  onRoomClick?: (roomId: string) => void;
  refetch?: () => void;
}

export const RoomsList: FC<Props> = ({
  onRoomClick,
  refetch,
  rooms,
  isLoading = false,
  accountNonExistedRooms = [],
}) => {
  const { t } = useTranslation();

  const sortedRooms = rooms;
  return (
    <Container>
      <FlatList
        ListFooterComponent={
          <Footer accounts={accountNonExistedRooms} onPressed={onRoomClick} />
        }
        contentContainerStyle={{
          flexGrow: 1,
          backgroundColor: '#ffffff',
          paddingTop: 10,
          paddingBottom: 10,
        }}
        ListEmptyComponent={
          <EmptyState
            hide={isLoading || accountNonExistedRooms?.length > 0}
            lottieSrc={lotties.noChatData}
            width={300}
            title={t('thereIsNoChatToDisplay')}
          />
        }
        data={sortedRooms}
        refreshing={isLoading}
        onRefresh={refetch}
        keyExtractor={({ id }) => id}
        renderItem={({ item }) => (
          <RoomRow key={item.id} room={item} onSelected={onRoomClick} />
        )}
        ItemSeparatorComponent={() => <Separator />}
      />
    </Container>
  );
};
