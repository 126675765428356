import { useCallback } from 'react';
import NetInfo from '@react-native-community/netinfo';
import { useQueryClient } from '@tanstack/react-query';

export const useRefresh = () => {
  const queryClient = useQueryClient();
  const { isConnected } = NetInfo.useNetInfo();

  const refresh = useCallback(() => {
    if (!isConnected) {
      return;
    }
    return queryClient.invalidateQueries({
      predicate: ({ queryKey }) =>
        queryKey?.[0] !== 'user' && queryKey?.[0] !== 'localAvailabilities',
    });
  }, [isConnected, queryClient]);

  const refreshUser = useCallback(() => {
    if (!isConnected) {
      return;
    }
    return queryClient.invalidateQueries({
      predicate: ({ queryKey }) => queryKey?.[0] === 'user',
    });
  }, [isConnected, queryClient]);

  const refreshAvailabilities = useCallback(
    () => queryClient.invalidateQueries({ queryKey: ['availabilities'] }),
    [queryClient]
  );

  const refreshPeriods = useCallback(
    () => queryClient.invalidateQueries({ queryKey: ['periods'] }),
    [queryClient]
  );

  const refreshAll = useCallback(() => {
    if (!isConnected) {
      return;
    }
    return queryClient.invalidateQueries({
      predicate: ({ queryKey }) =>
        // queryKey?.[0] === 'user' ||
        // queryKey?.[0] === 'accounts' ||
        // queryKey?.[0] === 'periods' ||
        // queryKey?.[0] === 'labels' ||
        // queryKey?.[0] === 'modules' ||
        // queryKey?.[0] === 'positions' ||
        queryKey?.[0] === 'branches' ||
        queryKey?.[0] === 'locations' ||
        queryKey?.[0] === 'uniforms' ||
        queryKey?.[0] === 'requestsTypes' ||
        queryKey?.[0] === 'timesheets' ||
        queryKey?.[0] === 'bookings' ||
        queryKey?.[0] === 'offers' ||
        queryKey?.[0] === 'sequences' ||
        queryKey?.[0] === 'mandatories',
      // console.log({ queryKey });
      // return queryKey?.[0] === 'offers' || queryKey?.[0] === 'bookings' || queryKey?.[0] === 'timesheets';
    });
  }, [isConnected, queryClient]);

  return {
    refresh,
    refreshAvailabilities,
    refreshPeriods,
    refreshAll,
    refreshUser,
  };
};
