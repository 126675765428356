import Axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import { serialize } from 'v8';

export type MultitonType = 'admin' | 'user' | 'tablet';

export class HttpMultiton {
  private static instances: Map<MultitonType, HttpMultiton> = new Map<
    MultitonType,
    HttpMultiton
  >();

  private axios: AxiosInstance;

  private static globalRequestInterceptors: Parameters<
    AxiosInstance['interceptors']['request']['use']
  >[] = [];

  private static globalResponseInterceptors: Parameters<
    AxiosInstance['interceptors']['response']['use']
  >[] = [];

  private constructor(private type: MultitonType) {
    this.axios = Axios.create({
      paramsSerializer: { serialize: qs.stringify },
    });

    HttpMultiton.globalRequestInterceptors.forEach((interceptor) => {
      this.axios.interceptors.request.use(...interceptor);
    });

    HttpMultiton.globalResponseInterceptors.forEach((interceptor) => {
      this.axios.interceptors.response.use(...interceptor);
    });
  }

  public static getInstance(type: MultitonType) {
    let instance = HttpMultiton.instances.get(type);
    if (!instance) {
      instance = new HttpMultiton(type);
      HttpMultiton.instances.set(type, instance);
    }

    return instance;
  }

  addInterceptor<T extends 'request' | 'response'>(
    type: T,
    ...interceptor: Parameters<AxiosInstance['interceptors'][T]['use']>
  ) {
    const [onFul, onRej] = interceptor as Parameters<
      AxiosInstance['interceptors'][T]['use']
    >;
    this.axios.interceptors[type].use(onFul as any, onRej as any);
  }

  public static addGlobalInterceptor<T extends 'request' | 'response'>(
    type: T,
    ...interceptor: Parameters<AxiosInstance['interceptors'][T]['use']>
  ) {
    const iType =
      type === 'request'
        ? 'globalRequestInterceptors'
        : 'globalResponseInterceptors';

    HttpMultiton[iType].push(interceptor as any);

    [...HttpMultiton.instances.values()].forEach((instance) =>
      instance.addInterceptor(type, ...interceptor)
    );
  }

  get Axios() {
    return this.axios;
  }

  get<T = any>(url: string, headers?: any, params: object = {}) {
    return this.axios.request<T>({
      url,
      method: 'GET',
      params,
      paramsSerializer: {
        serialize: (params) => {
          const { branches, projectIds, ...rest } = params || {};

          let base = qs.stringify(rest, {
            arrayFormat: 'indices',
            indices: true,
          });

          if (
            branches &&
            Array.isArray(branches) &&
            branches.every((element) => typeof element === 'number')
          ) {
            base = `${base}${base.length === 0 ? '' : '&'}branches=[${(
              branches || []
            ).join(',')}]`;
          }

          if (
            (branches &&
              Array.isArray(branches) &&
              !branches.every((element) => typeof element === 'number')) ||
            (branches && !Array.isArray(branches))
          ) {
            base = `${base}${base.length === 0 ? '' : '&'}${qs
              .stringify(
                { branches },
                { arrayFormat: 'indices', indices: true }
              )
              .slice(1)}`;
          }

          if (
            projectIds &&
            Array.isArray(projectIds) &&
            projectIds.every((element) => typeof element === 'number')
          ) {
            base = `${base}${base.length === 0 ? '' : '&'}projectIds=[${(
              projectIds || []
            ).join(',')}]`;
          }

          if (
            (projectIds &&
              Array.isArray(projectIds) &&
              !projectIds.every((element) => typeof element === 'number')) ||
            (projectIds && !Array.isArray(projectIds))
          ) {
            base = `${base}${base.length === 0 ? '' : '&'}${qs
              .stringify(
                { projectIds },
                { arrayFormat: 'indices', indices: true }
              )
              .slice(1)}}`;
          }

          return base;
        },
      },
      headers,
    });
  }

  post<T = any>(url: string, params: object = {}, data: object | string = {}) {
    return this.axios.request<T>({
      url,
      method: 'POST',
      params,
      data,
    });
  }

  put<T = any>(url: string, params: object = {}, data: object | string = {}) {
    return this.axios.request<T>({
      url,
      method: 'PUT',
      params,
      data,
    });
  }

  patch<T = any>(url: string, params: object = {}, data: object | string = {}) {
    return this.axios.request<T>({
      url,
      method: 'PATCH',
      params,
      data,
    });
  }

  httpDelete<T = any>(
    url: string,
    params: object = {},
    data: object | string = {}
  ) {
    return this.axios.request<T>({
      url,
      method: 'DELETE',
      params,
      data,
    });
  }
}
