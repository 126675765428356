import React, { FC, useCallback } from 'react';
import { IRequestTypeKind } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseSelect,
  FlexRow,
  H5Medium,
  H6Medium,
  SelectProps,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

type Props = Omit<
  SelectProps<IRequestTypeKind, IRequestTypeKind['id']>,
  'optionToSelectedOption'
>;

const Container = styled(FlexRow)``;

const Title = styled(H6Medium)`
  color: ${({ theme }) => theme.colors.surface};
  font-weight: 500;
`;

const ListTitle = styled(H5Medium)`
  color: ${({ theme }) => theme.colors.gray1};
  font-weight: 500;
`;

const SelectedOptionChip: FC<IRequestTypeKind> = ({ slug }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t(slug)}</Title>
    </Container>
  );
};
const ListOption: FC<IRequestTypeKind> = ({ slug }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ListTitle>{t(slug)}</ListTitle>
    </Container>
  );
};

export const KindsSelect: FC<Props> = ({ ...props }) => {
  const optionToSelectedOption = useCallback(
    ({ id }: IRequestTypeKind) => id as IRequestTypeKind['id'],
    []
  );

  return (
    <BaseSelect
      {...props}
      optionToSelectedOption={optionToSelectedOption}
      renderSelectedOptionChip={SelectedOptionChip}
      renderOption={ListOption}
    />
  );
};
