import React, { FC, useCallback, useMemo, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { to } from '@violetta/ubeya/await';
import { useRequests, useRequestsTypes } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  Button,
  FlexColumnDirection,
  H3Medium,
  SvgIcon,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import { API_DATE_FORMAT } from '../../main/constants';
import { AddRequestForm, FormValues } from '../components/AddRequestForm';

const SuccessContainer = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  align-items: center;
  justify-content: center;
  padding-top: 150px;
  padding-bottom: 30px;
`;

const SuccessMessage = styled(H3Medium)`
  margin: 30px 0;
`;

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

interface Props {}

type ParamList = {
  params: {
    date: Date;
    branchId?: number;
    onSucess?: () => void;
  };
};

export const AddRequestScreen: FC<Props> = () => {
  const { goBack } = useNavigation();
  const { t } = useTranslation();
  const { params } = useRoute<RouteProp<ParamList, 'params'>>();

  const dateProp = params?.date;
  const branchId = params?.branchId;
  const onSucess = params?.onSucess;

  const [successMessage, setSuccessMessage] = useState<boolean>(false);
  const { addRequest: add, isLoadingAddRequest: isLoading } = useRequests();
  const { mappedRequestsTypesByType } = useRequestsTypes();
  const typeId = useMemo(
    () => mappedRequestsTypesByType?.absence?.id || 1,
    [mappedRequestsTypesByType]
  );

  const onSubmit = useCallback(
    async (values: FormValues) => {
      const {
        branchId: [branchId],
        employeeReason,
        date,
        jobTypeId: [jobTypeId],
      } = values;

      const startTime = (date?.value as any)?.start;
      const endTime = (date?.value as any)?.end;
      const [err] = await to(
        add({
          typeId,
          branchId,
          employeeReason,
          data: {
            jobTypeId,
            startTime: startTime
              ? moment(startTime).format(API_DATE_FORMAT)
              : null,
            endTime: endTime ? moment(endTime).format(API_DATE_FORMAT) : null,
          },
        })
      );

      if (!err) {
        onSucess?.();
        setSuccessMessage(true);
      }
    },
    [add, onSucess, typeId]
  );

  return (
    <KeyboardAwareScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      extraScrollHeight={200}
    >
      <Container>
        {!successMessage && (
          <AddRequestForm
            onSubmit={onSubmit}
            isLoading={isLoading}
            dateProp={dateProp}
            branchId={branchId}
          />
        )}

        {successMessage && (
          <SuccessContainer>
            <SvgIcon
              xml={icons.symbolIcons.planeSuccess}
              width={250}
              height={250}
            />
            <SuccessMessage>{t('requestSuccessMessage')}</SuccessMessage>
            <Button
              title={t('backToRequests')}
              onPress={goBack}
              type="primary"
            />
          </SuccessContainer>
        )}
      </Container>
    </KeyboardAwareScrollView>
  );
};
