import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { to } from '@violetta/ubeya/await';
import { FieldValues, useEditEmployeeAccount } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Button,
  CustomLoaderWrapped,
  UbeyaForm,
} from '@violetta/ubeya/ui';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RefreshControl, ScrollView } from 'react-native';
import Toast from 'react-native-toast-message';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts';

const Container = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  justify-content: space-between;
  padding: 0 20px 33px 20px;
  flex: 1;
`;

const FormHeader = styled(BaseText)`
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 20px;
`;

type ParamList = {
  params: {
    accountId: number;
  };
};

export const ChangeAccountDetailsScreen = () => {
  const {
    params: { accountId },
  } = useRoute<RouteProp<ParamList, 'params'>>();
  const [isScrollEnabled, setIsScrollEnabled] = useState(true);
  const { t } = useTranslation();
  const { goBack } = useNavigation();
  const {
    fields,
    isLoading,
    editEmployeeDetails,
    isEditingEmployeeDetails,
    refetch,
    isError,
  } = useEditEmployeeAccount(accountId);
  const [fieldValues, setFieldValues] = useState<{
    values: FieldValues;
    dirty: boolean;
    valid: boolean;
    initialValues: FieldValues;
  }>({});
  const { rtl } = useContext(ConfigContext);

  useEffect(() => {
    if (isError) {
      Toast.show({
        type: 'error',
        text1: t('unknown'),
      });
    }
  }, [isError, t]);

  const form = useMemo(
    () => ({
      id: 1,
      fields: fields.map((field) => ({ ...field, name: t(field.name) })),
    }),
    [fields, t]
  );

  const showToast = useCallback(({ text1, text2, type, onHide }) => {
    Toast.show({
      type,
      text1,
      text2,
      onHide,
    });
  }, []);

  const onChange = useCallback(
    ({
      dirty,
      initialValues,
      dirtyFields,
      valid,
    }: {
      values: FieldValues;
      dirty: boolean;
      valid: boolean;
      initialValues: FieldValues;
      dirtyFields: FieldValues;
    }): void => {
      setFieldValues({ dirty, initialValues, values: dirtyFields, valid });
    },

    []
  );

  const onSubmit = useCallback(
    async (values, form) => {
      const formattedValues = Object.entries(fieldValues?.values)
        .map((value) => {
          const formFieldId = value?.[0]?.split('-')?.[1];
          const formFieldValue = value?.[1];
          return { id: formFieldId, value: formFieldValue };
        })
        .filter((value) => !!value?.value);

      const [error, resp] = await to(editEmployeeDetails(formattedValues));

      if (resp?.data) {
        goBack();
        showToast({
          text1: t('changedProfileDataSuccessfully'),
          type: 'success',
        });
      } else if (error) {
        showToast({ text1: t('unknown'), type: 'error' });
      }
    },
    [editEmployeeDetails, fieldValues, goBack, showToast, t]
  );

  if (fields?.length === 0 && isLoading) {
    return <CustomLoaderWrapped />;
  }

  return (
    <Container>
      <ScrollView
        style={{ flex: 1 }}
        scrollEnabled={isScrollEnabled}
        contentContainerStyle={{ paddingBottom: 70 }}
        refreshControl={
          <RefreshControl onRefresh={refetch} refreshing={isLoading} />
        }
      >
        <FormHeader>
          {fields?.length === 0 ? t('noFields') : t('employeeDetails')}
        </FormHeader>
        {fields?.length > 0 && (
          <UbeyaForm
            setIsScrollEnabled={(value) => setIsScrollEnabled(value)}
            form={form!}
            onSubmit={onSubmit}
            onChange={onChange}
            rtl={rtl}
          />
        )}
      </ScrollView>
      {fields?.length > 0 && (
        <Button
          title={t('saveChanges')}
          type="primary"
          style={{ marginTop: 30 }}
          onPress={onSubmit}
          isLoading={isEditingEmployeeDetails}
          disabled={
            isEditingEmployeeDetails ||
            !fieldValues?.dirty ||
            !fieldValues?.valid
          }
        />
      )}
    </Container>
  );
};
