import { atom } from 'recoil';

export const selectedAccount = atom({ key: 'selectedAccount', default: null });
export const selectedBranch = atom<number[] | null>({
  key: 'selectedBranch',
  default: [],
});

export const selectedUserChatRoom = atom({
  key: 'selectedUserChatRoom',
  default: null,
});
export const selectedAdminChatRoom = atom({
  key: 'selectedAdminChatRoom',
  default: null,
});

type FiltersType = {
  positionIds?: number[];
  locationIds?: number[];
  startTime?: string[];
};

export const accountLiveViewFilters = atom({
  key: 'accountLiveViewFilters',
  default: {} as FiltersType,
});

export const projectLiveViewFilters = atom({
  key: 'projectLiveViewFilters',
  default: {} as FiltersType,
});
