import React, { FC } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { RipplePressable } from '..';
import { BaseView, FlexRow } from '../Layout/Layout';
import { H6Regular } from '../Typography';

const Container = styled(FlexRow)`
  border-radius: 25px;
  border-color: ${({ theme }) => theme.colors.primary};
  border-width: 2px;
  align-items: stretch;
  justify-content: space-around;
`;

const Item = styled(BaseView)<{
  selected: boolean;
  isFirst: boolean;
  isLast: boolean;
  padding?: number;
}>`
  padding: ${({ padding }) => (padding ? `${padding}px` : '12px')} 0;
  border-right-width: ${({ isLast }) => (isLast ? '0' : '2px')};
  border-color: ${({ theme }) => theme.colors.primary};
  background-color: ${({ theme, selected }) => (selected ? theme.colors.primary : 'transparent')};
  align-items: center;
  justify-content: center;
  ${({ isFirst }) => (isFirst ? 'border-top-left-radius: 25px; border-bottom-left-radius: 25px' : '')}
  ${({ isLast }) => (isLast ? 'border-top-right-radius: 25px; border-bottom-right-radius: 25px' : '')}
	flex: 1;
  ${({ isFirst, isLast }) =>
    isFirst ? 'margin-left: -1px; margin-bottom: -1px;' : isLast ? 'margin-right: -1px; margin-bottom: -1px;' : ''}
`;
const Title = styled(H6Regular)<{ selected: boolean }>`
  text-align: center;
  color: ${({ theme, selected }) => (selected ? theme.colors.white : theme.colors.primary)};
  font-weight: normal;
`;

interface TogglerItem {
  id: number;
  title: string;
}
interface Props {
  items: TogglerItem[];
  selectedItemId: number;
  onItemSelected?: (value: number) => void;
  padding?: number;
}

export const Toggler: FC<Props> = ({ items, selectedItemId, padding, onItemSelected = () => {} }) => (
  <Container>
    {items.map((item, index) => (
      <Item
        key={index}
        as={RipplePressable}
        onPress={() => selectedItemId !== item.id && onItemSelected(item.id)}
        selected={item.id === selectedItemId}
        isFirst={index === 0}
        padding={padding}
        // eslint-disable-next-line react/jsx-closing-bracket-location
        isLast={index === items.length - 1}>
        <Title selected={item.id === selectedItemId}>{item.title}</Title>
      </Item>
    ))}
  </Container>
);

export default Toggler;
