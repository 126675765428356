export enum BookingsScreenNames {
  MAIN = 'bookings',
  BOOKINGS = 'bookings_list',
  BOOKING = 'booking',
  PROJECT_FEED = 'project_feed',
  TEAM = 'team',
  CAPTAIN = 'captain',
  SCHEDULE = 'schedule',
  PASS_CARD = 'pass_card',
}
