import React, { FC } from 'react';
import {
  BaseView,
  Card,
  FlexRowBetween,
  FlexRowCenter,
  H3Bold,
  H4Bold,
} from '@violetta/ubeya/ui';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';

const Container = styled(BaseView)`
  margin-top: 10px;
`;
const CardBody = styled(BaseView)``;
const Header = styled(FlexRowBetween)`
  padding: 15px 0 5px;
`;
const Title = styled(H3Bold)`
  flex: 1;
  display: flex;
  flex-direction: row;
`;

const Footer = styled(FlexRowCenter)`
  padding: 5px 0;
  justify-content: center;
`;

const MoreButton = styled(Card)`
  padding: 10px 0;
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-radius: 8px;
`;

const ButtonText = styled(H4Bold)`
  color: ${({ theme }) => theme.colors.primaryLight};
`;

interface Props {
  title: string;
  body: FC<any>;
  onMoreClick?: () => void;
  rtl?: boolean;
  detailedMoreTitle?: string;
}

export const HomeCard: FC<Props> = ({
  title,
  detailedMoreTitle,
  body: Body,
  onMoreClick,
}) => (
  <Container>
    <Header>
      <Title>{title}</Title>
    </Header>

    <CardBody>
      <Body />
    </CardBody>
    <Footer>
      {onMoreClick && !!detailedMoreTitle && (
        <MoreButton as={Pressable} onPress={onMoreClick}>
          <ButtonText>{detailedMoreTitle}</ButtonText>
        </MoreButton>
      )}
    </Footer>
  </Container>
);
