import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import { images } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import {
  useAccounts,
  useBookings,
  useBranches,
  useLabels,
  useLocations,
  useModules,
  useNotifications,
  useOffers,
  usePositions,
  useSequencePrompter,
  useTimesheets,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseScreen, FlexColumnDirection } from '@violetta/ubeya/ui';
import { useRefresh } from '@violetta/ubeya/utils';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { InteractionManager, Platform, StatusBar } from 'react-native';
import styled from 'styled-components/native';
import { colors } from '../../../style';
import {
  DEFAULT_VIEW_ASYNC_STORE_KEY,
  DEFAULT_VIEW_STAFF_ASYNC_STORE_VALUE,
} from '../../main/constants';
import { OnboardingScreenNames } from '../../onboarding/navigation/ScreenNames';
import { SequencesScreenNames } from '../../sequences/navigation/ScreenNames';
import {
  EmployersCard,
  IncomeCard,
  ThingsToDoCard,
  TimeclocksCard,
} from '../components';
import { BranchRequestCard } from '../components/BranchRequestCard';
import { EmptyStateCard } from '../components/EmptyStateCard/EmptyStateCard';
import { NotificationsCard } from '../components/NotificationsCard/NotificationsCard';
import { SchedulingCards } from '../components/SchedulingCards';
import { TimeAvailabilityCard } from '../components/TimeAvailabilityCard';
import { FeedCard } from '../components/FeedCard/FeedCard';
import { BirthdayCard } from '../components/BirthdayCard';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

export const HomeScreen: FC = React.memo(() => {
  const initialLoad = useRef<boolean>(true);
  const isFocused = useIsFocused();

  useEffect(() => {
    if (isFocused) {
      InteractionManager.runAfterInteractions(() => {
        StatusBar.setBarStyle('light-content', true);
        if (Platform.OS === 'android') {
          StatusBar.setBackgroundColor(colors.primary);
        }
      });
    }
    return () => {
      StatusBar.setBarStyle('dark-content', false);
      if (Platform.OS === 'android') {
        StatusBar.setBackgroundColor(colors.surface);
      }
    };
  }, [isFocused]);

  useEffect(() => {
    AsyncStorage.setItem(
      DEFAULT_VIEW_ASYNC_STORE_KEY,
      DEFAULT_VIEW_STAFF_ASYNC_STORE_VALUE
    );
  }, []);

  const { refresh } = useRefresh();
  const { navigate } = useNavigation();
  const { t } = useTranslation();
  const {
    isLoading: isLoadingAccounts,
    mappedAccounts,
    accounts,
    showIncomeLink,
    isEngagementMode,
  } = useAccounts();
  const { data, birthdates } = useUser();
  const {
    branches,
    mappedBranches,
    isLoading: isLoadingBranches,
  } = useBranches();
  const { mappedLabels } = useLabels();
  const { mappedModulesBySlug } = useModules();
  const { mappedPositions } = usePositions();
  const { mappedLocations } = useLocations();
  const { notifications, total } = useNotifications({ pageSize: 3 });
  const { isLoading: isLoadingTimesheets } = useTimesheets();
  const { bookings, isLoading: isLoadingBookings } = useBookings();
  // offfers is not cached, so we look at isInitialLoading
  const { offers, isInitialLoading: isLoadingOffers } = useOffers();
  const timeAvailabilitiesAccount = useMemo(
    () => (accounts || []).filter((account) => account.timeAvailability),
    [accounts]
  );

  useSequencePrompter({
    onPrompt: (sequenceId, instanceId) =>
      navigate(SequencesScreenNames.MAIN, {
        screen: SequencesScreenNames.SEQUENCE,
        params: { sequenceId, instanceId },
      }),
  });

  const isLoading = useMemo(() => {
    if (
      initialLoad.current &&
      (isLoadingTimesheets ||
        isLoadingBookings ||
        isLoadingOffers ||
        isLoadingAccounts ||
        isLoadingBranches)
    ) {
      return true;
    }
    initialLoad.current = false;
    return false;
  }, [
    isLoadingAccounts,
    isLoadingBookings,
    isLoadingBranches,
    isLoadingOffers,
    isLoadingTimesheets,
  ]);

  const showJoinABranch = useMemo(
    () =>
      !isLoadingBranches &&
      branches.length === 0 &&
      data?.candidates?.length === 0,
    [branches.length, data?.candidates?.length, isLoadingBranches]
  );

  return (
    <BaseScreen onRefresh={refresh} isLoading={isLoading}>
      {isFocused && (
        <StatusBar barStyle="light-content" backgroundColor={colors.primary} />
      )}
      <Container>
        {showJoinABranch && (
          <EmptyStateCard
            height={252}
            image={images.svgs.joinCompany}
            rtlImage={images.svgs.joinCompanyRtl}
            buttonText={t('joinBranchLink')}
            onPress={() => navigate(OnboardingScreenNames.MAIN)}
            text={t('notAssociatedWithEmployer')}
          />
        )}

        <BirthdayCard birthdates={birthdates} />

        {data?.candidates?.length > 0 && (
          <BranchRequestCard branch={data?.candidates?.[0]?.branch} />
        )}
        {Platform.OS !== 'web' && (
          <ThingsToDoCard
            mappedPositions={mappedPositions}
            mappedBranches={mappedBranches}
          />
        )}

        {Platform.OS !== 'web' && <EmployersCard />}

        {isEngagementMode && <FeedCard />}

        <TimeAvailabilityCard accounts={timeAvailabilitiesAccount} />
        {mappedModulesBySlug?.timesheet && (
          <TimeclocksCard branches={branches} mappedBranches={mappedBranches} />
        )}
        {mappedModulesBySlug?.scheduling && (
          <SchedulingCards
            bookings={bookings}
            offers={offers}
            branches={branches}
            accounts={accounts}
            mappedBranches={mappedBranches}
            mappedLabels={mappedLabels}
            mappedPositions={mappedPositions}
            mappedLocations={mappedLocations}
            mappedAccounts={mappedAccounts}
            showJoinABranch={showJoinABranch}
            isLoadingBookings={isLoadingBookings}
            isLoadingOffers={isLoadingOffers}
          />
        )}
        {showIncomeLink && <IncomeCard />}
        {total > 0 && (
          <NotificationsCard notifications={notifications} total={total} />
        )}
      </Container>
    </BaseScreen>
  );
});
