import React, { FC, useCallback, useMemo, useState } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { IPost } from '@violetta/ubeya/entities';
import {
  FlexRowBetween,
  FlexRowCenter,
  H5Regular,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { Pressable } from 'react-native';
import styled, { useTheme } from 'styled-components/native';

const Container = styled(FlexRowBetween)`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.gray4};
`;

const LeftContainer = styled(FlexRowCenter)``;

const RightContainer = styled(FlexRowCenter)`
  flex-direction: row-reverse;
`;

const Text = styled(H5Regular)`
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-left: 10px;
`;

interface Props {
  post: IPost;
  createLike(x: { postId: number }): Promise<void>;
  deleteLike(x: { postId: number; likeId: number }): Promise<void>;
}

const iconProps = { width: 16, height: 17 };

const NonLikedIcon = () => (
  <SvgIcon xml={icons.actionIcons.heart} {...iconProps} />
);
const LikedIcon = () => (
  <SvgIcon xml={icons.actionIcons.heartLiked} {...iconProps} />
);

export const PostStatusBar: FC<Props> = ({ post, createLike, deleteLike }) => {
  const { like, likesTotal, comments } = post;
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleLikeClick = useCallback(async () => {
    if (isDisabled) {
      return;
    }
    setIsDisabled(true);
    like
      ? await deleteLike({ postId: post.id, likeId: like.id })
      : await createLike({ postId: post.id });
    setIsDisabled(false);
  }, [post, like, deleteLike, createLike, isDisabled]);

  return (
    <Container>
      <LeftContainer as={RipplePressable} onPress={handleLikeClick}>
        {like ? <LikedIcon /> : <NonLikedIcon />}
        <Text>{likesTotal}</Text>
      </LeftContainer>
      <RightContainer>
        {false && (
          <SvgIcon xml={icons.actionIcons.actionsMenu} width={4} height={18} />
        )}
        {/* NOTE: not implemented in backend yet */}
        {false && <Text>{comments?.length} Comments</Text>}
        {false && (
          <SvgIcon xml={icons.actionIcons.comment} width={15} height={16} />
        )}
      </RightContainer>
    </Container>
  );
};
