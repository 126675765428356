import {
  useAccounts,
  useBranches,
  useLabels,
  useLocations,
  useModules,
  usePositions,
  useRequestsTypes,
  useUniforms,
} from '@violetta/ubeya/entities';

export const useInitialFetch = () => {
  useLabels();
  useModules();
  usePositions();
  useAccounts();
  useBranches();
  useLocations();
  useUniforms();
  useRequestsTypes();
};
