import React, { FC } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, Close, FlexRow, H7Light } from '@violetta/ubeya/ui';
import moment from 'moment';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { theme } from '../../../../style/theme';

interface Props {
  id: number;
  startTime: Date;
  status: number;
  endTime: Date;
  onDeletePeriod: (id: number, startTime: Date, endTime: Date) => void;
  isEditEnabled?: boolean;
}

const Container = styled(BaseView)`
  margin: 6px 0;
`;

const Hour = styled(H7Light)`
  margin-right: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: #252525;
`;
const Content = styled(FlexRow)`
  align-items: center;
  justify-content: center;
`;

const LeftBorder = styled<{ status: number }>(BaseView)`
  border-radius: 2px;
  height: 20px;
  width: 3px;
  background-color: ${({ status }) => (status === 1 ? '#ff2820' : '#46cc88')};
`;

const BorderContainer = styled(BaseView)`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TimeAvailabilityItemChip: FC<Props> = ({
  id,
  startTime,
  endTime,
  onDeletePeriod,
  status,
  isEditEnabled,
}) => {
  const { t } = useTranslation();

  return (
    <Container
      as={Pressable}
      onPress={
        isEditEnabled ? () => onDeletePeriod(id, startTime, endTime) : undefined
      }
    >
      <Content>
        <BorderContainer>
          <LeftBorder status={status} />
        </BorderContainer>
        <Hour>
          {status === 1 ? t('preferNotWork') : t('preferWork')}
          {'  | '}
        </Hour>
        <Hour style={{ marginRight: 8 }}>{`${moment(startTime).format(
          'LT'
        )}-${moment(endTime).format('LT')}`}</Hour>
        {isEditEnabled && <Close color={theme.colors.gray1} size={19} />}
      </Content>
    </Container>
  );
};
