import React from 'react';
import moment from 'moment';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseText, BaseView, SvgIcon } from '@violetta/ubeya/ui';
import { IBreaktime } from '../entities';
import { IReport, MAP_ITEM_TYPE } from '../entities/IReport';
import { CustomTimesheetDTO } from './useTimesheets';
import styled from 'styled-components/native';
import { icons } from '@violetta/ubeya/assets';

const BreakEndNob = styled(BaseView)`
  width: 15px;
  height: 15px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 24px;
`;

const BreakStarted = styled(BaseView)`
  width: 15px;
  height: 15px;
  border-color: ${({ theme }) => theme.colors.primary};
  border-width: 2px;
  border-radius: 24px;
  background-color: white;
`;

const StartEndContainer = styled(BaseView)`
  margin-left: -11px;
  width: 32px;
  height: 23px;
`;

const InlineContainer = styled(BaseView)`
  margin-left: -7px;
  width: 28px;
  height: 17px;
`;

const ClockInAfterCheck = styled(BaseView)`
  width: 15px;
  height: 15px;
  border-color: ${({ theme }) => theme.colors.success};
  border-width: 2px;
  border-radius: 24px;
  background-color: white;
`;

const StartNob = () => (
  <StartEndContainer>
    <SvgIcon
      width={23}
      height={23}
      xml={icons.uiIcons.circledCheckmarkStroke}
    />
  </StartEndContainer>
);
const EndNob = () => (
  <StartEndContainer>
    <SvgIcon width={23} height={23} xml={icons.uiIcons.circledCheckmarkFill} />
  </StartEndContainer>
);

const ClockInAfterCheckInNob = () => (
  <InlineContainer>
    <ClockInAfterCheck />
  </InlineContainer>
);

const BreakStartedNob = () => (
  <InlineContainer>
    <BreakStarted />
  </InlineContainer>
);

const BreakEndedNob = () => (
  <InlineContainer>
    <BreakEndNob />
  </InlineContainer>
);

export const useTimesheetsUtils = () => {
  const { t } = useTranslation();

  const createReports = (
    timesheet: CustomTimesheetDTO,
    breaktimes: IBreaktime[],
    mappedLocations: { [x: string]: any },
    mappedBreakTypes: { [x: string]: any },
    momentTz
  ) => {
    const map = [] as (IReport & { localStringTimestamp: string })[];
    const location = mappedLocations?.[timesheet?.locationId];
    if (timesheet?.checkInTime) {
      map.push({
        localStringTimestamp: timesheet.checkInTime.toLocaleString(),
        timestamp: momentTz
          .tz(timesheet.checkInTime, location?.timezoneName)
          .toDate(),
        title: `${t('timesheetCheckIn')} 👋`,
        nob: ClockInAfterCheckInNob,
        type: MAP_ITEM_TYPE.CHECK_IN,
      });
    }

    if (timesheet?.checkOutTime) {
      map.push({
        localStringTimestamp: timesheet.checkOutTime.toLocaleString(),
        timestamp: momentTz
          .tz(timesheet.checkOutTime, location?.timezoneName)
          .toDate(),
        title: `${t('timesheetCheckOut')} 👋`,
        nob: ClockInAfterCheckInNob,
        type: MAP_ITEM_TYPE.CHECK_OUT,
      });
    }

    if (timesheet?.startTime) {
      let subTitle: string | undefined;
      if (timesheet?.actualStartTime) {
        if (
          !moment(timesheet?.actualStartTime, 'YYYY-MM-DD HH:mm:00').isSame(
            moment(timesheet?.startTime, 'YYYY-MM-DD HH:mm:00')
          )
        ) {
          subTitle = t('clockInWillBeReportedAt', {
            time: moment(timesheet?.actualStartTime).format('H:mm'),
          }) as string;
        }
      }
      map.push({
        localStringTimestamp: timesheet.startTime.toLocaleString(),
        timestamp: momentTz
          .tz(timesheet.startTime, location?.timezoneName)
          .toDate(),
        title: t('clockIn'),
        subTitle,
        nob: StartNob,
        type: MAP_ITEM_TYPE.CLOCK_IN,
      });
    }

    if (timesheet?.endTime) {
      map.push({
        localStringTimestamp: timesheet.endTime.toLocaleString(),
        timestamp: momentTz
          .tz(timesheet.endTime, location?.timezoneName)
          .toDate(),
        title: t('clockOut'),
        nob: EndNob,
        type: MAP_ITEM_TYPE.CLOCK_OUT,
      });
    }

    for (let breaktime of breaktimes) {
      if (breaktime?.startTime) {
        map.push({
          localStringTimestamp: breaktime.startTime.toLocaleString(),
          timestamp: momentTz
            .tz(breaktime.startTime, location?.timezoneName)
            .toDate(),
          title: `${t('breakStarted')}${
            breaktime?.breakTypeId
              ? ` (${mappedBreakTypes?.[breaktime?.breakTypeId]?.title})`
              : ''
          }`,
          nob: BreakStartedNob,
          type: MAP_ITEM_TYPE.BREAK_STARTED,
        });
      }

      if (breaktime?.endTime) {
        map.push({
          localStringTimestamp: breaktime.endTime.toLocaleString(),
          timestamp: momentTz
            .tz(breaktime.endTime, location?.timezoneName)
            .toDate(),
          title: t('breakEnded'),
          nob: BreakEndedNob,
          type: MAP_ITEM_TYPE.BREAK_ENDED,
        });
      }
    }

    return map.sort((a, b) =>
      a?.localStringTimestamp === b?.localStringTimestamp
        ? a?.type === b?.type // if timestamp are the same, compare the logical type
          ? 0
          : a?.type > b?.type
          ? 1
          : -1
        : a?.localStringTimestamp > b?.localStringTimestamp
        ? 1
        : -1
    );
  };

  return {
    createReports,
  };
};
