import React, { FC, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { IPayroll, IBranch, useAccounts } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  H4Bold,
  H4Medium,
  BaseText,
  FlexRowBetween,
  FlexRowCenter,
  BaseView,
  FlexColumnDirection,
  SvgIcon,
  BaseCollapsible,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';
import { IncomeRow } from './IncomeRow';

const Container = styled(FlexRowBetween)`
  padding: 10px 10px;
  flex: 1;
`;

const MonthContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
  width: 25px;
  margin-right: 20px;
  position: relative;
`;

const MonthJobsContainer = styled(BaseView)`
  align-items: flex-start;
  flex: 1;
`;

const IncomeContainer = styled(BaseView)`
  padding: 10px 0;
  background-color: ${({ theme }) => theme.colors.offwhitesmoke};
`;

const Month = styled(H4Bold)``;
const MonthTotal = styled(H4Bold)`
  color: ${({ theme }) => theme.colors.income};
`;

const ShiftsNum = styled(H4Medium)`
  font-size: 13px;
  margin-top: 3px;
`;
const IconWrapper = styled(BaseView)`
  position: absolute;
  top: 0;
  left: -1;
`;

const MonthDigitWrapper = styled(BaseView)`
  margin-top: 8px;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MonthDigit = styled(BaseText)`
  font-weight: 500;
  font-size: 13px;
`;

const Wrapper = styled(FlexRowCenter)`
  flex: 1;
`;

interface Props {
  date: string;
  payrolls: IPayroll[];
  mappedBranches: Record<number, IBranch>;
}

export const IncomeCollapsible: FC<Props> = ({
  date,
  payrolls,
  mappedBranches,
}) => {
  const { mappedAccounts } = useAccounts();
  const { t } = useTranslation();
  const monthTotal = useMemo(
    () =>
      (
        payrolls.reduce(
          (output, current) => parseFloat(output + current.value || 0),
          0
        ) || 0
      ).toFixed(2),
    [payrolls]
  );
  const numOfShifts = payrolls.length;
  const branch = mappedBranches[payrolls?.[0]?.branchId];
  const account = mappedAccounts[branch?.accountId];
  const currency = account?.currency?.symbol;

  const Trigger = () => (
    <Container>
      <Wrapper>
        <MonthContainer>
          <IconWrapper>
            <SvgIcon
              xml={icons.uiIcons.incomeCalendar}
              width={25}
              height={25}
            />
          </IconWrapper>
          <MonthDigitWrapper>
            <MonthDigit>{moment(date).format('M')}</MonthDigit>
          </MonthDigitWrapper>
        </MonthContainer>
        <MonthJobsContainer>
          <BaseView>
            <Month>{moment(date).format('MMMM')}</Month>
          </BaseView>
          <BaseView>
            <ShiftsNum>
              {numOfShifts}{' '}
              {`${numOfShifts > 1 ? t('incomeShifts') : t('incomeShift')}`}
            </ShiftsNum>
          </BaseView>
        </MonthJobsContainer>
        <MonthTotal>
          {currency}
          {monthTotal}
        </MonthTotal>
      </Wrapper>
    </Container>
  );

  const renderPayrolls = useMemo(
    () => (
      <IncomeContainer>
        {payrolls.map((payroll) => (
          <IncomeRow
            t={t}
            key={payroll.timesheetId}
            payroll={payroll}
            mappedBranches={mappedBranches}
          />
        ))}
      </IncomeContainer>
    ),
    [mappedBranches, payrolls, t]
  );

  return (
    <BaseCollapsible
      trigger={Trigger}
      containerStyle={{ marginLeft: 30, marginRight: 30 }}
    >
      {renderPayrolls}
    </BaseCollapsible>
  );
};
