import React, { FC } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { css } from 'styled-components';
import styled from 'styled-components/native';
import { BaseText } from '../Typography';
import { BaseView, FlexRowCenter } from '../Layout/Layout';
import { SvgIcon } from '../Icons/SvgIcon';

const ImageContainer = styled(FlexRowCenter)<{
  size: number;
  limitContainerSize?: boolean;
}>`
  ${({ limitContainerSize }) =>
    limitContainerSize &&
    css`
      width: ${({ size }) => `${size}px`};
      height: ${({ size }) => `${size}px`};
    `}

  border-radius: ${({ size }) => `${size / 2}px`};
  justify-content: center;
`;

const Image = styled.Image<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: ${({ size }) => `${size / 2}px`};
  resize-mode: cover;
`;

const NameContainer = styled(ImageContainer)`
  background-color: ${({ theme }) => theme.colors.gray4};

  ${({ limitContainerSize }) =>
    limitContainerSize &&
    css`
      width: ${({ size }) => `${size}px`};
      height: ${({ size }) => `${size}px`};
    `}
`;

const EmptyLogoContainer = styled(BaseView)`
  border-radius: ${({ size }) => `${size / 2}px`};
  background-color: ${({ theme }) => theme.colors.primary};
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: ${({ size }) => `${size / 2}px`};
  resize-mode: cover;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmptyLogoText = styled(BaseText)`
  font-size: ${({ size }) => `${size / 2}px`};
  color: ${({ theme }) => theme.colors.surface};
`;

interface Props {
  image: string | null;
  name?: string | null;
  size: number;
  style?: any;
  imageStyle?: any;
  limitContainerSize?: boolean;
  showNameAsEmptyLogo?: boolean;
}

export const AccountImage: FC<Props> = ({
  image,
  size,
  style,
  limitContainerSize = true,
  name,
  showNameAsEmptyLogo = false,
  imageStyle,
}) => {
  if (image) {
    return (
      <ImageContainer
        size={size}
        limitContainerSize={limitContainerSize}
        style={style}
      >
        <Image source={{ uri: image }} size={size} style={imageStyle} />
      </ImageContainer>
    );
  }

  if (name && showNameAsEmptyLogo) {
    return (
      <EmptyLogoContainer
        size={size}
        limitContainerSize={limitContainerSize}
        style={style}
      >
        <EmptyLogoText size={size}>
          {(name || '').charAt(0).toUpperCase()}
        </EmptyLogoText>
      </EmptyLogoContainer>
    );
  }
  return (
    <NameContainer
      size={size}
      limitContainerSize={limitContainerSize}
      style={style}
    >
      <SvgIcon
        xml={icons.uiIcons.employeePlaceholder}
        width={size}
        height={size}
        defaultViewBox={false}
      />
    </NameContainer>
  );
};
