import React, { FC, useContext, useMemo } from 'react';
import {
  Booking,
  ILabel,
  ILocation,
  IPosition,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Card,
  ChevronRight,
  FlexColumnCenter,
  FlexColumnDirection,
  FlexRowCenter,
  H3Bold,
  H5Medium,
  H6Regular,
  H7Medium,
  H7Regular,
  Marker as MarkerBase,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import moment from 'moment';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts/ConfigContext';
import { colors } from '../../../style';

const Container = styled(Card)`
  padding: 5px 0;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const BetweenContainer = styled(FlexRowCenter)``;

const Date = styled(FlexColumnDirection)`
  width: 80px;
  align-items: center;
  padding: 0 10px;
  margin-left: 3px;
`;

const Day = styled(H3Bold)`
  font-weight: 500;
`;
const LightDate = styled(H7Regular)`
  margin-top: 5px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const DetailsContainer = styled(FlexColumnCenter)`
  padding: 2px 0;
  margin-left: 15px;
  justify-content: space-between;
  align-items: flex-start;
`;

const DataContainer = styled(FlexRowCenter)`
  margin-top: 2px;
  margin-bottom: 1px;
  position: relative;
  max-width: 200px;
  align-items: flex-start;
`;

const DataContainerSpaced = styled(DataContainer)`
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`;

const Data = styled(H5Medium)`
  margin-right: 5px;
  font-weight: 500;
  margin-bottom: 3px;
`;

const DataLow = styled(H6Regular)``;

const Marker = styled(MarkerBase)`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

const ConfirmedChip = styled(BaseView)`
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.success};
  padding: 3px 8px;
  border-radius: 6px;
`;

const ReleaseChip = styled(BaseView)`
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.seconday2};
  padding: 3px 8px;
  border-radius: 6px;
`;

const ConfirmedChipText = styled(H7Medium)`
  color: ${({ theme }) => theme.colors.surface};
  font-size: 9px;
  font-weight: 500;
  text-align: center;
`;

interface Props {
  booking: Booking;
  onPress?: (booking: Booking) => void;
  mappedLocations: { [key: number]: ILocation };
  mappedPositions: { [key: number]: IPosition };
  mappedLabels: { [key: number]: ILabel };
}

export const BookingCard: FC<Props> = ({
  booking,
  onPress,
  mappedLocations,
  mappedPositions,
  mappedLabels,
}) => {
  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();

  const [date, day] = useMemo(() => {
    const m = moment(booking?.project?.date);
    return [
      m.format('l').split('/')?.splice(0, 2)?.join('/'),
      rtl ? m.format('dddd') : m.format('ddd'),
    ] as const;
  }, [booking?.project?.date, rtl]);

  const formattedHours = useMemo(
    () => rangeFormat(booking.startTime, booking.endTime),
    [booking]
  );

  const labelId = useMemo(() => booking?.project.labelId, [booking]);

  const position = useMemo(
    () => !!booking?.positionId && t(mappedPositions[booking?.positionId].slug),
    [booking?.positionId, t, mappedPositions]
  );

  const markerColor = useMemo(
    () =>
      labelId
        ? mappedLabels[labelId]?.background
        : booking?.confirmation && booking?.confirmation.isConfirmed
        ? colors.success
        : colors.gray13,
    [booking?.confirmation, labelId, mappedLabels]
  );

  return (
    <Container as={RipplePressable} onPress={() => onPress(booking)}>
      <Marker color={markerColor} />
      <BetweenContainer>
        <Date>
          <Day>{day}</Day>
          <LightDate>{date}</LightDate>
        </Date>
        <DetailsContainer>
          <DataContainerSpaced>
            <Data>{booking.compoundedName}</Data>
          </DataContainerSpaced>
          {!!position && <Data>{position}</Data>}
          {!!booking?.comments && <Data>{booking?.comments}</Data>}

          <FlexColumnDirection>
            {!!formattedHours && (
              <DataContainer>
                <DataLow>{formattedHours}</DataLow>
              </DataContainer>
            )}
            {booking.locationId && (
              <DataContainer>
                <DataLow>
                  {mappedLocations[booking.locationId]?.address}
                </DataLow>
              </DataContainer>
            )}
          </FlexColumnDirection>
          {booking?.confirmation && booking?.confirmation.isConfirmed && (
            <ConfirmedChip>
              <ConfirmedChipText>{t('confirmed')}</ConfirmedChipText>
            </ConfirmedChip>
          )}
          {[0, 1].includes(booking?.swap?.status) && (
            <ReleaseChip>
              <ConfirmedChipText>
                {t('swapPendingChip').toUpperCase()}
              </ConfirmedChipText>
            </ReleaseChip>
          )}
          {booking?.release?.status === 0 && (
            <ReleaseChip>
              <ConfirmedChipText>
                {t('releasePendingChip').toUpperCase()}
              </ConfirmedChipText>
            </ReleaseChip>
          )}
        </DetailsContainer>
      </BetweenContainer>
      <ChevronRight size={25} rtl={rtl} />
    </Container>
  );
};
