import React, { useMemo, useState } from 'react';
import countriesJson from './countries.json';
import { ICountry } from '@violetta/ubeya/entities';

const countries = countriesJson.countries as ICountry[];

export const useCountries = () => {
  const [allCountries] = useState(countries);

  const mappedCountreis = useMemo(
    () =>
      (allCountries || []).reduce(
        (prev, { code, ...country }) => ({
          ...prev,
          [code]: { code, ...country },
        }),
        {} as { [key: string]: ICountry }
      ),
    [allCountries]
  );

  return {
    mappedCountreis,
    countries: allCountries,
  };
};
