import React from 'react';
import { lotties } from '@violetta/ubeya/assets';
import { InferProps as PropsOf } from '@violetta/ubeya/utils';
import { ActivityIndicator, Platform } from 'react-native';
import styled from 'styled-components/native';
import { Lottie } from '../Lotties';

interface Props {
  size?: number;
  width?: number;
  height?: number;
}

const LoaderWrapper = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.surface};
`;

export const CustomLoader = ({ size = 150, width, height }: Props = {}) => {
  //   <Spinner size={size} isVisible color={color} type="ChasingDots" />
  if (Platform.OS === 'web') {
    return <ActivityIndicator size="small" color="#C5C5C5" />;
  }
  return (
    <Lottie
      name={lotties.ubeyaLoader}
      autoPlay
      width={width || size}
      height={height || size}
      resizeMode="cover"
    />
  );
};

export const CustomLoaderWrapped = (props: PropsOf<typeof CustomLoader>) => (
  <LoaderWrapper>
    <CustomLoader {...props} />
  </LoaderWrapper>
);
