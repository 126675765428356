import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { SvgIcon } from '../Icons';
import { BaseView } from '../Layout';

const Container = styled(BaseView)`
  position: relative;
  align-items: center;
  justify-content: center;
  padding-bottom: 6px;
`;

interface Props {
  notified?: boolean;
  icon: string;
}

export const TabIcon: FC<Props> = ({ icon }) => {
  const { colors } = useTheme();
  return (
    <Container>
      <SvgIcon xml={icon} width={24} height={24} color={colors.primary} />
    </Container>
  );
};
