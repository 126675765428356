import React, { FC, useContext, useMemo } from 'react';
import {
  IAccount,
  IBranch,
  ILabel,
  ILocation,
  IPosition,
  TimesheetDTO,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';

import {
  BaseView,
  Card as BaseCard,
  FlexColumnDirection,
  FlexRowCenter,
  H5Medium,
  H6Medium,
  H6Regular,
  H7Medium,
  H7Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import {
  hoursToTotalDuration,
  totalDuration,
  rangeFormat,
} from '@violetta/ubeya/utils';
import moment from 'moment';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts/ConfigContext';

const Container = styled(BaseView)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Wrapper = styled(BaseCard)`
  padding: 20px 10px;
  margin: 10px 21px;
  border-width: 0px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.offwhitesmoke};
  elevation: 0;
  shadow-color: rgba(0, 0, 0, 0);
  shadow-opacity: 0;
  shadow-offset: 0px 0px;
  shadow-radius: 0px;
`;

const BetweenContainer = styled(FlexRowCenter)``;

const Date = styled<{ color: string }>(FlexColumnDirection)`
  align-items: center;
  justify-content: flex-start;
  border-right-width: 2px;
  border-right-color: ${({ color }) => color};
  width: 60px;
  height: 100%;
`;

const LightDate = styled(H7Regular)`
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const DetailsContainer = styled(BaseView)`
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const DataContainer = styled(FlexRowCenter)`
  margin-top: 2px;
  margin-bottom: 1px;
  position: relative;
  max-width: 200px;
  align-items: flex-start;
`;

const DataContainerSpaced = styled(DataContainer)`
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
`;

const Data = styled(H5Medium)`
  margin-right: 5px;
  font-weight: 500;
`;
const DataTotal = styled(H6Regular)`
  margin-right: 5px;
  margin-top: 7px;
`;

const BranchName = styled<{ color: string }>(H7Regular)`
  margin-bottom: 6px;
  letter-spacing: 0;
  line-height: 18px;
  color: ${({ color }) => color};
`;

const ShiftName = styled(H6Medium)`
  font-weight: 500;
  margin-bottom: 6px;
  flex-wrap: wrap;
`;

const BranchLogo = styled.Image`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-top: 10px;
`;

const LocationName = styled(H6Regular)`
  font-weight: 300;
  letter-spacing: 0;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.gray2};
`;

interface Props {
  timesheet: TimesheetDTO;
  mappedAccounts: Record<number, IAccount>;
  mappedLocations: Record<number, ILocation>;
  mappedPositions: Record<number, IPosition>;
  mappedBranches: Record<number, IBranch>;
  mappedLabels: Record<number, ILabel>;
  onPress?: () => void;
}

export const TimesheetCard: FC<Props> = ({
  timesheet,
  onPress,
  mappedAccounts,
  mappedLocations,
  mappedPositions,
  mappedBranches,
  mappedLabels,
}) => {
  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();

  const [date, day] = useMemo(() => {
    const m = moment(
      timesheet.job?.date ||
        timesheet.shift?.date ||
        timesheet.shift?.project?.date
    );
    return [
      m.format('D MMM'),
      rtl ? m.format('dddd') : m.format('ddd'),
    ] as const;
  }, [rtl, timesheet.job?.date, timesheet.shift]);

  const title = useMemo(() => {
    const { shift, job } = timesheet;
    if (job) {
      return mappedBranches[job.branchId]?.name;
    }

    const splitted = [] as string[];
    const name = shift?.project?.name;
    const labelId = shift?.project?.labelId;
    const positionId = shift?.positionId;
    const locationId = shift?.project?.locationId;

    if (name) {
      splitted.push(name);
    }

    if (locationId) {
      const location = mappedLocations?.[locationId];
      splitted.push(location?.normalizedName || '');
    }
    if (labelId) {
      splitted.push(mappedLabels?.[labelId]?.title || '');
    }

    if (positionId) {
      splitted.push(t(mappedPositions?.[positionId]?.slug));
    }

    return splitted.join(' - ');
  }, [
    timesheet,
    mappedBranches,
    mappedLocations,
    mappedLabels,
    t,
    mappedPositions,
  ]);

  const branchId = useMemo(
    () => timesheet?.shift?.project?.branchId,
    [timesheet?.shift?.project?.branchId]
  );

  const totalTime = useMemo(() => {
    const { startTime, endTime } = timesheet;
    if (!startTime || !endTime) {
      return null;
    }
    const deductBreaktimesIsActive =
      timesheet?.shift?.project?.branch?.accountId &&
      mappedAccounts?.[timesheet?.shift?.project?.branch?.accountId]
        ?.payrollConfig?.deductBreaktimesIsActive;

    const breakDureation = deductBreaktimesIsActive
      ? (timesheet?.breaktimes || []).reduce(
          (sum, { startTime, endTime }) =>
            sum +
            totalDuration(moment(startTime), moment(endTime)).as('minutes'),
          0
        )
      : 0;

    return hoursToTotalDuration(
      totalDuration(moment(startTime), moment(endTime)).subtract(
        breakDureation,
        'minutes'
      )
    );
  }, [mappedAccounts, timesheet]);

  const logo = useMemo(
    () => mappedBranches[branchId]?.accountId && mappedBranches[branchId]?.logo,
    [branchId, mappedBranches]
  );

  const account = useMemo(
    () =>
      mappedBranches[branchId]?.accountId
        ? mappedAccounts[mappedBranches[branchId]?.accountId]
        : null,
    [branchId, mappedAccounts, mappedBranches]
  );

  const branchName = useMemo(
    () => mappedBranches[branchId]?.name,
    [branchId, mappedBranches]
  );

  const time = useMemo(
    () => rangeFormat(timesheet.shift?.startTime, timesheet.shift?.endTime),
    [timesheet.shift?.endTime, timesheet.shift?.startTime]
  );

  return (
    <Wrapper>
      <Container as={RipplePressable} onPress={onPress}>
        <Date color={account?.color || '#000000'}>
          <H7Medium>{day}</H7Medium>
          <LightDate>{date}</LightDate>
          {!!logo && <BranchLogo source={{ uri: logo }} />}
        </Date>
        <DetailsContainer>
          <BranchName color={account?.color || '#000000'}>
            {branchName}
          </BranchName>
          <ShiftName>{title}</ShiftName>
          <LocationName>{time}</LocationName>
          {!!totalTime && (
            <DataTotal>{`${t('totalTime')} ${totalTime} ${t(
              'hours'
            )}`}</DataTotal>
          )}
        </DetailsContainer>
      </Container>
    </Wrapper>
  );
};
