import React, { FC, useCallback, useMemo } from 'react';
import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
} from '@react-navigation/material-top-tabs';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  HeaderBar,
  HeaderlessBar,
  Toggler,
} from '@violetta/ubeya/ui';
import styled, { useTheme } from 'styled-components/native';
import { TimesheetsScreen, TimesheetsScreenNames } from '../../timesheets';
import { CaptainHeaderRightContent } from '../components';
import { BookingHeader } from '../components/headers/BookingHeader';
import { CaptainHeader } from '../components/headers/CaptainHeader';
import { CaptainScreen } from '../screens';
import { BookingPassCardScreen } from '../screens/BookingPassCardScreen';
import { BookingScreen } from '../screens/BookingScreen';
import { BookingsScreen } from '../screens/BookingsScreen';
import { ProjectFeedScreen } from '../screens/ProjectFeedScreen';
import { TeamScreen } from '../screens/TeamScreen';
import { BookingsScreenNames } from './ScreenNames';
import { Platform } from 'react-native';

const TogglerContainer = styled(BaseView)`
  width: 250px;
`;

const { Navigator, Screen } = createNativeStackNavigator();
const { Navigator: TabNavigator, Screen: TabScreen } =
  createMaterialTopTabNavigator();

const TopBar = React.memo((props: MaterialTopTabBarProps) => {
  const { navigation, state } = props;
  const { index } = state;
  const { t } = useTranslation();
  const weekData = useMemo(
    () => [
      { id: 0, title: t('upcomingShiftsToggler') },
      { id: 1, title: t('pastShiftsToggler') },
    ],
    [t]
  );

  const changeItem = useCallback(
    () =>
      navigation.jumpTo(
        index === 0
          ? TimesheetsScreenNames.TIMESHEETS
          : BookingsScreenNames.BOOKINGS
      ),
    [navigation, index]
  );

  const headerTitle = useCallback(
    () => (
      <TogglerContainer>
        <Toggler
          padding={6}
          items={weekData}
          selectedItemId={index}
          onItemSelected={changeItem}
        />
      </TogglerContainer>
    ),
    [changeItem, index, weekData]
  );

  return (
    <HeaderBar hideBack hasLeftMenu headerTitle={headerTitle} {...props} />
  );
});

export const ScheduleNavigation = () => (
  <TabNavigator
    screenOptions={{ tabBarScrollEnabled: false }}
    tabBar={(props: MaterialTopTabBarProps) => <TopBar {...props} />}
  >
    <TabScreen name={BookingsScreenNames.BOOKINGS} component={BookingsScreen} />
    <TabScreen
      name={TimesheetsScreenNames.TIMESHEETS}
      component={TimesheetsScreen}
    />
  </TabNavigator>
);

export const BookingsNavigation: FC = () => {
  const { colors } = useTheme();

  return (
    <Navigator
      initialRouteName={BookingsScreenNames.BOOKING}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
        // eslint-disable-next-line react/jsx-closing-bracket-location
      }}
    >
      <Screen
        name={BookingsScreenNames.BOOKING}
        component={BookingScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BookingHeader {...props} />
          ),
        }}
      />
      <Screen
        name={BookingsScreenNames.PROJECT_FEED}
        component={ProjectFeedScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BookingHeader {...props} />
          ),
        }}
      />
      <Screen
        name={BookingsScreenNames.TEAM}
        component={TeamScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BookingHeader {...props} />
          ),
        }}
      />
      <Screen
        name={BookingsScreenNames.PASS_CARD}
        component={BookingPassCardScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <BookingHeader {...props} />
          ),
        }}
      />
      <Screen
        name={BookingsScreenNames.CAPTAIN}
        component={CaptainScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <CaptainHeader
              {...props}
              // hasRightContent={Platform.OS !== 'web'}
              // rightContent={CaptainHeaderRightContent}
            />
          ),
        }}
      />
    </Navigator>
  );
};
