import React, { FC, useCallback } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { Pressable, StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { SvgIcon } from '../Icons';
import { FlexRow, FlexRowCenter } from '../Layout';
import { H4Regular } from '../Typography';

const Container = styled(FlexRow)`
  align-items: center;
  padding: 10px 0;
`;

const Box = styled<{ hasValue: boolean }>(FlexRowCenter)`
  height: 22px;
  width: 22px;
  border-radius: 3px;
  border-width: 1px;
  border-color: #d9d9d9;
  justify-content: center;
  background-color: ${({ theme, hasValue }) =>
    hasValue ? theme.colors.primary : theme.colors.surface};
`;
const Title = styled(H4Regular)`
  /* flex: 1; */
  margin-left: 10px;
  font-size: 14px;
  font-weight: normal;
  color: #252525;
`;

const BranchLogo = styled.Image`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-left: 10px;
`;

interface Props {
  title?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  color?: string;
  style?: StyleProp<ViewStyle>;
  image?: string;
}

export const CheckBox: FC<Props> = ({
  title,
  value,
  onChange,
  color = '#ffffff',
  style,
  image,
}) => {
  const onSelected = useCallback(() => {
    onChange?.(!value);
  }, [onChange, value]);

  return (
    <Container as={Pressable} onPress={onSelected} style={style}>
      <Box hasValue={!!value}>
        {!!value && (
          <SvgIcon
            xml={icons.actionIcons.done}
            height={14}
            width={14}
            color={color}
          />
        )}
      </Box>
      {!!image && <BranchLogo source={{ uri: image }} />}
      {!!title && <Title>{title}</Title>}
    </Container>
  );
};
