import React, { FC, useCallback, useMemo, useRef } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import moment from 'moment';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import styled from 'styled-components/native';
import { ChevronDown, IconClose } from '../Icons';
import { FlexColumnDirection, FlexRowBetween } from '../Layout';
import { InputBox } from '../TextInputs';
import { H3Bold, H4Regular } from '../Typography';
import { DatePicker } from './DatePicker';

const Container = styled(FlexColumnDirection)`
  width: 100%;
`;

const PreviewTitle = styled(H4Regular)`
  flex: 1;
  padding-left: 12px;
`;

const ModalHeader = styled(FlexRowBetween)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 12px 20px 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export interface DateInputPickerProps {
  placeholder?: string;
  onChange?: (value: Date) => void;
  value?: Date;
  rtl: boolean;
}

export const DateInputPicker: FC<DateInputPickerProps> = ({
  placeholder,
  onChange,
  value,
  rtl,
}) => {
  const { t } = useTranslation();
  const modalizeRef = useRef<Modalize>(null);

  const selectedDateFormatted = useMemo(
    () => (value ? moment(value).format('L') : t(placeholder || '')),
    [placeholder, value, t]
  );

  const open = useCallback(() => {
    modalizeRef.current.open();
  }, []);

  const close = useCallback(() => {
    modalizeRef.current.close();
  }, []);

  const onSelected = useCallback(
    (value: Date) => {
      onChange?.(value);
      close();
    },
    [close, onChange]
  );

  const Header = useMemo(
    () => (
      <ModalHeader>
        <H3Bold>{t('datePickerSingleModalHeader')}</H3Bold>
        <IconClose color="#000" size={22} onPress={close} />
      </ModalHeader>
    ),
    [close, t]
  );

  return (
    <Container>
      <InputBox
        onPress={open}
        icon={{ xml: icons.uiIcons.calendar, width: 20, height: 20 }}
      >
        <PreviewTitle>{selectedDateFormatted}</PreviewTitle>
        <ChevronDown />
      </InputBox>
      <Portal>
        <Modalize
          ref={modalizeRef}
          onOverlayPress={close}
          onBackButtonPress={close}
          adjustToContentHeight
          handlePosition="inside"
          HeaderComponent={Header}
        >
          <DatePicker onSelected={onSelected} value={value} rtl={rtl} />
        </Modalize>
      </Portal>
    </Container>
  );
};
