import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { INotification } from '@violetta/ubeya/entities';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

const selector = createCachedSelector(
  (data) => data,
  (data) => {
    const notifications = data?.data;
    const total = data?.total;
    return { notifications, total };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useNotifications = ({ pageSize }) => {
  const { data: userData } = useUser();

  const storeKey = ['notifications', String(pageSize)];
  const { isPending, data, refetch } = useQuery<{ data: INotification[] }>({
    queryKey: storeKey,
    queryFn: () => api.getNotifications({ pageSize }),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { notifications, total } = useMemo(() => {
    const { notifications = [], total = 0 } = data || {};
    return {
      notifications,
      total,
    };
  }, [data]);

  return {
    isLoading: isPending,
    notifications,
    total,
    refetch,
  };
};
