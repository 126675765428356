import React, { FC } from 'react';
import { IPostMedia } from '@violetta/ubeya/entities';
import { BaseView, H5Regular } from '@violetta/ubeya/ui';
import LinearGradient from 'react-native-linear-gradient';
import styled from 'styled-components/native';
import { BACKGROUNDS } from './PostBackgrounds';
import { TextWithLinks } from './TextWithLinks';
import { Platform } from 'react-native';

const Container = styled.View<{ backgroundId: number }>`
  ${({ backgroundId, theme }) =>
    !Number.isInteger(backgroundId) &&
    `background-color: ${theme.colors.surface}`};
`;

const BaseText = styled(H5Regular)``;

const BackgroundText = styled(BaseText)`
  color: ${({ theme }) => theme.colors.surface};
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  padding: 40px 0;
`;

const NoBackgroundText = styled(BaseText)`
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: 10px 0;
  font-family: Rubik;
  font-size: 15px;
  font-weight: normal;
`;

const TextContainer = styled(BaseView)`
  padding: 0 30px;
`;

interface Props {
  media: IPostMedia[];
}

export const PostMediaContent: FC<Props> = ({ media }) => {
  // TODO: support multiple media items
  const text = media?.[0]?.text;
  const backgroundId = media?.[0]?.backgroundId;

  if (!text) return null;

  return (
    <Container backgroundId={backgroundId}>
      {Platform.OS !== 'web' &&
        backgroundId != null &&
        Number.isInteger(backgroundId) &&
        !!BACKGROUNDS?.[backgroundId] && (
          <LinearGradient
            style={{ flex: 1, padding: 20 }}
            colors={
              BACKGROUNDS?.[backgroundId]?.colors || ['#6467EF', '#6467EF']
            }
            start={
              BACKGROUNDS?.[backgroundId]?.start || {
                x: 0,
                y: 0,
              }
            }
            // eslint-disable-next-line react/jsx-closing-bracket-location
            end={
              BACKGROUNDS?.[backgroundId]?.end || {
                x: 1,
                y: 1,
              }
            }
          >
            <BackgroundText>{text}</BackgroundText>
          </LinearGradient>
        )}

      {Platform.OS === 'web' && (
        <TextContainer>
          <TextWithLinks component={NoBackgroundText} onPress={() => {}}>
            {text}
          </TextWithLinks>
        </TextContainer>
      )}
      {!Number.isInteger(backgroundId) && (
        <TextContainer>
          <TextWithLinks component={NoBackgroundText} onPress={() => {}}>
            {text}
          </TextWithLinks>
        </TextContainer>
      )}
    </Container>
  );
};
