import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { AccountProfileCard } from '../../components';

const LinksSection = ({ links = [] }) => {
  return (
    <>
      {links.map(({ name, value }) => (
        <AccountProfileCard
          onPress={() => Linking.openURL(value)}
          title={name}
          hideIcon
        />
      ))}
    </>
  );
};

export default LinksSection;
