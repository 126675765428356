import React, { FC } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { LoginScreen } from '../screens';
import { ActivationCodeScreen } from '../screens/ActivationCodeScreen';

const { Navigator, Screen } = createNativeStackNavigator();

export const LoginNavigation: FC = () => (
  <NavigationContainer>
    <Navigator>
      <Screen
        name="login"
        component={LoginScreen}
        options={{ headerShown: false }}
      />
      <Screen
        name="activationCode"
        component={ActivationCodeScreen}
        options={{ headerShown: false }}
      />
    </Navigator>
  </NavigationContainer>
);
