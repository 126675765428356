import { icons } from '@violetta/ubeya/assets';

export const GENERAL_LINKS = [
  {
    type: 'facebook',
    icon: icons.companyProfileIcon.facebookIcon,
    name: 'facebookPage',
  },
  {
    type: 'instagram',
    icon: icons.companyProfileIcon.instagramIcon,
    name: 'instagramPage',
  },
  {
    type: 'linkdIn',
    icon: icons.companyProfileIcon.linkedInIcon,
    name: 'linkdinPage',
  },
  {
    type: 'website',
    icon: icons.companyProfileIcon.websiteIcon,
    name: 'websitePage',
  },
];

export const MAPPED_GENERAL_LINKS = GENERAL_LINKS.reduce((output, current) => {
  output[current.type] = current;
  return output;
}, {});

export const LINK_PREFIX = 'https://';
