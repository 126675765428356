import React, { FC } from 'react';
import { H4Medium } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const Title = styled(H4Medium)<{ paddingVertical?: number }>`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.61px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.primary};
  ${({ paddingVertical }) =>
    !!paddingVertical &&
    `padding-top: ${paddingVertical}px; padding-bottom: ${paddingVertical}px`};
`;

interface Props {
  title: string;
  paddingVertical?: number;
}

export const ActionLabel: FC<Props> = React.memo(
  ({ title, paddingVertical }) => (
    <Title paddingVertical={paddingVertical}>{title.toUpperCase()}</Title>
  )
);
