import React, { FC, useCallback, useContext, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import {
  IOffer,
  useOffers,
  usePositions,
  useUniforms,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseCollapsible,
  BaseText,
  BaseView,
  Button,
  FlexColumnDirection,
  FlexRow,
  FlexRowBetween,
  H7Regular,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { InferGenericArray, uiAlert } from '@violetta/ubeya/utils';
import moment from 'moment';
import { ActivityIndicator, Platform, Image as BaseImage } from 'react-native';
import Toast from 'react-native-toast-message';
import { useQueryClient } from '@tanstack/react-query';
import styled, { useTheme } from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import { API_DATE_FORMAT, API_DATE_TIME_FORMAT } from '../../main';
import { EMPTY_POSITION_ID } from '../constants';
import { useNavigation } from '@react-navigation/native';
import { FeedScreenNames } from '../../feed/navigation/ScreenNames';
import Markdown from 'react-native-markdown-display';

const Container = styled(BaseView)`
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #eaeaea;
  padding: 7px 0;
`;

const IconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
`;

const Divider = styled(BaseView)`
  height: 5px;
`;

const CardContent = styled(FlexRow)`
  justify-content: space-between;
`;

const ShiftJobDescription = styled(H7Regular)`
  width: 100%;
  margin-bottom: 10px;
`;

const ShiftDetails = styled(FlexColumnDirection)``;

const Actions = styled(BaseView)<{ rtl: boolean }>`
  justify-content: ${({ isApplicationLoading }) =>
    isApplicationLoading ? 'center' : 'flex-end'};
  align-items: flex-start;
  flex-direction: row;
`;

const PositionName = styled(H7Regular)`
  margin-left: 8px;
  letter-spacing: 0.86px;
  color: ${({ theme }) => theme.colors.gray15};
  width: 180px;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  color: #252525;
`;

const ShiftDate = styled(H7Regular)`
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  color: #252525;
`;

const ShiftDateBold = styled(ShiftDate)`
  font-weight: 400;
`;

const ShiftHours = styled(H7Regular)`
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  color: #252525;
  font-weight: 300;
`;
const Uniform = styled(H7Regular)`
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  color: #252525;
  font-weight: 300;
`;
const Wage = styled(H7Regular)`
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  color: #252525;
  font-weight: 300;
`;
const Notes = styled(H7Regular)`
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  color: #252525;
  font-weight: 300;
`;

const Dates = styled(BaseView)``;

const DatesTitle = styled(BaseText)`
  margin-bottom: 5px;
`;

const RowDetail = styled(FlexRow)`
  align-items: center;
  margin-bottom: 8px;
`;

const ButtonContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

const AppliedText = styled(BaseText)`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme, vPressed, xPressed }) =>
    xPressed
      ? theme.colors.gray13
      : vPressed
      ? theme.colors.surface
      : theme.colors.approve};
`;

const LoaderContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
`;

const TriggerText = styled(H7Regular)`
  font-size: 15px;
  font-weight: 500;
`;
const TriggerContainer = styled(BaseView)``;

const MoreDetailsContainer = styled(BaseView)`
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 15px;
  padding-left: 15px;
  background-color: ${({ theme }) => theme.colors.offwhitesmoke};
`;

const Image = styled(BaseImage)`
  width: 30px;
  height: 30px;
  border-radius: 15px;
`;

const ImageContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

interface Props {
  isAutoBook: boolean;
  shifts: InferGenericArray<IOffer['shifts']>[];
  refetchBookings: () => void;
  positionId: number;
  projectUniformId?: number;
  hideNotes: boolean;
  isShowOfferWage: boolean;
  currency?: string;
}

const ApplicationActions = ({ isAutoBook, shifts, refetchBookings }) => {
  const { t } = useTranslation();
  const {
    addBulkApplications,
    editBulkApplications,
    deleteBulkApplications,
    refetch,
    isAddingApplication,
    isDeletingApplication,
    isEditingApplication,
  } = useOffers();

  const { colors } = useTheme();

  const { rtl } = useContext(ConfigContext);
  const queryClient = useQueryClient();

  const isApplicationLoading = useMemo(
    () => isAddingApplication || isDeletingApplication || isEditingApplication,
    [isAddingApplication, isDeletingApplication, isEditingApplication]
  );

  const shiftsIds = shifts.map(({ id }) => id);
  const projectId = shifts[0]?.projectId;

  const allRejected = shifts.every(
    ({ application }) => !!application?.isRejected
  );
  const allApplied = shifts.every(
    ({ application }) => application?.isRejected === false
  );

  const applicationsIds = shifts
    .filter(({ application }) => !!application)
    .map(({ application }) => application.id);

  const [xPressed, vPressed] = useMemo(
    () => [allRejected === true, allApplied === true],
    [allApplied, allRejected]
  );

  const onVPress = useCallback(async () => {
    if (!xPressed && !vPressed) {
      if (isAutoBook) {
        const res = await uiAlert({
          title: t('autoBookAreYouSureAlertTitle'),
          save: {
            text: t('yes'),
          },
          cancel: {
            text: t('no'),
          },
        });
        if (!res) {
          return;
        }
      }

      try {
        await addBulkApplications({
          projectId,
          isRejected: false,
          shiftsIds,
        });
      } catch (e) {
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) => queryKey?.[0] === 'offers',
        });
        if (isAutoBook) {
          await uiAlert({
            title: t('autoBookErrorAlert'),
            save: {
              text: t('ok'),
            },
          });
        } else {
          await uiAlert({
            title: t('applyErrorAlert'),
            save: {
              text: t('ok'),
            },
          });
        }
        return;
      }

      if (isAutoBook) {
        await Promise.all([refetch(), refetchBookings()]);

        return;
      }
    }

    if (xPressed) {
      try {
        await editBulkApplications({
          projectId,
          applicationsIds,
          isRejected: false,
          shiftsIds,
        });
      } catch (e) {
        await Promise.all([refetch(), refetchBookings()]);
        Toast.show({ text1: t('somethingWentWrongAlert'), type: 'error' });
      }
    }

    if (vPressed) {
      try {
        await deleteBulkApplications({ projectId, applicationsIds, shiftsIds });
      } catch (e) {
        await Promise.all([refetch(), refetchBookings()]);
        Toast.show({ text1: t('somethingWentWrongAlert'), type: 'error' });
      }
    }
  }, [
    xPressed,
    vPressed,
    isAutoBook,
    t,
    addBulkApplications,
    projectId,
    shiftsIds,
    queryClient,
    refetch,
    refetchBookings,
    editBulkApplications,
    applicationsIds,
    deleteBulkApplications,
  ]);

  const onXPress = useCallback(async () => {
    if (!xPressed && !vPressed) {
      await addBulkApplications({
        projectId,
        isRejected: true,
        shiftsIds,
      });
    }

    if (vPressed) {
      try {
        await editBulkApplications({
          projectId,
          applicationsIds,
          isRejected: true,
          shiftsIds,
        });
      } catch (e) {
        await Promise.all([refetch(), refetchBookings()]);
        Toast.show({ text1: t('somethingWentWrongAlert'), type: 'error' });
      }
    }
    if (xPressed) {
      try {
        await deleteBulkApplications({ projectId, applicationsIds, shiftsIds });
      } catch (e) {
        await Promise.all([refetch(), refetchBookings()]);
        Toast.show({ text1: t('somethingWentWrongAlert'), type: 'error' });
      }
    }
  }, [
    xPressed,
    vPressed,
    addBulkApplications,
    projectId,
    shiftsIds,
    editBulkApplications,
    applicationsIds,
    refetch,
    refetchBookings,
    t,
    deleteBulkApplications,
  ]);

  return (
    <Actions isApplicationLoading={isApplicationLoading} rtl={rtl}>
      {isApplicationLoading && (
        <LoaderContainer>
          <ActivityIndicator color={colors.primary} size="small" />
        </LoaderContainer>
      )}
      {!isApplicationLoading && (
        <BaseView>
          <ButtonContainer>
            {!isAutoBook && (
              <Button
                type="primaryReversed"
                title=""
                paddingVertical={Platform.OS === 'android' ? 8 : 10}
                paddingHorizontal={10}
                radius={20}
                style={[
                  xPressed
                    ? {
                        backgroundColor: colors.decline2,
                        borderColor: colors.decline2,
                      }
                    : { borderColor: colors.gray13 },
                  { shadowOpacity: 0 },
                ]}
                onPress={onXPress}
              >
                <SvgIcon
                  xml={
                    xPressed
                      ? icons.actionIcons.xSecondary
                      : icons.actionIcons.xDisabled
                  }
                  width={16}
                  height={16}
                  color={xPressed ? colors.surface : colors.gray18}
                />
              </Button>
            )}
            <Button
              type="primaryReversed"
              title=""
              style={[
                vPressed
                  ? {
                      backgroundColor: colors.approve,
                      borderColor: colors.approve,
                    }
                  : xPressed
                  ? { borderColor: colors.gray13 }
                  : { borderColor: colors.approve },
                { shadowOpacity: 0, marginLeft: 10 },
              ]}
              onPress={onVPress}
              radius={20}
              paddingHorizontal={10}
              paddingVertical={Platform.OS === 'android' ? 8 : 10}
            >
              <AppliedText vPressed={vPressed} xPressed={xPressed}>
                {vPressed
                  ? t('applied')
                  : isAutoBook
                  ? t('offerTakeJob')
                  : t('apply')}
              </AppliedText>
            </Button>
          </ButtonContainer>
        </BaseView>
      )}
    </Actions>
  );
};

export const EntireProjectApplyShiftCard: FC<Props> = React.memo(
  ({
    isAutoBook,
    refetchBookings,
    projectUniformId,
    shifts,
    positionId,
    hideNotes = false,
    isShowOfferWage = false,
    currency = '',
  }) => {
    const { dateFormat } = useContext(ConfigContext);
    const { colors } = useTheme();
    const { mappedPositions } = usePositions();
    const { mappedUniforms } = useUniforms();
    const { navigate } = useNavigation();

    const { t } = useTranslation();

    const shiftsDatesByPosition = useMemo(
      () =>
        (shifts || []).map(
          ({ startTime, endTime, comments, wage, uniformId }) => ({
            date: moment(startTime).format(API_DATE_FORMAT),
            startTime: moment(startTime).format(API_DATE_TIME_FORMAT),
            endTime: moment(endTime).format(API_DATE_TIME_FORMAT),
            uniformId,
            comments,
            wage,
          })
        ),
      [shifts]
    );

    const shiftsDatesOnlyByPositionSet = useMemo(
      () =>
        Array.from(
          new Set(
            (shifts || []).map(({ startTime }) =>
              moment(startTime).format(API_DATE_FORMAT)
            )
          )
        ),
      [shifts]
    );

    const openGallery = useCallback(
      (uri) => {
        navigate(FeedScreenNames.GALLERY, {
          items: [
            {
              idx: 0,
              metadata: {},
              type: 'image',
              uri,
            },
          ],
          idx: 0,
        });
      },
      [navigate]
    );

    const Trigger = () => (
      <TriggerContainer>
        <TriggerText>{t('details')}</TriggerText>
      </TriggerContainer>
    );

    return (
      <Container>
        <CardContent>
          <ShiftDetails>
            <>
              {positionId && positionId !== EMPTY_POSITION_ID && (
                <RowDetail>
                  <IconContainer>
                    <SvgIcon
                      xml={icons.uiIcons.jobCase}
                      width={22}
                      height={22}
                      color={colors.gray1}
                    />
                  </IconContainer>
                  <PositionName>
                    {t(mappedPositions[positionId]?.slug)}
                  </PositionName>
                </RowDetail>
              )}

              <DatesTitle>{t('availableDates')}:</DatesTitle>
              <Dates>
                {shiftsDatesOnlyByPositionSet?.map((date) => (
                  <ShiftDate key={date}>
                    {moment(date).format(`dddd, ${dateFormat}`)}
                  </ShiftDate>
                ))}
              </Dates>
            </>
          </ShiftDetails>

          <ApplicationActions
            isAutoBook={isAutoBook}
            shifts={shifts}
            refetchBookings={refetchBookings}
          />
        </CardContent>

        <BaseCollapsible
          trigger={Trigger}
          withBorder={false}
          triggerStyle={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
          containerStyle={{ marginBottom: 10, marginTop: 7 }}
        >
          <MoreDetailsContainer>
            {positionId &&
              positionId !== -1 &&
              !!mappedPositions[positionId]?.jobDescription && (
                <ShiftJobDescription>
                  <Markdown style={{ text: { lineHeight: 24 } }}>
                    {mappedPositions[positionId].jobDescription}
                  </Markdown>
                </ShiftJobDescription>
              )}
            <Dates>
              {shiftsDatesByPosition?.map(
                (
                  { date, startTime, endTime, comments, wage, uniformId },
                  index
                ) => (
                  <>
                    <ShiftDateBold key={`date-${index}`}>
                      {moment(date).format(`dddd, ${dateFormat}`)}
                    </ShiftDateBold>
                    <ShiftHours key={index}>
                      {`${moment(startTime).format('LT')}${
                        endTime ? ` - ${moment(endTime).format('LT')}` : ''
                      }`}
                    </ShiftHours>
                    {uniformId && uniformId !== projectUniformId && (
                      <FlexRowBetween>
                        <Uniform>
                          {mappedUniforms[uniformId]?.name}
                          {': '}
                          {mappedUniforms[uniformId]?.description}
                        </Uniform>
                        {mappedUniforms[uniformId]?.image && (
                          <ImageContainer
                            as={RipplePressable}
                            onPress={() =>
                              openGallery(mappedUniforms[uniformId]?.image)
                            }
                          >
                            <Image
                              source={{
                                uri: mappedUniforms[uniformId]?.image!,
                              }}
                            />
                          </ImageContainer>
                        )}
                      </FlexRowBetween>
                    )}
                    {isShowOfferWage && !!wage?.flatWage && (
                      <Wage>
                        {currency}
                        {wage?.flatWage}/{t('flat')} {t('wageEstimated')}
                      </Wage>
                    )}
                    {isShowOfferWage &&
                      !!wage?.hourlyWage &&
                      !wage?.flatWage && (
                        <Wage>
                          {currency}
                          {wage?.hourlyWage}/{t('hour')} {t('wageEstimated')}
                        </Wage>
                      )}
                    {!hideNotes && !!comments && <Notes>{comments}</Notes>}
                    {index !== shiftsDatesByPosition?.length - 1 && <Divider />}
                  </>
                )
              )}
            </Dates>
          </MoreDetailsContainer>
        </BaseCollapsible>
      </Container>
    );
  }
);
