import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from '../locale';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    returnEmptyString: false,
    compatibilityJSON: 'v3',
    resources: (resources || []).reduce((all, { key, lng }) => {
      all[key] = lng;
      return all;
    }, {} as Record<string, any>),
    lng: 'en',
    fallbackLng: {
      ...(resources || []).reduce((all, { key, fallback }) => {
        all[key] = fallback;
        return all;
      }, {} as Record<string, any>),
      default: ['en'],
    },

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });
