import { Platform } from 'react-native';
import styled from 'styled-components/native';

export const BaseView = styled.View``;

export const FlexParent = styled.View<{ rtl?: boolean; pressed?: boolean }>`
  display: flex;
  flex-direction: ${({ rtl = false }) =>
    rtl === true ? 'row-reverse' : 'row'};
`;
export const FlexColumn = styled(FlexParent)`
  flex-direction: column;
  flex: 1;
`;
export const FlexColumn2 = styled(FlexParent)`
  flex-direction: column;
  flex: 2;
`;
export const FlexColumn3 = styled(FlexParent)`
  flex-direction: column;
  flex: 3;
`;

export const FlexColumnDirection = styled(BaseView)`
  display: flex;
  flex-direction: column;
`;

export const FlexCenter = styled(FlexParent)`
  justify-content: center;
  align-items: center;
`;

export const FlexRowEvenly = styled(FlexParent)`
  justify-content: space-evenly;
  align-items: center;
`;

export const FlexRowAround = styled(FlexParent)`
  justify-content: space-around;
  align-items: center;
`;

export const FlexRow = styled(FlexParent)``;

export const FlexRowBetween = styled(FlexParent)`
  justify-content: space-between;
  align-items: center;
`;

export const FlexColumnBetween = styled(FlexColumnDirection)`
  justify-content: space-between;
  align-items: center;
`;

export const FlexRowCenter = styled(FlexParent)`
  align-items: center;
`;

export const FlexColumnCenter = styled(FlexColumnDirection)`
  justify-content: center;
  align-items: center;
`;

export const FlexColumnEvenly = styled(FlexColumnDirection)`
  justify-content: space-evenly;
  align-items: center;
`;

export const WideFlexColumnCenter = styled(FlexColumnCenter)`
  flex: 1;
`;

export const WideFlexColumnStart = styled(FlexColumnDirection)`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

export const WideFlexCenter = styled(FlexCenter)`
  flex: 1;
`;

export const WideFlexBetween = styled(FlexRowBetween)`
  flex: 1;
`;

export const WideFlexParent = styled(FlexParent)`
  flex: 1;
`;

interface IAbsolute {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
  rtl?: boolean;
}
export const Absolute = ({
  top,
  bottom,
  right,
  left,
  rtl,
}: IAbsolute = {}) => styled(BaseView)`
  position: absolute;
  ${top ? `top: ${String(top)}px;` : ''}
  ${bottom ? `bottom: ${String(bottom)}px;` : ''}
	${right ? `${rtl ? 'left' : 'right'}: ${String(right)}px;` : ''}
	${left ? `${rtl ? 'right' : 'left'}: ${String(left)}px;` : ''}
`;

export const TAB_BAR_HEIGHT = Platform.OS === 'ios' ? 85 : 60;
