import React, { FC, useContext } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  SvgIcon,
  FlexRowBetween,
  H5Light,
  ChevronRight,
  IconClose,
} from '@violetta/ubeya/ui';
import { Platform, Pressable } from 'react-native';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../contexts';

const Container = styled(FlexRowBetween)`
  padding: 20px;
`;

const Title = styled(H5Light)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #1e2534;
`;

interface Props {
  onClose;
}

export const TimeAvailabilityModalHeader: FC<Props> = ({ onClose }) => {
  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('addPreferencesConstraint')}</Title>
      {Platform.OS === 'web' && (
        <Pressable onPress={onClose}>
          <IconClose color="#000" size={22} onPress={onClose} />
        </Pressable>
      )}
    </Container>
  );
};
