import AsyncStorage from '@react-native-async-storage/async-storage';
import CookieManager from '@react-native-cookies/cookies';
import { Platform } from 'react-native';

export const initCookies = async () => {
  const keys = await AsyncStorage.getAllKeys();

  return Promise.all(
    keys
      .filter((key) => key.startsWith('cookie_'))
      .map(async (key) => {
        const [_, url] = key.split('cookie_');
        const cookies = JSON.parse((await AsyncStorage.getItem(key))!);
        return Promise.all(
          Object.keys(cookies).map((key) =>
            CookieManager.set(url, cookies[key])
          )
        );
      })
  );
};

export const clearCookies = async () => {
  if (Platform.OS !== 'web') {
    await CookieManager.clearAll();
  }
  return Promise.all(
    (await AsyncStorage.getAllKeys())
      .filter((key) => key.startsWith('cookie_'))
      .map((key) => AsyncStorage.removeItem(key))
  );
};

export const clearCookie = async (type: 'admin' | 'user') => {
  await CookieManager.clearByName(`${type}_access_token`);
  await CookieManager.clearByName(`${type}_refresh_token`);
  Promise.all(
    (await AsyncStorage.getAllKeys())
      .filter((key) => key.startsWith('cookie_'))
      .map((key) => AsyncStorage.removeItem(key))
  );
};
