import { useCallback } from 'react';
import { Alert, AlertButton } from 'react-native';

interface Props {
  title: string;
  message?: string;
  options: (AlertButton & { type: 'confirm' | 'cancel' })[];
}

export const useAlert = () => {
  const createAlert = useCallback(({ message, options, title }: Props) => {
    Alert.alert(title, message, options);
  }, []);

  return {
    createAlert,
  };
};
