import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderlessBar } from '@violetta/ubeya/ui';
import { useTheme } from 'styled-components/native';
import { SettingsHeader } from '../components/headers/SettingsHeader';
import { SettingsScreen } from '../screens';
import { SettingsScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const SettingsNavigation: FC = () => {
  const { colors } = useTheme();

  return (
    <Navigator
      initialRouteName={SettingsScreenNames.SETTINGS}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
      }}
    >
      <Screen
        name={SettingsScreenNames.SETTINGS}
        component={SettingsScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <SettingsHeader {...props} />
          ),
        }}
      />
    </Navigator>
  );
};
