import React, { FC, useCallback, useMemo, useRef } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import moment from 'moment';
import { Pressable } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import styled, { useTheme } from 'styled-components/native';
import { IconClose, SvgIcon } from '../Icons';
import { BaseView, FlexRow, FlexRowBetween } from '../Layout';
import { InputBox } from '../TextInputs';
import { BaseText, H3Bold } from '../Typography';
import { DateRangePicker } from './DateRangePicker';

const Container = styled(BaseView)``;
const DateContainer = styled(FlexRow)``;

const Preview = styled(BaseView)`
  flex: 1;
  display: flex;
`;

const PreviewTextBase = styled(BaseText)`
  font-size: 17px;
  padding-left: 12px;
  padding-left: 12px;
`;
const PreviewText = styled(PreviewTextBase)`
  color: ${({ theme }) => theme.colors.inputText};
`;
const PreviewTextPlaceholder = styled(PreviewTextBase)`
  color: ${({ theme }) => theme.colors.gray3};
`;

const Divider = styled(BaseView)`
  padding: 0 10px;
`;

const ModalHeader = styled(FlexRowBetween)`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 12px 20px 20px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const RightHeader = styled(FlexRow)`
  justify-content: flex-end;
  align-items: center;
`;

const HeaderIcon = styled(BaseView)`
  margin-left: 15px;
`;

export type ValueParams = {
  start: Date | null;
  end: Date | null;
};

export interface Props {
  onChange?: (value: ValueParams) => void;
  value?: ValueParams;
}

export const DateRangeInputPicker: FC<Props> = ({ onChange, value }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const modalizeRef = useRef<Modalize>(null);

  const open = useCallback(() => {
    modalizeRef.current.open();
  }, []);

  const close = useCallback(() => {
    modalizeRef.current.close();
  }, []);

  const clear = useCallback(
    () =>
      onChange?.({
        start: null,
        end: null,
      }),
    [onChange]
  );

  const Header = useMemo(
    () => (
      <ModalHeader>
        <H3Bold>{t('datePickerModalHeader')}</H3Bold>
        <RightHeader>
          <HeaderIcon as={Pressable} onPress={close}>
            <SvgIcon xml={icons.actionIcons.done} height={20} width={20} />
          </HeaderIcon>
          <HeaderIcon as={Pressable} onPress={clear}>
            <SvgIcon xml={icons.actionIcons.trash} height={20} width={20} />
          </HeaderIcon>
          <HeaderIcon>
            <IconClose color="#000" size={22} onPress={close} />
          </HeaderIcon>
        </RightHeader>
      </ModalHeader>
    ),
    [clear, close, t]
  );

  return (
    <Container>
      <DateContainer as={Pressable} onPress={open}>
        <Preview>
          <InputBox
            icon={{ xml: icons.uiIcons.calendar, width: 20, height: 20 }}
            onPress={open}
          >
            {value?.start ? (
              <PreviewText>
                {moment(value?.start).format('ddd D MMM')}
              </PreviewText>
            ) : (
              <PreviewTextPlaceholder>{t('begin')}</PreviewTextPlaceholder>
            )}
          </InputBox>
        </Preview>
        <Divider />
        <Preview>
          <InputBox
            icon={{ xml: icons.uiIcons.calendar, width: 20, height: 20 }}
            onPress={open}
          >
            {value?.end ? (
              <PreviewText>
                {moment(value?.end).format('ddd D MMM')}
              </PreviewText>
            ) : (
              <PreviewTextPlaceholder>{t('end')}</PreviewTextPlaceholder>
            )}
          </InputBox>
        </Preview>
      </DateContainer>
      <Portal>
        <Modalize
          scrollViewProps={{
            contentContainerStyle: {
              flex: 1,
            },
            scrollEnabled: false,
          }}
          onOverlayPress={close}
          onBackButtonPress={close}
          ref={modalizeRef}
          handlePosition="inside"
          HeaderComponent={Header}
        >
          <DateRangePicker
            onSelected={onChange}
            value={value}
            color={theme.colors.primary}
          />
        </Modalize>
      </Portal>
    </Container>
  );
};
