import React, { FC, useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@violetta/ubeya/i18n';
import { getFileExtension, isFileImage, uiAlert } from '@violetta/ubeya/utils';
import { Linking } from 'react-native';
import styled from 'styled-components/native';
import { RipplePressable, BaseView, FlexColumnCenter } from '../Layout';
import { BaseText } from '../Typography';
import AttachmentActionsRenderer from './AttachmentActionsRenderer';
import { EmployeeDocumentPreview } from './EmployeeDocumentPreview';

const Document = styled(BaseView)`
  height: 150px;
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
`;

const DocumentData = styled(BaseView)`
  margin-left: 3px;
  justify-content: space-between;
`;

const DocumentTitle = styled(BaseText)`
  font-size: 10px;
  font-weight: normal;
  color: #252525;
  max-width: 110px;
  margin-bottom: 4px;
`;

const DocumentType = styled(BaseText)`
  font-size: 10px;
  font-weight: normal;
  color: #858585;
`;

const PreviewWrapper = styled(FlexColumnCenter)``;

interface Props {
  document: any;
  openAttachmentImagesGallery: (link: string) => void;
  onRemoveItem?: (value: any) => void;
}

export const ComplianceDocumentCard: FC<Props> = ({
  document,
  openAttachmentImagesGallery,
  onRemoveItem,
}) => {
  const ext = useMemo(
    () => document?.link && getFileExtension(document?.link)?.toUpperCase(),
    [document?.link]
  );
  const isImage = useMemo(
    () => document?.link && isFileImage(document?.link),
    [document?.link]
  );

  const { t } = useTranslation();
  const { navigate } = useNavigation();

  const openFile = useCallback(() => {
    if (!document?.link) {
      uiAlert({ title: t('unknown'), save: { text: t('ok') } });
      return;
    }
    if (isImage) {
      return openAttachmentImagesGallery(document);
    }
    if (ext === 'PDF') {
      return navigate('pdf-reader-nav', {
        screen: 'pdf-reader',
        params: {
          uri: document?.link,
        },
      });
    }
    return Linking.openURL(document?.link);
  }, [document, ext, isImage, navigate, openAttachmentImagesGallery, t]);

  const fieldValue = useMemo(
    () =>
      document
        ? {
            value: [
              {
                link: document?.link,
                expirationDate: document?.expirationDate,
              },
            ],
          }
        : null,
    [document]
  );

  const handleRemoveItem = useCallback(
    () => onRemoveItem && onRemoveItem({ item: document }),
    [document, onRemoveItem]
  );

  if (fieldValue) {
    return (
      <PreviewWrapper>
        <DocumentTitle numberOfLines={1}>{document?.title || ''}</DocumentTitle>

        <EmployeeDocumentPreview
          field={fieldValue}
          style={{ height: 250, width: 250 }}
          showWarningExpired
          useLargeIcon
        />
        <AttachmentActionsRenderer
          onDelete={handleRemoveItem}
          onPreview={openFile}
          showPreview
        />
      </PreviewWrapper>
    );
  }

  return (
    <Document as={RipplePressable} onPress={openFile}>
      <DocumentData>
        <DocumentTitle numberOfLines={1}>{document?.title || ''}</DocumentTitle>
        <DocumentType>{ext || ''}</DocumentType>
      </DocumentData>
    </Document>
  );
};
