import { Alert, AlertButton, Platform } from 'react-native';

type Options = {
  title?: string;
  message?: string;
  cancel?: {
    onCancel?: () => void;
    text?: string;
  };
  save?: {
    onSave?: () => void;
    text?: string;
  };
};

export const uiAlert = ({ message = '', title = '', cancel, save }: Options) =>
  new Promise<boolean>((resolve) => {
    const buttons = [] as AlertButton[];

    if (cancel) {
      buttons.push({
        text: cancel?.text,
        type: 'cancel',
        onPress: () => {
          cancel?.onCancel?.();
          resolve(false);
        },
      });
    }

    if (save) {
      buttons.push({
        text: save?.text,
        type: 'confirm',
        onPress: () => {
          save?.onSave?.();
          resolve(true);
        },
      });
    }

    if (Platform.OS === 'web') {
      if (confirm(`${title} ${message ? ` - ${message} :` : ''}`)) {
        save?.onSave?.();
        resolve(true);
      } else {
        cancel?.onCancel?.();
        resolve(false);
      }
    } else {
      Alert.alert(title, message, buttons, { cancelable: false });
    }
  });
