import { useTranslation } from '@violetta/ubeya/i18n';
import { Card, H3Bold } from '@violetta/ubeya/ui';
import React, { FC } from 'react';
import styled from 'styled-components/native';

const Box = styled(Card)`
  padding: 10px 20px;
  display: flex;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  height: 178px;
`;
const Title = styled(H3Bold)`
  margin: 15px 0;
  color: ${({ theme }) => theme.colors.white};
`;

interface Props {}

export const AccountCard: FC<Props> = ({}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Title>Holly's</Title>
    </Box>
  );
};
