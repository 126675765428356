import React, { FC } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import styled from 'styled-components/native';
import { BaseView } from '../Layout';
import { H7Medium } from '../Typography';

const NewChip = styled(BaseView)<{ color: string }>`
  background-color: ${({ theme, color }) => color || theme.colors.yellow1};
  padding: 4px 14px;
  border-radius: 16px;
  position: absolute;
  right: 0;
`;

const NewChipText = styled(H7Medium)`
  color: ${({ theme }) => theme.colors.surface};
  font-size: 13px;
  font-weight: normal;
  text-align: center;
`;

interface Props {
  text: string;
  color?: string;
  style?: StyleProp<ViewStyle>;
}

export const NotificationChip: FC<Props> = React.memo(({ text, color, style }) => (
  <NewChip color={color} style={style}>
    <NewChipText>{text}</NewChipText>
  </NewChip>
));
