import React, { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { IBreaktime, useTimesheets } from '@violetta/ubeya/entities';
import { useQueryClient, useMutation } from '@tanstack/react-query';

export const useBreaktimes = (timesheetId: number) => {
  const queryClient = useQueryClient();
  const { timesheets } = useTimesheets();

  const breaktimes = useMemo(() => {
    const timesheet = timesheets!.find(({ id }) => id === timesheetId);
    return (
      timesheet && timesheet.breaktimes ? timesheet.breaktimes : []
    ) as IBreaktime[];
  }, [timesheets, timesheetId]);

  const { mutateAsync: startBreaktime } = useMutation<IBreaktime, {}, {}>({
    mutationFn: ({ breakTypeId }: { breakTypeId?: number }) => {
      return api.addBreaktime({ timesheetId, breakTypeId });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['timesheets'] });
    },
  });

  const { mutateAsync: endBreaktime } = useMutation<
    IBreaktime,
    {},
    { breaktimeId: number }
  >({
    mutationFn: ({ breaktimeId }: { breaktimeId: number }) =>
      api.editBreaktime({ timesheetId, breaktimeId }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['timesheets'] });
    },
  });

  return {
    breaktimes,
    startBreaktime,
    endBreaktime,
  };
};
