import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FlexRowBetween,
  H5Light,
  SearchBox,
} from '@violetta/ubeya/ui';
import React, { FC } from 'react';
import styled from 'styled-components/native';

const Container = styled(FlexRowBetween)`
  padding: 20px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
  width: 100%;
`;

const Title = styled(H5Light)`
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: #1e2534;
`;

const SearchContainer = styled(BaseView)`
  padding: 2px;
  width: 60%;
`;

interface Props {
  setSearch: (value: string) => void;
  search: string;
}

export const LiveViewFilterModalHeader: FC<Props> = ({ search, setSearch }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('filterBy')}</Title>
      <SearchContainer>
        <SearchBox
          autoCorrect={false}
          onChange={setSearch}
          searchTerm={search}
          placeholder={t('search') || ''}
        />
      </SearchContainer>
    </Container>
  );
};
