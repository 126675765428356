import React, { FC } from 'react';
import styled from 'styled-components';
import { icons } from '@violetta/ubeya/assets';
import { Pressable, TouchableHighlight } from 'react-native';
import { SvgIcon } from '../Icons/SvgIcon';
import { FlexRowEvenly, FlexRowBetween } from '../Layout';

const Container = styled(FlexRowBetween)``;

type Props = {
  value?: number;
  total?: number;
  onChange?: (value: number | undefined) => void;
};

export const StarRating: FC<Props> = ({
  total = 5,
  value = 0,
  onChange = () => {},
}) => (
  <Container>
    {value > 0 &&
      [...Array(value)].map((_, i) => (
        <TouchableHighlight
          onPress={() => onChange(Number(i + 1))}
          key={i}
          activeOpacity={0.5}
          underlayColor={'#fff'}
        >
          <SvgIcon
            xml={icons.actionIcons.rateStarFull}
            width={20}
            height={20}
          />
        </TouchableHighlight>
      ))}
    {[...Array(total - value)].map((_, i) => (
      <TouchableHighlight
        onPress={() => onChange(Number(i + Number(value) + 1))}
        key={i}
      >
        <SvgIcon xml={icons.actionIcons.rateStarEmpty} width={20} height={20} />
      </TouchableHighlight>
    ))}
  </Container>
);
