import React, { FC } from 'react';
import { BaseView, FlexColumnDirection, H5Regular } from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';

const Reports = styled(BaseView)`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  padding: 10px 0;
`;

const Timestamps = styled(FlexColumnDirection)`
  width: 100px;
  align-items: center;
`;
const Titles = styled(FlexColumnDirection)``;
const Title = styled(H5Regular)`
  margin-top: 10px;
  height: 30px;
`;

const Timestamp = styled(H5Regular)`
  margin-top: 10px;
  font-weight: 500;
  height: 30px;
`;

interface IReport {
  timestamp: Date;
  title: string;
}

interface Props {
  reports: IReport[];
}

export const TimesheetBreakdown: FC<Props> = ({ reports }) => (
  <Reports>
    <Timestamps>
      {reports.map(({ timestamp }, idx) => (
        <Timestamp key={idx}>{moment(timestamp).format('LT')}</Timestamp>
      ))}
    </Timestamps>
    <Titles>
      {reports.map(({ title }, idx) => (
        <Title key={idx}>{title}</Title>
      ))}
    </Titles>
  </Reports>
);
