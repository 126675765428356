import { useNavigation } from '@react-navigation/core';
import { useAccounts } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, SwiperView } from '@violetta/ubeya/ui';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import { ProfileScreenNames } from '../../../profile';
import { CardTitle } from '../CardTitle/CardTitle';
import { EmployerSwiperCard } from './EmployerSwiperCard';

type Props = {};

export const EmployersCard: FC<Props> = React.memo(() => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { accounts = [] } = useAccounts();
  const { rtl } = useContext(ConfigContext);

  const navigateAccount = useCallback(
    (accountId: number) => {
      navigate(ProfileScreenNames.MAIN, {
        screen: ProfileScreenNames.ACCOUNT_PROFILE,
        params: { accountId },
      });
    },
    [navigate]
  );

  const handleShowAllTodos = useCallback(() => {
    navigate(ProfileScreenNames.MAIN);
  }, [navigate]);

  const formattedAccounts = useMemo(
    () =>
      accounts.map((account) => ({
        ...account,
        onButtonPress: () => navigateAccount(account.id),
        onCardPress: () => navigateAccount(account.id),
      })),
    [accounts, navigateAccount]
  );

  if ((accounts || []).length === 0) {
    return null;
  }

  return (
    <BaseView>
      <CardTitle
        title={t('employers')}
        count={(formattedAccounts || []).length}
        onShowAllPress={handleShowAllTodos}
      />
      <SwiperView
        maxNumOfItems={formattedAccounts.length}
        cards={formattedAccounts}
        changeEveryInterval
        rtl={rtl}
        initialIndex={0}
        hideLastItem
        swipeComponent={(item, i) => (
          <EmployerSwiperCard key={i} account={item} />
        )}
        onLastItemPress={handleShowAllTodos}
      />
    </BaseView>
  );
});
