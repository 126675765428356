import {
  useMutation,
  useQueryClient,
  MutationFunction,
  UseMutationOptions,
} from '@tanstack/react-query';

interface Params<TResult, DataStructure = {}, TVariables = {}> {
  refetchOnSuccess?: boolean;
  refetchOnError?: boolean;
  mutateOnSettled?: boolean;
  mutateOnlyOnSuccess?: boolean;
  shouldRollbackOnError?: boolean;
  onSuccess?: (
    newData: TResult | undefined,
    previousData: DataStructure,
    variables: TVariables
  ) => void;
  onSettled?: (
    newData: TResult | undefined,
    error: TError | null,
    payload: TVariables
  ) => void;
}

type MergeData<DataStructure, TVariables, TResult> = (props: {
  previousData: DataStructure;
  payload: TVariables;
  newData?: TResult;
}) => DataStructure;

export const useOptimisticMutation = <
  DataStructure,
  TVariables,
  TResult,
  TError = {}
>(
  key: string[],
  fetch: MutationFunction<TResult, TVariables>,
  mutateData?: MergeData<DataStructure, TVariables, TResult>,
  {
    refetchOnSuccess = true,
    refetchOnError = true,
    mutateOnSettled = false,
    mutateOnlyOnSuccess = false,
    shouldRollbackOnError = true,
    onSuccess,
    onSettled,
  }: Params<TResult, DataStructure, TVariables> = {},
  config?: UseMutationOptions<TResult, TError, TVariables, () => void>
) => {
  const queryClient = useQueryClient();
  const mutate = useMutation<TResult, TError, TVariables, () => void>({
    networkMode: 'online',
    mutationKey: key,
    retry: 5,
    mutationFn: fetch,
    onMutate: (payload: TVariables) => {
      const previousData = queryClient.getQueryData<DataStructure>(key)!;

      const nextData = (() => {
        if (mutateOnlyOnSuccess) {
          return previousData;
        }
        return mutateData?.({ previousData, payload }) || previousData;
      })();
      queryClient.setQueryData(key, nextData);

      return () => queryClient.setQueryData(key, previousData);
    },
    // eslint-disable-next-line handle-callback-err
    // onError: (err, newData, rollback) => {
    //   if (shouldRollbackOnError) {
    //     rollback();
    //   }
    // },
    onSettled: (
      newData: TResult | undefined = undefined,
      error: TError | null,
      payload: TVariables
    ) => {
      const previousData = queryClient.getQueryData<DataStructure>(key)!;

      if (!error && onSuccess) {
        onSuccess(newData, previousData, payload);
      }
      if (mutateOnSettled) {
        const nextData =
          mutateData?.({ previousData, payload, newData }) || previousData;
        queryClient.setQueryData(key, nextData);
      }

      if ((!error && refetchOnSuccess) || (error && refetchOnError)) {
        // return queryClient.invalidateQueries(key, { exact: true });
      }
      onSettled?.(newData, error, payload);
    },
    ...config,
  });

  return mutate;
};
