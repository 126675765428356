import React, { FC } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { FlexRowCenter } from '../Layout';
import { H5Medium } from '../Typography';

const ButtonContainer = styled(FlexRowCenter)`
	width: 80px;
	height: 80px;
	justify-content: center;
	border-radius: 40px;
	background-color: ${({ theme }) => theme.colors.primary};
`;

const ButtonTitle = styled(H5Medium)`
	font-weight: 500;
	color: ${({ theme }) => theme.colors.lightSurface};
`;

interface Props {
	title?: string;
	onPress?: () => void;
}

export const ClockButton: FC<Props> = ({ title, onPress }) => (
		<Pressable onPress={onPress}>
			<ButtonContainer>
				<ButtonTitle>{title}</ButtonTitle>
			</ButtonContainer>
		</Pressable>
	);
