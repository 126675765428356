import 'react-native-gesture-handler';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NetInfo from '@react-native-community/netinfo';
import { Text } from 'react-native';
import {
  api,
  refreshTokenInterceptorCreator,
  appInfoInterceptorCreator,
  sendError,
} from '@violetta/ubeya/api';
import { initCookies, useUser } from '@violetta/ubeya/auth';
import { useAccounts } from '@violetta/ubeya/entities';
import {
  useLocale,
  useTranslatedApp,
  useTranslation,
} from '@violetta/ubeya/i18n';
import { BaseView, MainLoader } from '@violetta/ubeya/ui';
import { HttpMultiton, useLoader } from '@violetta/ubeya/utils';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/ar';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/he';
import 'moment/locale/nl';
import { ErrorBoundary } from 'react-error-boundary';
import Toast from 'react-native-toast-message';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import styled, { ThemeProvider } from 'styled-components/native';
import { Config, ConfigContext, ConfigContextProvider } from './contexts';
import { toastConfig } from './contexts/toastConfig';
import { NetworkErrorNavigation } from './modules/errors';
import { LoginNavigation } from './modules/login';
import {
  APP_VERSION,
  AuthorizedApp,
  DefaultView,
  DEFAULT_VIEW_ASYNC_STORE_KEY,
  DEFAULT_VIEW_STAFF_ASYNC_STORE_VALUE,
  TIME_FORMAT,
} from './modules/main';
import { SplashScreen } from './SplashScreen';
import {
  offerCalendarMonthAtom,
  showSplashAtom,
  timeAvailabilitySelectedWeek,
  weeklyScheduleSelectedWeekAtom,
} from './state';
import { theme } from './style';
import { config } from './modules/main/appConfig';
import NativeConfig from 'react-native-config';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

// User interceptor
const {
  onResponseFailed: onUserResponseFailed,
  onResponseSuccess: onUserResponseSuccess,
} = refreshTokenInterceptorCreator({
  axios,
  refreshToken: api.refreshToken,
});
axios.interceptors.response.use(onUserResponseSuccess, onUserResponseFailed);
const appInfoInterceptor = appInfoInterceptorCreator(config.appName);
axios.interceptors.request.use(appInfoInterceptor);

const LoaderWrapper = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const BaseContainer = styled(BaseView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

interface PropsAppDataContainer {
  setConfig: Dispatch<SetStateAction<Config>>;
  isLoading: boolean;
}
interface Props {
  setConfig: Dispatch<SetStateAction<Config>>;
  defaultView: DefaultView;
}

const AppShell: FC<Props> = ({ setConfig, defaultView }) => {
  const {
    loader: { hide, show },
  } = useContext(ConfigContext);
  const { isSuccess, isError, isLoading, languageId, error } = useUser();

  const [showSplash, setShowSplash] = useRecoilState(showSplashAtom);
  const [, setSelectedTimeAvailabilityWeek] = useRecoilState(
    timeAvailabilitySelectedWeek
  );
  const [, setSelectedWeeklyScheduleWeek] = useRecoilState(
    weeklyScheduleSelectedWeekAtom
  );
  const [, setSelectedWeeksetOfferCalendarMonth] = useRecoilState(
    offerCalendarMonthAtom
  );

  const { isConnected } = NetInfo.useNetInfo();

  const rtl = useTranslatedApp();
  const { isInitialLoading: isAccountsLoading } = useAccounts();
  const momentLocale = moment.locale();
  useEffect(() => {
    setSelectedTimeAvailabilityWeek({
      date: moment().startOf('week').toDate(),
    });
    setSelectedWeeklyScheduleWeek({ date: moment().startOf('week').toDate() });
    setSelectedWeeksetOfferCalendarMonth(moment().format('MMM YYYY'));
  }, [
    momentLocale,
    setSelectedTimeAvailabilityWeek,
    setSelectedWeeklyScheduleWeek,
    setSelectedWeeksetOfferCalendarMonth,
  ]);

  useEffect(() => {
    setConfig((prev) => ({ ...prev, rtl }));
  }, [languageId, rtl, setConfig]);

  useEffect(() => {
    if (!isLoading && !isAccountsLoading) {
      hide();
    } else {
      show();
    }
  }, [isLoading, isAccountsLoading, hide, show]);

  useEffect(() => {
    setShowSplash(false);
    const splashTimer = setTimeout(() => setShowSplash(false), 1800);

    // this will clear Timeout
    // when component unmount like in willComponentUnmount
    // and show will not change to true
    return () => {
      clearTimeout(splashTimer);
    };
  }, [setShowSplash]);

  if (isError && !isLoading && error?.response?.status !== 401) {
    return <NetworkErrorNavigation />;
  }

  if (isLoading || isAccountsLoading) {
    return (
      <BaseContainer>
        {/* <CustomLoaderWrapped /> */}
        <MainLoader />
      </BaseContainer>
    );
  }

  if (!isSuccess && isError && error?.response?.status === 401) {
    return <LoginNavigation />;
  }
  if (!isSuccess) {
    return <BaseContainer />;
  }
  // if (updateStatus !== UpdateStatus.OK) {
  //   return <UpgradeScreen />;
  // }
  // if (accounts.length <= 0) {
  // 	return <OnboardingNavigation />;
  // }

  // if (!finishedOnboarding) {
  //   return <WelcomeNavigation />;
  // }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <AuthorizedApp defaultView={defaultView} />
    </GestureHandlerRootView>
  );
};

const AppData: FC<Props> = (props) => {
  const [storageLoaded, setStorageLoaded] = useState(false);
  const [defaultView, setDefaultView] = useState<DefaultView>(
    DEFAULT_VIEW_STAFF_ASYNC_STORE_VALUE
  );
  const { loadLocale } = useLocale();

  const loadDefaultView = async () => {
    const storageDefaultView = (await AsyncStorage.getItem(
      DEFAULT_VIEW_ASYNC_STORE_KEY
    )) as DefaultView;
    setDefaultView(storageDefaultView || DEFAULT_VIEW_STAFF_ASYNC_STORE_VALUE);
  };

  useEffect(() => {
    initCookies()
      .then(() => {
        loadLocale()
          .then(() => {
            loadDefaultView().then(() => {
              setStorageLoaded(true);
            });
          })
          .catch((e) => console.log('loadLocale ERR'));
      })
      .catch((e) => console.log('initCookies ERR'));
  }, [loadLocale]);

  if (!storageLoaded) {
    return <SplashScreen />;
  }

  return <AppShell {...props} defaultView={defaultView} />;
};

const AppDataContainer: FC<PropsAppDataContainer> = ({
  setConfig,
  isLoading,
}) => {
  const [showSplash] = useRecoilState(showSplashAtom);
  return (
    <>
      <AppData setConfig={setConfig} />
      {showSplash && <SplashScreen />}
      {isLoading && !showSplash && <MainLoader />}
    </>
  );
};

export const App: FC = () => {
  const { isLoading, hide, show, toggle } = useLoader({ defaultValue: true });

  const { t } = useTranslation();

  const [config, setConfig] = useState({
    timeFormat: TIME_FORMAT[24],
    dateFormat: 'DD/MM/YYYY',
    shortDateFormat: 'DD/MM/YY',
    firstDayOfWeek: 1,
    currency: '$',
    countryCode: 'US',
    rtl: false,
    t,
    loader: {
      isLoading,
      hide,
      show,
      toggle,
    },
  } as Config);

  return (
    <ErrorBoundary
      FallbackComponent={() => <></>}
      onError={(e) => {
        sendError(APP_VERSION, `${e.name} ${e.message} ${e.stack}`);
        // eslint-disable-next-line react/jsx-closing-bracket-location
      }}
    >
      <ConfigContextProvider config={config}>
        <ThemeProvider theme={theme}>
          <AppDataContainer setConfig={setConfig} />
          <Toast
            config={toastConfig}
            autoHide
            visibilityTime={1500}
            position="bottom"
          />
        </ThemeProvider>
      </ConfigContextProvider>
    </ErrorBoundary>
  );
};
