import React, { FC, useEffect, useState } from 'react';
import { FlexColumnCenter, H1Regular } from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';

const Time = styled(H1Regular)`
  font-size: 40px;
  color: ${({ theme, mode }) =>
    mode === 'light' ? theme.colors.white : theme.colors.textPrimary};
`;

interface Props {
  mode: 'light' | 'dark';
  timeFormat: {
    '24': string;
    '12': string;
  };
}

export const ClockComponent: FC<Props> = ({ mode, timeFormat }) => {
  const [time, setTime] = useState(moment());
  useEffect(() => {
    const interval = setInterval(() => setTime(moment()));
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <FlexColumnCenter>
      <Time mode={mode}>{time.format(timeFormat[24])}</Time>
    </FlexColumnCenter>
  );
};
