import React, { FC } from 'react';
import {
  FlexRow,
  FlexRowCenter,
  H5Regular,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const Container = styled(FlexRow)`
  padding: 5px 20px;
  margin-top: 20px;
`;

const Content = styled(FlexRowCenter)`
  flex: 1;
`;

const Title = styled(H5Regular)`
  margin-left: 20px;
`;

interface Props {
  title: string;
  icon?: {
    xml: string;
    width: number;
    height: number;
  };
  image?: FC<any>;
  onPress?: () => void;
}

export const NavLightCard: FC<Props> = ({
  icon,
  image: Image,
  title,
  onPress = () => {},
}) => (
  <Container as={RipplePressable} onPress={onPress}>
    <Content>
      {icon && <SvgIcon xml={icon.xml} width={20} height={20} />}
      {Image && <Image />}
      <Title>{title}</Title>
    </Content>
  </Container>
);
