import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { colors } from '../../../style/theme';
import { ChatHeader } from '../components/headers/ChatHeader';
import { UserRoomsListScreen } from '../screens/user/UserRoomsListScreen';
import { ChatScreenNames } from './ScreenNames';
import { UserSupport } from '../screens/user/UserSupport';
import { OffersHeaderRightComponent } from '../../offers/components/OfferHeader/OffersHeaderRightComponent';
import { config } from '../../main/appConfig';
import { ChatHeaderRightComponent } from '../components/headers/ChatHeaderRightComponent';

const { Navigator, Screen } = createNativeStackNavigator();

export const ChatNavigation: FC = () => (
  <Navigator
    initialRouteName={ChatScreenNames.ROOMS_LIST}
    screenOptions={{
      header: (props: NativeStackHeaderProps) => (
        <ChatHeader
          {...props}
          hasLeftMenu
          hideBack
          color={colors.lightSurface}
          hasRightContent={config.showSupportModal}
          rightContent={ChatHeaderRightComponent}
        />
      ),
      // eslint-disable-next-line react/jsx-closing-bracket-location
    }}
  >
    <Screen name={ChatScreenNames.ROOMS_LIST} component={UserRoomsListScreen} />
  </Navigator>
);
