import { useTranslation } from '@violetta/ubeya/i18n';
import { API_DATE_FORMAT, API_DATE_TIME_FORMAT } from '@violetta/ubeya/shared';
import {
  BaseText,
  BaseView,
  Button,
  CheckBox,
  CheckBoxField,
  FlexRowBetween,
} from '@violetta/ubeya/ui';
import moment from 'moment-timezone';
import React, { FC, useContext, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import styled, { useTheme } from 'styled-components/native';
import { LiveViewFilterModalHeader } from './LiveViewFilterModalHeader';

const ModalContainer = styled(BaseView)``;

const ModalSection = styled(BaseView)`
  padding: 10px 23px;

  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
`;

const ModalRow = styled(FlexRowBetween)`
  margin-bottom: 15px;
  align-items: center;
`;

const ModalRowText = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #1e2534;
`;

const ButtonContainer = styled(BaseView)`
  padding: 24px 16px;
`;

export type LiveViewFilters = {
  positionIds?: number[];
  locationIds?: number[];
  startTime?: string[];
  shiftDates?: string[];
};

export type LiveViewFiltersInnerFormat = {
  positionIds?: { id: number; value: string }[];
  locationIds?: { id: number; value: string }[];
  startTime?: { id: number; value: string }[];
  shiftDates?: { id: number; value: string }[];
};

type ShiftsInfoType = {
  locationIds: { id: number; value: string }[];
  positionIds: { id: number; value: string | null }[];
  startTime: { value: Date }[];
  shiftDates: { value: Date }[];
};

type OptionsToFilter = Partial<ShiftsInfoType>;

interface Props {
  filters: LiveViewFilters;
  setFilters(values): any;
  modalizeRef: any;
  shiftsInfo: ShiftsInfoType;
  timeFormat: string;
  dateFormat: string;
  showDateFilter?: boolean;
}

type FilterRowParams = {
  form: any;
  rowText: string;
  sectionName: string;
  emptyStateText?: string;
  options: { id?: number; value: string | Date }[];
  titleFormatter: (value: { id?: number; value: string | Date }) => string;
};

const FilterRow = ({
  form,
  rowText,
  sectionName,
  options,
  emptyStateText,
  titleFormatter,
}: FilterRowParams) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  return (
    <ModalSection>
      <ModalRow>
        <ModalRowText>{rowText}</ModalRowText>
        <Button
          title={t('clear')}
          onPress={() => form.mutators.clearValues({ sectionName })}
          type="primary"
          fontSize={14}
          radius={18}
          paddingVertical={6}
          style={{
            backgroundColor: colors.primaryLight4,
            borderColor: colors.primaryLight4,
          }}
          textStyle={{ color: colors.textPrimary }}
        />
      </ModalRow>
      {options.length === 0 && (
        <BaseText>{emptyStateText || t('noOptions')}</BaseText>
      )}
      {(options || []).map((option, i) => {
        return (
          <CheckBoxField
            key={option.id}
            props={{
              title: titleFormatter(option),
              color: colors.surface,
              style: { marginBottom: 5, maxWidth: '80%' },
            }}
            fieldProps={{
              name: `${sectionName}[${i}]`,
              parse: (value) => (value ? option.id : false),
            }}
            component={CheckBox}
          />
        );
      })}
    </ModalSection>
  );
};

const generateArray = ({
  options = [],
  currValues = [],
}: {
  options: { id?: number; value: string | number | null }[];
  currValues: string[] | number[];
}) => {
  const newIdsArr = [...new Array(options.length)].fill(null);
  (currValues || []).forEach((id) => {
    const indexInOptions = options.findIndex(
      ({ id: optionId }) => optionId === id
    );
    newIdsArr[indexInOptions] = id;
  });

  return newIdsArr;
};

export const LiveViewFilterModal: FC<Props> = ({
  filters,
  setFilters,
  shiftsInfo,
  modalizeRef,
  timeFormat,
  showDateFilter,
  dateFormat,
}) => {
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const optionsForFilters: OptionsToFilter = useMemo(() => {
    if (showDateFilter) return shiftsInfo;

    const { shiftDates, ...rest } = shiftsInfo || {};
    return rest;
  }, []);

  const searchableOptions = search
    ? Object.entries(optionsForFilters).reduce((output, [key, options]) => {
        output[key] = options.filter(({ value }) => {
          if (!value) {
            return false;
          }
          let formattedValue = value as string;

          if (key === 'startTime') {
            formattedValue = moment(value, API_DATE_TIME_FORMAT).format(
              timeFormat || 'hh:mm'
            );
          }

          if (key === 'shiftDates') {
            formattedValue = moment(value, API_DATE_TIME_FORMAT).format(
              dateFormat || 'YYYY-MM-DD'
            );
          }

          return formattedValue
            ?.toLocaleLowerCase()
            ?.startsWith(search?.toLocaleLowerCase());
        });

        return output;
      }, {} as ShiftsInfoType)
    : optionsForFilters;

  const allLocations = searchableOptions.locationIds || [];
  const allPositions = searchableOptions.positionIds || [];
  const allStartTime = (searchableOptions.startTime || []).map(({ value }) => ({
    value,
    id: value,
  }));
  const allShiftDates = (searchableOptions.shiftDates || []).map(
    ({ value }) => ({
      value,
      id: value,
    })
  );

  const initialValues = useMemo(
    () =>
      Object.entries(filters).reduce((all, [filterType, values]) => {
        switch (filterType) {
          case 'locationIds': {
            all[filterType] = generateArray({
              options: allLocations,
              currValues: values,
            });
            break;
          }

          case 'positionIds': {
            all[filterType] = generateArray({
              options: allPositions,
              currValues: values,
            });
            break;
          }

          case 'startTime': {
            all[filterType] = generateArray({
              options: allStartTime,
              currValues: values,
            });
            break;
          }

          case 'shiftDates': {
            all[filterType] = generateArray({
              options: allShiftDates,
              currValues: values,
            });
            break;
          }

          default:
            break;
        }
        return all;
      }, {} as Record<string, any>),
    []
  );

  return (
    <Portal>
      <Form
        onSubmit={(values: LiveViewFiltersInnerFormat) => {
          /* in case we undo checked, it saves as false */
          const parsedValues = Object.entries(values).reduce(
            (all, [key, value]) => {
              all[key] = value.filter((curr) => !!curr);
              return all;
            },
            {} as LiveViewFilters
          );

          setFilters(parsedValues);
          modalizeRef?.current?.close();
        }}
        mutators={{
          clearValues: (args, state, utils) => {
            utils.changeValue(state, args[0].sectionName, () => []);
          },
        }}
        keepDirtyOnReinitialize
        initialValues={initialValues}
        render={({ handleSubmit, form }) => (
          <Modalize
            adjustToContentHeight
            ref={modalizeRef}
            panGestureEnabled={scrollEnabled}
            scrollViewProps={{ scrollEnabled }}
            withHandle={false}
            HeaderComponent={
              <LiveViewFilterModalHeader
                search={search}
                setSearch={setSearch}
              />
            }
            FooterComponent={
              <ButtonContainer>
                <Button
                  title={t('done')}
                  type="primary"
                  onPress={handleSubmit}
                />
              </ButtonContainer>
            }
          >
            <ModalContainer>
              <FilterRow
                form={form}
                rowText={t('byPosition')}
                emptyStateText={t('noPositions')}
                sectionName="positionIds"
                options={allPositions}
                titleFormatter={({ value }) => t(value as string)}
              />

              <FilterRow
                form={form}
                rowText={t('byArea')}
                emptyStateText={t('noLocations')}
                sectionName="locationIds"
                options={allLocations}
                titleFormatter={({ value }) => t(value as string)}
              />

              <FilterRow
                form={form}
                rowText={t('byStartTime')}
                sectionName="startTime"
                options={allStartTime}
                titleFormatter={({ value }) =>
                  moment(value, API_DATE_TIME_FORMAT).format(
                    showDateFilter ? `${dateFormat}, ${timeFormat}` : timeFormat
                  )
                }
              />

              {showDateFilter && (
                <FilterRow
                  form={form}
                  rowText={t('byDate')}
                  sectionName="shiftDates"
                  options={allShiftDates}
                  titleFormatter={({ value }) =>
                    moment(value, API_DATE_TIME_FORMAT).format(
                      dateFormat || API_DATE_FORMAT
                    )
                  }
                />
              )}
            </ModalContainer>
          </Modalize>
        )}
      />
    </Portal>
  );
};
