import React, { FC, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons, images } from '@violetta/ubeya/assets';
import {
  Booking,
  IAccount,
  IBranch,
  ILabel,
  ILocation,
  IOffer,
  IPosition,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Button,
  Card,
  FlexColumnDirection,
  FlexRow,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

import { BookingsScreenNames } from '../../../bookings/navigation';
import { OffersScreenNames } from '../../../offers/navigation';
import { HomeScreenNames } from '../../navigation';
import { AvailabilitiesCard } from '../AvailabilitiesCard';
import { EmptyStateCard } from '../EmptyStateCard';
import { ProjectOffersCard } from '../ProjectOffersCard';
import { UpcomingShiftsCard } from '../UpcomingShiftsCard';

type SchedulingCardsProps = {
  accounts?: IAccount[];
  branches: IBranch[];
  bookings: Booking[];
  offers: IOffer[];
  mappedBranches: Record<number, IBranch>;
  mappedAccounts: Record<number, IAccount>;
  mappedLabels: Record<number, ILabel>;
  mappedLocations: Record<number, ILocation>;
  mappedPositions: Record<number, IPosition>;
  showJoinABranch: boolean;
  isLoadingBookings: boolean;
  isLoadingOffers: boolean;
};

const CardContent = styled(FlexRow)`
  align-items: center;
  padding: 12px;
`;
const IconContainer = styled(BaseView)`
  padding-left: 5px;
  display: flex;
`;
const Content = styled(BaseView)`
  flex: 1;
  padding-left: 20px;
`;
const Title = styled(BaseText)`
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  color: #252525;
`;
const Description = styled(BaseText)`
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #252525;
`;

const ButtonContainer = styled(BaseView)`
  align-items: flex-end;
  margin-top: 10px;
`;

const EmptySchedule: FC<{
  title: string;
  subtitle: string;
  onPress: () => void;
}> = ({ title, subtitle, onPress }) => {
  const { t } = useTranslation();
  return (
    <Card as={RipplePressable} onPress={onPress}>
      <CardContent>
        <IconContainer>
          <SvgIcon
            xml={icons.cardIcons.scheduleCardIcon}
            width={116}
            height={83}
          />
        </IconContainer>
        <Content>
          <Title>{title}</Title>
          <Description>{subtitle}</Description>
          <ButtonContainer>
            <Button
              type="primaryReversed"
              title={t('view')}
              onPress={onPress}
              paddingHorizontal={20}
              paddingVertical={5}
            />
          </ButtonContainer>
        </Content>
      </CardContent>
    </Card>
  );
};

export const SchedulingCards: FC<SchedulingCardsProps> = ({
  bookings,
  offers,
  accounts,
  branches,
  mappedBranches,
  mappedAccounts,
  mappedLabels,
  mappedLocations,
  mappedPositions,
  showJoinABranch,
  isLoadingBookings,
  isLoadingOffers,
}) => {
  const firstBookings = useMemo(() => (bookings || []).slice(0, 5), [bookings]);
  const totalBookings = useMemo(() => bookings?.length || 0, [bookings]);
  const totalOffers = useMemo(() => offers?.length || 0, [offers]);
  const firstOffers = useMemo(() => offers.slice(0, 5), [offers]);
  const { navigate } = useNavigation();
  const { t } = useTranslation();

  return (
    <FlexColumnDirection>
      {branches?.length > 0 && <AvailabilitiesCard branches={branches} />}
      {totalBookings > 0 && (
        <UpcomingShiftsCard
          bookings={firstBookings}
          total={totalBookings}
          mappedAccounts={mappedAccounts}
          mappedLabels={mappedLabels}
          mappedPositions={mappedPositions}
          mappedBranches={mappedBranches}
        />
      )}
      {!isLoadingBookings && !showJoinABranch && totalBookings === 0 && (
        <EmptySchedule
          title={t('upcomingShifts')}
          subtitle={t('emptyStateBookings')}
          onPress={() =>
            navigate(HomeScreenNames.TABS, {
              screen: BookingsScreenNames.SCHEDULE,
            })
          }
        />
      )}
      {totalOffers > 0 && (
        <ProjectOffersCard
          mappedAccounts={mappedAccounts}
          offers={firstOffers}
          mappedBranches={mappedBranches}
          total={totalOffers}
          mappedLocations={mappedLocations}
        />
      )}
      {!isLoadingOffers && !showJoinABranch && totalOffers === 0 && (
        <EmptyStateCard
          height={255}
          image={images.svgs.emptyOffersCardImage}
          rtlImage={images.svgs.emptyOffersCardImageRtl}
          buttonText={t('show')}
          onPress={() =>
            navigate(HomeScreenNames.TABS, {
              screen: OffersScreenNames.OFFERS,
            })
          }
        />
      )}
    </FlexColumnDirection>
  );
};
