import React, { FC, useMemo } from 'react';
import styled from 'styled-components/native';
import { BaseView, FlexRowCenter } from '../Layout';
import { H6Medium } from '../Typography';
import { BaseImage } from './Image';

const ImageContainer = styled(BaseView)`
  border-radius: 50px;
`;

const BranchImageContainer = styled(BaseImage)`
  border-radius: 50px;
  resize-mode: contain;
`;

const Avatar = styled(FlexRowCenter)`
  border-radius: 50px;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray7};
`;

const AvatarText = styled(H6Medium)`
  font-weight: 500;
`;

interface Props {
  size?: number;
  image?: string | null;
  name?: string;

  imageComponent?: FC<any>;
}

export const BranchImage: FC<Props> = ({ size = 30, image, name, imageComponent: ImageComponent }) => (
  <ImageContainer style={{ width: size, height: size }}>
    {!!image && !ImageComponent && <BranchImageContainer width={size} height={size} source={{ uri: image }} />}

    {!image && ImageComponent && <ImageComponent />}

    {!image && !ImageComponent && (
      <Avatar style={{ width: size, height: size }}>
        {!!name && (
          <AvatarText>
            {name.charAt(0)}
            {name.charAt(name.length - 1)}
          </AvatarText>
        )}
      </Avatar>
    )}
  </ImageContainer>
);
