import MultiSlider from '@ptomasroos/react-native-multi-slider';
import { to } from '@violetta/ubeya/await';
import {
  BaseText,
  BaseView,
  Button,
  FlexRow,
  FlexRowBetween,
  Radio,
} from '@violetta/ubeya/ui';
import { GeolocationError, getCurrentLocation } from '@violetta/ubeya/utils';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useRecoilState } from 'recoil';
import styled, { useTheme } from 'styled-components/native';
import { userLocationAtom } from '../../state';

const Container = styled(BaseView)``;
const Title = styled(BaseText)`
  font-weight: 600;
`;
const EndRange = styled(BaseText)`
  margin-right: 10px;
  align-text: center;
  font-weight: 600;
`;
const EnableLocationContainer = styled(BaseView)`
  margin: 20px 0 10px;
`;
const Content = styled(BaseView)`
  margin-top: 10px;
`;
const DistanceContainer = styled(FlexRowBetween)`
  margin-top: 5px;
  align-items: center;
`;

interface Props {
  setScrollEnabled(value): any;
  onChange(event: any): void;
  t: (s: string) => string;
  value: number[];
}

export const SliderFilter: FC<Props> = ({
  setScrollEnabled,
  value,
  onChange,
  t,
}) => {
  const { colors } = useTheme();

  const [isLocationLoading, setIsLocationLoading] = useState<boolean>(false);
  const [userLocation, setUserLocation] = useRecoilState(userLocationAtom);

  const getLocation = useCallback(async () => {
    setIsLocationLoading(true);
    const [locationErr, location] = await to(getCurrentLocation());
    setIsLocationLoading(false);
    if (locationErr || !location) {
      switch (locationErr as unknown as GeolocationError) {
        case GeolocationError.NO_LOCATION_AVAILABLE:
        case GeolocationError.POSITION_UNAVAILABLE:
        case GeolocationError.PERMISSION_DENIED:
        case GeolocationError.TIMEOUT:
        default:
          return false;
      }
    }
    setUserLocation(location);
  }, [setUserLocation]);

  if (!userLocation) {
    return (
      <View>
        <Title>{t('maximumDistance')}</Title>
        <EnableLocationContainer>
          <Button
            type="primaryReversed"
            title={t('findNearbyJobs')}
            onPress={getLocation}
            fontSize={14}
            paddingHorizontal={isLocationLoading ? 15 : 30}
            paddingVertical={12}
            radius={30}
            isLoading={isLocationLoading}
          />
        </EnableLocationContainer>
      </View>
    );
  }

  return (
    <Container>
      <DistanceContainer>
        <Title>{t('maximumDistance')}</Title>
        <EndRange>
          {value[0] === 110
            ? t('distanceEverywhere')
            : `${value[0]} ${t('distanceKM')}`}
        </EndRange>
      </DistanceContainer>

      <Content>
        <MultiSlider
          values={value}
          step={10}
          min={0}
          max={110}
          trackStyle={{}}
          markerStyle={{
            backgroundColor: colors.surface,
            borderColor: colors.primary,
            borderWidth: 1,
            elevation: 3,
            width: 23,
            height: 23,
          }}
          selectedStyle={{ backgroundColor: colors.primary }}
          containerStyle={{
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 10,
            paddingBottom: 30,
            marginTop: 10,
          }}
          touchDimensions={{
            height: 100,
            width: 100,
            borderRadius: 50,
            slipDisplacement: 500,
          }}
          smoothSnapped
          onValuesChangeStart={() => {
            setScrollEnabled(false);
          }}
          onValuesChange={onChange}
          onValuesChangeFinish={() => {
            setScrollEnabled(true);
          }}
        />
      </Content>
    </Container>
  );
};
