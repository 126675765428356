import React, { useCallback, useMemo, useRef } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { IPostAttachment } from '@violetta/ubeya/entities';
import {
  BaseText,
  BaseView,
  FlexCenter,
  FlexColumnDirection,
  FlexParent,
  RipplePressable,
  SvgIcon,
  WideFlexParent,
} from '@violetta/ubeya/ui';
import { Image, View } from 'react-native';
import Video from 'react-native-video';
import styled from 'styled-components/native';
import { FeedScreenNames } from '../../../navigation';
import { RawAttachments } from './RawAttachments';

const LinksCount = styled(BaseText)`
  font-size: 30px;
  color: white;
`;
const LinksCountContainer = styled(FlexCenter)`
  background-color: rgba(0, 0, 0, 0.3);
  flex: 1;
  width: 100%;
  padding: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
const FlexHalf = styled(View)`
  flex: 0.5;
`;

const Container = styled(FlexParent)<{ fixedHeight: boolean }>`
  height: ${(props) => (props.fixedHeight ? '200px' : 'auto')};
`;

const ColumnContainer = styled(FlexColumnDirection)``;
const SingleContainer = styled(WideFlexParent)`
  width: 100%;
`;

const FlexImage = styled(Image)`
  flex: 1;
`;

const getAttachmentType = (a: IPostAttachment) =>
  a.mimeType.startsWith('image')
    ? 'image'
    : a.mimeType.startsWith('video')
    ? 'video'
    : 'file';

const Single = ({
  onPress,
  uri,
  type,
  resizeMode = 'contain',
  more = 0,
}: {
  onPress?: () => void;
  uri: string;
  type: 'image' | 'video' | 'file';
  resizeMode?: 'contain' | 'cover';
  more?: number;
}) => {
  const player = useRef<Video | null>(null);

  const VideoContainer = styled(BaseView)`
    position: relative;
    width: 100%;
    height: 100%;
  `;

  const IconContainer = styled(BaseView)`
    position: absolute;
    top: 10px;
    right: 10px;
    align-items: center;
    justify-content: center;

    width: 30px;
    height: 30px;
    padding-left: 5px;
    border-radius: 50px;

    shadow-color: rgba(0, 0, 0, 0.12);
    shadow-opacity: 1;
    shadow-offset: 0 2px;
    elevation: 5;

    background-color: ${({ theme }) => theme.colors.gray9};
  `;

  const PlayIcon = styled(SvgIcon)``;

  return (
    <SingleContainer as={RipplePressable} onPress={onPress}>
      {type === 'image' && (
        <FlexImage resizeMode={resizeMode} source={{ uri }} />
      )}
      {type === 'video' && (
        <VideoContainer>
          {/* <Video
            source={{ uri }}
            style={{
              flex: 1,
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
            }}
            ref={player}
            playWhenInactive={false}
            playInBackground={false}
            paused
          /> */}
          <IconContainer>
            <PlayIcon
              xml={icons.uiIcons.play}
              width={20}
              height={20}
              color="black"
            />
          </IconContainer>
        </VideoContainer>
      )}

      {more > 0 && (
        <LinksCountContainer>
          <LinksCount>+{more}</LinksCount>
        </LinksCountContainer>
      )}
    </SingleContainer>
  );
};

const Gallery = ({
  onPress,
  attachments,
}: Props & { onPress?: (idx: number) => void }) => {
  const [link1, link2, link3] = useMemo(
    () => attachments.map((a) => a.link),
    [attachments]
  );

  return (
    <WideFlexParent>
      <FlexHalf>
        <FlexHalf>
          <Single
            uri={link3}
            onPress={() => {
              onPress?.(2);
            }}
            resizeMode="cover"
            type={getAttachmentType(attachments[2])}
          />
        </FlexHalf>
        <FlexHalf>
          <Single
            uri={link2}
            onPress={() => {
              onPress?.(1);
            }}
            resizeMode="cover"
            type={getAttachmentType(attachments[1])}
            more={attachments.length - 3}
          />
        </FlexHalf>
      </FlexHalf>
      <FlexHalf>
        <Single
          uri={link1}
          onPress={() => {
            onPress?.(0);
          }}
          resizeMode="cover"
          type={getAttachmentType(attachments[0])}
        />
      </FlexHalf>
    </WideFlexParent>
  );
};

const Double = ({
  attachments,
  onPress,
}: Props & { onPress?: (idx: number) => void }) => (
  <WideFlexParent>
    {attachments.map((attachment, i) => (
      <FlexHalf key={attachment.id}>
        <Single
          type={getAttachmentType(attachment)}
          uri={attachment.link}
          resizeMode="cover"
          onPress={() => onPress?.(i)}
        />
      </FlexHalf>
    ))}
  </WideFlexParent>
);

const AttachmentsLayout = ({
  attachments,
  onPress,
}: Props & { onPress?: (idx: number) => void }) => {
  const { length } = attachments;

  // Handle 1 attachment
  if (length === 1)
    return (
      <Single
        uri={attachments[0].link}
        onPress={() => onPress?.(0)}
        type={getAttachmentType(attachments[0])}
      />
    );
  // Handle 2 attachments
  if (length === 2) {
    return <Double attachments={attachments} onPress={onPress} />;
  }
  // Handle 2+ attachments
  if (length > 2) {
    return <Gallery attachments={attachments} onPress={onPress} />;
  }

  // TODO: assert never
  return null;
};

interface Props {
  attachments: IPostAttachment[];
}

export const PostAttachments = ({ attachments }: Props) => {
  const { navigate } = useNavigation();

  const isVideoOrImageAttachments = useMemo(
    () =>
      attachments.filter((attachment) =>
        ['image', 'video'].includes(getAttachmentType(attachment))
      ),
    [attachments]
  );

  const notVideoNorImageAttachments = useMemo(
    () =>
      attachments.filter(
        (attachment) =>
          !['image', 'video'].includes(getAttachmentType(attachment))
      ),
    [attachments]
  );

  const imageItems = useMemo(
    () =>
      (isVideoOrImageAttachments || []).filter(
        (item) => getAttachmentType(item) === 'image'
      ),
    [isVideoOrImageAttachments]
  );
  const videoItems = useMemo(
    () =>
      (isVideoOrImageAttachments || []).filter(
        (item) => getAttachmentType(item) === 'video'
      ),
    [isVideoOrImageAttachments]
  );

  const openGallery = useCallback(
    (idx: number = 0) => {
      const selectedItem = isVideoOrImageAttachments[idx];
      // can be either video or image in this context
      const itemIsImage = getAttachmentType(selectedItem) === 'image';

      const currentType = itemIsImage ? 'image' : 'video';
      const selectedTypeArray =
        currentType === 'image' ? imageItems : videoItems;

      navigate(FeedScreenNames.GALLERY, {
        items: selectedTypeArray.map((item, idx) => ({
          idx,
          metadata: {},
          type: currentType,
          uri: item.link,
        })),
        idx: selectedTypeArray.findIndex(({ id }) => id === selectedItem.id),
        type: currentType,
      });
    },
    [imageItems, isVideoOrImageAttachments, navigate, videoItems]
  );

  return (
    <ColumnContainer>
      <Container fixedHeight={isVideoOrImageAttachments.length > 0}>
        <AttachmentsLayout
          attachments={isVideoOrImageAttachments}
          onPress={openGallery}
        />
      </Container>
      {notVideoNorImageAttachments.length > 0 && (
        <RawAttachments attachments={notVideoNorImageAttachments} />
      )}
    </ColumnContainer>
  );
};
