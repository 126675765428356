import React, { FC, useState, useCallback } from 'react';
import { ICountry } from '@violetta/ubeya/entities';
import { useCountries, useTranslation } from '@violetta/ubeya/i18n';
import {
  FlatList,
  H3Bold,
  FlexParent,
  FlexCenter,
  SearchBox,
  Modal,
  IconClose,
} from '@violetta/ubeya/ui';
import { getCountryCodeFromRegionCode } from '@violetta/ubeya/utils';
import styled from 'styled-components/native';
import { CountryListItem } from './CountryListItem';

const SafeAreaView = styled.SafeAreaView`
  flex: 1;
  background-color: white;
  padding-top: 10px;
`;

const ModalHeader = styled(FlexParent)`
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.surface};
  padding: 15px;
`;

const SearchContainer = styled(FlexCenter)`
  background-color: ${({ theme }) => theme.colors.gray11};
  flex-grow: 0;
  padding: 15px;
`;

const H3BoldWhite = styled(H3Bold)``;

interface Props {
  onCancel?: () => void;
  onSelect?: (country: ICountry, index: number) => void;
}

export const CountryPickerModal: FC<Props> = ({ onCancel, onSelect }) => {
  const { countries } = useCountries();
  const { t } = useTranslation();
  const [filteredCountries, setFilteredCountries] = useState([...countries]);

  const keyExtractor = useCallback((item: ICountry) => item.code, []);

  const filterCountries = useCallback(
    (searchTerm: string) => {
      const newCountries = countries.filter(
        (country) =>
          country.name.toLowerCase().startsWith(searchTerm.toLowerCase()) ||
          String(getCountryCodeFromRegionCode(country.code)).startsWith(
            searchTerm
          )
      );

      setFilteredCountries(newCountries);
    },
    [countries]
  );

  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible
      onRequestClose={onCancel}
    >
      <SafeAreaView>
        <ModalHeader>
          <H3BoldWhite>{t('countries')}</H3BoldWhite>
          <IconClose color="#000" size={22} onPress={onCancel} />
        </ModalHeader>

        <SearchContainer>
          <SearchBox onChange={filterCountries} />
        </SearchContainer>

        <FlatList
          contentContainerStyle={{ flexGrow: 1 }}
          data={filteredCountries}
          renderItem={({ item, index }) => (
            <CountryListItem
              key={item.code}
              item={item}
              index={index}
              onSelect={onSelect}
            />
          )}
          keyExtractor={keyExtractor}
        />
      </SafeAreaView>
    </Modal>
  );
};
