import React, { useEffect, useRef } from 'react';
import {
  GooglePlaceData,
  GooglePlacesAutocomplete,
} from 'react-native-google-places-autocomplete';
import styled from 'styled-components/native';
import { BaseView } from '../Layout';
import { BaseText } from '../Typography';
import { TextInput } from './TextInput';

const LocationRowContainer = styled(BaseView)`
  padding: 5px 0;
`;

const LocationRow = (data: GooglePlaceData) => (
  <LocationRowContainer>
    <BaseText>{data?.description || ''}</BaseText>
  </LocationRowContainer>
);

export const GooglePlacesTextInput = ({ onChange, value }) => {
  const ref = useRef();

  useEffect(() => {
    value?.address && ref.current?.setAddressText(value.address);
  }, [value.address]);

  return (
    <GooglePlacesAutocomplete
      placeholder=""
      ref={ref}
      onPress={(_, details = null) => {
        if (details) {
          onChange({
            address: details?.formatted_address,
            lat: details?.geometry?.location?.lat,
            lng: details?.geometry?.location?.lng,
          });
        }
      }}
      fetchDetails
      keepResultsAfterBlur
      enablePoweredByContainer={false}
      defaultValue={value?.address || ''}
      suppressDefaultStyles
      debounce={100}
      textInputProps={{
        InputComp: TextInput,
        autoComplete: 'off',
        autoCapitalize: 'none',
        autoCorrect: false,
      }}
      renderRow={LocationRow}
      keyboardShouldPersistTaps="handled"
      minLength={2} // minimum length of text to search
      query={{
        key: 'AIzaSyD15Bv6hyuwLNszcnvNvHXkfBN0nWF9OQM',
      }}
    />
  );
};
