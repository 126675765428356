import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeNativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderlessBar } from '@violetta/ubeya/ui';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';
import { OfferHeader } from '../components/OfferHeader/OfferHeader';
import { OffersHeader } from '../components/OfferHeader/OffersHeader';
import { OffersHeaderRightComponent } from '../components/OfferHeader/OffersHeaderRightComponent';
import { OfferScreen, OffersScreen } from '../screens';
import { OffersScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const OfferNavigation: FC = () => {
  const { colors } = useTheme();

  return (
    <Navigator
      initialRouteName={OffersScreenNames.OFFER}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
        animationEnabled: Platform.OS !== 'android',
      }}
    >
      <Screen
        name={OffersScreenNames.OFFER}
        component={OfferScreen}
        options={{
          header: (props: NativeStackHeaderProps) => <OfferHeader {...props} />,
          animationEnabled: Platform.OS !== 'android',
        }}
      />
    </Navigator>
  );
};

export const OffersNavigation: FC = () => (
  <Navigator
    initialRouteName={OffersScreenNames.OFFERS}
    screenOptions={{
      animationEnabled: Platform.OS !== 'android',
    }}
  >
    <Screen
      name={OffersScreenNames.OFFERS}
      component={OffersScreen}
      options={{
        header: (props: NativeStackHeaderProps) => (
          <OffersHeader
            {...props}
            hideBack
            hasLeftMenu
            hasRightContent
            rightContent={OffersHeaderRightComponent}
            shadowed={false}
          />
        ),
        animationEnabled: Platform.OS !== 'android',
      }}
    />
  </Navigator>
);
