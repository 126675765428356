import { lotties } from '@violetta/ubeya/assets';
import { usePosts } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, EmptyState, FlexColumnDirection } from '@violetta/ubeya/ui';
import { useStats } from '@violetta/ubeya/utils';
import React, { FC, useEffect } from 'react';
import { FlatList } from 'react-native';
import styled from 'styled-components/native';
import { Post } from './components';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightYellow};
`;

export const FeedScreen: FC = () => {
  const {
    posts,
    isLoading,
    employees,
    fetchMore,
    refetch,
    createPollAnswer,
    deletePollAnswer,
    createLike,
    deleteLike,
  } = usePosts();
  const { t } = useTranslation();
  const { invalidateHasUnreadPosts } = useStats({ enabled: false });

  useEffect(() => {
    invalidateHasUnreadPosts();
  }, [invalidateHasUnreadPosts]);

  return (
    <Container>
      <FlatList
        contentContainerStyle={{ flexGrow: 1 }}
        ListEmptyComponent={
          !isLoading ? (
            <EmptyState lottieSrc={lotties.noFeedData} title={t('noPosts')} />
          ) : (
            <BaseView />
          )
        }
        data={posts}
        refreshing={isLoading}
        onRefresh={refetch}
        onEndReachedThreshold={1}
        onEndReached={() => fetchMore()}
        keyExtractor={({ id }) => id.toString()}
        renderItem={({ item }) => (
          <Post
            key={item.id}
            title={item.title}
            post={item}
            employees={employees}
            createPollAnswer={createPollAnswer}
            deletePollAnswer={deletePollAnswer}
            createLike={createLike}
            deleteLike={deleteLike}
          />
        )}
      />
    </Container>
  );
};
