import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, H4Medium, RipplePressable } from '@violetta/ubeya/ui';
import React, { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/native';
import QRCode from 'react-qr-code';

const QrCodeContainer = styled(BaseView)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0 20px;
`;

const LinkContainer = styled(BaseView)`
  padding-top: 7px;
`;

const Link = styled(H4Medium)`
  font-size: 15px;
  color: #6467ef;
`;

interface Props {
  onScan: () => void;
  data: object;
}

export const TimeclockQRScan: FC<Props> = ({ onScan, data }) => {
  const { t } = useTranslation();
  const [seed, setSeed] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeed(Math.random() * 100);
    }, 10000);
    return () => clearInterval(intervalId);
  }, [setSeed]);

  const qrData = useMemo(() => JSON.stringify({ ...data, seed }), [data, seed]);

  return (
    <QrCodeContainer>
      <QRCode value={qrData} size={200} />
      <LinkContainer as={RipplePressable} onPress={onScan}>
        <Link>{t('orScanQrCode')}</Link>
      </LinkContainer>
    </QrCodeContainer>
  );
};
