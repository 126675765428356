import { api } from '@violetta/ubeya/api';
import { IEmployeeSlotBreakPlots } from '@violetta/ubeya/entities';
import { useQuery } from '@tanstack/react-query';

export const useEmployeeSlotBreakPlots = (employeeSlotId: number) => {
  const storeKey = ['employeeSlotBreakPlots', employeeSlotId];

  const { isPending, data } = useQuery<{ data: IEmployeeSlotBreakPlots[] }>({
    queryKey: storeKey,
    queryFn: () => api.getEmployeeSlotBreakPlots(employeeSlotId),
    enabled: !!employeeSlotId,
    staleTime: 0,
  });

  return {
    isLoading: isPending,
    data: data?.data || [],
  };
};
