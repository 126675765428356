import {
  BaseView,
  FlexColumnCenter,
  FlexRowEvenly,
  H2Bold,
  H4Bold,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { Alert } from '@violetta/ubeya/alert';
import { useDuration } from '@violetta/ubeya/utils';
import React, { FC, useContext, useMemo } from 'react';
import styled from 'styled-components/native';
import { TimeclockState } from '../interfaces';
import TimeclockContext from './TimeclockContext';
import { useNetInfo } from '@react-native-community/netinfo';
import { ActivityIndicator } from 'react-native';
import { useTheme } from '@react-navigation/native';
import { useTranslation } from '@violetta/ubeya/i18n';
import { icons } from '@violetta/ubeya/assets';

const Container = styled(BaseView)`
  display: flex;
  width: 100%;
  background-color: #f9f9f9;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled(BaseView)`
  width: 100%;
  background-color: #46cc88;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const Clock = styled(H2Bold)`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.08px;
  color: #fff;
  width: 235px;
  text-align: center;
  padding: 16px 0;
`;

const BreakWrapper = styled(Wrapper)`
  background-color: #defdee;
`;
const BreakClock = styled(Clock)`
  color: #39a26d;
  padding: 16px 0;
`;

const SmallCircle = styled(FlexColumnCenter)`
  padding: 5px 10px;
  border-radius: 19.5px;
  border: solid 1px #39a26d;
  background-color: #defdee;
  margin-right: 10px;
`;

const SmallTitle = styled(H4Bold)`
  margin-left: 5px;
  color: #39a26d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
`;

interface BreakProps {}

interface Props {}

const Break: FC<BreakProps> = () => {
  const {
    onStartBreak,
    onEndBreak,
    changing,
    config,
    state,
    checkIn,
    checkOut,
  } = useContext(TimeclockContext);
  const { isConnected } = useNetInfo();
  const { colors } = useTheme();
  const { t } = useTranslation();

  const isDisabled = useMemo(() => changing !== null, [changing]);
  const isStartBreakLoading = useMemo(
    () => changing === TimeclockState.BreaktimeStarted,
    [changing]
  );
  const isEndBreakLoading = useMemo(
    () => changing === TimeclockState.BreaktimeEnded,
    [changing]
  );

  const showBreaksButton =
    config?.toRequireClockBreaks &&
    (!config.toRequireCheckInWithMobile ||
      // if check in is required, do not show it when we are in one of the check in/out states
      (!!checkIn && !checkOut));

  if (!showBreaksButton) {
    return null;
  }

  if (state === TimeclockState.BreaktimeStarted) {
    return (
      <RipplePressable
        onPress={
          isDisabled
            ? () => {}
            : isConnected
            ? onEndBreak
            : () =>
                Alert.alert(t('noInternet'), t('breakUnavailable'), [
                  { text: t('ok'), type: 'confirm' },
                ])
        }
        style={{ position: 'absolute', bottom: -20, right: 0 }}
      >
        <SmallCircle>
          <FlexRowEvenly>
            {!isEndBreakLoading && (
              <>
                <SvgIcon
                  xml={icons.actionIcons.breakPause}
                  width={18}
                  height={18}
                />
                <SmallTitle>{t('break')}</SmallTitle>
              </>
            )}
            {isEndBreakLoading && (
              <ActivityIndicator color={colors.primary} size="small" />
            )}
          </FlexRowEvenly>
        </SmallCircle>
      </RipplePressable>
    );
  }
  return (
    <RipplePressable
      onPress={
        isDisabled
          ? () => {}
          : isConnected
          ? onStartBreak
          : () =>
              Alert.alert(t('noInternet'), t('breakUnavailable'), [
                { text: t('ok'), type: 'confirm' },
              ])
      }
      style={{ position: 'absolute', bottom: -20, right: 0 }}
    >
      {!config?.automaticBreakTime && (
        <SmallCircle>
          <FlexRowEvenly>
            {!isStartBreakLoading && (
              <>
                <SvgIcon
                  xml={icons.actionIcons.breakPlay}
                  width={18}
                  height={18}
                />
                <SmallTitle>{t('break')}</SmallTitle>
              </>
            )}
            {isStartBreakLoading && (
              <ActivityIndicator color={colors.primary} size="small" />
            )}
          </FlexRowEvenly>
        </SmallCircle>
      )}
    </RipplePressable>
  );
};

export const ClockOngoing: FC<Props> = () => {
  const { startTime, breakStartTime, changing, state } =
    useContext(TimeclockContext);
  const { clock } = useDuration(startTime);
  const { clock: breakClock } = useDuration(breakStartTime);

  const isClockOutLoading = useMemo(
    () => changing === TimeclockState.TimeclockEnded,
    [changing]
  );

  if (isClockOutLoading) {
    return null;
  }

  return (
    <Container>
      {state === TimeclockState.BreaktimeStarted ? (
        <BreakWrapper>
          <BreakClock>{breakClock}</BreakClock>
          <Break />
        </BreakWrapper>
      ) : (
        <Wrapper>
          <Clock>{clock}</Clock>
          <Break />
        </Wrapper>
      )}
    </Container>
  );
};
