import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import moment from 'moment';
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { ITimeAvailability, ITimeOff } from '../entities';

type AddParams = {
  timeAvailabilities: Array<
    Pick<ITimeAvailability, 'startTime' | 'endTime' | 'status'>
  >;
};
type DeleteParams = { id: number };

function* generatorId() {
  let i = -1;
  while (true) {
    yield i;
    // eslint-disable-next-line no-plusplus
    i--;
  }
}

const counter = generatorId();

export const useTimeAvailabilities = ({
  accountId,
  from,
  to,
}: {
  accountId: number;
  from: Date;
  to: Date;
}) => {
  const storeKey = useMemo(
    () => [
      'timeAvailabilities',
      String(accountId),
      moment(from).format('YYYY-MM-DD'),
      moment(to).format('YYYY-MM-DD'),
    ],
    [accountId, from, to]
  );

  const queryClient = useQueryClient();

  const { data: userData } = useUser();

  const { isPending, data, refetch } = useQuery<{
    data: ITimeAvailability[];
    timeAvailabilities: ITimeAvailability[];
    timeOffs: ITimeOff[];
  }>({
    queryKey: storeKey,
    queryFn: async () => {
      const data = (await api.getTimeAvailabilities({
        accountId,
        from,
        to,
      })) as {
        data: ITimeAvailability[];
        timeAvailabilities: ITimeAvailability[];
        timeOffs: ITimeOff[];
      };

      return data || [];
    },
    enabled: !!userData?.id,
  });

  const { mutateAsync: addTimeAvailabilities } = useMutation({
    mutationFn: (params: AddParams) =>
      api.addTimeAvailabilities({ ...params, accountId }),
    onMutate: ({ timeAvailabilities }) => {
      const data = queryClient.getQueryData(storeKey) as ITimeAvailability[];
      const addedData = timeAvailabilities.map((timeAvailability) => ({
        ...timeAvailability,
        id: counter.next().value,
      }));

      return queryClient.setQueryData(storeKey, {
        ...data,
        timeAvailabilities: [...data.timeAvailabilities, ...addedData],
      });
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: storeKey }),
  });

  const { mutateAsync: deleteTimeAvailability } = useMutation({
    mutationFn: (params: DeleteParams) =>
      api.deleteTimeAvailability({ ...params, accountId }),
    onMutate: ({ id }) => {
      const data = queryClient.getQueryData(storeKey) as ITimeAvailability[];
      const filteredData = data?.timeAvailabilities.filter(
        ({ id: cId }) => id !== cId
      );

      return queryClient.setQueryData(storeKey, {
        ...data,
        timeAvailabilities: filteredData,
      });
    },
  });

  return {
    timeAvailabilities: data?.timeAvailabilities || [],
    timeOffs: data?.timeOffs || [],
    addTimeAvailabilities,
    deleteTimeAvailability,
    refetch,
    isLoading: isPending,
  };
};
