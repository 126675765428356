import React, { useState, FC } from 'react';
import { useUser } from '@violetta/ubeya/auth';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseImage,
  FlexRow,
  FlexRowCenter,
  H3Medium,
  TextInput,
  Loader,
} from '@violetta/ubeya/ui';

import styled from 'styled-components/native';

const Container = styled(FlexRow)`
  background-color: ${({ theme }) => theme.colors.surface};
  margin-bottom: 25px;
`;

const Image = styled(BaseImage)`
  height: 36px;
  width: 36px;
  border-radius: 50px;
`;

const EmptyImageContainer = styled(FlexRowCenter)`
  height: 36px;
  width: 36px;
  border-radius: 50px;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray9};
`;

const EmptyImageTitle = styled(H3Medium)`
  font-weight: 500;
`;

interface Props {
  onComment?: (comment: string) => void;
}

export const MyComment: FC<Props> = ({ onComment }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const { data } = useUser();

  if (!data) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      {data.image ? (
        <Image source={{ uri: data.image }} />
      ) : (
        <EmptyImageContainer>
          <EmptyImageTitle>{data.firstName.charAt(0)}</EmptyImageTitle>
        </EmptyImageContainer>
      )}

      <TextInput
        placeholder={t('writeYourComment')}
        editable
        autoCapitalize="none"
        style={{ marginLeft: 10, fontSize: 12, height: 36 }}
        value={comment}
        onChangeText={setComment}
        onSubmitEditing={() => onComment?.(comment)}
      />
    </Container>
  );
};
