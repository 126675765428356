import { useMemo } from 'react';
import momentTz from 'moment-timezone';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray, useOptimisticMutation } from '@violetta/ubeya/utils';
import { createCachedSelector } from 're-reselect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IApplication, IOffer } from '../entities';
import { useLabels } from './useLabels';
import { useLocations } from './useLocations';
import { useBranches } from './useBranches';

export type MappedOffer = { title: string; offers: IOffer[] };

type AddParams = Pick<IApplication, 'isRejected' | 'shiftId'> & {
  projectId: number;
};
type AddBulkParams = Pick<IApplication, 'isRejected'> & { shiftsIds: number[] };

type EditParams = Pick<
  IApplication,
  'id' | 'isRejected' | 'hasCar' | 'comment' | 'shiftId'
> & { projectId: number };

type DeleteParams = Pick<IApplication, 'id' | 'shiftId'> & {
  projectId: number;
};
type DeleteBulkParams = {
  applicationsIds: number[];
  projectId: number;
};

type EditBulkParams = {
  applicationsIds: number[];
  projectId: number;
  isRejected: boolean;
};

// const findItem = (previousData: { data: IOffer[] }, shiftId: number) => {
//   const project = previousData.data.find(({ shifts }) => shifts.find(({ id }) => id === shiftId));

//   if (!project) {
//     return null;
//   }

//   return project.shifts.find(({ id }) => id === shiftId);
// };

const normalizedShiftTimes = ({
  offer,
  mappedLocations,
  isRemoteWork,
}: {
  offer: IOffer;
  mappedLocations: any;
  isRemoteWork?: boolean;
}) => {
  const { shifts } = offer || {};
  if (!shifts || !isRemoteWork) {
    return shifts;
  }
  // remote work
  return (shifts || []).map((shift) => {
    const { startTime: rawStartTime, endTime: rawEndTime } = shift;
    const location = !!shift.locationId && mappedLocations?.[shift.locationId];
    if (!location) {
      return { ...shift, startTime: rawStartTime, endTime: rawEndTime };
    }
    const startTime = momentTz
      .tz(rawStartTime, location?.timezoneName)
      .toDate();
    const endTime = momentTz.tz(rawEndTime, location?.timezoneName).toDate();

    return { ...shift, startTime, endTime };
  });
};

const compoundedNameBuilder = (
  offer: IOffer,
  mappedLabels: any,
  mappedLocations: any
) => {
  const { name } = offer;
  const label = !!offer.labelId && mappedLabels?.[offer.labelId];
  const location = !!offer.locationId && mappedLocations?.[offer.locationId];
  if (label) {
    return `${label.title}`;
  }
  return `${!!name && name.length > 0 ? `${name} - ` : ''}${
    location ? location.normalizedName : ''
  }`;
};

const selector = createCachedSelector(
  (data) => data?.data,
  (data, mappedLabels) => mappedLabels,
  (data, mappedLabels, mappedLocations) => mappedLocations,
  (data, mappedLabels, mappedLocations, mappedBranches) => mappedBranches,
  (data, mappedLabels, mappedLocations, mappedBranches) => {
    const offers = (data || [])
      .filter(
        (offer) =>
          !!offer?.branchId && !!mappedBranches[offer?.branchId]?.accountId
      )
      .map(
        (offer) =>
          ({
            ...offer,
            shifts: normalizedShiftTimes({
              offer,
              mappedLocations,
              isRemoteWork:
                offer?.branchId &&
                !!mappedBranches?.[offer?.branchId]?.schedulingConfig
                  ?.isRemoteWork,
            }),
            didApply: offer.shifts.some(
              (shift) =>
                shift.application &&
                shift.application.id > 0 &&
                !shift.application.isRejected
            ),
            didReject: offer.shifts.some(
              (shift) =>
                shift.application &&
                shift.application.id > 0 &&
                shift.application.isRejected
            ),
            isSeen: offer.shifts.some((value) => value.seen),
            compoundedName: compoundedNameBuilder(
              offer,
              mappedLabels,
              mappedLocations
            ),
            accountId:
              offer?.branchId && mappedBranches[offer?.branchId]?.accountId,
          } as IOffer)
      )
      .sort((a, b) =>
        new Date(a.date).getTime() - new Date(b.date).getTime() > 0 ? 1 : -1
      );

    const newOffersCount = offers.reduce(
      (acc, offer) => acc + (offer?.isSeen ? 0 : 1),
      0
    );
    const mappedOffers = mappedArray(offers) as {
      [key: number]: IOffer;
    }[];

    return { offers, mappedOffers, newOffersCount };
  }
)({
  keySelector: (
    data,
    mappedLabels,
    mappedLocations,
    mappedBranches,
    storeKey
  ) => storeKey,
});

export const useOffers = () => {
  const queryClient = useQueryClient();

  const { data: userData } = useUser();
  const { mappedLabels } = useLabels();
  const { mappedBranches } = useBranches();
  const { mappedLocations } = useLocations();

  const storeKey = ['offers'];
  const { isLoading, isPending, data, refetch, isStale } = useQuery<{
    data: IOffer[];
  }>({
    queryKey: storeKey,
    queryFn: () => api.getOffers(),
    enabled: !!userData?.id,
    retry: false, // for offline mode
    select: (data) =>
      selector(
        data,
        mappedLabels,
        mappedLocations,
        mappedBranches,
        storeKey.join('#')
      ),
  });

  const {
    offers = [],
    mappedOffers = {},
    newOffersCount = 0,
  } = useMemo(() => {
    const {
      offers: rawOffers = [],
      mappedOffers: rawMappedOffers = {},
      newOffersCount: rawNewOffersCount = 0,
    } = data || {};
    return {
      offers: rawOffers,
      mappedOffers: rawMappedOffers,
      newOffersCount: rawNewOffersCount,
    };
  }, [data]);

  type MarkAsReadParams = { offerId: number };

  const {
    mutateAsync: markAsRead,
    isError: isMarkReadError,
    isPending: isMarkReadLoading,
  } = useOptimisticMutation<
    { data: IOffer[] },
    MarkAsReadParams,
    { data: void }
  >(
    storeKey,
    (params: MarkAsReadParams) =>
      api.updateOfferStats({ offerId: params.offerId, seen: true }),
    ({ previousData, payload }) => {
      const newPreviousData = previousData.data.map((offer) =>
        offer.id === payload.offerId
          ? {
              ...offer,
              shifts: (offer.shifts || []).map((shift) => ({
                ...shift,
                seen: new Date(),
              })),
            }
          : offer
      );
      return { ...previousData, data: newPreviousData };
    },
    { refetchOnSuccess: false }
  );

  const { mutateAsync: editApplication, isPending: isEditingApplication } =
    useMutation<
      { data: IOffer[] },
      EditParams,
      IApplication & { projectId: number }
    >({
      mutationFn: (params: EditParams) => api.editApplication(params),
      onSuccess: (response, { projectId, shiftId }) => {
        const previousData = queryClient.getQueryData(storeKey) as {
          data: IOffer[];
        };
        const newOffers = previousData.data.map((offer) =>
          offer.id !== projectId
            ? offer
            : {
                ...offer,
                shifts: offer.shifts.map((shift) =>
                  shift.id !== shiftId
                    ? shift
                    : { ...shift, application: response.data }
                ),
              }
        );
        queryClient.setQueryData(storeKey, {
          ...previousData,
          data: newOffers,
        });
      },
    });

  const {
    mutateAsync: editBulkApplications,
    isPending: isEditingBulkApplications,
  } = useMutation<
    { data: IOffer[] },
    EditBulkParams,
    EditBulkParams & { shiftsIds: number[] }
  >({
    mutationFn: (params: EditBulkParams) => api.editBulkApplications(params),
    onSuccess: (response, { projectId, shiftsIds, isRejected }) => {
      const previousData = queryClient.getQueryData(storeKey) as {
        data: IOffer[];
      };

      const newOffers = previousData.data.map((offer) =>
        offer.id !== projectId
          ? offer
          : {
              ...offer,
              shifts: offer.shifts.map((shift) =>
                !shiftsIds.includes(shift.id)
                  ? shift
                  : {
                      ...shift,
                      application: { ...shift.application, isRejected },
                    }
              ),
            }
      );
      queryClient.setQueryData(storeKey, { ...previousData, data: newOffers });
    },
  });

  const { mutateAsync: addApplication, isPending: isAddingApplication } =
    useMutation<
      { data: IOffer[] },
      AddParams,
      IApplication & { projectId: number }
    >({
      mutationFn: (params: AddParams) => api.addApplication(params),
      onSuccess: (response, { projectId, shiftId }) => {
        const previousData = queryClient.getQueryData(storeKey) as {
          data: IOffer[];
        };
        const newOffers = previousData.data.map((offer) =>
          offer.id !== projectId
            ? offer
            : {
                ...offer,
                shifts: offer.shifts.map((shift) =>
                  shift.id !== shiftId
                    ? shift
                    : { ...shift, application: response.data }
                ),
              }
        );

        queryClient.setQueryData(storeKey, {
          ...previousData,
          data: newOffers,
        });
      },
    });

  const {
    mutateAsync: addBulkApplications,
    isPending: isAddingBulkApplications,
  } = useMutation<
    { data: IOffer[] },
    AddBulkParams & { projectId: number },
    AddBulkParams & { projectId: number }
  >({
    mutationFn: (params: AddBulkParams) => api.addBulkApplications(params),
    onSuccess: (response, { projectId, shiftsIds }) => {
      const previousData = queryClient.getQueryData(storeKey) as {
        data: IOffer[];
      };
      const mappedResponse = response?.data?.reduce((all, curr) => {
        if (!all[curr.shiftId]) {
          all[curr.shiftId] = [];
        }
        all[curr.shiftId] = curr;
        return all;
      }, {});

      ///todo ttypes
      ///
      ///
      const newOffers = previousData.data.map((offer) =>
        offer.id !== projectId
          ? offer
          : {
              ...offer,
              shifts: offer.shifts.map((shift) =>
                !shiftsIds.includes(shift.id)
                  ? shift
                  : { ...shift, application: mappedResponse?.[shift.id] }
              ),
            }
      );

      queryClient.setQueryData(storeKey, { ...previousData, data: newOffers });
    },
  });

  const { mutateAsync: deleteApplication, isPending: isDeletingApplication } =
    useMutation<{ data: IOffer[] }, DeleteParams, DeleteParams>({
      mutationFn: (params: DeleteParams) => api.deleteApplication(params),
      onSuccess: (response, { projectId, shiftId }) => {
        const previousData = queryClient.getQueryData(storeKey) as {
          data: IOffer[];
        };
        const newOffers = previousData.data.map((offer) =>
          offer.id !== projectId
            ? offer
            : {
                ...offer,
                shifts: offer.shifts.map((shift) =>
                  shift.id !== shiftId
                    ? shift
                    : { ...shift, application: undefined }
                ),
              }
        );

        queryClient.setQueryData(storeKey, {
          ...previousData,
          data: newOffers,
        });
      },
    });

  const {
    mutateAsync: deleteBulkApplications,
    isPending: isDeletingBulkApplications,
  } = useMutation<
    { data: IOffer[] },
    DeleteBulkParams,
    DeleteBulkParams & { shiftsIds: number[] }
  >({
    mutationFn: (params: DeleteBulkParams) =>
      api.deleteBulkApplications(params),
    onSuccess: (response, { projectId, shiftsIds }) => {
      const previousData = queryClient.getQueryData(storeKey) as {
        data: IOffer[];
      };
      const newOffers = previousData.data.map((offer) =>
        offer.id !== projectId
          ? offer
          : {
              ...offer,
              shifts: offer.shifts.map((shift) =>
                !shiftsIds.includes(shift.id)
                  ? shift
                  : { ...shift, application: undefined }
              ),
            }
      );
      queryClient.setQueryData(storeKey, { ...previousData, data: newOffers });
    },
  });

  return {
    isLoading: isPending,
    isInitialLoading: isLoading,
    offers,
    mappedOffers,
    addApplication,
    addBulkApplications,
    deleteApplication,
    deleteBulkApplications,
    editApplication,
    editBulkApplications,
    isAddingApplication,
    isAddingBulkApplications,
    isDeletingApplication,
    isDeletingBulkApplications,
    isEditingApplication,
    isEditingBulkApplications,
    markAsRead,
    isMarkReadError,
    isMarkReadLoading,
    newOffersCount,
    refetch,
    isStale,
  };
};
