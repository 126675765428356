import React, { FC, useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { IOffer, useUniforms } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FlexColumnDirection,
  FlexRowBetween,
  H5Regular,
  RipplePressable,
  HyperLink,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { Image as BaseImage, Linking } from 'react-native';
import styled from 'styled-components/native';
import { FeedScreenNames } from '../../feed';

const Container = styled(BaseView)`
  margin-top: 15px;
`;

const ColumnDetail = styled(FlexColumnDirection)`
  margin-bottom: 16px;
`;

const DataTitle = styled(H5Regular)`
  margin-left: 3px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  letter-spacing: 1.14px;
  color: ${({ theme }) => theme.colors.secondaryTitle};
`;

const DataDetails = styled(H5Regular)`
  margin-left: 3px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.86px;
  color: ${({ theme }) => theme.colors.gray15};
  max-width: 80%;
`;

const DataDetailsLink = styled(DataDetails)`
  color: ${({ theme }) => theme.colors.link};
`;

const Image = styled(BaseImage)`
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;
const ImageContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

interface Props {
  offer: IOffer;
  isProjectMultiDay: boolean;
  hideClient?: boolean;
  hideGeneralComments?: boolean;
}

interface DetailsProps {
  text: string;
}

export const OfferDetails: FC<Props> = ({
  isProjectMultiDay,
  offer: {
    uniformId,
    date,
    comments,
    clientName,
    onsiteContactPhone,
    onsiteContact,
    meetingSpot,
  },
  hideClient = false,
  hideGeneralComments = false,
}) => {
  const { mappedUniforms } = useUniforms();
  const { t } = useTranslation();
  const { navigate } = useNavigation();

  const projectDate = useMemo(() => moment(date).format('dddd, LL'), [date]);

  const openGallery = useCallback(
    (uri) => {
      navigate(FeedScreenNames.GALLERY, {
        items: [
          {
            idx: 0,
            metadata: {},
            type: 'image',
            uri,
          },
        ],
        idx: 0,
      });
    },
    [navigate]
  );

  return (
    <Container>
      {!isProjectMultiDay && <DataTitle>{t('jobDetails')}</DataTitle>}
      {!isProjectMultiDay && (
        <ColumnDetail>
          <DataDetails>{projectDate}</DataDetails>
        </ColumnDetail>
      )}
      {uniformId && (
        <ColumnDetail>
          <DataTitle>{t('uniform')}</DataTitle>
          <FlexRowBetween>
            <DataDetails>
              {mappedUniforms[uniformId]?.name}{' '}
              {mappedUniforms[uniformId]?.description}
            </DataDetails>
            {mappedUniforms[uniformId]?.image && (
              <ImageContainer
                as={RipplePressable}
                onPress={() => openGallery(mappedUniforms[uniformId]?.image)}
              >
                <Image source={{ uri: mappedUniforms[uniformId]?.image! }} />
              </ImageContainer>
            )}
          </FlexRowBetween>
        </ColumnDetail>
      )}
      {!hideGeneralComments && !!comments && (
        <ColumnDetail>
          <DataTitle>{t('notes')}</DataTitle>
          <HyperLink>
            <DataDetails>{comments}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}
      {!hideClient && !!clientName && (
        <ColumnDetail>
          <DataTitle>{t('clientName')}</DataTitle>
          <HyperLink>
            <DataDetails>{clientName}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}
      {!!meetingSpot && (
        <ColumnDetail>
          <DataTitle>{t('meetingSpot')}</DataTitle>
          <HyperLink>
            <DataDetails>{meetingSpot}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}
      {!!onsiteContact && (
        <ColumnDetail>
          <DataTitle>{t('onsiteContact')}</DataTitle>
          <HyperLink>
            <DataDetails>{onsiteContact}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}
      {!!onsiteContactPhone && (
        <ColumnDetail
          as={RipplePressable}
          onPress={() => Linking.openURL(`tel:${onsiteContactPhone}`)}
        >
          <DataTitle>{t('onsiteContactPhone')}</DataTitle>
          <DataDetailsLink>{onsiteContactPhone}</DataDetailsLink>
        </ColumnDetail>
      )}
    </Container>
  );
};
