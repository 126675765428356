import {
  CustomTimesheetDTO,
  IBranchTimesheetConfig,
  IEmployeeSlotBreakPlots,
} from '@violetta/ubeya/entities';
import React from 'react';
import { IReport } from '../interfaces';
import { TimeclockState } from '../interfaces/TimeclockState';

type TimeclockContextType = {
  onStartShift?: () => void;
  onEndShift?: () => void;
  onStartBreak?: (params: object) => void;
  onEndBreak?: () => void;
  onCheckInShift?: () => void;
  onCheckOutShift?: () => void;
  changing: TimeclockState | null;
  startTime: Date;
  endTime: Date;
  breakStartTime?: Date | null;
  checkIn: Date | null;
  checkOut: Date | null;
  config: IBranchTimesheetConfig | null;
  state: unknown;
  reports: IReport[];
  name: string;
  branchName?: string;
  employeeSlotBreakPlots: IEmployeeSlotBreakPlots[] | null;
  timesheet: CustomTimesheetDTO;
  setIsQrCodeScannerModalOpen?: (
    type: 'start' | 'end' | 'checkIn' | 'checkOut'
  ) => void;
  refetch: () => void;
  isLoadingRefetch: boolean;
  isTimeReportLoading: boolean;
};

const TimeclockContext = React.createContext<TimeclockContextType>(null);

export default TimeclockContext;
