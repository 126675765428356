import React, { FC } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { SvgIcon } from './SvgIcon';

interface Props {
  size?: number;
  rtl?: boolean;
  color?: string;
}

export const ChevronLeft: FC<Props> = ({
  size = 25,
  color = '#000000',
  rtl = false,
}) =>
  rtl ? (
    <SvgIcon
      xml={icons.cardIcons.chevronRight}
      width={size}
      height={size}
      color={color}
    />
  ) : (
    <SvgIcon
      xml={icons.cardIcons.chevronLeft}
      width={size}
      height={size}
      color={color}
    />
  );
