import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { Booking, useLocations, usePositions } from '@violetta/ubeya/entities';
import { useLocale, useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  FlexColumnDirection,
  FlexRow,
  FlexRowBetween,
  H3Bold,
  H6Medium,
  H7Regular,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import moment from 'moment';
import { Pressable } from 'react-native';
import { useQueryClient } from '@tanstack/react-query';
import styled, { useTheme } from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import { BookingsScreenNames } from '../../bookings/navigation/ScreenNames';

const Container = styled(BaseView)`
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #eaeaea;
  padding: 7px 0;
`;

const CardContent = styled(FlexRow)`
  justify-content: space-between;
`;
const ShiftDetails = styled(FlexColumnDirection)`
  width: 58%;
`;

const Actions = styled(FlexRowBetween)`
  justify-content: center;
  align-items: center;
  width: 42%;
`;

const ShiftPosition = styled(H6Medium)`
  font-weight: 500;
  margin-left: 10px;
`;

const ShiftHours = styled(H7Regular)`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  color: #252525;
`;

const RowDetail = styled(FlexRow)`
  align-items: center;
  margin-top: 8px;
`;

const ShiftLocation = styled(H7Regular)<{ bold?: boolean }>`
  margin-left: 8px;
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.86px;
  color: ${({ theme }) => theme.colors.gray15};
  width: 150px;
`;

const ButtonContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  display: flex;
`;

const ShiftDate = styled(H7Regular)`
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  color: #252525;
`;

const xContainer = styled(BaseView)`
  width: 36px;
  height: 36px;
  border-radius: 18px;
`;

const IconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 20px;
`;

const BookedLink = styled(H6Medium)`
  margin-right: 25px;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.primary};
`;

interface Props {
  isAutoBook: boolean;
  isLoading: boolean;
  shift: Booking['shifts'];
  projectLocationId?: number;
  isProjectMultiDay?: boolean;
}

export const BookedShiftCard: FC<Props> = React.memo(
  ({
    projectLocationId,
    shift: { id, startTime, endTime, positionId, locationId, comments },
    isProjectMultiDay = false,
  }) => {
    const { colors } = useTheme();
    const { locale } = useLocale();
    const { goBack, navigate } = useNavigation();
    const queryClient = useQueryClient();
    const { mappedPositions } = usePositions();
    const { mappedLocations } = useLocations();
    const { t } = useTranslation();
    const { dateFormat } = useContext(ConfigContext);

    const navigateBooking = useCallback(() => {
      navigate(BookingsScreenNames.MAIN, {
        screen: BookingsScreenNames.BOOKING,
        params: { bookingId: id },
      });
    }, [id, navigate]);

    const formattedHours = useMemo(
      () => rangeFormat(startTime, endTime),
      [startTime, endTime]
    );

    const formattedDate = useMemo(
      () =>
        isProjectMultiDay
          ? locale === 'he'
            ? `יום ${moment(startTime).format(`dddd, ${dateFormat}`)}`
            : moment(startTime).format(`dddd, ${dateFormat}`)
          : '',
      [dateFormat, isProjectMultiDay, locale, startTime]
    );

    return (
      <Container>
        <CardContent>
          <ShiftDetails>
            {!!formattedHours && (
              <>
                <RowDetail>
                  <ShiftHours>{formattedHours}</ShiftHours>
                </RowDetail>
                {!!formattedDate && (
                  <RowDetail>
                    <ShiftDate>{formattedDate}</ShiftDate>
                  </RowDetail>
                )}
              </>
            )}

            {positionId && (
              <RowDetail>
                <IconContainer>
                  <SvgIcon
                    xml={icons.uiIcons.jobCase}
                    width={22}
                    height={22}
                    color={colors.gray1}
                  />
                </IconContainer>
                <ShiftLocation>
                  {t(mappedPositions[positionId]?.slug)}
                </ShiftLocation>
              </RowDetail>
            )}
            {locationId && locationId !== projectLocationId && (
              <RowDetail>
                <IconContainer>
                  <SvgIcon
                    xml={icons.uiIcons.locationOutline}
                    width={18}
                    height={22}
                    color={colors.gray1}
                  />
                </IconContainer>
                <ShiftLocation numberOfLines={2}>
                  {mappedLocations[locationId]?.address}
                </ShiftLocation>
              </RowDetail>
            )}
            {!!comments && (
              <RowDetail>
                <IconContainer />
                <ShiftLocation numberOfLines={2} bold>
                  {comments}
                </ShiftLocation>
              </RowDetail>
            )}
          </ShiftDetails>
          <Actions>
            <ButtonContainer>
              <Button
                type="primaryReversed"
                title={t('booked')}
                paddingVertical={10}
                paddingHorizontal={20}
                fontSize={14}
                disabled
                disabledOnPress={navigateBooking}
                radius={20}
              />
            </ButtonContainer>
          </Actions>
        </CardContent>
      </Container>
    );
  }
);
