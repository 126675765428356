import { Alert } from '@violetta/ubeya/alert';
import { icons } from '@violetta/ubeya/assets';
import { IEmployee, TimesheetDTO } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { uiAlert } from '@violetta/ubeya/utils';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { Linking, Pressable } from 'react-native';
import { css } from 'styled-components';
import styled, { useTheme } from 'styled-components/native';
import { Button } from '../Buttons/Button';
import { InputField, StarRatingField } from '../FormFields';
import { ChevronDown, ChevronUp, SvgIcon } from '../Icons';
import { UserImage } from '../Images';
import {
  BaseView,
  FlexColumnDirection,
  FlexParent,
  FlexRow,
  FlexRowBetween,
} from '../Layout';
import { TimeChangeModal } from '../Modals/TimeChangeModal';
import { StarRating } from '../StarRating';
import { TextInput } from '../TextInputs/TextInput';
import { BaseText, H3Regular } from '../Typography';
import { BaseCollapsible } from './BaseCollapsible';

const Container = styled(FlexColumnDirection)`
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.colors.gray13};
  border-radius: 8px;
  padding: 22px 27px;
`;

const StarContainer = styled(BaseView)`
  width: 200px;
  margin: 10px 0;
`;

const InputContainer = styled(BaseView)``;

const RateShift = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray15};
`;

const TimesheetApprovalText = styled(BaseText)`
  color: ${({ $full, theme }) =>
    $full ? theme.colors.approve : theme.colors.textPrimary};
  font-size: ${({ $small }) => ($small ? 14 : 16)}px;
  margin-top: ${({ $marginTop }) => $marginTop || 0}px;
`;

const Section = styled(FlexParent)`
  border-bottom-color: ${({ theme }) => theme.colors.gray13};
  border-bottom-width: 1;

  ${({ $topBorder }) =>
    $topBorder &&
    css`
      border-top-color: ${({ theme }) => theme.colors.gray13};
      border-top-width: 1;
    `}
  padding: 10px 0;
`;

const DeclineSection = styled(FlexColumnDirection)``;
const TitleSection = styled(FlexParent)`
  margin-bottom: 10px;
`;

const EmployeeName = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  width: 160px;
`;

const Dash = styled(H3Regular)`
  margin: 0 5px;
`;

const FormContainer = styled(FlexRow)`
  align-items: center;
`;

const Details = styled(FlexRowBetween)``;

const TriggerHeader = styled(FlexRowBetween)`
  width: 100%;
  padding: 10px 0;
`;

const TimeText = styled(BaseText)`
  font-size: 14px;
  color: #252525;
  text-decoration: underline;
  font-weight: 300;
`;

const ApproveButton = styled(Button)`
  padding: 8px 16px;
`;

const DeclineButton = styled(Button)`
  width: 100px;
  padding: 8px 16px;
  margin-right: 10px;
  background-color: #f7f8fa;
`;

const Wrapper = styled(BaseView)`
  width: 100%;
  margin-bottom: 20px;
`;

const ButtonContainer = styled(FlexParent)`
  margin: 5px 0 10px 0;
`;

const inputStyle = (colors) => ({
  borderTopWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  marginBottom: 10,
  paddingLeft: 0,
  paddingBottom: 0,
  fontSize: 14,
  color: colors.textPrimary,
  backgroundColor: 'transparent',
});

interface Props {
  employee: IEmployee;
  startTime: Date | null;
  endTime: Date | null;
  rating: number;
  timesheet: TimesheetDTO;
  onUpdateTime: (values: any) => void;
  onUpdateSlot: (values: any) => void;
  onApproveTimesheet?: () => void;
  onDeclineTimesheet?: () => void;
  shift: any;
  review: string;
  payrollNotes: string;
  notes?: string;
  hidePayrollNotes?: boolean;
  showNotes?: boolean;
  showTimesheetApproval?: boolean;
}

interface TimesheetApprovalSectionProps {
  onApproveTimesheet: () => void;
  onDeclineTimesheet: () => void;
  timesheet: TimesheetDTO;
}

export const TimesheetApprovalSection: FC<TimesheetApprovalSectionProps> = ({
  onApproveTimesheet,
  onDeclineTimesheet,
  timesheet,
}) => {
  const { t } = useTranslation();
  const {
    employeeApproved = null,
    clientApproved = null,
    clientDeclined = null,
    employeeDeclined = null,
  } = timesheet || {};

  const existsClientResponse = !!clientApproved || !!clientDeclined;
  const existsEmployeeResponse = !!employeeApproved || !!employeeDeclined;

  const approvedByEmployeeAndClient = !!clientApproved && !!employeeApproved;

  const timesheetIconStyle = {
    marginRight: 5,
  };

  return (
    <Wrapper>
      <TitleSection>
        <SvgIcon
          style={{
            ...timesheetIconStyle,
            display: approvedByEmployeeAndClient ? 'flex' : 'none',
          }}
          width={23}
          height={23}
          xml={icons.timesheetApprovalIcons.timesheetGeneralApproved}
        />
        <TimesheetApprovalText $full={approvedByEmployeeAndClient}>
          {t(
            approvedByEmployeeAndClient
              ? 'timesheetApproved'
              : 'timesheetApprovalSection'
          )}
        </TimesheetApprovalText>
      </TitleSection>

      {!existsClientResponse && (
        <ButtonContainer>
          <DeclineButton
            lowercase
            fontSize={16}
            paddingHorizontal={0}
            type="primaryReversed"
            onPress={onDeclineTimesheet}
            title={t('decline') || ''}
          />

          <ApproveButton
            lowercase
            fontSize={16}
            paddingHorizontal={0}
            type="primary"
            onPress={onApproveTimesheet}
            title={t('approve') || ''}
          />
        </ButtonContainer>
      )}

      {clientApproved && (
        <Section>
          <SvgIcon
            style={timesheetIconStyle}
            width={23}
            height={23}
            xml={icons.timesheetApprovalIcons.timesheetApproved}
          />
          <TimesheetApprovalText>{t('client')}</TimesheetApprovalText>
        </Section>
      )}

      {clientDeclined && (
        <Section>
          <SvgIcon
            style={timesheetIconStyle}
            width={23}
            height={23}
            xml={icons.timesheetApprovalIcons.timesheetDeclined}
          />
          <DeclineSection>
            <TimesheetApprovalText>{t('client')}</TimesheetApprovalText>
            {clientDeclined?.reason && (
              <TimesheetApprovalText $small $marginTop={5}>
                {clientDeclined.reason}
              </TimesheetApprovalText>
            )}
          </DeclineSection>
        </Section>
      )}

      {!existsEmployeeResponse && (
        <Section>
          <SvgIcon
            style={timesheetIconStyle}
            width={23}
            height={23}
            xml={icons.timesheetApprovalIcons.timesheetNoAnswer}
          />
          <TimesheetApprovalText>{t('employee')}</TimesheetApprovalText>
        </Section>
      )}

      {employeeApproved && (
        <Section>
          <SvgIcon
            style={timesheetIconStyle}
            width={23}
            height={23}
            xml={icons.timesheetApprovalIcons.timesheetApproved}
          />
          <TimesheetApprovalText>{t('employee')}</TimesheetApprovalText>
        </Section>
      )}

      {employeeDeclined && (
        <Section>
          <SvgIcon
            style={timesheetIconStyle}
            width={23}
            height={23}
            xml={icons.timesheetApprovalIcons.timesheetDeclined}
          />
          <DeclineSection>
            <TimesheetApprovalText>{t('employee')}</TimesheetApprovalText>
            {employeeDeclined?.reason && (
              <TimesheetApprovalText>
                {employeeDeclined.reason}
              </TimesheetApprovalText>
            )}
          </DeclineSection>
        </Section>
      )}
    </Wrapper>
  );
};

export const AdminEmployeeCollapsible: FC<Props> = ({
  employee,
  startTime,
  endTime,
  rating,
  onUpdateTime,
  onUpdateSlot,
  onApproveTimesheet,
  timesheet,
  onDeclineTimesheet,
  shift,
  review,
  payrollNotes,
  hidePayrollNotes,
  notes,
  showNotes = true,
  showTimesheetApproval = false,
}) => {
  const { t } = useTranslation();
  const [isStartModalOpen, setIsStartModalOpen] = useState(false);
  const [isEndModalOpen, setIsEndModalOpen] = useState(false);
  const [timeState, setTimeState] = useState({ startTime, endTime });
  const { colors } = useTheme();

  useEffect(() => {
    setTimeState({ startTime, endTime });
  }, [endTime, startTime]);

  const call = useCallback(() => {
    if (!employee?.phone) {
      Alert.alert(t('contactHasNoPhone'));
      return;
    }
    const url = `tel:${`+${employee?.phone}`}`;
    if (!Linking.canOpenURL(url)) {
      Alert.alert('Can not call from this device');
      return;
    }
    Linking.openURL(url);
  }, [employee?.phone, t]);

  const openSms = useCallback(() => {
    if (!employee?.phone) {
      Alert.alert(t('contactHasNoPhone'));
      return;
    }
    const url = `sms:${employee?.phone}`;
    if (!Linking.canOpenURL(url)) {
      Alert.alert('Can not send SMS from this device');
      return;
    }
    Linking.openURL(url);
  }, [employee?.phone, t]);

  const shiftStartTime = useMemo(() => shift?.startTime, [shift?.startTime]);
  const shiftEndTime = useMemo(() => shift?.endTime, [shift?.endTime]);

  const employeeName = useMemo(
    () => `${employee?.firstName || ''} ${employee?.lastName || ''}`,
    [employee?.firstName, employee?.lastName]
  );

  const handleCloseStartModal = useCallback(() => {
    setIsStartModalOpen(false);
  }, []);

  const handleCloseEndModal = useCallback(() => {
    setIsEndModalOpen(false);
  }, []);

  const captainAutoClock = useCallback(
    async (name: string) => {
      const res = await uiAlert({
        title:
          name === 'startTime'
            ? t('captainAutoClockAreYouSureClockIn', { employeeName })
            : t('captainAutoClockAreYouSureClockOut', { employeeName }),
        message: '',
        cancel: {
          text: t('no'),
        },
        save: {
          text: t('yes'),
        },
      });

      if (!res) {
        return;
      }

      setTimeState({
        ...timeState,
        [name]: new Date(),
      });
      onUpdateTime({
        values: { ...timeState, [name]: new Date() },
      });
    },
    [employeeName, onUpdateTime, t, timeState]
  );

  if (!employee) {
    return null;
  }

  const Trigger = React.memo(({ isCollapsed }) => (
    <TriggerHeader>
      <FlexRowBetween>
        <UserImage
          size={30}
          firstName={employee?.firstName}
          lastName={employee?.lastName}
          image={employee?.image}
        />
        {isCollapsed ? <ChevronDown /> : <ChevronUp />}
        <EmployeeName>{employeeName}</EmployeeName>
      </FlexRowBetween>
      <Details key={employee?.id}>
        <FormContainer>
          <Pressable onPress={() => setIsStartModalOpen(true)}>
            <TimeText>
              {moment(timeState?.startTime).isValid()
                ? moment(timeState?.startTime).format('LT')
                : t('timePickerStartPlaceholder')}
            </TimeText>
          </Pressable>
          <Dash>-</Dash>
          <Pressable
            onPress={() =>
              timeState?.startTime ? setIsEndModalOpen(true) : {}
            }
          >
            <TimeText>
              {moment(timeState?.endTime).isValid()
                ? moment(timeState?.endTime).format('LT')
                : t('timePickerEndPlaceholder')}
            </TimeText>
          </Pressable>
        </FormContainer>
      </Details>
    </TriggerHeader>
  ));

  const existsClockInOut = !!timesheet?.startTime && !!timesheet?.endTime;
  return (
    <BaseCollapsible
      trigger={Trigger}
      containerStyle={{ marginBottom: 10 }}
      hideRightChevron
      withBorder
    >
      <Form
        keepDirtyOnReinitialize
        onSubmit={() => {}}
        initialValues={{
          rating,
          review,
          payrollNotes,
          notes,
        }}
        render={() => {
          return (
            <Container>
              {showTimesheetApproval &&
                existsClockInOut &&
                !!onApproveTimesheet &&
                !!onDeclineTimesheet && (
                  <TimesheetApprovalSection
                    onApproveTimesheet={onApproveTimesheet}
                    onDeclineTimesheet={onDeclineTimesheet}
                    timesheet={timesheet}
                  />
                )}

              <RateShift>
                {t('rate')} {employee?.firstName}
              </RateShift>
              <FormSpy
                subscription={{ values: true, dirtyFields: true }}
                onChange={onUpdateSlot}
              />
              <StarContainer>
                <StarRatingField
                  component={StarRating}
                  fieldProps={{
                    name: 'rating',
                  }}
                  props={{}}
                />
              </StarContainer>
              <InputContainer>
                {showNotes && (
                  <InputField
                    fieldProps={{
                      name: 'notes',
                    }}
                    props={{
                      placeholder: t('bookingNotes'),
                      style: inputStyle(colors),
                      placeholderTextColor: colors.gray2,
                    }}
                    component={TextInput}
                  />
                )}
                <InputField
                  fieldProps={{
                    name: 'review',
                  }}
                  props={{
                    placeholder: t('review'),
                    style: inputStyle(colors),
                    placeholderTextColor: colors.gray2,
                  }}
                  component={TextInput}
                />
                {!hidePayrollNotes && (
                  <InputField
                    fieldProps={{
                      name: 'payrollNotes',
                    }}
                    component={TextInput}
                    props={{
                      placeholder: t('payrollNotes'),
                      placeholderTextColor: colors.gray2,
                      style: inputStyle(colors),
                    }}
                  />
                )}
              </InputContainer>
              <ButtonContainer>
                <Button
                  textStyle={{
                    fontWeight: 'normal',
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'solid',
                    letterSpacing: 0.2,
                  }}
                  fontSize={16}
                  paddingHorizontal={0}
                  type="transparent"
                  onPress={openSms}
                  title={t('message')}
                />
                <Button
                  textStyle={{
                    fontWeight: 'normal',
                    textDecorationLine: 'underline',
                    textDecorationStyle: 'solid',
                    letterSpacing: 0.2,
                  }}
                  fontSize={16}
                  paddingHorizontal={0}
                  type="transparent"
                  onPress={call}
                  title={t('call')}
                />
                <BaseView />
              </ButtonContainer>
            </Container>
          );
        }}
      />
      <TimeChangeModal
        isOpen={isStartModalOpen}
        values={timeState}
        onStateChange={setTimeState}
        shiftTime={shiftStartTime}
        onTimeChange={onUpdateTime}
        onClose={handleCloseStartModal}
        employeeName={employeeName}
        name="startTime"
      />
      <TimeChangeModal
        isOpen={isEndModalOpen}
        values={timeState}
        onTimeChange={onUpdateTime}
        onStateChange={setTimeState}
        shiftTime={shiftEndTime}
        onClose={handleCloseEndModal}
        employeeName={employeeName}
        name="endTime"
      />
    </BaseCollapsible>
  );
};
