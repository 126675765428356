import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  CustomLoaderWrapped,
  FlexColumnBetween,
  FlexColumnDirection,
  H2Bold,
  H3Medium,
  H4Bold,
  H4Medium,
  H6Medium,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { openMap, useDuration } from '@violetta/ubeya/utils';
import moment from 'moment';
import React, { FC, useContext, useMemo } from 'react';
import styled from 'styled-components/native';
import { TimeclockState } from '../interfaces';
import TimeclockContext from './TimeclockContext';
import { useLocations } from '@violetta/ubeya/entities';

const Container = styled(BaseView)`
  display: flex;
  flex: 1;
  width: 100%;
`;

const LoadingContainer = styled(BaseView)`
  top: 30px;
  width: 100%;
  justify-content: space-between;
`;

const BottomContentContainer = styled(FlexColumnBetween)`
  align-items: center;
  width: 100%;
  padding: 0 28px;
  flex: 1;
`;

const TopContainer = styled(FlexColumnDirection)`
  align-items: center;
  width: 100%;
`;

const NextShiftText = styled(H4Medium)`
  margin-top: 10px;
  font-weight: 400;
  font-size: 16px;
`;

interface Props {}

export const ClockStart: FC<Props> = () => {
  const { timesheet, changing } = useContext(TimeclockContext);
  const startTime = timesheet?.scheduledStartTime;

  const { t } = useTranslation();
  const { clock } = useDuration(startTime);
  const { mappedLocations } = useLocations();

  const loading = useMemo(
    () => changing === TimeclockState.TimeclockStarted,
    [changing]
  );
  const now = useMemo(() => moment(), []);
  const shiftAlreadyStarted = useMemo(
    () => now.diff(moment(startTime), 'seconds') > 0,
    [now, startTime]
  );
  const startsAt = useMemo(
    () => moment(startTime).format('HH:mm:ss'),
    [startTime]
  );
  const isScheduleStartTimeValid = useMemo(
    () => !!startTime && moment(startTime).isValid(),
    [startTime]
  );

  return (
    <Container>
      <BottomContentContainer>
        <TopContainer>
          {isScheduleStartTimeValid && (
            <NextShiftText>
              {`${
                shiftAlreadyStarted
                  ? t('yourNextShiftStartedAt')
                  : t('yourNextShiftStartsIn')
              } ${!loading && (shiftAlreadyStarted ? startsAt : clock)}`}
            </NextShiftText>
          )}
          {loading && (
            <LoadingContainer>
              <CustomLoaderWrapped />
            </LoadingContainer>
          )}
        </TopContainer>
      </BottomContentContainer>
    </Container>
  );
};
