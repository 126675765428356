import React, { FC, useCallback } from 'react';
import {
  BottomTabBarOptions,
  BottomTabBarProps,
} from '@react-navigation/bottom-tabs';
import styled from 'styled-components/native';
import {
  BaseView,
  FlexColumnCenter,
  RipplePressable,
  TAB_BAR_HEIGHT,
} from '../Layout';
import { BaseText, H7Regular } from '../Typography';
import { TabIcon } from './TabIcon';

const Container = styled(BaseView)`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface};
  height: ${TAB_BAR_HEIGHT}px;
  shadow-color: rgba(0, 0, 0, 0.12);
  shadow-opacity: 0.4;
  shadow-offset: 0px -1px;
  shadow-radius: 4px;
  elevation: 5;
`;

const TabBarText = styled(BaseText)`
  color: ${({ active, theme }) =>
    active ? theme.colors.primary : theme.colors.textPrimary};
  font-size: 12px;
  font-weight: 300;
`;

const IconContainer = styled(FlexColumnCenter)`
  flex: 1;
  height: 100%;
  border-top-width: 1.5px;
  border-top-color: ${({ theme, active }) =>
    active ? theme.colors.primary : 'transparent'};
`;

const Notified = styled(BaseView)`
  position: absolute;
  right: 5px;
  top: 5px;
  height: 15px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.chip};
`;

const EmptyNotified = styled(BaseView)`
  position: absolute;
  right: 15px;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  background-color: ${({ theme }) => theme.colors.chip};
`;

const NotifText = styled(H7Regular)`
  color: ${({ theme }) => theme.colors.white};
`;

export enum TabNames {
  HOME = 'drawer-nav',
  MORE = 'more',
  FEED = 'feed',
  CHAT = 'chat',
  OFFERS = 'offers',
  SCHEDULE = 'schedule',
}

type TabItem = {
  name: string;
  displayName: string;
  screen: string;
  icons: { active: string; normal: string };
  notified?: boolean;
};

export const TabBar: FC<
  BottomTabBarProps<BottomTabBarOptions> & { items: TabItem[] }
> = ({ state, navigation, items }) => {
  const { navigate } = navigation;

  const handlePress = useCallback(
    (name, screen) => {
      navigate(name, { screen });
    },
    [navigate]
  );

  return (
    <Container>
      {items.map(
        (
          {
            name,
            screen,
            icons: { active, normal },
            notified,
            displayName,
            notifNum,
          },
          index
        ) => {
          const isActive =
            state.routeNames[state.index] === screen ||
            state.routeNames[state.index] === name;
          return (
            <IconContainer
              key={index}
              active={isActive}
              as={RipplePressable}
              onPress={() => handlePress(name, screen)}
            >
              <TabIcon
                key={name}
                icon={isActive ? active : normal}
                notified={notifNum}
              />
              <TabBarText active={isActive}>{displayName}</TabBarText>
              {notifNum === -1 && <EmptyNotified />}
              {notifNum > 0 && (
                <Notified>
                  <NotifText>{notifNum}</NotifText>
                </Notified>
              )}
            </IconContainer>
          );
        }
      )}
    </Container>
  );
};
