import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import buffer from 'buffer';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { FieldTypes } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { isFileImage, uiAlert, pickImg } from '@violetta/ubeya/utils';
import moment from 'moment';
import { useField } from 'react-final-form';
import { Platform } from 'react-native';
import DocumentPicker from 'react-native-document-picker';
import RNFS from 'react-native-fs';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';

import styled from 'styled-components/native';

import { ComplianceDocumentCard } from '../ComplianceDocument';
import { DateInputPicker } from '../DatePicker';
import { FormGroup } from '../Forms';
import { SvgIcon } from '../Icons';
import {
  BaseView,
  FlexColumn,
  FlexParent,
  FlexRowBetween,
  RipplePressable,
} from '../Layout';
import { CustomLoader } from '../Loaders/CustomLoader';
import { BaseText, H3Bold } from '../Typography';
import { DatePickerField } from './DatePickerField';

const ALLOWED_DOC_UPLOAD_TYPES = [
  DocumentPicker.types.video,
  DocumentPicker.types.doc,
  DocumentPicker.types.docx,
  DocumentPicker.types.pdf,
  DocumentPicker.types.xls,
  DocumentPicker.types.xlsx,
];

const Container = styled(BaseView)`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const InnerWrapper = styled(FlexColumn)`
  margin: 20px 0;
  align-items: center;
  justify-content: center;
`;

const { Buffer } = buffer;

const MainTitle = styled(BaseText)`
  font-size: 30px;
  margin: 30px 0 20px;
  text-align: center;
`;

const SubTitle = styled(BaseText)`
  font-size: 14px;
  text-align: center;
`;

const Wrapper = styled(BaseView)`
  margin: 40px 0;
`;

const ModalContainer = styled(BaseView)`
  padding-bottom: 30px;
`;

const ModalSection = styled(BaseView)`
  padding: 20px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
`;

const ModalRow = styled(FlexRowBetween)`
  align-items: center;
`;

const ModalRowText = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  color: #1e2534;
`;

const ModalHeader = styled(FlexParent)`
  justify-content: flex-start;
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 12px;
`;

const Title = styled(H3Bold)``;

interface Props {
  modalizeRef: any;
}

const UploadOptionsModal: FC<Props> = ({
  modalizeRef,
  uploadImage,
  uploadFile,
}) => {
  const [scrollEnabled, setScrollEnabled] = useState(true);
  const { t } = useTranslation();

  const close = useCallback(() => {
    modalizeRef.current?.close();
  }, [modalizeRef]);

  return (
    <Portal>
      <Modalize
        adjustToContentHeight
        ref={modalizeRef}
        handlePosition="inside"
        disableScrollIfPossible={Platform.OS !== 'android'}
        onBackButtonPress={close}
        onOverlayPress={close}
        panGestureEnabled={scrollEnabled}
        scrollViewProps={{ scrollEnabled }}
        withHandle={false}
        HeaderComponent={
          <ModalHeader>
            <Title>{t('uploadOption')}</Title>
          </ModalHeader>
        }
      >
        <ModalContainer>
          <ModalSection>
            <ModalRow as={RipplePressable} onPress={uploadImage}>
              <ModalRowText>{t('uploadImage')}</ModalRowText>
            </ModalRow>
          </ModalSection>
          <ModalSection>
            <ModalRow as={RipplePressable} onPress={uploadFile}>
              <ModalRowText>{t('uploadFile')}</ModalRowText>
            </ModalRow>
          </ModalSection>
        </ModalContainer>
      </Modalize>
    </Portal>
  );
};

export const UploadComplianceDocumentField = ({ field, id }) => {
  const { t } = useTranslation();
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const {
    input: { value: documentFieldValue, onChange: setDocumentFieldValue },
  } = useField(`field-${id}-0`);

  const modalizeRef = useRef<Modalize>(null);

  const { navigate } = useNavigation();

  const hasExpiration = useMemo(() => field?.hasExpiration, [field]);

  const pickImgMethod = useCallback(async () => {
    try {
      setIsLoadingFile(true);
      const assets = await pickImg({
        texts: {
          cancel: t('cancel'),
          choosePhoto: t('choosePhoto'),
          takeAPhoto: t('takePhoto'),
          title: t('chooseImageUploadMethod'),
        },
        image: {
          includeBase64: true,
          mediaType: 'photo',
          maxWidth: 1000,
          maxHeight: 1000,
          quality: 0.7,
        },
      });

      if (!assets) {
        setIsLoadingFile(false);
        return;
      }

      const parsedAssets = await Promise.all(
        assets.map(async ({ type, uri, base64 }) => ({
          fieldTypeId: FieldTypes.FIELD_TYPE_DOCUMENT,
          content: base64,
          type,
          link: uri,
          ...(hasExpiration && { expirationDate: moment().add(1, 'days') }),
        }))
      );

      setDocumentFieldValue(parsedAssets);
      setIsLoadingFile(false);
    } catch (err) {
      setIsLoadingFile(false);
    }
  }, [hasExpiration, setDocumentFieldValue, t]);

  const pickDoc = useCallback(async () => {
    try {
      setIsLoadingFile(true);
      const res = await DocumentPicker.pick({
        type: ALLOWED_DOC_UPLOAD_TYPES,
        allowMultiSelection: false,
      });
      const parsedRes = res.map((r) => ({
        ...r,
        name: r.name.replaceAll(' ', '_'),
        uri: r.uri.replaceAll('%20', ' '),
      }));

      const parsedAssets = await Promise.all(
        parsedRes.map(async ({ uri, type, name }) => {
          const content = await RNFS.readFile(decodeURI(uri), 'base64');

          return {
            fieldTypeId: FieldTypes.FIELD_TYPE_DOCUMENT,
            content,
            title: name || '',
            type,
            link: uri,
            ...(hasExpiration && { expirationDate: moment().add(1, 'days') }),
          };
        })
      );

      setDocumentFieldValue(parsedAssets);
      setIsLoadingFile(false);
    } catch (err) {
      setIsLoadingFile(false);
    }
  }, [hasExpiration, setDocumentFieldValue]);

  const readWebFile = useCallback(
    (e) => {
      setIsLoadingFile(true);
      let parsedAssets = [];
      (Array.from(e.target.files) || []).forEach((file) => {
        const uri = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (ev) => {
          const tempRes = ev?.target?.result?.toString().split(',')?.[1] || '';
          parsedAssets = [
            ...parsedAssets,
            {
              fieldTypeId: FieldTypes.FIELD_TYPE_DOCUMENT,
              content: tempRes,
              title: file?.name || '',
              type: file?.type,
              link: uri,
              ...(hasExpiration && { expirationDate: moment().add(1, 'days') }),
            },
          ];
          setDocumentFieldValue(parsedAssets);
        };
      });
      setIsLoadingFile(false);
    },
    [hasExpiration, setDocumentFieldValue]
  );

  const openAttachmentImagesGallery = useCallback(
    ({ id: selectedId }) => {
      const images = (documentFieldValue || []).filter(
        (document) => document?.link && isFileImage(document?.link)
      );
      const selectedIdx = images.findIndex(({ id }) => selectedId === id);
      if (selectedIdx === -1) {
        return;
      }
      navigate('gallery', {
        items: images.map(({ link }, idx) => ({
          idx,
          metadata: {},
          type: 'image',
          uri: link,
          ...(hasExpiration && { expirationDate: moment().add(1, 'days') }),
        })),
        idx: selectedIdx,
      });
    },
    [documentFieldValue, hasExpiration, navigate]
  );

  const onRemoveItem = async ({ item }) => {
    const tempDocFieldValue = documentFieldValue;
    if (tempDocFieldValue) {
      const res = await uiAlert({
        title: t('areYouSure'),
        save: {
          text: t('yes'),
        },
        cancel: {
          text: t('no'),
        },
      });
      if (!res) {
        return;
      }

      const filteredDocFieldValue = tempDocFieldValue.filter(
        (val) => val?.link !== item?.link
      );
      setDocumentFieldValue(filteredDocFieldValue);
    }
  };

  if (isLoadingFile) {
    return <CustomLoader />;
  }

  if (!documentFieldValue || documentFieldValue.length === 0) {
    return (
      <Container>
        <InnerWrapper>
          <MainTitle>{field?.name}</MainTitle>
          <SubTitle>{t('addDocumentDescription')}</SubTitle>
        </InnerWrapper>

        <Wrapper
          as={RipplePressable}
          onPress={
            Platform.OS !== 'web'
              ? () => modalizeRef?.current?.open()
              : () => {}
          }
        >
          <SvgIcon
            width={200}
            height={200}
            xml={icons.complianceIcons.complianceUploadDocument}
          />
        </Wrapper>

        {Platform.OS === 'web' && (
          <input
            type="file"
            accept="image/*, video/*"
            onChange={readWebFile}
            style={{ width: 75, height: 45, position: 'absolute', opacity: 0 }}
          />
        )}
        {Platform.OS === 'web' && (
          <input
            type="file"
            accept={ALLOWED_DOC_UPLOAD_TYPES.join(', ')}
            onChange={readWebFile}
            style={{
              width: 60,
              height: 45,
              position: 'absolute',
              left: 90,
              opacity: 0,
            }}
          />
        )}

        {Platform.OS !== 'web' && (
          <UploadOptionsModal
            modalizeRef={modalizeRef}
            uploadImage={pickImgMethod}
            uploadFile={pickDoc}
          />
        )}
      </Container>
    );
  }

  return (
    <Container>
      <MainTitle>{field?.name}</MainTitle>

      {documentFieldValue
        ?.filter((document) => !!document.link)
        .map((document) => (
          <ComplianceDocumentCard
            key={document?.content}
            document={{
              expirationDate: document?.expirationDate,
              link: document?.link,
              title: document?.title,
            }}
            openAttachmentImagesGallery={openAttachmentImagesGallery}
            onRemoveItem={onRemoveItem}
          />
        ))}

      {hasExpiration && documentFieldValue && (
        <FormGroup
          error={
            documentFieldValue[0].expirationDate &&
            moment().isSameOrAfter(moment(documentFieldValue[0].expirationDate))
          }
          title={t('expirationDate')}
          isRequired
        >
          <DatePickerField
            fieldProps={{
              name: `field-${id}-0[0].expirationDate`,
            }}
            props={{}}
            component={DateInputPicker}
          />
        </FormGroup>
      )}
    </Container>
  );
};
