import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { useSequences } from './useSequences';
import { Platform } from 'react-native';

export const promptSequenceAtom = atom<boolean>({
  key: 'promptSequenceAtom',
  default: false,
});

interface Options {
  onPrompt?: (sequenceId: number, instanceId: number) => void;
}

export const useSequencePrompter = ({ onPrompt }: Options) => {
  const { sequences } = useSequences();
  const [promptSequence, setPromptSequence] =
    useRecoilState(promptSequenceAtom);

  useEffect(() => {
    if (promptSequence || Platform.OS === 'web') {
      return;
    }

    const sequence =
      sequences &&
      (sequences || []).find(({ isPrompt, isDone }) => isPrompt && !isDone);
    if (!sequence) {
      return;
    }
    onPrompt?.(sequence.id, sequence.instances[0]);
    setPromptSequence(true);
  }, [sequences, onPrompt, promptSequence, setPromptSequence]);

  return {};
};
