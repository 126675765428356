import { Booking } from '@violetta/ubeya/entities';
import { get } from 'lodash';
import moment from 'moment';

export const API_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const getFileExtension = (link: string) =>
  link?.split('.')?.slice(-1)?.pop()?.split('?')?.[0];
export const getFileName = (link: string) =>
  link?.split('/')?.slice(-1)?.pop()?.split('?')?.[0];
export const compoundedNameBuilder = (
  booking: Booking,
  mappedLabels: any,
  mappedLocations: any
) => {
  if (!booking) {
    return null;
  }

  const label =
    !!booking?.project?.labelId && mappedLabels?.[booking.project.labelId];
  const locationId = booking?.locationId || booking?.project?.locationId;

  const location = !!locationId && mappedLocations?.[locationId];

  if (label) {
    return `${label.title}`;
  }
  return [booking?.project?.name, location?.normalizedName]
    .filter((a) => !!a)
    .join(' - ');
};

export const enumerateDaysBetweenDates = (
  startDate: moment.Moment,
  endDate: moment.Moment
) => {
  const now = startDate.clone();
  const dates: string[] = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format('YYYY-MM-DD'));
    now.add(1, 'days');
  }
  return dates;
};

export const SORT_BY_DATE_AND_TIME =
  (key, format = null, reverse = false) =>
  (a, b) => {
    const aValue = get(a, key);
    const bValue = get(b, key);

    if (aValue && !bValue) {
      return reverse ? 1 : -1;
    }
    if (bValue && !aValue) {
      return reverse ? -1 : 1;
    }
    if (!aValue && !bValue) {
      return 0;
    }
    if (reverse) {
      return (
        moment(bValue, format ?? API_DATE_TIME_FORMAT) -
        moment(aValue, format ?? API_DATE_TIME_FORMAT)
      );
    }
    return (
      moment(aValue, format ?? API_DATE_TIME_FORMAT) -
      moment(bValue, format ?? API_DATE_TIME_FORMAT)
    );
  };

export const isEndTimeBeforeStartTime = (
  startTime,
  endTime,
  includeEqualStartEndTIme = true
) =>
  endTime.get('hour') < startTime.get('hour') ||
  (endTime.get('hour') === startTime.get('hour') &&
    (includeEqualStartEndTIme
      ? endTime.get('minute') <= startTime.get('minute')
      : endTime.get('minute') < startTime.get('minute')));

// update start time and end time with the correct date
export const getTimeRangeWithDates = ({
  date,
  startTime,
  endTime,
  checkBaseStartDate = false,
  // consider 10:00 start time and 10:00 end time as in different dates
  includeEqualStartEndTIme = true,
}) => {
  const startDate = moment(date);
  const endDate = moment(date);
  const endDateWithExtraDay = moment(date);

  const startMoment = moment(startTime);
  const endMoment = moment(endTime);

  const start = startMoment.isValid()
    ? startDate.set({
        hour: startMoment.get('hour'),
        minute: startMoment.get('minute'),
        seconds: 0,
      })
    : null;

  // get the endTime after adding a day
  const endTimeWithExtraDay =
    endTime && endMoment.isValid()
      ? endDateWithExtraDay.set({
          date: endDateWithExtraDay.add(1, 'day').get('date'),
          hour: endMoment.get('hour') || '',
          minute: endMoment.get('minute') || '',
          seconds: 0,
        })
      : null;

  // get the endTime withouth adding a day
  const endTimeWithoutExtraDay =
    endTime && endMoment.isValid()
      ? endDate.set({
          date: endDate.get('date'),
          hour: endMoment.get('hour') || '',
          minute: endMoment.get('minute') || '',
          seconds: 0,
        })
      : null;

  const endTimeBeforeStartTime = isEndTimeBeforeStartTime(
    startMoment,
    endMoment,
    includeEqualStartEndTIme
  );
  let addExtraDay = endTimeBeforeStartTime;

  /* conditions:
    1. startTime date is before the event date.
    2. endTime is before startTime (meaning there is a need to add extra day) 
    3. endTimeWithExtraDay is not null
  */
  const calaculateIfExtraDay =
    checkBaseStartDate &&
    start &&
    moment(startTime).isBefore(start) &&
    endTimeBeforeStartTime &&
    endTimeWithExtraDay;

  if (calaculateIfExtraDay) {
    //endTime needs to be +1 only if the total durtion after the addition does not exceeds 24 hours
    addExtraDay = !(
      moment
        .duration(endTimeWithExtraDay.diff(startTime, 'milliseconds'))
        .asHours() > 24
    );
  }

  const end = addExtraDay ? endTimeWithExtraDay : endTimeWithoutExtraDay;

  return {
    startTime: start ? start.format(API_DATE_TIME_FORMAT) : null,
    endTime: end ? end.format(API_DATE_TIME_FORMAT) : null,
  };
};

export const clockApiKeyToSlug = (apiKey: string) => {
  switch (apiKey) {
    case 'startTime':
      return 'clockIn';

    case 'endTime':
      return 'clockOut';

    case 'checkInTime':
      return 'timesheetCheckIn';

    case 'checkOutTime':
      return 'timesheetCheckOut';
    default:
      return '';
  }
};
