import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { palette } from '@violetta/ubeya/ui';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { ILabel } from '../entities';
import { useMemo } from 'react';

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const labels = (data || []).map(
      (
        { id, isDeleted, title, startTime, endTime, accountId, branches },
        index
      ) =>
        ({
          id,
          title,
          accountId,
          branches,
          startTime,
          endTime,
          isDeleted,
          color: palette[index % palette.length].dark,
          background: palette[index % palette.length].light,
        } as ILabel)
    );

    const mappedLabels = mappedArray(labels) as { [key: number]: ILabel };
    return { labels, mappedLabels };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useLabels = () => {
  const { data: userData } = useUser();
  const storeKey = ['labels'];
  const { isPending, data, isFetchedAfterMount } = useQuery<{
    data: (Omit<ILabel, 'branches'> & { branches: { id: number }[] })[];
  }>({
    queryKey: storeKey,
    queryFn: () => api.getLabels(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { labels, mappedLabels } = useMemo(() => {
    const { labels = [], mappedLabels = {} } = data || {};
    return {
      labels,
      mappedLabels,
    };
  }, [data]);

  return {
    isLoading: isPending,
    labels,
    mappedLabels,
    isFetchedAfterMount,
  };
};
