import React, { FC, useMemo, useState } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import {
  BaseView,
  H3Bold,
  IconClose,
  IGalleryItem,
  useGallery,
} from '@violetta/ubeya/ui';
import ImagesGallery from 'react-native-image-gallery';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';

const Container = styled(SafeAreaView)`
  flex: 1;
`;

const HeaderContainer = styled(BaseView)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-direction: row;
`;

interface Props {}

type ParamList = {
  params: {
    items: IGalleryItem<any>[];
    idx?: number;
    type?: string;
  };
};

export const GalleryScreen: FC<Props> = () => {
  const {
    params: { items, type, idx },
  } = useRoute<RouteProp<ParamList, 'params'>>();
  const { goBack } = useNavigation();
  const [currentPage, setCurrentPage] = useState((idx || 0) + 1);
  const parsedItems = useMemo(
    () =>
      (items || []).map((item) => ({ source: { uri: item?.uri }, ...item })),
    [items]
  );

  const { Gallery } = useGallery({
    items: parsedItems || [],
    idx,
  });

  if (type === 'video') {
    return (
      <Container>
        <Gallery />
      </Container>
    );
  }

  return (
    <Container>
      <HeaderContainer>
        <BaseView style={{ width: 44 }} />
        <H3Bold>
          {currentPage}/{parsedItems?.length || 0}
        </H3Bold>
        <IconClose color="#000" size={22} onPress={goBack} />
      </HeaderContainer>
      <ImagesGallery
        images={parsedItems}
        initialPage={idx}
        onPageSelected={(page) => setCurrentPage(page + 1)}
      />
    </Container>
  );
};
