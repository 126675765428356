import React, { useRef, useEffect, useCallback } from 'react';
import { Props, RecaptchaV3 } from './Recaptcha';

const RECAPTCHA_SCRIPT_ID = 'recaptcha-key';

declare global {
  interface Window {
    grecaptcha: {
      ready: (cb: () => void) => void;
      execute: (siteKey: string, options: { action: string }) => Promise<string>;
    };
  }
}

const loadScriptByURL = (id: string, url: string) => {
  const isScriptExist = document.getElementById(id);

  if (!isScriptExist) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.id = id;
    document.body.appendChild(script);
  }
};

export const useRecaptcha = ({ action, captchaDomain, onReceiveToken, siteKey }: Props) => {
  const callback = useRef<() => void | null>(null);

  const handler = useCallback(() => {
    window?.grecaptcha?.ready(() => {
      window.grecaptcha
        .execute(siteKey, { action })
        .then((token: string) => onReceiveToken({ data: token, type: 'success' }))
        .catch((err) => onReceiveToken({ data: err, type: 'failure' }));
    });
  }, [action, onReceiveToken, siteKey]);

  useEffect(() => {
    callback.current = () => handler();
  }, [handler]);

  useEffect(() => {
    loadScriptByURL(RECAPTCHA_SCRIPT_ID, `https://www.google.com/recaptcha/api.js?render=${siteKey}`);
  }, [siteKey]);

  useEffect(() => {
    const elem = document.getElementById(RECAPTCHA_SCRIPT_ID);

    elem.addEventListener('load', callback.current);

    return () => elem.removeEventListener('load', callback.current);
  }, []);

  const Recaptcha = useCallback(
    () => (
      <RecaptchaV3 action={action} captchaDomain={captchaDomain} onReceiveToken={onReceiveToken} siteKey={siteKey} />
    ),
    [action, captchaDomain, onReceiveToken, siteKey],
  );

  return {
    Recaptcha,
    // refreshToken,
  };
};
