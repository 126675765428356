import { useMemo } from 'react';
import { api, PutAvailabilities } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { useCRUD } from '@violetta/ubeya/utils';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IAvailability } from '../entities';
import { usePeriods } from './usePeriods';

interface Props {
  periodId: number;
}

type AddParams = Pick<
  IAvailability,
  'accountId' | 'labelId' | 'status' | 'date'
>;

type EditParams = Pick<IAvailability, 'id' | 'status'>;

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const parsedAvailabilities = (data || []).map((availability) => ({
      ...availability,
      date: moment(availability.date, 'YYYY-MM-DD').toDate(),
    }));

    return { parsedAvailabilities };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useAvailabilities = ({ periodId }: Props) => {
  const queryClient = useQueryClient();

  const { data: userData } = useUser();
  const { mappedPeriods } = usePeriods();

  const storeKey = ['availabilities', String(periodId)];

  const [startDate, endDate] = useMemo(() => {
    const period = mappedPeriods[periodId];

    const startDate = period.dates[0].date;
    const endDate = period.dates[period.dates.length - 1].date;
    const { branchId } = period;

    return [startDate, endDate, branchId] as const;
  }, [mappedPeriods, periodId]);

  const { isPending, data } = useQuery<{ data: IAvailability[] }>({
    queryKey: storeKey,
    queryFn: () => api.getAvailabilities([startDate, endDate] as const),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { parsedAvailabilities } = useMemo(() => {
    const { parsedAvailabilities: newParsedAvailabilities = [] } = data || {};
    return { parsedAvailabilities: newParsedAvailabilities };
  }, [data]);

  const {
    mutateAsync: updateAvailabilities,
    isPending: isUpdatingAvailability,
  } = useMutation({
    mutationFn: (params: PutAvailabilities) => api.putAvailabilities(params),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: storeKey });
    },
  });

  const {
    addItem: addAvailability,
    deleteItem: deleteAvailability,
    editItem: editAvailability,
  } = useCRUD(storeKey, {
    addApi: (params: AddParams) => api.addAvailability(params),
    editApi: (params: EditParams) => api.editAvailability(params),
    deleteApi: ({ id }: { id: number }) => api.deleteAvailability(id),
  });

  return {
    isLoading: isPending,
    availabilities: parsedAvailabilities,
    addAvailability,
    deleteAvailability,
    editAvailability,
    updateAvailabilities,
    isUpdatingAvailability,
  };
};
