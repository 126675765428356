import React, { FC } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { SvgIcon } from './SvgIcon';

interface Props {
  size?: number;
}

export const ChevronUp: FC<Props> = ({ size = 25 }) => (
  <SvgIcon xml={icons.cardIcons.chevronUp} width={size} height={size} />
);
