import React, { FC, useContext } from 'react';
import { Linking } from 'react-native';
import Hyperlink from 'react-native-hyperlink';
import { ThemeContext } from 'styled-components/native';

export const HyperLink: FC<{}> = ({ children }) => {
  const theme = useContext(ThemeContext);
  return (
    <Hyperlink
      onPress={(url) => {
        if (Linking.canOpenURL(url)) {
          Linking.openURL(url);
        }
      }}
      linkStyle={{
        color: theme.colors.blue1,
        textDecorationLine: 'underline',
        textAlign: 'left',
      }}>
      {children}
    </Hyperlink>
  );
};
