import React, { FC, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Button,
  Card,
  FlexRow,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { IncomeScreenNames } from '../../../income/navigation/ScreenNames';

const CardContent = styled(FlexRow)`
  align-items: center;
  padding: 12px;
`;
const IconContainer = styled(BaseView)`
  padding-left: 5px;
  display: flex;
`;
const Content = styled(BaseView)`
  flex: 1;
  padding-left: 20px;
`;
const Title = styled(BaseText)`
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  color: #252525;
`;
const Description = styled(BaseText)`
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: #252525;
`;

const ButtonContainer = styled(BaseView)`
  align-items: flex-end;
  margin-top: 10px;
`;

interface Props {}

export const IncomeCard: FC<Props> = React.memo(() => {
  const { navigate } = useNavigation();
  const { t } = useTranslation();

  const navigateToIncome = useCallback(
    () => navigate(IncomeScreenNames.MAIN),
    [navigate]
  );

  return (
    <Card as={RipplePressable} onPress={navigateToIncome}>
      <CardContent>
        <IconContainer>
          <SvgIcon
            xml={icons.cardIcons.incomeCardIcon}
            width={116}
            height={83}
          />
        </IconContainer>
        <Content>
          <Title>{t('yourEarnings')}</Title>
          <Description>{t('incomeHomeDesc')}</Description>
          <ButtonContainer>
            <Button
              type="primaryReversed"
              title={t('view')}
              onPress={navigateToIncome}
              paddingHorizontal={20}
              paddingVertical={5}
            />
          </ButtonContainer>
        </Content>
      </CardContent>
    </Card>
  );
});
