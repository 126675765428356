import React from 'react';
import { lotties } from '@violetta/ubeya/assets';
import { useBranches, usePayroll } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseScreen,
  BaseView,
  CustomLoader,
  H3Medium,
  Lottie,
  WideFlexColumnCenter,
  H1Regular,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';
import { IncomeCollapsible } from '../components/IncomeCollapsible';

const LoadingContainer = styled(BaseView)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Content = styled(BaseView)`
  flex: 1;
  padding-top: 20px;
`;

const Footer = styled(BaseView)`
  padding: 10px 20px;
`;

const Disclaimer = styled(H3Medium)`
  text-align: center;
  font-size: 15px;
`;

const EmptyTitle = styled(H1Regular)`
  padding: 10px 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  color: #252525;
`;

const EmptyScreen = () => {
  const { t } = useTranslation();
  return (
    <WideFlexColumnCenter>
      <Lottie
        source={lotties.noIncomes}
        autoPlay
        resizeMode="cover"
        height={450}
        width={365}
        style={{ marginRight: -60, marginTop: -100 }}
      />
      <EmptyTitle>{t('emptyIncome')}</EmptyTitle>
    </WideFlexColumnCenter>
  );
};

export const IncomeScreen = () => {
  const { mappedBranches } = useBranches();
  const {
    isLoading,
    mappedPayrollsByMonthAndYear,
    payrolls,
    refetch: refetchPayroll,
  } = usePayroll();
  const { t } = useTranslation();

  const sortedMonths = Object.keys(mappedPayrollsByMonthAndYear).sort(
    (date1, date2) => {
      const start = moment(`${date1}-01`);
      const end = moment(`${date2}-01`);
      if (start.isAfter(end)) {
        return -1;
      }
      return 1;
    }
  );

  return (
    <BaseScreen onRefresh={refetchPayroll}>
      <Content>
        {isLoading ? (
          <LoadingContainer>
            <CustomLoader />
          </LoadingContainer>
        ) : (payrolls || []).length === 0 ? (
          <EmptyScreen />
        ) : (
          sortedMonths.map((date) => (
            <IncomeCollapsible
              date={`${date}-01`}
              payrolls={mappedPayrollsByMonthAndYear[date]}
              mappedBranches={mappedBranches}
              key={date}
            />
          ))
        )}
      </Content>
      <Footer>
        <Disclaimer>{t('payrollDisclaimer')}</Disclaimer>
      </Footer>
    </BaseScreen>
  );
};
