import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderlessBar } from '@violetta/ubeya/ui';
import { useTheme } from 'styled-components/native';
import {
  AccountProfileHeader,
  EditUserHeader,
  SpecificAccountProfileHeader,
} from '../components/account-profile/components';
import { AccountGalleryScreen, ChangeAccountDetailsScreen } from '../screens';
import { AccountProfileScreen } from '../screens/AccountProfileScreen';
import { EditUserScreen } from '../screens/EditUserScreen';
import { ProfileScreen } from '../screens/ProfileScreen';
import { ProfileScreenNames } from './ScreenNames';
import { BranchContactScreen } from '../screens/BranchContactScreen';

const { Navigator, Screen } = createNativeStackNavigator();

export const ProfileNavigation: FC = () => {
  const { colors } = useTheme();

  return (
    <Navigator
      initialRouteName={ProfileScreenNames.PROFILE}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
      }}
    >
      <Screen
        name={ProfileScreenNames.PROFILE}
        component={ProfileScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <AccountProfileHeader {...props} />
          ),
        }}
      />
      <Screen
        name={ProfileScreenNames.EDIT_USER}
        component={EditUserScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <EditUserHeader {...props} />
          ),
        }}
      />
      <Screen
        name={ProfileScreenNames.ACCOUNT_PROFILE}
        component={AccountProfileScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <SpecificAccountProfileHeader {...props} />
          ),
        }}
      />
      <Screen
        name={ProfileScreenNames.ACCOUNT_GALLERY}
        component={AccountGalleryScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <AccountProfileHeader {...props} />
          ),
        }}
      />
      <Screen
        name={ProfileScreenNames.CHANGE_ACCOUNT_DETAILS}
        component={ChangeAccountDetailsScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <AccountProfileHeader {...props} />
          ),
        }}
      />

      <Screen
        name={ProfileScreenNames.BRANCH_CONTACT}
        component={BranchContactScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <SpecificAccountProfileHeader {...props} />
          ),
        }}
      />
    </Navigator>
  );
};
