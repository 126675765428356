import React, { FC } from 'react';
import { IMandatory } from '@violetta/ubeya/entities';
import {
  BaseView,
  Card,
  FlexColumnCenter,
  FlexRow,
  H4Regular,
  H5Medium,
  H5Regular,
} from '@violetta/ubeya/ui';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { ActionButton } from '../Buttons/ActionButton';

const Container = styled(Card)`
  padding: 12px;
`;

const Details = styled(BaseView)`
  align-items: center;
`;

const Title = styled(H5Medium)`
  text-align: center;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: normal;
  margin-bottom: 17px;
`;

const ButtonContainer = styled(FlexColumnCenter)``;

const TextLink = styled(H5Regular)`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 8px;
`;
const Text = styled(H4Regular)`
  text-align: center;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray15};
  max-width: 210px;
`;

interface Props {
  mandatory: IMandatory;
  onPress: () => void;
  onUrlPress: () => void;
  isLoading: boolean;
}

export const Mandatory: FC<Props> = ({
  mandatory,
  isLoading,
  onPress,
  onUrlPress,
}) => (
  <Container>
    <Details>
      <Title>{mandatory?.title}</Title>
      {mandatory?.url ? (
        <BaseView as={Pressable} onPress={onUrlPress}>
          <TextLink>{mandatory?.urlText}</TextLink>
        </BaseView>
      ) : (
        <BaseView />
      )}
      {mandatory?.description && <Text>{mandatory?.description}</Text>}
    </Details>

    <ButtonContainer>
      <FlexRow>
        <ActionButton
          title={mandatory.sendText}
          onPress={onPress}
          isLoading={isLoading}
        />
      </FlexRow>
    </ButtonContainer>
  </Container>
);
