import React, { FC, useContext } from 'react';
import { FlexCenter, H5Medium, RipplePressable } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { Platform, Pressable } from 'react-native';
import { useTranslation } from '@violetta/ubeya/i18n';
import { ConfigContext } from '../../../contexts/ConfigContext';

const Container = styled(FlexCenter)`
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.primary};
  shadow-color: rgba(0, 0, 0, 0.52);
  shadow-opacity: ${Platform.OS === 'ios' ? 0.2 : 1.0};
  shadow-offset: 5px 5px;
  shadow-radius: 5px;
  elevation: 5;
  bottom: 30px;
  right: 30px;
`;

const Plus = styled(H5Medium)`
  margin-top: -2px;
  font-size: 35px;
  color: ${({ theme }) => theme.colors.white};
`;

interface Props {
  onPress?: () => void;
}

export const AddRequestIcon: FC<Props> = ({ onPress }) => {
  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();

  return (
    <Container as={RipplePressable} onPress={onPress}>
      <Plus>+</Plus>
    </Container>
  );
};
