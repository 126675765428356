import React, { FC, forwardRef, useCallback, useMemo } from 'react';
import { useNavigation, useTheme } from '@react-navigation/native';
import {
  CustomTimesheetDTO,
  useBookings,
  useBranches,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Button,
  FlexColumnBetween,
  FlexRow,
  FlexRowBetween,
  H3Regular,
  H4Regular,
} from '@violetta/ubeya/ui';
import { uiAlert } from '@violetta/ubeya/utils';
import { IConfirmation } from 'entities/src/entities/IConfirmation';
import moment from 'moment';
import { Platform, Pressable } from 'react-native';
import styled from 'styled-components/native';
import { TimeclockScreenNames } from '../../timeclock';
import { TimesheetsScreenNames } from '../../timesheets';

const ClockinFooter = styled(FlexColumnBetween)`
  width: 100%;
  background-color: #f7f8fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  shadow-color: #000;
  shadow-offset: 0px -1px;
  shadow-opacity: 0.2;
  shadow-radius: 5px;
  elevation: 5;
`;

const ButtonsContainer = styled(BaseView)`
  padding: 20px;
  width: 100%;
`;

const ConfirmationButtonContainer = styled(BaseView)`
  width: 100%;
`;

const ConfirmationMessageContainer = styled(BaseView)`
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  margin-bottom: 12px;
`;

const DontForgetText = styled(H4Regular)<{ $center?: boolean }>`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  ${({ $center }) => $center && 'text-align: center'};
`;

const ButtonText = styled(DontForgetText)`
  text-decoration: underline;
  padding-left: 3px;
`;

const BookingFooterButtonContainer = styled(FlexRowBetween)`
  width: 100%;
  padding: 0 20px;
  flex-wrap: wrap;
`;

const ConfirmationText = styled(BaseText)`
  align-items: center;
`;

interface Props {
  projectId: number;
  timesheet: CustomTimesheetDTO | undefined;
  confirmation: IConfirmation;
}

export const BookingFooter: FC<Props> = ({
  projectId,
  timesheet,
  confirmation,
}) => {
  const { t } = useTranslation();
  const { navigate, replace, push } = useNavigation();
  const { colors } = useTheme();
  const { mappedBranches } = useBranches();
  const { updateConfirmation, isUpdatingConfirmation } = useBookings();

  const confirmationCB = useCallback(
    async (projectId, id, message) => {
      const res = await uiAlert({
        title: t('areYouSureYouWishToConfirm'),
        message,
        cancel: {
          text: t('no'),
        },
        save: {
          text: t('yes'),
        },
      });

      if (!res) {
        return;
      }
      updateConfirmation({ projectId, id });
    },
    [t, updateConfirmation]
  );

  const [fromTo, totalTime] = useMemo(() => {
    if (timesheet?.startTime && timesheet?.endTime) {
      const startTime = moment(timesheet?.startTime);
      const endTime = moment(timesheet?.endTime);
      const difference = endTime.diff(startTime, 'm');
      const hours = difference ? Math.round((difference / 60) * 100) / 100 : 0;
      return [`${startTime.format('LT')} - ${endTime.format('LT')}`, hours];
    }
    return [];
  }, [timesheet?.endTime, timesheet?.startTime]);

  const shouldCheckIn = timesheet?.requireCheckInWithMobile;

  const goToClockTitle =
    /* If check in is required */
    shouldCheckIn
      ? !timesheet?.startTime
        ? t('clockIn')
        : !timesheet?.checkInTime
        ? t('timesheetCheckIn')
        : !timesheet?.checkOutTime
        ? t('timesheetCheckOut')
        : t('clockOut')
      : /* If check in is NOT required */
      !shouldCheckIn
      ? !timesheet?.startTime
        ? t('clockIn')
        : t('clockOut')
      : '';
  return (
    <ClockinFooter>
      {!(timesheet?.startTime && timesheet?.endTime) && (
        <ButtonsContainer>
          {confirmation && !confirmation.isConfirmed && !timesheet?.endTime && (
            <ConfirmationButtonContainer>
              <ConfirmationMessageContainer>
                <DontForgetText>
                  {confirmation.message
                    ? confirmation.message
                    : t('confirmationNeeded')}
                </DontForgetText>
              </ConfirmationMessageContainer>

              <Button
                type="primary"
                title={t('confirm')}
                lowercase
                fontSize={16}
                paddingHorizontal={25}
                paddingVertical={10}
                isLoading={isUpdatingConfirmation}
                loaderColor={colors.primary}
                onPress={() =>
                  confirmationCB(
                    projectId,
                    confirmation.id,
                    confirmation.message
                  )
                }
              />
            </ConfirmationButtonContainer>
          )}
          {(!confirmation || confirmation.isConfirmed) &&
            timesheet &&
            (timesheet?.disableClockWithMobile !== undefined
              ? !timesheet?.disableClockWithMobile
              : !!mappedBranches?.[timesheet?.branchId]?.timesheetConfig
                  ?.toAllowClockWithMobile) && (
              <Button
                type="primary"
                title={goToClockTitle}
                lowercase
                fontSize={16}
                paddingHorizontal={25}
                paddingVertical={15}
                loaderColor={colors.primary}
                onPress={() =>
                  navigate(TimeclockScreenNames.MAIN, {
                    screen: TimeclockScreenNames.TIMECLOCK_REPORT,
                    params: { timesheet, timesheetId: timesheet.id },
                  })
                }
              />
            )}
        </ButtonsContainer>
      )}
      {timesheet?.startTime && timesheet?.endTime && (
        <BookingFooterButtonContainer>
          <DontForgetText>{fromTo} </DontForgetText>
          <DontForgetText>{`${t('total')}: ${totalTime} ${t(
            'hours'
          )}`}</DontForgetText>
          <Button
            type="primary"
            title={t('details')}
            paddingHorizontal={0}
            fontSize={14}
            textStyle={{ textDecorationLine: 'underline', color: colors.text }}
            style={{
              backgroundColor: 'transparent',
              shadowOpacity: 0,
              borderColor: 'transparent',
            }}
            onPress={() =>
              replace(TimesheetsScreenNames.MAIN, {
                screen: TimesheetsScreenNames.TIMESHEET,
                params: { timesheet, timesheetId: timesheet.id },
              })
            }
          />
        </BookingFooterButtonContainer>
      )}
    </ClockinFooter>
  );
};
