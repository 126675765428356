import {
  CreatePollAnswerParams,
  DeletePollAnswerParams,
  FieldValue,
  IAccountProfile,
  IBoardPayload,
  Posts,
} from '@violetta/ubeya/entities';
import moment from 'moment';
import qs from 'qs';

import {
  get as _get,
  post as _post,
  put as _put,
  httpDelete as _delete,
  patch as _patch,
} from './http';

// local
// export const baseURL = process.env.API_BASE_URL ?? 'http://localhost:7401';
// export const authBaseURL = process.env.AUTH_BASE_URL ?? 'http://localhost:7404';
// export const chatURL = 'http://localhost:7405';
// export const chatWSURL = 'http://localhost:7406';
// export const authURL = 'http://localhost:3004';
// export const webURL = 'http://localhost:3000';

// staging

// export const baseURL =
//   process.env.API_BASE_URL ?? 'https://crew-dev2946.api.ubeya.dev';
// export const authBaseURL =
//   process.env.AUTH_BASE_URL ?? 'https://auth-dev2946.api.ubeya.dev';
// export const chatURL = 'h  ttps://chat-dev2946.api.ubeya.dev';
// export const chatWSURL = 'https://chat-ws-dev2946.api.ubeya.dev';
// export const authURL = 'https://auth-dev2946.ubeya.dev';
// export const webURL = 'https://ottavio-dev2946.ubeya.dev';

// // production
export const baseURL = process.env.API_BASE_URL ?? 'https://crew.api.ubeya.com';
export const authBaseURL =
  process.env.AUTH_BASE_URL ?? 'https://auth.api.ubeya.com';
export const chatURL = 'https://chat.api.ubeya.com';
export const chatWSURL = 'https://chat-ws.api.ubeya.com';
export const authURL = 'https://auth.ubeya.com';
export const webURL = 'https://ottavio.ubeya.com';

const httpOptions = () => ({
  baseURL,
  responseType: 'json',
  withCredentials: true,
});

const get = async (url: string, params = {}, options = {}) => {
  const opts = await httpOptions();

  return _get(url, {
    ...opts,
    ...options,
    params,
    paramsSerializer: {
      indexes: true,
    },
  });
};

const post = async (url: string, body: string | object = '', options = {}) => {
  const opts = httpOptions();

  return _post(url, body, { ...opts, ...options });
};

const put = async (url: string, body: string | object = '') => {
  const opts = httpOptions();
  return _put(url, body, { ...opts });
};

const patch = async (url: string, body: string | object = '') => {
  const opts = httpOptions();
  return _patch(url, body, { ...opts });
};

const httpDelete = async (url: string, data = {}) => {
  const opts = httpOptions();
  return _delete(url, { ...opts, body: data });
};

export const fetchUser = (appBuild: number) => get('/auth/me', { appBuild });
export const getRegions = () => get('/regions');
export const getLabels = () => get('/labels');
export const getModules = () => get('/modules');
export const getPositions = () => get('/positions');
export const getAccounts = () => get('/accounts');
export const getBranches = () => get('/branches');
export const getLocations = () => get('/locations');
export const getSequences = () => get('/sequences');
export const getMandatories = () => get('/mandatories');
export const getUniforms = () => get('/uniforms');
export const getBookings = () => get('/bookings');
export const getTeam = ({
  projectId,
  date,
}: {
  projectId: number;
  date: Date;
}) =>
  get(`/projects/${projectId}/employees`, {
    date: moment(date).format('YYYY-MM-DD'),
  });
export const getEmployeeEditableFields = (accountId: number) =>
  get(`/accounts/${accountId}/profile`);
export const getAccountProfile = (accountId: number) =>
  get(`/accounts/${accountId}/company-profile`);
export const editEmployeeFields = (accountId: number, values: any) =>
  put(`/accounts/${accountId}/profile`, { fields: values });

export const getCaptain = ({
  projectId,
  date,
}: {
  projectId: number;
  date?: string;
}) => get(`/projects/${projectId}/captain`, { date });

export const updateAccountProfile = (
  accountId: number,
  profile: IAccountProfile
) => patch(`/accounts/${accountId}/company-profile`, { ...profile });

export const updateCaptainProject = ({
  projectId,
  values,
}: {
  projectId: number;
  values: {
    managerRating?: number;
    managerClientFeedback?: string;
    managerNotes?: string;
    managerIssues?: string;
  };
}) => patch(`/projects/${projectId}/captain`, values);

export const updateCaptainShiftEmployee = ({
  projectId,
  shiftId,
  employeeId,
  values,
  delta,
}: {
  projectId: number;
  shiftId: number;
  employeeId: number;
  delta?: number;
  values: { startTime?: Date; endTime?: Date; rating?: number };
}) =>
  patch(
    `/projects/${projectId}/captain/shifts/${shiftId}/employees/${employeeId}`,
    { ...values, delta }
  );

export const updateCaptainOSShiftEmployee = ({
  projectId,
  shiftId,
  employeeId,
  values,
  delta,
}: {
  projectId: number;
  shiftId: number;
  employeeId: number;
  delta?: number;
  values: { startTime?: Date; endTime?: Date; rating?: number };
}) =>
  patch(
    `/os/projects/${projectId}/captain/shifts/${shiftId}/employees/${employeeId}`,
    { ...values, delta }
  );

export const getOffers = () => get('/offers');
export const updateOfferStats = ({
  offerId,
  seen,
}: {
  offerId: number;
  seen?: boolean;
}) => patch(`/offers/${offerId}/updateStats`, { seen });

export const getTimesheets = () => get('/timesheets');
export const getTimesheetsHistory = (accountId?: number) =>
  get('/timesheets/history', { accountId });

export const getSpecificTimesheet = (timesheetId: number) =>
  get(`/timesheets/${timesheetId}`);

export const getDirectory = (branchId: number) =>
  get('/directory', { branchId });
export const getTimeAvailabilities = ({
  accountId,
  from,
  to,
}: {
  accountId: number;
  from: Date;
  to: Date;
}) =>
  get(`/accounts/${accountId}/timeAvailabilities`, {
    from: moment(from).format('YYYY-MM-DD'),
    to: moment(to).format('YYYY-MM-DD'),
  });

export const addTimeAvailabilities = ({
  accountId,
  timeAvailabilities,
}: {
  timeAvailabilities: {
    startTime: Date;
    endTime: Date;
    status: 0 | 1 | 2;
    comment?: string;
  }[];
  accountId: number;
}) =>
  post(`/accounts/${accountId}/timeAvailabilities`, {
    timeAvailabilities: timeAvailabilities.map(
      ({ startTime, endTime, status, comment }) => ({
        startTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
        endTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
        status,
        comment: comment || undefined,
      })
    ),
  });

export const deleteTimeAvailability = ({
  id,
  accountId,
}: {
  id: number;
  accountId: number;
}) => httpDelete(`/accounts/${accountId}/timeAvailabilities/${id}`);

export const deleteGalleryPhotos = (accountId: number, id: number) =>
  httpDelete(`accounts/${accountId}/employee/photos/${id}`);

export const getRequestsTypes = () => get('/requests/types');
export const getRequests = () => get('/requests');
export const addRequest = ({
  typeId,
  branchId,
  employeeReason,
  data: { startTime, endTime, jobTypeId },
}: {
  typeId: number;
  branchId: number;
  employeeReason: string;
  data: {
    startTime: Date;
    endTime: Date | null;
    jobTypeId?: number;
  };
}) =>
  post('/requests', {
    typeId,
    branchId,
    employeeReason,
    data: { startTime, endTime, jobTypeId },
  });

export const addGeneralRequest = ({
  typeId,
  accountId,
  board,
  employeeReason,
}: {
  typeId: number;
  accountId: number;
  board: IBoardPayload[];
  employeeReason?: string;
}) =>
  post('/requests', {
    typeId,
    accountId,
    data: { board },
    employeeReason,
  });

export const getCompliances = () => get('/compliances');

export const getAvailabilities = ([from, to]: readonly [Date, Date]) =>
  get('/availabilities', {
    from: moment(from).format('YYYY-MM-DD'),
    to: moment(to).format('YYYY-MM-DD'),
  });

export type PutAvailabilities = {
  periodId: number;
  added: { date: Date; labelId: number; status: 0 | 1 | 2 }[];
  edited: { id: number; status: 0 | 1 | 2 }[];
};
export const putAvailabilities = ({ periodId, ...body }: PutAvailabilities) =>
  put(`/periods/${periodId}/availabilities`, body);

export const sendError = (appVersion: string, error: any) => {
  post('/error', { app: 'crew', message: `${appVersion} - ${error}` });
};

export const patchInstance = ({
  instanceId,
  sequenceId,
  employeeSlotId,
  forms,
  isDone = false,
}: {
  sequenceId: number;
  instanceId: number;
  employeeSlotId?: number;
  forms: { id: number; fields: { id: number; value: FieldValue }[] }[];
  isDone?: boolean;
}) =>
  patch(`/sequences/${sequenceId}/instances/${instanceId}`, {
    employeeSlotId,
    forms,
    isDone,
  });

export const getSequenceInstance = ({
  sequenceId,
  instanceId,
}: {
  sequenceId: number;
  instanceId: number;
}) => get(`/sequences/${sequenceId}/instances/${instanceId}`);

export const getPeriods = () => get('/periods');

export const getPayroll = () => get('/payroll');

export const updatePeriod = ({
  id: periodId,
  seen = true,
}: {
  id: number;
  seen?: boolean;
}) => patch(`/periods/${periodId}`, { seen });

export const addAvailability = ({
  date,
  labelId,
  accountId,
  status,
}: {
  date: Date;
  accountId: number;
  labelId: number;
  status: 0 | 1 | 2;
}) =>
  post('/availabilities', {
    date: moment(date).format('YYYY-MM-DD'),
    labelId,
    accountId,
    status,
  });

export const updateConfirmation = ({
  projectId,
  id: confirmationId,
  isConfirmed = true,
}: {
  projectId: number;
  id: string;
  isConfirmed?: boolean;
}) =>
  patch(`/bookings/${projectId}/confirmations/${confirmationId}`, {
    isConfirmed,
  });

export const loginToken = () => get('/auth/token');

export const editAvailability = ({
  id,
  status,
}: {
  id: number;
  status: 0 | 1 | 2;
}) =>
  patch(`/availabilities/${id}`, {
    status,
  });

export const deleteAvailability = (id: number) =>
  httpDelete(`/availabilities/${id}`);

export const addApplication = ({
  isRejected,
  shiftId,
}: {
  shiftId: number;
  isRejected: boolean;
}) =>
  post('/applications', {
    isRejected,
    shiftId,
  });

export const addBulkApplications = ({
  isRejected,
  shiftsIds,
}: {
  shiftsIds: number[];
  isRejected: boolean;
}) =>
  post('/applications/bulk', {
    isRejected,
    shiftsIds,
  });

export const editApplication = ({
  id,
  isRejected,
  hasCar,
  comment,
  shiftId,
}: {
  id: number;
  isRejected: boolean;
  shiftId: number;
  hasCar?: boolean;
  comment?: string;
}) =>
  patch(`/applications/${id}`, {
    isRejected,
    shiftId,
    hasCar,
    comment,
  });

export const editBulkApplications = ({
  applicationsIds,
  isRejected,
}: {
  applicationsIds: number[];
  isRejected: boolean;
}) =>
  patch('/applications/edit-bulk', {
    isRejected,
    applicationsIds,
  });

export const deleteApplication = ({ id }: { id: number; shiftId: number }) =>
  httpDelete(`/applications/${id}`);
export const deleteBulkApplications = ({
  applicationsIds,
}: {
  applicationsIds: number[];
}) => patch('/applications/delete-bulk', { applicationsIds });

export const clock = async ({
  id,
  clockType,
  location,
  delta,
}: {
  id: number;
  clockType: 'in' | 'out' | 'checkIn' | 'checkOut';
  location: {
    lat: number;
    lng: number;
  } | null;
  delta?: number;
}) =>
  patch(`/timesheets/${id}/clock`, {
    clockType,
    location,
    delta,
  });

export const addBreaktime = ({
  timesheetId,
  breakTypeId,
}: {
  timesheetId: number;
  breakTypeId?: number;
}) => post(`/timesheets/${timesheetId}/breaktimes`, { breakTypeId });

export const approveTimesheet = ({
  timesheetId,
  approve,
}: {
  timesheetId: number;
  approve: { signature: string };
}) => patch(`/timesheets/${timesheetId}/response`, { approve });

export const declineTimesheet = ({
  timesheetId,
  decline,
}: {
  timesheetId: number;
  decline: { reason: string };
}) => patch(`/timesheets/${timesheetId}/response`, { decline });

export const editBreaktime = ({
  timesheetId,
  breaktimeId,
}: {
  timesheetId: number;
  breaktimeId: number;
}) =>
  patch(`/timesheets/${timesheetId}/breaktimes/${breaktimeId}`, {
    clockType: 'out',
  });

export const addJob = ({ date, branchId }: { date: Date; branchId: number }) =>
  post('/jobs', {
    date: moment(date).format('YYYY-MM-DD'),
    branchId,
  });

export const sendFCMToken = ({ token }: { token: string }) =>
  put('/messaging/token', { token });

export const sendDevice = ({
  os,
  appVersion,
  appBuild,
}: {
  os: {
    platform: string;
    version: string;
  };
  appVersion: string;
  appBuild: number;
}) => patch('/device', { os, appVersion, appBuild });

export const uploadGalleryPhoto = ({
  accountId,
  image,
  type,
}: {
  accountId: number;
  image: string;
  type: string;
}) =>
  post(`/accounts/${accountId}/employee/photos`, { accountId, image, type });

export const uploadPhoto = ({ image, type }: { image: string; type: string }) =>
  patch('/user/photo', { image, type });
export const editUser = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => patch('/user', { firstName, lastName });
export const updateUserSubscprition = ({ email }: { email: boolean }) =>
  patch('/user/subscribe', { email });

export const signup = ({
  phone,
  recaptchaToken,
  isVoice,
}: {
  phone: string;
  recaptchaToken: string;
  isVoice?: boolean;
}) =>
  post(
    `${authBaseURL}/user/signup`,
    { phone, isVoice },
    {
      headers: {
        'x-recaptcha-token': recaptchaToken,
      },
    }
  );

export const userLogin = ({
  phone,
  code,
  tokenId,
  email,
}: {
  phone: string;
  code: string;
  email: string;
  tokenId: string;
}) => post(`${authBaseURL}/user/login`, { phone, code, tokenId, email });

export const userLogout = () => post(`${authBaseURL}/user/logout`);

// export const adminLogin = ({ email, password }: { email: string; password: string }) =>
//   post(`${apiBaseURL}/admin/login`, { email, password });

export const refreshToken = () => post(`${authBaseURL}/user/refresh`);

export const refreshTabletToken = () => post(`${authBaseURL}/tablet/refresh`);

export const getPosts = ({
  pageSize,
  token,
  branches,
  projectId,
  full,
  isStar,
}: {
  branches?: number[];
  pageSize: number;
  token: number;
  projectId?: number;
  full?: boolean;
  isStar?: boolean;
}): Promise<Posts> =>
  get('/posts', {
    pageSize,
    token,
    branches: branches?.join('#') || undefined,
    projectId,
    full,
    isStar,
  }).catch((e) => console.log({ e: JSON.stringify(e.response) }));

export const createPollAnswer = ({
  pollId,
  questionId,
  pollOptionId,
}: CreatePollAnswerParams) =>
  post(`/polls/${pollId}/questions/${questionId}/answers`, { pollOptionId }); // TODO: import return types

export const deletePollAnswer = ({
  pollId,
  questionId,
  answerId,
}: DeletePollAnswerParams) =>
  httpDelete(`/polls/${pollId}/questions/${questionId}/answers/${answerId}`);

export const createLike = ({ postId }) => post(`/posts/${postId}/likes`);
export const deleteLike = ({ postId, likeId }) =>
  httpDelete(`/posts/${postId}/likes/${likeId}`);

export const createEmployeeCandidate = ({ first_name, last_name, code }) =>
  post('/accounts/candidates', {
    first_name,
    last_name,
    code,
  });

// CHAT

export const markReadAllRoomMessages = ({ roomId }) =>
  post(`${chatURL}/markReadAllRoomMessages`, { roomId });

export const getAllRooms = () => get(`${chatURL}/rooms`);

export const getMessages = ({ roomId }) => get(`${chatURL}/rooms/${roomId}`);

export const createRoom = ({ accountId }) =>
  post(`${chatURL}/createRoomUser`, { accountId });

export const sendMessage = ({ roomId, message, type }) =>
  post(`${chatURL}/sendMessage`, { roomId, message }, { params: { type } });

export const leaveChat = ({ roomId }) => post('/leaveRoom', { roomId });

export const removeFromRoom = ({ roomId, participant }) =>
  post('/removeFromRoom', { roomId, participant });

export const pinRoom = ({ roomId }) => post('/pin', { roomId });

export const unpinRoom = ({ roomId }) => post('/unpin', { roomId });

export const readRoomMessages = ({ roomId, firstMessageId, type }) =>
  get(`${chatURL}/readRoomMessages`, { roomId, firstMessageId, type });

export const getAvailableUsers = ({ type }) =>
  get(`${chatURL}/availableUsers`, { type });

export const getShiftActionReasons = () => get('/requests/reasons');

export const joinRoom = ({ roomId, participants }) =>
  post('/joinRoom', { roomId, participants });

export const getTimeAvailability = ({ accountId, from, to }) =>
  get(`/accounts/${accountId}/timeAvailabilities`, { from, to });

export const getWeeklySchedule = ({ accountId, from, to }) =>
  get(`/accounts/${accountId}/schedule`, { from, to });

export const getNotifications = ({
  pageSize,
  token,
}: {
  pageSize: number;
  token?: number;
}) => get('/audit-logs', { pageSize, token });

export const createReleaseFromShiftRequest = ({
  accountId,
  shiftId,
  employeeReason,
  employeeReasonOther,
}) =>
  post('/requests', {
    typeId: 3,
    accountId,
    data: { shiftId },
    employeeReason,
    employeeReasonOther,
  });

export const cancelReleaseFromShiftRequest = ({ requestId }) =>
  httpDelete(`/requests/${requestId}`);

export const getSwapEmployees = (projectId: number, shiftId: number) =>
  get(`/bookings/${projectId}/shifts/${shiftId}/swaps/employees`);

export const createSwap = ({
  projectId,
  dstSlotId,
  reasonId,
  dstEmployeeId,
  bookingId,
}: {
  projectId: number;
  dstSlotId: number;
  reasonId: number;
  dstEmployeeId: number;
  bookingId: number;
}) =>
  post(`/bookings/${projectId}/shifts/${bookingId}/swaps`, {
    dstSlotId,
    reasonId,
    dstEmployeeId,
  });

export const cancelSwap = ({
  projectId,
  shiftId,
  swapId,
}: {
  projectId: number;
  shiftId: number;
  swapId: number;
}) => httpDelete(`/bookings/${projectId}/shifts/${shiftId}/swaps/${swapId}`);

export const acceptSwap = ({
  accountId,
  swapId,
}: {
  accountId: number;
  swapId: number;
}) => post('/requests', { typeId: 4, accountId, data: { swapId } });

export const updateBookingsStats = ({
  projectId,
  isRead,
}: {
  projectId: number;
  isRead?: boolean;
}) => patch(`/bookings/${projectId}/updateStats`, { isRead });

export const getStats = () => get('/stats');

export const leaveAccount = ({ accountId }: { accountId: number }) =>
  httpDelete(`/accounts/${accountId}`);

export const getBreaktypes = () => get('/breaktypes');

export const editUserName = ({
  firstName,
  lastName,
}: {
  firstName: string;
  lastName: string;
}) => patch('/user/name', { firstName, lastName });

export const getEmployeeSlotBreakPlots = (employeeSlotId: number) =>
  get('/employee-slot-break-plots', { employeeSlotId });
