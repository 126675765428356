import { useMemo } from 'react';
import NetInfo from '@react-native-community/netinfo';
import { adminApi } from '@violetta/ubeya/api';
import { useTranslation } from '@violetta/ubeya/i18n';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import * as sharedAtoms from '../../../shared/src/atoms';
import { useAdminUser } from './useAdminUser';

const selector = createCachedSelector(
  (data) => data?.data,
  (data, t) => t,
  (data, t) => {
    const positions = data?.data || [];
    const positionsOptions = (positions || []).map(({ id, slug }) => ({
      value: id,
      label: t(slug),
      id,
    }));
    const mappedPositions = mappedArray(positions || []);

    return { positions, mappedPositions, positionsOptions };
  }
)({
  keySelector: (data, t, storeKey) => storeKey,
});

export const usePositions = () => {
  const selectedAccount = useRecoilValue(sharedAtoms.selectedAccount);
  const selectedAccountId = selectedAccount?.id;
  const storeKey = useMemo(
    () => ['positions', String(selectedAccountId)],
    [selectedAccountId]
  );
  const { t } = useTranslation();

  const { data: userData } = useAdminUser();

  const { isPending, data, refetch } = useQuery({
    queryKey: storeKey,
    queryFn: () => adminApi.getPositions({ accountId: selectedAccountId }),
    enabled: !!userData?.id,
    select: (data) => selector(data, t, storeKey.join('#')),
  });

  const { positions, positionsOptions, mappedPositions } = useMemo(() => {
    const {
      positions = [],
      positionsOptions = [],
      mappedPositions = {},
    } = data || {};
    return {
      positions,
      positionsOptions,
      mappedPositions,
    };
  }, [data]);

  return {
    positions,
    refetch,
    isLoading: isPending,
    positionsOptions,
    mappedPositions,
  };
};
