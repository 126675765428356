import moment from 'moment';
import { config } from './appConfig';

export const APP_VERSION = config.version.APP_VERSION;
export const BUILD_NUMBER = config.version.BUILD_NUMBER;

export const EMPLOYEES_TABS = {
  active: { filterBy: 'active', dataKey: 'activeCount' },
  inactive: { filterBy: 'inactive', dataKey: 'inActiveCount' },
  deleted: { filterBy: 'deleted', dataKey: 'deletedCount' },
  requests: { filterBy: 'daily', dataKey: 'requestsCount' },
};

export const HIDE_LOCATION_NAME_ACCOUNT_IDS = [6652];

export const SETTINGS__ACCOUNTS_TABS = [
  'info',
  'branches',
  'admins',
  'fields',
  'positions',
  'tags',
  'clients',
];
export const SUPERADMIN__TABS = ['account'];

export enum DefaultView {
  STAFF = 'staff',
  ADMIN = 'admin',
}

export const DEFAULT_VIEW_ASYNC_STORE_KEY = 'default_view';
export const DEFAULT_VIEW_STAFF_ASYNC_STORE_VALUE = DefaultView.STAFF;
export const DEFAULT_VIEW_ADMIN_ASYNC_STORE_VALUE = DefaultView.ADMIN;

export const ADMIN_SWITCH_NOTIFICATION_ASYNC_STORE_KEY =
  'admin_switch_notification';
export const STAFF_SWITCH_NOTIFICATION_ASYNC_STORE_KEY =
  'staff_switch_notification';

export const SETTINGS__BRANCH_TABS = [
  'info',
  'admins',
  'timesheet',
  'schedule',
  'payroll',
  'app',
  'locations',
  'uniforms',
];

export const TIME_FORMAT = {
  12: 'hh:mm A',
  24: 'HH:mm',
};

export const DATE_FORMAT = [
  'DD/MM/YYYY',
  'MM/DD/YYYY',
  'YYYY/MM/DD',
  'MMM D, YYYY',
];

export const DAYS_OF_WEEK = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const DAYS = [
  { day: 'SUN', title: 'Sunday', id: 1, dow: 0 },
  { day: 'MON', title: 'Monday', id: 2, dow: 1 },
  { day: 'TUE', title: 'Tuesday', id: 3, dow: 2 },
  { day: 'WED', title: 'Wednsday', id: 4, dow: 3 },
  { day: 'THU', title: 'Thursday', id: 5, dow: 4 },
  { day: 'FRI', title: 'Friday', id: 6, dow: 5 },
  { day: 'SAT', title: 'Saturday', id: 7, dow: 6 },
];

export const getDatesRange = () => ({
  today: { start: moment(), end: moment() },
  thisWeek: { start: moment().startOf('week'), end: moment().endOf('week') },
  lastWeek: {
    start: moment().subtract(1, 'week').startOf('week'),
    end: moment().subtract(1, 'week').endOf('week'),
  },
  thisMonth: { start: moment().startOf('month'), end: moment().endOf('month') },
  lastMonth: {
    start: moment().subtract(1, 'month').startOf('month'),
    end: moment().subtract(1, 'month').endOf('month'),
  },
  custom: { start: moment(), end: moment() },
});

export const ALLOWED_PHOTO_TYPES = '.jpeg,.jpg,.gif,.png';

export const ALLOWED_VIDEOS_TYPES = '.mp4,.MOV';

export const ALLOWED_DOCUMENT_EXTENSIONS =
  '.jpeg,.jpg,.gif,.png,.pdf,.doc,.docx,.ppt,.pptx';

export const API_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const IPHONE_TOS_URL = 'https://www.ubeya.com/privacy/app-terms-iphone/';
export const ANDROID_TOS_URL =
  'https://www.ubeya.com/privacy/app-terms-android/';
export const WEB_TOS_URL = 'https://www.ubeya.com/privacy/terms/';
export const HELP_CENTER_URL =
  'https://main.ubeya.com/register/ubeya-intercom-help-for-app-users';
export const THE_MIX_URL =
  'https://www.archospitality.co.uk/support-for-under-25s';
export const THE_MIX_ZENDESK_URL =
  'https://wearearc.zendesk.com/hc/en-gb/requests/new';
export const IPHONE_RATEAPP_URL =
  'itms-apps://itunes.apple.com/app/id1538331228';
export const ANDROID_RATEAPP_MANAGER_URL =
  'market://details?id=com.ubeya.manager.app';
export const ADMIN_WEB_FORGOT_PASSWORD_URL = 'https://auth.ubeya.com/forgot/';
export const ADMIN_WEB_LOGIN_URL = 'https://ottavio.ubeya.com/';
export const ADMIN_WEB_PROJECT_URL = ({ accountId, branchId, projectId }) =>
  `https://ottavio.ubeya.com/${accountId}/scheduling/branch/${branchId}/project/${projectId}`;

export const ANDROID_RATEAPP_URL = config.links.ANDROID_RATEAPP_URL;
export const IPHONE_RATEAPP_MANAGER_URL =
  config.links.IPHONE_RATEAPP_MANAGER_URL;
