import Geolocation, { PositionError } from 'react-native-geolocation-service';
import { hasLocationPermission } from './permissions';
import { Location } from './types';

const EARTH_RADIUS_KM = 6371.01; // Earth's radius in km
const EARTH_RADIUS_MI = 3958.762079; // Earth's radius in miles

export enum GeolocationError {
  NO_LOCATION_AVAILABLE,
  PERMISSION_DENIED,
  POSITION_UNAVAILABLE,
  TIMEOUT,
  UNKNOWN,
}

export const getCurrentLocation = async () => {
  const hasPermission = await hasLocationPermission();

  if (!hasPermission) {
    return Promise.reject(GeolocationError.PERMISSION_DENIED);
  }

  return new Promise<Location>((resolve, reject) =>
    Geolocation.getCurrentPosition(
      (info) => {
        if (info) {
          resolve({
            lat: info.coords.latitude,
            lng: info.coords.longitude,
          });
        } else {
          reject(GeolocationError.NO_LOCATION_AVAILABLE);
        }
      },
      (error) => {
        if (error.code === PositionError.PERMISSION_DENIED) {
          reject(GeolocationError.PERMISSION_DENIED);
        } else if (error.code === PositionError.POSITION_UNAVAILABLE) {
          reject(GeolocationError.POSITION_UNAVAILABLE);
        } else if (error.code === PositionError.TIMEOUT) {
          reject(GeolocationError.TIMEOUT);
        } else {
          reject(GeolocationError.UNKNOWN);
        }
      },
      { timeout: 30000 }
    )
  );
};

export const distance = (src: Location, dst: Location) => {
  if (
    src?.lat === undefined ||
    src?.lng === undefined ||
    dst?.lat === undefined ||
    dst?.lng === undefined
  ) {
    return 0;
  }
  const lat1 = src.lat;
  const lon1 = src.lng;
  const lat2 = dst.lat;
  const lon2 = dst.lng;
  const R = 6371e3; // metres

  const q1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const q2 = (lat2 * Math.PI) / 180;
  const dq = ((lat2 - lat1) * Math.PI) / 180;
  const dg = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(dq / 2) * Math.sin(dq / 2) +
    Math.cos(q1) * Math.cos(q2) * Math.sin(dg / 2) * Math.sin(dg / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.abs(R * c) / 1000; // in metres
};

// Math.acos(
//   Math.sin(src.lat) * Math.sin(dst.lat) + Math.cos(src.lat) * Math.cos(dst.lat) * Math.cos(src.lng - dst.lng),
// ) * EARTH_RADIUS_KM;
