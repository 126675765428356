import React, { FC } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import styled from 'styled-components/native';
import { FlexColumnDirection } from '../Layout';
import { BaseText, H3Regular, H4Regular, H5Regular } from '../Typography';

const Container = styled(FlexColumnDirection)<{ isHidden?: boolean }>`
  margin: 5px 0;
  width: 100%;
  display: ${({ isHidden }) =>
    isHidden && isHidden !== undefined ? 'none' : 'flex'};
`;

const Title = styled(H5Regular)`
  margin: 10px 0;
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme, error }) =>
    error ? theme.colors.error : theme.colors.textPrimary};
`;

const Description = styled(H5Regular)`
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.gray2};
`;

const ErrorMessage = styled(BaseText)`
  color: ${({ theme }) => theme.colors.red1};
  margin-top: 10px;
`;

interface Props {
  title?: string;
  description?: string;
  isHidden?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  error?: string;
  hideTitle?: boolean;
}

export const FormGroup: FC<Props> = ({
  title,
  description,
  children,
  isHidden,
  isReadOnly,
  isRequired,
  error,
  hideTitle = false,
}) => {
  const { t } = useTranslation();

  return (
    <Container isHidden={isHidden}>
      {!!title && !isReadOnly && !hideTitle && (
        <Title error={error}>
          {t(title) || title}
          {isRequired && ' *'}
        </Title>
      )}
      {!!description && <Description>{description}</Description>}
      {children}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Container>
  );
};
