import React, { FC, useCallback } from 'react';
import { useRoute, RouteProp } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';

import { IDirectoryContact } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseImage,
  BaseText,
  BaseView,
  FlexCenter,
  FlexColumnDirection,
  FlexRow,
  H3Medium,
  SvgIcon,
  H4Bold,
  H5Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { Linking } from 'react-native';
import { Alert } from '@violetta/ubeya/alert';
import styled from 'styled-components/native';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  padding: 16px;
`;

const Data = styled(BaseView)`
  margin-left: 15px;
  font-weight: 500;
`;

const Name = styled(H4Bold)`
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const LittleHeader = styled(H5Regular)`
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 10px;
`;

const Phone = styled(H5Regular)`
  font-size: 16px;
  font-weight: normal;
  color: #6d6d78;
`;

const ProfileImage = styled(BaseImage)`
  height: 63px;
  width: 63px;
  border-radius: 33px;
`;

const UserAvatar = styled(FlexCenter)`
  height: 63px;
  width: 63px;
  border-radius: 33px;
  background-color: ${({ theme }) => theme.colors.gray3};
`;

const DataRow = styled(FlexRow)`
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px 14px 27px 14px;
`;

const BigDataRow = styled(DataRow)`
  padding: 30px 14px 47px 14px;
  align-items: center;
  justify-content: flex-start;
`;

const AvatarText = styled(H3Medium)``;

interface Props {}

type ParamList = {
  params: { params: IDirectoryContact };
};

export const ContactScreen: FC<Props> = () => {
  const { params } = useRoute<RouteProp<ParamList, 'params'>>();
  const { firstName, lastName, image, phone, email } = params?.params;
  const { t } = useTranslation();

  const call = useCallback(
    ({ phone }) => {
      if (!phone) {
        Alert.alert(t('contactHasNoPhone'));
        return;
      }
      const url = `tel:${phone}`;
      if (!Linking.canOpenURL(url)) {
        Alert.alert('Can not call from this device');
        return;
      }
      Linking.openURL(url);
    },
    [t]
  );
  const mail = useCallback(({ email }) => {
    const url = `mailto:${email}`;
    if (!Linking.canOpenURL(url)) {
      Alert.alert('Can not send mail from this device');
      return;
    }
    Linking.openURL(url);
  }, []);

  return (
    <Container>
      <BigDataRow>
        {image ? (
          <ProfileImage source={{ uri: image }} />
        ) : (
          <UserAvatar>
            <AvatarText>{firstName?.charAt(0)}</AvatarText>
          </UserAvatar>
        )}
        <Data>
          <Name>
            {firstName} {lastName}
          </Name>
          <Phone>{phone}</Phone>
        </Data>
      </BigDataRow>
      <DataRow as={RipplePressable} onPress={() => call({ phone })}>
        <FlexColumnDirection>
          <LittleHeader>{t('phone')}</LittleHeader>
          <Phone>{phone}</Phone>
        </FlexColumnDirection>
        <SvgIcon xml={icons.actionIcons.phone} width={20} height={20} />
      </DataRow>
      {email && (
        <DataRow as={RipplePressable} onPress={() => mail({ mail: email })}>
          <FlexColumnDirection>
            <LittleHeader>{t('email')}</LittleHeader>
            <Phone>{mail}</Phone>
          </FlexColumnDirection>
          <SvgIcon xml={icons.actionIcons.phone} width={20} height={20} />
        </DataRow>
      )}
    </Container>
  );
};
