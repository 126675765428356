import React, { FC, useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  FieldTypes,
  FieldValues,
  IField,
  IFieldOption,
  IUbeyaForm,
  useUbeyaForm,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { Config } from 'final-form';
import { Form, FormSpy } from 'react-final-form';
import { Linking } from 'react-native';
import Markdown from 'react-native-markdown-display';
import styled from 'styled-components/native';
import { Button } from '../Buttons';
import { CheckBox } from '../CheckBoxes/CheckBox';
import { DateInputPicker } from '../DatePicker';
import {
  AddressField,
  CheckBoxField,
  DatePickerField,
  InputField,
  SelectField,
  SignatureField,
  UploadDocumentField,
  UploadComplianceDocumentField,
} from '../FormFields';
import { BaseImage } from '../Images';
import { BaseView } from '../Layout';
import { FieldSelect } from '../Select';
import { SelectProps } from '../Select/types';
import { TextInput } from '../TextInputs';
import { GooglePlacesTextInput } from '../TextInputs/GooglePlacesTextInput';
import { DocumentLink, H4Regular } from '../Typography';
import { FormGroup } from './FormGroup';

const DocumentLinkContainer = styled(BaseView)`
  margin: 10px 0;
`;

type FieldError = {
  [id: number]: boolean;
};

const isNumber = (x: string) => /^[0-9-]*$/g.exec(x);

interface Props {
  form: IUbeyaForm;
  rtl: boolean;
  onSubmit?: Config['onSubmit'];
  onChange?: (params: {
    values: FieldValues;
    dirty: boolean;
    valid: boolean;
    initialValues: FieldValues;
    dirtyFields: FieldValues;
  }) => void;
  hasSubmitButton?: boolean;
  isSubmitting?: boolean;
  controlledErrors?: FieldError;
  setIsScrollEnabled?: (value: boolean) => void;
}

const validateTextLength = ({
  value,
  maxLength,
  exactLength,
  t,
}: {
  value?: string;
  maxLength?: number;
  exactLength?: number;
  t: any;
}) => {
  if (!value) {
    return undefined;
  }

  if (maxLength) {
    return value.length > maxLength
      ? t('passedMaxChars', { total: maxLength })
      : undefined;
  }

  if (exactLength) {
    return value.length !== exactLength
      ? t('notExactChars', { total: exactLength })
      : undefined;
  }

  return undefined;
};

// eslint-disable-next-line react/no-unused-prop-types
const FieldFactory = ({
  field,
  setIsScrollEnabled,
  t,
  rtl,
}: {
  field: IField & { complianceField?: boolean };
  idx: number;
  setIsScrollEnabled?: (value: boolean) => void;
  t: any;
  rtl: boolean;
}) => {
  const {
    id,
    fieldTypeId,
    name,
    options,
    allowMultiple,
    isMultiline,
    isRequired,
    type = 0,
    metadata,
    complianceField = false,
  } = field;

  const fieldTextMetadata = ({
    metadata,
  }: {
    metadata: { maxNumberOfChar?: string; limitType?: string };
  }) => {
    const result: { exactLength?: number; maxLength?: number } = {};
    const { maxNumberOfChar, limitType } = metadata || {};

    if (!maxNumberOfChar) {
      return result;
    }

    switch (limitType) {
      case 'exact':
        result.exactLength = Number(maxNumberOfChar);
        break;
      case 'max':
        result.maxLength = Number(maxNumberOfChar);
        break;
      default:
    }

    return result;
  };

  const fieldMetadata = ({
    fieldTypeId,
    metadata,
  }: {
    fieldTypeId: FieldTypes;
    metadata: any;
  }) => {
    switch (fieldTypeId) {
      // currently not activated for number type
      case FieldTypes.FIELD_TYPE_TEXT:
        return fieldTextMetadata({ metadata });

      default:
        return {};
    }
  };
  const { navigate } = useNavigation();

  switch (fieldTypeId) {
    case FieldTypes.FIELD_TYPE_DATE:
      return (
        <DatePickerField
          fieldProps={{
            name: `field-${id}-${type}`,
          }}
          props={{
            rtl,
          }}
          component={DateInputPicker}
        />
      );

    case FieldTypes.FIELD_TYPE_TEXT: {
      const fieldMetadataInfo = fieldMetadata({ fieldTypeId, metadata });

      const { maxLength, exactLength } = fieldMetadataInfo || {};

      return (
        <InputField
          fieldProps={{
            name: `field-${id}-${type}`,
            validate: (value: string) =>
              validateTextLength({
                value,
                maxLength,
                exactLength,
                t,
              }),
          }}
          props={{
            ...fieldMetadataInfo,
            //for UI Block
            maxLength: maxLength || exactLength,
            multiline: isMultiline,
            numberOfLines: isMultiline ? 3 : 1,
            hideError: true, //shown throught FormGroup
            isRequired,
            ...(type === 1 && { keyboardType: 'numeric' }),
          }}
          component={TextInput}
        />
      );
    }
    case FieldTypes.FIELD_TYPE_NUMBER:
      return (
        <InputField
          fieldProps={{
            name: `field-${id}-${type}`,
            validate: (value) =>
              isNumber(value) ? undefined : t('onlyNumbers'),
          }}
          props={{
            ...fieldMetadata({ fieldTypeId, metadata }),
            isRequired,
            hideError: true, //shown throught FormGroup
            keyboardType: 'numeric',
          }}
          component={TextInput}
        />
      );
    case FieldTypes.FIELD_TYPE_OPTIONS: {
      const tempOptions =
        id === 23
          ? options?.map(({ title, id }) => ({ title: t(title), id }))
          : options;
      const tempName = id === 23 ? t(name) : name;

      return (
        <SelectField<SelectProps<IFieldOption, IFieldOption['id']>>
          selectComponent={FieldSelect}
          fieldProps={{
            name: `field-${id}-${type}`,
          }}
          props={{
            options: tempOptions!,
            isMulti: allowMultiple,
            searchBy: 'title',
            title: tempName,
            rtl,
          }}
        />
      );
    }

    case FieldTypes.FIELD_TYPE_READONLY_TITLE:
      return (
        <Markdown
          onLinkPress={(url) => {
            if (!url.startsWith('http')) {
              const tempUrl = `http://${url}`;
              Linking.openURL(tempUrl);
              return false;
            }
            return true;
          }}
        >
          {field.metadata?.text}
        </Markdown>
      );
    case FieldTypes.FIELD_TYPE_READONLY_IMAGE: {
      return (
        <BaseImage
          source={{ uri: field.metadata?.path }}
          width={150}
          height={150}
        />
      );
    }
    case FieldTypes.FIELD_TYPE_READONLY_DOCUMENT:
      return (
        <DocumentLinkContainer>
          <DocumentLink
            component={H4Regular}
            onPress={() => {
              if (field.metadata?.path?.toLowerCase()?.endsWith('pdf')) {
                return navigate('pdf-reader-nav', {
                  screen: 'pdf-reader',
                  params: {
                    uri: field.metadata?.path,
                  },
                });
              }
              Linking.openURL(field.metadata?.path);
            }}
          >
            {field.name}
          </DocumentLink>
        </DocumentLinkContainer>
      );
    case FieldTypes.FIELD_TYPE_DOCUMENT:
      return complianceField ? (
        <UploadComplianceDocumentField id={id} field={field} />
      ) : (
        <UploadDocumentField id={id} type={type} field={field} />
      );

    case FieldTypes.FIELD_TYPE_BOOLEAN:
      return (
        <CheckBoxField
          props={{ title: field.name || t(field.slug) || '' }}
          fieldProps={{
            name: `field-${id}-${type}`,
          }}
          component={CheckBox}
        />
      );
    case FieldTypes.FIELD_TYPE_SIGNATURE:
      return <SignatureField id={id} setIsScrollEnabled={setIsScrollEnabled} />;
    case FieldTypes.FIELD_TYPE_ADDRESS:
      return (
        <AddressField
          id={id}
          component={GooglePlacesTextInput}
          fieldProps={{
            name: `field-${id}-${type}`,
          }}
        />
      );
    default:
      return null;
  }
};

const Container = styled(BaseView)``;

export const UbeyaForm: FC<Props> = ({
  form,
  onSubmit = () => {},
  onChange,
  hasSubmitButton,
  controlledErrors = {},
  isSubmitting,
  setIsScrollEnabled,
  rtl,
}) => {
  const { formsResults } = useUbeyaForm();
  const { t } = useTranslation();
  const [formResults] = useState(() =>
    form.fields.reduce(
      (carry, field) => ({
        ...carry,
        [['field', field.id, field.type || '0']
          .filter((cur) => !!cur)
          .join('-')]:
          field.value || formsResults?.[form.id]?.fields[field.id]?.value,
      }),
      {}
    )
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onChangeInternal = useCallback<
    (params: {
      values: FieldValues;
      dirty: boolean;
      initialValues: FieldValues;
      dirtyFields: [string, boolean];
      errors: Record<string, string>;
    }) => void
  >(
    ({ dirty, initialValues, values, dirtyFields, errors = {}, ...rest }) => {
      const formResults = formsResults?.[form.id];
      const allValues =
        Object.keys(values).length > 0 ? values : formResults?.fields;

      onChange?.({
        dirty: dirty || formResults?.dirty || false,
        valid: Object.entries(errors).length === 0,
        initialValues: formResults?.fields || initialValues,
        values: allValues,
        dirtyFields: Object.entries(dirtyFields)
          .filter(([, valueDirtyField]) => !!valueDirtyField)
          .reduce((output, [key]) => {
            output[key] = allValues[key];
            return output;
          }, {} as FieldValues),
      });
    },
    [form.id, formsResults, onChange]
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={formResults}
      render={({ handleSubmit, errors, dirtyFields, valid }) => (
        <Container>
          <FormSpy
            subscription={{
              values: true,
              dirty: true,
              dirtyFields: true,
              initialValues: true,
              errors: true,
            }}
            onChange={onChangeInternal}
          />
          {form.fields.map((field, idx) => (
            <FormGroup
              key={idx}
              title={field.name || t(field.slug || '')}
              isReadOnly={field.isReadOnly}
              isRequired={field.isRequired}
              hideTitle={
                [FieldTypes.FIELD_TYPE_BOOLEAN].includes(field.fieldTypeId) ||
                field.hideTitle
              }
              error={
                (dirtyFields?.[`field-${field.id}-${field.type || 0}`] &&
                  errors?.[`field-${field.id}-${field.type || 0}`]) ||
                controlledErrors?.[field.id]
              }
            >
              <FieldFactory
                field={field}
                idx={idx}
                setIsScrollEnabled={setIsScrollEnabled}
                t={t}
                rtl={rtl}
              />
            </FormGroup>
          ))}

          {hasSubmitButton && (
            <Button
              title={t('saveChanges')}
              type="primary"
              style={{ marginTop: 30 }}
              onPress={handleSubmit}
              isLoading={isSubmitting}
              disabled={
                isSubmitting || !valid || Object.keys(dirtyFields).length === 0
              }
            />
          )}
        </Container>
      )}
    />
  );
};
