import React, { FC, useContext } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderBar, HeaderlessBar } from '@violetta/ubeya/ui';
import { useTheme } from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import { AddRequestScreen } from '../screens/AddRequestScreen';
import { RequestsScreen } from '../screens/RequestsScreen';
import { RequestsScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const RequestsNavigation: FC = () => {
  const { colors } = useTheme();
  const { rtl } = useContext(ConfigContext);

  return (
    <Navigator
      initialRouteName={RequestsScreenNames.MY_REQUESTS}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderlessBar {...props} color={colors.lightSurface} />
        ),
      }}
    >
      <Screen
        name={RequestsScreenNames.MY_REQUESTS}
        component={RequestsScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <HeaderBar {...props} rtl={rtl} />
          ),
        }}
      />
      <Screen
        name={RequestsScreenNames.ADD_REQUEST}
        component={AddRequestScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <HeaderBar {...props} rtl={rtl} />
          ),
        }}
      />
    </Navigator>
  );
};
