import React, { FC, useContext } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderBar } from '@violetta/ubeya/ui';
import { ConfigContext } from '../../../contexts';
import { NotificationsScreen } from '../screens';
import { NotificationsScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const NotifictionsNavigation: FC = () => {
  const { rtl } = useContext(ConfigContext);

  return (
    <Navigator
      initialRouteName={NotificationsScreenNames.NOTIFICATIONS}
      screenOptions={{
        header: () => null,
      }}
    >
      <Screen
        name={NotificationsScreenNames.NOTIFICATIONS}
        component={NotificationsScreen}
        options={{
          header: (props: NativeStackHeaderProps) => (
            <HeaderBar {...props} rtl={rtl} />
          ),
        }}
      />
    </Navigator>
  );
};
