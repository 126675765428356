export enum UpdateStatus {
  OK = 1,
  NEEDS_UPDATE = 2,
  MUST_UPDATE = 3,
}

type BirthdateType = {
  accountId: number;
  date: Date;
};
export interface IUser {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  image: string;
  token: string;
  refreshToken: string;
  accounts: number[];
  languageId: number;
  updateStatus: UpdateStatus;
  subscribeEmail: boolean;
  birthdates: BirthdateType[];
}
