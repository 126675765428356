import React, { FC } from 'react';
import { InferProps } from '@violetta/ubeya/utils';
import { Field } from 'react-final-form';
import { Radio } from '../Radios';
import { FormFieldProps } from './types';

export const RadioField: FC<
  FormFieldProps<boolean, InferProps<typeof Radio>>
> = ({ fieldProps, props, component: Component }) => (
  <Field<boolean>
    {...fieldProps}
    render={({ input }) => (
      <Component
        {...props}
        onChange={(value) => {
          input.onChange(value);
          props?.onChange?.(value);
        }}
        value={input.value}
      />
    )}
  />
);
