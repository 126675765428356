import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigation } from '@react-navigation/native';
import { images } from '@violetta/ubeya/assets';
import { useBranches, useDirectory } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseCollapsible,
  BaseView,
  BranchSelect,
  FlexCenter,
  FlexColumnDirection,
  FlexRowCenter,
  FormGroup,
  H5Medium,
  SearchBox,
  SvgIcon,
  WideFlexColumnCenter,
} from '@violetta/ubeya/ui';
import { Alert } from '@violetta/ubeya/alert';
import { Linking, SectionList } from 'react-native';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts/ConfigContext';
import { DirectoryCard } from '../components/DirectoryCard';
import { DirectoryScreenNames } from '../navigation';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const FiltersContainer = styled(BaseView)`
  padding: 0 12px;
`;

const SearchContainer = styled(FlexCenter)`
  margin-top: 15px;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  flex-grow: 0;
`;

const TitleContainer = styled(FlexRowCenter)`
  padding: 10px;
`;

const SectionTitle = styled(H5Medium)`
  font-weight: 500;
  margin-left: 10px;
  font-size: 16px;
  line-height: 20px;
`;

const NumberTitle = styled(H5Medium)`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: #6d6d78;
  margin-left: 5px;
`;

const NoContactsText = styled(H5Medium)`
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
  margin-top: 27px;
`;

const EmptyDirectoryList = () => {
  const { t } = useTranslation();
  return (
    <WideFlexColumnCenter>
      <SvgIcon
        xml={images.svgs.phonebookNoSearchResults}
        width={180}
        height={180}
      />
      <NoContactsText>{t('noContacts')}</NoContactsText>
    </WideFlexColumnCenter>
  );
};

interface Props {}

export const DirectoryScreen: FC<Props> = () => {
  const { t } = useTranslation();
  const { rtl } = useContext(ConfigContext);
  const { branches } = useBranches();
  const [branchId, setBranchId] = useState<number>(() =>
    (branches || []).length === 0 ? 0 : branches![0].id
  );
  const { navigate } = useNavigation();
  const { directory, refetch, isLoading } = useDirectory(branchId);

  const [filteredContacts, setFilteredContacts] = useState([
    ...(directory || []),
  ]);

  useEffect(() => {
    setFilteredContacts(directory || []);
  }, [directory]);

  const filterContacts = useCallback(
    (searchTerm: string) => {
      const newContacts = (directory || []).filter(
        (contact) =>
          contact?.firstName
            ?.toLocaleLowerCase()
            ?.startsWith(searchTerm?.toLocaleLowerCase()) ||
          contact?.lastName
            ?.toLocaleLowerCase()
            ?.startsWith(searchTerm?.toLocaleLowerCase())
      );

      setFilteredContacts(newContacts);
    },
    [directory]
  );

  const onSelected = useCallback((value: number[]) => {
    setBranchId(value?.[0]);
  }, []);

  const selectedBranches = useMemo(
    () => (branchId === 0 ? [] : [branchId]),
    [branchId]
  );

  const firstLetters = useMemo(
    () => [
      ...new Set<string>(
        filteredContacts.map((contact) => contact?.firstName[0])
      ),
    ],
    [filteredContacts]
  );
  const separatedContactsByFirstLetter = useMemo(
    () =>
      firstLetters
        .map((letter) => ({
          title: letter,
          data: [
            filteredContacts.filter((cont) =>
              cont?.firstName?.startsWith(letter)
            ),
          ],
        }))
        .sort((a, b) => (a.title > b.title ? 1 : -1)),
    [filteredContacts, firstLetters]
  );

  const call = useCallback(
    ({ phone }) => {
      if (!phone) {
        Alert.alert(t('contactHasNoPhone'));
        return;
      }
      const url = `tel:${phone}`;
      if (!Linking.canOpenURL(url)) {
        Alert.alert('Can not call from this device');
        return;
      }
      Linking.openURL(url);
    },
    [t]
  );

  const handleNavigateToContactScreen = useCallback(
    (contact) => navigate(DirectoryScreenNames.CONTACT, { params: contact }),
    [navigate]
  );

  return (
    <Container>
      <FiltersContainer>
        <FormGroup title={t('branch')} isHidden={branches.length === 1}>
          <BranchSelect
            placeholder="choose branch"
            options={branches}
            isMulti={false}
            onSelected={onSelected}
            value={selectedBranches}
          />
        </FormGroup>
        <SearchContainer>
          <SearchBox onChange={filterContacts} />
        </SearchContainer>
      </FiltersContainer>
      <SectionList
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 50,
          marginTop: 15,
          marginLeft: 20,
          marginRight: 20,
        }}
        sections={separatedContactsByFirstLetter}
        keyExtractor={(item) => Math.random().toString()}
        renderItem={({ item: items, section }) => (
          <BaseCollapsible
            isCollpased={false}
            trigger={() => (
              <TitleContainer>
                <SectionTitle>{section.title}</SectionTitle>
                <NumberTitle>({items?.length})</NumberTitle>
              </TitleContainer>
            )}
          >
            {items.map((item, index) => (
              <DirectoryCard
                contact={item}
                onPress={handleNavigateToContactScreen}
                key={index + item.id}
                onIconPress={call}
              />
            ))}
          </BaseCollapsible>
        )}
        ListEmptyComponent={isLoading ? <BaseView /> : <EmptyDirectoryList />}
        refreshing={isLoading}
        onRefresh={refetch}
        stickySectionHeadersEnabled={false}
      />
    </Container>
  );
};
