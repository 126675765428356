import React, { FC } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { SvgIcon } from '../Icons';
import { FlexRowBetween, FlexRowCenter } from '../Layout';
import { H3Medium } from '../Typography';

const Container = styled(FlexRowBetween)`
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px;
  background-color: #eeedf5;
`;

const Title = styled(H3Medium)`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray1};
`;

const LeftContainer = styled(FlexRowCenter)``;

interface Props {
  title: string;
  icon?: {
    xml: string;
    width: number;
    height: number;
  };
  onClick?: () => void;
  rtl?: boolean;
}

export const CardHeader: FC<Props> = ({ title, icon, onClick, rtl = false }) => (
  <Pressable onPress={onClick}>
    <Container>
      <LeftContainer>
        {icon && <SvgIcon xml={icon.xml} width={icon.width} height={icon.height} />}
        <Title>{title}</Title>
      </LeftContainer>
      {/* {onClick && <ChevronRight size={20} rtl={rtl} />} */}
    </Container>
  </Pressable>
);
