import { Linking, PermissionsAndroid, Platform } from 'react-native';
import { Alert } from '@violetta/ubeya/alert';
import Geolocation from 'react-native-geolocation-service';

export const hasLocationPermissionIOS = async () => {
  const openSetting = () => {
    Linking.openSettings().catch(() => {
      Alert.alert('Unable to open settings');
    });
  };
  const status = await Geolocation.requestAuthorization('whenInUse');

  if (status === 'granted') {
    return true;
  }

  if (status === 'denied' || status === 'disabled') {
    // Alert.alert('Location permission denied');
    Alert.alert(
      'Turn on Location Services to allow Ubeya to determine your location.',
      '',
      [
        { text: 'Go to Settings', onPress: openSetting, type: 'confirm' },
        { text: "Don't Use Location", onPress: () => {}, type: 'cancel' },
      ]
    );
  }

  return false;
};

export const hasLocationPermission = async () => {
  if (Platform.OS === 'web') {
    return true;
  }

  if (Platform.OS === 'ios') {
    const hasPermission = await hasLocationPermissionIOS();
    return hasPermission;
  }

  if (Platform.OS === 'android' && Platform.Version < 23) {
    return true;
  }

  const hasPermission = await PermissionsAndroid.check(
    PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
  );

  if (hasPermission) {
    return true;
  }

  const status = await PermissionsAndroid.request(
    PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION
  );

  if (status === PermissionsAndroid.RESULTS.GRANTED) {
    return true;
  }

  if (status === PermissionsAndroid.RESULTS.DENIED) {
    return false;
  }
  if (status === PermissionsAndroid.RESULTS.NEVER_ASK_AGAIN) {
    return false;
  }

  return false;
};
