import React, { FC, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Booking, ITimesheet, useUniforms } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseImage,
  BaseView,
  DocumentCard,
  FlexColumnDirection,
  FlexRow,
  FlexRowBetween,
  H4Bold,
  H5Regular,
  HyperLink,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { isFileImage } from '@violetta/ubeya/utils';
import { Linking } from 'react-native';
import styled from 'styled-components/native';
import { FeedScreenNames } from '../../feed/navigation';

const Container = styled(FlexColumnDirection)`
  width: 100%;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
  border-bottom-width: 1px;
  padding: 0 30px;
`;

const ColumnDetail = styled(FlexColumnDirection)`
  margin-bottom: 7px;
  margin-top: 7px;
`;

const DataTitle = styled(H4Bold)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-bottom: 4px;
`;
const DataDetails = styled(H5Regular)`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
  max-width: 80%;
`;

const Image = styled(BaseImage)`
  width: 50px;
  height: 50px;
`;

const Document = styled(BaseView)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: solid 1px #c9c9c9;
  border-radius: 8px;
  margin-right: 10px;
  position: relative;
`;

const UniformRow = styled(FlexRow)``;

const DataDetailsLink = styled(DataDetails)`
  color: ${({ theme }) => theme.colors.link};
`;

const ImageContainer = styled(BaseView)``;

const DocumentsContainer = styled(BaseView)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

interface Props {
  uniformId: number | null;
  locationId: number | null;
  date: Date;
  meetingSpot: string | null;
  clientName: string | null;
  onsiteContact: string | null;
  onsiteContactPhone: string | null;
  comments: string | null;
  generalComments: string | null;
  name: string;
  timesheet: ITimesheet;
  booking: Booking;
  withLogo?: boolean;
  showBreaksNotice?: boolean;
  openLocationMap?: () => void;
}

export const BookingDetails: FC<Props> = ({ booking }) => {
  const { mappedUniforms } = useUniforms();

  const uniformId = booking?.project?.uniformId;
  const clientName = booking?.project?.clientName;
  const meetingSpot = booking?.project?.meetingSpot;
  const onsiteContact = booking?.project?.onsiteContact;
  const onsiteContactPhone = booking?.project?.onsiteContactPhone;
  const comments = booking?.comments;
  const generalComments = booking?.project?.comments;

  const { t } = useTranslation();
  const { navigate } = useNavigation();

  const openGallery = useCallback(
    (uri) => {
      navigate(FeedScreenNames.GALLERY, {
        items: [
          {
            idx: 0,
            metadata: {},
            type: 'image',
            uri,
          },
        ],
        idx: 0,
      });
    },
    [navigate]
  );

  const openAttachmentImagesGallery = useCallback(
    ({ id: selectedId }) => {
      const images = (booking?.documents || []).filter(
        (document) => document?.link && isFileImage(document?.link)
      );
      const selectedIdx = images.findIndex(({ id }) => selectedId === id);
      if (selectedIdx === -1) {
        return;
      }
      navigate(FeedScreenNames.GALLERY, {
        items: images.map(({ link }, idx) => ({
          idx,
          metadata: {},
          type: 'image',
          uri: link,
        })),
        idx: selectedIdx,
      });
    },
    [booking?.documents, navigate]
  );

  return (
    <Container>
      {!!comments && (
        <ColumnDetail>
          <DataTitle>{t('notes')}</DataTitle>
          <HyperLink>
            <DataDetails>{comments}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}
      {uniformId && (
        <ColumnDetail>
          <DataTitle>{t('uniform')}</DataTitle>
          <UniformRow>
            {mappedUniforms[uniformId]?.image && (
              <ImageContainer
                as={RipplePressable}
                onPress={() => openGallery(mappedUniforms[uniformId]?.image)}
              >
                <Document>
                  <Image source={{ uri: mappedUniforms[uniformId]?.image! }} />
                </Document>
              </ImageContainer>
            )}
            <DataDetails>
              {mappedUniforms[uniformId]?.name}{' '}
              {mappedUniforms[uniformId]?.description}
            </DataDetails>
          </UniformRow>
        </ColumnDetail>
      )}
      {!!meetingSpot && (
        <ColumnDetail>
          <DataTitle>{t('meetingSpot')}</DataTitle>
          <HyperLink>
            <DataDetails>{meetingSpot}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}
      {!!generalComments && (
        <ColumnDetail>
          <DataTitle>{t('generalComments')}</DataTitle>
          <HyperLink>
            <DataDetails>{generalComments}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}

      {!!clientName && (
        <ColumnDetail>
          <DataTitle>{t('clientName')}</DataTitle>
          <HyperLink>
            <DataDetails>{clientName}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}
      {!!onsiteContact && (
        <ColumnDetail>
          <DataTitle>{t('onsiteContact')}</DataTitle>
          <HyperLink>
            <DataDetails>{onsiteContact}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}
      {!!onsiteContactPhone && (
        <ColumnDetail
          as={RipplePressable}
          onPress={() => Linking.openURL(`tel:${onsiteContactPhone}`)}
        >
          <DataTitle>{t('onsiteContactPhone')}</DataTitle>
          <DataDetailsLink>{onsiteContactPhone}</DataDetailsLink>
        </ColumnDetail>
      )}
      {booking?.documents?.length > 0 && (
        <DataTitle>{t('documents')}</DataTitle>
      )}
      {booking?.documents?.length > 0 && (
        <DocumentsContainer>
          {(booking?.documents || []).map((document) => (
            <DocumentCard
              document={document}
              key={document?.id}
              openAttachmentImagesGallery={openAttachmentImagesGallery}
            />
          ))}
        </DocumentsContainer>
      )}
    </Container>
  );
};
