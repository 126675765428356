import React, { FC, useMemo } from 'react';
import { IQuestionOption } from '@violetta/ubeya/entities';
import {
  BaseView,
  Card,
  CustomLoader,
  FlexRowCenter,
  H6Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { parseFilterArgs } from 'react-query/types/core/utils';
import styled from 'styled-components/native';

const Container = styled(FlexRowCenter)`
  margin-top: 5px;
  margin-bottom: 5px;
`;

const OptionCard = styled(BaseView)<{
  selected: boolean;
  isLoading: boolean;
  isDisabled: boolean;
}>`
  position: relative;
  border: solid 1px
    ${({ selected, theme }) => (selected ? theme.colors.primary : '#ddd')};
  height: 45px;
  flex-direction: row;
  flex: 1;
  margin: 0;
  border-radius: 4px;
  ${({ isDisabled }) => isDisabled && 'opacity: 0.3'};
`;

const Title = styled(H6Regular)<{ selected?: boolean }>`
  font-family: Rubik;
  letter-spacing: 0.3px;
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const LeftContainer = styled(BaseView)`
  ${({ widthPercentage }) => `width: ${widthPercentage}%`};
  border-radius: 3px;
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.primary : theme.colors.primaryLighter};
`;

const RightContainer = styled(BaseView)`
  ${({ widthPercentage }) => `width: ${widthPercentage}%`};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.background};
`;

const Answers = styled(H6Regular)`
  margin-left: 10px;
  width: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
`;

const TitleContainer = styled(BaseView)`
  position: absolute;
  left: 7px;
  height: 45px;
  justify-content: center;
  max-width: 290px;
`;

interface Props {
  option: IQuestionOption;
  selected: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  totalAnswerNum: number;
  onPress: (id: number) => void;
}

export const QuestionOption: FC<Props> = ({
  option: { answersTotal, id, text },
  selected,
  isLoading,
  isDisabled,
  totalAnswerNum,
  onPress,
}) => {
  const coloredPercentage = useMemo(
    () =>
      answersTotal !== 0 ? ((answersTotal * 1.0) / totalAnswerNum) * 100 : 0,
    [answersTotal, totalAnswerNum]
  );
  const uncoloredPercentage = useMemo(
    () => 100 - coloredPercentage,
    [coloredPercentage]
  );

  return (
    <Container>
      <OptionCard
        as={RipplePressable}
        onPress={() => !isDisabled && onPress(id)}
        isLoading={isLoading}
        isDisabled={isDisabled}
        selected={selected}
      >
        <LeftContainer
          widthPercentage={coloredPercentage}
          selected={selected}
        />
        <RightContainer widthPercentage={uncoloredPercentage} />
        <TitleContainer>
          <Title selected={selected} numberOfLines={2}>
            {text}
          </Title>
          {isLoading && <CustomLoader size={15} />}
        </TitleContainer>
      </OptionCard>
      <Answers>+{answersTotal}</Answers>
    </Container>
  );
};
