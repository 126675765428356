import { IPost, useAccounts } from '@violetta/ubeya/entities';
import {
  BaseImage,
  BaseText,
  BaseView,
  FlexRowBetween,
  FlexRowCenter,
  H5Medium,
  H5Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components/native';

const Container = styled(FlexRowBetween)`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const AccountLogo = styled(BaseImage)`
  height: 36px;
  width: 36px;
  border-radius: 50px;
`;

const EmptyImage = styled(FlexRowCenter)`
  height: 36px;
  width: 36px;
  border-radius: 50px;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray9};
`;

const EmptyImageTitle = styled(H5Medium)`
  font-weight: 500;
`;

const LeftContainer = styled(FlexRowCenter)``;

const ProjectName = styled(H5Regular)`
  font-size: 17px;
  font-weight: 500;
`;
const Title = styled(BaseView)`
  display: flex;
`;
const RightContainer = styled(RipplePressable)``;

const AccountName = styled(H5Regular)`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 2px;
`;

const PostTime = styled(H5Regular)`
  color: ${({ theme }) => theme.colors.gray2};
  font-size: 12px;
  font-weight: normal;
`;

const HeaderTextContainer = styled(BaseView)`
  margin-left: 10px;
  margin-right: 50px;
`;

const ModalContainer = styled(BaseView)`
  padding: 23px;
`;

const ModalRow = styled(FlexRowBetween)`
  margin-bottom: 33px;
  align-items: center;
`;

const ModalRowText = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  color: #1e2534;
`;

const ModalRowTextBold = styled(ModalRowText)`
  font-weight: bold;
`;

interface Props {
  post: IPost;
  title?: string;
}

export const PostHeader: FC<Props> = ({ post, title }) => {
  // const { data: userData } = useUser();
  // const { imageFunc, nameFunc } = useUserChatFunctions();

  // const { data: roomsData } = useRooms({
  //   id: userData?.id,
  //   type: 'user',
  //   imageFunc,
  //   nameFunc,
  // });
  // const { navigate } = useNavigation();
  // const { t } = useTranslation();
  // const modalizeRef = useRef<Modalize>(null);
  // const roomId = useMemo(
  //   () => roomsData?.find((d) => d?.contextId?.toString() === post?.accountId?.toString())?.id,
  //   [post?.accountId, roomsData],
  // );

  // const onRoomClick = useCallback(() => {
  //   if (roomId) {
  //     modalizeRef?.current?.close();
  //     navigate(ChatScreenNames.ROOM, {
  //       screen: ChatScreenNames.ROOM,
  //       params: { roomId },
  //     });
  //   }
  // }, [navigate, roomId]);

  const { mappedAccounts } = useAccounts();

  const { name, logo } = useMemo(
    () => mappedAccounts?.[post.accountId] || {},
    [post, mappedAccounts]
  );

  const ago = useMemo(() => moment.utc(post.createdAt).fromNow(), [post]);

  return (
    <Container>
      <LeftContainer>
        {logo ? (
          <AccountLogo source={{ uri: logo }} />
        ) : (
          <EmptyImage>
            <EmptyImageTitle>{name?.charAt(0)}</EmptyImageTitle>
          </EmptyImage>
        )}

        <HeaderTextContainer>
          <Title>
            <AccountName>{name}</AccountName>
            {!!title && <ProjectName numberOfLines={2}>{title}</ProjectName>}
          </Title>
          <PostTime>{ago}</PostTime>
        </HeaderTextContainer>
      </LeftContainer>
      {/* <RightContainer onPress={() => modalizeRef?.current?.open()}>
        <SvgIcon xml={icons.actionIcons.actionsMenu} width={20} height={20} rotation="90deg" />
      </RightContainer> */}
      {/* <Portal>
        <Modalize
          ref={modalizeRef}
          adjustToContentHeight
          handlePosition="inside"
          HeaderComponent={<ModalHeader title={name} modalizeRef={modalizeRef} />}>
          <ModalContainer>
            <ModalRow as={RipplePressable} onPress={onRoomClick}>
              <ModalRowText>
                {t('send')} <ModalRowTextBold>{name}</ModalRowTextBold> {t('aMessage')}
              </ModalRowText>
            </ModalRow>
          </ModalContainer>
        </Modalize>
      </Portal> */}
    </Container>
  );
};
