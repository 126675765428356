import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import {
  useAccounts,
  useBranches,
  useSequence,
  useSequences,
  useUbeyaForm,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BackButton,
  BaseScreen,
  BaseView,
  Button,
  FlexColumn,
  FlexColumnBetween,
  FlexColumnDirection,
  FlexRow,
  FlexRowBetween,
  H1Bold,
  H3Bold,
  H4Regular,
  H5Regular,
  ProgressBar,
  SvgIcon,
} from '@violetta/ubeya/ui';
import styled, { useTheme } from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import { SequencesScreenNames } from '../navigation/ScreenNames';
import { Platform } from 'react-native';

const Container = styled(FlexColumnBetween)`
  padding: 10px 30px 10px 30px;
  flex: 1;
  height: 100%;
  width: 100%;
`;
const TitleContainer = styled(FlexColumnDirection)`
  width: 100%;
`;
const Company = styled(FlexColumn)`
  width: 100%;
  align-items: center;
  text-align: center;
  margin-right: 40px;
`;

const CompanyName = styled(H1Bold)`
  width: 100%;
  text-align: center;
  margin: 0 0 30px;
`;

const Title = styled(H1Bold)`
  text-align: left;
`;

const Top = styled(FlexColumnDirection)`
  flex: 1;
  width: 100%;
`;
const Bottom = styled(BaseView)`
  width: 100%;
`;

const Progress = styled(FlexColumnDirection)``;

const ProgressRow = styled(FlexRowBetween)``;
const ProgressTitle = styled(H3Bold)`
  margin-bottom: 10px;
`;

const Description = styled(H4Regular)`
  margin: 40px 0;
  text-align: left;
`;

const Forms = styled(FlexColumnDirection)`
  margin-top: 50px;
`;
const FormRow = styled(FlexRow)`
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray11};
  padding: 20px 0;
  align-items: center;
`;

const FormStatus = styled(BaseView)`
  width: 30px;
`;
const FormIcon = styled(BaseView)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: ${({ theme }) => theme.colors.primary};
`;
const FormName = styled(H5Regular)`
  margin-left: 15px;
`;

const Logo = styled.Image`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-right: 20px;
`;

const Header = styled(FlexRow)`
  padding: 5px 10px;
  align-items: center;
  justify-content: center;
`;

interface Props {}

type ParamList = {
  params: {
    sequenceId: number;
    instanceId: number;
    employeeSlotId?: number;
  };
};

export const SequenceScreen: FC<Props> = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { mappedBranches } = useBranches();
  const { mappedAccounts } = useAccounts();

  const { goBack } = useNavigation();
  const { rtl } = useContext(ConfigContext);
  const {
    params: { instanceId, sequenceId, employeeSlotId },
  } = useRoute<RouteProp<ParamList, 'params'>>();

  const { selectedSequenceId, setSelectedSequenceId } = useSequences();
  const { isLoading, sequenceInstance, completedFormIds, isStale } =
    useSequence(sequenceId, instanceId);

  useEffect(() => {
    if (selectedSequenceId !== sequenceId && sequenceId) {
      setSelectedSequenceId(sequenceId);
    }
  }, [selectedSequenceId, sequenceId, setSelectedSequenceId]);

  useEffect(() => {
    if (!isLoading && !sequenceInstance && !isStale) {
      goBack();
    }
  }, [goBack, isLoading, isStale, sequenceInstance]);

  const { setCurrentFormId, isFormCompleted, isReadOnlyForm } = useUbeyaForm();

  const forms = useMemo(
    () => sequenceInstance?.sequence?.forms,
    [sequenceInstance?.sequence?.forms]
  );
  const nonReadOnlyForms = useMemo(
    () => forms?.filter((form) => !isReadOnlyForm(form)) || [],
    [forms, isReadOnlyForm]
  );
  const nonReadOnlyCompletedIds = useMemo(
    () =>
      Array.from(completedFormIds).filter((id) =>
        nonReadOnlyForms?.map(({ id }) => id).includes(id)
      ),
    [completedFormIds, nonReadOnlyForms]
  );

  const onPress = useCallback(() => {
    if (!forms?.[0].id) {
      return;
    }

    setCurrentFormId(forms?.[0].id);

    navigate(SequencesScreenNames.SEQUENCE_FORMS, {
      //   forms,
      sequenceId,
      instanceId,
      employeeSlotId,
    });
  }, [
    forms,
    setCurrentFormId,
    navigate,
    sequenceId,
    instanceId,
    employeeSlotId,
  ]);

  const company = useMemo(() => {
    if (!sequenceInstance?.sequence?.branches) {
      return null;
    }
    for (const branchId of sequenceInstance?.sequence?.branches) {
      if (mappedBranches[branchId]) {
        return {
          name: mappedBranches[branchId]?.name,
          logo:
            mappedBranches[branchId]?.logo ||
            mappedAccounts[mappedBranches[branchId]?.accountId]?.logo,
        };
      }
    }
    return null;
  }, [mappedAccounts, mappedBranches, sequenceInstance]);

  const percentage = useMemo(
    () =>
      nonReadOnlyCompletedIds?.length && nonReadOnlyCompletedIds.length > 0
        ? nonReadOnlyCompletedIds.length / nonReadOnlyForms.length
        : 0,
    [nonReadOnlyCompletedIds.length, nonReadOnlyForms.length]
  );

  return (
    <BaseScreen isLoading={isLoading}>
      <Header>
        <BackButton rtl={rtl} onPress={goBack} />
        {!!company?.logo && (
          <Company>
            <Logo source={{ uri: company.logo }} />
          </Company>
        )}
      </Header>
      <Container>
        <Top>
          <TitleContainer>
            {!!company?.name && (
              <CompanyName numberOfLines={1}>{company.name}</CompanyName>
            )}
            <Title>{sequenceInstance?.sequence?.title}</Title>
          </TitleContainer>
          <Description>{sequenceInstance?.sequence?.description}</Description>

          {nonReadOnlyCompletedIds.length > 0 && (
            <>
              <Progress>
                <ProgressRow>
                  <ProgressTitle>{t('steps')}</ProgressTitle>
                  <ProgressTitle>
                    {nonReadOnlyCompletedIds.length}/{nonReadOnlyForms?.length}
                  </ProgressTitle>
                </ProgressRow>
                <ProgressBar percentage={percentage} />
              </Progress>

              <Forms>
                {nonReadOnlyForms.map((form, idx) => (
                  <FormRow key={idx}>
                    <FormStatus>
                      {isFormCompleted(form) && (
                        <FormIcon>
                          <SvgIcon
                            xml={icons.actionIcons.done}
                            height={16}
                            width={16}
                            color={theme.colors.lightSurface}
                          />
                        </FormIcon>
                      )}
                    </FormStatus>

                    <FormName>{form.title}</FormName>
                  </FormRow>
                ))}
              </Forms>
            </>
          )}
        </Top>
        <Bottom>
          <Button
            type="primary"
            style={{
              width: '100%',
              ...(Platform.OS === 'android' && { marginBottom: 30 }),
            }}
            onPress={onPress}
            title={t('letsStart')}
          />
        </Bottom>
      </Container>
    </BaseScreen>
  );
};
