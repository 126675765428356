import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useBranches, useLabels } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  FlexColumnDirection,
  FlexParent,
  FlexRow,
  FlexRowCenter,
  H2Regular,
  H3Medium,
  H3Regular,
  H5Light,
  H5Regular,
  H6Regular,
  IconClose,
  InputField,
  Modal,
  TextInput,
  TimeLabelContainerProps,
  TimePicker,
  TimePickerField,
} from '@violetta/ubeya/ui';
import { generateRange, rangeFormat, required } from '@violetta/ubeya/utils';
import moment from 'moment';
import { Form, FormSpy, useField } from 'react-final-form';
import { Keyboard, Pressable, TouchableWithoutFeedback } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';

type FormValues = {
  startTime: Date;
  endTime: Date;
  status: 0 | 1;
  comment?: string;
};

interface Props {
  branchId: number;
  date: Date;
  weekStart: Date;
  availabilityModalizeRef: React.RefObject<Modalize>;
  weekEnd: Date;
  onClose?: () => void;
  onSave?: (values: FormValues & { dates: Date[] }) => void;
  status: 0 | 1;
}

const ModalHeader = styled(FlexParent)`
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface};
  padding: 15px;
  margin-bottom: 30px;
  border-radius: 15px;
`;

const ICON_SIZE = 22;

const WarningText = styled(H3Medium)`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.seconday2};
`;
const H3BoldWhite = styled(H3Medium)`
  font-weight: 500;
  margin-bottom: 40px;
  color: #252631;
`;
const EmptyView = styled(BaseView)`
  width: ${ICON_SIZE}px;
`;

const Hours = styled(FlexRowCenter)`
  align-self: center;
`;
const Dash = styled(H3Regular)`
  margin: 0 10px;
`;

const Container = styled(FlexColumnDirection)`
  flex: 1;
  padding-bottom: 20px;
  align-items: center;
`;

const ButtonContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  margin: 20px 0;
`;

const CurrentDate = styled(H5Light)`
  text-align: center;
`;

const Strip = styled(BaseView)`
  margin-bottom: 45px;
  height: 1px;
  background-color: rgba(37, 37, 37, 0.1);
`;

const TimeLabelContainer = styled(BaseView)`
  border-bottom-width: 2px;
  border-color: rgba(37, 37, 37, 0.1);
`;

const TimeLabel = styled(H2Regular)`
  font-size: 26px;
  padding: 5px 15px;
`;
const CustomTimepickerContainer = styled(BaseView)``;
const CustomTimepickerBottomTitle = styled(H6Regular)`
  margin-top: 12px;
  text-align: center;
`;

const CommentContainer = styled(BaseView)`
  margin: 24px;
  width: 80%;
`;

const Label = styled(H5Regular)`
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  color: #595959;
  margin-bottom: 4px;
`;

const LabelsContainer = styled(BaseView)`
  padding: 0 40px;
`;

const CustomTimepicker: FC<TimeLabelContainerProps & { label: string }> = ({
  setShow,
  value,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <CustomTimepickerContainer>
      <TimeLabelContainer as={Pressable} onPress={() => setShow(true)}>
        <TimeLabel>
          {moment(value).isValid() ? moment(value).format('LT') : '-'}
        </TimeLabel>
      </TimeLabelContainer>
      <BaseView>
        <CustomTimepickerBottomTitle>{t(label)}</CustomTimepickerBottomTitle>
      </BaseView>
    </CustomTimepickerContainer>
  );
};

const Timepickers: FC<{ onTimepickersChange?: () => void }> = () => {
  const { rtl } = useContext(ConfigContext);

  const {
    input: { onChange },
  } = useField('endTime');

  const onChangeInternal = ({ values }: { values: Partial<FormValues> }) => {
    if (
      !values.startTime ||
      !values.endTime ||
      moment(values.endTime).isSameOrAfter(moment(values.startTime))
    ) {
      return;
    }

    const startTime = moment(values.startTime);
    const newEndDate = moment(values.endTime);
    newEndDate.set({
      year: startTime.year(),
      month: startTime.month(),
      date: startTime.date(),
    });

    newEndDate.add(1, 'd');

    onChange(newEndDate.toDate());
  };

  return (
    <Hours rtl={rtl}>
      <FormSpy
        subscription={{ values: true, dirty: true, dirtyFields: true }}
        onChange={onChangeInternal}
      />
      <TimePickerField
        component={TimePicker}
        fieldProps={{
          name: 'startTime',
          validate: required,
        }}
        props={{
          transparent: false,
          step: 30,
          component: ({ setShow, value }) => (
            <CustomTimepicker setShow={setShow} value={value} label="from" />
          ),
        }}
      />
      <Dash />
      <TimePickerField
        component={TimePicker}
        fieldProps={{
          name: 'endTime',
          validate: required,
        }}
        props={{
          transparent: false,
          step: 30,
          component: ({ setShow, value }) => (
            <CustomTimepicker setShow={setShow} value={value} label="to" />
          ),
        }}
      />
    </Hours>
  );
};

const Warning = () => {
  const {
    input: { value: startTimeValue },
  } = useField('startTime');

  const {
    input: { value: endTimeValue },
  } = useField('endTime');

  const { t } = useTranslation();

  const showWarning = useMemo(() => {
    const startTimeMoment = startTimeValue && moment(startTimeValue);
    const endTimeMoment = endTimeValue && moment(endTimeValue);
    if (!startTimeMoment || !endTimeMoment) {
      return false;
    }
    if (startTimeMoment.hours() < endTimeMoment.hours()) {
      return false;
    }
    if (startTimeMoment.hours() === endTimeMoment.hours()) {
      return startTimeMoment.minutes() > endTimeMoment.minutes();
    }
    return true;
  }, [endTimeValue, startTimeValue]);

  if (!showWarning) {
    return null;
  }

  return <WarningText>{t('timePickrOverDayWarning')}</WarningText>;
};

export const AddTimeAvailabilityModal: FC<Props> = ({
  date,
  branchId,
  onClose,
  onSave,
  availabilityModalizeRef,
  weekStart,
  weekEnd,
  status,
}) => {
  const { t } = useTranslation();
  const { mappedBranches } = useBranches();
  const { labels } = useLabels();

  const [selectedLabel, setSelectedLabel] = useState<number | null>(null);
  const relevantLabels = useMemo(
    () =>
      labels.filter(
        (l) =>
          !l.isDeleted &&
          !!l.startTime &&
          !!l.endTime &&
          (l.branches || []).includes(branchId)
      ),
    [branchId, labels]
  );

  const { bottom, top } = useSafeAreaInsets();

  const dates = useMemo(
    () => generateRange(weekStart, weekEnd),
    [weekEnd, weekStart]
  );
  const [selectedDays, setSelectedDays] = useState(() =>
    dates.reduce(
      (carry, item) => ({
        ...carry,
        [moment(item).format('YYYY-MM-DD')]: moment(item).isSame(
          moment(date),
          'date'
        ),
      }),
      {} as { [key: string]: boolean }
    )
  );

  const initialValues = useMemo(() => {
    const startTime = moment();
    const endTime = moment();
    startTime.set({ hour: 10, minute: 30 });
    endTime.set({ hour: 22, minute: 30 });

    const branch = mappedBranches[branchId];
    const defaultStartTime = branch?.timesheetConfig?.defaultJobStartTime;
    const defaultEndTime = branch?.timesheetConfig?.defaultJobEndTime;
    if (defaultStartTime) {
      const [hour, minute] = defaultStartTime.split(':');
      startTime.set({ hour, minute });
    }
    if (defaultEndTime) {
      const [hour, minute] = defaultEndTime.split(':');
      endTime.set({ hour, minute });
    }

    return { startTime: startTime.toDate(), endTime: endTime.toDate() };
  }, [branchId, mappedBranches]);

  const getSelectedDates = useCallback(
    () =>
      Object.keys(selectedDays)
        .filter((key) => !!selectedDays[key])
        .map((date) => moment(date, 'YYYY-MM-DD').toDate()),
    [selectedDays]
  );

  const onSaveInternal = useCallback(
    (values: FormValues) => {
      onSave?.({
        ...values,
        dates: [date],
        status,
      });
    },

    [date, onSave, status]
  );

  return (
    <Portal>
      <Modalize
        withHandle={false}
        ref={availabilityModalizeRef}
        HeaderComponent={
          <ModalHeader>
            <IconClose color="black" size={ICON_SIZE} onPress={onClose} />
            <CurrentDate>
              {moment(date).format('ddd L').toUpperCase()}
            </CurrentDate>
            <EmptyView />
          </ModalHeader>
        }
      >
        {/* <KeyboardAwareScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingBottom: bottom, paddingTop: top }}
        extraScrollHeight={200}> */}
        <Form<FormValues>
          onSubmit={onSaveInternal}
          initialValues={initialValues}
        >
          {({ handleSubmit, valid, form }) => (
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
              <>
                <Container>
                  <H3BoldWhite>
                    {status === 0 ? t('preferWork') : t('preferNotWork')}
                  </H3BoldWhite>
                  <LabelsContainer>
                    {relevantLabels?.length > 1 &&
                      relevantLabels.map((label) => (
                        <Button
                          type="primary"
                          style={
                            selectedLabel === label.id
                              ? {
                                  backgroundColor: '#e5ebf2',
                                  borderColor: '#000000',
                                  borderWidth: 2,
                                  marginBottom: 20,
                                }
                              : {
                                  backgroundColor: '#e5ebf2',
                                  borderColor: '#798a9e',
                                  marginBottom: 20,
                                }
                          }
                          textStyle={
                            selectedLabel === label?.id
                              ? { color: '#798a9e' }
                              : { color: '#798a9e' }
                          }
                          disabled={!valid}
                          onPress={() => {
                            setSelectedLabel(label.id);
                            form.change(
                              'startTime',
                              moment(label?.startTime, 'HH:mm:ss').toDate()
                            );
                            form.change(
                              'endTime',
                              moment(label?.endTime, 'HH:mm:ss').toDate()
                            );
                          }}
                          title={`${label?.title} - ${rangeFormat(
                            moment(label?.startTime, 'HH:mm:ss').toDate(),
                            moment(label?.endTime, 'HH:mm:ss').toDate()
                          )}`}
                        />
                      ))}
                  </LabelsContainer>
                  <Timepickers />
                  {status === 1 && (
                    <CommentContainer>
                      <Label>{t('comment')}</Label>
                      <InputField
                        fieldProps={{
                          name: 'comment',
                        }}
                        props={{
                          multiline: true,
                          numberOfLines: 3,
                        }}
                        component={TextInput}
                      />
                    </CommentContainer>
                  )}
                </Container>

                <Warning />

                <ButtonContainer>
                  <Button
                    type="primary"
                    fullWidth
                    disabled={!valid}
                    onPress={handleSubmit}
                    title={t('save')}
                  />
                </ButtonContainer>
              </>
            </TouchableWithoutFeedback>
          )}
        </Form>
        {/* </KeyboardAwareScrollView> */}
      </Modalize>
    </Portal>
  );
};
