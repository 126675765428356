import { selectedAccount } from '@violetta/ubeya/shared';
import { AccountImage, BaseView, FlexRow, H5Medium } from '@violetta/ubeya/ui';
import { AppContext } from '../../../../../main/AppContext';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/native';

const Container = styled(FlexRow)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Title = styled(H5Medium)`
  font-weight: normal;
  font-size: 16px;
  margin-left: 15px;
  flex-wrap: wrap;
`;

const IconWrapper = styled(BaseView)`
  width: 30px;
  height: 30px;
`;

interface Props {}

export const SpecificAccountProfileHeaderTitle: FC<Props> = () => {
  const { profile } = useContext(AppContext);

  const account = profile?.selectedAccount;
  if (!profile?.selectedAccount) {
    return null;
  }
  const { name, logo } = account;

  return (
    <Container>
      <IconWrapper>
        <AccountImage name={name} image={logo} size={30} showNameAsEmptyLogo />
      </IconWrapper>
      <Title>{name}</Title>
    </Container>
  );
};
