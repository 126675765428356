import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigation, useTheme } from '@react-navigation/native';
import { api, authBaseURL } from '@violetta/ubeya/api';
import { icons } from '@violetta/ubeya/assets';
import { ICountry } from '@violetta/ubeya/entities';
import { useCountries, useTranslation } from '@violetta/ubeya/i18n';
import { useRecaptcha } from '@violetta/ubeya/recaptcha';
import messaging from '@react-native-firebase/messaging';
import notifee from '@violetta/ubeya/notifee';
import {
  BaseView,
  Button,
  FlexColumn,
  FlexColumnDirection,
  FlexRow,
  FlexRowEvenly,
  H4Light,
  H4Medium,
  H5Medium,
  RipplePressable,
  SvgIcon,
  TextInput,
} from '@violetta/ubeya/ui';
import {
  formatPhone,
  getCountryCodeFromRegionCode,
} from '@violetta/ubeya/utils';
import { Alert } from '@violetta/ubeya/alert';
import {
  I18nManager,
  PixelRatio,
  Platform,
  ScrollView,
  useWindowDimensions,
} from 'react-native';
import CarrierInfo from 'react-native-carrier-info';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
// import { LoginWithFirebaseGoogle } from '..';
import { finishedOnboardingAtom, showSplashAtom } from '../../../state';
import { CountryPickerModal } from '../modals';

const SafeView = styled.SafeAreaView`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const Container = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  padding: 30px 35px 0;
  flex: 1;
`;

const SITE_KEY = '6LelDjMaAAAAAORDlqHORcXkmPV62Gqms9087gh5';

const SubTitle = styled(H4Medium)`
  margin: 15px 0 5px;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
`;
const SignupIcon = styled(BaseView)`
  margin: 20px 0;
`;

const CountryCode = styled(H4Medium)``;

const HeaderContainer = styled(FlexColumnDirection)`
  justify-content: center;
  align-items: center;
`;

const ContentContainer = styled(FlexColumnDirection)`
  width: 100%;
  flex: 1;
`;

const CountryCodeContainer = styled(BaseView)`
  margin-right: 15px;
  border: solid 1px ${({ theme }) => theme.colors.gray3};
  border-radius: 4px;
  padding: 16px 8px;
`;
const SpaceUp = styled(BaseView)`
  margin-top: 15px;
`;

const OrContainer = styled(FlexRowEvenly)`
  align-items: center;
  justify-content: center;
`;
const OrDivider = styled(BaseView)`
  margin-top: 3px;
  height: 1px;
  flex: 1;
  background-color: #d9d9d9;
`;
const Or = styled(H4Light)`
  font-weight: normal;
  text-align: center;
  margin: 50px 10px;
`;
const PhoneContainer = styled(FlexColumn)`
  flex: 1;
`;

const ButtonContainer = styled(FlexColumn)`
  margin-top: 30px;
  flex: 1;
`;

const PhoneRowContainer = styled(FlexRow)`
  margin-top: 10px;
  align-items: center;
`;

const PhoneInput = styled(TextInput)<{ isInvalid: boolean }>`
  padding: 15px 10px;
  height: 55px;
  border-color: ${({ theme, isInvalid }) =>
    isInvalid ? theme.colors.red1 : '#d9d9d9'};
`;

const Error = styled(H5Medium)<{ $pending: boolean }>`
  font-size: 15px;
  text-align: center;
  font-weight: normal;
  color: ${({ theme, $pending }) =>
    $pending ? theme.colors.gray2 : theme.colors.error};
  margin-top: 20px;
`;

const Logo = styled(BaseView)``;

const phoneValidations = {
  isRequired: (x: string) => !!x,
  isNumber: (x: string) => /^[0-9-]*$/g.exec(x),
};

const phoneInputAlertKeys = {
  empty: 'inputEmpty',
  nonNumeric: 'inputNonNumeric',
  server: 'inputNotVerified',
  invalidInput: 'invalidInput',
  networkError: 'noInternetFriendlyMsg',
  maximumNetworkAttempts: 'maximumNetworkAttempts',
  recaptchaFailure: 'recaptchaFailure',
  error: 'loginGenericError',
};

type PhoneInputAlert = keyof typeof phoneInputAlertKeys;

export const LoginScreen: FC = () => {
  const [recaptchAttempt, setRecaptchAttempt] = useState<number>(1);
  const [showSplash] = useRecoilState(showSplashAtom);
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const { colors } = useTheme();
  const [phone, setPhone] = useState('');
  const [isRetry, setIsRetry] = useState('');
  const [isVoice, setIsVoice] = useState(false);
  const [recaptchaRetrying, setRecaptchaRetrying] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<{
    pending: boolean;
    message: string;
  } | null>(null);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const stateRecaptchaRef = useRef<string>();
  const [isGoogleLoginProcessOn, setIsGoogleLoginProcessOn] = useState(false);

  stateRecaptchaRef.current = recaptchaToken;
  const [finishedOnboarding, setFinishedOnboarding] = useRecoilState(
    finishedOnboardingAtom
  );

  const onReceiveToken = useCallback(({ data: token }) => {
    setRecaptchaToken(token);
  }, []);

  const { Recaptcha } = useRecaptcha({
    uniq: recaptchAttempt,
    action: 'submit',
    captchaDomain: authBaseURL,
    onReceiveToken,
    siteKey: SITE_KEY,
  });
  const [showPicker, setShowPicker] = useState(false);
  const { mappedCountreis } = useCountries();

  const windowHeight = useWindowDimensions().height;
  const [selectedCountry, setSelectedCountry] = useState({
    id: 239,
    name: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'GB',
  } as ICountry);

  const getCountryCode = useCallback(async () => {
    const countryCode = await (async () => {
      if (Platform.OS === 'web') {
        return 'GB';
      }
      return ((await CarrierInfo.isoCountryCode()) as string) || 'GB';
    })();

    const item = mappedCountreis[countryCode.toUpperCase()];
    if (item) {
      setSelectedCountry(item);
    }
  }, [mappedCountreis]);

  useEffect(() => {
    getCountryCode();
  }, [getCountryCode]);

  useEffect(() => {
    if (finishedOnboarding) {
      setFinishedOnboarding(false);
    }
  }, [finishedOnboarding, setFinishedOnboarding]);

  const createAlert = useCallback(
    (bodyKey: PhoneInputAlert) =>
      Alert.alert(
        '',
        t(phoneInputAlertKeys[bodyKey]),
        [{ text: t('ok'), type: 'confirm', onPress: () => {} }],
        {
          cancelable: false,
        }
      ),
    [t]
  );

  const { mutateAsync: getCode, isPending } = useMutation({
    mutationFn: ({
      phone,
      recaptchaToken,
      isVoice,
    }: {
      phone: string;
      recaptchaToken: string;
      isVoice?: boolean;
    }) => api.signup({ phone, recaptchaToken, isVoice }),
    onSuccess: (result: any, { phone }) => {
      setRecaptchaRetrying(false);
      setErrorMessage(null);
      setIsRetry(true);
      return navigate('activationCode', {
        phone,
      });
    },
    onSettled: () => {},
    throwOnError: false,
  });

  const togglePicker = useCallback(() => {
    setShowPicker((picker) => !picker);
  }, []);

  const refetchRecaptchaToken = useCallback(() => {
    setRecaptchaRetrying(true);
    if (recaptchAttempt >= 3) {
      setErrorMessage({
        pending: false,
        message: 'Could not verify, please re-open the app',
      });
      setRecaptchaRetrying(false);
      return;
    }
    setErrorMessage({
      pending: true,
      message: 'Verifying identity, please hold...',
    });
    setRecaptchAttempt((prev) => prev + 1);
  }, [recaptchAttempt]);

  const sendToServer = useCallback(async () => {
    if (!phoneValidations.isRequired(phone)) return createAlert('empty');
    if (!phoneValidations.isNumber(phone)) return createAlert('nonNumeric');
    try {
      const formattedPhone = formatPhone(
        phone,
        String(getCountryCodeFromRegionCode(selectedCountry.code))
      );
      // must do this to watch exception for some reason
      return await getCode({
        phone: formattedPhone,
        recaptchaToken: stateRecaptchaRef.current!,
        isVoice,
      });
    } catch (e) {
      if (
        e?.response?.data?.errors?.headers?.[0]?.message?.includes(
          'x-recaptcha-token'
        ) ||
        (e?.response?.data?.recaptchaVerifyData &&
          !e?.response?.data?.recaptchaVerifyData?.success)
      ) {
        refetchRecaptchaToken();
        return;
      }
      setRecaptchaRetrying(false);
      setErrorMessage(null);
      setRecaptchAttempt(1);
      if (e && e?.toJSON?.()?.message === 'Network Error') {
        return createAlert('networkError');
      }
      if (e && e?.response?.data?.error?.includes?.('invalid_phone_mumber')) {
        return createAlert('invalidInput');
      }
      if (e && e?.response?.status === 429) {
        return createAlert('maximumNetworkAttempts');
      }
      if (
        e?.response?.data?.errors?.headers?.[0]?.message?.includes(
          'x-recaptcha-token'
        ) ||
        (e?.response?.data?.recaptchaVerifyData &&
          !e?.response?.data?.recaptchaVerifyData?.success)
      ) {
        return createAlert('recaptchaFailure');
      }

      return createAlert('error');
    }
  }, [
    createAlert,
    getCode,
    phone,
    refetchRecaptchaToken,
    selectedCountry.code,
    isVoice,
  ]);

  const onSubmit = useCallback(
    async (isSendVoice = false) => {
      setIsVoice(isSendVoice);
      if (!stateRecaptchaRef.current) {
        refetchRecaptchaToken();
        return;
      }
      sendToServer();
    },
    [sendToServer, refetchRecaptchaToken]
  );

  useEffect(() => {
    if (stateRecaptchaRef.current && recaptchAttempt > 1) {
      // we tried to refetch
      sendToServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaToken]);

  const onChange = useCallback((text: string) => {
    setPhone(text);
  }, []);

  const onSelect = useCallback((country: ICountry) => {
    setShowPicker(false);

    setSelectedCountry(country);
  }, []);

  const invalidPhoneInput = useMemo(
    () =>
      phoneValidations.isRequired(phone) && !phoneValidations.isNumber(phone),
    [phone]
  );

  const showSVG = useMemo(
    () => PixelRatio.getFontScale() <= 1 && windowHeight > 800,
    [windowHeight]
  );

  const showSSO = useMemo(() => {
    if (Platform.OS !== 'web') {
      return false;
    }
    const queryParam = new URLSearchParams(window.location.search);
    const { host } = window?.location || {};

    return (
      queryParam?.get('sso') === '1' || host.includes('crew-sso.ubeya.com')
    );
  }, []);

  if (showSplash) {
    return null;
  }

  return (
    <SafeView>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        keyboardShouldPersistTaps="always"
      >
        <Recaptcha />
        <Container>
          <HeaderContainer>
            <Logo>
              <SvgIcon
                xml={icons.whiteLabelLogos.logo}
                width={300}
                height={50}
              />
            </Logo>
            {!showSSO && <SubTitle>{t('enterYourPhoneNumber')}</SubTitle>}
            {showSVG && (
              <SignupIcon>
                <SvgIcon
                  xml={icons.uiIcons.signup}
                  width={65}
                  height={65}
                  color="#000000"
                  defaultViewBox={false}
                />
              </SignupIcon>
            )}
          </HeaderContainer>
          <ContentContainer>
            {!showSSO && (
              <>
                <PhoneRowContainer rtl={I18nManager.isRTL}>
                  <CountryCodeContainer
                    as={RipplePressable}
                    onPress={togglePicker}
                  >
                    <CountryCode>
                      +
                      {String(
                        getCountryCodeFromRegionCode(selectedCountry.code)
                      )}
                    </CountryCode>
                  </CountryCodeContainer>
                  <PhoneContainer>
                    <PhoneInput
                      autoFocus
                      placeholder="Phone"
                      editable
                      autoCapitalize="none"
                      keyboardType="phone-pad"
                      value={phone}
                      onChangeText={onChange}
                      onSubmitEditing={() => onSubmit(false)}
                      isInvalid={invalidPhoneInput}
                    />
                  </PhoneContainer>
                </PhoneRowContainer>

                <ButtonContainer>
                  <Button
                    title={isRetry ? t('sendCodeViaSMS') : t('next')}
                    onPress={() => onSubmit(false)}
                    type="primary"
                    isLoading={isPending || recaptchaRetrying}
                    disabled={invalidPhoneInput || isGoogleLoginProcessOn}
                  />
                  {isRetry && (
                    <Button
                      title={t('sendCodeViaVoice')}
                      onPress={() => onSubmit(true)}
                      type="primaryReversed"
                      style={{ marginTop: 10 }}
                      isLoading={isPending || recaptchaRetrying}
                      loaderColor={colors.primary}
                      disabled={invalidPhoneInput || isGoogleLoginProcessOn}
                    />
                  )}
                </ButtonContainer>
              </>
            )}
            {showSSO && (
              <>
                <SpaceUp />
                {/* <OrContainer>
                    <OrDivider />
                    <Or>or</Or>
                    <OrDivider />
                  </OrContainer> */}
                {/* <LoginWithFirebaseGoogle
                  setIsGoogleLoginProcessOn={setIsGoogleLoginProcessOn}
                  disabled={isLoading || recaptchaRetrying}
                /> */}
              </>
            )}

            {errorMessage && (
              <Error $pending={errorMessage.pending}>
                {errorMessage.message}
              </Error>
            )}

            {showPicker && (
              <CountryPickerModal onCancel={togglePicker} onSelect={onSelect} />
            )}
          </ContentContainer>
        </Container>
      </ScrollView>
    </SafeView>
  );
};
