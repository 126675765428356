import { InternalAxiosRequestConfig } from 'axios';

export const appInfoInterceptorCreator =
  (appName: string) => (config: InternalAxiosRequestConfig) => {
    if (!config || !config.headers) {
      return config;
    }
    // Modify the request headers
    config.headers['X-App-Name'] = appName;
    return config;
  };
