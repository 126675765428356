import React, { FC, useCallback, useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import { IAccount } from '@violetta/ubeya/entities';
import { BaseView } from '@violetta/ubeya/ui';
import { ConfigContext } from '../../../../contexts';
import { TimeAvailabilityScreenNames } from '../../../time-availability/navigation';
import { AvailabilityCard } from '../AvailabilitiesCard/AvailabilityCard';

interface Props {
  accounts: IAccount[];
}

export const TimeAvailabilityCard: FC<Props> = React.memo(({ accounts }) => {
  const { navigate } = useNavigation();
  const { rtl } = useContext(ConfigContext);

  const navigateTimeAvailability = useCallback(
    (accountId: number) => {
      navigate(TimeAvailabilityScreenNames.MAIN, {
        screen: TimeAvailabilityScreenNames.MAIN,
        params: { accountId },
      });
    },
    [navigate]
  );

  if (accounts.length === 0) {
    return null;
  }

  return (
    <BaseView>
      {accounts.map(({ id, name }) => (
        <AvailabilityCard
          key={id}
          id={id}
          rtl={rtl}
          name={accounts.length > 1 ? name : undefined}
          onPress={navigateTimeAvailability}
          weeklyPreferences
        />
      ))}
    </BaseView>
  );
});
