import React, { FC } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { IncomeHeader } from '../../home/components/IncomeHeader';
import { IncomeScreen } from '../screens/IncomeScreen';
import { IncomeScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const IncomeNavigation: FC = () => (
  <Navigator initialRouteName={IncomeScreenNames.INCOME}>
    <Screen
      name={IncomeScreenNames.INCOME}
      component={IncomeScreen}
      options={{
        headerTitle: 'Your Earnings',
        header: (props) => <IncomeHeader {...props} />,
      }}
    />
  </Navigator>
);
