import { useTranslation } from '@violetta/ubeya/i18n';
import React, { FC } from 'react';
import styled from 'styled-components/native';
import { FlexColumnDirection, FlexRowBetween } from '../Layout';
import { H3Regular, H4Regular, H5Regular } from '../Typography';

const Container = styled(FlexRowBetween)<{ isHidden?: boolean }>`
  margin: 5px 0;
  width: 100%;
  display: ${({ isHidden }) =>
    isHidden && isHidden !== undefined ? 'none' : 'flex'};
`;

const Title = styled(H5Regular)`
  margin: 10px 0;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray1};
`;

const Description = styled(H5Regular)`
  margin: 10px 0;
  color: ${({ theme }) => theme.colors.gray2};
`;

interface Props {
  title?: string;
  description?: string;
  isHidden?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
}

export const FormGroupHorizontal: FC<Props> = ({
  title,
  description,
  children,
  isHidden,
  isReadOnly,
  isRequired,
}) => {
  const { t } = useTranslation();
  return (
    <Container isHidden={isHidden}>
      {!!title && !isReadOnly && (
        <Title>
          {t(title)}
          {isRequired && ' *'}
        </Title>
      )}
      {!!description && <Description>{description}</Description>}
      {children}
    </Container>
  );
};
