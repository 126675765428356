import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import { Defined } from '@violetta/ubeya/utils';
import { Pressable as PressableBase, Platform } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import styled from 'styled-components/native';
import { ISelectOption } from './ISelectOption';
import { SelectListItem } from './SelectListItem';
import { SelectProps } from './types';
import { BaseView, FlexParent } from '../Layout/Layout';
import { Button } from '../Buttons/Button';
import { SearchBox } from '../SearchBox/SearchBox';
import { H3Bold } from '../Typography';

const Container = styled(BaseView)<{ isMulti: boolean }>`
  margin-bottom: ${({ isMulti }) => (isMulti ? 30 : 50)}px;
`;

const ButtonContainer = styled(BaseView)`
  padding: 20px 20px 10px 20px;
`;

const Separator = styled(BaseView)`
  border-bottom-width: 1px;
  border-bottom-color: #eaeaea;
  margin: 0 16px;
`;

const ModalHeader = styled(FlexParent)`
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  margin: 12px;
`;

const Title = styled(H3Bold)`
  margin-left: 10px;
`;

const Pressable = styled(PressableBase)`
  display: flex;
  flex-direction: row;
`;

const SearchBoxContainer = styled(BaseView)`
  margin: 24px;
`;

interface Props<Option> {
  selectedOptions: Option[];
  headerTitle: string | undefined;
  rtl: boolean;
  modalizeRef: React.MutableRefObject<Modalize>;
  options: SelectProps<Option, any>['options'];
  searchBy?: string | null;
  isMulti?: SelectProps<Option, any>['isMulti'];
  onSelected?: (value: number[]) => void;
  renderOption: Defined<SelectProps<Option, any>['renderOption']>;
}

export const SelectOptionsList = <Option,>({
  onSelected = () => {},
  options,
  selectedOptions = [],
  searchBy = null,
  isMulti = false,
  headerTitle,
  rtl,
  renderOption: RenderOptionComponent,
  modalizeRef,
}: Props<Option>) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const [chosenValues, setChosenValues] = useState<Set<number>>(
    () => new Set(selectedOptions.map((op) => op.id))
  );

  const keyExtractor = useCallback(
    (option: ISelectOption) => option.id.toString(),
    []
  );

  const filteredOptions = useMemo(
    () =>
      searchBy
        ? options.filter((op) => op?.[searchBy]?.includes(searchTerm))
        : options,
    [options, searchBy, searchTerm]
  );

  const onPress = useCallback(
    (option) => {
      if (!isMulti) {
        onSelected([option.id]);
        setChosenValues(new Set([option.id]));
      } else {
        setChosenValues((previousChosenValues) => {
          const newSet = new Set(previousChosenValues);
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          previousChosenValues.has(option.id)
            ? newSet.delete(option.id)
            : newSet.add(option.id);
          return newSet;
        });
      }
    },
    [isMulti, onSelected]
  );

  const isDirty = useMemo(
    () =>
      !(
        selectedOptions.every((value) => chosenValues.has(value.id)) &&
        [...chosenValues].every((chosenValue) =>
          selectedOptions.includes(chosenValue)
        )
      ),
    [selectedOptions, chosenValues]
  );

  const close = useCallback(() => {
    modalizeRef.current?.close();
  }, [modalizeRef]);

  return (
    <Portal>
      <Modalize
        ref={modalizeRef}
        adjustToContentHeight={!(options?.length > 10 && searchBy)}
        handlePosition="inside"
        onClose={() => setSearchTerm('')}
        disableScrollIfPossible={Platform.OS !== 'android'}
        onBackButtonPress={close}
        onOverlayPress={close}
        modalTopOffset={options?.length > 10 && searchBy ? 10 : undefined}
        flatListProps={{
          contentContainerStyle: { flexGrow: 1 },
          data: filteredOptions,
          ItemSeparatorComponent: () => <Separator />,
          renderItem: ({ item: option }) => (
            <SelectListItem<Option>
              isMulti={isMulti}
              key={option.id}
              option={option}
              onPress={onPress}
              isSelected={chosenValues.has(option.id)}
              renderOption={RenderOptionComponent}
            />
          ),
          keyExtractor,
        }}
        HeaderComponent={
          <>
            {!!headerTitle && (
              <ModalHeader>
                <Pressable onPress={close}>
                  <Title>{headerTitle || ''}</Title>
                </Pressable>
              </ModalHeader>
            )}
            {options?.length > 10 && searchBy && (
              <SearchBoxContainer>
                <SearchBox
                  onChange={(text) => setSearchTerm(text)}
                  autoCorrect={false}
                />
              </SearchBoxContainer>
            )}
          </>
        }
        FooterComponent={
          <Container isMulti={isMulti}>
            {isMulti ? (
              <ButtonContainer>
                <Button
                  disabled={!isDirty}
                  title={t('save')}
                  onPress={() => onSelected([...chosenValues])}
                  type="primary"
                  paddingVertical={12}
                  paddingHorizontal={25}
                />
              </ButtonContainer>
            ) : null}
          </Container>
        }
      />
    </Portal>
  );
};
