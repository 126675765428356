import { useMemo } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { createAdminStoreKey } from '@violetta/ubeya/utils';
import { createCachedSelector } from 're-reselect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import * as sharedAtoms from '../../../shared/src/atoms';

const selector = createCachedSelector(
  (data) => data.data,
  (data, selectedBranch) => selectedBranch,
  (data, selectedBranch) => {
    const locations = (data?.data || []).filter(
      ({ branches, isSubLocation }) => {
        if (isSubLocation) {
          return true;
        }
        const currBranch = branches.find(({ id }) => id === selectedBranch);

        if (!currBranch || currBranch.isDeleted) {
          return false;
        }
        return true;
      }
    );
    const locationsOptions = (locations || []).map(({ id, name }) => ({
      value: id,
      label: name,
      id,
    }));
    const mappedLocations = (data?.data || []).reduce(
      (prev, { id, ...location }) => ({ ...prev, [id]: location }),
      {}
    );

    return { locations, locationsOptions, mappedLocations };
  }
)({
  keySelector: (data, selectedBranch, storeKey) =>
    `${storeKey.join('#')}#${selectedBranch}`,
});

export const useLocations = ({ branchId } = {}) => {
  const selectedAccount = useRecoilValue(sharedAtoms.selectedAccount);
  const selectedBranch = useRecoilValue(sharedAtoms.selectedBranch);
  const selectedAccountId = selectedAccount?.id;
  const tempBranchId = branchId || selectedBranch?.[0];
  const queryClient = useQueryClient();

  const storeKey = useMemo(
    () => createAdminStoreKey(['locations', selectedAccountId]),
    [selectedAccountId]
  );
  const { isPending, data, refetch } = useQuery({
    queryKey: storeKey,
    queryFn: () => adminApi.getLocations({ accountId: selectedAccountId }),
    select: (data) => selector(data, tempBranchId, storeKey),
    enabled: !!selectedAccountId,
  });

  const { mutateAsync: addNewLocation, isPending: isAddingNewLocation } =
    useMutation({
      mutationFn: ({
        payload,
      }: {
        payload: {
          address: string;
          lat: number | null;
          lng: number | null;
          name: string;
        };
      }) =>
        adminApi.addNewLocation({
          accountId: selectedAccountId,
          branchId: tempBranchId,
          payload,
        }),
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: storeKey, exact: true });
      },
    });

  const {
    locations = [],
    locationsOptions = [],
    mappedLocations = {},
  } = data || {};

  return {
    isLoading: isPending,
    locations,
    refetch,
    locationsOptions,
    mappedLocations,
    addNewLocation,
    isAddingNewLocation,
  };
};
