import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { IUniform } from '../entities';

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const uniforms = data || [];

    const mappedUniforms = mappedArray(uniforms) as { [key: number]: IUniform };

    return { uniforms, mappedUniforms };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useUniforms = () => {
  const { data: userData } = useUser();
  const storeKey = useMemo(() => ['uniforms'], []);
  const { isPending, data } = useQuery<{ data: IUniform[] }>({
    queryKey: storeKey,
    queryFn: () => api.getUniforms(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { uniforms, mappedUniforms } = useMemo(() => {
    const { uniforms = [], mappedUniforms = {} } = data || {};
    return {
      uniforms,
      mappedUniforms,
    };
  }, [data]);

  return {
    isLoading: isPending,
    uniforms,
    mappedUniforms,
  };
};
