import React, { FC, forwardRef, Ref } from 'react';
import { Button } from '@violetta/ubeya/ui';
import { InferProps } from '@violetta/ubeya/utils';
import {
  ActivityIndicator,
  Pressable,
  TextInput as OriginalTextInput,
} from 'react-native';
import styled from 'styled-components/native';
import { BaseView, FlexRow } from '../Layout';
import { H5Light } from '../Typography';

const BaseTextInput = styled.TextInput.attrs({ maxFontSizeMultiplier: 1 })`
  font-family: ${({ theme }) => theme.fonts.regular.family};
  font-weight: ${({ theme }) => theme.fonts.regular.weight};
  font-size: 14px;
`;

const Container = styled(FlexRow)`
  width: 100%;
  align-items: center;
`;

const ButtonText = styled(H5Light)`
  color: ${({ theme }) => theme.colors.primary};
`;

const Submit = styled(BaseView)``;

const AdaptedTextInput = styled(BaseTextInput)<{
  error?: boolean;
  numberOfLines?: number;
}>`
  display: flex;
  border-bottom-width: 1px;
  height: ${({ numberOfLines }) => `${(numberOfLines || 1) * 44}px`};
  border-color: ${({ error, theme }) =>
    error ? theme.colors.red1 : theme.colors.inputBorder};
  background-color: ${({ theme }) => theme.colors.white};
  align-items: center;
  justify-content: center;
  padding: 14px 10px;
  flex: 1;
`;

export const TextSubmitInput: FC<
  InferProps<OriginalTextInput> & {
    ref?: Ref<any>;
    error?: boolean;
    onSubmit?: () => void;
    buttonText?: string;
    isLoading?: boolean;
    initialValue?: string;
  }
> = forwardRef<any>(
  (
    { children, buttonText, onSubmit, isLoading, initialValue, ...props },
    ref
  ) => (
    <Container>
      <AdaptedTextInput {...props} ref={ref}>
        {children}
      </AdaptedTextInput>
      {(initialValue === undefined || initialValue !== props.value) && (
        <Submit as={Pressable} onPress={onSubmit}>
          {isLoading ? (
            <ActivityIndicator size="small" color="#000000" />
          ) : (
            <Button
              type="primary"
              title={buttonText}
              onPress={onSubmit}
              paddingVertical={10}
              paddingHorizontal={20}
              fontSize={14}
              disabled={isLoading}
              radius={20}
              isLoading={isLoading}
            />
            // <SvgIcon xml={icons.actionIcons.send} width={15} height={15} color="#6d6d78" />
          )}
        </Submit>
      )}
    </Container>
  )
);
