import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { IBreakType } from '@violetta/ubeya/entities';
import { useQuery } from '@tanstack/react-query';
import { mappedArray } from '@violetta/ubeya/utils';

export const useBreakTypes = () => {
  const storeKey = ['breaktype'];

  const { isPending, data } = useQuery<Array<IBreakType>>({
    queryKey: storeKey,
    queryFn: () => api.getBreaktypes(),
  });

  const mappedBreakTypesByBranch = useMemo(
    () =>
      (data?.data || []).reduce((acc, curr) => {
        const payloads = acc[curr.branchId] || [];

        payloads.push(curr);

        acc[curr.branchId] = payloads;

        return acc;
      }, {}),
    [data?.data]
  );

  const mappedBreakTypes = useMemo(
    () => mappedArray(data?.data || []),
    [data?.data]
  );

  return {
    isLoading: isPending,
    data: data?.data || [],
    mappedBreakTypesByBranch,
    mappedBreakTypes,
  };
};
