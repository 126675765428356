import React, { FC } from 'react';
import styled from 'styled-components/native';
import { FlexRow } from '../Layout';
import { TimelineNob } from './TimelineNob';

const Container = styled(FlexRow)``;

interface Props {
  item: FC<{ nob: FC<any>; data: any }>;
  data: any;
  nob: FC<any>;
  last: boolean;
  first: boolean;
}

export const TimelineItem: FC<Props> = ({ item: Item, nob, data, first, last }) => (
  <Container>
    <Item data={data} nob={() => <TimelineNob item={nob} top={!first} bottom={!last} />} />
  </Container>
);
