import React, { FC, useContext } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { FlexRow, H3Medium } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { AppContext } from '../../../main/AppContext';

const Container = styled(FlexRow)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Title = styled(H3Medium)`
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
`;

export const ChooseAvailabilityHeaderTitle: FC<StackHeaderProps> = () => {
  const {
    generalAvailability: { selectedDay },
  } = useContext(AppContext);

  return (
    <Container>
      <Title>{selectedDay || 'Choose Availability'}</Title>
    </Container>
  );
};
