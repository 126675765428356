import React, { FC, useCallback, useEffect } from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  FlexColumnDirection,
  SelectField,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { Form } from 'react-final-form';
import { DeviceEventEmitter } from 'react-native';
import styled from 'styled-components/native';
import { Timepickers } from '../../time-availability/components/Timepickers';
import { GeneralAvailabilitySelect } from '../components/GeneralAvailabilitySelect';
import { GeneralAvailabilityScreenNames } from '../navigation';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  justify-content: space-between;
  z-index: 0;
`;

const Footer = styled(BaseView)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
`;

interface Props {
  title: string;
  date: Date;
}

type FormValues = {
  start: Date | null;
  end: Date | null;
  type: 'Available' | 'Not Available' | 'Partial';
  day: number;
};

type ParamList = {
  params: {
    params: {
      title: string;
      day: number;
    };
  };
};

export const ChooseAvailabilityScreen: FC<Props> = () => {
  const { params: routeParams } = useRoute<RouteProp<ParamList, 'params'>>();
  const { t } = useTranslation();
  const { navigate } = useNavigation();

  const onSaveInternal = useCallback(
    (values: FormValues) => {
      navigate(GeneralAvailabilityScreenNames.GENERAL_AVAILABILITY, {
        params: {
          ...values,
          start: values.start
            ? moment(values.start).format('YYYY-MM-DD HH:mm')
            : null,
          end: values.end
            ? moment(values.end).format('YYYY-MM-DD HH:mm')
            : null,
        },
      });
    },
    [navigate]
  );

  return (
    <Form<FormValues>
      onSubmit={onSaveInternal}
      initialValues={{
        start: undefined,
        end: undefined,
        type: 'Available',
        day: routeParams?.params?.day,
      }}
    >
      {({ handleSubmit, valid, values }) => (
        <Container>
          <BaseView>
            <SelectField
              selectComponent={GeneralAvailabilitySelect}
              fieldProps={{
                name: 'type',
              }}
              props={{
                options: [
                  { id: 1, slug: 'availableAllDay', type: 'Available' },
                  { id: 2, slug: 'notAvailableAllDay', type: 'Not Available' },
                  { id: 3, slug: 'availableOnSpecificTimes', type: 'Partial' },
                ],
                isMulti: false,
              }}
            />
            {values.type === 'Partial' && <Timepickers />}
          </BaseView>
          <Footer>
            <Button
              title={t('save')}
              onPress={() => {
                handleSubmit();
                // goBack();
              }}
              type="primary"
              radius={40}
              disabled={!valid}
              paddingVertical={15}
              paddingHorizontal={100}
            />
          </Footer>
        </Container>
      )}
    </Form>
  );
};
