import React, { FC, useState } from 'react';
import { Pressable, PressableProps } from 'react-native';
import styled from 'styled-components/native';
import { BaseView } from '../Layout';

export const CircledIcon = styled(BaseView)`
  height: 44px;
  width: 44px;
  justify-content: center;
  border-radius: 50px;
  align-items: center;
  shadow-color: rgba(0, 0, 0, 0.12);
  shadow-opacity: 1;
  shadow-offset: 0 2px;
  shadow-radius: 5px;
  elevation: 5;
  background-color: ${({ theme }) => theme.colors.surface};
`;

interface Props {
  pressed?: boolean;
  pressedCircleIcon: FC<any>;
  notPressedCircleIcon: FC<any>;
}

export const PressableCircledIcon: FC<PressableProps & Props> = (props: PressableProps & Props) => {
  const { pressed = false, pressedCircleIcon: PressedCircleIcon, notPressedCircleIcon: NotPressedCircleIcon } = props;
  const [iconPressed, setIconPressed] = useState(pressed);

  return <CircledIcon as={Pressable} {...props} />;
};
