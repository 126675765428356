import React, { FC, useContext, useMemo } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { FlexRow, H3Medium } from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';
import { AppContext } from '../../main/AppContext';

const Container = styled(FlexRow)`
  flex: 1;
  justify-content: center;
`;
const Title = styled(H3Medium)`
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: #252631;
`;

export const TimesheetHeaderTitle: FC<StackHeaderProps> = () => {
  const {
    timesheets: { selectedTimesheet },
  } = useContext(AppContext);

  const date = useMemo(
    () =>
      selectedTimesheet
        ? moment(
            selectedTimesheet?.shift?.date || selectedTimesheet?.date
          ).format('dddd MMM, DD')
        : '',
    [selectedTimesheet]
  );

  return (
    <Container>
      <Title>{date}</Title>
    </Container>
  );
};
