import React, { FC, useCallback } from 'react';
import { TouchableHighlight, GestureResponderEvent, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import styled from 'styled-components/native';

const Wrapper = styled(View)`
  padding: 2px 10px;
`;
interface Props {
  onPress?: (event: GestureResponderEvent) => void;
  size: number;
  color: string;
}

export const IconClose: FC<Props> = ({ onPress, size, color }) => {
  const onPressInternal = useCallback(
    (event: GestureResponderEvent) => {
      if (onPress) {
        onPress(event);
      }
    },
    [onPress],
  );

  return (
    <TouchableHighlight onPress={onPressInternal} underlayColor="#fff">
      <Wrapper>
        <Icon name="clear" color={color} size={size} />
      </Wrapper>
    </TouchableHighlight>
  );
};
