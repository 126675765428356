import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { useQuery } from '@tanstack/react-query';

export const useCompliances = () => {
  const { data: userData } = useUser();

  const storeKey = ['compliances'];

  const { isPending, data, refetch } = useQuery({
    queryKey: storeKey,
    queryFn: async () => {
      const data = await api.getCompliances();
      return data || [];
    },
    enabled: !!userData?.id,
    staleTime: 0,
  });

  return {
    isLoading: isPending,
    data: data?.data || [],
    refetch,
  };
};
