import React, { FC, useMemo } from 'react';
import { IBreakType, IEmployeeSlotBreakPlots } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  FlexColumnDirection,
  FlexRow,
  H3Medium,
  H5Medium,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { hoursToTotalDuration, totalDuration } from '@violetta/ubeya/utils';
import moment from 'moment';
import styled from 'styled-components/native';
import { useBreakTypes } from '../../timeclock';

const Container = styled(FlexColumnDirection)`
  width: 100%;
`;

const Title = styled(H5Medium)`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-left: 10px;
`;

const Hours = styled(H5Medium)`
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-left: 10px;
`;

const Header = styled(H3Medium)<{ $disabled: boolean }>`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const RowContainer = styled(FlexRow)`
  padding: 6px 0;
  align-items: center;
  width: 100%;
`;

const DetailsContainer = styled(FlexRow)`
  padding-right: 0px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

interface Props {
  employeeSlotBreakPlots: IEmployeeSlotBreakPlots[] | null;
}
interface RowProps {
  breakType: IBreakType;
  startTime: Date;
  endTime: Date;
}

const BreakPlotRow: FC<RowProps> = ({ breakType, startTime, endTime }) => {
  const { t } = useTranslation();
  const totalHours = useMemo(
    () =>
      hoursToTotalDuration(totalDuration(moment(startTime), moment(endTime))),

    [endTime, startTime]
  );

  return (
    <RowContainer>
      {/* <SvgIcon xml={icons.uiIcons.employeeSlotBreakPlots} width={25} height={25} color={theme.colors.surface} /> */}
      <DetailsContainer>
        <Title>{breakType?.title || t('noBreakType')}</Title>
        <Hours>{`${moment(startTime).format('LT')} - ${moment(endTime).format(
          'LT'
        )} (${totalHours}${t('h')})`}</Hours>
      </DetailsContainer>
    </RowContainer>
  );
};

export const BookingBreakPlots: FC<Props> = ({ employeeSlotBreakPlots }) => {
  const { t } = useTranslation();
  const { mappedBreakTypes } = useBreakTypes();

  if (!employeeSlotBreakPlots || employeeSlotBreakPlots.length === 0) {
    return null;
  }

  return (
    <Container>
      <Header>{t('employeeSlotBreakPlots')}</Header>
      {(employeeSlotBreakPlots || []).map(
        ({ id, breakTypeId, startTime, endTime }) => (
          <BreakPlotRow
            key={id}
            breakType={mappedBreakTypes?.[breakTypeId]}
            startTime={startTime}
            endTime={endTime}
          />
        )
      )}
    </Container>
  );
};
