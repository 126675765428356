import React, { FC } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { useTranslation } from '@violetta/ubeya/i18n';
import styled from 'styled-components/native';
import { H2Regular } from '../Typography';

const Title = styled(H2Regular)`
  color: ${({ theme }) => theme.colors.gray1};
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const HeaderTitle: FC<StackHeaderProps> = ({ route }) => {
  const { t } = useTranslation();

  return <Title>{t(route?.name)}</Title>;
};
