import React, { FC, useContext } from 'react';
import { useLocale } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Button,
  Card,
  FlexRow,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { SvgXml } from 'react-native-svg';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../contexts/ConfigContext';

const Container = styled(Card)`
  position: relative;
  background-color: transparent;
`;

const Content = styled(BaseView)<{ rtl: boolean }>`
  flex: 1;
  padding: 10px 0;
  border-radius: 6px;
  align-items: flex-end;
  flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
`;

const CustomImageBackground = styled(BaseView)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Wrapper = styled(FlexRow)<{ hasText: boolean }>`
  justify-content: flex-end;
  justify-content: ${({ hasText }) => (hasText ? 'space-between' : 'flex-end')};
  padding: 0 10px;
  width: 100%;
`;

const TextContainer = styled(BaseView)`
  width: 50%;
`;

const CustomText = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: rgba(37, 37, 37, 0.8);
`;

interface Props {
  image: string;
  rtlImage: string;
  onPress: () => void;
  text?: string;
  buttonText: string;
  height: number;
}

export const EmptyStateCard: FC<Props> = React.memo(
  ({ image, buttonText, onPress, rtlImage, text, height }) => {
    const { rtl } = useContext(ConfigContext);
    const { locale } = useLocale();
    return (
      <Container as={RipplePressable} onPress={onPress} style={{ height }}>
        <Content rtl={rtl}>
          <CustomImageBackground>
            <SvgXml
              xml={locale === 'he' ? rtlImage : image}
              width="100%"
              height="100%"
              preserveAspectRatio="xMinyMin meet"
            />
          </CustomImageBackground>
          <Wrapper hasText={!!text}>
            {!!text && (
              <TextContainer>
                <CustomText>{text}</CustomText>
              </TextContainer>
            )}
            <Button
              title={buttonText}
              type="primaryReversed"
              onPress={onPress}
              paddingHorizontal={40}
              paddingVertical={12}
              style={[
                {
                  backgroundColor: 'rgba(255, 255, 255, 0.99)',
                  borderWidth: 0,
                },
              ]}
            />
          </Wrapper>
        </Content>
      </Container>
    );
  }
);
