import React, { FC, useMemo } from 'react';
import { useLocale, useTranslation } from '@violetta/ubeya/i18n';
import { H6Regular } from '@violetta/ubeya/ui';
import moment from 'moment';
import { TextStyle } from 'react-native';
import styled from 'styled-components/native';

const DateText = styled(H6Regular)`
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
  font-weight: ${({ bold }) => (bold ? '500' : 'normal')};
  letter-spacing: 0;
  line-height: 21px;

  color: ${({ theme }) => theme.colors.textPrimary};
`;

const DayText = styled(DateText)`
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize}px` : '')};
  color: ${({ theme }) => theme.colors.primary};
`;

interface Props {
  date: Date;
  endDate?: Date;
  fontSize?: number;
  numberOfLines?: number;
  isMultiDate?: boolean;
  bold?: boolean;
  style?: TextStyle;
  withDays?: boolean;
  dateFormat?: string;
}

export const DateTextComponent: FC<Props> = ({
  isMultiDate = false,
  date,
  endDate,
  fontSize = 16,
  numberOfLines = undefined,
  bold,
  style,
  withDays = true,
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const dateFormat = withDays ? 'dddd, MMMM D' : 'MMMM D';

  const itemDay = moment(date).dayOfYear();
  const todaysDay = moment().dayOfYear();

  let formattedDate: string;
  formattedDate = moment(date).format(dateFormat);
  if (locale === 'he') {
    formattedDate = `יום ${moment(date).format('dddd, D MMMM')}`;
  }

  const showEndDate = endDate && isMultiDate;

  let formattedEndDate: string;
  formattedEndDate = showEndDate ? moment(endDate).format(dateFormat) : '';
  if (showEndDate && locale === 'he') {
    formattedEndDate = `יום ${moment(endDate).format('dddd, D MMMM')}`;
  }

  const formattedDates = useMemo(() => {
    const daysDifference = showEndDate
      ? moment(endDate).diff(moment(date), 'days') + 1
      : '';
    return `${formattedDate}${showEndDate ? ` - ${formattedEndDate}` : ''}${
      daysDifference ? ` (${daysDifference} ${t('daysLow')})` : ''
    }`;
  }, [date, endDate, formattedDate, formattedEndDate, showEndDate, t]);

  if (!date) {
    return null;
  }

  return (
    <DateText
      fontSize={fontSize}
      bold={bold}
      style={style}
      numberOfLines={numberOfLines}
    >
      <DayText fontSize={fontSize} bold={bold}>
        {itemDay === todaysDay + 1
          ? `${t('tomorrow')}, `
          : itemDay === todaysDay
          ? `${t('today')}, `
          : ''}
      </DayText>
      {formattedDates}
    </DateText>
  );
};
