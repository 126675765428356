import { BaseText, BaseView } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { useMemo } from 'react';
import { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { StatsColorsMap } from './constants';

type LiveViewTypes =
  | 'scheduled'
  | 'onTimeClockIn'
  | 'earlyClockIn'
  | 'lateClockIn'
  | 'onTimeClockOut'
  | 'earlyClockOut'
  | 'lateClockOut'
  | 'inShift'
  | 'late'
  | 'undertime'
  | 'overtime'
  | 'finished'
  | 'absent';

type DataParams = {
  liveViewStats: Record<LiveViewTypes, number>;
};

const EMPTY_COLOR = '#bfbfbf';

const Wrapper = styled(BaseView)`
  justify-content: center;
  padding: 5px;
  margin: 10px 0;
`;

const DataBarWrapper = styled(BaseView)`
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-vertical: 5;
`;

const DataBarName = styled(BaseText)`
  margin-right: 8px;
  font-size: 16px;
  width: 80px;
`;

const DataBarView = styled(BaseView)`
  width: ${({ $width }) => $width}%;
  background-color: ${({ $color }) => $color};
  position: relative;
  border-radius: 5px;
  height: 30px;
  justify-content: center;
`;

const DataBarViewRow = styled(BaseView)`
  flex: 1;
  border-radius: 5px;
  position: relative;
`;

const DataBarViewText = styled(BaseText)`
  font-weight: 600;
  position: absolute;

  ${({ $isEmpty }) =>
    $isEmpty
      ? css`
          font-size: 14px;
          width: 10px;
          left: 3px;
          text-align: center;
          color: white;
        `
      : css`
          font-size: 18px;
          right: 2px;
          color: white;
          margin-right: 8px;
        `}
`;

const DataBar = ({ name, value, color, width, isEmpty }) => (
  <DataBarWrapper>
    <DataBarName>{name}</DataBarName>
    <DataBarViewRow>
      <DataBarView $color={color} $width={width}>
        <DataBarViewText $isEmpty={isEmpty}>{value}</DataBarViewText>
      </DataBarView>
    </DataBarViewRow>
  </DataBarWrapper>
);

export const LiveViewChartBar = ({ liveViewStats }: DataParams) => {
  const { t } = useTranslation();
  const data = useMemo(
    () => [
      {
        name: t('onTimeClockIn'),
        value: liveViewStats.onTimeClockIn || 0,
        color: StatsColorsMap.onTimeClockIn,
      },
      // {
      //   name: t('earlyClockIn'),
      //   value: liveViewStats.earlyClockIn || 0,

      // },
      {
        name: t('lateClockIn'),
        value: liveViewStats.lateClockIn || 0,
        color: StatsColorsMap.lateClockIn,
      },
      // {
      //   name: t('onTimeClockOut'),
      //   value: liveViewStats.onTimeClockOut || 0,

      // },
      {
        name: t('earlyClockOut'),
        value: liveViewStats.earlyClockOut || 0,
        color: StatsColorsMap.earlyClockOut,
      },
      {
        name: t('lateClockOut'),
        value: liveViewStats.lateClockOut || 0,
        color: StatsColorsMap.lateClockOut,
      },
    ],
    [
      liveViewStats.onTimeClockIn,
      liveViewStats.lateClockIn,
      liveViewStats.earlyClockOut,
      liveViewStats.lateClockOut,
      t,
    ]
  );

  // min value which is not 0
  const { maxValue, minValue } = useMemo(() => {
    let dataMaxValue = 0;
    let dataMinValue = 1;

    data.forEach((item) => {
      dataMaxValue = Math.max(item.value, dataMaxValue);
      dataMinValue = Math.min(item.value || 1, dataMinValue);
    });

    return { maxValue: dataMaxValue, minValue: dataMinValue };
  }, [data]);

  return (
    <Wrapper>
      {data.map(({ name, value = 1, color: itemColor }, index) => {
        const isEmpty = value === 0;
        /* show empty as 50% from the minValue */
        const width = isEmpty ? minValue / 14 : value / maxValue;
        const color = isEmpty ? EMPTY_COLOR : itemColor;
        return (
          <DataBar
            key={index}
            name={name}
            width={width * 100}
            value={value}
            color={color}
            isEmpty={isEmpty}
          />
        );
      })}
    </Wrapper>
  );
};
