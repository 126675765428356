import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import createCachedSelector from 're-reselect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FieldTypes } from '../entities';

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => ({
    fields: data,
  })
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useEditEmployeeAccount = (accountId, enabled = true) => {
  const { data: userData } = useUser();
  const storeKey = ['editableFields', accountId];
  const queryClient = useQueryClient();
  const { isPending, data, refetch, isError } = useQuery({
    queryKey: storeKey,
    queryFn: () => api.getEmployeeEditableFields(accountId),
    enabled: !!userData?.id && enabled,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const {
    mutateAsync: editEmployeeDetails,
    isPending: isEditingEmployeeDetails,
  } = useMutation({
    mutationFn: (values) => api.editEmployeeFields(accountId, values),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: storeKey });
    },
  });

  const { fields = [] } = data || {};

  return {
    isLoading: isPending,
    fields,
    refetch,
    editEmployeeDetails,
    isEditingEmployeeDetails,
    isError,
  };
};
