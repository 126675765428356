import React, { FC, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import {
  IRequest,
  RequestTypes,
  useRequestsTypes,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  H4Bold,
  H5Regular,
  RipplePressable,
  FlexRow,
  H5Bold,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled, { useTheme } from 'styled-components/native';

const Container = styled(BaseView)`
  margin-right: 20px;
  margin-left: 20px;
  padding: 20px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray3};
`;

const Content = styled(BaseView)`
  flex: 1;
`;

const Data = styled(BaseView)`
  font-weight: 500;
`;

const Name = styled(H4Bold)`
  padding-bottom: 5px;
`;
const Date = styled(H5Regular)``;
const Divider = styled(BaseView)`
  margin: 2px 0;
`;
const EmployeeReason = styled(H5Bold)`
  font-size: 15px;
`;
const ManagerReason = styled(H5Bold)`
  font-size: 15px;
`;

const Head = styled(H5Regular)`
  margin-right: 5px;
`;

const Status = styled(H4Bold)<{ color: string }>`
  color: ${({ color }) => color};
`;
const StatusContainer = styled(BaseView)``;

const ReasonContainer = styled(FlexRow)`
  align-items: center;
  padding-top: 3px;
  padding-bottom: 3px;
  flex-wrap: wrap;
  padding-right: 10px;
`;

interface Props {
  request: IRequest;
  onPress?: () => void;
}

export const RequestCard: FC<Props> = ({ request, onPress }) => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const { data, typeId, status, employeeReason, managerReason } = request;

  const { mappedRequestsTypesByTypeId } = useRequestsTypes();

  const requestTranslation = useMemo(() => {
    switch (typeId) {
      case RequestTypes.ABSENCE:
        return (
          mappedRequestsTypesByTypeId[typeId]?.kinds.find(
            (kind) => data.jobTypeId === kind.id
          )?.slug || ''
        );

      case RequestTypes.GENERAL_AVAILABILITY:
        return mappedRequestsTypesByTypeId[typeId].type;

      case RequestTypes.RELEASE_SHIFT:
        return mappedRequestsTypesByTypeId[typeId].type;

      case RequestTypes.SWAP_SHIFT:
        return mappedRequestsTypesByTypeId[typeId].type;

      default:
        break;
    }
  }, [mappedRequestsTypesByTypeId, typeId, data]);

  const requestDate = useMemo(() => {
    // eslint-disable-next-line eqeqeq
    const endStr =
      data?.endTime && data?.startTime != data?.endTime
        ? `- ${moment(data?.endTime).format('L')}`
        : '';
    return `${moment(data?.startTime).format('L')}${endStr}`;
  }, [data?.startTime, data?.endTime]);

  const requestStatus = useMemo(() => {
    switch (status) {
      case 1:
        return {
          icon: icons.symbolIcons.planeGreen,
          title: t('approvedRequest'),
          color: colors.green1,
        };
      case 2:
        return {
          icon: icons.symbolIcons.planeRed,
          title: t('declinedRequest'),
          color: colors.red1,
        };
      default:
        return {
          icon: icons.symbolIcons.planeYellow,
          title: t('pendingRequest'),
          color: colors.yellow1,
        };
    }
  }, [colors.green1, colors.red1, colors.yellow1, status, t]);

  return (
    <Container as={RipplePressable} onPress={onPress}>
      <Content>
        {/* <SvgIcon xml={requestStatus.icon} width={20} height={20} /> */}
        <Data>
          {requestTranslation && <Name>{t(requestTranslation)}</Name>}
          <Date>{requestDate}</Date>
          {employeeReason || managerReason ? <Divider /> : null}
          {!!employeeReason && (
            <ReasonContainer>
              <Head>{t('employeeReason')} </Head>
              <EmployeeReason>{employeeReason}</EmployeeReason>
            </ReasonContainer>
          )}
          {!!managerReason && (
            <ReasonContainer>
              <Head>{t('managerReason')} </Head>
              <ManagerReason>{managerReason}</ManagerReason>
            </ReasonContainer>
          )}
        </Data>
      </Content>
      <StatusContainer>
        <Status color={requestStatus.color}>{requestStatus.title}</Status>
      </StatusContainer>
    </Container>
  );
};
