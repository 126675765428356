import moment from 'moment';

export const totalDuration = (startTime: moment.Moment, endTime: moment.Moment) => {
  if (!startTime || !endTime) {
    return moment.duration(0);
  }
  return moment.duration(moment(endTime).diff(moment(startTime)));
};

export const hoursToTotalDuration = (duration: moment.Duration) =>
  Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(':mm');
