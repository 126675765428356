import { useCallback, useEffect, useState } from 'react';
import PushNotificationsIOS, {
  PushNotification,
} from '@react-native-community/push-notification-ios';
import messaging, {
  FirebaseMessagingTypes,
} from '@react-native-firebase/messaging';
import { Platform } from 'react-native';
import { Notifications } from 'react-native-notifications';
import notifee from '@violetta/ubeya/notifee';

const ANDROID_PUSH_NOTIFICATION_CHANNEL_ID: string = 'ubeya-notification-main';
interface Props {
  onNotificationPress?: (
    remoteMessage: FirebaseMessagingTypes.RemoteMessage,
    type: 'quit' | 'background' | 'foreground'
  ) => void;
  onNotificationReceived?: (
    remoteMessage: FirebaseMessagingTypes.RemoteMessage,
    type: 'quit' | 'background' | 'foreground'
  ) => void;

  onTokenReceived?: (token: string) => void;
  refetchStats?: () => void;
  deps?: any[];
}

export const usePushNotificationsPermissions = () => {
  const [isPushAllowed, setIsPushAllowed] = useState<boolean>(false);

  const checkPermissions = useCallback(async () => {
    if (Platform.OS === 'web') {
      return false;
    }

    const authorizationStatuses = [
      messaging.AuthorizationStatus.AUTHORIZED,
      messaging.AuthorizationStatus.PROVISIONAL,
    ];

    /**
     * First, check if we already asked for permissions, otherwise,
     * ask again.
     */
    return (
      authorizationStatuses.includes(await messaging().hasPermission()) ||
      authorizationStatuses.includes(
        Platform.OS === 'android'
          ? await notifee.requestPermission()
          : await messaging().requestPermission()
      )
    );
  }, []);

  /**
   * Request push notifications permissions
   * from the OS
   */
  useEffect(() => {
    checkPermissions().then((isPermitted) => {
      setIsPushAllowed(isPermitted);
    });
  }, [checkPermissions, setIsPushAllowed]);

  return { isPushAllowed };
};

export const useForegroundPushNotifications = ({
  isPushAllowed,
  onNotificationPress,
  onNotificationReceived,
}: {
  isPushAllowed: boolean;
  onNotificationPress: Props['onNotificationPress'];
  onNotificationReceived: Props['onNotificationReceived'];
}) => {
  /**
   * Handle local notifications.
   * We use it to listen to the local push notification fired by wix's
   * react-native-notifications.
   */
  useEffect(() => {
    if (!isPushAllowed || Platform.OS === 'web') {
      return () => {};
    }

    /**
     * This is a handler for ios local notifications,
     * wix react-native-notifications cannot handle it,
     * so we use PushNotificationsIOS to handle local
     * notifications on IOS.
     */

    if (Platform.OS === 'ios') {
      PushNotificationsIOS.addEventListener(
        'localNotification',
        (notification: PushNotification) => {
          const isClicked = notification.getData().userInteraction === 1;
          const data = notification.getData()
            .payload as FirebaseMessagingTypes.RemoteMessage;

          if (!data) {
            return;
          }
          if (isClicked) {
            return onNotificationPress?.(data, 'foreground');
          }
        }
      );

      return () => {
        PushNotificationsIOS.removeEventListener('localNotification');
      };
    } else {
      notifee.onForegroundEvent(({ type, detail }) => {
        if (type === 1 /* press */) {
          return onNotificationPress?.(
            detail?.notification?.data as FirebaseMessagingTypes.RemoteMessage,
            'foreground'
          );
        }
      });
      notifee.onBackgroundEvent(async (value) => {
        const { type, detail } = value;
        if (type === 1 /* press */) {
          return onNotificationPress?.(
            detail?.notification?.data as FirebaseMessagingTypes.RemoteMessage,
            'foreground'
          );
        }
      });
    }
    // /**
    //  * Here we use wix's react-native-notifications
    //  * to handle local push notifications, only for android
    //  */

    // const { remove } = Notifications.events().registerNotificationOpened(
    //   (notification: Notification | undefined) => {
    //     console.log({ notification });
    //     const data = notification?.payload?.payload;
    //     if (!data) {
    //       return;
    //     }

    //     return onNotificationPress?.(data, 'foreground');
    //   }
    // );

    // return () => {
    //   try {
    //     return remove();
    //   } catch (e) {}
    // };
  }, [isPushAllowed, onNotificationPress]);

  useEffect(() => {
    if (!isPushAllowed || Platform.OS === 'web') {
      return () => {};
    }
    return messaging().onMessage((remoteMessage) => {
      const body = remoteMessage.notification?.body || '';
      const title = remoteMessage.notification?.title || '';
      const sound =
        Platform.OS === 'ios'
          ? remoteMessage.notification?.ios?.sound
          : remoteMessage.notification?.android?.sound;
      onNotificationReceived?.(remoteMessage, 'foreground');

      if (Platform.OS === 'android') {
        /**
         * This callback is being called via rnfirebase only in case
         * the app is in foreground, so in that case, we should fire-up
         * a local push notification using wix's react-native-notifications,
         * and send the remoteMessage as is, for simplicity of the API
         */

        notifee.displayNotification({
          title,
          body,
          data: remoteMessage?.data,
          android: {
            channelId: ANDROID_PUSH_NOTIFICATION_CHANNEL_ID,
            ...(remoteMessage?.notification?.android?.imageUrl && {
              largeIcon: remoteMessage?.notification?.android?.imageUrl,
            }),
            smallIcon: 'notification_icon',
            color: '#040849',
          },
        });
      } else {
        // @ts-ignore
        Notifications.postLocalNotification({
          body,
          title,
          sound,
          silent: false,
          category: 'SOME_CATEGORY',
          userInfo: {},
          payload: remoteMessage,
        });
      }
    });
  }, [isPushAllowed, onNotificationReceived]);
};

export const useBackgroundPushNotifications = ({
  isPushAllowed,
  onNotificationPress,
}: {
  isPushAllowed: boolean;
  onNotificationPress: Props['onNotificationPress'];
}) => {
  /**
   * Register remote push notifications with rnfirebase,
   * all remote push notifications are handled via this library,
   * on both platforms
   */
  useEffect(() => {
    if (!isPushAllowed || Platform.OS === 'web') {
      return () => {};
    }

    // messaging().registerDeviceForRemoteMessages();

    const cleans = [
      messaging().unregisterDeviceForRemoteMessages,
      messaging().onNotificationOpenedApp((remoteMessage) =>
        onNotificationPress?.(remoteMessage, 'background')
      ),
    ] as Array<(...args: any[]) => any>;

    /**
     * Clean up methods
     */
    return () =>
      cleans.forEach((clean) => {
        try {
          return clean();
        } catch (e) {}
      });
  }, [onNotificationPress, isPushAllowed]);
};

export const useQuitPushNotifications = ({
  isPushAllowed,
  onNotificationPress,
}: {
  isPushAllowed: boolean;
  onNotificationPress: Props['onNotificationPress'];
}) => {
  /**
   * This handles the quit mode notifications,
   * if you click a notification when the app is in quit mode.
   */
  useEffect(() => {
    if (!isPushAllowed || Platform.OS === 'web') {
      return;
    }

    messaging()
      .getInitialNotification()
      .then((remoteMessage) => {
        if (!remoteMessage) {
          return;
        }

        return onNotificationPress?.(remoteMessage, 'quit');
      });
  }, [onNotificationPress, isPushAllowed]);
};

export const usePushNotification = ({
  onNotificationPress,
  onNotificationReceived,
  onTokenReceived,
  refetchStats,
}: Props) => {
  const { isPushAllowed } = usePushNotificationsPermissions();

  useForegroundPushNotifications({
    isPushAllowed,
    onNotificationPress,
    onNotificationReceived,
  });
  useBackgroundPushNotifications({ isPushAllowed, onNotificationPress });
  useQuitPushNotifications({ isPushAllowed, onNotificationPress });

  /**
   * If the push notification are allowed,
   * make sure to get the token and subscribe to a refresh token mode,
   * via the callback supplied by the user
   */
  useEffect(() => {
    if (Platform.OS !== 'web') {
      refetchStats?.();

      if (isPushAllowed) {
        messaging()
          .getToken()
          .then((token) => {
            onTokenReceived?.(token);
          })
          .catch((e) => console.error(e));

        return messaging().onTokenRefresh((token) => {
          onTokenReceived?.(token);
        });
      }
      if (Platform.OS === 'ios') {
        PushNotificationsIOS?.setApplicationIconBadgeNumber(0);
      }
    }
    // if (Platform.OS === 'android') {
    //   Notifications.setNotificationChannel({
    //     channelId: ANDROID_PUSH_NOTIFICATION_CHANNEL_ID,
    //     name: ANDROID_PUSH_NOTIFICATION_CHANNEL_NAME,
    //     importance: 5,
    //     enableVibration: false,
    //     showBadge: true,
    //   });
    //   console.log('registered');
    // }

    return () => {};
  }, [onTokenReceived, isPushAllowed, refetchStats]);
  return { isPushAllowed };
};
