import { IBranch, usePosts } from '@violetta/ubeya/entities';
import styled from 'styled-components/native';
import React, { FC, useCallback } from 'react';
import { Post } from '../../../feed/screens/feed/components';
import { BaseView, Card } from '@violetta/ubeya/ui';
import { CardTitle } from '../CardTitle/CardTitle';
import { useTranslation } from 'react-i18next';
import { FeedScreenNames } from '../../../feed';
import { useNavigation } from '@react-navigation/core';

const Container = styled(Card)``;

type Props = {};

export const FeedCard: FC<Props> = React.memo(() => {
  const { t } = useTranslation();
  const {
    posts,
    employees,
    createPollAnswer,
    deletePollAnswer,
    createLike,
    deleteLike,
  } = usePosts({ isStar: true, pageSize: 5 });
  const { navigate } = useNavigation();

  const navigateFeed = useCallback(() => {
    navigate(FeedScreenNames.MAIN, {
      screen: FeedScreenNames.FEED,
    });
  }, [navigate]);

  if (!posts || posts?.length === 0) {
    return null;
  }

  return (
    <BaseView>
      <CardTitle
        title={t('feed')}
        onShowAllPress={navigateFeed}
        alwaysShowAllLink
      />

      {posts.map((post) => (
        <Container>
          <Post
            key={post.id}
            title={post.title}
            post={post}
            employees={employees}
            createPollAnswer={createPollAnswer}
            deletePollAnswer={deletePollAnswer}
            createLike={createLike}
            deleteLike={deleteLike}
            withBoxStyling={false}
          />
        </Container>
      ))}
    </BaseView>
  );
});
