import React, { FC, useMemo } from 'react';
import { lotties } from '@violetta/ubeya/assets';
import { useBookings, useNotifications } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, EmptyState } from '@violetta/ubeya/ui';
import { FlatList, SafeAreaView } from 'react-native';
import styled from 'styled-components/native';
import { AuditLog } from '../../home/components/AuditLog/AuditLog';

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

type Props = {};

export const NotificationsScreen: FC<Props> = () => {
  const { notifications, isLoading, refetch } = useNotifications({
    pageSize: 100,
  });
  const { t } = useTranslation();
  const { mappedBookings } = useBookings();
  const bookingIds = useMemo(
    () => Object.keys(mappedBookings),
    [mappedBookings]
  );

  return (
    <Container>
      <FlatList
        ListEmptyComponent={
          !isLoading ? (
            <EmptyState
              lottieSrc={lotties.noFeedData}
              title={t('noNotifications')}
            />
          ) : (
            <BaseView />
          )
        }
        contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
        data={notifications}
        keyExtractor={(item) => item.id?.toString()}
        renderItem={({ item }) => (
          <AuditLog
            key={item?.id}
            type={item?.type}
            data={JSON.parse(item?.data)}
            id={item?.id}
            createdAt={item?.createdAt}
            notification={item}
            canNavigate={bookingIds?.includes(item?.shiftId?.toString())}
          />
        )}
        refreshing={isLoading}
        onRefresh={refetch}
      />
    </Container>
  );
};
