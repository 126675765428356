import chatBackground from './chat-background.svg';
import emptyOffersCardImageRtl from './empty-offers-card-image-rtl.svg';
import emptyOffersCardImage from './empty-offers-card-image.svg';
import emptyProfileImage from './empty-profile.svg';
import emptyScheduleCardImageRtl from './empty-schedule-card-image-rtl.svg';
import emptyScheduleCardImage from './empty-schedule-card-image.svg';
import joinCompanyRtl from './join-company-rtl.svg';
import joinCompany from './join-company.svg';
import lastItem from './last-item.svg';
import passGradient from './pass-gradient.svg';
import phonebookNoSearchResults from './phonebook-no-search-results.svg';
import welcome from './welcome-1.svg';
import welcome2 from './welcome-2.svg';
import welcome3 from './welcome-3.svg';

const defaultCoverPhoto =
  'https://s3.us-west-2.amazonaws.com/general.ubeya.com/images/company-profile-default-cover.jpg';

const svgs = {
  emptyProfileImage,
  passGradient,
  phonebookNoSearchResults,
  chatBackground,
  joinCompany,
  joinCompanyRtl,
  emptyOffersCardImage,
  emptyScheduleCardImage,
  emptyOffersCardImageRtl,
  emptyScheduleCardImageRtl,
  welcome,
  welcome2,
  welcome3,
  lastItem,
};

const jpg = {
  defaultCoverPhoto,
};

export { svgs, jpg };
