import { useCallback } from 'react';
import { FieldTypes, FieldValue, IField, IUbeyaForm } from '../entities';
import { atom, useRecoilState } from 'recoil';

export type FieldValues = Record<`field-${number}`, IField['value']>;
export type FieldDirtyValues = Record<`field-${number}`, boolean>;

export const currentFormAtom = atom<IUbeyaForm['id'] | null>({
  key: 'currentForm',
  default: null,
});

export interface FormResults {
  [formId: number]: {
    id: number;
    dirty: boolean;
    valid?: boolean;
    fields: {
      [fieldId: number]: {
        id: number;
        type?: number;
        value: FieldValue;
      };
    };
  };
}

export const formsResultsAtom = atom<FormResults>({
  key: 'formsResults',
  default: {},
});

export const useUbeyaForm = () => {
  const [currentFormId, setCurrentFormId] = useRecoilState(currentFormAtom);
  const [formsResults, setFormsResults] = useRecoilState(formsResultsAtom);

  const buildFieldIdTypeKey = useCallback(
    (fieldId, type) =>
      ['field', fieldId, type].filter((cur) => cur === 0 || !!cur).join('-'),
    []
  );

  const save = useCallback(
    (
      formId: number,
      results: FieldValues,
      dirty?: boolean,
      valid?: boolean
    ) => {
      setFormsResults((formsResults) => {
        const result = {
          ...formsResults,
          [formId]: {
            id: formId,
            valid:
              valid !== undefined
                ? valid
                : formsResults?.[formId]?.valid || false,
            dirty:
              dirty !== undefined
                ? dirty
                : formsResults?.[formId]?.dirty || false,
            fields: Object.entries(formsResults?.[formId]?.fields || {}).reduce(
              (carry, [fieldIdPayload, { value, type }]) => {
                const [fieldId] = fieldIdPayload.split('-');

                carry[[fieldId, type].filter((cur) => !!cur).join('-')] = {
                  id: buildFieldIdTypeKey(fieldId, type),
                  type,
                  value: results[buildFieldIdTypeKey(fieldId, type)] || value,
                };
                return carry;
              },
              {}
            ),
          },
        };

        return result;
      });
    },
    [buildFieldIdTypeKey, setFormsResults]
  );

  const isReadOnlyForm = useCallback(
    (form: IUbeyaForm) => {
      const fields = Object.entries(formsResults?.[form.id]?.fields || {});
      return fields.every(([, { id }]) => {
        const fieldTypeId = form?.fields.find(
          (field) => field.id === id
        )?.fieldTypeId;
        return (
          fieldTypeId &&
          [
            FieldTypes.FIELD_TYPE_READONLY_IMAGE,
            FieldTypes.FIELD_TYPE_READONLY_TITLE,
            FieldTypes.FIELD_TYPE_READONLY_DOCUMENT,
          ].includes(fieldTypeId)
        );
      });
    },
    [formsResults]
  );

  const isFormCompleted = useCallback(
    (form: IUbeyaForm) => {
      const fields = Object.entries(formsResults?.[form.id]?.fields || {});

      return false;
      // return (
      //   fields
      //     .filter(([, { id }]) => !!form?.fields.find((field) => field.id === id)?.isRequired)
      //     .every(([, { value }]) => value !== undefined && value !== false) && fields.length > 0
      // );
    },
    [formsResults]
  );

  return {
    currentFormId,
    setCurrentFormId,
    save,
    formsResults,
    isFormCompleted,
    isReadOnlyForm,
    setFormsResults,
  };
};
