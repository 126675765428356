import { useTranslation } from '@violetta/ubeya/i18n';
import React, { FC, useEffect, useMemo, useState } from 'react';
import QRCode from 'react-qr-code';
import styled from 'styled-components/native';
import { IconClose, SvgIcon } from '../Icons';
import {
  BaseView,
  FlexColumn,
  FlexColumnCenter,
  FlexRowBetween,
} from '../Layout/Layout';
import { Modal } from '../Modals/Modal';
import { H2Bold, H3Bold } from '../Typography';
import { RipplePressable } from '../Layout/RipplePressable';
import { icons } from '@violetta/ubeya/assets';

const Title = styled(H2Bold)`
  margin-bottom: 50px;
`;

const SubTitle = styled(H3Bold)`
  margin-bottom: 10px;
`;

const ModalHeader = styled(FlexRowBetween)`
  background-color: #f7f8fa;
  padding: 20px 30px;
`;

const HeaderIcon = styled(BaseView)`
  margin-left: -10px;
`;

const Container = styled(FlexColumn)`
  flex: 1;
  align-items: center;
  background-color: #f7f8fa;
`;

const Card = styled(FlexColumnCenter)`
  background-color: ${({ theme }) => theme.colors.surface};
  width: 95%;
  border-color: #dcdfec;
  border-radius: 8px;
  border-width: 1px;
  padding: 20px 10px 30px;
`;

const ScanQRText = styled(H3Bold)`
  margin-bottom: 20px;
`;

interface Props {
  title: string;
  subTitle?: string;
  qrCodeData: object;
  onClose: () => void;
}

export const QrCodeModal: FC<Props> = ({
  title,
  subTitle,
  qrCodeData,
  onClose,
}) => {
  const { t } = useTranslation();
  const [seed, setSeed] = useState<number>(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeed(Math.random() * 100);
    }, 10000);
    return () => clearInterval(intervalId);
  }, [setSeed]);

  const qrData = useMemo(
    () => JSON.stringify({ ...qrCodeData, seed }),
    [qrCodeData, seed]
  );

  return (
    <Modal animationType="slide" presentationStyle="pageSheet" visible>
      <ModalHeader>
        <HeaderIcon
          as={RipplePressable}
          onPress={() => {
            onClose();
          }}
        >
          <SvgIcon width={16} height={16} xml={icons.actionIcons.x} />
        </HeaderIcon>
      </ModalHeader>
      <Container>
        <Title>{title}</Title>

        <Card>
          {subTitle && <SubTitle>{subTitle}</SubTitle>}
          <ScanQRText>{t('qrScanCode')}</ScanQRText>
          <QRCode value={qrData} size={300} />
        </Card>
      </Container>
    </Modal>
  );
};
