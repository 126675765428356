import React, { FC } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import { PromptProps } from '@violetta/ubeya/utils';
import { Modal, Platform } from 'react-native';
import styled from 'styled-components/native';
import { Button } from '../Buttons';
import {
  FlexColumnDirection,
  FlexRow,
  FlexRowCenter,
  FlexRowEvenly,
} from '../Layout';
import { H3Medium, H5Medium } from '../Typography';

const AlertBox = styled(FlexRowCenter)`
  flex: 1;
  padding: 15px;
`;

const Title = styled(H3Medium)`
  text-align: left;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray1};
  padding: 15px 0;
`;

const Text = styled(H5Medium)`
  color: ${({ theme }) => theme.colors.gray1};
  margin-top: 10px;
  padding: 5px 0 10px;
`;

const Buttons = styled(FlexRowEvenly)`
  margin-top: 20px;
`;

const ButtonContainer = styled(FlexRow)`
  flex: 12;
`;

const Space = styled(FlexRow)`
  flex: 1;
`;

const AlertBoxContainer = styled(FlexColumnDirection)`
  flex: 1;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  shadow-color: rgba(0, 0, 0, 0.05);
  shadow-opacity: ${Platform.OS === 'ios' ? 0.6 : 0.3};
  shadow-offset: 0 2px;
  shadow-radius: 5px;
  elevation: 5;
`;

const AlertBoxOverlay = styled(FlexColumnDirection)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  opacity: 0.15;
`;

type Props = PromptProps & { visible?: boolean };

export const Prompt: FC<Props> = ({
  onCancel,
  onSave,
  text,
  title,
  visible,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      animationType="fade"
      visible={!!visible}
      onRequestClose={onCancel?.cb}
      transparent
      style={{
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <AlertBox>
        <AlertBoxOverlay />
        <AlertBoxContainer>
          {!!title && <Title>{title}</Title>}
          {!!text && <Text>{text}</Text>}
          <Buttons>
            {onCancel && (
              <ButtonContainer>
                <Button
                  type="primaryReversed"
                  title={onCancel.title || t('cancel')}
                  onPress={onCancel.cb}
                />
              </ButtonContainer>
            )}
            {onSave && onCancel && <Space />}
            {onSave && (
              <ButtonContainer>
                <Button
                  type="primary"
                  title={onSave.title || t('save')}
                  onPress={onSave.cb}
                />
              </ButtonContainer>
            )}
          </Buttons>
        </AlertBoxContainer>
      </AlertBox>
    </Modal>
  );
};
