import { Location } from '@violetta/ubeya/utils';
import moment from 'moment';
import { atom } from 'recoil';
import { persistentAtom } from 'recoil-persistence/react-native';
import { OfferStatusFilterOptions } from './modules/offers/constants';
import { config } from './modules/main/appConfig';

export const showSplashAtom = atom<boolean>({
  key: 'showSplashAtom',
  default: true,
});

export const timeAvailabilitySelectedWeek = atom<{
  date: Date;
  enabled: boolean;
}>({
  key: 'timeAvailabilitySelectedWeek',
  default: { date: moment().startOf('week').toDate(), enabled: false },
});

export const weeklyScheduleSelectedWeekAtom = atom<{ date: Date }>({
  key: 'weeklyScheduleSelectedWeekAtom',
  default: { date: moment().startOf('week').toDate() },
});

export const offerCalendarMonthAtom = atom({
  key: 'offerCalendarMonthAtom',
  default: moment().format('MMM YYYY'),
});

export const isEditingGeneralAvailabilityAtom = atom<boolean>({
  key: 'isEditingGeneralAvailabilityAtom',
  default: false,
});

export const isOfferCalendarOpenAtom = atom({
  key: 'isOfferCalendarOpenAtom',
  default: false,
});

export const isOffersModalOpenAtom = atom({
  key: 'isOffersModalOpenAtom',
  default: false,
});

export const isOffersCalendarClickedAtom = atom({
  key: 'isOffersCalendarClickedAtom',
  default: false,
});

export const offersFilterAtomDefault = {
  distance: [],
  branchIds: [],
  positionIds: [],
  offersStatus: { [OfferStatusFilterOptions.ALL]: true },
};

export const offersFilterAtom = atom({
  key: 'offersFilterAtom',
  default: offersFilterAtomDefault,
});

export const finishedOnboardingAtom = persistentAtom({
  key: 'finishedOnboardingAtom',
  default: true,
});

export const searchStateAtom = atom({
  key: 'searchStateAtom',
  default: {
    searchKey: '',
    isSearchOpen: false,
  },
});

export const hideTabsAtom = atom({
  key: 'hideTabsAtom',
  default: new Set<string>(),
});

type IMutationQueueItem = {
  mutation: any;
  timeRequested: string | Date;
  params:
    | {
        timesheetId: number;
        location: Location | null;
      }
    | {
        projectId: number;
        shiftId: number;
        employeeId: number;
        values: any;
        delta: number;
      };
};

export const userLocationAtom = atom<Location | null>({
  key: 'userLocationAtom',
  default: null,
});

export const supportSectionOpen = atom<boolean>({
  key: 'supportSectionOpen',
  default: config.showSupportModal,
});

export const qrCodeTorchState = atom<boolean>({
  key: 'qrCodeTorchState',
  default: false,
});

export const unsyncedTimesheetsAtom = atom<Set<string>>({
  key: 'unsyncedTimesheetsAtom',
  default: new Set(),
});
