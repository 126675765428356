import React, { FC, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Button,
  Card,
  FlexRow,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';

const AvailabilityCardContainer = styled(FlexRow)``;

const IconContainer = styled(BaseView)`
  justify-content: flex-end;
  align-items: center;
`;

const Content = styled(BaseView)`
  padding: 12px;
  flex: 1;
`;

const Dates = styled(BaseText)`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 3px;
`;
const Text = styled(BaseText)`
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;
const Name = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

const ButtonContainer = styled(BaseView)`
  align-items: flex-end;
  margin-top: 10px;
`;

interface Props {
  id: number;
  date?: Date;
  onPress: (id: number) => void;
  name?: string;
  rtl: boolean;
  weeklyPreferences?: boolean;
}

export const AvailabilityCard: FC<Props> = ({
  id,
  name,
  date,
  onPress,
  rtl,
  weeklyPreferences,
}) => {
  const { t } = useTranslation();

  const [startDate, endDate] = useMemo(
    () => [
      moment(date).format('DD MMM'),
      moment(date).add(6, 'days').format('DD MMM'),
    ],
    [date]
  );
  return (
    <Card as={RipplePressable} onPress={() => onPress(id)}>
      <AvailabilityCardContainer>
        <IconContainer>
          <SvgIcon
            xml={icons.uiIcons.newAvailabilityCardIcon}
            width={141}
            height={121}
          />
        </IconContainer>
        <Content>
          <Dates>{`${startDate} - ${endDate}`}</Dates>
          <Text>
            {weeklyPreferences
              ? t('setWeeklyPreferences')
              : t('openAvailability')}
          </Text>
          {!!name && <Name>{name}</Name>}
          <ButtonContainer>
            <Button
              type="primaryReversed"
              title={t('view')}
              onPress={() => onPress(id)}
              paddingHorizontal={20}
              paddingVertical={5}
            />
          </ButtonContainer>
        </Content>
      </AvailabilityCardContainer>
    </Card>
  );
};
