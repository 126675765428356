import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { QrCodeScannerHeader } from '../components/QrCodeScannerHeader';
import { QrCodeScannerScreen } from '../screens';
import { QrCodeScannerScreenNames } from './ScreenNames';
import { ScannedEmployeeScreen } from '../screens/ScannedEmployeeScreen';
import { ScannedEmployeeHeader } from '../components/ScannedEmployeeHeader';

const { Navigator, Screen } = createNativeStackNavigator();

export const QrCodeScannerNavigation: FC = () => (
  <Navigator initialRouteName={QrCodeScannerScreenNames.SCANNER}>
    <Screen
      name={QrCodeScannerScreenNames.SCANNER}
      component={QrCodeScannerScreen}
      options={{
        header: (props: NativeStackHeaderProps) => (
          <QrCodeScannerHeader {...props} />
        ),
      }}
    />
    <Screen
      name={QrCodeScannerScreenNames.EMPLOYEE}
      component={ScannedEmployeeScreen}
      options={{
        header: (props: NativeStackHeaderProps) => (
          <ScannedEmployeeHeader {...props} />
        ),
      }}
    />
  </Navigator>
);
