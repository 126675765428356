import React, { FC } from 'react';
import { RouteProp, useRoute } from '@react-navigation/native';
import { BaseView } from '@violetta/ubeya/ui';
import Pdf from 'react-native-pdf';
import styled from 'styled-components/native';

const Container = styled(BaseView)`
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;

type ParamList = {
  params: {
    uri: string;
  };
};

export const PdfReaderScreen: FC = React.memo(() => {
  const { params } = useRoute<RouteProp<ParamList, 'params'>>();
  const { uri } = params || {};

  return (
    <Container>
      <Pdf
        trustAllCerts={false}
        style={{ flex: 1, width: '100%', height: '100%' }}
        source={{ uri }}
        onError={(error) => {
          console.log('PdfReaderScreen', error);
        }}
        onPressLink={(uri) => {
          console.log(`Link pressed: ${uri}`);
        }}
      />
    </Container>
  );
});
