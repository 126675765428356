import React, { FC } from 'react';
import { webURL } from '@violetta/ubeya/api';
import { CustomLoaderWrapped } from '@violetta/ubeya/ui';
import WebView from 'react-native-webview';

export const ManagementScreen: FC = () => (
  <WebView
    source={{
      uri: webURL,
    }}
    originWhitelist={['*']}
    renderLoading={() => <CustomLoaderWrapped />}
    startInLoadingState
  />
);
