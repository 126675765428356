import { RouteProp, useRoute } from '@react-navigation/native';
import { BranchInformationSchema, ILocation } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FlexColumnDirection,
  FlexRow,
  H2Bold,
} from '@violetta/ubeya/ui';
import { openMap } from '@violetta/ubeya/utils';
import React, { useCallback } from 'react';
import { Alert, Linking } from 'react-native';
import Markdown from 'react-native-markdown-display';
import styled from 'styled-components/native';
import { AccountProfileCard } from '../components';

type ParamList = {
  params: {
    branchInfo: BranchInformationSchema & { location: ILocation };
  };
};

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  padding: 30px 30px;
  padding-top: 0;
`;

const EmptySection = styled(FlexRow)`
  border-top-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray11};
`;

const SectionTitle = styled(H2Bold)`
  margin: 25px 0;
  font-size: 18px;
  font-weight: 500;
`;

const MarkdownContainer = styled(BaseView)`
  margin-left: 25px;
`;

export const BranchContactScreen = () => {
  const {
    params: { branchInfo },
  } = useRoute<RouteProp<ParamList, 'params'>>();

  const { t } = useTranslation();

  const { contacts, officeHoursText, location } = branchInfo || {};

  // assuming there is only one (if at all)
  const contact = contacts[0];

  const { phone, name, email } = contact || {};

  const call = useCallback(() => {
    if (!phone) {
      Alert.alert(t('contactHasNoPhone'));
      return;
    }
    const url = `tel:${phone}`;
    if (!Linking.canOpenURL(url)) {
      Alert.alert('Can not call from this device');
      return;
    }
    Linking.openURL(url);
  }, [phone, t]);

  const mail = useCallback(() => {
    const url = `mailto:${email}`;
    if (!Linking.canOpenURL(url)) {
      Alert.alert('Can not send mail from this device');
      return;
    }
    Linking.openURL(url);
  }, [email]);

  const details = [
    { title: location.address, onPress: () => openMap(location) },
    { title: name, formattedTitle: `${t('name')}: ${name}` },
    { title: phone, formattedTitle: t('callUs'), onPress: call },
    { title: email, formattedTitle: t('emailUs'), onPress: mail },
  ];

  return (
    <Container>
      <SectionTitle>{t('contact')}</SectionTitle>
      {details
        .filter(({ title }) => !!title)
        .map(({ onPress, title, formattedTitle }) => {
          return (
            <AccountProfileCard
              onPress={onPress}
              hideArrow={!onPress}
              title={formattedTitle || title}
              hideIcon
            />
          );
        })}

      <EmptySection />

      {officeHoursText && officeHoursText?.trim()?.length > 0 && (
        <>
          <SectionTitle>{t('officeHours')}</SectionTitle>

          <MarkdownContainer>
            <Markdown>{officeHoursText}</Markdown>
          </MarkdownContainer>
        </>
      )}
    </Container>
  );
};
