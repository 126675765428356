import React, { FC, useCallback, useMemo } from 'react';
import { useRoute, RouteProp } from '@react-navigation/native';
import { lotties } from '@violetta/ubeya/assets';
import { useSwap } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  EmptyState,
  H3Medium,
  H3Regular,
  H6Medium,
  Lottie,
  SearchBox,
  WideFlexColumnCenter,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { RefreshControl, SectionList } from 'react-native';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import { searchStateAtom } from '../../../state';
import { SwapListRow } from '../components';

const Container = styled(BaseView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const Separator = styled(BaseView)`
  border-bottom-width: 1px;
  border-bottom-color: #eaeaea;
  margin: 0 16px;
`;

const DayStrip = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.primaryLight2};
  padding: 8px 16px;
`;
const DayTitle = styled(H6Medium)`
  font-weight: 500;
`;

const EmptyTitle = styled(H3Regular)`
  padding: 10px 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  color: #252525;
`;

const DateTitle = styled(H3Medium)`
  font-weight: normal;
  color: ${({ theme }) => theme.colors.primary};
`;

type ParamList = {
  params: {
    bookingId: number;
    projectId: number;
  };
};

export const SwapListScreen: FC = () => {
  const { params } = useRoute<RouteProp<ParamList, 'params'>>();
  const { isLoading, mappedSwapEmployeesByDay, refetchSwapEmployees } = useSwap(
    params?.projectId,
    params?.bookingId
  );
  const [searchState] = useRecoilState(searchStateAtom);
  const { t } = useTranslation();

  const filteredSwap = useMemo(
    () =>
      (mappedSwapEmployeesByDay || [])
        ?.map((swapEmployees) => ({
          title: swapEmployees.title,
          data:
            searchState.searchKey.length > 0
              ? swapEmployees?.data?.filter(
                  ({ employee }) =>
                    `${employee?.firstName} ${employee?.lastName}`.includes(
                      searchState.searchKey
                    ) ||
                    `${employee?.firstName} ${employee?.lastName}`
                      .toLowerCase()
                      .includes(searchState.searchKey) ||
                    `${employee?.firstName} ${employee?.lastName}`
                      .toUpperCase()
                      .includes(searchState.searchKey)
                )
              : swapEmployees?.data,
        }))
        .filter((swapEmployees) => swapEmployees?.data?.length > 0),
    [mappedSwapEmployeesByDay, searchState.searchKey]
  );

  const renderSectionHeader = useCallback(
    ({ section: { title, data } }) => (
      <DayStrip key={title}>
        <DayTitle>{`${moment(title).format('ddd MM/DD').toUpperCase()} (${
          data.length
        })`}</DayTitle>
      </DayStrip>
    ),
    []
  );

  return (
    <Container>
      <SectionList
        contentContainerStyle={{ flexGrow: 1 }}
        sections={filteredSwap}
        refreshing={isLoading}
        ListEmptyComponent={
          <EmptyState
            hide={isLoading}
            lottieSrc={lotties.noUpcomingShifts}
            title={t('emptyStateBookings')}
          />
        }
        refreshControl={
          <RefreshControl
            onRefresh={refetchSwapEmployees}
            refreshing={isLoading}
          />
        }
        keyExtractor={(props) => props.id.toString()}
        renderItem={({ item }) => <SwapListRow item={item} />}
        ItemSeparatorComponent={() => <Separator />}
        renderSectionHeader={renderSectionHeader}
      />
    </Container>
  );
};
