export enum OfferStatusFilterOptions {
  ALL = 'allOffersFilter',
  OPEN = 'openOffers',
  PENDING = 'pendingOffers',
  REJECTED = 'rejectedOffers',
}

export enum OfferApplicationType {
  PER_SHIFT = 1,
  PER_PROJECT = 2,
}

export const EMPTY_POSITION_ID = -1;
