import { CustomTimesheetDTO } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  FlexColumnDirection,
  H4Bold,
  H5Regular,
  HyperLink,
} from '@violetta/ubeya/ui';
import React, { FC } from 'react';
import styled from 'styled-components/native';

const Container = styled(FlexColumnDirection)`
  width: 100%;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
  border-bottom-width: 1px;
  padding: 0 15px;
`;

const ColumnDetail = styled(FlexColumnDirection)`
  margin-bottom: 7px;
  margin-top: 7px;
`;

const DataTitle = styled(H4Bold)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
  margin-bottom: 4px;
`;
const DataDetails = styled(H5Regular)`
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.textPrimary};
  max-width: 80%;
`;

interface Props {
  timesheet: CustomTimesheetDTO;
}

export const TimesheetDetails: FC<Props> = ({ timesheet }) => {
  const { t } = useTranslation();
  const generalComments = timesheet?.shift?.project?.comments;
  const comments = timesheet?.shift?.comments;
  if (!generalComments && !comments) {
    return null;
  }

  return (
    <Container>
      {!!comments && (
        <ColumnDetail>
          <DataTitle>{t('notes')}</DataTitle>
          <HyperLink>
            <DataDetails>{comments}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}

      {!!generalComments && (
        <ColumnDetail>
          <DataTitle>{t('generalComments')}</DataTitle>
          <HyperLink>
            <DataDetails>{generalComments}</DataDetails>
          </HyperLink>
        </ColumnDetail>
      )}
    </Container>
  );
};
