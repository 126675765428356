import React, { FC, useContext } from 'react';
import moment from 'moment';
import { CalendarList, LocaleConfig } from 'react-native-calendars';
import Collapsible from 'react-native-collapsible';
import { useRecoilState } from 'recoil';
import { useTheme } from 'styled-components/native';
import { ConfigContext } from '../../../contexts/ConfigContext';
import { isOfferCalendarOpenAtom, offerCalendarMonthAtom } from '../../../state';

LocaleConfig.locales.en = {
  monthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  monthNamesShort: ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
  dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednsday', 'Thursday', 'Friday', 'Saturday'],
  dayNamesShort: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  today: 'Today',
};
LocaleConfig.defaultLocale = 'en';

interface Props {
  dates: any;
  handleSelectDate: any;
}

export const OfferCalendar: FC<Props> = ({ dates, handleSelectDate }) => {
  const [, setOfferCalendarMonth] = useRecoilState(offerCalendarMonthAtom);
  const [isOfferCalendarOpen] = useRecoilState(isOfferCalendarOpenAtom);
  const { colors } = useTheme();
  const { firstDayOfWeek } = useContext(ConfigContext);

  return (
    <Collapsible collapsed={!isOfferCalendarOpen}>
      <CalendarList
        key={1} // to re-render un year change
        theme={{
          textDayFontSize: 13,
          textDayStyle: {
            paddingTop: 2,
          },
          dayTextColor: colors.textPrimary,
          todayTextColor: '#ffffff',
          textDayFontWeight: 'bold',
          textDisabledColor: colors.gray18,
          selectedDayBackgroundColor: colors.primaryLight5,
          selectedDayTextColor: colors.textPrimary,
          monthTextColor: colors.gray12,
        }}
        disableAllTouchEventsForDisabledDays
        monthFormat="MMMM yyyy"
        markedDates={dates}
        disabledByDefault
        onDayPress={handleSelectDate}
        hideArrows
        horizontal
        pagingEnabled
        calendarHeight={270}
        scrollEnabled
        hideExtraDays
        onVisibleMonthsChange={(date) => setOfferCalendarMonth(moment(date[0].dateString).format('MMM YYYY'))}
        firstDay={firstDayOfWeek || 1}
        renderHeader={() => null}
        disableArrowLeft
        disableArrowRight
      />
    </Collapsible>
  );
};
