import React, { FC, useMemo } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  Button,
  FlexColumnDirection,
  FlexRowBetween,
  H1Light,
  H5Regular,
} from '@violetta/ubeya/ui';
import { useDuration } from '@violetta/ubeya/utils';
import momentTz from 'moment-timezone';
import styled, { useTheme } from 'styled-components/native';
import { CustomTimesheetDTO } from '@violetta/ubeya/entities';

const Container = styled(FlexColumnDirection)``;

const StartedAtTitle = styled(H5Regular)`
  text-align: left;
  font-weight: normal;
`;

const Clock = styled(H1Light)`
  margin-top: 5px;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 1.3px;
  line-height: 36px;
  color: ${({ color }) => color};
`;

export interface ActiveTimeclockProps {
  timesheet: CustomTimesheetDTO;
  reportedStartTime: Date;
  onCardPress?(): void;
  activeBreak: any;
  toRequireCheckInWithMobile?: boolean;
}

export const ActiveTimeclockCardBody: FC<ActiveTimeclockProps> = ({
  reportedStartTime,
  onCardPress,
  activeBreak,
  timesheet,
  toRequireCheckInWithMobile,
}) => {
  const { clock } = useDuration(reportedStartTime);
  const { t } = useTranslation();
  const { colors } = useTheme();
  const startedAt = useMemo(
    () => momentTz(reportedStartTime).format('LT'),
    [reportedStartTime]
  );

  const clockSlug = (() => {
    if (activeBreak) {
      return 'endBreak';
    }
    if (toRequireCheckInWithMobile) {
      if (!timesheet.checkInTime) {
        return 'timesheetCheckIn';
      }
      if (!timesheet.checkOutTime) {
        return 'timesheetCheckOut';
      }
    }
    return 'clockOut';
  })();

  return (
    <Container>
      {/* <FlexRowCenter>
					<GreenCircle />
					<ActiveShiftTitle>{t("activeShift")}</ActiveShiftTitle>
				</FlexRowCenter> */}
      <StartedAtTitle>
        {`${activeBreak ? t('breakStarted') : t('shiftStarted')} - `}
        {startedAt}
      </StartedAtTitle>
      <FlexRowBetween>
        <Clock color={activeBreak ? colors.primary : colors.green3}>
          {clock}
        </Clock>
        <Button
          narrow
          type="primary"
          title={t(clockSlug)}
          onPress={onCardPress}
        />
      </FlexRowBetween>
    </Container>
  );
};
