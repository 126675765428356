import React, { FC } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { BaseView, BaseText } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const Container = styled(BaseView)`
  margin: 0 20px;
`;

const Title = styled(BaseText)`
  font-size: 20px;
  font-weight: 600;
`;

export const FieldHeaderTitle: FC<StackHeaderProps> = (props) => {
  const params = props?.route?.params || {};
  const title = params?.title;

  return (
    <Container>
      <Title>{title}</Title>
    </Container>
  );
};
