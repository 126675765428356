import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { useTranslation } from '@violetta/ubeya/i18n';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';

const selector = createCachedSelector(
  (data) => data?.data,
  (data, t) => t,
  (data, t) => {
    const reasons = data.map((reason) => ({
      id: reason.id,
      label: t(reason.slug),
      value: t(reason.slug),
    }));

    const mappedReasons = mappedArray(reasons);

    return { reasons, mappedReasons };
  }
)({
  keySelector: (data, t, storeKey) => storeKey,
});

export const useRequestReasons = () => {
  const storeKey = useMemo(() => ['requestReasons'], []);
  const { t } = useTranslation();
  const { data: userData } = useUser();

  const { isPending, data, refetch } = useQuery({
    queryKey: storeKey,
    queryFn: () => api.getShiftActionReasons(),
    enabled: !!userData?.id,
    select: (data) => selector(data, t, storeKey.join('#')),
  });

  const { reasons = [], mappedReasons = {} } = data || {};

  return {
    requestReasons: reasons,
    mappedReasons,
    isLoading: isPending,
    refetch,
  };
};
