import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  FlexRow,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { QrCodeScannerScreenNames } from '../../qr-code-scanner/navigation/ScreenNames';
import { icons } from '@violetta/ubeya/assets';

const Container = styled(FlexRow)`
  padding-right: 16px;
`;

const Text = styled(BaseText)`
  font-size: 18px;
  font-weight: bold;
`;

export const CaptainHeaderRightContent = () => {
  const { t } = useTranslation();
  const { navigate } = useNavigation();
  const handleNavigateToScannerScreen = useCallback(() => {
    navigate(QrCodeScannerScreenNames.MAIN, {
      screen: QrCodeScannerScreenNames.SCANNER,
    });
  }, [navigate]);

  return (
    <Container as={RipplePressable} onPress={handleNavigateToScannerScreen}>
      {/* <Text>{t('qrScan')}</Text> */}
      <SvgIcon xml={icons.uiIcons.QRIcon} color="#000" width={40} height={40} />
    </Container>
  );
};
