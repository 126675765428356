import { useNetInfo } from '@react-native-community/netinfo';
import { experimental_createPersister } from '@tanstack/react-query-persist-client';
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import {
  CustomDTO,
  PickPrimitiveKeys,
  addOfflineMutation,
  mappedArray,
  useOptimisticMutation,
} from '@violetta/ubeya/utils';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { SetterOrUpdater } from 'recoil';
import { IShift, IUser } from '../entities';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { API_DATE_FORMAT } from '@violetta/ubeya/shared';
import AsyncStorage from '@react-native-async-storage/async-storage';

type Booking = CustomDTO<IShift, PickPrimitiveKeys<IShift>> & {
  employees: {
    employeeId: number;
    startTime: Date | null;
    endTime: Date | null;
    rating: number;
  }[];
};

export type Employee = CustomDTO<
  IUser,
  'id' | 'firstName' | 'lastName' | 'image' | 'phone'
> & { shiftId: number };

type ResponseType = {
  data: {
    shifts: Booking[];
    employees: Employee[];
    project?: {
      id: number;
      labelId?: number;
      date: Date;
    };
  };
};

export enum CAPTAIN_TYPE_ENUM {
  EVENT = 1,
  SHIFT = 2,
  AREA = 3,
}

const uniqueUnsycnedtimesheetId = (storeKey: string[], employeeId: number) =>
  `captain-${storeKey.join('-')}-${employeeId}`;

export const captainQueryFn = ({
  projectId,
  date,
}: {
  projectId: number;
  date?: Date;
}) => {
  const formattedDate = moment(date).format(API_DATE_FORMAT);

  return api.getCaptain({ projectId, date: formattedDate });
};

export const captainSelector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const team = data || {
      shifts: [],
      employees: [],
      project: {},
      orders: [],
      config: {},
    };

    const mappedEmployees = mappedArray(team.employees) as {
      [key: number]: Employee;
    };

    return { team, mappedEmployees };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

const persister = experimental_createPersister({
  storage: AsyncStorage,
  maxAge: 1000 * 60 * 60 * 72, // 72 hours,
});

type CaptainProps = {
  projectId: number;
  date?: Date;
};

const storeKeyCreator = (projectId: number, date?: Date) => [
  'captain',
  String(projectId),
  moment(date).format(API_DATE_FORMAT),
];

export const prefetchCaptainOffline = ({
  projectId,
  date,
  queryClient,
}: CaptainProps & { queryClient: QueryClient }) => {
  const storeKey = storeKeyCreator(projectId, date);

  queryClient.prefetchQuery({
    persister: persister.persisterFn,
    queryKey: storeKey,
    queryFn: () => captainQueryFn({ projectId, date }),
  });
};

export const useCaptain = ({
  projectId,
  unsyncedTimesheetsState,
  date,
}: CaptainProps & {
  unsyncedTimesheetsState?: [Set<string>, SetterOrUpdater<Set<string>>];
}) => {
  const { data: userData } = useUser();
  const queryClient = useQueryClient();
  const { isConnected } = useNetInfo();

  const [, setUnsyncedTimesheetsState] = unsyncedTimesheetsState || [];

  const storeKey = storeKeyCreator(projectId, date);

  const { isFetched, isPending, data, refetch } = useQuery<ResponseType>({
    persister: persister.persisterFn,
    queryKey: storeKey,
    queryFn: () => captainQueryFn({ projectId, date }),
    enabled: !!userData?.id && !!projectId,
    select: (data) => captainSelector(data, storeKey.join('#')),
    // refetchInterval: 5000,
    // refetchIntervalInBackground: false,
  });

  const { team = {}, mappedEmployees = {}, config = {} } = data || {};

  const { mutateAsync: updateShiftEmployee, isPending: isUpdatingEmployee } =
    useOptimisticMutation(
      storeKey,
      ({
        employeeId,
        projectId,
        shiftId,
        values,
        delta,
      }: {
        projectId: number;
        shiftId: number;
        employeeId: number;
        values: {
          startTime?: Date | string;
          endTime?: Date | string;
          rating?: number;
        };
        delta?: number;
      }) =>
        api.updateCaptainShiftEmployee({
          employeeId,
          projectId,
          shiftId,
          values,
          delta,
        }),
      ({ previousData, payload }) => {
        const now = moment().toDate();

        if (!isConnected) {
          addOfflineMutation({
            mutation: 'updateCaptainShiftEmployee',
            params: {
              employeeId: payload.employeeId,
              date,
              projectId: payload.projectId,
              shiftId: payload.shiftId,
              values: payload.values,
            },
            timeRequested: now,
          });
        }

        const {
          data: { shifts, ...rest },
        } = previousData;

        const newShifts = shifts.map((shift: any) =>
          shift.id === payload.shiftId
            ? {
                ...shift,
                employees: (shift.employees || []).map((employee: any) =>
                  employee.employeeId === payload.employeeId
                    ? { ...employee, ...payload.values }
                    : employee
                ),
              }
            : shift
        );

        const nextData = {
          data: { ...rest, shifts: newShifts },
        };

        // HACK to have setQueryData save on the persister
        queryClient.setQueryData(storeKey, nextData);
        const query = queryClient.getQueryCache().find({ queryKey: storeKey });
        persister.save(query);

        // update number of unfinished (ongoing) timesheets
        setUnsyncedTimesheetsState?.((prev) => {
          const newSet = new Set(prev);
          const key = uniqueUnsycnedtimesheetId(storeKey, payload.employeeId);
          newSet.add(key);
          return newSet;
        });
        return nextData;
      },
      {
        onSuccess: (newData, prevData, variables) => {
          queryClient.invalidateQueries({ queryKey: storeKey });
        },
        onSettled: (newData, prevData, variables) => {
          setUnsyncedTimesheetsState?.((prev) => {
            const newSet = new Set(prev);
            const key = uniqueUnsycnedtimesheetId(
              storeKey,
              variables.employeeId
            );
            newSet.delete(key);
            return newSet;
          });
        },
        shouldRollbackOnError: false,
        // mutateOnlyOnSuccess: isConnected,
      }
    );

  const {
    mutateAsync: updateOSShiftEmployee,
    isPending: isUpdatingOSEmployee,
  } = useOptimisticMutation(
    storeKey,
    ({
      employeeId,
      projectId,
      shiftId,
      values,
      delta,
    }: {
      projectId: number;
      shiftId: number;
      employeeId: number;
      values: { startTime?: Date; endTime?: Date; rating?: number };
      delta?: number;
    }) =>
      api.updateCaptainOSShiftEmployee({
        employeeId,
        projectId,
        shiftId,
        values,
        delta,
      }),
    ({ previousData, payload }) => {
      const now = moment().toDate();

      if (!isConnected) {
        addOfflineMutation({
          mutation: 'updateCaptainOSShiftEmployee',
          params: {
            employeeId: payload.employeeId,
            projectId: payload.projectId,
            shiftId: payload.shiftId,
            values: payload.values,
          },
          timeRequested: now,
        });
      }

      const {
        data: { orders, ...rest },
      } = previousData;

      const newOrders = orders.map((order: any) => ({
        ...order,
        shifts: (order.shifts || []).map((shift: any) =>
          shift.id === payload.shiftId
            ? {
                ...shift,
                employees: (shift.employees || []).map((employee: any) =>
                  employee.employeeId === payload.employeeId
                    ? { ...employee, ...payload.values }
                    : employee
                ),
              }
            : shift
        ),
      }));

      const nextData = {
        data: { ...rest, orders: newOrders },
      };

      // HACK to have setQueryData save on the persister
      queryClient.setQueryData(storeKey, nextData);
      const query = queryClient.getQueryCache().find({ queryKey: storeKey });
      persister.save(query);

      // update number of unfinished (ongoing) timesheets
      setUnsyncedTimesheetsState?.((prev) => {
        const newSet = new Set(prev);
        const key = uniqueUnsycnedtimesheetId(storeKey, payload.employeeId);
        newSet.add(key);
        return newSet;
      });
      return nextData;
    },
    {
      onSuccess: (newData, prevData, variables) => {
        queryClient.invalidateQueries({ queryKey: storeKey });
      },
      onSettled: (newData, prevData, variables) => {
        setUnsyncedTimesheetsState?.((prev) => {
          const newSet = new Set(prev);
          const key = uniqueUnsycnedtimesheetId(storeKey, variables.employeeId);
          newSet.delete(key);
          return newSet;
        });
      },
      shouldRollbackOnError: false,
      // mutateOnlyOnSuccess: isConnected,
    }
  );

  const {
    mutateAsync: updateCaptainProject,
    isPending: isUpdatingCaptainProject,
  } = useMutation({
    mutationFn: ({
      projectId,
      values,
    }: {
      projectId: number;
      values: {
        managerRating?: number;
        managerClientFeedback?: string;
        managerNotes?: string;
        managerIssues?: string;
      };
    }) => api.updateCaptainProject({ projectId, values }),
    // onSuccess: () => {
    //   queryClient.invalidateQueries({ queryKey: storeKey });
    // },
  });

  return {
    isLoading: isPending,
    team,
    config,
    mappedEmployees,
    updateCaptainProject,
    isUpdatingCaptainProject,
    updateShiftEmployee,
    updateOSShiftEmployee,
    isUpdatingOSEmployee,
    isUpdatingEmployee,
    isFetched,
    refetch,
  };
};
