import React, { FC } from 'react';
import styled from 'styled-components/native';
import { BaseView, FlexRow } from '../Layout';
import { H7Medium } from '../Typography';

const Container = styled(FlexRow)``;

const CommonProgress = styled(BaseView)<{ percentage: number; showPercentage: boolean }>`
  ${({ showPercentage }) => !showPercentage && 'height: 6px'};
  border-radius: ${({ showPercentage }) => (showPercentage ? '10px' : '3px')};
`;

const Progress = styled(CommonProgress)<{ percentage: number; showPercentage: boolean }>`
  ${({ percentage }) => percentage < 1 && `border-bottom-right-radius: ${0};`}
  ${({ percentage }) => percentage < 1 && `border-top-right-radius: ${0};`}
  background-color: ${({ theme }) => theme.colors.primary};
  flex: ${({ percentage }) => Math.ceil(percentage * 100)};
`;

const Other = styled(CommonProgress)<{ percentage: number; showPercentage: boolean }>`
  border-bottom-left-radius: ${({ percentage }) => (percentage === 0 ? '3px' : '0')};
  border-top-left-radius: ${({ percentage }) => (percentage === 0 ? '3px' : '0')};
  background-color: ${({ theme }) => theme.colors.gray3};
  flex: ${({ percentage }) => Math.ceil((1 - percentage) * 100)};
`;

const Percentage = styled(H7Medium)`
  color: ${({ theme }) => theme.colors.gray9};
  margin-left: 5px;
  font-weight: 500;
`;

interface Props {
  percentage: number;
  showPercentage?: boolean;
}

export const ProgressBar: FC<Props> = ({ percentage, showPercentage = false }) => (
  <Container>
    <Progress percentage={percentage} showPercentage={showPercentage}>
      {showPercentage && <Percentage>{Math.ceil(percentage * 100)}%</Percentage>}
    </Progress>
    <Other percentage={percentage} showPercentage={showPercentage} />
  </Container>
);
