import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import { useLocale, useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  FlexColumnDirection,
  FlexRowCenter,
  H1Medium,
  H4Regular,
  H6Regular,
  Link,
  SvgIcon,
  TextInput,
} from '@violetta/ubeya/ui';
import { useInterval } from '@violetta/ubeya/utils';
import { Alert } from '@violetta/ubeya/alert';
import {
  I18nManager,
  InteractionManager,
  PixelRatio,
  Platform,
  ScrollView,
  TextInput as OriginalTextInput,
  useWindowDimensions,
} from 'react-native';
import styled from 'styled-components/native';
import { CodeDigit } from '../components';

interface Props {}
const SITE_KEY = '6LelDjMaAAAAAORDlqHORcXkmPV62Gqms9087gh5';

const Container = styled(BaseView)`
  padding: 30px 20px;
  flex: 1;
`;

const DigitsContainer = styled(FlexRowCenter)``;
const ButtonContainer = styled(BaseView)`
  margin-top: -35px;
`;
const FillSpace = styled(BaseView)`
  flex: 1;
`;
const HiddenContainer = styled(FlexRowCenter)`
  opacity: 0;
`;
const SendAgainContainer = styled(FlexRowCenter)`
  margin-top: 20px;
  align-items: center;
  justify-content: center;
`;

const SendAgainText = styled(H6Regular)`
  margin-right: 4px;
`;
const SendAgainLink = styled(H6Regular)`
  text-decoration: underline;
  text-decoration-color: #3ad4e0;
  color: #6467ef;
`;

const HeaderContainer = styled(BaseView)`
  flex-direction: column;
  align-items: center;
  display: flex;
`;
const ContentContainer = styled(FlexColumnDirection)`
  margin: 10px 0;
`;

const HeaderTitle = styled(H1Medium)`
  font-weight: 500;
`;

const DescriptionTitle = styled(H4Regular)`
  margin-top: 15px;
  text-align: center;
  line-height: 28px;
  margin-bottom: 10px;
`;
const IconContainer = styled(BaseView)`
  margin-top: -10px;
`;

const SafeView = styled.SafeAreaView`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const VerificationIcon = styled(BaseView)`
  margin: 5px 0;
`;

type ParamList = {
  params: {
    phone: string;
    recaptchaToken: string;
  };
};

const ACTIVATION_CODE_LENGTH = 5;

export const ActivationCodeScreen: FC<Props> = () => {
  const rtl = I18nManager.isRTL;
  const { params } = useRoute<RouteProp<ParamList, 'params'>>();
  const { phone } = params || {};
  const input = useRef<OriginalTextInput>();
  const { locale } = useLocale();

  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [timerSeconds, setTimerSeconds] = useState(30);
  const { start, stop } = useInterval(() => {
    if (timerSeconds > 0) {
      setTimerSeconds(timerSeconds - 1);
    } else {
      stop();
    }
  }, 1000);
  const [code, setCode] = useState('');
  const windowHeight = useWindowDimensions().height;
  const [digits, setDigits] = useState(() =>
    [...new Array(ACTIVATION_CODE_LENGTH)].fill('')
  );
  const { login } = useUser({ enabled: false });
  const { t } = useTranslation();
  const { goBack } = useNavigation();

  const reset = useCallback(() => {
    setCode('');
    setDigits([...new Array(ACTIVATION_CODE_LENGTH)].fill(''));
    input.current?.clear();
  }, []);

  const onChange = useCallback(async (newCode: string) => {
    const splitted = newCode.split('');

    const result = [...new Array(ACTIVATION_CODE_LENGTH)].map(
      (_, idx) => splitted[idx] || ''
    );
    setCode(newCode);
    setDigits(result);
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      setIsLoadingLogin(true);
      await login({ phone, code });
    } catch (e) {
      console.error(e);
      Alert.alert(
        t('incorrectCode'),
        undefined,
        [{ text: t('ok'), onPress: reset, type: 'confirm' }],
        {
          cancelable: false,
        }
      );
    } finally {
      setIsLoadingLogin(false);
    }
  }, [login, phone, code, t, reset]);

  const focus = useCallback(() => {
    InteractionManager.runAfterInteractions(() => {
      if (input.current) {
        input.current.blur();
        input.current.focus();
      }
    });
  }, [input]);

  useEffect(() => {
    if (code.length === ACTIVATION_CODE_LENGTH) {
      onSubmit();
    }
  }, [code, onSubmit]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      // delay when loading in web
      setTimeout(() => input?.current?.focus(), 300);
    }
    start();
  }, [start]);

  const showSVG = useMemo(
    () => PixelRatio.getFontScale() <= 1 && windowHeight > 800,
    [windowHeight]
  );

  return (
    <SafeView>
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        keyboardShouldPersistTaps="always"
      >
        <Container>
          <HeaderContainer>
            <HeaderTitle>{t('verification')}</HeaderTitle>
            <DescriptionTitle>
              {t('sentSMSCode')} +{phone}
            </DescriptionTitle>
            <IconContainer>
              {showSVG && (
                <VerificationIcon>
                  <SvgIcon
                    xml={icons.uiIcons.verification}
                    width={65}
                    height={65}
                    color="#000000"
                    defaultViewBox={false}
                  />
                </VerificationIcon>
              )}
            </IconContainer>
          </HeaderContainer>
          <ContentContainer>
            <DigitsContainer rtl={rtl}>
              {digits.map((digit, idx) => (
                <CodeDigit key={idx} digit={digit} onPress={focus} />
              ))}
            </DigitsContainer>
            <SendAgainContainer>
              {locale !== 'de' && (
                <>
                  {timerSeconds > 0 ? (
                    <>
                      <SendAgainText>
                        {t('youShouldReceiveACodeIn')}
                      </SendAgainText>
                      <SendAgainText>
                        {timerSeconds} {t('seconds')}
                      </SendAgainText>
                    </>
                  ) : (
                    <Link component={SendAgainText} onPress={goBack}>
                      <SendAgainLink>{t('sendAgain')}</SendAgainLink>
                    </Link>
                  )}
                </>
              )}
            </SendAgainContainer>
            <HiddenContainer>
              <TextInput
                ref={input}
                keyboardType="number-pad"
                autoFocus
                editable
                onChangeText={onChange}
                maxLength={ACTIVATION_CODE_LENGTH}
              />
            </HiddenContainer>
          </ContentContainer>
          <ButtonContainer>
            <Button
              type="primary"
              title={t('activate')}
              onPress={onSubmit}
              isLoading={isLoadingLogin}
              disabled={code.length !== 5}
            />
          </ButtonContainer>
          <FillSpace />
        </Container>
      </ScrollView>
    </SafeView>
  );
};
