import React, { FC } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { ChevronLeft } from '../Icons/ChevronLeft';
import { BaseView } from '../Layout';

const BackContainer = styled(BaseView)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  rtl: boolean;
  onPress?: () => void;
  size?: number;
}

export const BackButton: FC<Props> = ({ rtl, onPress, size = 30 }) => (
  <BackContainer as={Pressable} onPress={onPress}>
    <ChevronLeft rtl={rtl} size={size} />
  </BackContainer>
);
