import React, { FC, useContext, useMemo } from 'react';
import {
  IBranch,
  ILocation,
  IOffer,
  offerTimeRange,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  AccountImage,
  BaseView,
  Card,
  FlexColumnDirection,
  FlexRow,
  H5Light,
  H6Regular,
  H7Medium,
  H7Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import moment from 'moment';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts/ConfigContext';

export interface Shift {
  id: number;
  positionId: number;
  labelId: number | null;
  startTime: Date;
  endTime: Date;
}

const Container = styled(Card)`
  margin: 7px 0;
  padding: 10px 10px;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
`;

const Top = styled(BaseView)`
  position: absolute;
  right: 10px;
  top: 7px;
  color: ${({ theme }) => theme.colors.gray1};
`;

const ShiftDetails = styled(FlexColumnDirection)`
  align-items: flex-start;
  margin-left: 15px;
`;

const ShiftName = styled(H5Light)`
  font-weight: 400;
  font-size: 15px;
`;

const BranchName = styled(H5Light)`
  font-weight: 400;
  padding-bottom: 2px;
`;

const Address = styled(H6Regular)`
  font-size: 12px;
  font-weight: 400;
  margin-right: 20px;
  padding: 5px 0;
  color: ${({ theme }) => theme.colors.gray2};
`;
const LightHours = styled(H6Regular)`
  font-size: 12px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.gray2};
`;

const Date = styled(H7Regular)`
  color: ${({ theme }) => theme.colors.gray1};
  text-align: right;
`;
const Status = styled(H7Medium)`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.surface};
  padding: 2px 3px;
  border-radius: 5px;
`;
const StatusSuccess = styled(Status)`
  background-color: ${({ theme }) => theme.colors.primary};
`;
const StatusReject = styled(Status)`
  background-color: ${({ theme }) => theme.colors.error};
`;

const NewChip = styled(BaseView)`
  margin-top: 8px;
  background-color: ${({ theme }) => theme.colors.red1};
  padding: 3px 5px;
  border-radius: 6px;
`;

const NewChipText = styled(H7Medium)`
  color: ${({ theme }) => theme.colors.surface};
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;

const Body = styled(FlexRow)``;
// flex-wrap: wrap;
//   flex: 1;
interface Props {
  offer: IOffer;
  onPress?: () => void;
  mappedBranches: { [key: number]: IBranch };
  mappedLocations: { [key: number]: ILocation };
}

export const OfferCard: FC<Props> = React.memo(
  ({ offer, onPress, mappedBranches, mappedLocations }) => {
    const { branchId, date: projectDate, locationId, compoundedName } = offer;
    const { rtl } = useContext(ConfigContext);
    const { t } = useTranslation();

    const branch = useMemo(
      () => mappedBranches[branchId],
      [branchId, mappedBranches]
    );

    const { day, projectOfferDate } = useMemo(() => {
      const projectDateMoment = moment(projectDate, 'YYYY-MM-DD');
      return {
        day: rtl
          ? projectDateMoment.format('dddd')
          : projectDateMoment.format('ddd'),
        projectOfferDate: projectDateMoment
          .format('l')
          .split('/')
          ?.splice(0, 2)
          ?.join('/'),
      };
    }, [projectDate, rtl]);

    const { start, end } = useMemo(() => offerTimeRange(offer), [offer]);
    const formattedHours = useMemo(() => rangeFormat(start, end), [start, end]);
    const seen = useMemo(
      () => offer.shifts.some(({ seen }) => !!seen),
      [offer.shifts]
    );

    if (!branch) {
      return null;
    }

    return (
      <Container as={RipplePressable} onPress={() => onPress(offer)}>
        <Top>
          <Date>
            {day}, {projectOfferDate}
          </Date>
        </Top>
        <Body>
          <AccountImage image={branch?.logo} name={branch?.name} size={40} />
          <ShiftDetails>
            <BranchName>{mappedBranches[branchId]?.name}</BranchName>
            <ShiftName>{compoundedName}</ShiftName>
            {locationId && mappedLocations[locationId] && (
              <Address>{mappedLocations[locationId]?.address}</Address>
            )}
            {!!formattedHours && <LightHours>{formattedHours}</LightHours>}
            {offer.didApply && <StatusSuccess>{t('pending')}</StatusSuccess>}
            {offer.didReject && <StatusReject>{t('rejected')}</StatusReject>}
            {!seen && (
              <NewChip>
                <NewChipText>{t('new')}</NewChipText>
              </NewChip>
            )}
          </ShiftDetails>
        </Body>
      </Container>
    );
  }
);
