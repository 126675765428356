import React, { FC, useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { Sequence } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FlexColumnDirection,
  H5Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { SequencesScreenNames } from '../../../sequences';
import { HomeCard } from '../HomeCard';
import { SequencesCardRow } from './SequencesCardRow';

interface Props {
  sequences: Sequence[];
  total: number;
}

const Container = styled(FlexColumnDirection)``;

const ShiftContainer = styled(FlexColumnDirection)`
  padding: 20px 0;
`;

const StripLine = styled(BaseView)`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.gray3};
`;

const EmptyStateTitle = styled(H5Regular)`
  text-align: center;
`;

export const SequencesCard: FC<Props> = ({ sequences, total }) => {
  const { t } = useTranslation();

  const { navigate } = useNavigation();

  const mappedSequences = useMemo(
    () =>
      sequences.reduce<{ [key: number]: Sequence }>(
        (carry, sequence) => ({ ...carry, [sequence.id]: sequence }),
        {}
      ),
    [sequences]
  );

  const navigateSequence = useCallback(
    (sequenceId: number, instanceId: number) => {
      navigate(SequencesScreenNames.MAIN, {
        screen: SequencesScreenNames.SEQUENCE,
        params: { sequenceId, instanceId },
      });
    },
    [navigate]
  );

  const instances = useMemo(
    () =>
      sequences
        .map(({ id, instances }) =>
          instances.map((instanceId) => ({
            id,
            instanceId,
          }))
        )
        .reduce((carry, item) => [...carry, ...item], []),
    [sequences]
  );

  const titleCount = useMemo(
    () => (total > 0 ? '(' + total + ')' : ''),
    [total]
  );

  return (
    <HomeCard
      title={`${t('sequences')} ${titleCount}`}
      body={() => (
        <Container>
          {sequences.length === 0 && (
            <EmptyStateTitle>{t('emptyStateSequence')}</EmptyStateTitle>
          )}
          {instances.map(({ id: sequenceId, instanceId }, idx) => (
            <BaseView
              as={RipplePressable}
              key={idx}
              onPress={() => navigateSequence(sequenceId, instanceId)}
            >
              <ShiftContainer>
                <SequencesCardRow sequence={mappedSequences[sequenceId]} />
              </ShiftContainer>
              {idx !== instances.length - 1 && <StripLine />}
            </BaseView>
          ))}
        </Container>
      )}
    />
  );
};
