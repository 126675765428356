import React, { FC } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { icons } from '@violetta/ubeya/assets';
import { selectedUserChatRoom } from '@violetta/ubeya/shared';
import {
  FlexRowBetween,
  H3Medium,
  SvgIcon,
  BaseView,
  FlexRow,
} from '@violetta/ubeya/ui';
import { Image as BaseImage } from 'react-native';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components/native';

const Container = styled(FlexRow)`
  flex: 1;
  margin: 0 30px;
  align-items: center;
  justify-content: center;
`;
const Title = styled(H3Medium)`
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.32px;
`;

const ImageContainer = styled(BaseView)`
  margin-right: 14px;
`;

const Image = styled(BaseImage)`
  width: 38px;
  height: 38px;
  border-radius: 19px;
`;

export const ChatRoomHeaderTitle: FC<StackHeaderProps> = () => {
  const selectedChatRoom = useRecoilValue(selectedUserChatRoom);

  return (
    <Container>
      <ImageContainer>
        {selectedChatRoom?.image ? (
          <Image source={{ uri: selectedChatRoom.image }} />
        ) : (
          <SvgIcon
            xml={icons.uiIcons.employeePlaceholder}
            width={38}
            height={38}
          />
        )}
      </ImageContainer>
      <Title>{selectedChatRoom?.name}</Title>
    </Container>
  );
};
