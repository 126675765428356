import React, { FC, useCallback, useMemo, useState } from 'react';
import { RouteProp, useRoute } from '@react-navigation/native';
import { api } from '@violetta/ubeya/api';
import { icons } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import { useAccounts } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  FlexColumnDirection,
  FlexRow,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { pickImg, uiAlert } from '@violetta/ubeya/utils';
import { Image, RefreshControl, ScrollView } from 'react-native';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/native';
import { Pressable } from 'react-native';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  padding: 30px 10px;
`;

const Photos = styled(FlexRow)`
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;
const Photo = styled.Image`
  width: 100px;
  height: 100px;
  margin: 20px 10px;
`;

const ButtonContainer = styled(BaseView)`
  margin: 10px 40px;
`;

export const LargeProfileImage = styled(Image)`
  height: 120px;
  width: 120px;
  border-width: 3px;
  border-color: ${({ theme }) => theme.colors.surface};
  border-radius: 60px;
`;

const ImageContainer = styled(BaseView)`
  position: relative;
`;

const Gallery = styled(BaseView)`
  padding: 30px 0;
`;

const RemoveChip = styled(BaseView)`
  width: 25px;
  height: 25px;
  border-radius: 13px;
  position: absolute;
  right: -7px;
  top: 7px;
  align-items: center;
  justify-content: center;
`;

interface Props {}

type ParamList = {
  params: {
    accountId: number;
  };
};

export const AccountGalleryScreen: FC<Props> = () => {
  const {
    params: { accountId },
  } = useRoute<RouteProp<ParamList, 'params'>>();

  const queryClient = useQueryClient();

  const { t } = useTranslation();

  const { mappedAccounts, refetch, isLoading } = useAccounts();

  const { data } = useUser();

  const account = useMemo(
    () => mappedAccounts[accountId],
    [mappedAccounts, accountId]
  );
  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(null);
  const [uploadedProfileImage, setUploadedProfileImage] = useState<
    string | null
  >(null);

  const image = useMemo(
    () => uploadedProfileImage || data?.image,
    [uploadedProfileImage, data]
  );
  const { mutateAsync: uploadPhoto } = useMutation({
    mutationFn: ({ base64, type }: { base64: string; type: string }) =>
      api.uploadGalleryPhoto({ accountId, image: base64, type }),
  });

  const { mutateAsync: deletePhoto } = useMutation({
    mutationFn: ({ id }: { id: number }) =>
      api.deleteGalleryPhotos(accountId, id),
  });

  const onDeletePhoto = useCallback(
    async (id) => {
      const res = await uiAlert({
        title: t('areYouSureYouWishToDeletePhoto'),
        cancel: {
          text: t('no'),
        },
        save: {
          text: t('yes'),
        },
      });

      if (!res) {
        return;
      }

      await deletePhoto({ id });

      await queryClient.invalidateQueries({ queryKey: ['accounts'] });
    },
    [deletePhoto, queryClient, t]
  );

  const photoSelected = useCallback(async () => {
    const assets = await pickImg({
      texts: {
        cancel: t('cancel'),
        choosePhoto: t('choosePhoto'),
        takeAPhoto: t('takePhoto'),
        title: t('chooseImageUploadMethod'),
      },
      image: {
        includeBase64: true,
        mediaType: 'photo',
        maxWidth: 1000,
        maxHeight: 1000,
        quality: 0.7,
      },
    });

    if (!assets) {
      return;
    }
    const { base64, type } = assets?.[0] || {};
    if (!base64 || !type) {
      return;
    }

    setIsUploadingImage(true);
    await uploadPhoto({ base64, type });
    await queryClient.invalidateQueries({ queryKey: ['accounts'] });
    setIsUploadingImage(false);
  }, [queryClient, t, uploadPhoto]);

  if (!account) {
    return null;
  }

  // if ((account.photos || []).length === 0) {
  //   return <EmptyState />;
  // }

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      refreshControl={
        <RefreshControl onRefresh={refetch} refreshing={isLoading} />
      }
    >
      <Container>
        {/* <Title>Profile Photo</Title> */}
        {/* {!isLoadingProfilePhoto && !!image && (
          <ImagePressable as={Pressable} onPress={photoProfileSelected}>
            <LargeProfileImage source={{ uri: image }} />
          </ImagePressable>
        )} */}
        {!image && (
          <ButtonContainer>
            <Button
              type="primary"
              isLoading={isUploadingImage}
              disabled={isUploadingImage}
              title={t('upload')}
              onPress={photoSelected}
            />
          </ButtonContainer>
        )}
        <Gallery>
          {/* <Title>Photos Gallery</Title> */}
          <Photos>
            {account.photos.map((photo) => (
              <ImageContainer>
                <Photo source={{ uri: photo.link }} />
                <RemoveChip
                  onPress={() => onDeletePhoto(photo.id)}
                  as={Pressable}
                >
                  <SvgIcon
                    width={25}
                    height={25}
                    xml={icons.cardIcons.availability}
                  />
                </RemoveChip>
              </ImageContainer>
            ))}
          </Photos>
          <ButtonContainer>
            <Button
              type="primary"
              isLoading={isUploadingImage}
              disabled={isUploadingImage}
              title={t('uploadPhoto')}
              onPress={photoSelected}
            />
          </ButtonContainer>
        </Gallery>
      </Container>
    </ScrollView>
  );
};
