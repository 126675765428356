import React, { FC, useEffect, useRef, useState } from 'react';
import { FieldTypes } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { useField } from 'react-final-form';
import SignatureScreen, {
  SignatureViewRef,
} from 'react-native-signature-canvas';
import styled from 'styled-components/native';
import { Button } from '../Buttons';
import { BaseImage } from '../Images';
import { BaseView, FlexRow } from '../Layout';
import { BaseText } from '../Typography';
import { Loader } from '../Loaders';

const Container = styled(BaseView)`
  width: 100%;
`;

const SignatureScreenContainer = styled(BaseView)`
  height: 200px;
`;

const ButtonContainer = styled(FlexRow)`
  margin-top: 12px;
`;

const ButtonText = styled(BaseText)`
  font-family: Rubik;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary};
`;

interface ISignatureFieldProps {
  id: string | number;
  setIsScrollEnabled?: (value: boolean) => void;
  loadDelay?: number;
}

export const SignatureField: FC<ISignatureFieldProps> = ({
  id,
  setIsScrollEnabled,
  loadDelay,
}) => {
  const { t } = useTranslation();
  const {
    input: { value: signatureFieldValue, onChange: setSignatureFieldValue },
  } = useField(`field-${id}`);
  const [initialLoading, setInitialLoading] = useState(true);

  const ref = useRef<SignatureViewRef>(null);

  const handleOK = (signature) => {
    const parsedAssets = [
      {
        fieldTypeId: FieldTypes.FIELD_TYPE_SIGNATURE,
        content: signature,
        type: 'image/png',
      },
    ];
    setSignatureFieldValue(parsedAssets);
  };

  // Called after ref.current.readSignature() reads an empty string
  const handleEmpty = () => {
    console.log('Empty');
  };

  const handleClear = () => {
    ref.current.clearSignature();
    setSignatureFieldValue([]);
  };

  const handleEnd = () => {
    ref.current.readSignature();
  };

  useEffect(() => {
    setTimeout(() => setInitialLoading(false), loadDelay || 2000);
  }, [loadDelay]);

  return (
    <Container>
      {!initialLoading ? (
        <SignatureScreenContainer>
          <SignatureScreen
            ref={ref}
            onOK={handleOK}
            onEmpty={handleEmpty}
            onBegin={() => {
              if (setIsScrollEnabled) {
                setIsScrollEnabled(false);
              }
            }}
            // dataURL={signatureFieldValue?.[0]?.content}
            onEnd={() => {
              if (setIsScrollEnabled) {
                setIsScrollEnabled(true);
              }
              handleEnd();
            }}
          />
          <ButtonContainer>
            <Button type="primaryReversed" onPress={handleClear} narrow>
              <ButtonText>{t('clear')}</ButtonText>
            </Button>
          </ButtonContainer>
        </SignatureScreenContainer>
      ) : (
        <Loader />
      )}
    </Container>
  );
};
