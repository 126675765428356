import React, { FC, useCallback, useMemo } from 'react';
import {
  ILocation,
  IPosition,
  useBranches,
  usePositions,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  hoursToTotalDuration,
  openMap,
  rangeFormat,
  rangeFormatMoment,
  totalDuration,
} from '@violetta/ubeya/utils';
import moment from 'moment';
import { Image as BaseImage, Linking, Platform, Pressable } from 'react-native';
import styled from 'styled-components/native';
import {
  BaseView,
  FlexColumnDirection,
  FlexRow,
  FlexRowEvenly,
} from '../Layout';
import {
  H1Regular,
  H2Regular,
  H4Medium,
  H4Regular,
  H5Medium,
  H5Regular,
} from '../Typography';

const Container = styled(FlexColumnDirection)`
  display: flex;
  border-radius: 16px;
`;
const Header = styled(FlexRow)``;
const NameContainer = styled(BaseView)`
  margin-bottom: 20px;
  display: flex;
  flex: 1;
`;
const ShiftTitle = styled(H4Medium)`
  padding: 2px 0;
  font-weight: 500;
`;

const LocationContainer = styled(BaseView)`
  display: flex;
  width: 100%;
`;

const Cards = styled(FlexRowEvenly)`
  width: 100%;
  margin-bottom: 20px;
`;

const TimeCard = styled(BaseView)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 80px;
  width: 80px;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  border-radius: 16px;
`;

const TotalCard = styled(TimeCard)`
  background-color: ${({ theme }) => theme.colors.lightBackground};
  width: 120px;
`;

const CardTitle = styled(H5Medium)`
  color: ${({ theme }) => theme.colors.gray1};
`;

const CardTime = styled(H1Regular)`
  font-weight: 500;
  font-size: 19px;
  color: ${({ theme }) => theme.colors.gray1};
`;

const ImageContainer = styled(BaseView)`
  display: flex;
`;

const Image = styled(BaseImage)`
  height: 150px;
  border-radius: 16px;
`;

const ScheduleContainer = styled(FlexRow)`
  margin-left: 35px;
  width: 100%;
  padding-bottom: 15px;
`;

const Time = styled(H4Regular)`
  margin-top: 8px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.primary};
`;

const AccountLogo = styled.Image`
  width: 60px;
  height: 60px;
  border-radius: 30px;
  margin-right: 20px;
`;

interface Props {
  title: string;
  branchId?: number;
  location: ILocation | null;
  position: IPosition | null;
  comments: string | null;
  startTime: moment.Moment | null;
  endTime: moment.Moment | null;
  scheduledStartTime?: moment.Moment | null;
  scheduledEndTime?: moment.Moment | null;
}

export const TimesheetSummary: FC<Props> = ({
  title,
  startTime,
  endTime,
  scheduledStartTime,
  scheduledEndTime,
  location,
  position,
  comments,
}) => {
  const { t } = useTranslation();

  const totalTime = useMemo(() => {
    if (!startTime || !endTime) {
      return null;
    }
    if (!startTime.isValid() || !endTime.isValid()) {
      return null;
    }
    return hoursToTotalDuration(totalDuration(startTime, endTime));
  }, [startTime, endTime]);

  const positionName = useMemo(
    () => !!position && t(position.slug),
    [position, t]
  );
  const formattedHours = useMemo(
    () => rangeFormatMoment(startTime, endTime),
    [endTime, startTime]
  );

  return (
    <Container>
      <Header>
        <NameContainer>
          <ShiftTitle>{title || ''}</ShiftTitle>
          {!!positionName && <ShiftTitle>{positionName || ''}</ShiftTitle>}
          {!!comments && <ShiftTitle>{comments}</ShiftTitle>}
          {!!formattedHours && (
            <Time>
              {formattedHours} ({totalTime} {t('hours')})
            </Time>
          )}
        </NameContainer>
      </Header>
      {scheduledStartTime && scheduledStartTime.isValid() && (
        <ScheduleContainer>
          <H5Regular>
            Scheduled time: {scheduledStartTime.format('LT')}{' '}
            {scheduledEndTime &&
              scheduledEndTime.isValid() &&
              scheduledEndTime.format('LT')}
          </H5Regular>
        </ScheduleContainer>
      )}

      {location && (
        <LocationContainer as={Pressable} onPress={() => openMap(location)}>
          {location?.image && (
            <ImageContainer>
              <Image
                source={{ uri: location.image }}
                resizeMode="cover"
                resizeMethod="scale"
              />
            </ImageContainer>
          )}
        </LocationContainer>
      )}
    </Container>
  );
};
