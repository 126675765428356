import React, { FC, useCallback, useContext, useMemo } from 'react';
import momentTz from 'moment-timezone';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import {
  IOffer,
  useLocations,
  useOffers,
  usePositions,
  useUniforms,
} from '@violetta/ubeya/entities';
import { useLocale, useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  FlexColumnDirection,
  FlexRow,
  H7Regular,
  SvgIcon,
  BaseText,
  RipplePressable,
  BaseCollapsible,
} from '@violetta/ubeya/ui';
import { InferGenericArray, rangeFormat, uiAlert } from '@violetta/ubeya/utils';
import moment from 'moment';
import { Image as BaseImage, ActivityIndicator, Platform } from 'react-native';
import { useQueryClient } from '@tanstack/react-query';
import styled, { useTheme } from 'styled-components/native';
import { ConfigContext } from '../../../contexts';
import Toast from 'react-native-toast-message';
import { FeedScreenNames } from '../../feed/navigation/ScreenNames';
import Markdown from 'react-native-markdown-display';

const Container = styled(BaseView)`
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: #eaeaea;
  padding: 7px 0;
`;

const CardContent = styled(FlexRow)`
  justify-content: space-between;
`;
const ShiftDetails = styled(FlexColumnDirection)``;

const Actions = styled(BaseView)<{ rtl: boolean }>`
  justify-content: ${({ isApplicationLoading }) =>
    isApplicationLoading ? 'center' : 'flex-end'};
  align-items: flex-start;
  flex-direction: row;
`;

const MoreDetailsContainer = styled(BaseView)`
  margin-bottom: 10px;
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.offwhitesmoke};
  width: 100%;
  margin-top: 10px;
`;

const ShiftHours = styled(H7Regular)`
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: 24px;
  color: #252525;
`;

const ShiftDate = styled(H7Regular)`
  font-size: 14px;
  font-style: normal;
  line-height: 24px;
  color: #252525;
`;

const ShiftDateBold = styled(ShiftDate)`
  font-weight: 500;
`;

const RowDetail = styled(FlexRow)`
  align-items: center;
  margin-bottom: 8px;
`;

const ShiftLocation = styled(H7Regular)<{ bold?: boolean }>`
  margin-left: 8px;
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.86px;
  color: ${({ theme }) => theme.colors.gray15};
  width: 150px;
`;

const ShiftJobDescription = styled(H7Regular)`
  margin-left: 8px;
  padding-right: 15px;
`;

const ShiftUniform = styled(H7Regular)`
  margin-left: 8px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.86px;
  color: ${({ theme }) => theme.colors.gray15};
  width: 150px;
`;

const Image = styled(BaseImage)`
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;

const ImageContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const Wage = styled(H7Regular)<{ bold?: boolean }>`
  margin-left: 13px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.86px;
  color: ${({ theme }) => theme.colors.gray15};
  width: 150px;
`;

const ButtonContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

const AppliedText = styled(BaseText)`
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme, vPressed, xPressed }) =>
    xPressed
      ? theme.colors.gray13
      : vPressed
      ? theme.colors.surface
      : theme.colors.approve};
`;

const DeclineText = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  color: ${({ theme, xPressed }) =>
    xPressed ? theme.colors.decline2 : 'transparent'};
`;

const IconContainer = styled(BaseView)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 20px;
`;

const TriggerText = styled(H7Regular)`
  font-size: 15px;
  font-weight: 500;
`;
const TriggerContainer = styled(BaseView)``;

const LoaderContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
`;

interface Props {
  isAutoBook: boolean;
  isRemoteWork?: boolean;
  isShowOfferWage: boolean;
  isProjectMultiDay?: boolean;
  isLoading: boolean;
  shift: InferGenericArray<IOffer['shifts']>;
  projectLocationId?: number;
  projectUniformId?: number;
  refetchBookings: () => void;
  currency?: string;
  hideNotes?: boolean;
}

export const ApplyShiftCard: FC<Props> = React.memo(
  ({
    isAutoBook,
    isRemoteWork = false,
    isShowOfferWage,
    projectLocationId,
    projectUniformId,
    refetchBookings,
    isProjectMultiDay = false,
    shift: {
      startTime,
      endTime,
      positionId,
      application,
      id: shiftId,
      projectId,
      locationId,
      uniformId,
      comments,
      wage,
    },
    currency = '',
    hideNotes = false,
  }) => {
    const { colors } = useTheme();
    const { locale } = useLocale();
    const { navigate } = useNavigation();
    const { rtl, dateFormat } = useContext(ConfigContext);

    const queryClient = useQueryClient();
    const { mappedPositions } = usePositions();
    const { mappedLocations } = useLocations();
    const { mappedUniforms } = useUniforms();

    const {
      addApplication: add,
      deleteApplication: remove,
      editApplication: edit,
      refetch,
      isAddingApplication,
      isDeletingApplication,
      isEditingApplication,
    } = useOffers();
    const { t } = useTranslation();
    const isApplicationLoading = useMemo(
      () =>
        isAddingApplication || isDeletingApplication || isEditingApplication,
      [isAddingApplication, isDeletingApplication, isEditingApplication]
    );
    const [xPressed, vPressed] = useMemo(
      () =>
        [
          application?.isRejected === true,
          application?.isRejected === false,
        ] as
          | readonly [true, false]
          | readonly [false, true]
          | readonly [false, false],
      [application]
    );

    const onVPress = useCallback(async () => {
      if (!xPressed && !vPressed) {
        if (isAutoBook) {
          const res = await uiAlert({
            title: t('autoBookAreYouSureAlertTitle'),
            save: {
              text: t('yes'),
            },
            cancel: {
              text: t('no'),
            },
          });
          if (!res) {
            return;
          }
        }

        try {
          await add({
            projectId,
            isRejected: false,
            shiftId,
          });
        } catch (e) {
          queryClient.invalidateQueries({
            predicate: ({ queryKey }) => queryKey?.[0] === 'offers',
          });
          if (isAutoBook) {
            await uiAlert({
              title: t('autoBookErrorAlert'),
              save: {
                text: t('ok'),
              },
            });
          } else {
            await uiAlert({
              title: t('applyErrorAlert'),
              save: {
                text: t('ok'),
              },
            });
          }
          return;
        }

        if (isAutoBook) {
          await Promise.all([refetch(), refetchBookings()]);
          // setTimeout(
          //   () =>
          //     queryClient.invalidateQueries({
          //       predicate: ({ queryKey }) => queryKey?.[0] === 'bookings' || queryKey?.[0] === 'offers',
          //     }),
          //   1500,
          // );
          // goBack();
          // await uiAlert({
          //   title: t('autoBookSuccessAlertTitle'),
          //   save: {
          //     text: t('ok'),
          //   },
          // });
          return;
        }
      }

      if (xPressed) {
        try {
          await edit({
            projectId,
            id: application?.id!,
            isRejected: false,
            shiftId,
          });
        } catch (e) {
          await Promise.all([refetch(), refetchBookings()]);
          Toast.show({ text1: t('somethingWentWrongAlert'), type: 'error' });
        }
      }

      if (vPressed) {
        try {
          await remove({ projectId, id: application?.id!, shiftId });
        } catch (e) {
          await Promise.all([refetch(), refetchBookings()]);
          Toast.show({ text1: t('somethingWentWrongAlert'), type: 'error' });
        }
      }
    }, [
      xPressed,
      vPressed,
      isAutoBook,
      t,
      add,
      projectId,
      shiftId,
      queryClient,
      refetch,
      refetchBookings,
      edit,
      application?.id,
      remove,
    ]);

    const onXPress = useCallback(async () => {
      if (!xPressed && !vPressed) {
        await add({
          projectId,
          isRejected: true,
          shiftId,
        });
      }

      if (vPressed) {
        try {
          await edit({
            projectId,
            id: application?.id!,
            isRejected: true,
            shiftId,
          });
        } catch (e) {
          await Promise.all([refetch(), refetchBookings()]);
          Toast.show({ text1: t('somethingWentWrongAlert'), type: 'error' });
        }
      }

      if (xPressed) {
        try {
          await remove({ projectId, id: application?.id!, shiftId });
        } catch (e) {
          await Promise.all([refetch(), refetchBookings()]);
          Toast.show({ text1: t('somethingWentWrongAlert'), type: 'error' });
        }
      }
    }, [
      xPressed,
      vPressed,
      add,
      projectId,
      shiftId,
      edit,
      application?.id,
      refetch,
      refetchBookings,
      t,
      remove,
    ]);

    const formattedHours = useMemo(
      () => rangeFormat(startTime, endTime),
      [startTime, endTime]
    );

    const formattedDate = useMemo(
      () =>
        isProjectMultiDay
          ? locale === 'he'
            ? `יום ${moment(startTime).format(`dddd, ${dateFormat}`)}`
            : moment(startTime).format(`dddd, ${dateFormat}`)
          : '',
      [dateFormat, isProjectMultiDay, locale, startTime]
    );

    const formattedDateWithTz = useMemo(
      () =>
        isRemoteWork
          ? momentTz
              .tz(startTime, mappedLocations?.[locationId]?.timezoneName)
              ?.format(`dddd, ${dateFormat}`) || ''
          : '',
      [dateFormat, isRemoteWork, locationId, mappedLocations, startTime]
    );

    const Trigger = () => (
      <TriggerContainer>
        <TriggerText>{t('details')}</TriggerText>
      </TriggerContainer>
    );

    const openGallery = useCallback(
      (uri) => {
        navigate(FeedScreenNames.GALLERY, {
          items: [
            {
              idx: 0,
              metadata: {},
              type: 'image',
              uri,
            },
          ],
          idx: 0,
        });
      },
      [navigate]
    );

    return (
      <Container>
        <CardContent>
          <ShiftDetails>
            {/* {!!isAutoBook && <InstantDetails>{t('instantBooking')}</InstantDetails>} */}
            {!!formattedHours && (
              <>
                <RowDetail>
                  <ShiftHours>{formattedHours}</ShiftHours>
                </RowDetail>
                {!!formattedDate && (
                  <RowDetail>
                    <ShiftDate>{formattedDate}</ShiftDate>
                  </RowDetail>
                )}
                {!!formattedDateWithTz && formattedDateWithTz !== '' && (
                  <RowDetail>
                    <ShiftDateBold>{formattedDateWithTz}</ShiftDateBold>
                  </RowDetail>
                )}
              </>
            )}

            {positionId && (
              <RowDetail>
                <IconContainer>
                  <SvgIcon
                    xml={icons.uiIcons.jobCase}
                    width={22}
                    height={22}
                    color={colors.gray1}
                  />
                </IconContainer>
                <ShiftLocation>
                  {t(mappedPositions[positionId]?.slug)}
                </ShiftLocation>
              </RowDetail>
            )}

            {locationId && locationId !== projectLocationId && (
              <RowDetail>
                <IconContainer>
                  <SvgIcon
                    xml={icons.uiIcons.locationOutline}
                    width={18}
                    height={22}
                    color={colors.gray1}
                  />
                </IconContainer>
                <ShiftLocation numberOfLines={2}>
                  {mappedLocations[locationId]?.address}
                </ShiftLocation>
              </RowDetail>
            )}

            {uniformId && uniformId !== projectUniformId && (
              <RowDetail>
                <IconContainer style={{ marginLeft: -2 }}>
                  <SvgIcon
                    xml={icons.uiIcons.admin}
                    width={25}
                    height={25}
                    color={colors.gray1}
                  />
                </IconContainer>
                <ShiftUniform>
                  {mappedUniforms[uniformId]?.name}{' '}
                  {mappedUniforms[uniformId]?.description}
                </ShiftUniform>
                {mappedUniforms[uniformId]?.image && (
                  <ImageContainer
                    as={RipplePressable}
                    onPress={() =>
                      openGallery(mappedUniforms[uniformId]?.image)
                    }
                  >
                    <Image
                      source={{ uri: mappedUniforms[uniformId]?.image! }}
                    />
                  </ImageContainer>
                )}
              </RowDetail>
            )}
            {isShowOfferWage && !!wage?.flatWage && (
              <RowDetail style={{ marginLeft: 2 }}>
                <SvgIcon
                  xml={icons.uiIcons.incomeIcon2}
                  width={17}
                  height={17}
                  color={colors.gray1}
                />
                <Wage>
                  {currency}
                  {wage?.flatWage}/{t('flat')} {t('wageEstimated')}
                </Wage>
              </RowDetail>
            )}
            {isShowOfferWage && !!wage?.hourlyWage && !wage?.flatWage && (
              <RowDetail style={{ marginLeft: 2 }}>
                <SvgIcon
                  xml={icons.uiIcons.incomeIcon2}
                  width={17}
                  height={17}
                  color={colors.gray1}
                />
                <Wage>
                  {currency}
                  {wage?.hourlyWage}/{t('hour')} {t('wageEstimated')}
                </Wage>
              </RowDetail>
            )}
            {!hideNotes && !!comments && (
              // <RowDetail>
              //   <SvgIcon xml={icons.actionIcons.comment} width={12} height={12} color={colors.gray1} />
              <RowDetail>
                <IconContainer />
                <ShiftLocation bold>{comments}</ShiftLocation>
              </RowDetail>
              // </RowDetail>
            )}
          </ShiftDetails>
          <Actions isApplicationLoading={isApplicationLoading} rtl={rtl}>
            {isApplicationLoading && (
              <LoaderContainer>
                {/* <CustomLoader width={60} height={22} /> */}
                <ActivityIndicator color={colors.primary} size="small" />
              </LoaderContainer>
            )}
            {!isApplicationLoading && (
              <BaseView>
                <ButtonContainer>
                  {!isAutoBook && (
                    <Button
                      type="primaryReversed"
                      title=""
                      paddingVertical={Platform.OS === 'android' ? 8 : 10}
                      paddingHorizontal={10}
                      radius={20}
                      style={[
                        xPressed
                          ? {
                              backgroundColor: colors.decline2,
                              borderColor: colors.decline2,
                            }
                          : { borderColor: colors.gray13 },
                        { shadowOpacity: 0 },
                      ]}
                      onPress={onXPress}
                    >
                      <SvgIcon
                        xml={
                          xPressed
                            ? icons.actionIcons.xSecondary
                            : icons.actionIcons.xDisabled
                        }
                        width={16}
                        height={16}
                        color={xPressed ? colors.surface : colors.gray18}
                      />
                    </Button>
                  )}
                  <Button
                    type="primaryReversed"
                    title=""
                    style={[
                      vPressed
                        ? {
                            backgroundColor: colors.approve,
                            borderColor: colors.approve,
                          }
                        : xPressed
                        ? { borderColor: colors.gray13 }
                        : { borderColor: colors.approve },
                      { shadowOpacity: 0, marginLeft: 10 },
                    ]}
                    onPress={onVPress}
                    radius={20}
                    paddingHorizontal={10}
                    paddingVertical={Platform.OS === 'android' ? 8 : 10}
                  >
                    {/* <SvgIcon
                      xml={icons.actionIcons.checkmark}
                      color={vPressed ? colors.background : colors.gray18}
                      width={16}
                      height={16}
                    /> */}
                    <AppliedText vPressed={vPressed} xPressed={xPressed}>
                      {vPressed
                        ? t('applied')
                        : isAutoBook
                        ? t('offerTakeJob')
                        : t('apply')}
                    </AppliedText>
                  </Button>
                </ButtonContainer>
                {/* <TextContainer>
                  {xPressed && <DeclineText xPressed={xPressed}>{t('cantWork')}</DeclineText>}
                  <AppliedText vPressed={vPressed} xPressed={xPressed}>
                    {vPressed ? t('applied') : isAutoBook ? t('offerTakeJob') : t('apply')}
                  </AppliedText>
                </TextContainer> */}
              </BaseView>
            )}
          </Actions>
        </CardContent>

        {positionId && !!mappedPositions[positionId].jobDescription && (
          <BaseCollapsible
            trigger={Trigger}
            withBorder={false}
            triggerStyle={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            containerStyle={{
              width: '100%',
              marginBottom: 10,
              marginTop: 7,
            }}
          >
            <MoreDetailsContainer>
              <ShiftJobDescription>
                <Markdown style={{ text: { lineHeight: 24 } }}>
                  {mappedPositions[positionId].jobDescription}
                </Markdown>
              </ShiftJobDescription>
            </MoreDetailsContainer>
          </BaseCollapsible>
        )}
      </Container>
    );
  }
);
