import { useMemo } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { createAdminStoreKey, mappedArray } from '@violetta/ubeya/utils';
import { createCachedSelector } from 're-reselect';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import * as sharedAtoms from '../../../shared/src/atoms';

export const API_DATE_FORMAT = 'YYYY-MM-DD';

const selector = createCachedSelector(
  (data) => data.data,
  ({ data }) => {
    const project = (() => {
      if (!data?.project) return null;
      return {
        ...data.project,
        shifts: data.project.shifts.map((shift) => ({
          ...shift,
          unbookings: (data?.unbookings || []).filter(
            ({ shiftId }) => shiftId === shift.id
          ),
        })),
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    })();

    const employeesMap = mappedArray(data?.employees || []);

    return { data, project, employeesMap };
  }
)({
  keySelector: (data, storeKey) => `${storeKey.join('#')}`,
});

export const useScannedEmployeeProject = ({
  projectId,
  branchId,
  employeeId,
}) => {
  const [selectedAccount] = useRecoilState(sharedAtoms.selectedAccount);
  const queryClient = useQueryClient();
  const storeKey = useMemo(
    () =>
      createAdminStoreKey([
        'project',
        'specific',
        Number(projectId),
        Number(employeeId),
      ]),
    [employeeId, projectId]
  );

  const projectStoreKey = useMemo(
    () => createAdminStoreKey(['project', 'specific', Number(projectId)]),
    [projectId]
  );

  const accountId = selectedAccount?.id;

  const {
    isFetched,
    isPending,
    data: selectorData,
    refetch,
  } = useQuery({
    queryKey: storeKey,
    queryFn: () =>
      adminApi.getProject({
        accountId,
        branchId,
        projectId,
      }),
    enabled: !!(branchId && projectId && employeeId),
    select: (data) => selector(data, storeKey),
  });

  const { project, employeesMap } = selectorData || {};

  const { mutateAsync: updateTimesheet, isPending: isUpdatingTimesheet } =
    useMutation({
      mutationFn: ({
        timesheetId,
        start,
        end,
      }: {
        timesheetId: string;
        start?: { time: string };
        end?: { time: string };
      }) => adminApi.updateTimesheet({ accountId, timesheetId, start, end }),
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: projectStoreKey,
          exact: true,
        });
      },
    });

  const { mutateAsync: updateSlot, isPending: isUpdatingSlot } = useMutation({
    mutationFn: ({
      shiftId,
      slotId,
      rating,
      review,
    }: {
      shiftId: number;
      slotId: number;
      rating?: number;
      review?: string;
    }) =>
      adminApi.updateSlot({
        accountId,
        branchId,
        projectId,
        shiftId,
        slotId,
        rating,
        review,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: projectStoreKey, exact: true });
    },
  });

  const {
    mutateAsync: updateOutsourcedTimesheet,
    isPending: isUpdatingOutsourcedTimesheet,
  } = useMutation({
    mutationFn: ({
      timesheetId,
      start,
      end,
    }: {
      timesheetId: string;
      start?: { time: string };
      end?: { time: string };
    }) =>
      adminApi.updateOutsourcedTimesheet({
        accountId,
        timesheetId,
        start,
        end,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: projectStoreKey, exact: true });
    },
  });

  const {
    mutateAsync: updateOutsourcedSlot,
    isPending: isUpdatingOutsourcedSlot,
  } = useMutation({
    mutationFn: ({
      slotId,
      rating,
      review,
    }: {
      shiftId: number;
      slotId: number;
      rating?: number;
      review?: string;
    }) =>
      adminApi.updateOutsourcedSlot({
        accountId,
        branchId,
        projectId,
        slotId,
        rating,
        review,
      }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: projectStoreKey, exact: true });
    },
  });

  const { mutateAsync: updateProject, isPending: isUpdatingProject } =
    useMutation({
      mutationFn: ({
        values,
      }: {
        values: {
          managerRating?: number;
          managerClientFeedback?: string;
          managerNotes?: string;
          managerIssues?: string;
        };
      }) =>
        adminApi.updateProject({
          projectId,
          branchId,
          accountId,
          data: values,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: projectStoreKey,
          exact: true,
        });
      },
    });

  return {
    isLoading: isPending,
    project,
    refetch,
    updateTimesheet,
    isUpdatingTimesheet,
    updateSlot,
    employeesMap,
    isUpdatingSlot,
    updateProject,
    isUpdatingProject,
    isUpdatingOutsourcedSlot,
    updateOutsourcedTimesheet,
    updateOutsourcedSlot,
    isUpdatingOutsourcedTimesheet,
    isFetched,
  };
};
