import { useQueryClient } from '@tanstack/react-query';
import { prefetchCaptainOffline, useBookings } from '@violetta/ubeya/entities';
import moment from 'moment';
import { useEffect } from 'react';

export const useOfflinePersist = () => {
  const queryClient = useQueryClient();
  const { bookings } = useBookings();

  useEffect(() => {
    (bookings || [])
      .filter((booking) => !!booking.captainType && booking?.project?.id)
      .map((booking) =>
        prefetchCaptainOffline({
          queryClient,
          projectId: booking?.project?.id,
          date: booking?.startTime
            ? moment(booking.startTime).toDate()
            : moment(booking.project.date).toDate(),
        })
      );
  }, [bookings, queryClient]);
};
