import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Booking, IPosition } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  Card,
  FlexRow,
  H5Medium,
  H6Regular,
  NotificationChip,
} from '@violetta/ubeya/ui';
import { rangeFormat, uiAlert } from '@violetta/ubeya/utils';
import moment from 'moment';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../contexts';
import { colors } from '../../../../style';

const Container = styled(Card)`
  padding: 12px;
`;

const LightHours = styled(H6Regular)`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const Title = styled(H5Medium)`
  flex-wrap: wrap;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  font-weight: normal;
  font-style: normal;
  padding-bottom: 10px;
  padding-right: 20px;
`;

const ShiftName = styled(H5Medium)`
  font-weight: 500;
  flex-wrap: wrap;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  max-width: 80%;
`;
const ShiftPosition = styled(ShiftName)`
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 28px;
  max-width: 80%;
`;

const TimeContainer = styled(FlexRow)`
  align-items: center;
  width: 100%;
`;

const FirstRow = styled(FlexRow)`
  padding-bottom: 5px;
  ${Platform.OS === 'web' && 'width:100%;'};
`;

const ShiftData = styled(BaseView)`
  padding-top: 12px;
  padding-right: 15px;
  flex: 1;
`;

const ButtonContainer = styled(BaseView)`
  align-items: flex-end;
`;

interface Props {
  booking: Booking;
  mappedPositions: Record<number, IPosition>;
  onConfirm: () => Promise<void>;
  isLoading: boolean;
}

export const Confirmation: FC<Props> = ({
  booking,
  onConfirm,
  isLoading,
  mappedPositions,
  mappedLocations,
}) => {
  const { rtl } = useContext(ConfigContext);
  const { t } = useTranslation();

  const confirm = useCallback(async () => {
    const res = await uiAlert({
      title: t('areYouSureYouWishToConfirm'),
      message: booking?.confirmation?.message,
      cancel: {
        text: t('no'),
      },
      save: {
        text: t('yes'),
      },
    });

    if (!res) {
      return;
    }
    onConfirm();
  }, [booking?.confirmation?.message, onConfirm, t]);

  const { day, date } = useMemo(() => {
    const shiftDate = moment(booking.date);

    return {
      day: rtl ? shiftDate.format('dddd') : shiftDate.format('ddd'),
      date: shiftDate.format('MMM, D'),
    };
  }, [booking.date, rtl]);

  const { positionId, comments, locationId } = booking;
  const location = useMemo(
    () => mappedLocations?.[locationId],
    [locationId, mappedLocations]
  );
  const position = useMemo(
    () => !!positionId && t(mappedPositions[positionId].slug),
    [positionId, t, mappedPositions]
  );
  const formattedHours = useMemo(
    () => rangeFormat(booking.startTime, booking.endTime),
    [booking]
  );
  const daysLeft = useMemo(
    () => Math.ceil(moment(booking.date).diff(moment(), 'days', true)),
    [booking.date]
  );

  return (
    <Container>
      <FirstRow>
        <ShiftData>
          <ShiftName>{booking.compoundedName}</ShiftName>
          {!!position && <ShiftPosition>{position}</ShiftPosition>}
          {!!comments && <ShiftPosition>{comments}</ShiftPosition>}
          {!!location && (
            <ShiftPosition>
              {location.address}, {location.name}
            </ShiftPosition>
          )}
        </ShiftData>
        <NotificationChip
          style={{ top: 5 }}
          text={
            daysLeft > 1
              ? t('inDays', { daysLeft })
              : daysLeft === 1
              ? t('tomorrow')
              : t('today')
          }
          color={colors.chip}
        />
      </FirstRow>
      {!!booking.confirmation.message && (
        <Title>{booking.confirmation.message}</Title>
      )}
      <TimeContainer>
        <LightHours>{date}</LightHours>
        {!!formattedHours && <LightHours>{formattedHours}</LightHours>}
      </TimeContainer>
      <ButtonContainer>
        <Button
          title={t('confirm')}
          onPress={confirm}
          isLoading={isLoading}
          disabled={isLoading}
          type="primary"
        />
      </ButtonContainer>
    </Container>
  );
};
