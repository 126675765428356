import React, { FC, useCallback, useEffect, useState } from 'react';
import DateTimePicker from '@react-native-community/datetimepicker';
import { useTranslation } from '@violetta/ubeya/i18n';
import moment from 'moment';
import { FieldMetaState } from 'react-final-form';
import { Modal, Pressable, View, Platform } from 'react-native';
import styled from 'styled-components/native';
import { BaseView, FlexRow, FlexRowBetween, RipplePressable } from '../Layout';
import { BaseText, H3Bold, H4Light, H4Regular } from '../Typography';

const BaseContainer = styled(Pressable)`
  flex: 1;
`;
const LinkContainer = styled(FlexRowBetween)`
  display: flex;
  padding: 0 20px;
  flex-wrap: wrap;
  width: 100%;
`;

const Container = styled(BaseView)`
  justify-content: center;
`;

const Link = styled(BaseView)``;

const TimeLabelContainer = styled(BaseView)`
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray7};
  align-items: center;
  justify-content: flex-end;
`;

const TimeLabel = styled<{ size: number }>(H4Light)`
  font-size: ${({ size }) => size};px;
`;

export interface TimeLabelContainerProps {
  setShow: (val: boolean) => void;
  value?: Date;
  placeholder?: string;
  size?: number;
  required?: boolean;
}
const DefaultTimeLabelContainer: FC<TimeLabelContainerProps> = ({
  setShow,
  value,
  placeholder,
  size = 14,
  required,
}) => (
  <TimeLabelContainer as={Pressable} onPress={() => setShow(true)}>
    <TimeLabel size={size}>
      {moment(value).isValid()
        ? moment(value).format('LT')
        : placeholder || '-'}
      {required && !moment(value).isValid() && '*'}
    </TimeLabel>
  </TimeLabelContainer>
);

const Cancel = styled(H4Regular)``;
const Title = styled(H4Regular)`
  max-width: 170px;
  flex-wrap: wrap;
`;
const Submit = styled(H3Bold)`
  color: #007aff;
`;

const ErrorMessage = styled(BaseText)`
  color: ${({ theme }) => theme.colors.red1};
  margin-top: 5px;
`;

type Props = {
  value?: Date;
  initialValue?: Date;
  title?: string;
  placeholder?: string;
  onChange?: (value: Date | undefined) => void;
  transparent?: boolean;
  required?: boolean;
  step?: number;
  size?: number;
  component?: FC<TimeLabelContainerProps>;
  meta?: FieldMetaState<Date>;
};

export const TimePicker: FC<Props> = ({
  value,
  title,
  placeholder,
  onChange,
  size,
  initialValue,
  required,
  step = 1,
  transparent = true,
  component: Component = DefaultTimeLabelContainer,
  meta,
}) => {
  const [date, setDate] = useState(
    (value && moment(value).toDate()) ||
      (initialValue && moment(initialValue).toDate()) ||
      new Date()
  );
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const onImmediateDateChange = useCallback(
    (event, selectedDate) => {
      const currentDate = selectedDate || date;
      setShow(false);
      onChange?.(currentDate);
    },
    [date, onChange]
  );

  const onDateChange = useCallback(
    (event, selectedDate) => {
      const currentDate = selectedDate || date;
      setDate(currentDate);
    },
    [date]
  );

  const onSelected = useCallback(() => {
    onChange?.(date);
    setShow(false);
  }, [date, onChange]);

  useEffect(() => {
    if (value) {
      setDate(moment(value).toDate());
    } else if (initialValue) {
      setDate(moment(initialValue).toDate());
    }
  }, [initialValue, value]);

  return (
    <>
      <Container>
        <Component
          setShow={setShow}
          value={value}
          placeholder={placeholder}
          size={size}
          required={required}
        />
        {meta?.error && meta?.touched && (
          <ErrorMessage>{meta?.error}</ErrorMessage>
        )}
      </Container>
      {(Platform.OS === 'ios' || Platform.OS === 'web') && (
        <Modal animationType="slide" transparent visible={show}>
          <BaseContainer onPress={() => setShow(false)}>
            <Pressable
              style={{
                height: 300,
                width: Platform.OS === 'web' ? 400 : null,
                marginTop: 'auto',
                borderTopColor: '#c4c6ca',
                paddingTop: 10,
                borderTopWidth: 1,
                alignSelf: Platform.OS === 'web' ? 'center' : 'stretch',
                backgroundColor: transparent ? undefined : 'white',
                alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
              }}
            >
              <LinkContainer>
                <Link as={RipplePressable} onPress={() => setShow(false)}>
                  <Cancel>{t('cancel')}</Cancel>
                </Link>
                {!!title && <Title numberOfLines={1}>{title}</Title>}
                <Link as={RipplePressable} onPress={onSelected}>
                  <Submit>{t('choose')}</Submit>
                </Link>
              </LinkContainer>
              <DateTimePicker
                value={date}
                mode="time"
                display="spinner"
                onChange={onDateChange}
                minuteInterval={step}
              />
            </Pressable>
          </BaseContainer>
        </Modal>
      )}
      {show && Platform.OS !== 'ios' && Platform.OS !== 'web' && (
        <DateTimePicker
          value={date}
          mode="time"
          display="default"
          onChange={onImmediateDateChange}
          minuteInterval={step}
        />
      )}
    </>
  );
};
