import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import {
  RouteProp,
  useNavigation,
  useRoute,
  useIsFocused,
} from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import {
  useAccounts,
  useBookings,
  useBranches,
  useOffers,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  CustomLoaderWrapped,
  H5Medium,
  SvgIcon,
  FlexRow,
  H3Regular,
  FlexRowBetween,
  H3Bold,
  Button,
} from '@violetta/ubeya/ui';
import { ScrollView, RefreshControl } from 'react-native';
import styled from 'styled-components/native';
import { AppContext } from '../../main/AppContext';
import { OfferApplyDetails, OfferHeader } from '../components';
import { OfferDetails } from '../components/OfferDetails';
import { OfferShifts } from '../components/OfferShifts';
import { checkIsProjectMultiDay } from '../utils';
import { HomeScreenNames } from '../../home/navigation/ScreenNames';
import { OffersScreenNames } from '../navigation/ScreenNames';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const Container = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  margin-bottom: 50px;
  padding-top: 10px;
`;

const PaddedContainer = styled(BaseView)`
  padding: 10px 20px;
`;

const OffersDisclaimer = styled(H5Medium)`
  margin: 25px 0 5px;
  font-size: 12px;
  font-weight: normal;
  line-height: 19px;
  letter-spacing: 0.86px;
  color: #7d7d7d;
`;

const Wrapper = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  position: relative;
  display: flex;
  flex: 1;
`;

const ClockinFooter = styled(FlexRowBetween)`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 0 20px;
`;

const ClockInContent = styled(FlexRow)`
  padding: 20px 0;
`;
const ClockInTitle = styled(H3Regular)`
  margin-left: 7px;
  color: white;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
`;

const OfferExpiredWrapper = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 50px;
  flex: 1;
`;

const OfferButtonContainer = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  margin-top: 30px;
`;

const OfferExpiredTitle = styled(H3Bold)`
  margin-top: -100px;
  margin-left: 7px;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  line-height: 30px;
`;
const OfferExpiredBody = styled(H3Regular)`
  text-align: center;
  margin-top: 7px;
  margin-left: 7px;
  margin-bottom: 20px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

interface Props {}

type ParamList = {
  params: {
    offerId: number;
  };
};

export const OfferScreen: FC<Props> = () => {
  const {
    offers: { selectOffer, selectedOffer },
  } = useContext(AppContext);

  const {
    params: { offerId: offerIdParam },
  } = useRoute<RouteProp<ParamList, 'params'>>();
  const offerId = offerIdParam && Number(offerIdParam);

  const { t } = useTranslation();
  const isFocused = useIsFocused();
  const [isOfferExpired, setIsOfferExpired] = useState<boolean>(false);
  const {
    mappedOffers,
    markAsRead,
    isLoading: isLoadingOffers,
    isMarkReadError,
    isMarkReadLoading,
    editApplication,
    refetch,
    isStale,
  } = useOffers();
  const {
    mappedBookings,
    mappedBookingsByProjects,
    refetch: refetchBookings,
  } = useBookings();
  const { mappedBranches, isLoading: isLoadingBranches } = useBranches();
  const { mappedAccounts } = useAccounts();
  const { goBack, navigate } = useNavigation();

  const offer = useMemo(() => mappedOffers?.[offerId], [mappedOffers, offerId]);
  const offerBookings = useMemo(
    () => (offer ? mappedBookingsByProjects?.[offer.id] : []),
    [mappedBookingsByProjects, offer]
  );

  useEffect(() => {
    let timeout;
    if (
      isFocused &&
      !isStale &&
      !isLoadingOffers &&
      !mappedOffers[offerId]?.id
    ) {
      timeout = setTimeout(() => {
        if (isFocused && !isLoadingOffers && !mappedOffers[offerId]?.id) {
          if (mappedBookingsByProjects?.[offerId]) {
            goBack();
          }
          setIsOfferExpired(true);
        }
      }, 1000);
    }

    if (offerId && mappedOffers[offerId] && selectedOffer?.id !== offerId) {
      selectOffer(mappedOffers[offerId]);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    mappedOffers,
    isLoadingOffers,
    goBack,
    offerId,
    selectOffer,
    selectedOffer,
    isStale,
    isFocused,
    mappedBookings,
    isOfferExpired,
    mappedBookingsByProjects,
  ]);

  useEffect(() => {
    if (
      (offer?.shifts || []).some((value) => !value.seen) &&
      !isMarkReadError &&
      !isMarkReadLoading
    ) {
      markAsRead({ offerId });
    }
  }, [offer, offerId, markAsRead, isMarkReadError, isMarkReadLoading]);

  const config = useMemo(
    () =>
      offer?.branchId ? mappedBranches[offer.branchId]?.schedulingConfig : null,
    [mappedBranches, offer?.branchId]
  );

  const accountId =
    offer?.branchId && mappedBranches[offer.branchId]?.accountId;
  const currency =
    offer?.branchId &&
    mappedBranches?.[offer?.branchId]?.accountId &&
    mappedAccounts?.[accountId]?.currency?.symbol;

  const isAutoBook = useMemo(
    () => offer?.autoBook || (config && config.autoBook),
    [config, offer?.autoBook]
  );

  const isRemoteWork = useMemo(
    () => offer?.isRemoteWork || (config && config.isRemoteWork),
    [config, offer?.isRemoteWork]
  );

  const isShowOfferWage = useMemo(
    () => config && config.isShowOfferWage,
    [config]
  );

  const isHasCarOnApply = useMemo(() => config?.isHasCarOnApply, [config]);
  const maskLocationAddressInAppOffers = useMemo(
    () => config?.maskLocationAddressInAppOffers,
    [config]
  );
  const maskClientInAppOffers = useMemo(
    () => config?.maskClientInAppOffers,
    [config]
  );
  const maskNotesInAppOffers = useMemo(
    () => config?.maskNotesInAppOffers,
    [config]
  );
  const maskGeneralCommentsInAppOffers = useMemo(
    () => config?.maskGeneralCommentsInAppOffers,
    [config]
  );

  const refreshAll = useCallback(() => {
    refetch();
    refetchBookings();
  }, [refetch, refetchBookings]);

  const isLoading = isLoadingOffers || isLoadingBranches;

  const isProjectMultiDay = useMemo(
    () =>
      checkIsProjectMultiDay({
        date: offer?.minShiftDate,
        endDate: offer?.maxShiftDate,
      }),
    [offer?.maxShiftDate, offer?.minShiftDate]
  );

  if (isOfferExpired) {
    return (
      <OfferExpiredWrapper>
        <OfferExpiredTitle>{t('offerExpiredTitle')}</OfferExpiredTitle>
        <OfferExpiredBody>{t('offerExpiredBody')}</OfferExpiredBody>
        <SvgIcon xml={icons.symbolIcons.hourglass} width={150} height={150} />
        <OfferButtonContainer>
          <Button
            type="primary"
            onPress={() => {
              navigate(HomeScreenNames.TABS, {
                screen: OffersScreenNames.OFFERS,
              });
            }}
            title={t('offerExpiredButton')}
          />
        </OfferButtonContainer>
      </OfferExpiredWrapper>
    );
  }

  if (!offer) {
    return <CustomLoaderWrapped />;
  }

  return (
    <Wrapper>
      <KeyboardAwareScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        refreshControl={
          <RefreshControl onRefresh={refreshAll} refreshing={isLoading} />
        }
      >
        <Container>
          <OfferHeader
            offer={offer}
            isProjectMultiDay={isProjectMultiDay}
            hideLocation={maskLocationAddressInAppOffers}
          />
          <PaddedContainer>
            <OfferShifts
              isProjectMultiDay={isProjectMultiDay}
              offer={offer}
              bookings={offerBookings}
              isLoading={isLoading}
              isAutoBook={isAutoBook}
              isRemoteWork={isRemoteWork}
              hideNotes={maskNotesInAppOffers}
              isShowOfferWage={isShowOfferWage}
              refetchBookings={refetchBookings}
              currency={currency}
            />
            {!isProjectMultiDay && (
              <OfferApplyDetails
                offer={offer}
                isLoading={isLoading}
                editApplication={editApplication}
                isHasCarOnApply={isHasCarOnApply}
                hideNotes={maskNotesInAppOffers}
              />
            )}
            <OfferDetails
              isProjectMultiDay={isProjectMultiDay}
              offer={offer}
              hideClient={maskClientInAppOffers}
              hideGeneralComments={maskGeneralCommentsInAppOffers}
            />
            {!isAutoBook && (
              <OffersDisclaimer>{t('offerDisclaimer')}</OffersDisclaimer>
            )}
          </PaddedContainer>
        </Container>
      </KeyboardAwareScrollView>
      {!isAutoBook && offer && offer?.didApply && (
        <ClockinFooter>
          <ClockInContent>
            <SvgIcon
              xml={icons.uiIcons.circledChecked}
              height={22}
              width={22}
            />
            <ClockInTitle>{`${t('requestSent')}. ${t(
              'waitForApproval'
            )}`}</ClockInTitle>
          </ClockInContent>
        </ClockinFooter>
      )}
    </Wrapper>
  );
};
