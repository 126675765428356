import React, { FC, useMemo } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { useTranslation } from '@violetta/ubeya/i18n';
import { FlexRowBetween, H3Medium } from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';

const Container = styled(FlexRowBetween)`
  flex: 1;
  justify-content: center;
`;

const Title = styled(H3Medium)`
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: #252631;
`;

export const TimeclockHeaderTitle: FC<StackHeaderProps> = () => {
  const currentDate = useMemo(() => moment().format('dddd MMM, DD'), []);

  return (
    <Container>
      <Title>{currentDate}</Title>
    </Container>
  );
};
