import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Animated, PanResponder, useWindowDimensions } from 'react-native';
import styled from 'styled-components/native';

import { BaseImage } from '../Images';
import {
  FlexColumnDirection,
  FlexRowCenter,
  WideFlexColumnCenter,
} from '../Layout';
import { H4Medium } from '../Typography';
import { IGalleryItem } from './IGalleryItem';
import { ImageGalleryItem } from './ImageGalleryItem';
import { VideoGalleryItem } from './VideoGalleryItem';

interface Props {
  item: IGalleryItem<any>;
  onSwap?: (dir: 'left' | 'right') => void;
  singleItem?: boolean;
}

const Container = styled(FlexColumnDirection)`
  flex: 1;
  padding-top: 50px;
`;

const Body = styled(FlexColumnDirection)`
  flex: 1;
  justify-content: center;
`;

export const GalleryItem: FC<Props> = React.memo(
  ({ item, onSwap, singleItem = false }) => {
    const { type } = item;

    const { width: windowWidth } = useWindowDimensions();

    const [animationState, setAnimationState] = useState<
      'started' | 'finished'
    >('finished');

    const pos = useRef<{ x: number; y: number }>({ x: 0, y: 0 }).current;

    const pan = useRef(new Animated.ValueXY());

    const updater = useCallback(
      ({ x, y }: { x: number; y: number }) => {
        pos.x = x;
        pos.y = y;
      },
      [pos]
    );

    useEffect(() => {
      const id =
        animationState === 'finished' ? pan.current.addListener(updater) : '';

      return () => {
        animationState === 'finished' && id !== ''
          ? pan.current.removeListener(id)
          : {};
      };
    }, [updater, animationState]);

    const panResponder = useRef(
      PanResponder.create({
        onMoveShouldSetPanResponder: () => true,
        onPanResponderGrant: () => {
          setAnimationState('started');
          pan.current.setOffset(pos);
        },
        onPanResponderMove: Animated.event([null, { dx: pan.current.x }]),
        onPanResponderRelease: (e, state) => {
          const { dx } = state;
          pan.current.flattenOffset();

          if (Math.abs(dx) > Math.floor(windowWidth / 2)) {
            const dir = dx < 0 ? 'left' : 'right';
            onSwap?.(dir);
          }

          pan.current = new Animated.ValueXY();

          setAnimationState('finished');
        },
      })
    ).current;

    return (
      <Animated.View
        style={{
          ...(!singleItem && { transform: [{ translateX: pan.current.x }] }),
          flex: 1,
        }}
        {...panResponder.panHandlers}
      >
        <Container>
          <Body>
            {type === 'image' && <ImageGalleryItem item={item} />}
            {type === 'video' && <VideoGalleryItem item={item} />}
          </Body>
        </Container>
      </Animated.View>
    );
  }
);
