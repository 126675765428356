import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useUser } from '@violetta/ubeya/auth';
import {
  useBookings,
  useBranches,
  usePositions,
  useRequestReasons,
  useRequests,
  useTimesheets,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  ChevronDown,
  FlexColumnDirection,
  FlexRowCenter,
  H5Medium,
  InputField,
  TextInput,
} from '@violetta/ubeya/ui';
import { rangeFormat } from '@violetta/ubeya/utils';
import moment from 'moment';
import { ScrollView } from 'react-native';
import styled, { useTheme } from 'styled-components/native';
import { ShiftInfoCard } from '../../../components/Cards';
import { TwoButtonModal } from '../../../components/Modals';
import { AppContext } from '../../main/AppContext';
import { ReasonSelect } from './ReasonSelect';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const TopWrapper = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.primaryLight4};
  padding: 32px 16px;
`;

const BottomWrapper = styled(BaseView)`
  padding: 16px 16px 24px 16px;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

const BranchName = styled(H5Medium)`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 4px;
`;

const EmployeeReasonOtherTitle = styled(H5Medium)`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 5px;
`;

const HeaderRow = styled(FlexRowCenter)`
  justify-content: center;
`;

const ButtonContainer = styled(BaseView)`
  margin-bottom: 24px;
`;

export const ReleaseScreen: FC = () => {
  const {
    bookings: { selectedBooking },
  } = useContext(AppContext);
  const [isSentModalOpen, setIsSentModalOpen] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [selectedReason, setSelectedReason] = useState<number | null>(null);
  const [employeeReasonOther, setEmployeeReasonOther] = useState<string | null>(
    null
  );
  const { colors } = useTheme();
  const { t } = useTranslation();
  const { data } = useUser();
  const { goBack } = useNavigation();

  const {
    cancelReleaseFromShift,
    createReleaseFromShift,
    isCancellingReleaseFromShift,
    isCreatingReleaseFromShift,
  } = useRequests();
  const { requestReasons, mappedReasons } = useRequestReasons();
  const { mappedBranches } = useBranches();
  const { mappedBookings } = useBookings();
  const { mappedPositions } = usePositions();

  const requestReasonsOptions = useMemo(
    () =>
      (requestReasons || []).map(({ id, label, value }) => ({
        id,
        slug: label,
        value,
      })),
    [requestReasons]
  );

  const booking = mappedBookings[selectedBooking?.id];
  const position = mappedPositions[booking?.positionId];
  const release = useMemo(() => booking?.release, [booking?.release]);

  const branch = useMemo(
    () => mappedBranches[booking?.project?.branchId],
    [mappedBranches, booking?.project?.branchId]
  );
  const accountId = useMemo(() => branch?.accountId, [branch?.accountId]);

  const day = useMemo(
    () => moment(booking?.startTime).format('dddd MMM, DD'),
    [booking?.startTime]
  );
  const time = useMemo(
    () => rangeFormat(booking?.startTime, booking?.endTime),
    [booking?.endTime, booking?.startTime]
  );

  const handleSendReleaseRequest = useCallback(async () => {
    const employeeReason = selectedReason
      ? mappedReasons[selectedReason]?.label
      : '';
    await createReleaseFromShift({
      accountId,
      shiftId: booking?.id,
      employeeReason,
      employeeReasonOther,
    });
    setIsSentModalOpen(false);
  }, [
    selectedReason,
    mappedReasons,
    createReleaseFromShift,
    accountId,
    booking?.id,
    employeeReasonOther,
  ]);

  const handleCancelReleaseRequest = useCallback(async () => {
    await cancelReleaseFromShift({ requestId: release?.requestId });
    setIsCancelModalOpen(false);
    goBack();
  }, [cancelReleaseFromShift, goBack, release?.requestId]);

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1 }}
      style={{ backgroundColor: 'white' }}
    >
      <Container>
        <TopWrapper>
          <HeaderRow>
            <BranchName>{t('shiftReleaseRequest')}</BranchName>
          </HeaderRow>
          <ShiftInfoCard
            image={data?.image}
            name={`${data?.firstName || ''} ${data?.lastName || ''}`}
            day={day}
            time={time}
            position={t(position?.slug)}
          />
        </TopWrapper>
        <BottomWrapper>
          <BaseView>
            {release?.status !== 0 && (
              <>
                <ReasonSelect
                  value={selectedReason ? [selectedReason] : null}
                  options={requestReasonsOptions}
                  isMulti={false}
                  rtl
                  placeholder={t('releaseReason')}
                  onSelected={(value) => setSelectedReason(value?.[0])}
                />
                {selectedReason !== null && (
                  <>
                    <EmployeeReasonOtherTitle>
                      {t('releaseReasonTitle')}
                    </EmployeeReasonOtherTitle>
                    <TextInput
                      editable
                      autoCapitalize="none"
                      multiline
                      numberOfLines={2}
                      style={{ fontSize: 12 }}
                      value={employeeReasonOther}
                      onChangeText={(value) => setEmployeeReasonOther(value)}
                    />
                  </>
                )}
              </>
            )}
          </BaseView>
          <ButtonContainer>
            <Button
              title={
                release?.status !== 0 ? t('sendRelease') : t('cancelRelease')
              }
              radius={30}
              fontSize={18}
              onPress={() =>
                release?.status !== 0
                  ? setIsSentModalOpen(true)
                  : setIsCancelModalOpen(true)
              }
              type="primary"
              disabled={!selectedReason && release?.status !== 0}
            />
          </ButtonContainer>
        </BottomWrapper>
        <TwoButtonModal
          firstButton={{
            title: t('cancel'),
            onPress: () => setIsSentModalOpen(false),
            isLoading: false,
          }}
          secondButton={{
            title: t('ok'),
            onPress: handleSendReleaseRequest,
            isLoading: isCreatingReleaseFromShift,
          }}
          isOpen={isSentModalOpen}
          title={t('terms')}
          subtitle={t('managerNeedsToApproveShiftRelease')}
        />
        <TwoButtonModal
          secondButton={{
            title: t('yes'),
            onPress: handleCancelReleaseRequest,
            isLoading: isCancellingReleaseFromShift,
          }}
          firstButton={{
            title: t('no'),
            onPress: () => setIsCancelModalOpen(false),
            isLoading: false,
          }}
          isOpen={isCancelModalOpen}
          title={t('confirmOperation')}
          subtitle={t('areYouSureToCancelShiftRelease')}
        />
      </Container>
    </ScrollView>
  );
};
