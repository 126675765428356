import { useNetInfo } from '@react-native-community/netinfo';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import { Alert } from '@violetta/ubeya/alert';
import {
  CAPTAIN_TYPE_ENUM,
  CLOCK_METHODS,
  IQRShiftEmployee,
  useAccounts,
  useBranches,
  useLocations,
  usePositions,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseImage,
  BaseText,
  BaseView,
  Button,
  FlexColumnCenter,
  FlexColumnDirection,
  FlexRow,
  H3Bold,
  H3Medium,
  H4Bold,
  H6Medium,
  RipplePressable,
  SvgIcon,
  Timeline,
} from '@violetta/ubeya/ui';
import moment from 'moment-timezone';
import React, { FC, useContext, useMemo } from 'react';
import { RefreshControl, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { BookingBreakPlots } from '../../bookings/components/BookingBreakPlots';
import { IReport, TimeclockState } from '../interfaces';
import { ClockOngoing } from './ClockOngoing';
import { ClockStart } from './ClockStart';
import TimeclockContext from './TimeclockContext';
import { TimeclockQRScan } from './TimeclockQRScan';
import { openMap } from '@violetta/ubeya/utils';
import { UserCard } from './UserCard';
import { BookingsScreenNames } from '../../bookings/navigation/ScreenNames';

const Container = styled(BaseView)`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  background-color: #f7f8fa;
`;

const HeadContainer = styled(ScrollView)`
  display: flex;
  flex: 1;
  width: 100%;
`;

const Card = styled(BaseView)<{ isBreaks: boolean }>`
  background-color: ${({ theme }) => theme.colors.surface};
  width: 95%;
  border-color: #dcdfec;
  border-radius: 8px;
  border-width: 1px;
  ${({ isBreaks }) => isBreaks && 'margin-top: 10px;'}
`;

const TopContainer = styled(FlexColumnDirection)`
  width: 100%;
`;

const Separator = styled(FlexColumnCenter)`
  background-color: ${({ theme }) => theme.colors.gray11};
  width: 100%;
  height: 1px;
`;

const BottomContentContainer = styled(FlexColumnCenter)`
  align-items: center;
  width: 100%;
  padding: 0 28px 28px;
`;

const FooterWrapper = styled(BaseView)`
  background-color: #f7f8fa;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 30px;
  shadow-color: #000;
  shadow-offset: 0px -1px;
  shadow-opacity: 0.1;
  shadow-radius: 3px;
  elevation: 5;
`;

const ButtonsContainer = styled(FlexColumnCenter)`
  width: 100%;
  flex: 1;
`;

const GreetingsContainer = styled(BaseView)`
  width: 100%;
  border-bottom-width: 1px;
  padding: 22px 0;
  border-bottom-color: #eaeaea;
  margin-bottom: 18px;
`;

const BookingBreakPlotsContainer = styled(BaseView)`
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
  border-bottom-width: 1px;
  width: 100%;
  padding-right: 28px;
  margin-bottom: 10px;
`;

const AreaName = styled(H6Medium)`
  font-weight: 500;
  text-align: center;
  padding: 15px 0;
  font-size: 16px;
  line-height: 22px;
`;

const ReportView = styled(BaseView)`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const TimeText = styled(BaseText)`
  font-size: 14px;
  font-weight: 500;
  color: #1e2534;
  width: 60px;
`;

const OfflineText = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  color: #252525;
  text-align: center;
  margin-top: 5px;
`;

const TimelineTitleContainer = styled(BaseView)``;
const Captain = styled(BaseView)`
  display: flex;
  align-items: center;
  padding: 20px 0;
`;

const CaptainText = styled(H3Medium)`
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 500;
`;

const TitleText = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  color: #252525;
`;

const SubTitleText = styled(BaseText)`
  font-size: 14px;
  font-weight: bold;
  color: #252525;
  width: 250px;
`;

const TimelineRow = styled(FlexRow)`
  flex: 1;
  align-items: center;
`;

const LocationContainer = styled(BaseView)`
  display: flex;
  padding: 15px 25px;
`;

const ImageContainer = styled(BaseView)``;

const Image = styled.Image`
  width: 100%;
  height: 100px;
  border-radius: 5px;
`;
interface FooterProps {
  showOfflineClockInButton: boolean;
  showOfflineCheckInButton: boolean;
  showOfflineCheckOutButton: boolean;
  showOfflineClockOutButton: boolean;
  showClockInButton: boolean;
  showCheckInButton: boolean;
  showCheckOutButton: boolean;
  showClockOutButton: boolean;
  isDisabled: boolean;
}

const Footer: FC<FooterProps> = ({
  showCheckInButton,
  showCheckOutButton,
  showClockInButton,
  showClockOutButton,
  showOfflineCheckInButton,
  showOfflineCheckOutButton,
  showOfflineClockInButton,
  showOfflineClockOutButton,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const {
    onStartShift,
    onEndShift,
    onCheckInShift,
    onCheckOutShift,
    state,
    isTimeReportLoading,
  } = useContext(TimeclockContext);

  const offlinePrompt = (slug: string, callback: () => void) =>
    Alert.alert(
      t(slug),
      undefined,
      [
        {
          text: t('cancel'),
          onPress: () => {},
        },
        { text: t('ok'), onPress: callback },
      ],
      { cancelable: true }
    );

  if (state === TimeclockState.BreaktimeStarted) {
    return null;
  }

  const hasClockButton =
    showOfflineClockInButton ||
    showOfflineCheckInButton ||
    showOfflineCheckOutButton ||
    showOfflineClockOutButton ||
    showClockInButton ||
    showCheckInButton ||
    showCheckOutButton ||
    showClockOutButton;

  if (!hasClockButton) {
    return null;
  }

  return (
    <FooterWrapper
      style={{
        height:
          showOfflineClockInButton ||
          showOfflineCheckInButton ||
          showOfflineCheckOutButton ||
          showOfflineClockOutButton
            ? 150
            : 100,
      }}
    >
      <ButtonsContainer>
        {showOfflineClockInButton ? (
          <>
            <Button
              type="primary"
              disabled={isDisabled}
              title={t('startShiftOffline')}
              onPress={
                isDisabled
                  ? () => {}
                  : () => offlinePrompt('offlinePromptClockIn', onStartShift)
              }
              fullWidth
              isLoading={isTimeReportLoading}
            />
            <OfflineText>
              It seems you are currently offline... let us know if you
              clocked-in with manager
            </OfflineText>
          </>
        ) : showClockInButton ? (
          <Button
            type="primary"
            disabled={isDisabled}
            title={t('startShift')}
            onPress={isDisabled ? () => {} : onStartShift}
            fullWidth
            isLoading={isTimeReportLoading}
          />
        ) : showOfflineCheckInButton ? (
          <>
            <Button
              type="primary"
              disabled={isDisabled}
              title={t('checkInShiftOffline')}
              onPress={
                isDisabled
                  ? () => {}
                  : () => offlinePrompt('offlinePromptCheckIn', onCheckInShift)
              }
              fullWidth
              isLoading={isTimeReportLoading}
            />
            <OfflineText>
              It seems you are currently offline... let us know if you
              checked-in with manager
            </OfflineText>
          </>
        ) : showCheckInButton ? (
          <Button
            type="primary"
            disabled={isDisabled}
            title={t('timesheetCheckIn')}
            onPress={isDisabled ? () => {} : onCheckInShift}
            fullWidth
            isLoading={isTimeReportLoading}
          />
        ) : showOfflineCheckOutButton ? (
          <>
            <Button
              type="primary"
              disabled={isDisabled}
              title={t('checkOutShiftOffline')}
              onPress={
                isDisabled
                  ? () => {}
                  : () =>
                      offlinePrompt('offlinePromptCheckOut', onCheckOutShift)
              }
              fullWidth
            />
            <OfflineText>
              It seems you are currently offline... let us know if you
              checked-out with manager
            </OfflineText>
          </>
        ) : showCheckOutButton ? (
          <Button
            type="primary"
            disabled={isDisabled}
            title={t('timesheetCheckOut')}
            onPress={isDisabled ? () => {} : onCheckOutShift}
            fullWidth
            isLoading={isTimeReportLoading}
          />
        ) : showOfflineClockOutButton ? (
          <>
            <Button
              type="primary"
              disabled={isDisabled}
              title={t('endShiftOffline')}
              onPress={
                isDisabled
                  ? () => {}
                  : () => offlinePrompt('offlinePromptClockOut', onEndShift)
              }
              fullWidth
              isLoading={isTimeReportLoading}
            />
            <OfflineText>
              It seems you are currently offline... let us know if you
              clocked-out with manager
            </OfflineText>
          </>
        ) : showClockOutButton ? (
          <Button
            type="primary"
            disabled={isDisabled}
            title={t('endShift')}
            onPress={isDisabled ? () => {} : onEndShift}
            fullWidth
            isLoading={isTimeReportLoading}
          />
        ) : null}
      </ButtonsContainer>
    </FooterWrapper>
  );
};

interface QRCodeInstructionsProps {
  showCheckInQR: boolean;
  showCheckOutQR: boolean;
  showClockInQR: boolean;
  showClockOutQR: boolean;
}

const QRCodeInstructions: FC<QRCodeInstructionsProps> = ({
  showCheckInQR,
  showCheckOutQR,
  showClockInQR,
  showClockOutQR,
}) => {
  const { timesheet } = useContext(TimeclockContext);
  const { t } = useTranslation();
  const { mappedLocations } = useLocations();
  const shiftLocation =
    timesheet?.locationId && mappedLocations?.[timesheet?.locationId];

  const instruction = (() => {
    if (
      !showCheckInQR &&
      !showCheckOutQR &&
      !showClockInQR &&
      !showClockOutQR
    ) {
      return null;
    }
    if (showCheckInQR && shiftLocation) {
      return `${t('timesheetProceedToArea')} ${shiftLocation.name}`;
    }
    return t('timesheetQRInstruction', {
      action: t(
        showCheckInQR
          ? 'timesheetCheckIn'
          : showClockInQR
          ? 'clockIn'
          : showCheckOutQR
          ? 'timesheetCheckOut'
          : showClockOutQR
          ? 'clockOut'
          : ''
      ).toLowerCase(),
    });
  })();
  if (!instruction) {
    return null;
  }

  return <AreaName>{instruction}</AreaName>;
};

interface Props {}

export const TimeclockPage: FC<Props> = () => {
  const {
    startTime,
    endTime,
    checkIn,
    checkOut,
    changing,
    config,
    reports,
    employeeSlotBreakPlots,
    state,
    timesheet,
    setIsQrCodeScannerModalOpen,
    refetch,
    isLoadingRefetch,
  } = useContext(TimeclockContext);

  const { t } = useTranslation();
  const { data: userData } = useUser();
  const { mappedBranches } = useBranches();
  const { mappedAccounts } = useAccounts();
  const { mappedLocations } = useLocations();
  const { mappedPositions } = usePositions();
  const { navigate } = useNavigation();

  const isDisabled = useMemo(() => changing !== null, [changing]);

  const { isConnected } = useNetInfo();

  const isInClockInState = !config?.toRequireCheckInWithMobile || !startTime;
  const isInCheckInState =
    config?.toRequireCheckInWithMobile && !checkIn && !!startTime;
  const isInCheckOutState =
    config?.toRequireCheckInWithMobile && checkIn && !checkOut;

  const isInClockOutState =
    (!config?.toRequireCheckInWithMobile || !!checkOut) && !endTime;

  const showClockInQR =
    isInClockInState &&
    config?.mobileClockMethods?.clockIn?.method ===
      CLOCK_METHODS.MANAGER_QR_SCAN;

  const showCheckInQR =
    isInCheckInState &&
    config?.mobileClockMethods?.checkIn?.method ===
      CLOCK_METHODS.MANAGER_QR_SCAN;

  const showClockOutQR =
    isInClockOutState &&
    config?.mobileClockMethods?.clockOut?.method ===
      CLOCK_METHODS.MANAGER_QR_SCAN;

  const showCheckOutQR =
    isInCheckOutState &&
    config?.mobileClockMethods?.checkOut?.method ===
      CLOCK_METHODS.MANAGER_QR_SCAN;

  const showOfflineClockInButton = !isConnected && showClockInQR;
  const showOfflineCheckOutButton = !isConnected && showCheckOutQR;
  const showOfflineCheckInButton = !isConnected && showCheckInQR;
  const showOfflineClockOutButton = !isConnected && showClockOutQR;

  const showClockInButton =
    config?.mobileClockMethods?.clockIn?.method !==
      CLOCK_METHODS.MANAGER_QR_SCAN &&
    // no check in or out required, so the employee can clock out
    !startTime;

  const showCheckInButton =
    isInCheckInState &&
    config?.mobileClockMethods?.checkIn?.method !==
      CLOCK_METHODS.MANAGER_QR_SCAN;

  const showCheckOutButton =
    isInCheckOutState &&
    config?.mobileClockMethods?.checkOut?.method !==
      CLOCK_METHODS.MANAGER_QR_SCAN;

  const showClockOutButton =
    config?.mobileClockMethods?.clockOut?.method !==
      CLOCK_METHODS.MANAGER_QR_SCAN &&
    !!startTime &&
    // no check in or out required, so the employee can clock out
    (!config?.toRequireCheckInWithMobile || // OR:
      // employee checked out and does not need a manager to scan them
      !!checkOut);

  const branch = timesheet?.branchId && mappedBranches?.[timesheet?.branchId];
  const account = branch?.accountId && mappedAccounts?.[branch?.accountId];
  const shiftLocation =
    timesheet?.locationId && mappedLocations?.[timesheet?.locationId];
  const projectLocation =
    timesheet?.shift?.project?.locationId &&
    mappedLocations?.[timesheet?.shift?.project?.locationId];

  const position =
    timesheet?.shift?.positionId &&
    mappedPositions?.[timesheet?.shift.positionId];

  const qrCodeData = useMemo(
    () => ({
      accountId: account?.id,
      photo: userData?.image,
      timesheetId: timesheet?.id,
      projectId: timesheet?.shift?.project?.id,
      branchId: timesheet?.branchId,
      employeeId: timesheet?.employeeId,
      shiftId: timesheet?.shift?.id,
      clientProjectId: timesheet?.order?.clientProjectId,
      isEmployeeScan: true,
    }),
    [
      account?.id,
      timesheet?.branchId,
      timesheet?.employeeId,
      timesheet?.id,
      timesheet?.order?.clientProjectId,
      timesheet?.shift?.id,
      timesheet?.shift?.project?.id,
      userData?.image,
    ]
  ) as IQRShiftEmployee;

  return (
    <Container>
      <HeadContainer
        contentContainerStyle={{
          alignItems: 'center',
        }}
        refreshControl={
          <RefreshControl onRefresh={refetch} refreshing={isLoadingRefetch} />
        }
      >
        {state === TimeclockState.InitialState ? (
          <ClockStart />
        ) : (
          <ClockOngoing />
        )}
        <Card isBreaks={config?.toRequireClockBreaks}>
          {state === TimeclockState.InitialState &&
            shiftLocation &&
            !!shiftLocation.image && (
              <LocationContainer>
                <ImageContainer
                  as={RipplePressable}
                  onPress={() => openMap(shiftLocation)}
                >
                  <Image
                    source={{ uri: shiftLocation.image }}
                    resizeMode="cover"
                    resizeMethod="scale"
                  />
                </ImageContainer>
              </LocationContainer>
            )}

          <BottomContentContainer>
            <TopContainer>
              {(employeeSlotBreakPlots || []).length > 0 && (
                <BookingBreakPlotsContainer>
                  <BookingBreakPlots
                    employeeSlotBreakPlots={employeeSlotBreakPlots}
                  />
                </BookingBreakPlotsContainer>
              )}

              <QRCodeInstructions
                showCheckInQR={showCheckInQR}
                showCheckOutQR={showCheckOutQR}
                showClockInQR={showClockInQR}
                showClockOutQR={showClockOutQR}
              />

              {showClockInQR ? (
                <TimeclockQRScan
                  onScan={() => setIsQrCodeScannerModalOpen('start')}
                  data={qrCodeData}
                />
              ) : showCheckInQR ? (
                <TimeclockQRScan
                  onScan={() => setIsQrCodeScannerModalOpen('checkIn')}
                  data={qrCodeData}
                />
              ) : showCheckOutQR ? (
                <TimeclockQRScan
                  onScan={() => setIsQrCodeScannerModalOpen('checkOut')}
                  data={qrCodeData}
                />
              ) : (
                showClockOutQR && (
                  <TimeclockQRScan
                    onScan={() => setIsQrCodeScannerModalOpen('end')}
                    data={qrCodeData}
                  />
                )
              )}
              {!!timesheet.captainType && (
                <>
                  <Separator />
                  <Captain>
                    <CaptainText>
                      {t(
                        timesheet.captainType === CAPTAIN_TYPE_ENUM.AREA
                          ? 'areaCaptain'
                          : timesheet.captainType === CAPTAIN_TYPE_ENUM.SHIFT
                          ? 'shiftCaptain'
                          : timesheet.captainType === CAPTAIN_TYPE_ENUM.EVENT
                          ? 'eventCaptain'
                          : ''
                      )}
                    </CaptainText>
                    <Button
                      type="primaryReversed"
                      title={t('captain')}
                      fullWidth
                      paddingVertical={10}
                      onPress={() =>
                        navigate(BookingsScreenNames.MAIN, {
                          screen: BookingsScreenNames.CAPTAIN,
                          params: {
                            bookingId: timesheet?.shift?.project?.id,
                            shiftDate: timesheet?.shift?.startTime
                              ? moment(timesheet?.shift?.startTime).toDate()
                              : moment(
                                  timesheet?.shift?.project?.date
                                ).toDate(),
                          },
                        })
                      }
                    />
                  </Captain>
                </>
              )}
              <Separator />

              {state !== TimeclockState.TimeclockEnded &&
                state !== TimeclockState.InitialState && (
                  <ReportView>
                    <Timeline
                      data={reports}
                      item={({
                        nob: Nob,
                        data: { timestamp, title, subTitle },
                      }: {
                        data: IReport;
                        nob: FC<any>;
                      }) => (
                        <TimelineRow>
                          <TimeText>
                            {moment(timestamp).format('H:mm')}
                          </TimeText>
                          <Nob />
                          <TimelineTitleContainer>
                            <TitleText>{title}</TitleText>
                            {subTitle && (
                              <SubTitleText>{subTitle}</SubTitleText>
                            )}
                          </TimelineTitleContainer>
                        </TimelineRow>
                      )}
                    />
                  </ReportView>
                )}
              {state !== TimeclockState.InitialState && <Separator />}
              <UserCard
                firstName={userData?.firstName}
                lastName={userData?.lastName}
                image={userData?.image}
                branchLogo={branch?.logo || account?.logo}
                branchName={branch?.name}
                positionName={position && t(position?.slug)}
                projectLocationName={projectLocation?.name}
                shiftLocationName={shiftLocation?.name}
              />
            </TopContainer>
          </BottomContentContainer>
        </Card>
      </HeadContainer>
      <Footer
        isDisabled={isDisabled}
        showCheckInButton={showCheckInButton}
        showCheckOutButton={showCheckOutButton}
        showClockInButton={showClockInButton}
        showClockOutButton={showClockOutButton}
        showOfflineCheckInButton={showOfflineCheckInButton}
        showOfflineCheckOutButton={showOfflineCheckOutButton}
        showOfflineClockInButton={showOfflineClockInButton}
        showOfflineClockOutButton={showOfflineClockOutButton}
      />
    </Container>
  );
};
