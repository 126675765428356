import {
  RouteProp,
  useIsFocused,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import { images } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import {
  useBookings,
  useBranches,
  useLabels,
  usePositions,
  useTimesheets,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  CustomLoaderWrapped,
  FlexColumnDirection,
  FlexRow,
  H2Bold,
  H3Bold,
  H3Medium,
  LargeProfileImage as BaseProfileImage,
} from '@violetta/ubeya/ui';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { Image as BaseImage } from 'react-native';
import { ScrollView } from 'react-native';
import { SvgXml } from 'react-native-svg';
import QRCode from 'react-qr-code';
import styled from 'styled-components/native';
import { AppContext } from '../../main/AppContext';

const Container = styled(FlexColumnDirection)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  position: relative;
  padding-bottom: 30px;
`;

const Wrapper = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.lightSurface};
  position: relative;
  display: flex;
  flex: 1;
`;

const PaddedContainer = styled(BaseView)<{ $withImage: boolean }>`
  padding: 0 16px;
  z-index: 10;
  margin-top: ${({ $withImage }) => ($withImage ? '-100px' : '0')};
`;

const ProfileImage = styled(BaseProfileImage)`
  width: 160px;
  height: 160px;
  border-radius: 10px;
`;

const UserContainer = styled(BaseView)`
  margin: 10px 15px;

  border-radius: 15px;
  background-color: #fff;
  shadow-color: rgba(0, 0, 0, 0.82);
  shadow-opacity: 0.2;
  shadow-offset: 0 1px;
  shadow-radius: 2px;
  elevation: 4;
  position: relative;
`;
const GardientContainer = styled(BaseView)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const ClientContent = styled(FlexRow)`
  padding: 10px 20px;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
`;
const ClientDetails = styled(BaseView)`
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  background-color: #fff;
`;
const UserContent = styled(FlexRow)`
  padding: 10px 20px;
  align-items: center;
`;
const UserDetails = styled(BaseView)`
  flex: 1;
`;

const ClientName = styled(H3Medium)`
  text-align: left;
  font-weight: 400;
  font-size: 17px;
`;
const UserName = styled(H3Bold)`
  text-align: left;
  color: #fff;
  font-size: 20px;
`;

const UserTitle = styled(H2Bold)`
  text-align: left;
`;

const BranchLogo = styled(BaseImage)`
  width: 80px;
  height: 80px;
  border-radius: 40;
`;

const QrCodeContainer = styled(BaseView)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;

interface Props {}

type ParamList = {
  params: {
    bookingId: number;
  };
};

export const BookingPassCardScreen: FC<Props> = () => {
  const {
    bookings: { selectBooking, selectedBooking },
  } = useContext(AppContext);

  const { goBack } = useNavigation();

  const {
    params: { bookingId },
  } = useRoute<RouteProp<ParamList, 'params'>>();

  const isFocused = useIsFocused();
  const { data: userData } = useUser();
  const { isLoading: isLoadingLabels } = useLabels();
  const { isLoading: isLoadingPositions } = usePositions();
  const { mappedBranches } = useBranches();
  const { t } = useTranslation();
  const { timesheets, isLoading: isLoadingTimesheets } = useTimesheets();
  const {
    mappedBookings,
    isLoading: isLoadingBookings,
    isStale,
  } = useBookings();
  const isLoading =
    isLoadingBookings ||
    isLoadingLabels ||
    isLoadingPositions ||
    isLoadingTimesheets;
  const booking = useMemo(
    () => mappedBookings[bookingId] || {},
    [bookingId, mappedBookings]
  );
  const {
    project,
    slot,
    employeeId,
    confirmation,
    comments,
    compoundedName,
    metadata,
  } = booking || {};

  const { accountId, branchId, projectId, os } = metadata || {};

  const {
    uniformId,
    clientName,
    clientLogo,
    date,
    meetingSpot,
    onsiteContactPhone,
    onsiteContact,
    comments: generalComments,
  } = project || {};

  useEffect(() => {
    let timeout;

    if (isFocused && !isStale && !isLoading && !booking?.id) {
      timeout = setTimeout(() => {
        if (isFocused && !isLoading && !booking?.id) {
          goBack();
        }
        selectBooking(booking);
      }, 1000);
    }
    selectBooking(booking);
    if (bookingId && booking && selectedBooking?.id !== bookingId) {
      selectBooking(booking);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [
    mappedBookings,
    isLoading,
    goBack,
    bookingId,
    selectBooking,
    selectedBooking,
    isFocused,
    isStale,
    booking,
  ]);

  const timesheet = useMemo(
    () => timesheets.find(({ shift }) => shift && shift.id === bookingId),
    [timesheets, bookingId]
  );

  const locationId = useMemo(() => {
    const locationId = booking?.locationId;
    const projectLocationId = booking?.project?.locationId;

    return locationId || projectLocationId || null;
  }, [booking?.locationId, booking?.project?.locationId]);

  const slotId = slot?.id;

  const shouldRender = useMemo(
    () => !isLoading && mappedBookings[bookingId]?.id,
    [bookingId, isLoading, mappedBookings]
  );

  const branch = useMemo(
    () => mappedBranches[branchId],
    [branchId, mappedBranches]
  );

  const qrCodeData = useMemo(
    () => ({
      accountId,
      photo: userData?.image,
      timesheetId: timesheet?.id,
      projectId,
      branchId,
      employeeId,
      slotId,
      shiftId: bookingId,
      clientAccountId: os?.clientAccountId,
      clientBranchId: os?.clientBranchId,
      clientProjectId: os?.clientProjectId,
    }),
    [
      accountId,
      bookingId,
      branchId,
      employeeId,
      os?.clientAccountId,
      os?.clientBranchId,
      os?.clientProjectId,
      projectId,
      slotId,
      timesheet?.id,
      userData?.image,
    ]
  );

  return (
    <Wrapper>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: confirmation
            ? timesheet?.startTime
              ? 100
              : 50
            : timesheet?.endTime
            ? 25
            : 0,
        }}
      >
        <Container>
          {!shouldRender && <CustomLoaderWrapped />}
          {shouldRender && (
            <>
              <UserContainer>
                {/* <UserTitle>{t('bookingPassCard')}</UserTitle> */}
                <GardientContainer>
                  <SvgXml
                    xml={images.svgs.passGradient}
                    width="100%"
                    height="100%"
                    preserveAspectRatio="xMinyMin slice"
                  />
                </GardientContainer>

                <UserContent>
                  {/* <UserTitle>{t('bookingPassCard')}</UserTitle> */}
                  <UserDetails>
                    <UserName>
                      {userData?.firstName} {userData?.lastName}
                    </UserName>
                  </UserDetails>
                  {!!userData?.image && (
                    <ProfileImage source={{ uri: userData.image }} />
                  )}
                </UserContent>
                <ClientContent>
                  <ClientDetails>
                    {branch?.logo && (
                      <BranchLogo source={{ uri: branch.logo }} />
                    )}
                    <ClientName>{branch?.name}</ClientName>
                  </ClientDetails>
                  {clientName && (
                    <ClientDetails>
                      {clientLogo && (
                        <BranchLogo source={{ uri: clientLogo }} />
                      )}
                      <ClientName>{clientName}</ClientName>
                    </ClientDetails>
                  )}
                </ClientContent>
              </UserContainer>
              <QrCodeContainer>
                <QRCode value={JSON.stringify(qrCodeData)} size={200} />
              </QrCodeContainer>
              {/* <PaddedContainer $withImage={false}>
                <BookingDetails
                  withLogo={false}
                  name={compoundedName}
                  date={date}
                  locationId={locationId}
                  uniformId={uniformId}
                  meetingSpot={meetingSpot}
                  clientName={clientName}
                  onsiteContact={onsiteContact}
                  onsiteContactPhone={onsiteContactPhone}
                  comments={comments}
                  generalComments={generalComments}
                  timesheet={timesheet}
                  booking={mappedBookings[bookingId]}
                />
              </PaddedContainer> */}
            </>
          )}
        </Container>
      </ScrollView>
    </Wrapper>
  );
};
