import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { createAdminStoreKey, mappedArray } from '@violetta/ubeya/utils';
import moment from 'moment';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { IPayroll } from '../entities';

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const payrolls = data || [];

    const mappedPayrollsByMonthAndYear = payrolls.reduce((acc, payroll) => {
      const date = moment(payroll.date).format('YYYY-MM');

      if (acc[date]) {
        acc[date] = [...acc[date], payroll].sort((payrollA, payrollB) =>
          new Date(payrollA.date).getTime() -
            new Date(payrollB.date).getTime() >
          0
            ? 1
            : -1
        );
      } else {
        acc[date] = [payroll];
      }
      return acc;
    }, {});

    const mappedPayrolls = mappedArray(payrolls);

    return { payrolls, mappedPayrolls, mappedPayrollsByMonthAndYear };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const usePayroll = () => {
  const { data: userData } = useUser();
  const storeKey = useMemo(() => createAdminStoreKey(['payroll']), []);
  const { isPending, data, refetch } = useQuery<{ data: IPayroll[] }>({
    queryKey: storeKey,
    queryFn: () => api.getPayroll(),
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const {
    payrolls = [],
    mappedPayrolls = {},
    mappedPayrollsByMonthAndYear = {},
  } = data || {};

  return {
    isLoading: isPending,
    payrolls,
    mappedPayrolls,
    mappedPayrollsByMonthAndYear,
    refetch,
  };
};
