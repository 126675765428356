import React, { FC } from 'react';

interface Props {
  source: { uri: string };
  originWhitelist?: string[];
  renderLoading?: () => any;
  startInLoadingState?: boolean;
}

export const WebView: FC<Props> = () => <></>;
