import React, { FC } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { SelectProps } from '../Select/types';

type Props<T> = T extends SelectProps<infer Option, infer SelectedOption>
  ? {
      fieldProps: FieldProps<SelectedOption[], FieldRenderProps<SelectedOption[]>>;
      props: SelectProps<Option, SelectedOption>;
      selectComponent: FC<SelectProps<Option, SelectedOption>>;
    }
  : never;

type InferSelectedOption<T> = T extends SelectProps<any, infer SelectedOption> ? SelectedOption : never;

export const SelectField = <T extends SelectProps<any, any>>({
  fieldProps,
  props,
  selectComponent: SelectComponent,
}: Props<T>) => (
  <Field<InferSelectedOption<T>[]>
    {...fieldProps}
    render={({ input, meta }) => (
      <SelectComponent {...props} onSelected={input.onChange} value={input.value} meta={meta} />
    )}
  />
);
