import React, { FC } from 'react';
import { InferProps } from '@violetta/ubeya/utils';
import { Field } from 'react-final-form';
import { TimePicker } from '../TimePicker';
import { FormFieldProps } from './types';

export const TimePickerField: FC<
  FormFieldProps<Date, InferProps<typeof TimePicker>>
> = ({ fieldProps, props, component: Component }) => (
  <Field<Date>
    {...fieldProps}
    render={({ input, meta }) => (
      <Component
        {...props}
        onChange={input.onChange}
        value={input.value}
        initialValue={props?.initialValue}
        meta={meta}
      />
    )}
  />
);
