import React, { FC, useState } from 'react';
import { Pressable, StyleProp, ViewStyle } from 'react-native';
import Collapsible from 'react-native-collapsible';
import styled from 'styled-components/native';
import { ChevronDown, ChevronUp } from '../Icons';
import { FlexRowBetween } from '../Layout';

const PressableContainer = styled.View`
  ${({ $withBorder }) =>
    $withBorder &&
    `border-bottom-color: #e7e9eb;
    border-bottom-width: 1px;
  `}
`;

interface Props {
  trigger: FC<any>;
  containerStyle?: StyleProp<ViewStyle>;
  triggerStyle?: StyleProp<ViewStyle>;
  hideRightChevron?: boolean;
  isCollpased?: boolean;
  withBorder?: boolean;
  triggerParams?: any;
}

export const BaseCollapsible: FC<Props> = ({
  trigger: Trigger,
  children,
  containerStyle,
  triggerStyle,
  hideRightChevron,
  isCollpased = true,
  withBorder = true,
  triggerParams = undefined,
  disabled = false,
}) => {
  const [isCollapsed, setCollapsed] = useState(isCollpased);

  if (Trigger === undefined) {
    return children;
  }

  return (
    <PressableContainer style={containerStyle} $withBorder={withBorder}>
      <Pressable
        onPress={() => {
          setCollapsed(!isCollapsed);
        }}
      >
        <FlexRowBetween style={triggerStyle}>
          <Trigger isCollapsed={isCollapsed} triggerParams={triggerParams} />
          {!disabled &&
            !hideRightChevron &&
            (isCollapsed ? <ChevronDown /> : <ChevronUp />)}
        </FlexRowBetween>
      </Pressable>
      {!disabled && (
        <Collapsible collapsed={isCollapsed}>{children}</Collapsible>
      )}
    </PressableContainer>
  );
};
