import React, { useMemo, useCallback, useState } from 'react';
import buffer from 'buffer';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { FieldTypes } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { isFileImage, uiAlert, pickImg } from '@violetta/ubeya/utils';
import moment from 'moment';

import { useField } from 'react-final-form';
import DocumentPicker from 'react-native-document-picker';
import RNFS from 'react-native-fs';

import styled from 'styled-components/native';
import { Button } from '../Buttons';
import { DocumentCard } from '../Document';
import { SvgIcon } from '../Icons';
import { BaseView } from '../Layout';
import { CustomLoader } from '../Loaders/CustomLoader';
import { BaseText } from '../Typography';
import { Platform } from 'react-native';
import { FormGroup } from '../Forms/FormGroup';
import { DatePickerField } from './DatePickerField';
import { DateInputPicker } from '../DatePicker/DateInputPicker';

const ALLOWED_DOC_UPLOAD_TYPES = [
  DocumentPicker.types.video,
  DocumentPicker.types.doc,
  DocumentPicker.types.docx,
  DocumentPicker.types.pdf,
  DocumentPicker.types.xls,
  DocumentPicker.types.xlsx,
];

const Wrapper = styled(BaseView)`
  display: flex;
`;
const Container = styled(BaseView)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
`;

const ButtonText = styled(BaseText)`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  color: #858585;
  margin-left: 5px;
`;

const { Buffer } = buffer;

export const UploadDocumentField = ({ id, type, field }) => {
  const { t } = useTranslation();
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const {
    input: { value: documentFieldValue, onChange: setDocumentFieldValue },
  } = useField(`field-${id}-${type}`);

  const { navigate } = useNavigation();

  const hasExpiration = useMemo(() => field?.hasExpiration, [field]);

  const pickImgMethod = useCallback(async () => {
    try {
      setIsLoadingFile(true);
      const assets = await pickImg({
        texts: {
          cancel: t('cancel'),
          choosePhoto: t('choosePhoto'),
          takeAPhoto: t('takePhoto'),
          title: t('chooseImageUploadMethod'),
        },
      });

      if (!assets) {
        setIsLoadingFile(false);
        return;
      }

      const parsedAssets = await Promise.all(
        assets.map(async ({ type, uri, base64 }) => ({
          fieldTypeId: FieldTypes.FIELD_TYPE_DOCUMENT,
          content: base64,
          type,
          link: uri,
          ...(hasExpiration && { expirationDate: moment().add(1, 'days') }),
        }))
      );

      setDocumentFieldValue(parsedAssets);
      setIsLoadingFile(false);
    } catch (err) {
      setIsLoadingFile(false);
      // uiAlert({ title: t('unknown'), save: { text: t('ok') } });
    }
  }, [hasExpiration, setDocumentFieldValue, t]);

  const pickDoc = useCallback(async () => {
    try {
      setIsLoadingFile(true);
      const res = await DocumentPicker.pick({
        type: ALLOWED_DOC_UPLOAD_TYPES,
        allowMultiSelection: false,
      });
      const parsedRes = res.map((r) => ({
        ...r,
        name: r.name.replaceAll(' ', '_'),
        uri: r.uri.replaceAll('%20', ' '),
      }));

      const parsedAssets = await Promise.all(
        parsedRes.map(async ({ uri, type, name }) => {
          const content = await RNFS.readFile(decodeURI(uri), 'base64');

          return {
            fieldTypeId: FieldTypes.FIELD_TYPE_DOCUMENT,
            content,
            title: name || '',
            type,
            link: uri,
            ...(hasExpiration && { expirationDate: moment().add(1, 'days') }),
          };
        })
      );

      setDocumentFieldValue(parsedAssets);
      setIsLoadingFile(false);
    } catch (err) {
      setIsLoadingFile(false);
      // uiAlert({ title: t('unknown'), save: { text: t('ok') } });
    }
  }, [hasExpiration, setDocumentFieldValue]);

  const readWebFile = useCallback(
    (e) => {
      setIsLoadingFile(true);
      let parsedAssets = [];
      (Array.from(e.target.files) || []).forEach((file) => {
        const uri = URL.createObjectURL(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (ev) => {
          const tempRes = ev?.target?.result?.toString().split(',')?.[1] || '';
          parsedAssets = [
            ...parsedAssets,
            {
              fieldTypeId: FieldTypes.FIELD_TYPE_DOCUMENT,
              content: tempRes,
              title: file?.name || '',
              type: file?.type,
              link: uri,
              ...(hasExpiration && { expirationDate: moment().add(1, 'days') }),
            },
          ];
          setDocumentFieldValue(parsedAssets);
        };
      });
      setIsLoadingFile(false);
    },
    [hasExpiration, setDocumentFieldValue]
  );

  const openAttachmentImagesGallery = useCallback(
    ({ id: selectedId }) => {
      const images = (documentFieldValue || []).filter(
        (document) => document?.link && isFileImage(document?.link)
      );
      const selectedIdx = images.findIndex(({ id }) => selectedId === id);
      if (selectedIdx === -1) {
        return;
      }
      navigate('gallery', {
        items: images.map(({ link }, idx) => ({
          idx,
          metadata: {},
          type: 'image',
          uri: link,
        })),
        idx: selectedIdx,
      });
    },
    [documentFieldValue, navigate]
  );

  const onRemoveItem = async ({ item }) => {
    const tempDocFieldValue = documentFieldValue;
    if (tempDocFieldValue) {
      const res = await uiAlert({
        title: t('areYouSure'),
        save: {
          text: t('yes'),
        },
        cancel: {
          text: t('no'),
        },
      });
      if (!res) {
        return;
      }

      const filteredDocFieldValue = tempDocFieldValue.filter(
        (val) => val?.link !== item?.link
      );
      setDocumentFieldValue(filteredDocFieldValue);
    }
  };

  if (isLoadingFile) {
    return <CustomLoader />;
  }

  return (
    <Wrapper>
      <Container>
        {(!documentFieldValue || documentFieldValue.length === 0) && (
          <Button
            type="primaryReversed"
            onPress={Platform.OS !== 'web' ? pickImgMethod : () => {}}
            fontSize={16}
            radius={2}
            style={{
              borderColor: '#d2d2d2',
              marginRight: 10,
            }}
            textStyle={{
              color: '#858585',
            }}
            paddingHorizontal={8}
            disabled={isLoadingFile}
            paddingVertical={10}
          >
            <SvgIcon width={18} height={18} xml={icons.uiIcons.imgIcon} />
            <ButtonText>Photo</ButtonText>
          </Button>
        )}
        {(!documentFieldValue || documentFieldValue?.length === 0) && (
          <Button
            type="primaryReversed"
            onPress={pickDoc}
            fontSize={16}
            radius={2}
            style={{
              borderColor: '#d2d2d2',
            }}
            textStyle={{
              color: '#858585',
            }}
            paddingHorizontal={8}
            disabled={isLoadingFile}
            paddingVertical={10}
          >
            <SvgIcon width={18} height={18} xml={icons.uiIcons.fileIcon} />
            <ButtonText>File</ButtonText>
          </Button>
        )}
        {(!documentFieldValue || documentFieldValue?.length === 0) &&
          Platform.OS === 'web' && (
            <input
              type="file"
              accept="image/*, video/*"
              onChange={readWebFile}
              style={{
                width: 75,
                height: 45,
                position: 'absolute',
                opacity: 0,
              }}
            />
          )}
        {(!documentFieldValue || documentFieldValue?.length === 0) &&
          Platform.OS === 'web' && (
            <input
              type="file"
              accept={ALLOWED_DOC_UPLOAD_TYPES.join(', ')}
              onChange={readWebFile}
              style={{
                width: 60,
                height: 45,
                position: 'absolute',
                left: 90,
                opacity: 0,
              }}
            />
          )}
        {documentFieldValue?.length > 0 &&
          documentFieldValue?.map((document) => (
            <DocumentCard
              key={document?.content}
              document={{ link: document?.link, title: document?.title }}
              openAttachmentImagesGallery={openAttachmentImagesGallery}
              onRemoveItem={onRemoveItem}
              canRemoveItem
            />
          ))}
      </Container>
      {hasExpiration && documentFieldValue?.length > 0 && (
        <FormGroup
          error={
            documentFieldValue[0]?.expirationDate &&
            moment().isSameOrAfter(moment(documentFieldValue[0].expirationDate))
          }
          title={t('expirationDate')}
          isRequired
        >
          <DatePickerField
            fieldProps={{
              name: `field-${id}-${type}[0].expirationDate`,
            }}
            props={{}}
            component={DateInputPicker}
          />
        </FormGroup>
      )}
    </Wrapper>
  );
};
