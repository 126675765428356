import React, { FC } from 'react';
import { IBranch } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Card as BaseCard,
  FlexRow,
  H5Medium,
  BaseImage,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const Container = styled(BaseView)``;
const Wrapper = styled(BaseCard)`
  border-width: 0px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.surface};
  elevation: 0;
  shadow-color: rgba(0, 0, 0, 0);
`;

const Box = styled(FlexRow)`
  padding: 16px 13px;
  align-items: center;
`;

const Details = styled(FlexRow)`
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
  padding-left: 15px;
`;

const Title = styled(H5Medium)`
  flex-wrap: wrap;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: normal;
  font-style: normal;
`;

const ApprovalChip = styled(BaseView)`
  padding: 6px 15px;
  background-color: ${({ theme }) => theme.colors.seconday2};
  border-radius: 4px;
  max-width: 180px;
  margin: 0 5px;
`;

const ApprovalText = styled(BaseText)`
  font-size: 9px;
  font-weight: 500;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

const ImageContainer = styled(BaseView)`
  width: 22px;
  height: 22px;
`;

const BranchLogo = styled(BaseImage)`
  width: 30px;
  height: 30px;
  border-radius: 15px;
`;

interface Props {
  branch: IBranch;
}

export const BranchRequestCard: FC<Props> = ({ branch }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <Box>
          <ImageContainer>
            <BranchLogo source={{ uri: branch?.logo }} resizeMode="cover" />
          </ImageContainer>
          <Details>
            <Title>
              {t('joinBranchPendingApproval', { name: branch?.name || '' })}
            </Title>
          </Details>
          <ApprovalChip>
            <ApprovalText>
              {t('pendingBranchRequest').toUpperCase()}
            </ApprovalText>
          </ApprovalChip>
        </Box>
      </Wrapper>
    </Container>
  );
};
