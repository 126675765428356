import React, { FC, useCallback } from 'react';
import { IPostAttachment } from '@violetta/ubeya/entities';
import styled from 'styled-components/native';
import {
  FlexParent,
  FlexRowCenter,
  H5Medium,
  Link,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { Linking } from 'react-native';
import { icons } from '@violetta/ubeya/assets';

interface RawAttachmentProps {
  attachments: IPostAttachment[];
}

const AttachmentText = styled(H5Medium)`
  font-weight: 500;
  margin-left: 15px;
`;

const AttachmentContainer = styled(FlexRowCenter)`
  padding: 10px;
`;

const Container = styled(FlexParent)<{ fixedHeight: boolean }>`
  height: ${({ fixedHeight }) => (fixedHeight ? '200px' : 'auto')};
`;

export const RawAttachments: FC<RawAttachmentProps> = ({ attachments }) => {
  const openFile = useCallback((link: string) => {
    Linking.openURL(link);
  }, []);

  return (
    <Container fixedHeight={false}>
      {attachments.map(({ title, link }, id) => (
        <AttachmentContainer key={id}>
          <SvgIcon height={18} width={18} xml={icons.symbolIcons.requests} />
          <Link component={AttachmentText} onPress={() => openFile(link)}>
            {title || link.slice(link.lastIndexOf('/') + 1)}
          </Link>
        </AttachmentContainer>
      ))}
    </Container>
  );
};
