import { useCallback, useMemo } from 'react';
import { IChatRoom } from '@violetta/ubeya/entities';
import { mappedArray } from '@violetta/ubeya/utils';
import useBoard from './useBoard';

export const useRooms = ({
  id,
  type,
  imageFunc,
  nameFunc,
  participantFunc,
}: {
  type: 'admin' | 'user';
  id: number;
  imageFunc: (params: {
    metadata?: any;
    participants: string[];
    contextId: number;
  }) => string;
  nameFunc: (params: {
    metadata?: any;
    participants: string[];
    contextId: number;
    roomName: string;
  }) => string;
  participantFunc?: (id: string) => string | null;
}) => {
  const {
    data,
    isLoading,
    myId,
    isLoadingMoreMessages,
    readMoreMessages,
    sendMessage,
    storeKey,
    refetchAllRooms,
  } = useBoard({
    id,
    type,
  });

  const formattedData = useMemo(
    () =>
      (data || []).map(
        ({ roomId, name, contextId, readByPayload, metadata, ...rest }) => {
          return {
            name: nameFunc({
              metadata,
              contextId: Number(contextId),
              roomName: name,
            }), // metadata?.projectId || !contextId ? name : mappedAccounts?.[contextId]?.name || '',
            image: imageFunc({ metadata, contextId: Number(contextId) }), //  mappedAccounts?.[contextId]?.logo,
            ...rest,
            contextId,
            metadata,
            roomId,
            unread: !(readByPayload || []).find(
              ({ type, id }) => `${id}-${type}` === myId
            ),
          };
        }
      ),
    [data, imageFunc, myId, nameFunc]
  );

  const mappedRooms = useMemo(
    () => mappedArray(formattedData),
    [formattedData]
  ) as { [key: number]: IChatRoom };

  const mappedRoomsByContextId = useMemo(
    () => mappedArray(formattedData, (value: IChatRoom) => value.contextId),
    [formattedData]
  ) as { [key: number]: IChatRoom };

  const hasUnread = useMemo(
    () => formattedData.some(({ unread }) => !!unread),
    [formattedData]
  );

  return {
    isLoading,
    data: formattedData,
    mappedRooms,
    mappedRoomsByContextId,
    isLoadingMoreMessages,
    readMoreMessages,
    sendMessage,
    storeKey,
    refetchAllRooms,
    hasUnread,
  };
};
