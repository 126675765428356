import { atom } from 'recoil';

export interface ILocalAvailabilities {
	id: number;
	accountId: number;
	branchId: number;
	date: Date;
	labelId: number;
	status: 0 | 1 | 2;
}

export type LocalAvailabilitiesDataType = {
	data: ILocalAvailabilities[];
	dirty: boolean;
};

export const localAvailabilitiesAtom = atom<LocalAvailabilitiesDataType>({
	key: 'localAvailabilities',
	default: { data: [], dirty: false },
});
