import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { IBoardPayload, useRequests } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  Button,
  FlatList,
  FlexRowBetween,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import { isEditingGeneralAvailabilityAtom } from '../../../state';
import { AppContext } from '../../main/AppContext';
import { GeneralAvailabilityDay } from '../components/GeneralAvailabilityDay';
import { GeneralAvailabilityModal } from '../components/GeneralAvailabilityModal';

const Container = styled.SafeAreaView`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const ModalContainer = styled(BaseView)`
  padding: 0 15px 15px 15px;
  background-color: ${({ theme }) => theme.colors.primaryLight2};
`;

const ModalRow = styled(FlexRowBetween)`
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray16};
  padding: 15px 0;
`;

const ModalRowText = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: #818181;
`;

const ModalRowSecondaryText = styled(BaseText)`
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  text-align: center;
  color: #6467ef;
  width: 54px;
`;

const ApprovalContainer = styled(BaseView)`
  align-items: center;
  justify-content: center;
`;

const ApprovalChip = styled<{ status: number }>(BaseView)`
  padding: 6px 22px;
  background-color: ${({ theme, status }) =>
    status === 0
      ? theme.colors.lighterOrange
      : status === 1
      ? theme.colors.lighterGreen
      : theme.colors.lighterRed};
  margin: 8px 0 28px 0;
  max-width: 180px;
`;

const ApprovalText = styled<{ status: number }>(BaseText)`
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
  color: ${({ theme, status }) =>
    status === 0
      ? theme.colors.seconday2
      : status === 1
      ? theme.colors.green3
      : theme.colors.seconday2};
`;

const ButtonContainer = styled(BaseView)`
  position: absolute;
  bottom: 15px;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  height: 80px;
  width: 100%;
  padding: 10px;
  z-index: 1000;
`;

interface Props {}

export interface Day {
  day: string;
  id: number;
  dow: number;
  title: string;
  period: IBoardPayload;
}

type ParamList = {
  params: {
    params: {
      start: string | null;
      end: string | null;
      type: 'Available' | 'Not Available' | 'Partial' | null;
      day: number | null;
    };
  };
};

export const GeneralAvailabilityScreen: FC<Props> = () => {
  const { params: routeParams } = useRoute<RouteProp<ParamList, 'params'>>();
  const {
    generalRequests,
    addGeneralRequest,
    isAddGeneralRequestLoading,
    isLoading,
    refetch,
    hasApprovedRequest,
  } = useRequests();
  const [days, setDays] = useState<Day[]>([]);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [newAvailability, setNewAvailability] = useState<IBoardPayload[]>([]);
  const [, setIsEditingGeneralAvailability] = useRecoilState(
    isEditingGeneralAvailabilityAtom
  );
  const {
    profile: { selectedAccount },
  } = useContext(AppContext);
  const generalRequest = useMemo(
    () => generalRequests.find((req) => req?.accountId === selectedAccount?.id),
    [generalRequests, selectedAccount?.id]
  );
  const boardData = useMemo(
    () => generalRequest?.data?.board,
    [generalRequest?.data?.board]
  );
  const { setParams } = useNavigation();

  const randomWeek = Array(7)
    .fill(0)
    .map((v, index) => moment().weekday(index));
  const tempDays = randomWeek.map((weekDay, index) => {
    const momentedDay = moment(weekDay);

    return {
      day: momentedDay.format('ddd'),
      title: momentedDay.format('dddd'),
      id: index,
      dow: momentedDay.day(),
    };
  });

  useEffect(() => {
    if (boardData?.length > 0) {
      const updatedDays = tempDays.map((day) => {
        const dayBoard = (boardData || []).find(
          (board) => board?.day === day?.dow
        );
        return { ...day, period: dayBoard };
      });
      setDays(updatedDays);
    } else {
      const updatedDays = tempDays.map((day) => ({
        ...day,
        period: { start: null, end: null, day: day.dow, type: 'Available' },
      }));
      setDays(updatedDays);
    }
  }, [boardData]);

  useEffect(() => {
    const newDay = days.find((day) => day.dow === routeParams?.params?.day);
    if (newDay && routeParams?.params) {
      newDay.period = routeParams?.params;

      const newAvailabilityTemp = [
        ...newAvailability.filter(
          (availability) => availability.day === routeParams?.params?.day
        ),
        routeParams?.params,
      ];

      setNewAvailability(newAvailabilityTemp);

      setDays(days);
    }
  }, [routeParams]);

  useEffect(() => {
    if (generalRequest?.data?.status === 0 || !generalRequest?.data?.status) {
      setIsEditingGeneralAvailability(true);
    } else if (generalRequest?.data?.status === 1) {
      setIsEditingGeneralAvailability(false);
    }
    return () => {
      if (generalRequest?.data?.status === 1) {
        setIsEditingGeneralAvailability(false);
      }
    };
  }, [generalRequest?.data?.status, setIsEditingGeneralAvailability]);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const modalizeRef = useRef<Modalize>(null);
  const { t } = useTranslation();
  const status = generalRequest?.data?.status;

  const accountId = useMemo(() => selectedAccount?.id, [selectedAccount?.id]);

  const updateRequest = useCallback(
    async (employeeReason) => {
      const board = days.map(
        (day) =>
          day.period || {
            start: null,
            end: null,
            day: day.dow,
            type: 'Available',
          }
      );

      await addGeneralRequest({ typeId: 2, accountId, board, employeeReason });
      setParams({ params: null });
      setNewAvailability([]);
      setIsUpdateModalOpen(false);
    },
    [accountId, addGeneralRequest, days, setParams]
  );

  const handleAddGeneralRequest = useCallback(() => {
    if (hasApprovedRequest) {
      setIsUpdateModalOpen(true);
    } else {
      updateRequest('');
    }
  }, [updateRequest, hasApprovedRequest]);

  // useEffect(() => {
  //   if (isModalOpen) {
  //     modalizeRef.current?.open('top');
  //   } else {
  //     modalizeRef.current?.close('alwaysOpen');
  //   }
  // }, [isModalOpen]);

  return (
    <Container>
      {[0, 1, 3].includes(status) && (
        <ApprovalContainer>
          <ApprovalChip status={status}>
            <ApprovalText status={status}>
              {status === 0
                ? t('pendingForApproval')
                : status === 1
                ? `${t('availability')} ${t('approvedRequest')}`
                : `${t('availability')} ${t('declinedRequest')}`}
            </ApprovalText>
          </ApprovalChip>
        </ApprovalContainer>
      )}
      <FlatList
        contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: newAvailability.length > 0 ? 65 : 0,
          paddingTop: [0, 1, 3].includes(status) ? 0 : 30,
        }}
        data={days}
        refreshing={isLoading}
        keyExtractor={(props) => props.id.toString()}
        renderItem={({ item }) => <GeneralAvailabilityDay item={item} />}
        onRefresh={refetch}
      />
      {newAvailability.length > 0 && (
        <ButtonContainer>
          <Button
            isLoading={isAddGeneralRequestLoading}
            disabled={isAddGeneralRequestLoading}
            type="primary"
            title={t('sendForApproval')}
            onPress={handleAddGeneralRequest}
            radius={32}
            paddingHorizontal={30}
            paddingVertical={15}
            fontSize={16}
          />
        </ButtonContainer>
      )}
      <GeneralAvailabilityModal
        isOpen={isUpdateModalOpen}
        setIsOpen={setIsUpdateModalOpen}
        handleUpdate={updateRequest}
        isLoading={isAddGeneralRequestLoading}
        disabled={isAddGeneralRequestLoading}
      />
      {/* <Modalize
        ref={modalizeRef}
        withHandle={false}
        onClose={() => setIsModalOpen(false)}
        HeaderComponent={<GeneralAvailabilityModalHeader setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />}
        alwaysOpen={60}
        modalHeight={280}>
        <ModalContainer>
          <ModalRow>
            <ModalRowText>{t('daysScheduledInAWeek')}</ModalRowText>
            <FlexRowBetween>
              <ModalRowSecondaryText>3 Min</ModalRowSecondaryText>
              <ModalRowSecondaryText>6 Max</ModalRowSecondaryText>
            </FlexRowBetween>
          </ModalRow>
          <ModalRow>
            <ModalRowText>{t('hoursScheduledInAWeek')}</ModalRowText>
            <FlexRowBetween>
              <ModalRowSecondaryText>18 Min</ModalRowSecondaryText>
              <ModalRowSecondaryText>48 Max</ModalRowSecondaryText>
            </FlexRowBetween>
          </ModalRow>
          <ModalRow>
            <ModalRowText>{t('hoursScheduledInADay')}</ModalRowText>
            <FlexRowBetween>
              <ModalRowSecondaryText>8 Max</ModalRowSecondaryText>
            </FlexRowBetween>
          </ModalRow>
          <ModalRow>
            <ModalRowText>{t('hoursBetweenShifts')}</ModalRowText>
            <FlexRowBetween>
              <ModalRowSecondaryText>8 Min</ModalRowSecondaryText>
            </FlexRowBetween>
          </ModalRow>
        </ModalContainer>
      </Modalize> */}
    </Container>
  );
};
