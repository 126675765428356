import React, { FC } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { icons } from '@violetta/ubeya/assets';
import {
  BaseView,
  FlexRow,
  H3Medium,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import {
  isOfferCalendarOpenAtom,
  isOffersCalendarClickedAtom,
  offerCalendarMonthAtom,
} from '../../../../state';

const Container = styled(FlexRow)`
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

const IconContainer = styled(BaseView)``;

const EmptyContainer = styled(BaseView)``;

const Title = styled(H3Medium)`
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  color: #252631;
  margin: 0 7px;
`;

export const OffersHeaderTitle: FC<StackHeaderProps> = () => {
  const [offerCalendarMonth] = useRecoilState(offerCalendarMonthAtom);
  const [isOfferCalendarOpen, setIsOfferCalendarOpen] = useRecoilState(
    isOfferCalendarOpenAtom
  );
  const [isOfferCalendarClicked, setIsOfferCalendarClicked] = useRecoilState(
    isOffersCalendarClickedAtom
  );

  return (
    <Container>
      <IconContainer
        as={RipplePressable}
        onPress={() => setIsOfferCalendarClicked(!isOfferCalendarClicked)}
      >
        <SvgIcon xml={icons.uiIcons.calendarWithDot} width={18} height={18} />
      </IconContainer>
      <FlexRow
        as={RipplePressable}
        onPress={() => setIsOfferCalendarOpen(!isOfferCalendarOpen)}
      >
        <Title>{offerCalendarMonth}</Title>
        <SvgIcon
          xml={
            isOfferCalendarOpen
              ? icons.cardIcons.chevronUp
              : icons.cardIcons.chevronDown
          }
          width={18}
          height={18}
        />
      </FlexRow>
      <EmptyContainer />
    </Container>
  );
};
