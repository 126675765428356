import React, { FC, useCallback, useMemo } from 'react';
import { Booking, CustomShiftDTO, IOffer } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseText, BaseView, FlexColumnDirection } from '@violetta/ubeya/ui';
import { isRangeOverlap } from '@violetta/ubeya/utils';
import moment from 'moment';
import styled from 'styled-components/native';
import { API_DATE_FORMAT } from '../../main';
import { EMPTY_POSITION_ID, OfferApplicationType } from '../constants';
import { ApplyShiftCard } from './ApplyShiftCard';
import { BookedShiftCard } from './BookedShiftCard';
import { EntireProjectApplyShiftCard } from './EntireProjectApplyShiftCard';

const Container = styled(FlexColumnDirection)`
  margin-top: 15px;
`;

const WarningContainer = styled(BaseView)`
  margin-bottom: 15px;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 15px;
`;

const WarningText = styled(BaseText)`
  color: ${({ theme }) => theme.colors.surface};
  font-weight: 300;
`;

interface ProjectApplicationProps {
  shiftsByPositionId: { [key: number]: (CustomShiftDTO & { date: string })[] };
  isAutoBook: boolean;
  hideNotes: boolean;
  isShowOfferWage: boolean;
  projectUniformId?: number;
  currency?: string;
  refetchBookings: () => void;
  timeDateSorter: (a: any, b: any) => number;
}

const EntireProjectApplication: FC<ProjectApplicationProps> = ({
  shiftsByPositionId = {},
  refetchBookings,
  isAutoBook,
  hideNotes,
  isShowOfferWage,
  timeDateSorter,
  projectUniformId,
  currency,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <WarningContainer>
        <WarningText>{t('entireProjectApplicationWarning')}</WarningText>
      </WarningContainer>
      {shiftsByPositionId &&
        Object.entries(shiftsByPositionId).map(([positionId, shifts]) => (
          <EntireProjectApplyShiftCard
            positionId={Number(positionId)}
            shifts={shifts.sort(timeDateSorter)}
            isAutoBook={isAutoBook}
            refetchBookings={refetchBookings}
            projectUniformId={projectUniformId}
            hideNotes={hideNotes}
            isShowOfferWage={isShowOfferWage}
            currency={currency}
          />
        ))}
    </>
  );
};

interface Props {
  isLoading: boolean;
  offer: IOffer;
  isAutoBook: boolean;
  isRemoteWork: boolean;
  isShowOfferWage: boolean;
  isProjectMultiDay?: boolean;
  hideNotes?: boolean;
  bookings?: Booking[];
  refetchBookings: () => void;
  currency?: string;
}

export const OfferShifts: FC<Props> = React.memo(
  ({
    isLoading,
    isProjectMultiDay = false,
    offer: {
      shifts,
      locationId: projectLocationId,
      applicationType,
      uniformId,
    },
    bookings,
    isAutoBook,
    isRemoteWork = false,
    isShowOfferWage,
    refetchBookings,
    currency,
    hideNotes = false,
  }) => {
    const timeDateSorter = useCallback((shift1, shift2) => {
      const start = moment(shift1.startTime);
      const end = moment(shift2.startTime);
      if (start.isAfter(end)) {
        return 1;
      }
      if (start.isSame(end)) {
        return shift1.id > shift2.id ? 1 : -1;
      }
      return -1;
    }, []);

    const sortedShifts = useMemo(() => {
      const uniqueShiftsBookings = (bookings || []).reduce(
        (output, current) => {
          const key = `${current.startTime}-${current.endTime}-${current.positionId}-${current.locationId}-${current.comments}`;
          if (output[key]) {
            return output;
          }
          current.isBooking = true;
          output[key] = current;
          return output;
        },
        {} as Booking & { isBooking: boolean }
      );

      const uniqueShiftsApplications = (shifts || []).reduce(
        (output, current) => {
          const startMoment = moment(current.startTime);
          const endMoment = moment(current.endTime);
          // check for conflicts
          const isConflict = (bookings || []).some((booking) =>
            isRangeOverlap(
              [startMoment, endMoment],
              [moment(booking.startTime), moment(booking.endTime)]
            )
          );

          if (isConflict) {
            return output;
          }

          const key = `${current.startTime}-${current.endTime}-${current.positionId}-${current.locationId}-${current.comments}`;
          if (output[key]) {
            return output;
          }
          current.isBooking = false;
          output[key] = current;
          return output;
        },
        {} as IOffer & { isBooking: boolean }
      );

      return [
        ...Object.values(uniqueShiftsBookings),
        ...Object.values(uniqueShiftsApplications),
      ].sort(timeDateSorter);
    }, [bookings, shifts, timeDateSorter]);

    const shiftsByPositionId = useMemo(
      () =>
        (shifts || []).reduce((all, shift) => {
          const { positionId: basePositionId, startTime } = shift;
          const positionId = basePositionId || EMPTY_POSITION_ID;

          if (!all[positionId]) {
            // eslint-disable-next-line no-param-reassign
            all[positionId] = [];
          }

          const formattedShiftDate = moment(startTime).format(API_DATE_FORMAT);

          all[positionId].push({ ...shift, date: formattedShiftDate });

          return all;
        }, {} as { [key: number]: (CustomShiftDTO & { date: string })[] }),
      [shifts]
    );

    return (
      <Container>
        {applicationType === OfferApplicationType.PER_SHIFT ? (
          sortedShifts.map((shift, index) => {
            if (!shift.isBooking) {
              return (
                <ApplyShiftCard
                  isProjectMultiDay={isProjectMultiDay}
                  key={shift.id}
                  shift={shift}
                  isLoading={isLoading}
                  isAutoBook={isAutoBook}
                  isRemoteWork={isRemoteWork}
                  hideNotes={hideNotes}
                  isShowOfferWage={isShowOfferWage}
                  projectLocationId={projectLocationId}
                  projectUniformId={uniformId}
                  refetchBookings={refetchBookings}
                  currency={currency}
                />
              );
            }

            return (
              <BookedShiftCard
                key={shift.id}
                shift={shift}
                isLoading={isLoading}
                isAutoBook={isAutoBook}
                projectLocationId={projectLocationId}
                isProjectMultiDay={isProjectMultiDay}
              />
            );
          })
        ) : (
          <EntireProjectApplication
            refetchBookings={refetchBookings}
            shiftsByPositionId={shiftsByPositionId}
            isAutoBook={isAutoBook}
            timeDateSorter={timeDateSorter}
            projectUniformId={uniformId}
            hideNotes={hideNotes}
            isShowOfferWage={isShowOfferWage}
            currency={currency}
          />
        )}
      </Container>
    );
  }
);
