import { BaseView, FlexRow } from '@violetta/ubeya/ui';
import React, { FC, useContext } from 'react';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts/ConfigContext';

const Container = styled(FlexRow)``;
const Dot = styled(BaseView)<{ isActive: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.success : theme.colors.gray10};
  margin: 0 8px;
`;
interface Props {
  step: number;
  count: number;
}

export const Dots: FC<Props> = ({ step, count }) => {
  const { rtl } = useContext(ConfigContext);
  if (count === 1) {
    return null;
  }
  return (
    <Container>
      {[...Array(count)].map((_, i) => (
        <Dot isActive={step === i} />
      ))}
    </Container>
  );
};
