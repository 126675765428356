import { useLiveView } from '@violetta/ubeya/admin-entities';
import { accountLiveViewFilters } from '@violetta/ubeya/shared';
import moment from 'moment';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Modalize } from 'react-native-modalize';
import { useRecoilState } from 'recoil';
import styled from 'styled-components/native';
import LiveViewContent from './LiveViewContent';
import LiveViewFadingCircle from './LiveViewFadingCircle';
import { icons } from '@violetta/ubeya/assets';
import { BaseText } from '../Typography/Typography';
import {
  BaseView,
  FlexColumn,
  FlexColumnCenter,
  FlexRowBetween,
} from '../Layout/Layout';
import LiveViewFilter from './LiveViewFilter';

const MainTitleContainer = styled(FlexRowBetween)`
  display: flex;
  width: 100%;
  flex: 1;
  padding: 10px 0;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
`;

const SubTitleContainer = styled(FlexRowBetween)`
  display: flex;
  width: 100%;
  flex: 1;
  padding-top: 20px;
  align-items: flex-start;
`;

const Hour = styled(BaseView)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const SubTitle = styled(BaseText)`
  font-size: 16px;
  font-weight: 400;
`;
const Title = styled(BaseText)`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 4px;
`;
const LiveEvents = styled(BaseText)`
  margin-top: 5px;
  font-size: 16px;
`;

const TitleWrapper = styled(FlexColumnCenter)`
  width: 100%;
`;

const AccountLiveView = ({
  timeFormat,
  dateFormat,
}: {
  timeFormat: string;
  dateFormat: string;
}) => {
  const { t } = useTranslation();
  const modalizeRef = useRef<Modalize>(null);
  const [filters, setFilters] = useRecoilState(accountLiveViewFilters);

  const {
    isLoading,
    data: { totalEvents = 0, stats = {}, shiftsInfo = {} },
  } = useLiveView({ filters });

  const showEmptyState = totalEvents === 0;

  const title = (
    <TitleWrapper>
      <MainTitleContainer>
        <Title>{t('liveAttendance')}</Title>
        {!showEmptyState && (
          <LiveViewFilter
            filters={filters}
            onPress={() => modalizeRef?.current?.open()}
          />
        )}
      </MainTitleContainer>

      <SubTitleContainer>
        <FlexColumn>
          <SubTitle>{moment().format('dddd, MMMM Do')}</SubTitle>

          <LiveEvents>
            {totalEvents === 0
              ? t('noLiveEvents')
              : t('totalLiveEvents', { total: totalEvents || 0 })}
          </LiveEvents>
        </FlexColumn>
        <Hour>
          <LiveViewFadingCircle />
          <SubTitle>{moment().format('LT')}</SubTitle>
        </Hour>
      </SubTitleContainer>
    </TitleWrapper>
  );

  return (
    <LiveViewContent
      stats={stats}
      shiftsInfo={shiftsInfo}
      showDateFilter={false}
      title={title}
      filters={filters}
      setFilters={setFilters}
      isLoading={isLoading}
      filterModalizeRef={modalizeRef}
      emptyStateIcon={icons?.emptyStates?.accountLiveViewEmptyState}
      showEmptyState={showEmptyState}
      timeFormat={timeFormat}
      dateFormat={dateFormat}
    />
  );
};

export default AccountLiveView;
