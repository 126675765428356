import { LinkingOptions } from '@react-navigation/native';
import { AvailabilitiesScreenNames } from '../../availabilities/navigation/ScreenNames';
import { BookingsScreenNames } from '../../bookings/navigation/ScreenNames';
import { ChatScreenNames } from '../../chat/navigation/ScreenNames';
import { ComplianceScreenNames } from '../../compliance';
import { DirectoryScreenNames } from '../../directory/navigation/ScreenNames';
import { FeedScreenNames } from '../../feed';
import { HomeScreenNames } from '../../home/navigation/ScreenNames';
import { ManagementScreenNames } from '../../management/navigation/ManagementScreenNames';
import { NotificationsScreenNames } from '../../notifications/navigation/ScreenNames';
import { OffersScreenNames } from '../../offers';
import { OnboardingScreenNames } from '../../onboarding/navigation/ScreenNames';
import { ProfileScreenNames } from '../../profile/navigation/ScreenNames';
import { RequestsScreenNames } from '../../requests';
import { SequencesScreenNames } from '../../sequences';
import { SettingsScreenNames } from '../../settings/navigation/ScreenNames';
import { TimeAvailabilityScreenNames } from '../../time-availability';
import { TimeclockScreenNames } from '../../timeclock';
import { TimesheetsScreenNames } from '../../timesheets';
import { IncomeScreenNames } from '../../income';
import { config } from '../../../modules/main/appConfig';

export const linking = {
  enabled: true,
  prefixes: config.prefixes,
  config: {
    screens: {
      [HomeScreenNames.DRAWER]: {
        screens: {
          [HomeScreenNames.TABS]: {
            screens: {
              [HomeScreenNames.MAIN]: {
                screens: {
                  [HomeScreenNames.HOME]: {
                    path: '/home',
                  },
                },
              },
              [FeedScreenNames.MAIN]: {
                screens: {
                  [FeedScreenNames.FEED]: {
                    path: '/feed',
                  },
                },
              },
              [ChatScreenNames.ROOMS_LIST]: {
                path: '/chat',
                screens: {
                  [ChatScreenNames.ROOM]: {
                    path: '/:roomId',
                    parse: {
                      roomId: (id) => `${id}`,
                    },
                  },
                },
              },
              [BookingsScreenNames.SCHEDULE]: {
                path: '/bookings',
                screens: {
                  [BookingsScreenNames.BOOKINGS]: {
                    path: '/upcoming',
                  },
                  [TimesheetsScreenNames.TIMESHEETS]: { path: '/past' },
                },
              },
              [OffersScreenNames.OFFERS]: {
                path: '/offers',
              },
            },
          },
        },
      },
      [NotificationsScreenNames.MAIN]: {
        screens: {
          [NotificationsScreenNames.NOTIFICATIONS]: {
            path: '/notifications',
          },
        },
      },
      [IncomeScreenNames.MAIN]: {
        screens: {
          [IncomeScreenNames.INCOME]: {
            path: '/incomes',
          },
        },
      },
      [RequestsScreenNames.MAIN]: {
        path: '/requests',
        screens: {
          [RequestsScreenNames.ADD_REQUEST]: {
            path: '/create',
          },
        },
      },

      [ComplianceScreenNames.MAIN]: {
        path: '/compliance',
      },

      [TimeclockScreenNames.TIMECLOCK_REPORT]: {
        path: '/timeclock',
      },
      [BookingsScreenNames.MAIN]: {
        screens: {
          [BookingsScreenNames.BOOKING]: {
            path: '/bookings/:bookingId',
            parse: {
              bookingId: (id) => `${id}`,
            },
          },
          [BookingsScreenNames.TEAM]: {
            path: '/bookings/:projectId/team',
            parse: {
              projectId: (id) => `${id}`,
            },
          },
          // not supported in backend
          // [BookingsScreenNames.CAPTAIN]: {
          //   path: '/bookings/:bookingId/captain',
          //   parse: {
          //     bookingId: (id) => `${id}`,
          //     date: ?? (add)
          //   },
          // },
        },
      },
      [OffersScreenNames.MAIN]: {
        screens: {
          [OffersScreenNames.OFFER]: {
            path: '/offers/:offerId',
            parse: {
              offerId: (id) => `${id}`,
            },
          },
        },
      },
      [AvailabilitiesScreenNames.MAIN]: {
        path: '/availabilities/:branchId',
        parse: {
          branchId: (id) => `${id}`,
        },
        screens: {
          [AvailabilitiesScreenNames.AVAILABILITY]: {
            path: '/summary',
          },
          [AvailabilitiesScreenNames.AVAILABILITY_PERIOD]: {
            path: '/period',
          },
        },
      },
      [TimesheetsScreenNames.MAIN]: {
        screens: {
          [TimesheetsScreenNames.TIMESHEET]: {
            path: '/timesheets/:timesheetId',
            parse: {
              timesheetId: (id) => `${id}`,
            },
          },
        },
      },
      [TimeAvailabilityScreenNames.MAIN]: {
        path: '/time-availabilities',
      },
      [DirectoryScreenNames.DIRECTORY]: {
        path: '/directory',
      },
      [OnboardingScreenNames.ONBOARDING_FORM]: {
        path: '/onboarding',
      },
      [SettingsScreenNames.MAIN]: {
        screens: {
          [SettingsScreenNames.SETTINGS]: {
            path: '/settings',
          },
        },
      },
      [ManagementScreenNames.MAIN]: {
        path: '/management',
      },
      [ProfileScreenNames.MAIN]: {
        path: '/profiles',
        screens: {
          [ProfileScreenNames.EDIT_USER]: {
            path: '/edit',
          },
          [ProfileScreenNames.ACCOUNT_PROFILE]: {
            path: '/:accountId/profile',
            parse: {
              accountId: (id) => `${id}`,
            },
          },
          [ProfileScreenNames.ACCOUNT_GALLERY]: {
            path: '/:accountId/gallery',
            parse: {
              accountId: (id) => `${id}`,
            },
          },
        },
      },
      [SequencesScreenNames.MAIN]: {
        path: '/sequences',
        screens: {
          [SequencesScreenNames.SEQUENCE]: {
            path: '/:sequenceId/:instanceId',
            parse: {
              sequenceId: (id) => `${id}`,
              instanceId: (id) => `${id}`,
            },
          },
        },
      },
    },
  },
} as LinkingOptions;
