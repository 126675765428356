import React, { FC, useMemo } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { useUser } from '@violetta/ubeya/auth';
import { Booking } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  Button,
  Card as BaseCard,
  SvgIcon,
  FlexColumnCenter,
  FlexRow,
  H5Regular,
  H5Medium,
  H6Medium,
  BaseImage,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';

const Container = styled(BaseView)``;

const Wrapper = styled(BaseCard)`
  border-width: 0px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.offwhitesmoke};
  elevation: 0;
  shadow-color: rgba(0, 0, 0, 0);
`;

const Box = styled(BaseView)`
  padding: 20px 8px 0 8px;
  align-items: center;
`;

const Title = styled(H5Medium)`
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 10px;
`;

const ButtonContainer = styled(FlexColumnCenter)`
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`;

const ProfilePictureContainer = styled(BaseView)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-bottom: 5px;
`;

const IconWrapper = styled(BaseView)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.gray9};
`;

const BranchLogo = styled(BaseImage)`
  width: 24px;
  height: 24px;
  border-radius: 12px;
`;

const EventName = styled(H6Medium)`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 5px;
`;

const CardDataDetails = styled(H5Regular)`
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray15};
  font-size: 13px;
`;

const Border = styled(BaseView)`
  width: 1px;
  border: solid 0.5px ${({ theme }) => theme.colors.gray15};
`;

const SwapDataContainer = styled(FlexRow)`
  background-color: white;
  border-radius: 4px;
  padding: 12px 0;
  width: 100%;
  justify-content: center;
`;

const EmployeeContainer = styled(BaseView)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

interface Props {
  booking: Booking;
  onPress: any;
}

export const Swap: FC<Props> = ({ booking, onPress }) => {
  const { t } = useTranslation();
  const { data } = useUser();

  const swapName = useMemo(
    () =>
      `${booking?.swap?.employee?.firstName || ''} ${
        booking?.swap?.employee?.laseName || ''
      }`,
    [booking?.swap?.employee?.firstName, booking?.swap?.employee?.laseName]
  );
  return (
    <Container>
      <Wrapper>
        <Box>
          <Title>
            {t('newRequestFromEmployee', { employeeName: swapName })}
          </Title>
          <SwapDataContainer>
            <EmployeeContainer>
              <ProfilePictureContainer>
                {!!booking?.swap?.employee?.image ? (
                  <BranchLogo
                    source={{ uri: booking?.swap?.employee?.image }}
                  />
                ) : (
                  <IconWrapper>
                    <SvgIcon xml={icons?.uiIcons?.profile} />
                  </IconWrapper>
                )}
              </ProfilePictureContainer>
              <EventName>{swapName}</EventName>
              <CardDataDetails>
                {moment(booking?.swap?.slot?.startTime).format(
                  'MMM, DD | dddd'
                )}
              </CardDataDetails>
            </EmployeeContainer>
            <Border />
            <EmployeeContainer>
              <ProfilePictureContainer>
                {!!data?.image ? (
                  <BranchLogo source={{ uri: data?.image }} />
                ) : (
                  <IconWrapper>
                    <SvgIcon xml={icons?.uiIcons?.profile} />
                  </IconWrapper>
                )}
              </ProfilePictureContainer>
              <EventName>({t('you')})</EventName>
              <CardDataDetails>
                {moment(booking?.startTime).format('MMM, DD | dddd')}
              </CardDataDetails>
            </EmployeeContainer>
          </SwapDataContainer>
          <ButtonContainer>
            <Button
              narrow
              type="transparent"
              title={t('showSwapDetails')}
              fontSize={14}
              onPress={onPress}
            />
          </ButtonContainer>
        </Box>
      </Wrapper>
    </Container>
  );
};
