import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { useOptimisticMutation } from '@violetta/ubeya/utils';
import {
  useQueryClient,
  useInfiniteQuery,
  useMutation,
} from '@tanstack/react-query';
import { IPost, IPostLike } from '../entities';
import { useBranches } from './useBranches';
import createCachedSelector from 're-reselect';
import { feedTitle } from '../utils/projects';
import { useLocations } from './useLocations';

export interface Posts {
  data: IPost[];
  total: number;
  token?: string;
}

export type DeletePollAnswerParams = Record<
  'pollId' | 'questionId' | 'answerId',
  number
>;
export type CreatePollAnswerParams = Record<
  'pollId' | 'questionId' | 'pollOptionId',
  number
>;

const selector = createCachedSelector(
  (data) => data,
  (data, mappedLocations) => mappedLocations,
  (data, mappedLocations) => ({
    posts: (data?.pages || []).reduce<IPost[]>((a, c) => {
      a.push(
        ...(c?.data.map((d) => ({
          ...d,
          title:
            d?.projects?.length && d?.projects?.length > 0
              ? feedTitle(d?.projects[0], mappedLocations)
              : '',
          comments: [], // TODO: remove once implemented
        })) || [])
      );
      return a;
    }, []),
  })
)({
  keySelector: (data, mappedLocations, storeKey) => storeKey,
});

export const usePosts = ({
  projectId,
  isStar,
  pageSize,
}: { projectId?: number; isStar?: boolean; pageSize?: number } = {}) => {
  const queryClient = useQueryClient();

  const { data: userData } = useUser();
  const { branches } = useBranches();
  const { mappedLocations } = useLocations();
  const storeKey = useMemo(
    () =>
      ['posts', isStar, projectId?.toString()].filter(
        (cur) => !!cur
      ) as string[],
    [isStar, projectId]
  );

  const { isLoading, isIdle, data, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery<Posts>({
      queryKey: storeKey,
      queryFn: ({ pageParam }) =>
        api.getPosts({
          projectId: projectId || undefined,
          branches: branches.map(({ id }) => id),
          isStar,
          pageSize: pageSize || 20,
          token: pageParam,
        }),
      enabled: !!userData?.id,
      staleTime: 0,
      getNextPageParam: (lastPage) =>
        lastPage?.total === 0 ? undefined : lastPage?.token,
      select: (data) => selector(data, mappedLocations, storeKey.join('#')),
    });

  const { posts } = useMemo(() => {
    const { posts = [] } = data || {};
    return {
      posts,
    };
  }, [data]);

  const { mutateAsync: createPollAnswer } = useMutation({
    mutationFn: api.createPollAnswer,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: storeKey, exact: true }), // NOTE: these are wasteful, invalidating all just for 1 item,
  });

  const { mutateAsync: deletePollAnswer } = useMutation({
    mutationFn: api.deletePollAnswer,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: storeKey, exact: true }),
  });

  type AddParams = { postId: number };
  type DeleteParams = { postId: number; likeId: number };

  const { mutateAsync: createLike } = useOptimisticMutation<
    { data: IPost[] }[],
    AddParams,
    { data: IPostLike }
  >(
    storeKey,
    (params: AddParams) => api.createLike({ postId: params.postId }),
    ({ previousData, payload, newData }) => ({
      ...previousData,
      pages: previousData.pages.map((previousDatum) => ({
        ...previousDatum,
        data: [
          ...previousDatum.data.map((post) =>
            post.id === payload.postId
              ? {
                  ...post,
                  likesTotal: newData ? post.likesTotal : post.likesTotal + 1,
                  like: newData
                    ? {
                        id: newData!.data.id,
                        createdAt: newData!.data.createdAt,
                      }
                    : {
                        id: 0,
                        createdAt: new Date(),
                      },
                }
              : post
          ),
        ],
      })),
    }),
    { mutateOnSettled: true, refetchOnSuccess: false }
  );
  const { mutateAsync: deleteLike } = useOptimisticMutation<
    { data: IPost[] }[],
    DeleteParams,
    IPost
  >(
    storeKey,
    (params: DeleteParams) =>
      api.deleteLike({ postId: params.postId, likeId: params.likeId }),
    ({ previousData, payload, newData }) => ({
      ...previousData,
      pages: previousData.pages.map((previousDatum) => ({
        ...previousDatum,
        data: [
          ...previousDatum.data.map((post) =>
            post.id === payload.postId
              ? {
                  ...post,
                  likesTotal: post.likesTotal - 1,
                  like: null,
                }
              : post
          ),
        ],
      })),
    }),
    {
      refetchOnSuccess: false,
    }
  );

  return {
    isLoading,
    isIdle,
    posts,
    employees: [],
    fetchMore: fetchNextPage,
    canFetchMore: hasNextPage,
    refetch,
    createPollAnswer,
    deletePollAnswer,
    createLike,
    deleteLike,
  };
};
