import { useMemo } from 'react';
import { api } from '@violetta/ubeya/api';
import { useUser } from '@violetta/ubeya/auth';
import { mappedArray } from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import { atom, useRecoilState } from 'recoil';
import { ISequence } from '../entities/ISequence';
import { ISequenceInstance } from '../entities/ISequenceInstance';

const SORT_BY_SEQUENCE_PRIORITY = (a: ISequence, b: ISequence) => {
  const sortByPriorityResult = (a?.priority || 0) - (b?.priority || 0);

  if (sortByPriorityResult === 0) {
    return b.id - a.id;
  }

  return sortByPriorityResult;
};

export type Sequence = Omit<ISequence, 'forms' | 'instances'> & {
  instances: Array<ISequenceInstance['id']>;
};

type ResponseType = {
  data: Array<Sequence>;
};

export const selectedSequenceIdAtom = atom<number | null>({
  key: 'selectedSequenceId',
  default: null,
});

const selector = createCachedSelector(
  (data) => data?.data,
  (data) => {
    const sequences = (data || []).sort(SORT_BY_SEQUENCE_PRIORITY);

    const mappedSequences = mappedArray(sequences) as {
      [key: number]: Sequence;
    };

    return { sequences, mappedSequences };
  }
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useSequences = () => {
  const { data: userData } = useUser();
  const [selectedSequenceId, setSelectedSequenceId] = useRecoilState(
    selectedSequenceIdAtom
  );

  const storeKey = useMemo(() => ['sequences'], []);
  const { isPending, data } = useQuery<ResponseType>({
    queryKey: storeKey,
    queryFn: api.getSequences,
    enabled: !!userData?.id,
    select: (data) => selector(data, storeKey.join('#')),
  });

  const { sequences, mappedSequences } = useMemo(() => {
    const { sequences = [], mappedSequences = {} } = data || {};
    return {
      sequences,
      mappedSequences,
    };
  }, [data]);

  return {
    isLoading: isPending,
    sequences,
    selectedSequenceId,
    setSelectedSequenceId,
    mappedSequences,
  };
};
