import React, { FC } from 'react';
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from '@react-navigation/native-stack';
import { HeaderBar } from '@violetta/ubeya/ui';
import { useTheme } from 'styled-components/native';
import { FeedScreen } from '../screens';
import { FeedScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

export const FeedNavigation: FC = () => {
  const { colors } = useTheme();

  return (
    <Navigator
      initialRouteName={FeedScreenNames.FEED}
      screenOptions={{
        header: (props: NativeStackHeaderProps) => (
          <HeaderBar
            {...props}
            hideBack
            hasLeftMenu
            color={colors.lightSurface}
          />
        ),
        // eslint-disable-next-line react/jsx-closing-bracket-location
      }}
    >
      <Screen name={FeedScreenNames.FEED} component={FeedScreen} />
    </Navigator>
  );
};
