import {
  AuthorizationStatus,
  DeliveredNotification,
  FetchResult,
  NotificationCategory,
  NotificationRequest,
  PresentLocalNotificationDetails,
  PushNotification,
  PushNotificationEventName,
  PushNotificationIOSStatic,
  PushNotificationPermissions,
  ScheduleLocalNotificationDetails,
} from './types';

export class PushNotificationsIOS implements PushNotificationIOSStatic {
  FetchResult: FetchResult;
  AuthorizationStatus: AuthorizationStatus;
  presentLocalNotification(details: PresentLocalNotificationDetails): void {}
  scheduleLocalNotification(details: ScheduleLocalNotificationDetails): void {}
  addNotificationRequest(request: NotificationRequest): void {}
  cancelAllLocalNotifications(): void {}
  removeAllPendingNotificationRequests(): void {}
  removePendingNotificationRequests(identifiers: string[]): void {}
  removeAllDeliveredNotifications(): void {}
  getDeliveredNotifications(callback: (notifications: Record<string, any>[]) => void): DeliveredNotification {
    return {
      title: '',
      body: '',
      identifier: '1',
      subtitle: '',
    };
  }
  removeDeliveredNotifications(identifiers: string[]): void {}
  setApplicationIconBadgeNumber(number: number): void {}
  getApplicationIconBadgeNumber(callback: (badge: number) => void): void {}
  cancelLocalNotifications(userInfo: Record<string, any>): void {}
  getScheduledLocalNotifications(callback: (notifications: ScheduleLocalNotificationDetails[]) => void): void {}
  getPendingNotificationRequests(callback: (notifications: NotificationRequest[]) => void): void {}
  addEventListener(type: 'notification' | 'localNotification', handler: (notification: PushNotification) => void): void;
  addEventListener(type: 'register', handler: (deviceToken: string) => void): void;
  addEventListener(
    type: 'registrationError',
    handler: (error: { message: string; code: number; details: any }) => void,
  ): void;
  addEventListener(type: any, handler: any): void {}
  removeEventListener(type: PushNotificationEventName): void {}
  requestPermissions(
    permissions?: PushNotificationPermissions | PushNotificationPermissions[],
  ): Promise<PushNotificationPermissions> {
    return Promise.resolve({});
  }
  abandonPermissions(): void {}
  checkPermissions(callback: (permissions: PushNotificationPermissions) => void): void {}
  getInitialNotification(): Promise<PushNotification> {
    const notification: any = {};
    return Promise.resolve(notification);
  }
  setNotificationCategories(categories: NotificationCategory[]): void {}
}
