import React, { FC, useCallback, useContext, useMemo } from 'react';
import {
  IAvailability,
  useLabels,
  useLocalAvailabilities,
  usePeriods,
} from '@violetta/ubeya/entities';
import {
  BaseView,
  FlexColumnBetween,
  FlexRowBetween,
  H5Medium,
  H5Regular,
  H6Medium,
  H6Regular,
  VIcon,
  XIcon,
} from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import { AppContext } from '../../../main/AppContext';

const Container = styled(BaseView)`
  width: 100%;
  padding: 12px 8px;
  border-top-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray11};
`;

const CardContent = styled(FlexRowBetween)``;
const LabelDetails = styled(FlexColumnBetween)`
  align-items: flex-start;
`;

const AvailabilityActions = styled(FlexRowBetween)``;

const LabelTitle = styled(H5Medium)`
  font-weight: 500;
`;

const LabelHours = styled(H5Regular)`
  margin-top: 5px;
`;

interface Props {
  labelId: number;
}

export const LabelCard: FC<Props> = ({ labelId }) => {
  const { rtl } = useContext(ConfigContext);
  const {
    availability: { periodId, selectedDay },
  } = useContext(AppContext);

  const { mappedLabels } = useLabels();
  const { mappedPeriods } = usePeriods();

  const { branchId } = mappedPeriods[periodId];

  const { title, startTime, endTime, id, accountId, branches } =
    mappedLabels[labelId];

  const {
    addLocalAvailability: add,
    updateLocalAvailability: edit,
    localAvailabilities,
  } = useLocalAvailabilities({
    periodId,
  });

  const availabilities = useMemo(
    () =>
      (localAvailabilities || []).filter(
        ({ date }) =>
          moment(date).isSame(moment(selectedDay), 'days') &&
          branches.includes(branchId)
      ),
    [selectedDay, localAvailabilities, branches, branchId]
  );

  const [xPressed, vPressed] = useMemo(
    () =>
      [
        availabilities.find(
          ({ labelId, status }) => labelId === id && status === 0
        ),
        availabilities.find(
          ({ labelId, status }) => labelId === id && status === 1
        ),
      ] as
        | readonly [IAvailability, undefined]
        | readonly [undefined, IAvailability]
        | readonly [undefined, undefined],
    [availabilities, id] // TODO: add hooks eslint rule
  );

  const onVPress = useCallback(() => {
    if (!xPressed && !vPressed) {
      return add({
        branchId,
        accountId,
        date: selectedDay,
        labelId: id,
        status: 1,
      });
    }

    if (xPressed) {
      return edit({
        id: xPressed.id,
        status: 1,
      });
    }
  }, [xPressed, vPressed, add, branchId, accountId, selectedDay, id, edit]);

  const onXPress = useCallback(() => {
    if (!xPressed && !vPressed) {
      return add({
        branchId,
        accountId,
        date: selectedDay,
        labelId: id,
        status: 0,
      });
    }

    if (vPressed) {
      return edit({
        id: vPressed.id,
        status: 0,
      });
    }
  }, [xPressed, vPressed, add, branchId, accountId, selectedDay, id, edit]);

  const timeToShow = useMemo(
    () =>
      startTime
        ? endTime
          ? `${moment(startTime, 'HH:mm:ss').format('LT')}-${moment(
              endTime,
              'HH:mm:ss'
            ).format('LT')}`
          : moment(startTime, 'HH:mm:ss').format('LT')
        : '',
    [startTime, endTime]
  );

  return (
    <Container>
      <CardContent rtl={rtl}>
        <LabelDetails>
          <LabelTitle>{title}</LabelTitle>
          <LabelHours>{timeToShow}</LabelHours>
        </LabelDetails>
        <AvailabilityActions>
          <XIcon
            style={{ marginRight: 20 }}
            onPress={onXPress}
            pressed={xPressed !== undefined}
          />
          <VIcon
            style={{ marginRight: 20 }}
            onPress={onVPress}
            pressed={vPressed !== undefined}
          />
        </AvailabilityActions>
      </CardContent>
    </Container>
  );
};
