import { images } from '@violetta/ubeya/assets';
import { IAccount } from '@violetta/ubeya/entities';
import {
  AccountImage,
  BaseImage,
  BaseView,
  Card,
  FlexRow,
  RipplePressable,
} from '@violetta/ubeya/ui';
import React, { FC } from 'react';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { AccountProfileCard } from '../../../profile/components';

const Container = styled(BaseView)`
  height: 190px;
  justify-content: space-between;
  width: 100%;
`;

const FirstRow = styled(FlexRow)`
  ${Platform.OS === 'web' && 'width:100%;'};
  flex: 1;
  position: relative;
  width: 100%;
`;

const LargeProfileImage = styled(BaseImage)`
  width: 100%;
  height: 100%;
  resize-mode: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export interface EmployerSwiperCardItemProps {
  account: IAccount & {
    onButtonPress?: () => void;
    onCardPress?: () => void;
  };
}

export const EmployerSwiperCard: FC<EmployerSwiperCardItemProps> = ({
  account,
}) => {
  const { name, logo, onButtonPress, onCardPress, coverPhoto } = account;
  return (
    <Card as={RipplePressable} onPress={onCardPress}>
      <Container>
        <FirstRow>
          <LargeProfileImage
            source={{ uri: coverPhoto || images.jpg.defaultCoverPhoto }}
          />
          <AccountImage
            name={name}
            showNameAsEmptyLogo
            image={logo}
            limitContainerSize={false}
            size={80}
            style={{
              position: 'absolute',
              top: '20%',
              left: '10%',
            }}
            imageStyle={{
              borderWidth: 2,
              borderColor: 'white',
            }}
          />
        </FirstRow>

        <AccountProfileCard
          title={name}
          onPress={onButtonPress}
          hideIcon
          titleStyle={{ fontSize: 18, fontWeight: '500' }}
        />
      </Container>
    </Card>
  );
};
