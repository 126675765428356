import { Location } from '@violetta/ubeya/utils';
import moment from 'moment';
import { atom } from 'recoil';
import { persistentAtom } from 'recoil-persistence/react-native';
import { COMPLIANCE_OPTIONS } from './constants';

export const complianceFilterAtom = persistentAtom({
  key: 'complianceFilter',
  default: COMPLIANCE_OPTIONS.MISSING,
});
