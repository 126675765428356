import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '@violetta/ubeya/api';
import { to } from '@violetta/ubeya/await';
import {
  FieldTypes,
  FieldValues,
  useSequenceInstance,
  useUbeyaForm,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BackButton,
  BaseScreen,
  BaseView,
  Button,
  FlexColumnDirection,
  FlexRow,
  FlexRowEvenly,
  H2Bold,
  UbeyaForm,
} from '@violetta/ubeya/ui';
import { uiAlert } from '@violetta/ubeya/utils';
import moment from 'moment';
import React, {
  FC,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Platform, SafeAreaView } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts/ConfigContext';
import { theme } from '../../../style/theme';
import { Dots } from './Dots';

const Container = styled(BaseView)`
  padding: 10px 30px;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const ButtonContainer = styled(BaseView)`
  width: 100%;
`;

const Top = styled(FlexColumnDirection)`
  width: 100%;
`;
const Bottom = styled(BaseView)`
  display: flex;
`;
const ButtonsContainer = styled(FlexRowEvenly)`
  ${Platform.OS === 'android' && 'margin-bottom:20px'}
`;

const Title = styled(H2Bold)`
  padding-left: 20px;
  flex: 1;
`;
const DotsContainer = styled(BaseView)`
  margin: 20px 0;
  align-items: center;
`;
const Header = styled(FlexRow)`
  padding: 5px 10px;
  align-items: center;
`;

interface Props {}

type ParamList = {
  params: {
    sequenceId: number;
    instanceId: number;
    employeeSlotId?: number;
    // forms: IUbeyaForm[];
  };
};

export const SequenceFormScreen: FC<Props> = () => {
  const queryClient = useQueryClient();

  const [isScrollEnabled, setIsScrollEnabled] = useState(true);
  const { t } = useTranslation();
  const { goBack } = useNavigation();
  const { rtl } = useContext(ConfigContext);
  const [isScreenLoading, setIsScreenLoading] = useState<boolean>(false);
  const ref = React.useRef(null);

  const {
    params: { instanceId, sequenceId, employeeSlotId },
  } = useRoute<RouteProp<ParamList, 'params'>>();
  const { sequenceInstance } = useSequenceInstance(sequenceId, instanceId);

  const forms = useMemo(
    () => sequenceInstance?.sequence?.forms || [],
    [sequenceInstance?.sequence?.forms]
  );
  const {
    currentFormId,
    setCurrentFormId,
    save,
    formsResults,
    setFormsResults,
  } = useUbeyaForm();

  const formResults = useRef<FieldValues>({});
  const [errors, setErrors] = useState({});

  const { mutateAsync: patchInstance, isPending: isLoading } = useMutation({
    mutationFn: ({ forms, isDone }: { forms: any; isDone?: boolean }) =>
      api.patchInstance({
        sequenceId,
        instanceId,
        employeeSlotId,
        forms,
        isDone,
      }),
    throwOnError: true,
  });

  const formIndex = useMemo(
    () => forms?.findIndex(({ id }) => id === currentFormId),
    [forms, currentFormId]
  );

  const lastPage = useMemo(
    () => formIndex === forms?.length - 1,
    [formIndex, forms?.length]
  );

  const buildFieldIdTypeKey = (fieldId, type) =>
    [fieldId, type].filter((cur) => !!cur).join('-');

  const form = useMemo(() => {
    if (formIndex === -1) {
      return null;
    }

    const form = forms[formIndex];
    const fieldsResults = formsResults?.[form.id]?.fields || {};

    return {
      ...form,
      fields: form.fields.map((field) => {
        return {
          ...field,
          value:
            fieldsResults?.[buildFieldIdTypeKey(field.id, field.type)]?.value,
        };
      }),
    };
  }, [forms, formIndex, formsResults]);

  const onChange = useCallback(
    ({
      values,
      dirty,
      initialValues,
      valid,
    }: {
      values: FieldValues;
      dirty: boolean;
      valid: boolean;
      initialValues: FieldValues;
    }) => {
      formResults.current = { ...initialValues, ...values };
      if (dirty && currentFormId) {
        save(currentFormId, { ...formResults.current }, true, valid);
      }
    },
    [currentFormId, save]
  );

  const onPress = useCallback(
    async (type: 'next' | 'prev') => {
      if (!currentFormId) {
        return;
      }

      save(currentFormId, { ...formResults.current });

      const fields = form?.fields || [];
      const isDirty = formsResults?.[currentFormId]?.dirty;
      const isValid = formsResults?.[currentFormId]?.valid;

      const requiredFields = (fields || []).filter(
        ({ isRequired }) => !!isRequired
      );

      const isFilled =
        fields.length > 0 &&
        (requiredFields || []).every(
          ({ id, type }) =>
            !!formResults.current[`field-${id}`] ||
            !!formResults.current[`field-${id}-${type}`]
        );

      const currentErrors = {};

      const unFilledFields = requiredFields.filter(
        ({ id, type }) =>
          !formResults.current[`field-${id}`] &&
          !formResults.current[`field-${id}-${type}`]
      );

      unFilledFields.forEach(({ id }) => {
        currentErrors[id] = true;
      });

      if (type === 'next') {
        if (!isFilled) {
          await to(
            uiAlert({
              title: t('pleaseFillRequiredFields'),
              save: { text: t('ok') },
            })
          );
          setErrors(currentErrors);
          ref?.current?.scrollTo(0);
          return;
        }

        if (!isValid) {
          await to(
            uiAlert({
              title: t('invalidSequenceForm'),
              save: { text: t('ok') },
            })
          );
          setErrors(currentErrors);
          ref?.current?.scrollTo(0);
          return;
        }

        if (lastPage) {
          const res = await uiAlert({
            title: t('areYouSureYouFinishedSequence'),
            cancel: {
              text: t('cancel'),
            },
            save: {
              text: t('send'),
            },
          });

          if (!res) {
            return;
          }
        }

        const payload = {
          forms: isDirty
            ? [
                {
                  id: currentFormId,
                  fields: forms[formIndex].fields
                    .filter(
                      (field) =>
                        (formResults.current?.[`field-${field.id}`] !==
                          undefined ||
                          formResults.current?.[
                            `field-${field.id}-${field.type}`
                          ]) !== undefined
                    )
                    .map((field) => ({
                      id: field.id,
                      type: field.type,
                      value:
                        field.fieldTypeId === FieldTypes.FIELD_TYPE_DATE
                          ? moment(
                              formResults.current?.[
                                `field-${field.id}-${field.type}`
                              ]
                            ).format('YYYY-MM-DD')
                          : formResults.current?.[
                              `field-${field.id}-${field.type}`
                            ] !== undefined
                          ? formResults.current?.[
                              `field-${field.id}-${field.type}`
                            ]
                          : formResults.current?.[`field-${field.id}`],
                    })),
                },
              ]
            : [],
          isDone: lastPage,
        };

        if (isDirty || lastPage) {
          setIsScreenLoading(true);
          const [err] = await to(patchInstance(payload));

          if (err) {
            setIsScreenLoading(false);
            return;
          }

          save(currentFormId, {}, false);
        }

        if (lastPage) {
          setFormsResults({});
          await queryClient.invalidateQueries({ queryKey: ['sequences'] });
          await queryClient.invalidateQueries({ queryKey: ['bookings'] });
        }
      }

      const nextFormIndex = type === 'next' ? formIndex + 1 : formIndex - 1;

      if (nextFormIndex < 0) {
        goBack();
        setIsScreenLoading(false);
        return;
      }

      if (nextFormIndex >= forms.length) {
        goBack();
        goBack();
        setIsScreenLoading(false);
        return;
      }

      const { id } = forms[nextFormIndex];

      formResults.current = {};
      setCurrentFormId(id);
      setIsScreenLoading(false);
      ref?.current?.scrollTo(0);
    },
    [
      currentFormId,
      save,
      formsResults,
      form?.fields,
      formIndex,
      forms,
      setCurrentFormId,
      lastPage,
      t,
      patchInstance,
      setFormsResults,
      queryClient,
      goBack,
    ]
  );
  if (!form) {
    return <BaseScreen isLoading />;
  }

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: theme.colors.background,
        paddingTop: Platform.OS === 'web' ? 10 : 0,
        paddingBottom: Platform.OS === 'web' ? 20 : 0,
      }}
    >
      <KeyboardAwareScrollView
        scrollEnabled={isScrollEnabled}
        innerRef={(inner) => {
          ref.current = inner;
        }}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <Header>
          <BackButton rtl={rtl} onPress={() => onPress('prev')} />
          {!!form?.title && <Title>{form.title}</Title>}
        </Header>
        <Container>
          <Top>
            <UbeyaForm
              setIsScrollEnabled={(value) => setIsScrollEnabled(value)}
              form={form!}
              controlledErrors={errors}
              onChange={onChange}
              key={form?.id}
              rtl={rtl}
            />
          </Top>
          <Bottom>
            <DotsContainer>
              <Dots step={formIndex} count={forms.length} />
            </DotsContainer>

            {/* <ButtonContainer>
              <Button type="primaryReversed" onPress={() => onPress('prev')} title={t('back')} disabled={isLoading} />
            </ButtonContainer> */}
            <ButtonContainer>
              <Button
                type="primary"
                onPress={() => onPress('next')}
                title={t(lastPage ? 'finish' : 'next')}
                isLoading={isLoading || isScreenLoading}
                disabled={isLoading || isScreenLoading}
              />
            </ButtonContainer>
            {/* <BackContainer as={Pressable} onPress={() => onPress('prev')}>
            <H5Bold>Back</H5Bold>
          </BackContainer> */}
          </Bottom>
        </Container>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
};
