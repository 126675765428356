import { useEffect, useMemo } from 'react';
import momentTz from 'moment-timezone';
import moment from 'moment';
import { api } from '@violetta/ubeya/api';
import {
  CustomDTO,
  mappedArray,
  PickPrimitiveKeys,
} from '@violetta/ubeya/utils';
import createCachedSelector from 're-reselect';
import { useQuery } from '@tanstack/react-query';
import {
  BreaktimeDTO,
  EmployeeSlotDTO,
  JobDTO,
  OrderDTO,
  ProjectDTO,
  ShiftDTO,
  TimesheetDTO,
} from '../entities';
import { useBranches } from './useBranches';
import { useLocations } from './useLocations';
import { experimental_createPersister } from '@tanstack/react-query-persist-client';
import AsyncStorage from '@react-native-async-storage/async-storage';

type CustomBreaktimeDTO = CustomDTO<BreaktimeDTO>;

type CustomJobDTO = CustomDTO<JobDTO, 'id' | 'branchId' | 'date'>;

type CustomEmployeeSlotDTO = CustomDTO<EmployeeSlotDTO, 'id'>;

type CustomProjectDTO = CustomDTO<ProjectDTO>;
// "id" | "date" | "name" | "branchId" | "labelId"

type CustomShiftDTO = CustomDTO<
  ShiftDTO,
  'id' | 'startTime' | 'endTime' | 'positionId' | 'locationId' | 'comments',
  {
    project: CustomProjectDTO;
  }
>;

type CustomOrderDTO = CustomDTO<OrderDTO, 'clientProjectId'>;

export type CustomTimesheetDTO = CustomDTO<
  TimesheetDTO,
  PickPrimitiveKeys<TimesheetDTO>,
  {
    job?: CustomJobDTO;
    breaktimes: CustomBreaktimeDTO[];
    shift?: CustomShiftDTO;
    sequences?: ISequence[];
    employeeSlot?: CustomEmployeeSlotDTO;
    order?: CustomOrderDTO;
  },
  {
    branchId: number;
    date?: Date;
    scheduledStartTime: Date | null;
    scheduledEndTime: Date | null;
    locationId: number | null;
    disableClockWithMobile?: boolean;
    requireCheckInWithMobile?: boolean;
  }
>;

interface Response {
  data: CustomTimesheetDTO[];
}

export const timesheetsPersister = experimental_createPersister({
  storage: AsyncStorage,
  maxAge: 1000 * 60 * 60 * 72, // 72 hours,
});

const normalizedShiftTimes = ({
  startTime: rawStartTime,
  endTime: rawEndTime,
  date: rawDate,
  locationId,
  mappedLocations,
  isRemoteWork = false,
}: {
  startTime: Date;
  endTime: Date;
  date: Date;
  locationId: number;
  mappedLocations: any;
  isRemoteWork?: boolean;
}) => {
  if (!isRemoteWork) {
    return { startTime: rawStartTime, endTime: rawEndTime, date: rawDate };
  }
  const location = !!locationId && mappedLocations?.[locationId];
  if (!location) {
    return { startTime: rawStartTime, endTime: rawEndTime, date: rawDate };
  }
  const startTime = momentTz.tz(rawStartTime, location?.timezoneName).toDate();
  const endTime = momentTz.tz(rawEndTime, location?.timezoneName).toDate();
  const date = momentTz.tz(rawStartTime, location?.timezoneName).toDate();

  return { startTime, endTime, date: moment(date)?.format('YYYY-MM-DD') };
};

const selector = createCachedSelector(
  (data) => data?.data,
  (data, mappedBranches) => mappedBranches,
  (data, mappedBranches, mappedLocations) => mappedLocations,
  (data, mappedBranches, mappedLocations) => {
    const timesheets = (() => {
      if (
        !data ||
        !mappedBranches ||
        Object.keys(mappedBranches).length === 0
      ) {
        return [];
      }
      return data.map((timesheet) => {
        if (timesheet?.shift) {
          const branch = mappedBranches?.[timesheet.shift!.project.branchId];
          const {
            startTime: rawStartTime,
            endTime: rawEndTime,
            locationId,
          } = timesheet.shift;
          const { startTime, endTime, date } = normalizedShiftTimes({
            startTime: rawStartTime,
            endTime: rawEndTime,
            date: timesheet.shift.date || timesheet.shift.project.date,
            locationId,
            mappedLocations,
            isRemoteWork: branch?.schedulingConfig?.isRemoteWork,
          });
          timesheet.branchId = timesheet.shift!.project.branchId;
          timesheet.locationId = locationId;
          timesheet.shift.date = date;
          timesheet.shift.startTime = startTime;
          timesheet.shift.endTime = endTime;
          timesheet.scheduledStartTime = startTime;
          timesheet.scheduledEndTime = endTime;
          timesheet.date = date;
        } else {
          const branch = mappedBranches?.[timesheet?.job!.branchId];
          if (branch) {
            timesheet.branchId = branch.id;
            timesheet.locationId = branch.locationId;
            timesheet.scheduledStartTime =
              branch.timesheetConfig.defaultJobStartTime;
            timesheet.scheduledEndTime =
              branch.timesheetConfig.defaultJobEndTime;
            timesheet.date = timesheet.job!.date;
          }
        }
        return timesheet;
      });
    })();

    const mappedTimesheets = mappedArray(timesheets) as {
      [key: number]: CustomTimesheetDTO;
    };

    return { timesheets, mappedTimesheets };
  }
)({
  keySelector: (data, mappedBranches, mappedLocations, storeKey) => storeKey,
});

export const useTimesheets = () => {
  const storeKey = useMemo(() => ['timesheets'], []);

  const { mappedBranches } = useBranches();
  const { mappedLocations } = useLocations();

  const { isPending, data, refetch } = useQuery<Response>({
    queryKey: storeKey,
    persister: timesheetsPersister.persisterFn,
    queryFn: () => api.getTimesheets(),
    enabled: !!mappedBranches,
    select: (data) =>
      selector(data, mappedBranches, mappedLocations, storeKey.join('#')),
  });

  const { timesheets, mappedTimesheets } = useMemo(() => {
    const { timesheets = [], mappedTimesheets = {} } = data || {};
    return {
      timesheets,
      mappedTimesheets,
    };
  }, [data]);

  return {
    isLoading: isPending,
    timesheets,
    mappedTimesheets,
    refetch,
  };
};
