import React, { FC } from 'react';
import styled from 'styled-components/native';
import { BaseView } from '../Layout';

const Container = styled(BaseView)`
  position: relative;
  padding: 10px 0;
  height: 100%;
  /* background-color: red; */
`;

const Line = styled(BaseView)`
  position: absolute;
  width: 1px;
  background-color: ${({ theme }) => theme.colors.gray3};
  height: 100%;
  left: 0;
  z-index: -1;
`;

const TopLine = styled(Line)`
  top: 0;
`;

const BottomLine = styled(Line)`
  bottom: 0;
`;

interface Props {
  item: FC<any>;
  top: boolean;
  bottom: boolean;
}

export const TimelineNob: FC<Props> = ({ item: Item, top, bottom }) => (
  <Container>
    {bottom && <BottomLine />}
    <Item />
    {top && <TopLine />}
  </Container>
);
