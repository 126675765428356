import {
  IBranch,
  useLocations,
  usePositions,
  useTimesheets,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { SORT_BY_DATE_AND_TIME } from '@violetta/ubeya/shared';
import { BaseView } from '@violetta/ubeya/ui';
import React, { FC, useMemo } from 'react';
import { CardTitle } from '../CardTitle/CardTitle';
import { TimeclockCard } from './TimeclockCard';

interface Props {
  branches: IBranch[];
  mappedBranches: Record<number, IBranch>;
}

export const TimeclocksCard: FC<Props> = React.memo(
  ({ branches, mappedBranches }) => {
    const { timesheets } = useTimesheets();
    const { t } = useTranslation();
    const { mappedLocations } = useLocations();
    const { mappedPositions } = usePositions();

    const sorter = SORT_BY_DATE_AND_TIME('startTime');

    const filteredTimesheets = useMemo(
      () =>
        timesheets
          .filter(
            (timesheet) =>
              (timesheet.disableClockWithMobile !== undefined
                ? !timesheet.disableClockWithMobile
                : mappedBranches?.[timesheet.branchId]?.timesheetConfig
                    ?.toAllowClockWithMobile) &&
              (!timesheet?.startTime || !timesheet?.endTime)
          )
          .sort(({ shift: shiftA }, { shift: shiftB }) =>
            sorter(shiftA, shiftB)
          ),
      [mappedBranches, sorter, timesheets]
    );

    if (
      (branches || []).length === 0 ||
      !timesheets ||
      timesheets.length === 0
    ) {
      return null;
    }

    return (
      <BaseView>
        {filteredTimesheets.length > 0 && <CardTitle title={t('timeclock')} />}
        {filteredTimesheets.map((item) => (
          <TimeclockCard
            key={`b${item.branchId}i${item.id}`}
            type={item.startTime ? 'active' : 'not-active'}
            timesheet={item}
            mappedLocations={mappedLocations}
            mappedBranches={mappedBranches}
            mappedPositions={mappedPositions}
            t={t}
          />
        ))}
      </BaseView>
    );
  }
);
