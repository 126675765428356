import React, { FC, useState, useCallback } from 'react';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import styled, { useTheme } from 'styled-components/native';
import { IconClose, SvgIcon } from '../Icons';
import { FlexCenter, Absolute, BaseView } from '../Layout';
import { AdaptedTextInput } from '../TextInputs';

interface Props {
  onChange?: (text: string) => void;
  searchTerm?: string;
  autoFocus?: boolean;
  autoCorrect?: boolean;
  placeholder?: string;
}

const Container = styled(FlexCenter)`
  position: relative;
  justify-content: flex-end;
  flex: 1;
`;

const EraseContainer = Absolute({ right: 5 });
const SearchContainer = Absolute({ right: 15 });

const CustomAdaptedTextInput = styled(AdaptedTextInput)`
  flex: 1;
  border-radius: 20px;
  padding-right: 40px;
`;

export const SearchBox: FC<Props> = ({
  onChange,
  searchTerm = '',
  autoFocus = false,
  autoCorrect = true,
  placeholder = undefined,
}) => {
  const [value, setValue] = useState(searchTerm);
  const { colors } = useTheme();
  const onChangeInternal = useCallback(
    (text: string) => {
      setValue(text);

      if (onChange) {
        onChange(text);
      }
    },
    [onChange]
  );

  return (
    <Container>
      <CustomAdaptedTextInput
        onChangeText={onChangeInternal}
        value={value}
        style={{ textAlign: 'left' }}
        autoFocus={autoFocus}
        autoCorrect={autoCorrect}
        autoCapitalize="none"
        selectionColor={colors.primary}
        placeholder={placeholder}
      />

      {value ? (
        <EraseContainer>
          <IconClose
            onPress={() => onChangeInternal('')}
            size={16}
            color="#999"
          />
        </EraseContainer>
      ) : (
        []
      )}
      {!value && (
        <SearchContainer>
          <SvgIcon xml={icons.symbolIcons.searchIcon} width={18} height={18} />
        </SearchContainer>
      )}
    </Container>
  );
};
