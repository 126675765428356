import { useNavigation } from '@react-navigation/native';
import { useLocale, useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, FlexRow } from '@violetta/ubeya/ui';
import moment from 'moment';
import momentTz from 'moment-timezone';
import React, { useCallback, useMemo } from 'react';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { BookingsScreenNames } from '../../../bookings/navigation';
import { API_DATE_FORMAT } from '../../../main/constants';
import { AuditLogRow } from './AuditLogRow';
import { getAuditLogTransMapping } from './auditLogTransMapping';
import { TimeAgoRenderer } from './TimeAgoRenderer';

const Container = styled(FlexRow)`
  padding: 8px 0;
  justify-content: space-between;
  align-items: center;
  ${({ isLast }) =>
    !isLast
      ? `border-bottom-width: 1px;
  border-bottom-color: #ddd;`
      : ''}
`;

const TextContainer = styled(BaseView)`
  width: 90%;
`;

export const AuditLog = ({
  id,
  type,
  data,
  createdAt,
  notification,
  canNavigate,
  isLast,
}) => {
  const { locale } = useLocale();

  const { t } = useTranslation();
  const reporterTitle =
    data.reportedType === 'admin' ? t('Administrator') : t('User');
  const { navigate } = useNavigation();
  const startTime = useMemo(
    () => data.startTime && moment.utc(data.startTime).format('LT'),
    [data.startTime]
  );

  const endTime = useMemo(
    () => data?.endTime && moment.utc(data?.endTime).format('LT'),
    [data?.endTime]
  );

  const shiftStartTime = useMemo(
    () =>
      data?.shiftStartTime && moment.utc(data?.shiftStartTime).format('l, LT'),
    [data?.shiftStartTime]
  );

  const formattedData = useMemo(
    () => ({
      ...data,
      shiftDate: moment
        .utc(new Date(data?.shiftDate), API_DATE_FORMAT, true)
        .format('l'),
      date: moment.utc(data?.date, API_DATE_FORMAT, true).format('l'),
      jobDate: moment
        .utc(new Date(data?.jobDate), API_DATE_FORMAT, true)
        .format('l'),
      employeeName: data?.employeeName || data?.reporterName,
      adminName: data?.adminName || data?.reporterName,
      startTime,
      endTime,
      shiftStartTime,
      reporterTitle: data?.reporterTitle || reporterTitle,
      createdAt,
    }),
    [createdAt, data, endTime, reporterTitle, shiftStartTime, startTime]
  );

  const processedType = useMemo(() => {
    if (type === 'EmployeeShiftUpdated') {
      return startTime && endTime
        ? 'EmployeeShiftTimeUpdated'
        : startTime
        ? 'EmployeeShiftStartTimeUpdated'
        : endTime
        ? 'EmployeeShiftEndTimeUpdated'
        : type;
    }
    return type;
  }, [endTime, startTime, type]);

  const auditLogTranslationKey = getAuditLogTransMapping({
    reportedType: data?.reportedType,
    type: processedType,
    checkShift: [
      'CreateTimesheetStartTime',
      'ResetTimesheetTime',
      'CreateTimesheetEndTime',
    ].includes(type),
    checkMessage: [
      'EmployeeApplicationDeclined',
      'EmployeeRegistrationProjectOnHold',
      'EmployeeRegistrationShiftOnHold',
      'EmployeeUnBookedForShift',
      'EmployeeTimesheetChanged',
    ].includes(type),
    shiftDate: data?.shiftDate,
    message: data?.message,
    formattedData,
  });

  const handleNavigateBooking = useCallback(() => {
    navigate(BookingsScreenNames.MAIN, {
      screen: BookingsScreenNames.BOOKING,
      params: { bookingId: notification?.shiftId },
    });
  }, [navigate, notification?.shiftId]);

  if (
    !auditLogTranslationKey ||
    !t(auditLogTranslationKey) ||
    t(auditLogTranslationKey) === ''
  ) {
    return null;
  }

  return (
    <Container
      as={Pressable}
      key={id}
      onPress={handleNavigateBooking}
      disabled={!canNavigate}
      isLast={isLast}
    >
      <TextContainer>
        <AuditLogRow
          key={id}
          i18nKey={auditLogTranslationKey}
          data={formattedData}
        />
        <TimeAgoRenderer
          time={momentTz.tz(createdAt, 'UTC').toDate()}
          locale={locale}
        />
      </TextContainer>
      {/* {canNavigate && <ChevronRight />} */}
    </Container>
  );
};
