import React, { FC, useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { icons } from '@violetta/ubeya/assets';
import { BaseView, RipplePressable, SvgIcon } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { NotificationsScreenNames } from '../../notifications/navigation';
import { HomeHeader } from '../components/HomeHeader';
import { HomeScreen } from '../screens';
import { HomeScreenNames } from './ScreenNames';

const { Navigator, Screen } = createNativeStackNavigator();

const IconRightContainer = styled(BaseView)`
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HomeNavigation: FC = () => {
  const { navigate } = useNavigation();

  const navigateToNotifications = useCallback(
    () => navigate(NotificationsScreenNames.MAIN),
    [navigate]
  );

  const HeaderRightNotifiction = () => (
    <IconRightContainer as={RipplePressable} onPress={navigateToNotifications}>
      <SvgIcon
        xml={icons.navIcons.notification}
        width={24}
        height={24}
        color="#ffffff"
      />
    </IconRightContainer>
  );

  return (
    <Navigator initialRouteName={HomeScreenNames.HOME}>
      <Screen
        name={HomeScreenNames.HOME}
        component={HomeScreen}
        options={{
          header: (props) => (
            <HomeHeader
              {...props}
              hideBack
              hasLeftMenu
              hasRightContent
              rightContent={HeaderRightNotifiction}
              isPrimaryColor
            />
          ),
        }}
      />
    </Navigator>
  );
};
