/* eslint-disable react/no-unescaped-entities */
import React, { FC, useCallback } from 'react';
import {
  FlexColumnCenter,
  H1Bold,
  H2Bold,
  BaseScreen,
} from '@violetta/ubeya/ui';

import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/native';

const Container = styled(FlexColumnCenter)`
  flex: 1;
`;

const Title = styled(H1Bold)`
  text-align: center;
  padding: 10px 30px;
`;

const Secondary = styled(H2Bold)`
  padding: 30px 30px;
`;

interface Props {}

export const NetworkErrorScreen: FC<Props> = () => {
  const queryClient = useQueryClient();

  const reload = useCallback(
    async () => queryClient.invalidateQueries({ queryKey: ['user'] }),
    [queryClient]
  );

  return (
    <BaseScreen onRefresh={reload}>
      <Container>
        <Title>Ooops, we didn't anticipate this taking so long</Title>
        <Secondary>Please try to refresh</Secondary>
      </Container>
    </BaseScreen>
  );
};
