import React, { FC } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  FlexRowBetween,
  H3Regular,
  RipplePressable,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const Container = styled(FlexRowBetween)`
  margin-top: 25px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 6px;
`;

const Title = styled(H3Regular)`
  color: #252525;
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
`;

const ShowAll = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.primary};
`;

interface Props {
  title: string;
  count?: number;
  showAllKey?: string;
  alwaysShowAllLink?: boolean;
  onShowAllPress?: () => void;
}

export const CardTitle: FC<Props> = React.memo(
  ({
    title,
    count,
    onShowAllPress,
    showAllKey = 'showAll',
    alwaysShowAllLink = false,
  }) => {
    const { t } = useTranslation();
    const showLink = alwaysShowAllLink || (!!count && Number(count) > 3);
    return (
      <Container>
        <Title>{title}</Title>
        {showLink && (
          <BaseView as={RipplePressable} onPress={onShowAllPress}>
            <ShowAll>
              {t(showAllKey)} {!!count && count > 1 ? `(${count})` : ''}
            </ShowAll>
          </BaseView>
        )}
      </Container>
    );
  }
);
