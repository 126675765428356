import { IAccount } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { FlexColumnDirection, H3Regular } from '@violetta/ubeya/ui';
import React, { FC } from 'react';
import styled from 'styled-components/native';
import { PersonalDetailsCard } from './PersonalDetailsCard';

const Container = styled(FlexColumnDirection)`
  flex: 1;
`;

const Title = styled(H3Regular)``;

interface Props {
  account: IAccount;
}

export const PersonalDetailsSection: FC<Props> = ({ account }) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>{t('personalDetails')}</Title>
      <PersonalDetailsCard />
    </Container>
  );
};
