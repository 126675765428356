import React, { FC, useCallback, useState } from 'react';
import { Locale, useLocale, useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FormGroup,
  FormGroupHorizontal,
  Switch,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { LanguageSelect } from '../components';
import { useTheme } from '@react-navigation/native';
import { useUser } from '@violetta/ubeya/auth';

const ScrollView = styled.ScrollView`
  background-color: ${({ theme }) => theme.colors.lightSurface};
`;

const Container = styled(BaseView)`
  flex: 1;
  display: flex;
  padding: 10px 20px;
`;
const Divider = styled(BaseView)`
  height: 20px;
`;

type Props = {};

export const SettingsScreen: FC<Props> = () => {
  const { languages, saveLocale, locale } = useLocale();
  const { t } = useTranslation();
  const { data: userData, subscribe } = useUser();
  const [isEmailSubscribed, setIsEmailSubscribed] = useState(
    !!userData?.subscribeEmail
  );
  const toggleSwitch = () =>
    setIsEmailSubscribed((previousState) => {
      subscribe({ email: !previousState });
      return !previousState;
    });

  const onSelected = useCallback(
    (value: string[]) => {
      const newLocale = value[0] as Locale;
      if (newLocale !== locale) {
        saveLocale(newLocale);
      }
    },
    [locale, saveLocale]
  );

  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Container>
        <FormGroup title={t('language')}>
          <LanguageSelect
            options={languages}
            onSelected={onSelected}
            value={[locale]}
          />
        </FormGroup>
        <Divider />
        <FormGroupHorizontal title={t('emailNotifications')}>
          <Switch onValueChange={toggleSwitch} value={isEmailSubscribed} />
        </FormGroupHorizontal>
      </Container>
    </ScrollView>
  );
};
