import { BaseText, BaseView } from '@violetta/ubeya/ui';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-native-chart-kit';
import styled from 'styled-components/native';
import { useMemo } from 'react';
import { StatsColorsMap } from './constants';

type LiveViewTypes =
  | 'checkedIn'
  | 'scheduled'
  | 'onTime'
  | 'inShift'
  | 'late'
  | 'undertime'
  | 'overtime'
  | 'finished'
  | 'absent'
  | 'totalEvents';

type DataParams = {
  liveViewStats: Record<LiveViewTypes, number>;
};

const InnerDataMainCircle = styled(BaseView)`
  width: 140px;
  height: 140px;

  border-radius: 100px;
  background-color: white;

  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -62px;
  margin-top: -70px;

  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const InnerDataMainCircleText = styled(BaseText)`
  padding: 2px;
  color: black;
  text-align: center;
  font-size: 18px;
`;

const InnerData = ({ scheduled }) => {
  const { t } = useTranslation();
  return (
    <InnerDataMainCircle>
      <InnerDataMainCircleText>{t('scheduled')}</InnerDataMainCircleText>
      <InnerDataMainCircleText> {scheduled}</InnerDataMainCircleText>
    </InnerDataMainCircle>
  );
};

const PieChartLegendWrapper = styled(BaseView)`
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-top: -10px;
`;

const PieChartLegendItem = styled(BaseView)`
  flex-direction: row;
  align-itemes: center;
  padding: 5px;
  margin-bottom: 5px;
`;

const PieChartLegendItemCircle = styled(BaseView)`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border-radius: 100;
  background-color: ${({ $color }) => $color};
`;

const PieChartLegendItemLabelName = styled(BaseText)`
  font-size: 16px;
`;
const PieChartLegendItemLabelValue = styled(BaseText)`
  font-size: 16px;
`;

const Title = styled(BaseText)`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-left: 10px;
  width: 400px;
`;

const PieChartLegend = ({ data = [] }) => {
  const { t } = useTranslation();
  return (
    <PieChartLegendWrapper>
      {data
        .filter(({ legend }) => legend === undefined || !!legend)
        .map((item, index) => (
          <PieChartLegendItem key={index}>
            <PieChartLegendItemCircle $color={item.color} />

            <PieChartLegendItemLabelName>
              {item.name}: {item.value}
            </PieChartLegendItemLabelName>
          </PieChartLegendItem>
        ))}
    </PieChartLegendWrapper>
  );
};

const MainWrapper = styled(BaseView)`
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
`;

const PieChartWrapper = styled(BaseView)`
  justify-content: center;
  align-items: center;
  flex: 2;
`;

export const LiveViewChart = ({ liveViewStats }: DataParams) => {
  const { t } = useTranslation();

  const data = useMemo(
    () => [
      {
        name: t('timesheetCheckedIn'),
        value: liveViewStats.checkedIn || 0,
        color: StatsColorsMap.timesheetCheckedIn,
      },
      {
        name: t('inShift'),
        value: liveViewStats.inShift || 0,
        color: StatsColorsMap.inShift,
      },

      {
        name: t('finished'),
        value: liveViewStats.finished || 0,
        color: StatsColorsMap.finished,
      },
      {
        name: t('noStart'),
        legend: true,
        value:
          (liveViewStats.scheduled || 0) -
            (liveViewStats.checkedIn || 0) -
            (liveViewStats.inShift || 0) -
            (liveViewStats.finished || 0) -
            liveViewStats.absent || 0,
        color: StatsColorsMap.noStart,
      },
      {
        name: t('absent'),
        value: liveViewStats.absent || 0,
        color: StatsColorsMap.absent,
      },
    ],
    [
      liveViewStats.absent,
      liveViewStats.checkedIn,
      liveViewStats.finished,
      liveViewStats.inShift,
      liveViewStats.scheduled,
      t,
    ]
  );

  return (
    <MainWrapper>
      <PieChartLegend data={data} />

      <PieChartWrapper>
        <PieChart
          data={data}
          width={250}
          height={250}
          chartConfig={{
            backgroundColor: '#ffffff',
            decimalPlaces: 0,
            color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
            barPercentage: 1,
          }}
          accessor="value"
          backgroundColor="transparent"
          paddingLeft="70"
          absolute
          hasLegend={false}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        />

        <InnerData scheduled={liveViewStats.scheduled || 0} />
      </PieChartWrapper>
    </MainWrapper>
  );
};
