import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNetInfo } from '@react-native-community/netinfo';
import { useIsFocused, useNavigation } from '@react-navigation/native';
import {
  CLOCK_METHODS,
  CustomTimesheetDTO,
  IBranchTimesheetConfig,
  IBreaktime,
  IEmployeeSlotBreakPlots,
  useBranches,
  useLocations,
  usePositions,
} from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseText,
  BaseView,
  FlexCenter,
  FlexColumn,
  FlexColumnDirection,
  FlexParent,
  FlexRowBetween,
  H3Bold,
  H3Light,
  IconClose,
  Loader,
  Modal,
  RipplePressable,
  SvgIcon,
} from '@violetta/ubeya/ui';
import { Alert } from '@violetta/ubeya/alert';
import momentTz from 'moment-timezone';
import { Platform, ScrollView, StyleSheet } from 'react-native';
import { Modalize } from 'react-native-modalize';
import { Portal } from 'react-native-portalize';
import QRCodeScanner from 'react-native-qrcode-scanner';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components/native';
import { TimesheetsScreenNames } from '../../timesheets';
import * as hooks from '../hooks';
import {
  IDuration,
  IReport,
  TimeclockState,
  TimeclockValidationStatus,
} from '../interfaces';
import { TimeclockBreakStarted } from './TimeclockBreakStarted';
import { TimeclockPage } from './TimeclockPage';
import { TimeclockInitial } from './TimeclockInitial';
import { HIDE_LOCATION_NAME_ACCOUNT_IDS } from '../../main/constants';
import { TimeclockCheckIn } from './TimeclockCheckIn';
import { qrCodeTorchState } from '../../../state';
import { useRecoilState } from 'recoil';
import { icons } from '@violetta/ubeya/assets';
import TimeclockContext from './TimeclockContext';
import { uiAlert } from '@violetta/ubeya/utils';
import { clockApiKeyToSlug } from '@violetta/ubeya/shared';

const Container = styled(BaseView)`
  flex: 1;
  width: 100%;
  background-color: #000;
`;
const Content = styled(FlexColumnDirection)`
  flex: 1;
  width: 100%;
  background-color: #000;
`;

const LoaderContainer = styled(FlexCenter)`
  align-items: center;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.surface};
`;

const QRTitle = styled(H3Light)`
  text-align: center;
  padding-right: 30px;
  flex: 1;
`;
const QRCodeScannerContainer = styled(BaseView)`
  margin-top: 50px;
`;

const ModalHeader = styled(FlexParent)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface};
  padding: 15px;
`;

const Title = styled(H3Bold)``;

const ModalContainer = styled(BaseView)`
  padding-bottom: 30px;
`;

const ModalSection = styled(BaseView)`
  padding: 20px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.gray11};
`;

const ModalRow = styled(FlexRowBetween)`
  align-items: center;
`;

const ModalRowText = styled(BaseText)`
  font-size: 16px;
  font-weight: normal;
  color: #1e2534;
`;

const IconsContainer = styled(FlexColumn)`
  align-items: flex-end;
`;

const PermissionsText = styled(H3Bold)`
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`;

const IconContainer = styled(RipplePressable)`
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  margin-top: 10px;
  margin-right: 10px;
`;

interface Props {
  title: string;
  state: TimeclockState;
  changeState: (state: TimeclockState) => Promise<void>;
  validate: (state: TimeclockState) => Promise<TimeclockValidationStatus>;
  reports: IReport[];
  timesheetId: number;
  timesheet: CustomTimesheetDTO;
  breaktimes: IBreaktime[];
  duration: IDuration;
  changing: TimeclockState | null;
  config: {
    toRequireCheckInWithMobile?: boolean;
    toForceRestrictions?: boolean;
  } | null;
  employeeSlotBreakPlots: IEmployeeSlotBreakPlots[] | null;
  refetch: () => void;
  isLoadingRefetch: boolean;
}

interface QRProps {
  timesheet: CustomTimesheetDTO;
  markShift: (isQrCodeScannerModalOpen: 'start' | 'end' | null) => void;
  qrCodeSecret: any;
  isQrCodeScannerModalOpen: 'start' | 'end' | null;
  onClose: () => void;
  prompt: any;
}

const QRCodeModal: FC<QRProps> = ({
  timesheet,
  markShift,
  qrCodeSecret,
  isQrCodeScannerModalOpen,
  onClose,
  prompt,
}) => {
  const { t } = useTranslation();
  // leave camera permissions to re-run render upon permissions change
  const isFocused = useIsFocused();

  const close = useCallback(() => {
    setIsQRLoading(false);
    onClose();
  }, [onClose]);

  const handleQRCodeValidation = useCallback(
    (value) => {
      try {
        const {
          isEmployeeScan,
          branchId,
          timesheetQRCode: timesheetQRCodeProp,
          shiftIds: qrShiftIds,
        } = JSON.parse(value?.data) || {};
        if (isEmployeeScan) {
          // This is a QR code that was created by an employee, not the manager
          prompt({
            title: t('qrCodeNotValid'),
            // body: t('timeclockErrorConfirm'),
            onConfirm: close,
          });
          return;
        }

        // we aee checking against a shift
        if (qrShiftIds !== undefined) {
          if (
            !timesheet?.shift?.id || // must have a shift id
            !(qrShiftIds || []).includes(timesheet?.shift?.id) // shift id is not valid
          ) {
            prompt({
              title: t('qrCodeInvalidShift'),
              body: t('qrCodeInvalidShiftBody'),
              onConfirm: close,
            });
            return;
          }
          // allowed
          markShift(isQrCodeScannerModalOpen!);
          return;
        }
        // we are checking against a branch
        if (
          branchId !== undefined &&
          timesheet?.branchId === branchId &&
          (timesheetQRCodeProp === undefined ||
            timesheetQRCodeProp === qrCodeSecret)
        ) {
          // allowed
          markShift(isQrCodeScannerModalOpen!);
          return;
        }
        prompt({
          title: t('qrCodeNotValid'),
          // body: t('timeclockErrorConfirm'),
          onConfirm: close,
        });
      } catch (err) {
        prompt({
          title: t('qrCodeNotValid'),
          onConfirm: close,
        });
      }
    },
    [
      close,
      isQrCodeScannerModalOpen,
      markShift,
      prompt,
      qrCodeSecret,
      t,
      timesheet?.branchId,
      timesheet?.shift?.id,
    ]
  );

  useEffect(() => {
    if (isFocused) {
      setIsQRLoading(false);
    }
  }, [isFocused]);

  const [isQRLoading, setIsQRLoading] = useState(false);
  const [torch, setTorch] = useRecoilState(qrCodeTorchState);

  return (
    <Modal
      animationType="slide"
      presentationStyle="pageSheet"
      visible={isQrCodeScannerModalOpen !== null}
    >
      <ModalHeader>
        <IconClose color="black" size={30} onPress={onClose} />
        <QRTitle>Please scan QR Code</QRTitle>
      </ModalHeader>
      <Container>
        <QRCodeScanner onRead={handleQRCodeValidation} />
      </Container>
    </Modal>
  );
};

export const TimeclockContent: FC<Props> = ({
  state,
  changeState,
  validate,
  reports,
  timesheetId,
  timesheet,
  breaktimes,
  duration,
  employeeSlotBreakPlots,
  changing,
  config,
  refetch,
  isLoadingRefetch,
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { navigate, goBack } = useNavigation();
  const { mappedBranches } = useBranches();
  const { mappedPositions } = usePositions();
  const { mappedLocations } = useLocations();
  const { isConnected } = useNetInfo();
  const [isTimeReportLoading, setIsTimeReportLoading] =
    useState<boolean>(false);
  const [isQrCodeScannerModalOpen, setIsQrCodeScannerModalOpen] = useState<
    'start' | 'end' | 'checkIn' | 'checkOut' | null
  >(null);

  const modalizeRef = useRef<Modalize>(null);
  const [scrollEnabled, setScrollEnabled] = useState(true);

  const closeBreakTypes = useCallback(() => {
    modalizeRef.current?.close();
  }, [modalizeRef]);

  const location = useMemo(
    () => mappedLocations[timesheet?.locationId],
    [mappedLocations, timesheet?.locationId]
  );
  const position = useMemo(
    () =>
      timesheet?.shift?.positionId &&
      mappedPositions?.[timesheet?.shift?.positionId]?.slug,
    [mappedPositions, timesheet?.shift?.positionId]
  );

  const startBreaktime = useCallback(
    (params) => {
      changeState(TimeclockState.BreaktimeStarted, {
        breakTypeId: params?.breakTypeId,
      });
    },
    [changeState]
  );

  const endBreaktime = useCallback(() => {
    changeState(TimeclockState.BreaktimeEnded);
  }, [changeState]);

  const shiftCheckIn = useCallback(() => {
    changeState(TimeclockState.TimeclockCheckedIn);
  }, [changeState]);

  const shiftCheckOut = useCallback(() => {
    changeState(TimeclockState.TimeclockCheckedOut);
  }, [changeState]);

  const startShift = useCallback(() => {
    changeState(TimeclockState.TimeclockStarted);
  }, [changeState]);

  const endShift = useCallback(() => {
    if (isConnected) {
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => queryKey?.[0] === 'timesheetsHistory',
      });
    }
    changeState(TimeclockState.TimeclockEnded);
  }, [changeState, isConnected, queryClient]);

  const prompt = useCallback(
    ({ title, body, onConfirm, onCancel }) =>
      Alert.alert(
        title,
        body,
        [
          ...(onCancel
            ? [
                {
                  text: t('cancel'),
                  onPress: onCancel || (() => {}),
                  type: 'cancel',
                },
              ]
            : []),
          { text: t('ok'), onPress: onConfirm, type: 'confirm' },
        ],
        { cancelable: false }
      ),
    [t]
  );
  const promptError = useCallback(
    ({ title, body }) =>
      Alert.alert(title, body, [{ text: t('ok'), type: 'confirm' }]),
    [t]
  );

  const markShift = useCallback(
    async (type: 'start' | 'end' | 'checkIn' | 'checkOut') => {
      const areYouSurePrompt = await uiAlert({
        title: t('areYouSureReportTimeclock', {
          time: t(clockApiKeyToSlug(`${type}Time`)).toLowerCase(),
        }),
        cancel: {
          text: t('no'),
        },
        save: {
          text: t('yes'),
        },
      });

      if (!areYouSurePrompt) {
        return;
      }

      const { currentState, chanegStateFunction } = (() => {
        switch (type) {
          case 'start':
            return {
              currentState: TimeclockState.TimeclockStarted,
              chanegStateFunction: startShift,
            };
          case 'end':
            return {
              currentState: TimeclockState.TimeclockEnded,
              chanegStateFunction: endShift,
            };
          case 'checkIn':
            return {
              currentState: TimeclockState.TimeclockCheckedIn,
              chanegStateFunction: shiftCheckIn,
            };
          case 'checkOut':
            return {
              currentState: TimeclockState.TimeclockCheckedOut,
              chanegStateFunction: shiftCheckOut,
            };
          default:
            throw new Error('invalid shift marking type');
        }
      })();

      if (isQrCodeScannerModalOpen) {
        setIsQrCodeScannerModalOpen(null);
      }

      setIsTimeReportLoading(true);
      const validation = await validate(currentState);
      if (validation === TimeclockValidationStatus.OK) {
        setIsTimeReportLoading(false);
        return chanegStateFunction();
      }

      const { message: promptMessage, warningOnly = false } = (() => {
        switch (validation) {
          case TimeclockValidationStatus.CLOCKIN_TOO_EARLY: {
            const shiftStartedDiffHours = momentTz(
              momentTz
                .tz(timesheet?.scheduledStartTime, location?.timezoneName)
                .toDate()
            ).diff(momentTz(), 'hour');
            if (shiftStartedDiffHours === 0) {
              const shiftStartedDiffMinutes = momentTz(
                momentTz
                  .tz(timesheet?.scheduledStartTime, location?.timezoneName)
                  .toDate()
              ).diff(momentTz(), 'minute');
              return {
                message: t('shiftNotStartedMinutes', {
                  minutes: shiftStartedDiffMinutes,
                }),
              };
            }

            return {
              message: t('shiftNotStartedHours', {
                hours: shiftStartedDiffHours,
              }),
            };
          }
          case TimeclockValidationStatus.CLOCKOUT_TOO_LATE: {
            const shiftEndedDiffHours = momentTz(
              momentTz
                .tz(timesheet?.scheduledEndTime, location?.timezoneName)
                .toDate()
            ).diff(momentTz(), 'hour');
            if (shiftEndedDiffHours === 0) {
              const shiftEndedDiffMinutes = momentTz(
                momentTz
                  .tz(timesheet?.scheduledEndTime, location?.timezoneName)
                  .toDate()
              ).diff(momentTz(), 'minute');
              return {
                message: t('shiftNotEndedMinutes', {
                  minutes: Math.abs(shiftEndedDiffMinutes),
                }),
              };
            }

            return {
              message: t('shiftNotEndedHours', { hours: shiftEndedDiffHours }),
            };
          }
          case TimeclockValidationStatus.CLOCKOUT_TOO_EARLY: {
            const scheduledTimezoneStartTime = momentTz().diff(
              momentTz(
                momentTz
                  .tz(timesheet?.startTime, location?.timezoneName)
                  .toDate()
              ),
              'minute'
            );

            return {
              message: t('shiftEndsTooEarly', {
                minutes: Math.abs(scheduledTimezoneStartTime),
              }),
              warningOnly: true,
            };
          }
          case TimeclockValidationStatus.TOO_FAR:
            return { message: t('clockDistanceWarning') };
          case TimeclockValidationStatus.LOCATION_PERMISSION_DENIED:
            return { message: t('clockLocationPermissionDenied') };
          case TimeclockValidationStatus.NO_LOCATION_AVAILABLE:
            return { message: t('clockLocationNoLocationAvailable') };
          case TimeclockValidationStatus.LOCATION_TIMEOUT:
            return { message: t('clockLocationLocationTimeout') };
          case TimeclockValidationStatus.LOCATION_UNKNOWN:
            return { message: t('clockLocationLocationUnknown') };
          case TimeclockValidationStatus.QR_CODE_NOT_VALID:
            return { message: t('qrCodeNotValid') };
          default:
            return { message: null };
        }
      })();

      setIsTimeReportLoading(false);
      if (config?.toForceRestrictions && !warningOnly) {
        return promptError({
          title: `${t('oops')}... ${promptMessage}`,
        });
      }

      return prompt({
        title: promptMessage,
        body: t('timeclockErrorConfirm'),
        onConfirm: chanegStateFunction,
        onCancel: () => {},
      });
    },
    [
      isQrCodeScannerModalOpen,
      validate,
      config?.toForceRestrictions,
      prompt,
      t,
      startShift,
      endShift,
      shiftCheckIn,
      shiftCheckOut,
      timesheet?.scheduledStartTime,
      timesheet?.scheduledEndTime,
      timesheet?.startTime,
      location?.timezoneName,
      promptError,
    ]
  );

  const markShiftStart = useCallback(() => {
    if (
      config?.mobileClockMethods?.clockIn?.method ===
      CLOCK_METHODS.EMPLOYEE_QR_SCAN
    ) {
      setIsQrCodeScannerModalOpen('start');
    } else {
      markShift('start');
    }
  }, [config?.mobileClockMethods?.clockIn?.method, markShift]);

  const markShiftEnd = useCallback(() => {
    if (
      config?.mobileClockMethods?.clockOut?.method ===
      CLOCK_METHODS.EMPLOYEE_QR_SCAN
    ) {
      setIsQrCodeScannerModalOpen('end');
    } else {
      markShift('end');
    }
  }, [config?.mobileClockMethods?.clockOut?.method, markShift]);

  const markShiftCheckIn = useCallback(() => {
    if (
      config?.mobileClockMethods?.checkIn?.method ===
      CLOCK_METHODS.EMPLOYEE_QR_SCAN
    ) {
      setIsQrCodeScannerModalOpen('checkIn');
    } else {
      markShift('checkIn');
    }
  }, [config?.mobileClockMethods?.checkIn?.method, markShift]);

  const markShiftCheckOut = useCallback(() => {
    if (
      config?.mobileClockMethods?.checkOut?.method ===
      CLOCK_METHODS.EMPLOYEE_QR_SCAN
    ) {
      setIsQrCodeScannerModalOpen('checkOut');
    } else {
      markShift('checkOut');
    }
  }, [config?.mobileClockMethods?.checkOut?.method, markShift]);

  useEffect(() => {
    if (state === TimeclockState.TimeclockEnded) {
      if (isConnected) {
        queryClient.invalidateQueries({ queryKey: ['timesheets'] });
      }
      goBack();
      if (timesheet?.id) {
        navigate(TimesheetsScreenNames.MAIN, {
          screen: TimesheetsScreenNames.TIMESHEET,
          params: { timesheet, viewDone: true, timesheetId: timesheet?.id },
        });
      }
    }
  }, [
    goBack,
    isConnected,
    navigate,
    queryClient,
    state,
    timesheet,
    timesheetId,
  ]);

  const { mappedBreakTypesByBranch } = hooks.useBreakTypes();

  const [breaktimeParams, setBreaktimeParams] = useState(null);

  const branchName = useMemo(
    () =>
      timesheet?.branchId ? mappedBranches?.[timesheet?.branchId]?.name : '',
    [mappedBranches, timesheet?.branchId]
  );

  const showLocationName = useMemo(
    () =>
      timesheet?.branchId
        ? !HIDE_LOCATION_NAME_ACCOUNT_IDS.includes(
            mappedBranches?.[timesheet?.branchId]?.accountId
          )
        : true,
    [mappedBranches, timesheet?.branchId]
  );

  const name = useMemo(
    () =>
      [showLocationName ? location?.name || '' : '', t(position) || '']
        .filter((a) => !!a && a !== '')
        .join(', '),
    [location?.name, position, showLocationName, t]
  );
  const reportedStartTime = useMemo(
    () =>
      timesheet?.startTime &&
      momentTz.tz(timesheet?.startTime!, location?.timezoneName).toDate(),
    [location?.timezoneName, timesheet?.startTime]
  );

  const reportedEndTime = useMemo(
    () =>
      timesheet?.endTime &&
      momentTz.tz(timesheet?.endTime!, location?.timezoneName).toDate(),
    [location?.timezoneName, timesheet?.endTime]
  );
  const reportedCheckInTime = useMemo(
    () =>
      timesheet?.checkInTime &&
      momentTz.tz(timesheet?.checkInTime!, location?.timezoneName).toDate(),
    [location?.timezoneName, timesheet?.checkInTime]
  );
  const reportedCheckOutTime = useMemo(
    () =>
      timesheet?.checkOutTime &&
      momentTz.tz(timesheet?.checkOutTime!, location?.timezoneName).toDate(),
    [location?.timezoneName, timesheet?.checkOutTime]
  );
  const reportedBreakStartTime = useMemo(
    () =>
      momentTz
        .tz(
          breaktimes[breaktimes.length - 1]?.startTime!,
          location?.timezoneName
        )
        .toDate(),
    [breaktimes, location?.timezoneName]
  );

  const onStartBreaktime = useCallback(
    (params: object) => {
      const breakTypes =
        mappedBreakTypesByBranch?.[timesheet?.branchId]?.filter(
          ({ isDeleted }) => !isDeleted
        ) || [];
      if (!breakTypes || breakTypes.length <= 1) {
        startBreaktime({ breakTypeId: breakTypes?.[0]?.id });
      } else {
        modalizeRef?.current?.open();
        setBreaktimeParams(params);
      }
    },
    [mappedBreakTypesByBranch, timesheet?.branchId, startBreaktime]
  );

  const contextParams = useMemo(
    () => ({
      onStartShift: markShiftStart,
      onEndShift: markShiftEnd,
      onStartBreak: onStartBreaktime,
      onCheckInShift: markShiftCheckIn,
      onCheckOutShift: markShiftCheckOut,
      onEndBreak: endBreaktime,
      employeeSlotBreakPlots: employeeSlotBreakPlots,
      changing,
      timesheet,
      startTime: reportedStartTime,
      endTime: reportedEndTime,
      checkIn: reportedCheckInTime,
      checkOut: reportedCheckOutTime,
      breakStartTime: reportedBreakStartTime,
      config,
      state,
      reports,
      branchName,
      name,
      setIsQrCodeScannerModalOpen,
      refetch,
      isLoadingRefetch,
      isTimeReportLoading,
    }),
    [
      branchName,
      changing,
      config,
      employeeSlotBreakPlots,
      endBreaktime,
      isLoadingRefetch,
      markShiftCheckIn,
      markShiftCheckOut,
      markShiftEnd,
      markShiftStart,
      name,
      onStartBreaktime,
      refetch,
      reportedBreakStartTime,
      reportedCheckInTime,
      reportedCheckOutTime,
      reportedEndTime,
      reportedStartTime,
      reports,
      state,
      timesheet,
      isTimeReportLoading,
    ]
  );
  return (
    <Container>
      <Content>
        <TimeclockContext.Provider value={contextParams}>
          {!timesheet?.id ? (
            <LoaderContainer>
              <Loader />
            </LoaderContainer>
          ) : (
            <TimeclockPage />
          )}
        </TimeclockContext.Provider>
      </Content>

      {isQrCodeScannerModalOpen && (
        <QRCodeModal
          isQrCodeScannerModalOpen={isQrCodeScannerModalOpen}
          timesheet={timesheet}
          qrCodeSecret={config?.qrCodeSecret}
          onClose={() => setIsQrCodeScannerModalOpen(null)}
          prompt={prompt}
          markShift={markShift}
        />
      )}

      <Portal>
        <Modalize
          adjustToContentHeight
          ref={modalizeRef}
          handlePosition="inside"
          disableScrollIfPossible={Platform.OS !== 'android'}
          onBackButtonPress={closeBreakTypes}
          onOverlayPress={closeBreakTypes}
          panGestureEnabled={scrollEnabled}
          scrollViewProps={{ scrollEnabled }}
          withHandle={false}
          HeaderComponent={
            <ModalHeader>
              <Title>{t('breakType')}</Title>
            </ModalHeader>
          }
        >
          <ModalContainer>
            {(mappedBreakTypesByBranch?.[timesheet?.branchId] || [])
              .filter(({ isDeleted }) => !isDeleted)
              .map(({ id, title }) => (
                <ModalSection>
                  <ModalRow
                    as={RipplePressable}
                    onPress={() => {
                      startBreaktime({
                        ...(breaktimeParams || {}),
                        breakTypeId: id,
                      });
                      modalizeRef?.current?.close();
                    }}
                  >
                    <ModalRowText>{title}</ModalRowText>
                  </ModalRow>
                </ModalSection>
              ))}
          </ModalContainer>
        </Modalize>
      </Portal>
    </Container>
  );
};
