import React, { FC } from 'react';
import {
  BaseView,
  Button,
  Card,
  FlexRow,
  H5Medium,
  H6Regular,
  NotificationChip,
  RipplePressable,
} from '@violetta/ubeya/ui';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { colors } from '../../../../style';

const Container = styled(BaseView)`
  padding: 12px;
  height: 190px;
  justify-content: space-between;
`;

const LightHours = styled(H6Regular)`
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.gray2};
`;

const ShiftName = styled(H5Medium)`
  font-weight: 500;
  flex-wrap: wrap;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 5px;
  max-width: 80%;
`;
const ShiftPosition = styled(H5Medium)`
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
`;

const TimeContainer = styled(FlexRow)`
  align-items: center;
  width: 100%;
`;

const FirstRow = styled(FlexRow)`
  padding-bottom: 5px;
  ${Platform.OS === 'web' && 'width:100%;'};
`;

const ShiftData = styled(BaseView)`
  padding-top: 12px;
  padding-right: 15px;
  flex: 1;
`;

const ButtonContainer = styled(BaseView)`
  align-items: flex-end;
`;

export interface SwiperCardItemProps {
  todo: {
    title: string;
    subtitle?: string;
    date?: string;
    time?: string;
    buttonText?: string;
    onButtonPress?: () => void;
    onCardPress?: () => void;
    isLoading?: boolean;
    hasChip?: boolean;
    chipText?: string;
  };
}

export const ThingsToDoSwiperCard: FC<SwiperCardItemProps> = ({ todo }) => {
  const {
    title,
    subtitle,
    date,
    time,
    buttonText,
    onButtonPress,
    isLoading,
    hasChip,
    chipText,
    onCardPress,
  } = todo;
  return (
    <Card as={RipplePressable} onPress={onCardPress}>
      <Container>
        <BaseView>
          <FirstRow>
            <ShiftData>
              <ShiftName numberOfLines={2}>{title}</ShiftName>
              {!!subtitle && (
                <ShiftPosition numberOfLines={2}>{subtitle}</ShiftPosition>
              )}
            </ShiftData>
            {hasChip && (
              <NotificationChip
                style={{ top: 5 }}
                text={chipText}
                color={colors.chip}
              />
            )}
          </FirstRow>
          <TimeContainer>
            <LightHours>{date}</LightHours>
            {!!time && <LightHours>{time}</LightHours>}
          </TimeContainer>
        </BaseView>
        {buttonText && (
          <ButtonContainer>
            <Button
              title={buttonText}
              onPress={onButtonPress}
              isLoading={isLoading}
              disabled={isLoading}
              type="primary"
              narrow
            />
          </ButtonContainer>
        )}
      </Container>
    </Card>
  );
};
