import React, { FC, useRef, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import Video from 'react-native-video';
import { IGalleryItem } from './IGalleryItem';

interface Props {
  item: IGalleryItem<any>;
}

export const VideoGalleryItem: FC<Props> = ({ item: { uri } }) => {
  const { width: windowWidth } = useWindowDimensions();
  const [size, setSize] = useState<{ width: number; height: number } | null>(
    null
  );

  const player = useRef<Video | null>(null);

  return (
    <Video
      source={{ uri }}
      style={{
        flex: 1,
        width: size?.width,
        height: size?.height,
      }}
      ref={player}
      onLoad={(response) => {
        const { width, height } = response.naturalSize;

        setSize({
          width: windowWidth,
          height: Math.floor((height / width) * windowWidth),
        });
      }}
      playWhenInactive={false}
      playInBackground={false}
    />
  );
};
