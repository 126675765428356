import {
  CreatePollAnswerParams,
  DeletePollAnswerParams,
  IEmployee,
  IPost,
} from '@violetta/ubeya/entities';
import { FlexColumnDirection } from '@violetta/ubeya/ui';
import React, { FC, useMemo } from 'react';
import styled from 'styled-components/native';
import { PostAttachments } from './PostAttachments';
import { PostComments } from './PostComments';
import { PostHeader } from './PostHeader';
import { PostMediaContent } from './PostMediaContent';
import { PostStatusBar } from './PostStatusBar';
import { QuestionPostContent } from './QuestionPostContent';

const Container = styled(FlexColumnDirection)<{ withBoxStyling: boolean }>`
  ${({ withBoxStyling }) =>
    withBoxStyling &&
    `
  shadow-color: rgba(0, 0, 0, 0.07);
  shadow-opacity: 1;
  shadow-offset: 0px 2px;
  shadow-radius: 20px;
  elevation: 10;
  margin-top: 20px;
`}
`;

interface Props {
  post: IPost;
  employees: Pick<IEmployee, 'id' | 'firstName' | 'lastName' | 'image'>[];
  roomsData?: any[];
  createPollAnswer(params: CreatePollAnswerParams): Promise<any>;
  deletePollAnswer(params: DeletePollAnswerParams): Promise<any>;
  createLike({ postId }: { postId: number }): Promise<any>;
  deleteLike({
    postId,
    likeId,
  }: {
    postId: number;
    likeId: number;
  }): Promise<any>;
  title?: string;
  withBoxStyling?: boolean;
}

export const Post: FC<Props> = ({
  post,
  employees,
  createPollAnswer,
  deletePollAnswer,
  createLike,
  deleteLike,
  title,
  withBoxStyling = true,
}) => {
  const { id, media, attachments, forms, polls } = post;
  const type = useMemo(
    () =>
      media?.[0]?.text || attachments.length > 0
        ? 'post'
        : polls?.[0]
        ? 'question'
        : forms?.[0]
        ? 'form'
        : undefined,
    [attachments.length, forms, media, polls]
  );

  return (
    <Container withBoxStyling={withBoxStyling}>
      <PostHeader post={post} title={title} />
      <PostMediaContent media={media} />
      {type === 'post' && <PostAttachments attachments={attachments} />}
      {type === 'question' && (
        <QuestionPostContent
          post={post}
          createPollAnswer={createPollAnswer}
          deletePollAnswer={deletePollAnswer}
        />
      )}
      <PostStatusBar
        post={post}
        createLike={createLike}
        deleteLike={deleteLike}
      />
      {/* Backend Not implemeneted */}
      {false && <PostComments post={post} employees={employees} />}
    </Container>
  );
};
