import { useCallback } from 'react';
// eslint-disable-next-line import/order
import * as AddCalendarEvent from 'react-native-add-calendar-event';
import { check, request, PERMISSIONS, RESULTS } from 'react-native-permissions';
import { Platform } from 'react-native';

export const useCalendar = () => {
  const getPermissions = useCallback(async () => {
    const hasPermissions = await check(
      Platform.OS === 'ios'
        ? PERMISSIONS.IOS.CALENDARS_WRITE_ONLY
        : PERMISSIONS.ANDROID.WRITE_CALENDAR
    );
    if (hasPermissions === RESULTS.GRANTED) {
      return true;
    }
    const result = await (async () => {
      // no permissions, ask for them
      if (Platform.OS === 'ios') {
        return request(PERMISSIONS.IOS.CALENDARS_WRITE_ONLY);
      }
      return request(PERMISSIONS.ANDROID.WRITE_CALENDAR);
    })();
    return result === RESULTS.GRANTED;
  }, []);

  const saveToCalendar = useCallback(
    ({
      title,
      endDate,
      startDate,
      description,
      location,
    }: {
      title?: string;
      description?: string;
      location?: string;
      startDate: Date;
      endDate?: Date;
    }) => {
      if (!title) {
        return '';
      }

      return AddCalendarEvent.presentEventCreatingDialog({
        title,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        location,
        notes: description,
      })
        .then((...args) => console.log('MyARGS', ...args))
        .catch((...args) => console.error('MyARGSERROR', ...args));
    },
    []
  );

  const findEventById = useCallback(
    (id: string) => RNCalendarEvents.findEventById(id),
    []
  );

  return {
    getPermissions,
    saveToCalendar,
    findEventById,
  };
};
