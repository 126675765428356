import { useQuery } from '@tanstack/react-query';
import { adminApi } from '@violetta/ubeya/api';
import { createAdminStoreKey } from '@violetta/ubeya/utils';
import * as sharedAtoms from '../../../shared/src/atoms';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
//todo change to
const POLLING_INTERVAL = 10 * 1000;

type FiltersType = {
  positionsIds?: number[];
  locationsIds?: number[];
  startTime?: string[];
};

type Params = {
  branchId: number;
  projectId: number;
  filters?: FiltersType;
};

export const useProjectLiveView = (params: Params) => {
  const selectedAccount = useRecoilValue(sharedAtoms.selectedAccount);
  const accountId = selectedAccount?.id;

  const { filters, branchId, projectId } = params;

  const storeKey = useMemo(
    () =>
      createAdminStoreKey([
        'project-live-view',
        accountId,
        branchId,
        projectId,
        filters,
      ]),
    [accountId, branchId, filters, projectId]
  );

  const { isPending, data, refetch } = useQuery({
    queryKey: storeKey,
    queryFn: () =>
      adminApi.fetchProjectLiveViewStats({
        accountId,
        branchId,
        projectId,
        filters,
      }),
    enabled: !!accountId && !!branchId && !!projectId,
    refetchInterval: POLLING_INTERVAL,
  });

  return {
    //todo
    data: data?.data ? data.data?.data : {},
    isLoading: isPending,
    refetch,
  };
};
