import styled from 'styled-components/native';
import { FlexColumnEvenly } from '../Layout';

export const LargeCircle = styled(FlexColumnEvenly)`
  width: 180px;
  height: 180px;
  background-color: ${({ theme }) => theme.colors.lightSurface};
  border-radius: 90px;
  border-width: 12px;
  border-color: ${({ theme }) => theme.colors.secondary};
`;
