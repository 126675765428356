import React, { FC, useCallback, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { icons } from '@violetta/ubeya/assets';
import { useTranslation } from '@violetta/ubeya/i18n';
import { getFileExtension, isFileImage, uiAlert } from '@violetta/ubeya/utils';
import { Linking } from 'react-native';
import styled from 'styled-components/native';
import { SvgIcon } from '../Icons';
import { BaseImage } from '../Images/Image';
import { RipplePressable, BaseView } from '../Layout';
import { BaseText } from '../Typography';

const Document = styled(BaseView)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: solid 1px #c9c9c9;
  border-radius: 8px;
  margin-right: 10px;
  margin-top: 10px;
  position: relative;
`;

const DocumentData = styled(BaseView)`
  margin-left: 3px;
  justify-content: space-between;
`;

const DocumentTitle = styled(BaseText)`
  font-size: 10px;
  font-weight: normal;
  color: #252525;
  max-width: 110px;
  margin-bottom: 4px;
`;

const DocumentType = styled(BaseText)`
  font-size: 10px;
  font-weight: normal;
  color: #858585;
`;

const RemoveChip = styled(RipplePressable)`
  width: 25px;
  height: 25px;
  border-radius: 13px;
  position: absolute;
  right: -7px;
  top: -7px;
  align-items: center;
  justify-content: center;
`;

interface Props {
  document: any;
  openAttachmentImagesGallery: (link: string) => void;
  onRemoveItem?: (value: any) => void;
  canRemoveItem?: boolean;
}

export const DocumentCard: FC<Props> = ({
  document,
  openAttachmentImagesGallery,
  onRemoveItem,
  canRemoveItem,
}) => {
  const ext = useMemo(
    () => document?.link && getFileExtension(document?.link)?.toUpperCase(),
    [document?.link]
  );
  const isImage = useMemo(
    () => document?.link && isFileImage(document?.link),
    [document?.link]
  );

  const { t } = useTranslation();
  const { navigate } = useNavigation();

  const openFile = useCallback(() => {
    if (!document?.link) {
      uiAlert({ title: t('unknown'), save: { text: t('ok') } });
      return;
    }
    if (isImage) {
      return openAttachmentImagesGallery(document);
    }
    if (ext === 'PDF') {
      return navigate('pdf-reader-nav', {
        screen: 'pdf-reader',
        params: {
          uri: document?.link,
        },
      });
    }
    return Linking.openURL(document?.link);
  }, [document, ext, isImage, navigate, openAttachmentImagesGallery, t]);

  const handleRemoveItem = useCallback(
    () => onRemoveItem && onRemoveItem({ item: document }),
    [document, onRemoveItem]
  );

  return (
    <Document
      as={RipplePressable}
      onPress={openFile}
      style={{ padding: isImage ? 0 : 8 }}
    >
      {!isImage && (
        <SvgIcon
          xml={
            ext === 'PDF'
              ? icons.uiIcons.pdfIcon
              : icons.uiIcons.unknownTypeIcon
          }
          width={33}
          height={33}
        />
      )}
      {!isImage && (
        <DocumentData>
          <DocumentTitle numberOfLines={1}>
            {document?.title || ''}
          </DocumentTitle>
          <DocumentType>{ext || ''}</DocumentType>
        </DocumentData>
      )}
      {isImage && (
        <BaseImage
          source={{ uri: document?.link }}
          style={{
            width: 50,
            height: 50,
            resizeMode: 'cover',
            borderRadius: 8,
          }}
        />
      )}
      {canRemoveItem && (
        <RemoveChip onPress={handleRemoveItem}>
          <SvgIcon width={25} height={25} xml={icons.cardIcons.availability} />
        </RemoveChip>
      )}
    </Document>
  );
};
