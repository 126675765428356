import { HttpMultiton } from '@violetta/ubeya/utils';

// local
// export const baseURL = process.env.API_BASE_URL ?? 'http://localhost:7400';
// export const authBaseURL = process.env.AUTH_BASE_URL ?? 'http://localhost:7404';
// export const chatURL = 'http://localhost:7405';
// export const chatWSURL = 'http://localhost:7406';

// staging
// export const baseURL =
//   process.env.API_BASE_URL ?? 'https://ottavio.api.ubeya.dev';
// export const authBaseURL =
//   process.env.AUTH_BASE_URL ?? 'https://auth.api.ubeya.dev';
// export const chatURL = 'https://chat.api.ubeya.dev';
// export const chatWSURL = 'https://chat-ws.api.ubeya.dev';

// production
export const baseURL =
  process.env.API_BASE_URL ?? 'https://ottavio.api.ubeya.com';
export const authBaseURL =
  process.env.AUTH_BASE_URL ?? 'https://auth.api.ubeya.com';
export const chatURL = 'https://chat.api.ubeya.com';
export const chatWSURL = 'https://chat-ws.api.ubeya.com';

const axios = HttpMultiton.getInstance('admin');

axios.Axios.defaults.baseURL = baseURL;
axios.Axios.defaults.withCredentials = true;
axios.Axios.defaults.responseType = 'json';

export const refreshToken = () => axios.post(`${authBaseURL}/admin/refresh`);

export const adminLogin = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => axios.post(`${authBaseURL}/admin/login`, {}, { email, password });

export const getMe = async () => axios.get('/auth/me');

export const getProjects = ({
  accountId,
  branchIds,
  from,
  to,
  page,
  statsForEntireProject,
}) =>
  axios.get(
    `/accounts/${accountId}/projects`,
    {},
    {
      branches: branchIds,
      from,
      to,
      page,
      statsForEntireProject,
    }
  );

export const getBranches = ({ accountId }) =>
  axios.get(`/accounts/${accountId}/branches`);

export const fetchClients = ({ accountId }) =>
  axios.get(`/accounts/${accountId}/clients`);

export const getPositions = ({ accountId }) =>
  axios.get(`/accounts/${accountId}/positions`);

export const getDirectory = (branchId: number) =>
  axios.get('/directory', { branchId });

export const getLocations = ({ accountId }) =>
  axios.get(`/accounts/${accountId}/locations`);

export const addNewLocation = ({ accountId, branchId, payload }) =>
  axios.post(
    `/accounts/${accountId}/branches/${branchId}/locations`,
    {},
    { ...payload }
  );

export const getProject = ({ accountId, branchId, projectId }) =>
  axios.get(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}`
  );

export const updateProject = ({ accountId, branchId, projectId, data }) =>
  axios.patch(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}`,
    {},
    data
  );

export const getEmployee = ({ accountId, employeeId }) =>
  axios.get(`/accounts/${accountId}/employees/${employeeId}`);

export const getTimesheet = ({ accountId, timesheetId }) =>
  axios.get(`/accounts/${accountId}/timesheets/${timesheetId}`);

export const updateTimesheet = ({ accountId, timesheetId, start, end }) =>
  axios.patch(
    `/accounts/${accountId}/timesheets/${timesheetId}`,
    {},
    {
      start,
      end,
    }
  );

export const updateSlot = ({
  accountId,
  branchId,
  projectId,
  shiftId,
  slotId,
  rating,
  review,
  notes,
  payrollNotes,
}) =>
  axios.patch(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}/shifts/${shiftId}/slots/${slotId}`,
    {},
    {
      rating,
      review,
      notes,
      payrollNotes,
    }
  );

export const updateOutsourcedTimesheet = ({
  accountId,
  timesheetId,
  start,
  end,
  clientApproved,
  clientDeclined,
}: {
  timesheetId: number;
  accountId: number;
  start?: { time: Date };
  end?: { time: Date };
  clientApproved?: { status: boolean; signature?: string };
  clientDeclined?: { status: boolean; reason: string };
}) =>
  axios.patch(
    `/accounts/${accountId}/timesheets/os/${timesheetId}`,
    {},
    {
      start,
      end,
      clientApproved,
      clientDeclined,
    }
  );

export const updateOutsourcedSlot = ({
  accountId,
  branchId,
  projectId,
  slotId,
  rating,
  review,
}) =>
  axios.patch(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}/os/slots/${slotId}`,
    {},
    {
      rating,
      review,
    }
  );

export const fetchRequests = ({ accountId }) =>
  axios.get(`/accounts/${accountId}/requests`);

export const fetchRequestsStats = ({ accountId }) =>
  axios.get(`/accounts/${accountId}/requests/stats`);

export const getRequestsTypes = () => axios.get('/requests/types');

export const getMessages = async ({ roomId }) => {
  const res = await axios.get(`${chatURL}/rooms/${roomId}`);
  return res?.data;
};

export const updateRequest = ({
  accountId,
  requestId,
  status,
  managerReason,
}) =>
  axios.patch(
    `/accounts/${accountId}/requests/${requestId}`,
    {},
    { status, managerReason }
  );

// Chat

export const markReadAllRoomMessages = ({ roomId, type }) =>
  axios.post(`${chatURL}/markReadAllRoomMessages`, { type }, { roomId });

export const getAllRooms = async () => {
  const res = await axios.get(`${chatURL}/rooms`, {});
  return res?.data;
};

export const sendMessage = ({ roomId, message, type }) =>
  axios.post(`${chatURL}/sendMessage`, { type }, { roomId, message });

export const readRoomMessages = async ({ roomId, firstMessageId }) => {
  const res = await axios.get(
    `${chatURL}/readRoomMessages`,
    {},
    { roomId, firstMessageId }
  );
  return res?.data;
};

export const fetchSuppliers = async ({ accountId }) =>
  axios.get(`/accounts/${accountId}/suppliers`);

export const fetchSupplier = async ({ accountId, supplierId }) =>
  axios.get(`/accounts/${accountId}/suppliers/${supplierId}`);

export const getAvailableUsers = async ({
  type,
}: {
  type: 'admin' | 'user';
}) => {
  const res = await axios.get(`${chatURL}/availableUsers`, {}, { type });
  return res?.data;
};

export const createOrder = async ({ accountId, branchId, payload }) =>
  axios.post(`/accounts/${accountId}/branches/${branchId}/orders`, {}, payload);

export const createProjectOrder = async ({ accountId, projectId, payload }) =>
  axios.post(`/accounts/${accountId}/projects/${projectId}/order`, {}, payload);

export const fetchEmployeesStats = ({ accountId, searchTerm, branches }) =>
  axios.get(
    `/accounts/${accountId}/employees/stats`,
    {},
    { searchTerm, branches }
  );

type LiveViewFilters = {
  positionIds?: number[];
  locationIds?: number[];
  startTime?: string[];
};

export const fetchLiveViewStats = ({
  accountId,
  branchIds,
  date,
  filters,
}: {
  accountId: number;
  branchIds: number[];
  date: string;
  filters?: LiveViewFilters;
}) =>
  axios.get(
    `/accounts/${accountId}/live-view/stats`,
    {},
    { branchIds, date, filters }
  );

export const fetchProjectLiveViewStats = ({
  accountId,
  branchId,
  projectId,
  filters,
}: {
  accountId: number;
  branchId: number;
  projectId: number;
  filters?: LiveViewFilters;
}) =>
  axios.get(
    `/accounts/${accountId}/branches/${branchId}/projects/${projectId}/liveView`,
    {},
    { filters }
  );

export const fetchEmployees = ({ accountId, ...params }) =>
  axios.get(`/accounts/${accountId}/employees`, {}, params);

export const fetchAccount = ({ accountId }) =>
  axios.get(
    `/accounts/${accountId}`,
    {},
    { includes: ['withCurrency', 'withLanguage', 'withCountry'] }
  );

export const sendFCMToken = ({ token }: { token: string }) =>
  axios.put('/admin/messaging/token', {}, { token });

export const sendDevice = ({
  os,
  appVersion,
  appBuild,
}: {
  os: {
    platform: string;
    version: string;
  };
  appVersion: string;
  appBuild: number;
}) => axios.patch('/admin/device', {}, { os, appVersion, appBuild });

export const getPrivateRoom = ({
  participant,
  accountId,
}: {
  participant: string;
  accountId: number;
}) =>
  axios.get<{ roomId: string }>(
    `${chatURL}/getPrivateRoom`,
    {},
    { participant, accountId }
  );
