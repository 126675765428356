import { useCallback, useMemo } from 'react';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { useLabels } from '../useLabels';
import { usePeriods } from '../usePeriods';
import { ILocalAvailabilities, localAvailabilitiesAtom, LocalAvailabilitiesDataType } from './state';

type AddParams = Omit<ILocalAvailabilities, 'id'>;

type EditParams = {
  id: number;
  status: 0 | 1 | 2;
};

function* generatorId() {
  let i = -1;
  while (true) {
    yield i;
    // eslint-disable-next-line no-plusplus
    i--;
  }
}

const counter = generatorId();

export const useLocalAvailabilities = ({ periodId }: { periodId: number }) => {
  const { mappedPeriods } = usePeriods();

  const { branchId, dates } = mappedPeriods[periodId];

  const { mappedLabels } = useLabels();

  const [data, setLocalAvailabilities] = useRecoilState(localAvailabilitiesAtom);

  const [localAvailabilities, dirty] = useMemo(
    () => [
      data.data.filter(({ date, labelId }) => {
        return (
          mappedLabels?.[labelId]?.branches?.includes(branchId) &&
          moment(date).isBetween(moment(dates[0].date), moment(dates[dates.length - 1].date), 'date', '[]')
        );
      }),
      data.dirty,
    ],
    [data, mappedLabels, branchId, dates],
  );

  const addLocalAvailability = useCallback(
    (params: AddParams) => {
      setLocalAvailabilities((prevLocalAvailabilities) => ({
        ...prevLocalAvailabilities,
        dirty: true,
        data: [
          ...prevLocalAvailabilities.data,
          {
            id: counter.next().value as number,
            ...params,
          },
        ],
      }));
    },
    [setLocalAvailabilities],
  );

  const deleteLocalAvailability = useCallback(
    ({ id }: { id: number }) => {
      setLocalAvailabilities((prevLocalAvailabilities) => ({
        ...prevLocalAvailabilities,
        dirty: true,
        data: prevLocalAvailabilities.data.filter((availability) => availability.id !== id),
      }));
    },
    [setLocalAvailabilities],
  );

  const updateLocalAvailability = useCallback(
    (params: EditParams) => {
      setLocalAvailabilities((prevLocalAvailabilities) => {
        const filteredAvailabilities = prevLocalAvailabilities.data.filter(({ id }) => id !== params.id);
        const updatedAvailability = prevLocalAvailabilities.data.find(({ id }) => id === params.id);

        if (!updatedAvailability) {
          return prevLocalAvailabilities;
        }

        return {
          ...prevLocalAvailabilities,
          dirty: true,
          data: [
            ...filteredAvailabilities,
            {
              ...updatedAvailability,
              ...params,
            },
          ],
        };
      });
    },
    [setLocalAvailabilities],
  );

  const putLocalAvailabilities = useCallback(
    (cb: (prevData: LocalAvailabilitiesDataType) => LocalAvailabilitiesDataType) => {
      setLocalAvailabilities((prevLocalAvailabilities) => cb(prevLocalAvailabilities));
    },
    [setLocalAvailabilities],
  );

  const filledDay = useCallback(
    (date: Date, labels: number[] | undefined = undefined) => {
      const period = mappedPeriods[periodId];

      const currentLabels =
        labels ?? period.dates.find(({ date: lDate }) => moment(date).isSame(moment(lDate), 'date'))?.labels ?? [];

      if (!currentLabels) {
        return false;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const labelId of currentLabels) {
        const result = (localAvailabilities || []).find(
          ({ date: lDate, labelId: lLabelId, status }) =>
            moment(lDate).isSame(moment(date), 'date') && lLabelId === labelId && status !== undefined,
        );

        if (!result) {
          return false;
        }
      }

      return true;
    },
    [localAvailabilities, periodId, mappedPeriods],
  );

  const filledPeriod = useMemo(() => {
    const period = mappedPeriods[periodId];

    // eslint-disable-next-line no-restricted-syntax
    for (const { date, labels } of period.dates) {
      if (labels.length === 0) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const result = filledDay(date, labels);
      if (!result) {
        return false;
      }
    }

    return true;
  }, [mappedPeriods, periodId, filledDay]);

  return {
    localAvailabilities,
    addLocalAvailability,
    updateLocalAvailability,
    deleteLocalAvailability,
    putLocalAvailabilities,
    filledPeriod,
    filledDay,
    dirty,
  };
};
