import { useCallback, useMemo } from 'react';
import { adminApi } from '@violetta/ubeya/api';
import { clearCookies } from '@violetta/ubeya/auth';
import { useTranslation } from '@violetta/ubeya/i18n';
import { createAdminStoreKey, mappedArray } from '@violetta/ubeya/utils';
import { createCachedSelector } from 're-reselect';
import { Alert } from '@violetta/ubeya/alert';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import * as sharedAtoms from '../../../shared/src/atoms';
import RNRestart from 'react-native-restart';

const selector = createCachedSelector(
  (data: any) => data,
  (data: any) => ({
    userData: data?.data,
    languageId: data?.data?.languageId ?? 7,
    accounts: data?.data?.accounts,
    updateStatus: data?.data?.updateStatus,
    mappedAccounts: mappedArray(data?.data?.accounts || []),
  })
)({
  keySelector: (data, storeKey) => storeKey,
});

export const useAdminUser = (options?: any) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [selectedAccount, setSelectedAccount] = useRecoilState(
    sharedAtoms.selectedAccount
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const storeKey = useMemo(() => createAdminStoreKey([]), []);
  const accountStoreKey = useMemo(() => createAdminStoreKey(['account']), []);
  const { isPending, isSuccess, data, isError, error, failureCount } = useQuery<
    any,
    any
  >({
    queryKey: storeKey,
    queryFn: () => adminApi.getMe(),
    select: (data) => selector(data, storeKey.join('#')),
    ...(options || {}),
  });

  const { data: accountData } = useQuery<any, any>({
    queryKey: accountStoreKey,
    queryFn: () => adminApi.fetchAccount({ accountId: selectedAccount?.id }),
    enabled: isSuccess && !!selectedAccount?.id,
  });

  const { userData, languageId, accounts, updateStatus, mappedAccounts } =
    data || {};

  const prompt = useCallback(
    ({ title, body, onConfirm }) =>
      Alert.alert(
        title,
        body,
        [
          { text: t('cancel'), type: 'cancel' },
          { text: t('ok'), onPress: onConfirm, type: 'confirm' },
        ],
        { cancelable: false }
      ),
    [t]
  );

  const logout = useCallback(
    async () =>
      prompt({
        title: t('confirmOperation'),
        body: t('logoutAreYouSure'),
        onConfirm: async () => {
          await clearCookies();
          RNRestart.Restart();
          queryClient.setQueryData(storeKey, null);
          queryClient.invalidateQueries({ queryKey: storeKey });
        },
      }),

    [prompt, queryClient, storeKey, t]
  );

  const reload = useCallback(async () => {
    queryClient.setQueryData(storeKey, null);
    return queryClient.invalidateQueries({ queryKey: storeKey });
  }, [queryClient, storeKey]);

  if (selectedAccount === null) {
    setSelectedAccount(data?.userData?.accounts[0] || null);
  }

  return {
    isLoading: isPending,
    isAuth: isSuccess && !isPending && !isError,
    isError,
    data: userData,
    accountData: accountData?.data,
    languageId,
    accounts,
    updateStatus,
    error,
    isSuccess,
    logout,
    reload,
    mappedAccounts,
    failureCount,
  };
};
