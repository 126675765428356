import React, { FC } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { useTranslation } from '@violetta/ubeya/i18n';
import { FlexRowBetween, H3Medium } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { config } from '../../../../modules/main/appConfig';

const Container = styled(FlexRowBetween)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Title = styled(H3Medium)`
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #252525;
`;

export const ChatHeaderTitle: FC<StackHeaderProps> = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t(config.chatTabSlug)}</Title>
    </Container>
  );
};
