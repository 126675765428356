import { ILocation } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import moment from 'moment';
import React, { FC, useMemo } from 'react';
import { Pressable } from 'react-native';
import { Modalize } from 'react-native-modalize';
import styled, { useTheme } from 'styled-components/native';
import { Button } from '../Buttons/Button';
import { CommentsCollapsible, FeedbackCollapsible } from '../Collapsible';
import { DateTextComponent } from '../DateText';
import {
  BaseView,
  FlexColumnCenter,
  FlexColumnDirection,
  FlexRowAround,
  FlexRowEvenly,
} from '../Layout';
import { ProjectLiveView } from '../LiveView';
import { BaseText, H3Regular, H5Regular, H6Medium, Link } from '../Typography';

const Container = styled(BaseView)`
  background-color: ${({ theme }) => theme.colors.whitesmoke};
  border-radius: 8px;
  padding-top: 8px;
`;
const QrContainer = styled(FlexRowEvenly)``;

const StatColumn = styled(FlexColumnDirection)``;
const StatNumber = styled<{ color: string }>(H6Medium)`
  font-size: 14px;
  font-weight: normal;
  line-height: 21px;
  text-align: center;
  color: ${({ color, theme }) => color || theme.colors.secondaryTitle};
`;
const StatText = styled(H6Medium)`
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondaryTitle};
  margin-bottom: 10px;
`;

const StatsContainer = styled(FlexRowAround)`
  margin-top: 15px;
`;

const CardDataDetails = styled(H5Regular)`
  font-size: 14px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray15};
  max-width: 80%;
  margin-bottom: 15px;
`;

const HeaderDateText = styled(H3Regular)`
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 10px;
  text-decoration: underline;
`;

const HeaderLocationText = styled(HeaderDateText)`
  text-decoration: none;
`;

const DataContainer = styled(BaseView)`
  padding: 10px;
  padding-bottom: 0px;
  width: 100%;
`;

const Separator = styled(FlexColumnCenter)`
  background-color: ${({ theme }) => theme.colors.gray11};
  margin: 15px 15px;
  height: 1px;
`;

interface Props {
  location: ILocation & { name: string };
  stats: {
    booked: number;
    clockedIn: number;
    clockedOut: number;
    needed?: number;
  };
  project: any;
  updateProject: (values: any) => void;
  goToProjectOnWeb?: () => void;
  captains?: string[];
  hasCreateNewOrder?: boolean;
  adminType?: boolean;
  toNewOrder?: () => void;
  call?: (phone: string) => void;
  filterModalizeRef: React.RefObject<Modalize>;
  timeFormat: string;
  dateFormat: string;
  shiftDate: Date;
  showScanQrCode: () => void;
  showPresentQrCode: () => void;
}

export const EventInfoCard: FC<Props> = ({
  location,
  stats,
  goToProjectOnWeb,
  project,
  updateProject,
  captains,
  adminType = false,
  hasCreateNewOrder,
  toNewOrder,
  timeFormat,
  dateFormat,
  filterModalizeRef,
  shiftDate,
  call,
  showScanQrCode,
  showPresentQrCode,
}) => {
  const { t } = useTranslation();
  const { colors } = useTheme();

  const projectName = useMemo(
    () => [location?.name, project?.name].filter((a) => !!a).join(' - '),
    [location?.name, project?.name]
  );

  const title = (
    <DataContainer>
      <Pressable onPress={goToProjectOnWeb}>
        <HeaderDateText>
          <DateTextComponent
            bold
            isMultiDate={false}
            date={shiftDate}
            fontSize={18}
          />
          {project.isMultiDay &&
            ` (${moment(project.date).format(dateFormat)} - ${moment(
              project.endDate
            ).format(dateFormat)})`}
        </HeaderDateText>
      </Pressable>
      <HeaderLocationText>{projectName}</HeaderLocationText>
      <CardDataDetails>{location?.address}</CardDataDetails>
      {hasCreateNewOrder && (
        <Link onPress={toNewOrder} component={BaseText}>
          {t('createNewOrder')}
        </Link>
      )}
      <StatsContainer>
        {stats?.needed ? (
          <StatColumn>
            <StatNumber>{stats?.needed}</StatNumber>
            <StatText>{t('employeesNeeded')}</StatText>
          </StatColumn>
        ) : (
          <></>
        )}
        <StatColumn>
          <StatNumber color={colors.green3}>{stats?.booked}</StatNumber>
          <StatText>{t('employeesBooked')}</StatText>
        </StatColumn>
        <StatColumn>
          <StatNumber color={colors.green3}>{stats?.clockedIn}</StatNumber>
          <StatText>{t('clockedIn')}</StatText>
        </StatColumn>
        <StatColumn>
          <StatNumber color={colors.seconday2}>
            {stats?.clockedOut || 0}
          </StatNumber>
          <StatText>{t('clockedOut')}</StatText>
        </StatColumn>
      </StatsContainer>
    </DataContainer>
  );

  if (adminType) {
    return (
      <Container>
        <ProjectLiveView
          timeFormat={timeFormat}
          dateFormat={dateFormat}
          projectId={project.id}
          branchId={project.branchId}
          filterModalizeRef={filterModalizeRef}
          title={title}
          renderBottom={
            <CommentsCollapsible
              containerStyle={{
                backgroundColor: colors.whitesmoke,
                paddingTop: 16,
                paddingBottom: 16,
                borderRadius: 8,
              }}
              project={project}
              updateProject={updateProject}
              call={call}
            />
          }
        />
      </Container>
    );
  }

  return (
    <Container>
      {title}
      <QrContainer>
        <Button
          type="primary"
          title={t('captainScanQrCode')}
          fontSize={14}
          lowercase
          paddingVertical={8}
          paddingHorizontal={15}
          radius={2}
          onPress={showScanQrCode}
        />
        <Button
          type="primaryReversed"
          title={t('captainPresenrBarcode')}
          fontSize={14}
          radius={2}
          lowercase
          paddingVertical={8}
          paddingHorizontal={15}
          onPress={showPresentQrCode}
        />
      </QrContainer>
      <Separator />
      <FeedbackCollapsible project={project} updateProject={updateProject} />
    </Container>
  );
};
