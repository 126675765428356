export const COMPLIANCE_OPTIONS = {
  MISSING: 0,
  UPLOADED: 1,
};

export const COMPLIANCE_SECTIONS = {
  MISSING: 'missingDocuments',
  INVALID: 'invalidDocuments',
  UPLOADED: 'uploadedDocuments',
};
