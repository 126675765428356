import { useCallback } from 'react';
import { useAccounts } from '@violetta/ubeya/entities';

export const useUserChatFunctions = () => {
  const { mappedAccounts } = useAccounts();
  const nameFunc = useCallback(
    ({
      metadata,
      contextId,
      roomName,
    }: {
      metadata?: any;
      contextId: number;
      roomName: string;
    }) =>
      metadata?.projectId || !contextId
        ? roomName
        : mappedAccounts?.[contextId]?.name || '',
    [mappedAccounts]
  );

  const imageFunc = useCallback(
    ({
      contextId,
    }: {
      metadata?: any;
      participants: string[];
      contextId: number;
    }) => mappedAccounts?.[contextId]?.logo,
    [mappedAccounts]
  );

  return { nameFunc, imageFunc };
};
