import React, { FC, useCallback, useContext, useMemo } from 'react';
import { useNavigation } from '@react-navigation/native';
import { IBranch, usePeriods } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView } from '@violetta/ubeya/ui';
import { ConfigContext } from '../../../../contexts';
import { AvailabilitiesScreenNames } from '../../../availabilities/navigation/ScreenNames';
import { AppContext } from '../../../main/AppContext';
import { CardTitle } from '../CardTitle/CardTitle';
import { AvailabilityCard } from './AvailabilityCard';

interface Props {
  branches: IBranch[];
}

export const AvailabilitiesCard: FC<Props> = ({ branches }) => {
  const { navigate } = useNavigation();
  const { mappedPeriodsByBranch } = usePeriods();
  const { rtl } = useContext(ConfigContext);
  const {
    availability: { setPeriod },
  } = useContext(AppContext);
  const { t } = useTranslation();

  const navigateAvailability = useCallback(
    (branchId: number) => {
      const periodId = mappedPeriodsByBranch[branchId]?.[0].id;
      setPeriod(periodId);

      // hack to wait for set period to finish
      navigate(AvailabilitiesScreenNames.MAIN, {
        screen: AvailabilitiesScreenNames.AVAILABILITY,
        params: {
          branchId,
        },
      });
    },
    [mappedPeriodsByBranch, navigate, setPeriod]
  );

  const displayedBranches = useMemo(
    () =>
      branches
        .filter(({ id }) => !!(mappedPeriodsByBranch[id]?.length > 0))
        .map((branch) => ({
          ...branch,
          filled: mappedPeriodsByBranch[branch.id]?.[0].filled,
          date: mappedPeriodsByBranch[branch.id]?.[0].dates?.[0].date,
        })) as (IBranch & { filled: boolean; date: Date })[],
    [mappedPeriodsByBranch, branches]
  );

  if (!displayedBranches || displayedBranches.length === 0) {
    return null;
  }

  return (
    <BaseView>
      <CardTitle title={t('availability')} />
      {displayedBranches.map(({ id, date, name }, index, arr) => (
        <AvailabilityCard
          key={id}
          id={id}
          date={date}
          rtl={rtl}
          name={arr.length > 1 ? name : null}
          onPress={navigateAvailability}
        />
      ))}
    </BaseView>
  );
};
