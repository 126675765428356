import React, { FC } from 'react';
import { StackHeaderProps } from '@react-navigation/stack';
import { useTranslation } from '@violetta/ubeya/i18n';
import { FlexRow, H3Medium } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';

const Container = styled(FlexRow)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Title = styled(H3Medium)`
  font-weight: 500;
  margin: 0 20px;
`;

export const BookingsHeaderTitle: FC<StackHeaderProps> = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title>{t('upcomingShifts')}</Title>
    </Container>
  );
};
