import React, { FC, useContext } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseView,
  FlexRowCenter,
  H2Regular,
  H6Regular,
  H3Regular,
  TimeLabelContainerProps,
  TimePicker,
  TimePickerField,
} from '@violetta/ubeya/ui';
import { required } from '@violetta/ubeya/utils';
import moment from 'moment';
import { useField, FormSpy } from 'react-final-form';
import { Pressable } from 'react-native';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../contexts';

const TimeLabelContainer = styled(BaseView)`
  width: 100px;
  border-bottom-width: 2px;
  border-color: rgba(37, 37, 37, 0.1);
  align-items: center;
`;

const TimeLabel = styled(H2Regular)`
  font-size: 20px;
  padding: 5px 0;
`;
const CustomTimepickerContainer = styled(BaseView)``;
const CustomTimepickerBottomTitle = styled(H6Regular)`
  margin-top: 12px;
  text-align: center;
`;

const Hours = styled(FlexRowCenter)`
  align-self: center;
  margin-top: 20px;
`;

const Dash = styled(H3Regular)`
  margin: 0 10px;
`;

type FormValues = {
  start: Date;
  end: Date;
};

const CustomTimepicker: FC<TimeLabelContainerProps & { label: string }> = ({
  setShow,
  value,
  label,
}) => {
  const { t } = useTranslation();

  return (
    <CustomTimepickerContainer>
      <TimeLabelContainer as={Pressable} onPress={() => setShow(true)}>
        <TimeLabel>
          {moment(value).isValid() ? moment(value).format('LT') : '-'}
        </TimeLabel>
      </TimeLabelContainer>
      <BaseView>
        <CustomTimepickerBottomTitle>{t(label)}</CustomTimepickerBottomTitle>
      </BaseView>
    </CustomTimepickerContainer>
  );
};

export const Timepickers: FC = () => {
  const { rtl } = useContext(ConfigContext);

  const {
    input: { onChange },
  } = useField('end');

  const onChangeInternal = ({ values }: { values: Partial<FormValues> }) => {
    if (
      !values.start ||
      !values.end ||
      moment(values.end).isSameOrAfter(moment(values.start))
    ) {
      return;
    }

    const newEndDate = moment(values.end).add(1, 'd');

    onChange(newEndDate.toDate());
  };

  return (
    <Hours rtl={rtl}>
      <FormSpy
        subscription={{ values: true, dirty: true, dirtyFields: true }}
        onChange={onChangeInternal}
      />
      <TimePickerField
        component={TimePicker}
        fieldProps={{
          name: 'start',
          validate: required,
        }}
        props={{
          transparent: false,
          step: 1,
          component: ({ setShow, value }) => (
            <CustomTimepicker setShow={setShow} value={value} label="from" />
          ),
        }}
      />
      <Dash>-</Dash>
      <TimePickerField
        component={TimePicker}
        fieldProps={{
          name: 'end',
          validate: required,
        }}
        props={{
          transparent: false,
          step: 1,
          component: ({ setShow, value }) => (
            <CustomTimepicker setShow={setShow} value={value} label="to" />
          ),
        }}
      />
    </Hours>
  );
};
