import React, { FC } from 'react';
import { Trans } from '@violetta/ubeya/i18n';
import { BaseText, BaseView, H5Regular } from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { theme } from '../../../../style';

const Container = styled(BaseView)`
  padding-bottom: 5px;
  display: flex;
  flex-direction: row;
`;

const LightDate = styled(BaseText)`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
`;

interface Props {
  i18nKey: string;
  data: any;
}

export const AuditLogRow: FC<Props> = React.memo(({ i18nKey, data }) => (
  <Container>
    <LightDate>
      <Trans
        i18nKey={i18nKey}
        values={data}
        components={[
          <BaseText style={{ fontWeight: 'normal' }} />,
          <BaseText style={{ color: theme.colors.approve }} />,
          <BaseText style={{ color: theme.colors.decline2 }} />,
          <BaseText style={{ color: theme.colors.auditLogOrange }} />,
          <BaseText style={{ color: theme.colors.auditLogYellow }} />,
        ]}
      />
    </LightDate>
  </Container>
));
