import React, { FC, useCallback } from 'react';
import { useTranslation } from '@violetta/ubeya/i18n';
import { BaseView, FlexRow, H7Light } from '@violetta/ubeya/ui';
import moment from 'moment';
import styled from 'styled-components/native';

interface Props {
  start: string | null;
  type: string;
  end: string | null;
}

const Container = styled(BaseView)``;

const Hour = styled(H7Light)`
  margin-right: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  color: ${({ status }) => (status === 1 ? '#ff2820' : '#46cc88')};
`;
const Content = styled(FlexRow)`
  align-items: center;
  justify-content: center;
`;

const LeftBorder = styled<{ status: number }>(BaseView)`
  border-radius: 2px;
  height: 25px;
  width: 3px;
  background-color: ${({ status }) => (status === 1 ? '#ff2820' : '#46cc88')};
`;

const BorderContainer = styled(BaseView)`
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GeneralAvailabilityItemChip: FC<Props> = ({
  start,
  end,
  type,
}) => {
  const status = type === 'Not Available' ? 1 : 2;
  const { t } = useTranslation();

  const getFormattedStatusByType = useCallback(
    (type) => {
      switch (type) {
        case 'Not Available':
          return t('notAvailable');
        case 'Partial':
        case 'Available':
          return t('available');

        default:
          return t('available');
      }
    },
    [t]
  );

  return (
    <Container>
      <Content>
        <BorderContainer>
          <LeftBorder status={status} />
        </BorderContainer>
        <Hour status={status}>{getFormattedStatusByType(type)}</Hour>
        {start && end && (
          <Hour status={status} style={{ marginRight: 8 }}>
            {' |  '}
            {`${moment(start).format('LT')} - ${moment(end).format('LT')}`}
          </Hour>
        )}
      </Content>
    </Container>
  );
};
