import React, { useContext } from 'react';
import { ShiftDTO, useLocations, usePositions } from '@violetta/ubeya/entities';
import { useTranslation } from '@violetta/ubeya/i18n';
import {
  BaseImage,
  BaseView,
  FlexColumn,
  FlexRow,
  FlexRowBetween,
  H5Regular,
} from '@violetta/ubeya/ui';
import styled from 'styled-components/native';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import { rangeFormat } from '@violetta/ubeya/utils';
import moment from 'moment';

const ShiftHeader = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;
`;

const ShiftTitleContainer = styled(FlexRow)`
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
`;

const DateTitleContainer = styled(FlexRowBetween)`
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
`;

const ShiftTitle = styled(H5Regular)`
  font-weight: 500;
  margin-right: ${({ $showLocation }) => ($showLocation ? 0 : 10)}px;
  flex-wrap: wrap;
`;

const LocationTitle = styled(H5Regular)`
  font-weight: 400;
  margin-right: 5px;
  flex-wrap: wrap;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 8px;
`;

const DateTitle = styled(H5Regular)`
  font-weight: 500;
  margin-right: 10px;
  flex-wrap: wrap;
`;

const ShiftTitleCount = styled(H5Regular)`
  align-self: flex-start;
  font-size: 13px;
  color: #858585;
  margin-top: 2px;
`;

const ShiftTitleTimes = styled(FlexRow)<{ rtl: boolean }>`
  align-self: flex-start;
  margin-left: 5px;
  flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
`;

const Time = styled(H5Regular)`
  font-weight: 500;
  font-size: 15px;
`;

const Container = styled(BaseView)`
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray11};
  padding: 18px 8px 0;
`;

const Supplier = styled(FlexRow)`
  align-items: center;
  padding-top: 10px;
  width: 100%;
`;

const BranchLogo = styled(BaseImage)`
  width: 36px;
  height: 36px;
  border-radius: 23px;
  margin-right: 10px;
`;

const SupplierName = styled(H5Regular)`
  font-weight: 500;
  margin-right: 10px;
  flex-wrap: wrap;
  max-width: 80%;
`;

type SupplierType = {
  name: string;
  logo?: string;
};

export const ShiftHeaderComponent = React.memo(
  ({
    shift,
    supplier,
    showDate = false,
    showLocation = false,
  }: {
    shift: ShiftDTO;
    supplier: SupplierType;
    showDate?: boolean;
    showLocation?: boolean;
  }) => {
    const { t } = useTranslation();
    const { mappedPositions } = usePositions();
    const { mappedLocations } = useLocations();
    const { rtl } = useContext(ConfigContext);
    const time = rangeFormat(shift.startTime, shift.endTime);
    const positionSectionRender = [
      shift.positionId &&
        (shift.position?.slug || t(mappedPositions[shift.positionId]?.slug)),
      shift.position?.slug,
      shift.comments || '',
    ].filter((elem) => !!elem);

    return (
      <Container>
        {supplier && (
          <Supplier>
            {supplier?.logo && <BranchLogo source={{ uri: supplier.logo }} />}
            {supplier?.name && <SupplierName>{supplier.name}</SupplierName>}
          </Supplier>
        )}

        <ShiftHeader>
          <ShiftTitleContainer>
            {(shift?.position || shift.positionId || !!shift.comments) && (
              <ShiftTitle $showLocation={showLocation}>
                {(positionSectionRender || []).join(', ')}
              </ShiftTitle>
            )}

            <ShiftTitleCount>
              {` (${(shift.employees || []).length})`}
            </ShiftTitleCount>
          </ShiftTitleContainer>
          <DateTitleContainer>
            <DateTitle>
              {showDate ? moment(shift.date).format('MMMM D') : ''}
            </DateTitle>
            <ShiftTitleTimes rtl={rtl}>
              <Time>{time}</Time>
            </ShiftTitleTimes>
          </DateTitleContainer>
        </ShiftHeader>
        {showLocation && shift.locationId && (
          <LocationTitle>
            {shift.location?.name || t(mappedLocations[shift.locationId]?.name)}
          </LocationTitle>
        )}
      </Container>
    );
  }
);
